import React, { useContext } from 'react'
import classNames from 'classnames'
import inlineInputFieldStyle from '../../../../components/InlineInputField/InlineInputField.module.scss'
import HtmlTextWrapper from '../../../../components/HtmlTextWrapper/HtmlTextWrapper'
import getUserStatusColor from '../../../../operations/getUserStatusColor'
import exerciseStyles from '../../ExerciseModules.module.scss'
import colorsContext from '../../../../contexts/ColorsContext'
import PutInOrderSelectedChoices from './PutInOrderSelectedChoices'
import PutInOrderChoices from './PutInOrderChoices'
import { orderBy } from 'lodash'
import { AnswerStatusEnums } from '../../../../enums/globalEnums/globalEnums'

const PutInOrderInputArea = ({ exerciseItem, stackIndex, itemIndex, enabled }) => {
	const { colors } = useContext(colorsContext)

	if (exerciseItem.checked) {
		return (
			<div
				className={classNames(inlineInputFieldStyle.middleInput)}
				style={{ borderColor: colors['MC3'] }}
			>
				<HtmlTextWrapper
					style={{ display: 'inline' }}
					textColor={exerciseItem.userAnswerStatus === AnswerStatusEnums.SKIPPED ? colors['C233'] : colors['C30']}
					data={{
						...exerciseItem.fullTextAnswer,
						sentence: exerciseItem.userChoices.map((obj, i) => (
							<span key={i} style={{ color: colors[getUserStatusColor(obj.answerStatus)] }}>
								{obj.text.sentence + (i + 1 < exerciseItem.userChoices.length ? ' ' : '')}
							</span>
						)),
					}}
				/>
			</div>
		)
	} else {
		return (
			<div
				className={classNames(inlineInputFieldStyle.middleInput, exerciseStyles.textHolder, 'position-relative')}
				style={{ borderColor: colors['C222'] }}
			>
				<PutInOrderSelectedChoices
					stackIndex={stackIndex}
					itemIndex={itemIndex}
					choices={exerciseItem.userChoices}
					enabled={enabled}
				/>
				<PutInOrderChoices
					stackIndex={stackIndex}
					itemIndex={itemIndex}
					choices={orderBy(exerciseItem.choices.filter(o => !o.selected), 'index')}
					enabled={enabled}
					display={false}
				/>
			</div>
		)
	}
}

export default PutInOrderInputArea