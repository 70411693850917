import * as React from "react"

const WritingSampleSvg = ({color}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 5.24 5.88"
    >
        <defs>
            <clipPath id="writing_sample_svg_a">
                <rect fill="none" width={4.77} height={5.88} rx={0.13} />
            </clipPath>
            <clipPath id="writing_sample_svg_b">
                <rect fill="none" x={0.55} y={3.31} width={1.97} height={1.99} />
            </clipPath>
        </defs>
        <g>
            <g>
                <path
                    fill={color}
                    d="M5.16,2.7h0L4.2,2.28a.08.08,0,1,1,.07-.15l.92.41a.08.08,0,0,1,0,.11.08.08,0,0,1-.07.05Z"
                />
                <g>
                    <path
                        fill="none"
                        d="M3.71,3.94l.9-2a.13.13,0,0,1,.17-.07L5.06,2a.13.13,0,0,1,.07.17l-.91,2a.12.12,0,0,1-.17.07l-.28-.13A.13.13,0,0,1,3.71,3.94Z"
                    />
                    <path
                        fill={color}
                        d="M3.72,3.91l.88-2a.16.16,0,0,1,.22-.08L5,1.94a.17.17,0,0,1,.08.22l-.87,2A.17.17,0,0,1,4,4.22l-.21-.09A.17.17,0,0,1,3.72,3.91ZM5,2.1,4.75,2,3.87,4l.22.09Z"
                    />
                </g>
                <g>
                    <path
                        className="cls-1"
                        d="M5.11,2.1l-.46-.2.08-.18a.25.25,0,0,1,.34-.11.26.26,0,0,1,.12.31Z"
                    />
                    <path
                        fill={color}
                        d="M5,1.88v0A.09.09,0,0,0,5,1.74a.1.1,0,0,0-.1,0v0L5,1.88m.09.22-.46-.2.08-.18a.24.24,0,0,1,.33-.12.26.26,0,0,1,.13.32Z"
                    />
                </g>
                <path
                    fill={color}
                    d="M4.68,3.77a.09.09,0,0,1-.08-.09,0,0,0,0,1,0,0l.45-1a.08.08,0,1,1,.15.07l-.45,1A.08.08,0,0,1,4.68,3.77Z"
                />
                <g>
                    <g>
                        <path
                            className="cls-1"
                            d="M3.62,4.82c-.06,0-.09-.12,0-.28a.36.36,0,0,0,0-.1L3.7,4l.5.22-.29.38-.06.08C3.77,4.81,3.67,4.85,3.62,4.82Z"
                        />
                        <path
                            fill={color}
                            d="M3.68,4.69a.47.47,0,0,0,.06-.09.86.86,0,0,1,.07-.1L4,4.25l-.21-.1-.07.32a.5.5,0,0,1,0,.12.36.36,0,0,0,0,.1m-.06.14c-.06,0-.09-.12,0-.28a.36.36,0,0,0,0-.1L3.7,4l.5.22-.29.38-.06.09c-.08.15-.18.19-.23.16Z"
                        />
                    </g>
                    <path
                        fill={color}
                        d="M3.9,4.55h0l-.24-.1s0,0,0-.06a0,0,0,0,1,.05,0l.24.11a0,0,0,0,1,0,0S3.92,4.55,3.9,4.55Z"
                    />
                </g>
            </g>
            <g>
                <g clipPath="url(#writing_sample_svg_a)">
                    <g>
                        <g>
                            <path
                                fill="#FFF"
                                d="M4.3,5.07a.37.37,0,0,1-.36.39H.84a.37.37,0,0,1-.36-.39h0V.89A.37.37,0,0,1,.84.5H3.93A.37.37,0,0,1,4.3.88h0v1h.07v-1A.46.46,0,0,0,3.93.42H.84A.46.46,0,0,0,.4.89h0V5.07a.45.45,0,0,0,.44.46H3.93a.44.44,0,0,0,.44-.46h0V4.43H4.3Z"
                            />
                            <path
                                id="Path_9599_-_Outline"
                                data-name="Path 9599 - Outline"
                                fill={color}
                                d="M3.93,5.61H.84a.53.53,0,0,1-.52-.54V.89A.54.54,0,0,1,.84.34H3.93a.54.54,0,0,1,.53.55V2H4.22V.88a.3.3,0,0,0-.29-.3H.84A.29.29,0,0,0,.56.89h0V5.08a.29.29,0,0,0,.28.3H3.93a.31.31,0,0,0,.29-.31h0V4.35h.24v.72a.54.54,0,0,1-.53.54Z"
                            />
                            <path
                                fill={color}
                                d="M3.93,5.61H.84a.53.53,0,0,1-.52-.54V.89A.54.54,0,0,1,.84.34H3.93a.54.54,0,0,1,.53.55V2H4.22V.88a.3.3,0,0,0-.29-.3H.84A.29.29,0,0,0,.56.89h0V5.08a.29.29,0,0,0,.28.3H3.93a.31.31,0,0,0,.29-.31h0V4.35h.24v.72a.54.54,0,0,1-.53.54Z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
        <g>
            <path
                fill={color}
                d="M3.59,1H1.12a.08.08,0,1,1,0-.16H3.59a.08.08,0,1,1,0,.16Z"
            />
            <path
                fill={color}
                d="M1.12,1.49A.09.09,0,0,1,1,1.41V.91A.08.08,0,0,1,1.12.83.07.07,0,0,1,1.2.91v.5A.08.08,0,0,1,1.12,1.49Z"
            />
            <path
                fill={color}
                d="M3.59,1.49H1.12A.09.09,0,0,1,1,1.41a.09.09,0,0,1,.08-.09H3.59a.09.09,0,0,1,.08.09A.09.09,0,0,1,3.59,1.49Z"
            />
            <path
                fill={color}
                d="M3.42,1.82h-2a.08.08,0,0,1-.08-.08.07.07,0,0,1,.08-.08h2a.07.07,0,0,1,.08.08A.08.08,0,0,1,3.42,1.82Z"
            />
            <path
                fill={color}
                d="M3.92,2.32h-2a.09.09,0,0,1-.08-.09.09.09,0,0,1,.08-.08h2A.09.09,0,0,1,4,2.23.09.09,0,0,1,3.92,2.32Z"
            />
            <path
                fill={color}
                d="M2.27,2.81H1.12A.08.08,0,0,1,1,2.73a.09.09,0,0,1,.08-.08H2.27a.08.08,0,0,1,.08.08A.07.07,0,0,1,2.27,2.81Z"
            />
            <path
                fill={color}
                d="M3.42,2.81H2.6a.08.08,0,0,1-.08-.08.09.09,0,0,1,.08-.08h.82a.08.08,0,0,1,.08.08A.07.07,0,0,1,3.42,2.81Z"
            />
            <path
                fill={color}
                d="M1.78,3.31H1.12A.09.09,0,0,1,1,3.23a.09.09,0,0,1,.08-.09h.66a.09.09,0,0,1,.08.09A.09.09,0,0,1,1.78,3.31Z"
            />
            <path
                fill={color}
                d="M2.93,3.31H2.11A.08.08,0,0,1,2,3.23a.08.08,0,0,1,.08-.09h.82A.09.09,0,0,1,3,3.23.09.09,0,0,1,2.93,3.31Z"
            />
        </g>
        <g>
            <g>
                <g clipPath="url(#writing_sample_svg_b)">
                    <g>
                        <g>
                            <path
                                fill="#5e5f5f"
                                d="M1.53,3.64a.67.67,0,1,0,.66.66h0v0l-.08,0h0a.6.6,0,0,1-.58.59.59.59,0,0,1,0-1.17.69.69,0,0,1,.24,0,.17.17,0,0,1,.06-.06.82.82,0,0,0-.3-.07"
                            />
                            <path
                                id="Path_9601_-_Outline"
                                data-name="Path 9601 - Outline"
                                fill="#39a949"
                                d="M1.53,5a.71.71,0,0,1-.7-.71.7.7,0,0,1,.7-.7h0a.65.65,0,0,1,.31.08l.06,0,0,0L1.8,3.8l0,0h0a.54.54,0,1,0-.43,1A.52.52,0,0,0,2,4.52a.54.54,0,0,0,0-.22v0h0s0,0,.07,0l.06,0V4.3A.7.7,0,0,1,1.53,5Z"
                            />
                        </g>
                    </g>
                </g>
            </g>
            <g>
                <path
                    fill="#39a949"
                    d="M1.51,4.67a.07.07,0,0,1-.06,0L1.21,4.4a.08.08,0,0,1,.12-.11l.24.24a.08.08,0,0,1,0,.11A.09.09,0,0,1,1.51,4.67Z"
                />
                <path
                    fill="#39a949"
                    d="M1.51,4.67a.08.08,0,0,1-.08-.09.11.11,0,0,1,0,0l.36-.48a.08.08,0,0,1,.11,0,.07.07,0,0,1,0,.11h0l-.36.48A.08.08,0,0,1,1.51,4.67Z"
                />
            </g>
        </g>
        <path
            fill={color}
            d="M3.19,5.06H3.1A.14.14,0,0,1,3,5C3,5,3,4.86,3,4.72H3L3,4.8l-.08.12s-.12.15-.2.11-.07-.18,0-.42a1.22,1.22,0,0,1,0-.17,1.37,1.37,0,0,0-.15.21c-.09.14-.16.27-.16.27a.08.08,0,1,1-.14-.08s.08-.15.17-.29.27-.33.33-.34a.14.14,0,0,1,.09,0c.05.06,0,.18,0,.41l0,.11,0,0a.34.34,0,0,1,.3-.21.13.13,0,0,1,.07,0s0,.1,0,.21,0,.07,0,.11L3.4,4.7a.26.26,0,0,1,.19-.06.08.08,0,0,1,.1.06.09.09,0,0,1-.06.1h0l-.09,0Z"
        />
    </svg>
)

export default WritingSampleSvg
