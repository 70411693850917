import React, { useContext, useEffect, useRef } from 'react'
import structureStyle from './../StructuredComponents.module.scss'
import componentStyle from './BubbleImageTemplate.module.scss'
import colorsContext from '../../../../contexts/ColorsContext'
import StructureTitleComponent from '../Components/StructureTitleComponent/StructureTitleComponent'
import classNames from 'classnames'
import StructureImageComponent from '../Components/StructureImageComponent/StructureImageComponent'
import StructureTextUseCase from '../../../../useCase/StructureUseCase/StructureTextUseCase'
import HtmlTextWrapper from '../../../../components/HtmlTextWrapper/HtmlTextWrapper'
import { isEmpty, isNumber } from 'lodash'
import { moreOptionsList } from '../../../../data'
import BubbleSvg from '../../../../icons/BubbleSvg'
import structuresContext from '../../../../contexts/StructuresContext'
import { robotTeacherScrollIntoViewByElement } from '../../../../operations/robotTeacherScrollIntoViewByElement'
import { useSelector } from 'react-redux'

const bubbleDescriptionsContainerClassName = {
	ONE_COLUMN: componentStyle.bubbleImageOneColumnContainer,
	TWO_COLUMN: componentStyle.bubbleImageTwoColumnContainer,
}

export const BubbleImageDescriptions = ({ obj, i }) => {
	const { colors } = useContext(colorsContext)
	const elRef = useRef(null)
	const { scrolling } = useSelector(state => state.roboTeacher)
	const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
	const isHidden = isRoboTeacher ?? false ? (roboTeacherData.currentTime ?? 0) < (obj?.text.hiddenTime ?? 0) : false

	useEffect(() => {
		if (obj?.text?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
			robotTeacherScrollIntoViewByElement(elRef?.current)
		}
	}, [isHidden])

	return (
		<div ref={elRef} className={classNames(structureStyle.roboTeacherView, { ['hide-element']: isHidden })}>
			<span className={componentStyle.bubble}>
				<BubbleSvg color={colors['C566']} />
				<span className={componentStyle.content} style={{ color: colors['C160'] }}>
					{i + 1}
				</span>
			</span>
			<HtmlTextWrapper
				style={{ display: 'inline' }}
				data={obj.text}
				moreOptionsList={moreOptionsList}
				textColor={colors['C23']}
				optionsColor={colors['C23']}
				showPhraseOptions
			/>
		</div>
	)
}

const BubbleImageTemplate = ({ template }) => {
	const { colors } = useContext(colorsContext)
	const elRef = useRef(null)
	const { scrolling } = useSelector(state => state.roboTeacher)
	const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
	const isHidden = isRoboTeacher ?? false ? (roboTeacherData.currentTime ?? 0) < (template?.hiddenTime ?? 0) : false

	useEffect(() => {
		if (template?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
			robotTeacherScrollIntoViewByElement(elRef?.current)
		}
	}, [isHidden])

	return (
		<div className="container container-1">
			<div
				ref={elRef}
				data-rt-id={template?.id}
				className={classNames(
					structureStyle.templateContainer,
					{ [structureStyle.border]: !isEmpty(template?.config?.borderColor) },
					{ ['hide-element']: isHidden },
				)}
				style={{
					background: isEmpty(template?.config?.backgroundColor) ? colors['C194'] : template.config.backgroundColor,
					borderColor: template?.config?.borderColor,
				}}>
				{template.data.title?.sentence && <StructureTitleComponent text={template.data.title} />}
				{template.data.picture && (
					<div className={componentStyle.imageContainer}>
						<StructureImageComponent picture={template.data.picture} />
					</div>
				)}
				<div
					className={classNames(
						componentStyle.bubbleDescriptionsContainer,
						bubbleDescriptionsContainerClassName[template.config.displayMode],
					)}>
					{template.data.bubbleDescriptions
						.filter(obj => !isEmpty(obj?.text?.sentence))
						.map((obj, i) => {
							return <BubbleImageDescriptions key={i} obj={obj} i={i} />
						})}
				</div>
				{template.data.texts.map((text, i) => {
					return <StructureTextUseCase key={i} textData={text} />
				})}
			</div>
		</div>
	)
}

export default BubbleImageTemplate
