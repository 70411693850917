import serviceCall from "../../services";
import matchingSentenceMapper from "../../services/mapper/sections/matchingSentenceMapper";
import matchingWordByWordMapper from "../../services/mapper/sections/matchingWordByWordMapper";
import { answerComparator } from "../../util/answerComparator/answerComparator";

const matchingExactUseCase = async (
	{
		matchType,
		inputType,
		sectionType,
		toolsCheck,
		unitId,
		trackerNumber,
		matchCases,
		isVoiceRecognition,
		quizIndex,
		dialogNumber
	}
) => {
	const res = await serviceCall(
		'checkSection',
		{
			matchType,
			sectionType,
			toolsCheck,
			unitId,
			trackerNumber,
			matchCases,
			isVoiceRecognition,
			inputType
		},
		'cloud',
	)

	const result = {
		data: res,
		quizIndex: quizIndex ?? null,
		dialogNumber: dialogNumber ?? null,
	}

	switch (inputType) {
		case 'SENTENCE_MATCH':
			return matchingSentenceMapper(result)
		case 'SENTENCE_WORD_BY_WORD_MATCH':
			if (isVoiceRecognition) {
				const modifiedUserAnswer = answerComparator(
					res?.exerciseNormalizedDTO?.normalizedSystemAnswer,
					res?.exerciseNormalizedDTO?.normalizedUserAnswer,
					res?.exerciseNormalizedDTO?.specialNamesCount
				)

				result.data.matchResultDtos = [{
					correctAnswer: res?.exerciseNormalizedDTO?.normalizedSystemAnswer,
					wordByWordResult: modifiedUserAnswer?.wordByWordResult,
					userAnswerStatus: modifiedUserAnswer?.status,
					correctPercentage: modifiedUserAnswer.correctPercentage
				}]
			}

			return matchingWordByWordMapper(result)
		default:
			return result
	}
}

export default matchingExactUseCase 