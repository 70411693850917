import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import serviceCall from "../../services";

const initialState = {
  loading: false,
  data: [],
  error: ''
}

export const fetchArchive = createAsyncThunk(
  'archive/fetchArchive',
  () => {
    return serviceCall('fetchArchive', "cloud")
  }
)

export const renameFile = createAsyncThunk(
  'archive/renameFile',
  ({fileId, newName}) => {
    return serviceCall(
      'renameArchiveFile',
      {fileId, newName},
      "cloud"
    )
  }
)

export const destroyArchiveFile = createAsyncThunk(
  'conversation/destroyArchiveFile',
  ({fileId, unitId}) => {
    return serviceCall(
      'deleteArchiveFile',
      {
        fileId,
        sectionType: "CONVERSATION",
        unitId
      },
      "cloud"
    )
  }
)

const archiveSlice = createSlice({
  name: 'archive',
  initialState,
  reducers: {
    renameArchiveFile: (state, action) => {
      state.data = [...state.data].map((obj) => {
        if (obj.id === action.payload.fileId) {
          return {
            ...obj,
            fileName: action.payload.newName
          }
        } else {
          return obj
        }
      })
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArchive.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchArchive.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data
    })
    builder.addCase(fetchArchive.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(renameFile.pending, (state) => {
      state.loading = true
    })
    builder.addCase(renameFile.fulfilled, (state, action) => {
      state.data = [...state.data].map(it => {
        if (it.id === action.payload?.data?.id) {
          return action.payload?.data
        } else {
          return it
        }
      })
      state.loading = false
    })
    builder.addCase(renameFile.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(destroyArchiveFile.pending, (state) => {
      state.loading = true
    })
    builder.addCase(destroyArchiveFile.fulfilled, (state, action) => {
      state.data = [...state.data].filter(obj => obj.id !== action.payload.fileId)
      state.loading = false
    })
    builder.addCase(destroyArchiveFile.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const {renameArchiveFile} = archiveSlice.actions

export default archiveSlice.reducer