const phraseTableMapper = (data, baseData) => {
  const tableBodyRows = data.map(item => {
    const partOfSpeech = item.partOfSpeeches?.map(ps => ps?.partOfSpeech ?? '')?.join('')

    return {
      id: item.index,
      columns: [
        {
          text: {
            sentence: item.unitId ?? '',
          },
          isTranslate: false,
        },
        {
          text: {
            sentence: item.title ?? ''
          },
          isTranslate: false,
        },
        {
          text: {
            sentence: item.translation ?? ''
          },
          isTranslate: true
        },
      ],
    }
  })

  if (baseData) {
    return {
      ...baseData,
      tableBodyRows: [...baseData.tableBodyRows, ...tableBodyRows]
    }
  }
  return {
    tableHeaderRows: [
      {
        text: {
          sentence: 'Unit',
        },
        isTranslate: false
      },
      {
        text: {
          sentence: 'Phrases',
        },
        isTranslate: false
      },
      {
        text: {
          sentence: 'Translation',
        },
        isTranslate: true
      },
    ],
    tableColumnsConfig: [
      {isCenter: true, isTranslate: false},
      {isCenter: true, isTranslate: false},
      {isCenter: true, isTranslate: true},
    ],
    tableBodyRows,
  }
}

export default phraseTableMapper