import serviceCall from "../../services";

const deleteUserAccountUseCase = async (data) => {
  return await serviceCall(
    'postDeleteUserAccount',
    data,
    'cloud'
  )
}

export default deleteUserAccountUseCase