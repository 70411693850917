import * as React from "react"

const ReadingSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style type="text/css">
            {
                ".ReadingSvg-st0{fill:none;stroke-width:37;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"
            }
        </style>
        <g>
            <g>
                <line className="ReadingSvg-st0" stroke={color} x1={104.4} y1={168} x2={207.4} y2={197} />
                <line className="ReadingSvg-st0" stroke={color} x1={100.6} y1={261.9} x2={203.6} y2={290.9} />
                <line className="ReadingSvg-st0" stroke={color} x1={405} y1={168.7} x2={302} y2={197.7} />
                <line className="ReadingSvg-st0" stroke={color} x1={409.1} y1={265.7} x2={306.1} y2={294.7} />
                <g>
                    <polyline
                        className="ReadingSvg-st0" stroke={color}
                        points="466.5,125.5 507.3,125.2 507.3,428.5 10.3,428.5 10.3,125.2 51.6,125.2  "
                    />
                    <path
                        className="ReadingSvg-st0" stroke={color}
                        d="M257.5,394l1.3-262l54.1-23.7c35.5-15.5,75.1-19.5,112.9-11.2l29.7,6.5v284.8l-59.8-6.9 c-29-3.3-58.4,0.6-85.5,11.4L256,414.7L196.5,391c-28.8-11.5-60.2-15.1-90.9-10.6l-46.1,6.8V98.5L89.7,93 c30.8-5.6,62.5-3.1,92.1,7.2l71.3,24.9"
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default ReadingSvg