import { useContext, useEffect, useState } from 'react'
import componentStyle from './RoboTeacherNavigationModal.module.scss'
import Modal from "../Modal";
import SecondaryButton from "../../atomicComponents/atoms/SecondaryButton/SecondaryButton";
import colorsContext from "../../contexts/ColorsContext";
import {updateRoboTeacherCurrentDataStatus} from "../../redux/features/roboTeacherSlice";
import {ENGLISH, NATIVE} from "../../enums/roboTeacher/dataStatusEnums";
import {useNavigate} from "react-router-dom";
import useAppUser from "../../hooks/useAppUser";
import {useDispatch, useSelector} from "react-redux";
import {translationsLanguagesData} from "../../enums/languageEnums/languageEnums";
import UnitDataStorage from "../../classes/UnitDataStorage";
import {setPreviousUnitNumber} from "../../redux/features/sharedSectionSlice";
import TranslatedText from '../TranslatedText/TranslatedText'
import useTranslatedText from "../../hooks/useTranslatedText";

const RoboTeacherNavigationModal = ({ unit }) => {
	const { colors } = useContext(colorsContext)
	const [isOpen, setIsOpen] = useState(false)
	const [offsetTop, setOffsetTop] = useState(0)
	const [offsetLeft, setOffsetLeft] = useState(0)
	const { previousUnitNumber } = useSelector(state => state.sharedSection)
	const navigate = useNavigate()
	const { appUserData } = useAppUser()
	const dispatch = useDispatch()
	const {dir} = useTranslatedText()

	useEffect(() => {
		const roboTeacherButton = document.getElementById('robo_teacher_button')

		if (roboTeacherButton) {
			setOffsetTop(roboTeacherButton.offsetTop - 7)
			setOffsetLeft(roboTeacherButton.offsetLeft - 7)

			const unitDataStorage = new UnitDataStorage()
			const unitData = unitDataStorage.getUnitData()

			if (unitData.unitNumber !== previousUnitNumber) {
				setIsOpen(true)
				dispatch(setPreviousUnitNumber(unitData.unitNumber))
			}
		}
	}, [])

  return (
    <>
      {isOpen &&
        <Modal closeModal={() => setIsOpen(false)}>
          <div
            className={componentStyle.headRobo}
            style={{
              backgroundColor: colors['C73'], borderColor: colors['C590'],
              top: offsetTop, left: offsetLeft
            }}
          >
            <img
              className={componentStyle.imageHeadRobo}
              src={`${window.location.origin}/assets/images/headRoboTeacher.png`} alt={'Robo Teacher'}
            />
          </div>
          <div className={componentStyle.container}>
            <div className={componentStyle.picture}>
              <img src={`${window.location.origin}/assets/images/robotTeacher.png`} alt={'Robo Teacher'}/>
            </div>
            <div className={componentStyle.card} style={{background: colors['C183'], color: colors['C2']}}>
              <div dir={dir} className={componentStyle.textCard}>
                <TranslatedText translationKey={'get.started.robo.teacher'}/>
              </div>
              <div className={componentStyle.buttons}>
                <SecondaryButton
                  onClick={() => {
                    dispatch(updateRoboTeacherCurrentDataStatus(NATIVE))
                    navigate('/sections/roboTeacher/' + unit)
                  }}
                >
                  <div className={componentStyle.flag}>
                    <img
                      src={`${window.location.origin}/assets/images/languages/${appUserData?.setting?.nativeLanguageFlagKey}.svg`}
                      alt=""
                    />
                  </div>
                  <TranslatedText translationKey={'yes.play'}/> {translationsLanguagesData[appUserData?.setting?.nativeLanguage]?.title}
                </SecondaryButton>
                <SecondaryButton
                  onClick={() => {
                    dispatch(updateRoboTeacherCurrentDataStatus(ENGLISH))
                    navigate('/sections/roboTeacher/' + unit)
                  }}
                >
                  <div className={componentStyle.flag}>
                    <img
                      className={componentStyle.flag}
                      src={`${window.location.origin}/assets/flags/gb.svg`}
                      alt=""
                    />
                  </div>
                  <TranslatedText translationKey={'yes.play.english'}/>
                </SecondaryButton>
                <button
									className={componentStyle.skipButton}
                  onClick={() => setIsOpen(false)}
                  style={{color: colors['C2']}}
                >
                  <TranslatedText translationKey={'skip'}/>
                </button>
              </div>
            </div>
          </div>
        </Modal>
      }
    </>
  )
}

export default RoboTeacherNavigationModal
