import * as React from "react"

const GameSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style type="text/css">
            {
                ".GameSvg-st0{stroke-width:12.7412;stroke-miterlimit:10;}"
            }
        </style>
        <g>
            <g>
                <g>
                    <path
                        className="GameSvg-st0" stroke={color}
                        d="M503.7,318.6c-1.3-28.9-6.2-61.1-13.5-91.2c-7.4-28.9-16.7-56.1-27.8-76.7c-43.2-77.4-90.3-65.5-146.4-50.4 c-17.9,4.4-37,9.4-56.8,11.4h-6.2c-19.7-1.9-38.9-7-56.8-11.4C139.9,85.7,93,73.1,49.6,151.2c-11.1,20.8-21,47.8-27.8,76.8 c-7.4,30.2-12.3,62.3-13.5,91.2c-1.3,32,1.9,55.4,8.6,71.7c6.9,15.1,16.7,24.5,29.6,28.3c11.8,3.1,25.3,1.9,39.5-4.4 c24-10.1,51.8-31.5,79.1-57.2c19.1-18.9,55-28.3,90.8-28.3s71.6,9.4,90.8,28.3c27.2,25.8,55,47.2,79.1,57.2 c14.2,5.7,27.8,7.5,39.5,4.4c12.3-3.8,22.9-12.5,29.6-28.9C502,374.6,505,351.3,503.7,318.6z M474.1,381.5 c-3.7,8.8-8.6,13.8-14.8,15.8c-6.9,1.9-15.5,0.6-25.3-3.1c-21.7-8.8-46.9-28.3-71.6-52.2C338.9,318,297.5,306,256.1,306 s-82.7,12-106.8,34.6c-25.3,23.9-50.7,44-71.6,52.2c-9.9,3.8-18.5,5.7-25.3,3.1c-6.2-1.9-11.2-7-14.8-15.8 c-4.9-12-7.4-31.5-6.2-60.4c1.3-27.1,5.6-57.9,12.9-86.8c6.9-27.1,15.5-52.2,26-71.2c33.9-62.3,73.5-52.2,120.5-39.7 c19.1,5,38.9,10.1,60.5,12c0.6,0,0.6,0,1.3,0h6.9c0.6,0,0.6,0,1.3,0c22.2-1.9,42-7,61.2-12c46.9-12,86.4-22.6,120.5,39.7 c10.5,18.9,19.1,43.4,26,71.2c6.9,28.9,11.8,59.2,12.9,86.8C482.1,348.8,479.7,368.4,474.1,381.5z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="GameSvg-st0" stroke={color}
                        d="M199.2,195.9c-6.2-5.7-13.5-10.1-22.2-10.7c-0.6-8.1-4.3-16.4-9.9-22.1l-0.6-0.6c-6.9-7-15.5-10.7-25.3-10.7 c-9.9,0-19.1,4.4-25.3,10.7c-5.6,5.7-9.9,13.8-10.5,22.6c-8.6,0.6-16.1,4.4-22.2,10.1l-0.6,0.6c-6.9,7-10.5,15.8-10.5,25.8 c0,10.1,4.3,19.5,10.5,25.8c6.2,6.3,13.5,10.1,22.9,10.7c0.6,8.8,4.3,17,10.5,22.6c6.9,7,15.5,10.7,25.3,10.7 c9.9,0,19.1-4.4,25.3-10.7c5.6-6.3,9.9-13.8,10.5-22.6c8.6-0.6,16.7-4.4,22.2-10.7c6.9-7,10.5-15.8,10.5-25.8 C209.7,211.6,205.4,202.1,199.2,195.9z M183.8,231.1c-2.5,2.5-5.6,3.8-9.2,3.8h-8.7c-6.2,0-11.8,5-11.8,12v8.1 c0,3.8-1.3,7-3.7,9.4c-2.5,2.5-5.6,3.8-9.2,3.8c-3.6,0-6.9-1.3-9.2-3.8c-2.5-2.5-3.7-5.7-3.7-9.4v-8.8c0-6.3-4.9-12-11.8-12h-8.6 c-3.7,0-6.9-1.3-9.2-3.8c-1.9-1.9-3.7-5-3.7-8.8c0-3.8,1.3-7,3.7-9.4c0,0,0,0,0.6-0.6c2.5-1.9,5.6-3.1,8.6-3.1h8.6 c6.2,0,11.8-5,11.8-12v-8.8c0-3.8,1.3-7,3.7-9.4c2.5-2.5,5.6-3.8,9.2-3.8c3.6,0,6.9,1.3,9.2,3.8c0,0,0,0,0.6,0.6 c1.9,2.5,3,5.7,3,8.8v8.8c0,6.3,4.9,12,11.8,12h8.6c3.7,0,6.9,1.3,9.2,3.8c2.5,2.5,3.7,5.7,3.7,9.4 C187.4,225.5,186.2,228.6,183.8,231.1z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <ellipse className="GameSvg-st0" stroke={color} cx={363.5} cy={176.9} rx={21.7} ry={22.1} />
                </g>
            </g>
            <g>
                <g>
                    <ellipse className="GameSvg-st0" stroke={color} cx={363.5} cy={266.3} rx={21.7} ry={22.1} />
                </g>
            </g>
            <g>
                <g>
                    <ellipse className="GameSvg-st0" stroke={color} cx={319.7} cy={221.6} rx={21.7} ry={22.1} />
                </g>
            </g>
            <g>
                <g>
                    <ellipse className="GameSvg-st0" stroke={color} cx={407.4} cy={221.6} rx={21.7} ry={22.1} />
                </g>
            </g>
        </g>
    </svg>
)

export default GameSvg
