import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getGatewayUseCase from "../../useCase/payment/getGatewayUseCase";
import subscriptionIranUseCase from "../../useCase/payment/subscriptionIranUseCase";
import paymentConfirmStripeUseCase from "../../useCase/payment/paymentConfirmStripeUseCase";
import subscriptionPayPalUseCase from "../../useCase/payment/subscriptionPayPalUseCase";
import subscriptionStripeUseCase from "../../useCase/payment/subscriptionStripeUseCase";
import subscriptionGoogleUseCase from "../../useCase/payment/subscriptionGoogleUseCase";

const initialState = {
	loading: false,
	error: '',
	gateway: null,
	subscriptionIranResponse: null,
	subscriptionPayPalResponse: null,
	subscriptionStripeResponse: null,
	confirmStripeResponse: null
}

export const getChooseGateway = createAsyncThunk(
	'chooseGateway/getChooseGateway',
	() => getGatewayUseCase(),
)

export const subscriptionIranPay = createAsyncThunk(
	'chooseGateway/subscriptionIranPay',
	({ asRenewal, discountCode, planId, psp, purchaseType }) => subscriptionIranUseCase({ asRenewal, discountCode, planId, psp, purchaseType }),
)

export const subscriptionPayPal = createAsyncThunk(
	'chooseGateway/subscriptionPayPal',
	({ asRenewal, discountCode, planId, psp, purchaseType }) => subscriptionPayPalUseCase({ asRenewal, discountCode, planId, psp, purchaseType }),
)

export const subscriptionStripe = createAsyncThunk(
	'chooseGateway/subscriptionStripe',
	({ asRenewal, discountCode, planId, psp, paymentMethodId, purchaseType }) => subscriptionStripeUseCase({ asRenewal, discountCode, planId, psp, paymentMethodId, purchaseType }),
)

export const subscriptionGoogle = createAsyncThunk(
	'chooseGateway/subscriptionGoogle',
	(
		{ psp, planId, asRenewal, purchaseType, purchaseToken }
	) => subscriptionGoogleUseCase({
		psp, planId, asRenewal, purchaseType, purchaseToken
	}),
)

export const confirmStripe = createAsyncThunk(
	'chooseGateway/confirmStripe',
	({ paymentIntentId }) => paymentConfirmStripeUseCase({ paymentIntentId }),
)

const chooseGatewaySlice = createSlice({
	name: 'chooseGateway',
	initialState,
	reducers: {
		clearSubscription: (state, action) => {
			state.subscriptionIranResponse = null
			state.subscriptionPayPalResponse = null
			state.subscriptionStripeResponse = null
			state.confirmStripeResponse = null
		}
	},
	extraReducers: builder => {
		//get Choose Gateway
		builder.addCase(getChooseGateway.pending, (state) => {
			state.loading = true
		})
		builder.addCase(getChooseGateway.fulfilled, (state, action) => {
			state.loading = false
			state.gateway = action.payload
		})
		builder.addCase(getChooseGateway.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message
		})

		//subscription Iran
		builder.addCase(subscriptionIranPay.pending, (state) => {
			state.loading = true
		})
		builder.addCase(subscriptionIranPay.fulfilled, (state, action) => {
			state.loading = false
			state.subscriptionIranResponse = action.payload
		})
		builder.addCase(subscriptionIranPay.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message
		})

		//subscription paypal
		builder.addCase(subscriptionPayPal.pending, (state) => {
			state.loading = true
		})
		builder.addCase(subscriptionPayPal.fulfilled, (state, action) => {
			state.loading = false
			state.subscriptionPayPalResponse = action.payload
		})
		builder.addCase(subscriptionPayPal.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message
		})

		//subscription stripe
		builder.addCase(subscriptionStripe.pending, (state) => {
			state.loading = true
		})
		builder.addCase(subscriptionStripe.fulfilled, (state, action) => {
			state.loading = false
			state.subscriptionStripeResponse = action.payload
		})
		builder.addCase(subscriptionStripe.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message
		})

		//confirm
		builder.addCase(confirmStripe.pending, (state) => {
			state.loading = true
		})
		builder.addCase(confirmStripe.fulfilled, (state, action) => {
			state.loading = false
			state.confirmStripeResponse = action.payload
		})
		builder.addCase(confirmStripe.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message
		})
	}
})

export const {
	clearSubscription
} = chooseGatewaySlice.actions

export default chooseGatewaySlice.reducer