import React, {useContext, useEffect} from 'react'
import exerciseStyles from './../ExerciseModules.module.scss'
import {useDispatch, useSelector} from 'react-redux'
import {
  checkExerciseItem, goToNextItem, setExerciseItemUpdated, toggleLockPaginable,
} from '../../../redux/features/exerciseSlice'
import PaginableExerciseModule from '../PaginableExerciseModule'
import classNames from 'classnames'
import colorsContext from '../../../contexts/ColorsContext'
import getUserAnswerStatusBorderColor from '../../../operations/getUserAnswerStatusBorderColor'
import PutInOrderChoices from './Components/PutInOrderChoices'
import ExerciseItemCheck from '../../../components/ExerciseItemCheck/ExerciseItemCheck'
import Lock from '../../../components/Lock/Lock'
import PutInOrderGivenInputTypeInline from './Components/PutInOrderGivenInputTypeInline'
import PutInOrderGivenInputTypeSeparated from './Components/PutInOrderGivenInputTypeSeparated'
import GivenTemplate from '../../../components/GivenTemplate/GivenTemplate'
import ExerciseAIAssistantButton from "../../../components/ExerciseAIAssistantButton/ExerciseAIAssistantButton";
import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const PutInOrderModule = () => {
  const {colors} = useContext(colorsContext)
  const dispatch = useDispatch()
  const {
    data,
    isSingleTemplate,
    currentItem,
    checkingItem,
    exerciseItemUpdated
  } = useSelector((state) => state.exercise)

  useEffect(() => {
    if (exerciseItemUpdated) {
      if (data.immediateCheck) {
        const currentEnableStack = data.stacks[currentItem.stackIndex]
        const currentEnableItem = currentEnableStack.items[currentItem.itemIndex]

        const allChoicesAreSelected = currentEnableItem?.choices?.every(it => it.selected)

        if (allChoicesAreSelected) {
          onCheckItem(currentEnableStack?.id, currentEnableItem, true)
        }
      }
      dispatch(setExerciseItemUpdated(false))
    }
  }, [exerciseItemUpdated])

  const onCheckItem = (stackId, exerciseItem, enabled) => {
    if (enabled && !checkingItem) {
      const skipped = exerciseItem.userChoices.length !== exerciseItem.choices.length
      const userAnswerData = {
        exerciseStackId: stackId,
        userAnswerItems: [
          {
            exerciseItemId: exerciseItem.id,
            skipped,
            userAnswers: skipped ? []
              : exerciseItem.userChoices.map(item => ({
                listIndex: 0,
                index: item.index,
              })),
          },
        ],
      }
      dispatch(checkExerciseItem({userAnswerData})).then((res) => {
        if (res.payload?.status === 200 && !isSingleTemplate) {
          dispatch(goToNextItem())
        }
      })
    }
  }

  const renderExerciseItem = (exerciseItem, exerciseItemClass, stackIndex, itemIndex, enabled) => {
    return (
      <div
        className={classNames(
          exerciseStyles.putInOrderCard, exerciseItemClass,
          {
            [exerciseStyles.cardDisabled]: !enabled && !exerciseItem.checked,
            [exerciseStyles.checked]: exerciseItem.checked,
          },
        )}
        style={{
          background: exerciseItem.checked ? colors['C516'] : colors['C62'],
          borderColor: exerciseItem.checked
            ? colors[getUserAnswerStatusBorderColor(exerciseItem.userAnswerStatus)]
            : 'transparent',
        }}
        key={itemIndex}
      >
        {(exerciseItem.given.voiceUri && !exerciseItem.checked) && <GivenTemplate exerciseItem={exerciseItem}/>}
        <div className='d-flex'>
          <div
            className={exerciseStyles.cardNumberBadge}
            style={{
              background: colors['C144'], color: colors['C233'],
              borderColor: exerciseItem.checked
                ? colors[getUserAnswerStatusBorderColor(exerciseItem.userAnswerStatus)]
                : 'transparent',
            }}
          >
            {itemIndex + 1}
          </div>
          <div>
            {exerciseItem.inputIsInsideGiven ?
              <PutInOrderGivenInputTypeInline
                exerciseItem={exerciseItem}
                stackIndex={stackIndex}
                itemIndex={itemIndex}
                enabled={enabled}
              /> :
              <PutInOrderGivenInputTypeSeparated
                exerciseItem={exerciseItem}
                stackIndex={stackIndex}
                itemIndex={itemIndex}
                enabled={enabled}
              />
            }
          </div>
        </div>
        {exerciseItem.checked ?
          exerciseItem.userAnswerStatus === AnswerStatusEnums.INCORRECT && (
            <div className={exerciseStyles.exerciseCardFooter}>
              <div className='d-flex gap-3 align-items-center'>
                <ExerciseAIAssistantButton
                  exerciseItemId={exerciseItem.id}
                  correctAnswer={exerciseItem.fullTextAnswers?.[0]?.sentenceString}
                  userAnswer={exerciseItem.userAnswer}
                />
                <Lock
                  onClickLock={() => dispatch(toggleLockPaginable({stackIndex, itemIndex}))}
                  isLocked={!exerciseItem.unlocked}
                />
              </div>
            </div>
          ) : <>
            <div className={exerciseStyles.phraseCardList}>
              <PutInOrderChoices
                stackIndex={stackIndex}
                itemIndex={itemIndex}
                choices={exerciseItem.choices}
                enabled={enabled}
              />
            </div>
            {!data.immediateCheck &&
              <div className={exerciseStyles.exerciseCardFooter}>
                <div className='w-100 d-flex justify-content-center align-items-center'>
                  <ExerciseItemCheck
                    disabled={!enabled}
                    checking={enabled && checkingItem}
                    onClick={() => onCheckItem(data.stacks[stackIndex].id, exerciseItem, enabled)}
                  />
                </div>
              </div>
            }
          </>
        }
      </div>
    )
  }

  return (
    <div className={exerciseStyles.putInOrderContainer}>
      <PaginableExerciseModule
        renderExerciseItem={renderExerciseItem}
      />
    </div>
  )
}

export default PutInOrderModule