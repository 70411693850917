import React, { useContext } from 'react'
import colorsContext from '../../contexts/ColorsContext'
import classNames from 'classnames'
import {Link} from "react-router-dom";

const SmallButton = ({ disabled, linkTo, onClick, backgroundColor, borderColor, color, style, children = 'Resend', type = 'button' }) => {

	const { colors } = useContext(colorsContext)

	const btnStyle = {
		background: backgroundColor ?? colors['C11'],
		borderColor: borderColor ?? colors['C160'],
		color: color ?? colors['C160'],
		...style,
	}

	if (linkTo) {
		return (
			<Link
				disabled={disabled}
				to={linkTo}
				className={classNames('btn-gs', { ['btn-disabled']: disabled})}
				style={btnStyle}
			>
				{children}
			</Link>
		)
	}

	return (
		<button
			type={type}
			disabled={disabled}
			onClick={onClick}
			className={classNames('btn-gs', { ['btn-disabled']: disabled})}
			style={btnStyle}
		>
			{children}
		</button>
	)
}
export default SmallButton