import serviceCall from "../../services";
import flashCardsMapper from "../../services/mapper/memoryBox/flashCardsMapper";
import {ASC} from "../../enums/globalEnums/globalEnums";

const getAllFlashCardByIsVocabUseCase = async ({categoryId, searchTerm, sortBy, sortKey, isVocab}) => {
	const result = await serviceCall(
		'fetchAllMemoryBoxFlashCardsByIsVocab',
		{
			categoryId: categoryId,
			frontText: searchTerm,
			sortAsc: sortBy === ASC,
			sortBy: sortKey,
			isVocab: isVocab
		},
		'cloud',
	)

	return flashCardsMapper(result)
}

export default getAllFlashCardByIsVocabUseCase