import classNames from 'classnames'
import HtmlTextWrapperStyle from './HtmlTextWrapper.module.scss'
import useQueryParams from '../../hooks/useQueryParams'

const WordLink = ({ highlighted, content, hrefValue }) => {
	const { addQueryParams } = useQueryParams()

	const onClickAnchor = hrefValue => {
		addQueryParams({
			openPhraseOptions: hrefValue,
			phraseType: 'dictionary',
		})
	}

	return (
		<>
			<span
				className={classNames(HtmlTextWrapperStyle.word, { [HtmlTextWrapperStyle.highlighted]: highlighted })}
				onClick={() => onClickAnchor(hrefValue)}
				style={{ cursor: 'pointer' }}
				dangerouslySetInnerHTML={{ __html: content }}
			/>
		</>
	)
}

export default WordLink
