import React, { useContext, useEffect, useRef } from 'react'
import colorsContext from '../../../contexts/ColorsContext'
import exerciseStyles from './../ExerciseModules.module.scss'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import ChatBox from '../../../components/ChatBox/ChatBox'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import { moreOptionsList } from '../../../data'
import {
	selectOptionPIOC,
	toggleLockPutInOrderConversation,
	unselectOptionPIOC,
} from '../../../redux/features/exerciseSlice'
import Lock from '../../../components/Lock/Lock'
import { orderBy } from 'lodash'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import getUserStatusColor from '../../../operations/getUserStatusColor'
import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const PutInOrderConversationModule = ({ data }) => {
	const { colors } = useContext(colorsContext)
	const dispatch = useDispatch()
	const dialogsRef = useRef([])
	const selectedDialogsRef = useRef([])
	const dialogsContentRef = useRef([])
	const { windowWidth } = useWindowDimensions()

	useEffect(() => {
		let timeoutId = 0
		if (!data.checked) {
			timeoutId = setTimeout(() => {
				dialogsRef.current.forEach((el, i) => {
					el.style.height = el.firstChild.clientHeight + 'px'
					selectedDialogsRef.current[i].style.height = el.firstChild.clientHeight + 'px'
				})
			}, 10)
		}

		return () => {
			clearTimeout(timeoutId)
		}
	}, [data, windowWidth])

	const renderTopConversations = () => {
		return orderBy(data.stack.givens, 'order').map((given, i) => {
			if (data.checked) {
				const correctGivenText = data.stack.givens.find(obj => obj.index === i).text
				return (
					<div
						className={exerciseStyles.singleConversationWrapper}
						key={given.index}
					>
						<ChatBox
							borderColor={colors[getUserStatusColor(given.userAnswerStatus)]}
							bgColor={colors['C160']} className={exerciseStyles.conversationDialogBox}
						>
							<HtmlTextWrapper
								className='font-size-30'
								moreOptionsList={moreOptionsList}
								showPhraseOptions
								phraseOptionsDisabled={given.userAnswerStatus === AnswerStatusEnums.INCORRECT && !data.unlocked}
								textColor={(given.userAnswerStatus === AnswerStatusEnums.INCORRECT && data.unlocked) ?
									colors['C233'] : colors[getUserStatusColor(given.userAnswerStatus)]}
								optionsColor={colors['C233']}
								data={
									(data.unlocked || given.userAnswerStatus === AnswerStatusEnums.CORRECT) ?
										correctGivenText : {
											...correctGivenText,
											sentence: given.text.sentence,
										}
								}
							/>
						</ChatBox>
					</div>
				)
			}
			return (
				<div
					className={classNames(
						exerciseStyles.singleConversationWrapper,
						'cursor-pointer',
						{ [exerciseStyles.disappear]: !given.selected },
					)}
					onClick={() => {
						if (given.selected) dispatch(unselectOptionPIOC({ givenIndex: given.index }))
					}}
					ref={el => selectedDialogsRef.current[given.index] = el}
					key={given.index}
				>
					<div className='w-100'>
						<ChatBox
							bgColor={colors['C534']} className={exerciseStyles.conversationDialogBox}
						>
							<p style={{ color: colors['C160'] }}>{given.text.sentenceString}</p>
						</ChatBox>
					</div>
				</div>
			)
		})
	}

	const renderMainConversations = () => {
		return data.stack.givens.map((given) => {
			return (
				<div
					ref={el => dialogsRef.current[given.index] = el}
					className={classNames(
						exerciseStyles.singleConversationWrapper,
						{ [exerciseStyles.disappear]: given.selected },
					)}
					onClick={() => {
						if (!given.selected) dispatch(selectOptionPIOC({ givenIndex: given.index }))
					}}
					key={given.index}
				>
					<div className='w-100'>
						<ChatBox bgColor={colors['C535']} className={exerciseStyles.conversationDialogBox}>
							<p style={{ color: colors['C23'] }} ref={el => dialogsContentRef.current[given.index] = el}>
								{given.text.sentenceString}
							</p>
						</ChatBox>
					</div>
				</div>
			)
		})
	}

	return (
		<div className={exerciseStyles.putInOrderConversationContainer}>
			<div className='container-fluid'>
				{(data.checked && data.stack.givens.some(it => it.userAnswerStatus === AnswerStatusEnums.INCORRECT)) &&
					<div className={classNames(exerciseStyles.exerciseCardFooter, exerciseStyles.footerLarge, 'mb-2')}>
						<div>
							<Lock
								isLocked={!data.unlocked}
								onClickLock={() => {
									dispatch(toggleLockPutInOrderConversation())
								}}
							/>
						</div>
					</div>
				}
				<div className={exerciseStyles.exerciseCard} style={{ background: colors['C62'] }}>
					{renderTopConversations()}
				</div>
				{!data.checked &&
					<div className={exerciseStyles.exerciseCard} style={{ background: colors['C62'] }}>
						{renderMainConversations()}
					</div>
				}
			</div>
		</div>
	)
}

export default PutInOrderConversationModule