import serviceCall from "../../services";
import filesAndFoldersMapper from "../../services/mapper/notepad/filesAndFoldersMapper";

const moveItemsUseCase = async ({ currentFolderId, destinationFolderId, data }) => {

	data = data.map(it => {
		return {
			id: it.id,
			userId: it.id,
			title: it.title,
			color: it.color,
			parentFolderId: it.parentFolderId ?? '',
			fileType: it.fileType,
			createdDate: it.date
		}
	})

	const result = await serviceCall(
		'notepadMoveItem',
		{ currentFolderId, destinationFolderId, data },
		'cloud',
	)

	return filesAndFoldersMapper(result.currentFolderContent);
}

export default moveItemsUseCase