import serviceCall from "../../services";

const addFlashCardInLessonUseCase = async ({ isVocab, text, unitId }) => {

	const bodyFormData = new FormData();
	bodyFormData.append('isVocab', isVocab);
	bodyFormData.append('unitId', unitId);
	bodyFormData.append('front.text', text);

	const result = serviceCall(
		'addMemoryBoxFlashCard',
		{ bodyFormData },
		'cloud',
	)

	return result
}

export default addFlashCardInLessonUseCase