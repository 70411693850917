import serviceCall from "../../services";
import aiPromptFeedbackMapper from "../../services/mapper/aiPromptFeedback/aiPromptFeedbackMapper";

const aiPromptFeedbackUseCase = async (data) => {
	const result = await serviceCall(
		'postAiPromptFeedback',
		data,
		'cloud',
	)

	return aiPromptFeedbackMapper(result)
}

export default aiPromptFeedbackUseCase