import * as React from "react"

const HomeSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style type="text/css">
            {
                ".HomeSvg-st0{stroke-width:19.5845;stroke-miterlimit:10;}"
            }
        </style>
        <g id="Page-1">
            <g transform="translate(0 -1)">
                <path
                    className="HomeSvg-st0"
                    stroke={color}
                    d="M446.8,479.9V225.4h40.4c7.2,0.1,13.7-4.4,16.1-11.3c2.3-6.6,0.1-13.8-5.5-18L268.7,15.7 c-6.3-4.9-15.1-4.9-21.3,0l-79.6,62.7V53c0-9.1-7.3-16.5-16.5-16.5H85.7c-9.1,0-16.5,7.3-16.5,16.5v102.8L18.1,196 c-5.6,4.2-7.7,11.6-5.5,18.1c2.4,6.9,8.8,11.4,16.1,11.3h40.4v254.5c0,13.6,11.1,24.6,24.6,24.6H184c13.6,0,24.6-11.1,24.6-24.6 v-156c0-4.5,3.7-8.2,8.2-8.2h82.1c4.5,0,8.2,3.7,8.2,8.2v156c0,13.6,11.1,24.6,24.6,24.6h90.3 C435.7,504.5,446.8,493.4,446.8,479.9z M323.7,479.9v-156c0-13.6-11.1-24.6-24.6-24.6h-82.1c-13.6,0-24.6,11.1-24.6,24.6v156 c0,4.5-3.7,8.2-8.2,8.2H93.8c-4.5,0-8.2-3.7-8.2-8.2V217.2c0-4.5-3.7-8.2-8.2-8.2H28.2l54.2-42.7c0.1-0.1,0.2-0.3,0.3-0.4 c1.6-1.5,2.5-3.5,2.7-5.6c0-0.2,0.1-0.3,0.1-0.5V53h65.7v42.2c0,0.3,0.1,0.7,0.2,1c0.1,1.1,0.5,2.3,1.1,3.1c0.1,0.3,0.3,0.6,0.5,1 c0.1,0.1,0.3,0.2,0.4,0.3c0.8,0.8,1.7,1.4,2.5,2c0.5,0.2,0.9,0.4,1.4,0.5c0.6,0.2,1.1,0.3,1.7,0.3c0.2,0,0.3,0.1,0.5,0.1 c0.3,0,0.7-0.1,1-0.2c0.6-0.1,1.2-0.2,1.7-0.3c0.5-0.2,1.1-0.5,1.5-0.8c0.3-0.1,0.6-0.3,0.9-0.5l92.9-73.1c0.3-0.2,0.7-0.2,1,0 L487.1,209h-48.7c-4.5,0-8.2,3.7-8.2,8.2v262.7c0,4.5-3.7,8.2-8.2,8.2h-90.3C327.3,488,323.7,484.4,323.7,479.9z"
                />
            </g>
        </g>
    </svg>
)

export default HomeSvg
