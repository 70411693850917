import * as React from "react"

const ReviewMistakesSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style type="text/css">
            {".ReviewMistakesSvg-st0{fill-rule:evenodd;clip-rule:evenodd;}"}
        </style>
        <g id="Icon">
            <path
                className="ReviewMistakesSvg-st0"
                d="M210.7,275.4c9-4,19.6,0.2,23.5,9.2c4,9-0.2,19.6-9.2,23.5c-19.7,8.6-41.5,13.4-64.3,13.4 C72,321.5,0,249.5,0,160.7S72,0,160.7,0s160.7,72,160.7,160.7c0,22.9-4.8,44.6-13.4,64.3c-4,9-14.5,13.1-23.5,9.2 c-9-4-13.1-14.5-9.2-23.5c6.7-15.3,10.4-32.2,10.4-50c0-69-56-125-125-125s-125,56-125,125s56,125,125,125 C178.5,285.8,195.4,282.1,210.7,275.4L210.7,275.4z"
            />
            <path
                className="ReviewMistakesSvg-st0"
                d="M351.3,190.5c88.7,0,160.7,72,160.7,160.7S440,512,351.3,512s-160.7-72-160.7-160.7S262.5,190.5,351.3,190.5z  M351.3,226.2c-69,0-125,56-125,125s56,125,125,125s125-56,125-125S420.3,226.2,351.3,226.2z"
            />
            <path
                className="ReviewMistakesSvg-st0"
                d="M274.3,363.9c-7-7-7-18.3,0-25.2c7-7,18.3-7,25.2,0l30.2,30.2l73.1-73.1c7-7,18.3-7,25.2,0c7,7,7,18.3,0,25.2 l-85.7,85.7c-7,7-18.3,7-25.2,0L274.3,363.9z"
            />
            <path
                className="ReviewMistakesSvg-st0"
                d="M130.5,216.2c-7,7-18.3,7-25.2,0c-7-7-7-18.3,0-25.2l85.7-85.7c7-7,18.3-7,25.2,0c7,7,7,18.3,0,25.2 L130.5,216.2z"
            />
            <path
                className="ReviewMistakesSvg-st0"
                d="M216.2,191c7,7,7,18.3,0,25.2c-7,7-18.3,7-25.2,0l-85.7-85.7c-7-7-7-18.3,0-25.2c7-7,18.3-7,25.2,0L216.2,191z "
            />
        </g>
    </svg>
)

export default ReviewMistakesSvg
