import { toast } from 'react-toastify';
import { languagesEnums } from '../../enums/languageEnums/languageEnums';
import {ENGLISH} from "../../enums/roboTeacher/dataStatusEnums";

// Function to determine if the current language is RTL
const isRTL = () => {
  // Fetch the user's native language from localStorage
  const selectedErrorTranslationLanguageKey = localStorage.getItem('selectedErrorTranslationLanguageKey') ?? ENGLISH

  return (
    selectedErrorTranslationLanguageKey === languagesEnums.PERSIAN_LANGUAGE ||
    selectedErrorTranslationLanguageKey === languagesEnums.ARABIC_LANGUAGE
  )
}


// Generalized toast function
const showToast = (content, type = 'default') => {
  toast(content, {
    type,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    closeButton: true,
    autoClose: 4000,
    isLoading: false,
    pauseOnFocusLoss: false,
    rtl: isRTL(),
  });
};

export default showToast;
