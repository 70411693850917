const unitBgColors = {
  1: 'C446',
  2: 'C447',
  3: 'C448',
  4: 'C449',
  5: 'C450',
  6: 'C451',
  7: 'C452',
  8: 'C453',
  9: 'C454',
  10: 'C455',
  11: 'C435',
  12: 'C436',
  13: 'C437',
  14: 'C438',
  15: 'C439',
  16: 'C440',
  17: 'C441',
  18: 'C442',
  19: 'C443',
  20: 'C444',
  21: 'C445',
  22: 'C348',
  23: 'C349',
  24: 'C350',
  25: 'C351',
  26: 'C352',
  27: 'C353',
  28: 'C354',
  29: 'C355',
  30: 'C356',
  31: 'C357',
  32: 'C358',
  33: 'C359',
  34: 'C360',
  35: 'C361',
  36: 'C362',
  37: 'C363',
  38: 'C364',
  39: 'C348',
  40: 'C349',
  41: 'C350',
  42: 'C351',
  43: 'C352',
  44: 'C353',
  45: 'C354',
  46: 'C355',
  47: 'C356',
  48: 'C357',
  49: 'C358',
  50: 'C359',
  51: 'C360',
  52: 'C361',
  53: 'C362',
  54: 'C363',
  55: 'C364',
  56: 'C314',
  57: 'C315',
  58: 'C316',
  59: 'C317',
  60: 'C318',
  61: 'C319',
  62: 'C336',
  63: 'C337',
  64: 'C338',
  65: 'C339',
  66: 'C340',
  67: 'C341',
  68: 'C342',
  69: 'C343',
  70: 'C344',
  71: 'C345',
  72: 'C346',
  73: 'C347',
  74: 'C456',
  75: 'C457',
  76: 'C458',
  77: 'C459',
  78: 'C460',
  79: 'C461',
  80: 'C462',
  81: 'C463',
  82: 'C464',
  83: 'C473',
  84: 'C474',
  85: 'C475',
  86: 'C476',
  87: 'C477',
  88: 'C478',
  89: 'C479',
  90: 'C480',
}

const getUnitBackgroundColorByNumber = (unitNumber = 1) => {
  return unitBgColors[unitNumber]
}

export default getUnitBackgroundColorByNumber