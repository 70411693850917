import React, { useContext } from 'react'
import style from './RadioButton.module.scss'
import classNames from 'classnames'
import colorsContext from '../../contexts/ColorsContext'

const RadioButton = ({ selected, color, backgroundColor, borderColor }) => {
	const { colors } = useContext(colorsContext)

	return (
		<span
			className={classNames(style.radioButton, { [style.selected]: selected, [style.border]: borderColor })}
			style={{ background: backgroundColor ?? colors['C160'], borderColor: borderColor ?? colors['C25'] }}
		>
			<span className={style.inner} style={{ background: color ?? colors['C25'] }} />
		</span>
	)
}

export default RadioButton
