import * as React from "react"

const MemorizeSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style type="text/css">
            {`.MemorizeSvg-st0{ stroke={color} stroke-width:10;stroke-miterlimit:10;}`}
        </style>
        <g id="Layer_1_00000034080820967001005280000005604941270977395640_">
            <g>
                <g>
                    <path
                        className="MemorizeSvg-st0" stroke={color}
                        d="M448.3,289.4H261c-10.6,0-19.1-8.5-19.1-19.1V129.6c0-4.1,3.3-7.3,7.3-7.3c4.1,0,7.3,3.3,7.3,7.3v140.5 c0,2.4,1.9,4.5,4.5,4.5h187.5c2.4,0,4.5-1.9,4.5-4.5V56.4c0-1.2-0.5-2.4-1.4-3.3l-35.4-32.8c-0.8-0.7-1.9-1.2-3-1.2H261 c-2.4,0-4.5,1.9-4.5,4.5v71.7c0,4.1-3.3,7.3-7.3,7.3s-7.3-3.3-7.3-7.3V23.6c0-10.6,8.5-19.1,19.1-19.1h152c4.8,0,9.4,1.8,13,5.1 l35.4,32.8c3.9,3.6,6.1,8.8,6.1,14.1v213.7C467.5,280.7,458.9,289.4,448.3,289.4z"
                    />
                    <path
                        className="MemorizeSvg-st0" stroke={color}
                        d="M384.2,69.4H298c-8.7,0-15.7-7-15.7-15.7V11.8c0-4.1,3.3-7.3,7.3-7.3c4.1,0,7.3,3.3,7.3,7.3v41.9 c0,0.5,0.5,1,1,1h86.2c0.5,0,1-0.5,1-1V11.8c0-4.1,3.3-7.3,7.3-7.3s7.3,3.3,7.3,7.3v41.9C399.8,62.4,392.8,69.4,384.2,69.4z"
                    />
                    <path
                        className="MemorizeSvg-st0" stroke={color}
                        d="M359.8,69.4c-4.1,0-7.3-3.3-7.3-7.3V11.8c0-4.1,3.3-7.3,7.3-7.3c4.1,0,7.3,3.3,7.3,7.3V62 C367.2,66.2,363.9,69.4,359.8,69.4z"
                    />
                    <path
                        className="MemorizeSvg-st0" stroke={color}
                        d="M418.1,289.4c-4.1,0-7.3-3.3-7.3-7.3v-98c0-0.5-0.5-1-1-1H299.6c-0.5,0-1,0.5-1,1v98c0,4.1-3.3,7.3-7.3,7.3 c-4.1,0-7.3-3.3-7.3-7.3v-98c0-8.7,7-15.7,15.7-15.7h110.2c8.7,0,15.7,7,15.7,15.7v98C425.5,286,422.1,289.4,418.1,289.4z"
                    />
                    <path
                        className="MemorizeSvg-st0" stroke={color}
                        d="M329.6,216.2h-38.3c-4.1,0-7.3-3.3-7.3-7.3c0-4.1,3.3-7.3,7.3-7.3h38.3c4.1,0,7.3,3.3,7.3,7.3 C337,212.9,333.6,216.2,329.6,216.2z"
                    />
                    <path
                        className="MemorizeSvg-st0" stroke={color}
                        d="M418.1,216.2h-54.2c-4.1,0-7.3-3.3-7.3-7.3c0-4.1,3.3-7.3,7.3-7.3h54.2c4.1,0,7.3,3.3,7.3,7.3 C425.5,212.9,422.1,216.2,418.1,216.2z"
                    />
                    <path
                        className="MemorizeSvg-st0" stroke={color}
                        d="M162.3,435c-3.6,0-6.9-2.6-7.3-6.4c-0.5-4.1,2.3-7.7,6.4-8.3l34.6-4.6c4.1-0.6,7.7,2.3,8.3,6.4 c0.5,4.1-2.3,7.7-6.4,8.3l-34.6,4.6C163.1,434.9,162.7,435,162.3,435z"
                    />
                    <path
                        className="MemorizeSvg-st0" stroke={color}
                        d="M309.9,507.5H174.1c-10.6,0-19.1-8.5-19.1-19.1v-52.3l-60.9,8.1c-8.5,1.1-17.1-1.6-23.4-7.3 c-6.4-5.8-9.8-14.1-9.4-22.6l1.2-26.6l-9.9-6.9c-3.5-2.4-5.2-6.7-4.1-10.8l1.4-5.8l-6-7c-3.1-3.6-4-8.5-2.5-13l4-11.4L27.5,327 c-4.6-1.4-8.2-4.9-9.8-9.4c-1.6-4.6-1-9.4,1.7-13.5l38.5-58.5c4.1-6.3,6.3-13.5,6.3-21c0-4-0.6-7.8-1.8-11.7 c-1.9-5.9-2.6-12.2-2.3-18.3c2.8-42.9,21.3-78.9,53.7-104.4c30.7-24,71.2-36.8,117.2-36.8c6.5,0,13,0.4,19.3,1.1 c4.1,0.5,7,4.1,6.5,8.2s-4.1,7-8.2,6.5c-5.7-0.7-11.6-1-17.6-1c-88.4,0-151.1,51.2-156.1,127.4c-0.2,4.3,0.2,8.7,1.6,12.9 c1.7,5.2,2.5,10.6,2.5,16.1c0,10.4-3,20.4-8.7,29l-38.5,58.5c0,0.1-0.1,0.2,0,0.5c0.1,0.2,0.2,0.2,0.4,0.4l22.3,7 c2.6,0.8,4.9,2.8,6.1,5.2c1.2,2.5,1.4,5.4,0.5,8.1l-5.2,14.9l7,8.1c2.2,2.5,3,6,2.2,9.2l-1.2,5.1l9.4,6.6c2.9,2,4.6,5.3,4.5,8.9 l-1.3,29.1c-0.2,4.2,1.4,8.3,4.6,11.1c3.1,2.9,7.3,4.2,11.6,3.6l69.2-9.2c2-0.2,4.2,0.4,5.8,1.8s2.5,3.4,2.5,5.5v60.7 c0,2.4,1.9,4.5,4.5,4.5h135.7c2.4,0,4.5-1.9,4.5-4.5v-90.9c0-29.9,8.7-58.4,25-82.4c7.6-11.1,13.6-23,18.2-35.6 c1.3-3.9,5.5-5.8,9.4-4.5s5.8,5.5,4.5,9.4c-4.9,13.7-11.7,26.9-19.9,38.9c-14.7,21.4-22.4,47.1-22.4,74.1v90.9 C329,498.8,320.5,507.5,309.9,507.5z M64.4,388.8L64.4,388.8L64.4,388.8z M61.1,368.4L61.1,368.4L61.1,368.4z M50.3,361.7 L50.3,361.7L50.3,361.7z"
                    />
                </g>
                <path
                    className="MemorizeSvg-st0" stroke={color}
                    d="M249.2,147.9c-4.1,0-7.3-3.3-7.3-7.3V77.4c0-4.1,3.3-7.3,7.3-7.3c4.1,0,7.3,3.3,7.3,7.3v63.1 C256.5,144.6,253.3,147.9,249.2,147.9z"
                />
            </g>
        </g>
    </svg>
)

export default MemorizeSvg
