import React, { useContext, useRef } from 'react'
import style from '../StructureModule.module.scss'
import CircleNavigationButton from '../../../components/CircleNavigationButton/CircleNavigationButton'
import { goNextPage, goPreviousPage } from '../../../redux/features/structuredSectionSlice'
import LevelNumberBox from '../../../components/LevelNumberBox/LevelNumberBox'
import colorsContext from '../../../contexts/ColorsContext'
import { useDispatch } from 'react-redux'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss/pagination';
import classNames from 'classnames'

const SinglePageControlButtons = ({ structuresData, templateId }) => {
	const { colors, theme } = useContext(colorsContext)
	const dispatch = useDispatch()

	const swiperRef = useRef(null)

	return (
		<div className={style.paginateContainer}>
			<CircleNavigationButton
				color={colors['C187']}
				direction='left'
				hideButton={structuresData.currentPageIndex === 0}
				onClick={() => {
					swiperRef.current?.swiper.slidePrev()
					dispatch(goPreviousPage({ templateId }))
				}}
			/>
			<div>
				<Swiper
					ref={swiperRef}
					className={classNames(style.sliderContainer, `theme-${theme}`)}
					pagination={{
						dynamicBullets: true
					}}
					allowTouchMove={false}
					modules={[Pagination]}
					initialSlide={structuresData.currentPageIndex}
				>
					{[...new Array(structuresData.sectionData.singlePages?.length)].map((item, i) => (
						<SwiperSlide key={i} />
					))}
				</Swiper>
				<div className='d-flex align-items-center justify-content-center'>
					<LevelNumberBox color={colors['C187']}>
						{structuresData.currentPageIndex + 1}/{structuresData.sectionData.singlePages?.length}
					</LevelNumberBox>
				</div>
			</div>
			<CircleNavigationButton
				color={colors['C187']}
				direction='right'
				hideButton={structuresData.currentPageIndex + 1 >= structuresData.sectionData.singlePages?.length}
				onClick={() => {
					swiperRef.current?.swiper.slideNext()
					dispatch(goNextPage({ templateId }))
				}}
			/>
		</div>
	)
}

export default SinglePageControlButtons