import styles from './ExerciseItemCheck.module.scss'
import React, {useContext} from 'react'
import colorsContext from '../../contexts/ColorsContext'
import classNames from 'classnames'

const ExerciseItemCheck = (
  {disabled, onClick, checking, children, isBorder, style, backgroundColor, borderColor, color},
) => {
  const {colors} = useContext(colorsContext)

  const btnStyle = isBorder
    ? {
      background: backgroundColor ?? 'transparent',
      color: color ?? colors['C11'],
      borderColor: borderColor ?? colors['C11'],
      opacity: checking ? 0.8 : 1,
    }
    : {
      background: backgroundColor ?? colors['C11'],
      color: color ?? colors['C160'],
      borderColor: borderColor ?? colors['C160'],
      opacity: checking ? 0.8 : 1,
    }

  return (
    <div className={styles.exerciseItemCheckContainer}>
      <button
        disabled={disabled}
        onClick={!disabled ? onClick : null}
        className={classNames(styles.exerciseItemCheckButton, {[styles.disabled]: disabled})}
        style={{...btnStyle, ...style}}
      >
        {children ?? 'Check'}
      </button>
    </div>
  )
}

export default ExerciseItemCheck