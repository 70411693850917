import React from "react"

const HeadphoneSvg = ({color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      xmlSpace="preserve"
      width={48}
      height={48}
      fill={color}
    >
      <path
        d="m4.36 28.48 3.43-1.37c.97-.39 2.07-.37 2.98.14.79.45 1.53 1.3 1.58 2.87.11 3.5 1.53 9.19 1.53 9.19s2.41 8.31-4.76 5.9-8.9-6.34-8.9-11.26S-2.35 9.17 17.05 3.44C35.7-2.07 46.3 14.11 47.12 21.04c.57 4.89.82 11.75.88 13.82.02.52-.02 1.03-.11 1.53-.49 2.62-2.67 9.88-11.92 9.25-3.83.5-1.53-7.33-1.53-7.33l1.31-9.08s.55-5.58 7.76-.55c1.14-13.9-9.4-19.03-9.4-19.03s-12.36-7.5-23.62 2.75c-8.36 7.62-6.13 16.08-6.13 16.08z"
        fill={color}
      />
    </svg>
  )
}

export default HeadphoneSvg
