import { createSlice } from "@reduxjs/toolkit";
import levelsUseCase from "../../useCase/levelAndUnitsUseCase/levelsUseCase";
import unitsUseCase from "../../useCase/levelAndUnitsUseCase/unitsUseCase";
import sectionsUseCase from "../../useCase/levelAndUnitsUseCase/sectionsUseCase";
import updateLevelStatusUseCase from "../../useCase/levelAndUnitsUseCase/updateLevelStatusUseCase";
import updateUnitStatusUseCase from "../../useCase/levelAndUnitsUseCase/updateUnitStatusUseCase";
import { baseAsyncThunk } from "../baseAsyncThunk";
import levelsAndUnitsUseCase from "../../useCase/levelAndUnitsUseCase/levelsAndUnitsUseCase";

const initialState = {
  unitsLoading: false,
  levelsLoading: false,
  getSectionsLoading: false,
  levels: [],
  error: '',
}

export const getLevelsAndUnits = baseAsyncThunk(
  'levelUnit/getLevelsAndUnits',
  () => levelsAndUnitsUseCase()
)

export const getLevels = baseAsyncThunk(
  'levelUnit/getLevels',
  (payload, thunkAPI) => {
    const { levelUnit } = thunkAPI.getState()
    return levelsUseCase({ oldLevels: payload?.keepUnits ? levelUnit?.levels : null })
  }
)

export const getUnits = baseAsyncThunk(
  'levelUnit/getUnits',
  ({ level }) => {
    return unitsUseCase({ level })
  }
)

export const getSections = baseAsyncThunk(
  'levelUnit/getSections',
  ({ level, unitId, studyType }) => {
    return sectionsUseCase({ level, unitId, studyType })
  }
)

export const toggleLevelStatus = baseAsyncThunk(
  'levelUnit/toggleLevelStatus',
  ({ level }) => {
    return updateLevelStatusUseCase({ level })
  }
)

export const toggleUnitStatus = baseAsyncThunk(
  'levelUnit/toggleUnitStatus',
  ({ level, unitId }) => {
    return updateUnitStatusUseCase({ level, unitId })
  }
)

const levelUnitSlice = createSlice({
  name: 'levelUnit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLevelsAndUnits.pending, (state) => {
      state.levelsLoading = true
      state.unitsLoading = true
    })
    builder.addCase(getLevelsAndUnits.fulfilled, (state, action) => {
      state.levelsLoading = false
      state.unitsLoading = false
      state.levels = action.payload
    })
    builder.addCase(getLevelsAndUnits.rejected, (state, action) => {
      state.levelsLoading = false
      state.unitsLoading = false
      state.error = action.error.message
    })
    builder.addCase(getLevels.pending, (state) => {
      state.levelsLoading = true
    })
    builder.addCase(getLevels.fulfilled, (state, action) => {
      state.levelsLoading = false
      state.levels = action.payload
    })
    builder.addCase(getLevels.rejected, (state, action) => {
      state.levelsLoading = false
      state.error = action.error.message
    })
    builder.addCase(getUnits.pending, (state) => {
      state.unitsLoading = true
    })
    builder.addCase(getUnits.fulfilled, (state, action) => {
      state.unitsLoading = false
      state.levels = state.levels.map((level) => {
        if (level.level === action.payload.level) {
          return { ...level, units: action.payload.units }
        }
        return level
      })
    })
    builder.addCase(getUnits.rejected, (state, action) => {
      state.unitsLoading = false
      state.error = action.error.message
    })
    builder.addCase(getSections.pending, (state) => {
      state.getSectionsLoading = true
    })
    builder.addCase(getSections.fulfilled, (state, action) => {
      state.getSectionsLoading = false
      state.levels = state.levels.map((level) => {
        if (level.level === action.payload.level) {
          return {
            ...level,
            units: level.units?.map(unit => {
              if (unit.unitNumber === action.payload.unitId) {
                return { ...unit, sections: action.payload.sections }
              }
              return unit
            })
          }
        }
        return level
      })
    })
    builder.addCase(getSections.rejected, (state, action) => {
      state.getSectionsLoading = false
      state.error = action.error.message
    })
  }
})

export default levelUnitSlice.reducer