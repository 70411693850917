import {createSlice} from "@reduxjs/toolkit";
import {ALL} from "../../../enums/globalEnums/globalEnums";

const initialState = {
  page: ALL
}

const yourSelectionSlice = createSlice({
  name: 'yourSelection',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    },
  },
})

export const {setPage} = yourSelectionSlice.actions

export default yourSelectionSlice.reducer