import React, { useContext } from 'react'
import exerciseStyles from './ExerciseModules.module.scss'
import { useSelector } from 'react-redux'
import colorsContext from '../../contexts/ColorsContext'
import Instruction from '../../components/Instruction/Instruction'
import classNames from "classnames";

const PaginableExerciseModule = ({ renderExerciseItem }) => {
	const { colors } = useContext(colorsContext)
	const { data, isSingleTemplate, currentItem } = useSelector((state) => state.exercise)

	const renderStack = (stack, stackIndex) => {
		const exerciseItemClass = stack.joined ? exerciseStyles.exerciseInCard : exerciseStyles.exerciseCard
		return (
			<div
				className={stack.joined ? exerciseStyles.exerciseCard : ''}
				style={{ background: stack.joined ? colors['C527'] : '' }}
				key={stackIndex}
			>
				{stack.items.map((item, i) => {
					let enabled = false
					if (currentItem.stackIndex === stackIndex && currentItem.itemIndex === i) {
						enabled = true
					}
					return renderExerciseItem(item, exerciseItemClass, stackIndex, i, enabled)
				})}
			</div>
		)
	}

	const renderExercises = () => {
		if (isSingleTemplate) {
			// Give and display current page item in single view
			const item = data.stacks[currentItem.stackIndex].items[currentItem.itemIndex]
			return (
				<>
					{data.stacks[currentItem.stackIndex].title &&
						<Instruction data={data.stacks[currentItem.stackIndex].title} />
					}
					<div className='container container-1'>
						{renderExerciseItem(item, exerciseStyles.exerciseCard, currentItem.stackIndex, currentItem.itemIndex, true)}
					</div>
				</>
			)
		} else {
			// Render all stacks in list view
			return data.stacks.map((stack, i) => {
				return (
					<React.Fragment key={i}>
						{stack.title && <Instruction data={stack.title} />}
						<div className={classNames(exerciseStyles.exerciseStack, 'container container-1')}>
							{renderStack(stack, i)}
						</div>
					</React.Fragment>
				)
			})
		}
	}

	return (
		<>
			{renderExercises()}
		</>
	)
}

export default PaginableExerciseModule
