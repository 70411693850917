

import * as React from "react"

const ContactUsSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style type="text/css">
            {
                ".ContactUsSvg-st0{stroke-miterlimit:10;}.ContactUsSvg-st1{stroke-width:15;stroke-miterlimit:10;}"
            }
        </style>
        <g>
            <path
                className="ContactUsSvg-st0"
                stroke={color}
                d="M370.3,354.6l-18.5-36.2c-1.5-3-4.2-5.3-7.4-6.3c-3.6-1.1-7.3-1.6-11.1-1.6c-22.2,0-40.3,18.1-40.3,40.3 c0,80.1,65,145.2,145.2,145.2c0.1,0,0.2,0,0.3,0c22.2,0.1,40.3-17.8,40.4-39.9c0-3.9-0.5-7.7-1.6-11.5c-1-3.3-3.3-6-6.5-7.5 l-36.3-18.2c-4.8-2.4-10.5-1.4-14.3,2.3l-10.1,10.1c-24.6-8.6-43.8-27.8-52.4-52.3l10.2-10.2C371.7,365,372.6,359.3,370.3,354.6 L370.3,354.6z M360.5,428.7c13.5,13.5,30.4,23.4,48.8,28.4h1.9c4.1,0.8,8.2-0.5,11.1-3.4l9.2-9.2l22.4,11.2 c-0.2,8.5-7.2,15.2-15.6,15.2c-66.4,0-120.3-53.8-120.3-120.3v-0.1c0-8.3,6.5-15.2,14.9-15.6l11.2,22.5l-9.2,9.2 c-2.9,3-4.2,7.2-3.4,11.2c0,0.6,0,1.2,0,1.7c5.1,18.6,15.1,35.4,28.8,48.8L360.5,428.7z"
            />
            <path
                className="ContactUsSvg-st1"
                stroke={color}
                d="M13.4,449.4c0,6.3,0.3,12.7,1,18.9c0.7,6.3,6,11,12.4,11h253c6.9,0,12.4-5.5,12.4-12.4s-5.5-12.4-12.4-12.4 H38.2c0-1.7,0-3.5,0-5.2c0.1-112.7,91.3-204,204-204.4h0.6c53.1-0.1,104.1,20.7,142.1,57.7c4.9,4.7,12.8,4.6,17.5-0.3 s4.6-12.8-0.3-17.5c-27.6-26.9-61.7-46.3-98.9-56.5c56.1-33.2,74.6-105.4,41.5-161.4S239.4-7.7,183.3,25.4s-74.6,105.4-41.5,161.4 c10.1,17.1,24.4,31.3,41.5,41.5C83.3,255.3,13.7,345.8,13.4,449.4L13.4,449.4z M149.4,127.9c-0.1-51.4,41.6-93.2,93-93.3 s93.2,41.6,93.3,93c0.1,51.3-41.5,93.1-92.9,93.3h-0.6c-51.4-0.3-92.9-42.1-92.6-93.6l0,0L149.4,127.9z"
            />
        </g>
    </svg>
)

export default ContactUsSvg