import React from 'react'
import StructureTitleComponent
	from '../../pages/Sections/StructuredComponents/Components/StructureTitleComponent/StructureTitleComponent'
import StructureTextAreaComponent
	from '../../pages/Sections/StructuredComponents/Components/StructureTextAreaComponent/StructureTextAreaComponent'
import StructureTextTitleComponent
	from '../../pages/Sections/StructuredComponents/Components/StructureTextTitleComponent/StructureTextTitleComponent'
import StructureTextComponent
	from '../../pages/Sections/StructuredComponents/Components/StructureTextComponent/StructureTextComponent'
import {ContentTypesStructureEnums} from '../../enums/structureEnums/templateType'

const StructureTextUseCase = ({ textData }) => {
	switch (textData?.displayType) {
		case ContentTypesStructureEnums.TITLE:
			return <StructureTitleComponent text={textData?.text} />
		case ContentTypesStructureEnums.TEXT_AREA:
			return <StructureTextAreaComponent text={textData?.text} />
		case ContentTypesStructureEnums.TEXT_TITLE:
			return <StructureTextTitleComponent text={textData?.text} />
		case ContentTypesStructureEnums.TEXT:
			return <StructureTextComponent text={textData?.text} />
		default:
			return null
	}
}

export default StructureTextUseCase