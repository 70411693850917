const userInfoMapper = (data) => {
  return {
    id: data?.id ?? '',
    username: data?.username ?? '',
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    email: data?.email ?? '',
    phoneNumber: data?.phoneNumber ?? '',
    address: data?.address ?? '',
    authenticationType: data?.authenticationType ?? '',
    enabled: data?.enabled,
    usernameVerified: data?.usernameVerified,
    setPassword: data?.setPassword,
    expireDate: data?.expireDate
  }
}

export default userInfoMapper