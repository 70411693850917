import React, { useContext } from 'react'
import exerciseStyles from '../../modules/ExerciseModules/ExerciseModules.module.scss'
import classNames from 'classnames'
import Image from '../Image'
import PlayButtonPrimaryAudio from '../PlayButtonPrimary/PlayButtonPrimaryAudio'
import makeUrl from '../../operations/makeUrl'
import colorsContext from '../../contexts/ColorsContext'
import { ContentTypesStructureEnums } from '../../enums/structureEnums/templateType'
import { VOICE } from '../../enums/globalEnums/globalEnums'

const GivenTemplate = ({ exerciseItem, givenText, hideVoice }) => {
	const { colors } = useContext(colorsContext)

	return (
		<div
			className={classNames(exerciseStyles.givenContainer, {
				[exerciseStyles.givenCenter]: !exerciseItem.given.type.includes(ContentTypesStructureEnums.TEXT),
			})}>
			{exerciseItem.given.type.includes(VOICE) && !hideVoice && (
				<div className={exerciseStyles.givenVoiceContainer}>
					<PlayButtonPrimaryAudio
						audioUrl={makeUrl(exerciseItem.given.voiceUri)}
						bgColor={colors['C65']}
						iconColor={colors['C174']}
					/>
				</div>
			)}
			<div className={classNames(exerciseStyles.givenTemplateContainer, exerciseItem.given.templateClassName)}>
				{exerciseItem.given.type.includes(ContentTypesStructureEnums.IMAGE) && (
					<Image onClickZoomIn className={exerciseStyles.image} uri={exerciseItem.given.imageUri} />
				)}
				{(exerciseItem.given.type.includes(ContentTypesStructureEnums.TEXT) || givenText) && (
					<div className={exerciseStyles.text}>{givenText}</div>
				)}
			</div>
		</div>
	)
}

export default GivenTemplate
