import serviceCall from "../../services";

const logoutUserAccountUseCase = async (data) => {
  return await serviceCall(
    'postLogoutUser',
    data,
    'cloud'
  )
}

export default logoutUserAccountUseCase