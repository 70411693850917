import BottomSheet from "../../../components/BottomSheet/BottomSheet";
import HelpVideo from "../../molecules/HelpVideo/HelpVideo";
import HelpVideoSuggestions from "../../molecules/HelpVideoSuggestions/HelpVideoSuggestions";
import {useEffect, useState} from "react";
import HelpVideoModalContent from "../../molecules/HelpVideoModalContent/HelpVideoModalContent";

const HelpVideoMobile = ({close, videos}) => {
  return (
    <BottomSheet
      overlayOpacity={0.5}
      hasSpace={true}
      close={close}
    >
      {() => (
        <div>
          <HelpVideoModalContent videos={videos}/>
        </div>
      )}
    </BottomSheet>
  )
}

export default HelpVideoMobile