
import * as React from "react"

const StatisticsSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <g>
            <path d="M177.1,511.4H40.4c-10.3,0-18.7-8.4-18.7-18.7V182c0-10.3,8.4-18.7,18.7-18.7h136.7c10.3,0,18.7,8.4,18.7,18.7v310.7 C195.8,503,187.4,511.4,177.1,511.4z M59.1,474h99.2V200.7H59.1V474z" />
            <path d="M313.8,511H177.1c-10.3,0-18.7-8.4-18.7-18.7V20c0-10.3,8.4-18.7,18.7-18.7h136.7c10.3,0,18.7,8.4,18.7,18.7v472.3 C332.5,502.6,324.1,511,313.8,511z M195.8,473.6h99.2V38.7h-99.2V473.6z" />
            <path d="M450.5,510.3H313.8c-10.3,0-18.7-8.4-18.7-18.7V106.1c0-10.3,8.4-18.7,18.7-18.7h136.7c10.3,0,18.7,8.4,18.7,18.7v385.5 C469.2,501.9,460.8,510.3,450.5,510.3z M332.5,472.9h99.2v-348h-99.2V472.9z" />
            <path d="M482.9,511.4H19.1c-10.3,0-18.7-8.4-18.7-18.7S8.8,474,19.1,474h463.8c10.3,0,18.7,8.4,18.7,18.7S493.2,511.4,482.9,511.4z " />
        </g>
    </svg>
)

export default StatisticsSvg