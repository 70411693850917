import {offlineGamesArrayList} from "../../../enums/gameEnums/gameTypeEnums";
import {millisToMinutesAndSeconds} from "../../../util/utility";

const unitGameStatisticMapper = (data) => {
  return {
    vicabMasterNumber: data?.vicabMasterNumber ?? 0,
    gameResults: offlineGamesArrayList.map(game => {
      const responseGame = data?.gameResults?.find(it => it.gameType === game.gameType)
      return {
        game: game,
        status: responseGame?.gameResultLevel,
        masterTime: millisToMinutesAndSeconds(responseGame?.masterTime ?? 0) ?? 0,
        bestTime: millisToMinutesAndSeconds(responseGame?.yourBestTime ?? 0) ?? 0
      }
    }) ?? [],
  }
}

export default unitGameStatisticMapper