import serviceCall from "../../services";
import paymentResultMapper from "../../services/mapper/payment/paymentResultMapper";

const getPaymentResultUseCase = async ({paymentId}) => {
  const result = await serviceCall(
    'fetchPaymentResult',
    {paymentId},
    'cloud',
  )

  return paymentResultMapper(result)
}

export default getPaymentResultUseCase