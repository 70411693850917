
import * as React from "react"

const TodayStudySvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style type="text/css">
            {
                ".TodayStudySvg-st0{stroke-width:13.8957;stroke-miterlimit:10;}"
            }
        </style>
        <path
            className="TodayStudySvg-st0"
            stroke={color}
            d="M287.4,379.6c2.2,2.2,3.3,5.2,3.3,8.1s-1.3,6-3.3,8.1c-2.2,2.1-5.1,3.3-8,3.3s-5.9-1.2-8-3.3 c-2.1-2.2-3.3-5.2-3.3-8.1s1.3-6,3.3-8.1C275.7,375.3,283.3,375.3,287.4,379.6z M226.1,379.6c-2.1,2.2-3.3,5.2-3.3,8.1 s1.3,6,3.3,8.1c2.2,2.1,5.1,3.3,8,3.3c3,0,5.9-1.3,8-3.3c2.1-2.2,3.3-5.2,3.3-8.1s-1.3-6-3.3-8.1 C237.8,375.3,230.2,375.3,226.1,379.6z M505.5,479.5c0,6.3-5,11.5-11.3,11.5h-67.9H87.2H19.3C13.1,491,8,485.9,8,479.5 c0-6.3,5-11.5,11.3-11.5h54.2L47.1,325.2c-1.9-10.1,0.8-20.4,7.2-28.3c6.5-7.9,16-12.4,26.1-12.4h7.4c5.6-51.6,48.8-91.8,101.1-91.8 h4c-23.2-18.9-37.9-47.9-37.9-80.3C155,55.3,200.7,9,256.8,9c56.2,0,101.8,46.3,101.8,103.3c0,32.4-14.9,61.4-37.9,80.3h4 c52.3,0,95.5,40.2,101.1,91.8h7.4c10.1,0,19.6,4.5,26.1,12.5c6.5,7.9,9.1,18.2,7.2,28.3L440,468h54.2 C500.4,468,505.5,473.2,505.5,479.5z M256.8,192.6c43.6,0,79.1-36,79.1-80.3S300.4,32,256.8,32s-79.1,36-79.1,80.3 S213.1,192.6,256.8,192.6z M110.6,284.4h292.3c-5.5-38.9-38.5-68.9-78.3-68.9H188.9C149.1,215.6,116.1,245.6,110.6,284.4z  M416.9,468l27.2-147c0.9-4.6-1.2-7.9-2.4-9.4c-1.3-1.5-4-4.1-8.7-4.1h-17.7c-0.1,0-0.1,0-0.2,0H98.4H80.5c-4.6,0-7.4,2.6-8.7,4.1 c-1.3,1.5-3.3,4.8-2.4,9.4l27.1,147H416.9z"
        />
    </svg>
)

export default TodayStudySvg