import { createSlice } from '@reduxjs/toolkit'
import serviceCall from '../../services'
import { setPasswordActionEnums, setPasswordStateEnums } from "../../enums/inquiryEnums/inquiryEnums";
import { baseAsyncThunk } from '../baseAsyncThunk';

const initialState = {
  loading: false,
  data: {
    username: null,
    password: '',
    rePassword: '',
    code: '',
    state: setPasswordStateEnums.SET_PASSWORD,
  },
  uid: null,
  error: '',
  errorEnum:''
}

export const resetUserPassword = baseAsyncThunk(
  'resetPassword/resetUserPassword',
  (arg, { getState }) => {
    const { resetPassword } = getState()
    return serviceCall(
      'postResetPassword',
      resetPassword.data,
      'cloud',
    )
  },
)

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    setResetPasswordData: (state, action) => {
      state.data.password = action.payload?.password ?? state.data.password
      state.data.rePassword = action.payload?.rePassword ?? state.data.rePassword
      state.data.state = action.payload?.state ?? state.data.state
      state.data.code = action.payload?.code ?? state.data.code
    },
    clearResetPasswordData: (state) => {
      state.data.state = null
      state.data.password = ''
      state.data.rePassword = ''
      state.data.code = ''
      state.data.state = setPasswordStateEnums.SET_PASSWORD
    }
  },
  extraReducers: builder => {
    builder.addCase(resetUserPassword.pending, (state) => {
      state.error = ''
      state.errorEnum= ''
      state.loading = true
    })
    builder.addCase(resetUserPassword.fulfilled, (state, action) => {
      state.loading = false
      switch (action.payload.action) {
        case setPasswordActionEnums.CONFIRM_USERNAME:
          state.data.state = setPasswordStateEnums.CONFIRMING_USERNAME
          state.data.username = action.payload.payload.username
          state.uid = action.payload.uid
      }
    })
    builder.addCase(resetUserPassword.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload?.error
      state.errorEnum = action.payload?.response?.error
    })
  },
})

export const { setResetPasswordData, clearResetPasswordData, loading, errorEnum } = resetPasswordSlice.actions

export default resetPasswordSlice.reducer