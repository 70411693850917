import moment from "moment";
import {FRIDAY, MONDAY, SATURDAY, SUNDAY, THURSDAY, TUESDAY, WEDNESDAY} from "../../../enums/base/daysEnums";

const setSettingStudyReminderMapper = ({param}) => {
  return {
    studyReminder: param?.reminder,
    applyToAll: param?.applyToAll,
    allTime: new moment(`${(param?.allTime?.hour ?? 0)}:${(param?.allTime?.minute ?? 0)}`, 'HH:mm').format(),
    monday: param?.days.find(it => it.type?.key === MONDAY)?.selected ?? true,
    mondayFromTime: new moment(`${(param?.days.find(it => it.type?.key === MONDAY)?.time?.hour ?? 0)}:${(param?.days.find(it => it.type?.key === MONDAY)?.time?.minute ?? 0)}`, 'HH:mm').format(),
    mondayToTime: null,
    tuesday: param?.days.find(it => it.type?.key === TUESDAY)?.selected ?? true,
    tuesdayFromTime: new moment(`${(param?.days.find(it => it.type?.key === TUESDAY)?.time?.hour ?? 0)}:${(param?.days.find(it => it.type?.key === TUESDAY)?.time?.minute ?? 0)}`, 'HH:mm').format(),
    tuesdayToTime: null,
    wednesday: param?.days.find(it => it.type?.key === WEDNESDAY)?.selected ?? true,
    wednesdayFromTime: new moment(`${(param?.days.find(it => it.type?.key === WEDNESDAY)?.time?.hour ?? 0)}:${(param?.days.find(it => it.type?.key === WEDNESDAY)?.time?.minute ?? 0)}`, 'HH:mm').format(),
    wednesdayToTime: null,
    thursday: param?.days.find(it => it.type?.key === THURSDAY)?.selected ?? true,
    thursdayFromTime : new moment(`${(param?.days.find(it => it.type?.key === THURSDAY)?.time?.hour ?? 0)}:${(param?.days.find(it => it.type?.key === THURSDAY)?.time?.minute ?? 0)}`, 'HH:mm').format(),
    thursdayToTime: null,
    friday: param?.days.find(it => it.type?.key === FRIDAY)?.selected ?? true,
    fridayFromTime: new moment(`${(param?.days.find(it => it.type?.key === FRIDAY)?.time?.hour ?? 0)}:${(param?.days.find(it => it.type?.key === FRIDAY)?.time?.minute ?? 0)}`, 'HH:mm').format(),
    fridayToTime: null,
    saturday: param?.days.find(it => it.type?.key === SATURDAY)?.selected ?? false,
    saturdayFromTime: new moment(`${(param?.days.find(it => it.type?.key === SATURDAY)?.time?.hour ?? 0)}:${(param?.days.find(it => it.type?.key === SATURDAY)?.time?.minute ?? 0)}`, 'HH:mm').format(),
    saturdayToTime: null,
    sunday: param?.days.find(it => it.type?.key === SUNDAY)?.selected ?? false,
    sundayFromTime: new moment(`${(param?.days.find(it => it.type?.key === SUNDAY)?.time?.hour ?? 0)}:${(param?.days.find(it => it.type?.key === SUNDAY)?.time?.minute ?? 0)}`, 'HH:mm').format(),
    sundayToTime: null,
    holidayOne: null,
    holidayTwo: null,
    userOffDates: param?.userOffDates?.map((item) => {
      return {
        date: new moment.utc(`${item?.year}:${item?.month}:${item?.day}`, 'YYYY-MM-DD').format()
      }
    })
  }
}
export default setSettingStudyReminderMapper