import React, { useContext, useEffect } from 'react'
import exerciseStyles from './../ExerciseModules.module.scss'
import classNames from 'classnames'
import colorsContext from '../../../contexts/ColorsContext'
import { useDispatch, useSelector } from 'react-redux'
import reactStringReplace from 'react-string-replace'
import ComboBoxDropdown from '../../../components/ComboBoxDropdown/ComboBoxDropdown'
import GivenTemplate from '../../../components/GivenTemplate/GivenTemplate'
import {
  checkExerciseItem,
  goToNextItem,
  selectChoiceCombo, setExerciseItemUpdated,
  toggleLockPaginable,
} from '../../../redux/features/exerciseSlice'
import ExerciseItemCheck from '../../../components/ExerciseItemCheck/ExerciseItemCheck'
import PaginableExerciseModule from '../PaginableExerciseModule'
import Lock from '../../../components/Lock/Lock'
import ExerciseFullTexts from '../Components/ExerciseFullTexts'
import removeWrapperTag from '../../../operations/removeWrapperTag'
import numberIsOdd from '../../../operations/numberIsOdd'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import ComboBox from '../../../components/ComboBox/ComboBox'
import getUserStatusColor from '../../../operations/getUserStatusColor'
import { ContentTypesStructureEnums } from '../../../enums/structureEnums/templateType'
import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const ComboBoxModule = ({ data }) => {
  const { colors } = useContext(colorsContext)
  const { isSingleTemplate, checkingItem, exerciseItemUpdated, currentItem } = useSelector((state) => state.exercise)
  const dispatch = useDispatch()

  // When an option is selected, this will be effected.
  // Check the item if all fields in current item selected and
  // the immediateCheck is enabled (equals to true)
  useEffect(() => {
    if (exerciseItemUpdated) {
      if (data.immediateCheck) {
        const currentEnableStack = data.stacks[currentItem.stackIndex]
        const currentEnableItem = currentEnableStack.items[currentItem.itemIndex]

        const allFieldsAreFilled = currentEnableItem.choices.every(choice => choice.items.some(it => it.selected))

        if (allFieldsAreFilled) {
          onCheckItem(currentEnableStack.id, currentEnableItem, true)
        }
      }
      dispatch(setExerciseItemUpdated(false))
    }
  }, [exerciseItemUpdated])

  const onClickOptionComboBox = (choicesIndex, option, exerciseItem, stackIndex, itemIndex, enabled) => {
    if (enabled) {
      dispatch(selectChoiceCombo({
        stackIndex,
        itemIndex,
        choicesIndex,
        choiceIndex: option.index,
      }))
    }
  }

  const renderGivenText = (exerciseItem, stackIndex, itemIndex) => {
    if (exerciseItem.checked && (exerciseItem.userAnswerStatus !== AnswerStatusEnums.INCORRECT || exerciseItem.unlocked)) {
      const userAnswerStatusList = exerciseItem.choices.map(field => {
        return field.userAnswer.userAnswerStatus
      })

      return <ExerciseFullTexts
        unlocked={exerciseItem.unlocked}
        fullTexts={exerciseItem.fullTextAnswers}
        userAnswerStatus={userAnswerStatusList}
        template='COMBOBOX'
        textColor={colors['C233']}
      />
    }

    const renderGivenTextParagraph = (paragraph, pIndex) => {
      if (paragraph.containsField) {
        const cleanParagraph = removeWrapperTag(paragraph.string, 'p')
        const arr = reactStringReplace(cleanParagraph, '###', (match, index) => {

          const choicesIndex = paragraph.fieldIndexStartsFrom + ((index - 1) / 2)
          const field = data.stacks?.[stackIndex].items?.[itemIndex].choices?.[choicesIndex]

          if (exerciseItem.checked) {
            return <ComboBox
              userAnswerStatus={field?.userAnswer?.userAnswerStatus}
              textColor={colors[getUserStatusColor(field?.userAnswer?.userAnswerStatus)]}
              borderColor={colors[getUserStatusColor(field?.userAnswer?.userAnswerStatus)]}
              value={field?.items?.[field.userAnswer.userSelectedIndex]?.text.sentenceString}
            />
          }

          const dropdownItems = field?.items?.map((option) => {
            return {
              text: option.text.sentenceString,
              selected: option.selected,
              onClick: () => onClickOptionComboBox(choicesIndex, option, exerciseItem, stackIndex, itemIndex, true),
            }
          })

          return (
            <ComboBoxDropdown
              disabled={checkingItem}
              dropdownItems={dropdownItems}
              key={choicesIndex}
            />
          )
        })
        /*
        * Example of arr:
        * ['<a href="text">Hello</a>', myCustomComponent, '.']
        * myCustomComponent is located in odd indexes (1, 3...)
        * */
        return arr.map((el, i) => (
          <React.Fragment key={i}>
            {numberIsOdd(i) ? el :
              <HtmlTextWrapper
                textColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
                optionsColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
                data={{
                  ...(exerciseItem.fullTextAnswers.length > pIndex ? exerciseItem.fullTextAnswers[pIndex] : {}),
                  sentence: el,
                }}
                phraseOptionsDisabled
                showPhraseOptions={exerciseItem.checked && i === 0}
                style={{ display: 'inline' }}
              />
            }
          </React.Fragment>
        ))
      } else {
        return (
          <HtmlTextWrapper
            textColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
            optionsColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
            data={{
              ...(exerciseItem.fullTextAnswers.length > pIndex ? exerciseItem.fullTextAnswers[pIndex] : {}),
              sentence: paragraph.string,
            }}
            phraseOptionsDisabled
            showPhraseOptions={exerciseItem.checked}
            style={{ display: 'inline' }}
          />
        )
      }
    }

    const givenText = exerciseItem.given.text
    if (givenText) {
      return givenText.paragraphList.map((paragraph, pIndex) => {
        return (
          <div className='exercise-paragraph' style={{ ...paragraph.paragraphStyle }} key={pIndex}>
            {renderGivenTextParagraph(paragraph, pIndex)}
          </div>
        )
      })
    }
  }

  const onCheckItem = (stackId, exerciseItem, enabled) => {
    if (enabled && !checkingItem) {
      const userAnswerData = {
        exerciseStackId: stackId,
        userAnswerItems: [
          {
            exerciseItemId: exerciseItem.id,
            userAnswers: exerciseItem.choices.map(choice => {
              const selectedChoice = choice.items.find(choiceItem => choiceItem.selected)
              return {
                listIndex: choice.index,
                index: selectedChoice ? selectedChoice.index : null,
                skipped: !selectedChoice,
              }
            }),
          },
        ],
      }
      dispatch(checkExerciseItem({ userAnswerData })).then((res) => {
        if (res.payload?.status === 200 && !isSingleTemplate) {
          dispatch(goToNextItem())
        }
      })
    }
  }

  const renderExerciseItem = (exerciseItem, exerciseItemClass, stackIndex, itemIndex, enabled) => {
    return (
      <div
        className={classNames(
          exerciseItemClass, exerciseStyles.comboBoxCard,
          {
            [exerciseStyles.cardDisabled]: (!enabled && !exerciseItem.checked),
            [exerciseStyles.checked]: exerciseItem.checked,
          },
        )}
        style={{
          background: exerciseItem.checked ? colors['C516'] : colors['C527'],
          borderColor: exerciseStyles.checked ?
            (exerciseItem.userAnswerStatus === AnswerStatusEnums.CORRECT ? colors['C30'] : colors['C29']) : colors['C160'],
        }}
        key={itemIndex}
      >
        <div className='d-flex'>
          <div
            className={exerciseStyles.cardNumberBadge}
            style={{
              background: colors['C144'], color: colors['C233'],
              borderColor: exerciseItem.checked
                ? (exerciseItem.userAnswerStatus === AnswerStatusEnums.CORRECT ? colors['C30'] : colors['C29'])
                : null,
            }}
          >
            {itemIndex + 1}
          </div>
          <GivenTemplate
            exerciseItem={exerciseItem}
            givenText={renderGivenText(exerciseItem, stackIndex, itemIndex)}
            fullTextAnswer={exerciseItem.checked && (exerciseItem.answerIsCorrect || exerciseItem.unlocked) ? exerciseItem.fullTextAnswer : null}
          />
          <div className={exerciseStyles.cardNumberBadge} />
        </div>
        {!exerciseItem.given.type.includes(ContentTypesStructureEnums.TEXT) &&
          <div className='d-flex justify-content-center'>
            <ComboBoxDropdown
              dropdownItems={exerciseItem.choices[0].items.map(option => {
                return {
                  text: option.text.sentenceString,
                  selected: option.selected,
                  onClick: () => onClickOptionComboBox(option, exerciseItem, stackIndex, itemIndex, enabled),
                }
              })}
            />
          </div>
        }
        {exerciseItem.checked ?
          (exerciseItem.choices.some(obj => obj.userAnswer.userAnswerStatus === AnswerStatusEnums.INCORRECT) &&
            <div className={classNames(exerciseStyles.exerciseCardFooter)}>
              <div className='d-flex gap-3 align-items-center'>
                {/*<ExerciseAIAssistantButton/>*/}
                <Lock
                  onClickLock={() => dispatch(toggleLockPaginable({ stackIndex, itemIndex }))}
                  isLocked={!exerciseItem.unlocked}
                />
              </div>
            </div>
          ) : (
            !data?.immediateCheck ?
              <div className={classNames(exerciseStyles.exerciseCardFooter)}>
                <ExerciseItemCheck
                  disabled={!enabled || checkingItem}
                  checking={enabled && checkingItem}
                  onClick={() => onCheckItem(data.stacks[stackIndex].id, exerciseItem, enabled)}
                />
              </div> :
              null
          )
        }
      </div>
    )
  }

  return (
    <div className={exerciseStyles.comboBoxContainer}>
      <PaginableExerciseModule
        renderExerciseItem={renderExerciseItem}
      />
    </div>
  )
}

export default ComboBoxModule