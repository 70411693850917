import * as React from "react"

const SectionsOutLineSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        width={512}
        height={512}
        viewBox="0 0 512 512"
    >
        <style>
            {
                ".SectionsOutLineSvg-st3{fill:none;stroke-width:29.8242;stroke-miterlimit:13.3333}"
            }
        </style>
        <defs>
            <filter
                id="Adobe_OpacityMaskFilter"
                width={509}
                height={509}
                x={1.5}
                y={1.5}
                filterUnits="userSpaceOnUse"
            >
                <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
            </filter>
        </defs>
        <mask
            id="custom_00000005975702156654196190000006463306520524970899_"
            width={509}
            height={509}
            x={1.5}
            y={1.5}
            maskUnits="userSpaceOnUse"
        >
            <path                d="M1.5 1.5h509v509H1.5z"
                style={{
                    fill: "#fff",
                    filter: "url(#Adobe_OpacityMaskFilter)",
                }}
            />
        </mask>
        <g
            style={{
                mask: "url(#custom_00000005975702156654196190000006463306520524970899_)",
            }}
        >
            <g transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
                <g>
                    <defs>
                        <path id="SectionsOutLineSvg-SVGID_1_" d="M-62.5 65.5h509v509h-509z" />
                    </defs>
                    <clipPath id="SVGID_00000180340519462525423810000002959121431654775190_">
                        <use
                            xlinkHref="#SectionsOutLineSvg-SVGID_1_"
                            style={{
                                overflow: "visible",
                            }}
                        />
                    </clipPath>
                    <g
                        style={{
                            clipPath:
                                "url(#SVGID_00000180340519462525423810000002959121431654775190_)",
                        }}
                    >
                        <g transform="translate(316.024 316.024)">
                            <path
                                d="M-79.3 48.7H50C33.8 112-16 161.8-79.3 178V48.7z"
                                className="SectionsOutLineSvg-st3"
                                stroke={color}
                            />
                        </g>
                        <g transform="translate(256.024 256.024)">
                            <path
                                d="M-64 64v179.7c-99.2 0-179.7-80.5-179.7-179.7S-163.3-115.7-64-115.7c99.2 0 179.7 80.5 179.7 179.7H-64z"
                                className="SectionsOutLineSvg-st3"
                                stroke={color}
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SectionsOutLineSvg
