import * as React from 'react'

const FullscreenSvg = ({ color }) => (
	<svg
		fill={color}
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		width={512}
		height={512}
		viewBox="0 0 512 512"
		xmlSpace="preserve"
	>
		<g>
			<g>
				<g>
					<path d="M165.4,0H17.1C7.6,0,0,7.6,0,17.1v136.5c0,9.4,7.6,17.1,17.1,17.1s17.1-7.6,17.1-17.1V34.1h131.2 c9.4,0,17.1-7.6,17.1-17.1S174.8,0,165.4,0z" />
					<path d="M165.4,477.9H34.1V358.4c0-9.4-7.6-17.1-17.1-17.1S0,349,0,358.4v136.5c0,9.4,7.6,17.1,17.1,17.1h148.3 c9.4,0,17.1-7.6,17.1-17.1C182.4,485.5,174.8,477.9,165.4,477.9z" />
					<path d="M494.9,0H379.6c-9.4,0-17.1,7.6-17.1,17.1s7.6,17.1,17.1,17.1h98.3v119.5c0,9.4,7.6,17.1,17.1,17.1 c9.4,0,17.1-7.6,17.1-17.1V17.1C512,7.6,504.4,0,494.9,0z" />
					<path d="M494.9,341.3c-9.4,0-17.1,7.6-17.1,17.1v119.5h-98.3c-9.4,0-17.1,7.6-17.1,17.1c0,9.4,7.6,17.1,17.1,17.1h115.4 c9.4,0,17.1-7.6,17.1-17.1V358.4C512,349,504.4,341.3,494.9,341.3z" />
				</g>
			</g>
		</g>
	</svg>
)

export default FullscreenSvg
