import React, { useContext } from 'react'
import classNames from 'classnames'
import styles from './InlineInputField.module.scss'
import colorsContext from '../../contexts/ColorsContext'
import getUserStatusColor from '../../operations/getUserStatusColor'
import { AnswerStatusEnums } from '../../enums/globalEnums/globalEnums'

const InlineInputField = ({ isInput, value, onChange, status = AnswerStatusEnums.SKIPPED, inputColorCode = 'C222' }) => {
	const { colors } = useContext(colorsContext)

	if (isInput) {
		return (
			<div
				contentEditable={true}
				// TODO: Check this out, What is the best alternative for onKeyPress event that is deprecated?
				onKeyPress={(e) => {
					return e.key === 'Enter' ? e.preventDefault() : true
				}}
				className={styles.middleInput}
				onInput={onChange}
				style={{ borderColor: colors[inputColorCode], color: colors['C23'] }}
			/>
		)
	} else {
		return (
			<span
				className={classNames(styles.middleInput, 'text-center')}
				style={{ borderColor: colors[inputColorCode], color: colors[getUserStatusColor(status)] }}
			>
				{value ?? <>&emsp;&emsp;&emsp;</>}
			</span>
		)
	}
}

export default InlineInputField