import './styles/tailwind.css'
import React from 'react'
import { Provider } from 'react-redux'
import App from './App'
import store from './redux/store'
import { createRoot } from 'react-dom/client'
import './initialScripts'
import * as Sentry from '@sentry/react'
import { removeQueryParamsAndNavigate } from './util/utility'

// Clean extra query params
removeQueryParamsAndNavigate(['phraseOptions', 'openPhraseOptions', 'phraseType', 'phraseUnit'])

// Sentry
const isProduction = window.location.hostname === 'app.vellso.com'
const isStage = window.location.hostname === 'stage-app.vellso.com'
const isDevelopment = window.location.hostname === 'dev-app.vellso.com'

// You can initiate sentry in development environment in the future
if (isProduction || isStage) {
	Sentry.init({
		dsn: 'https://3a5abe7b54f6f2aaa53c4b80e36392a7@sentry.vellso.com/2',
		integrations: [
			Sentry.replayIntegration({
				maskAllInputs: false, // Mask all input fields
				blockAllMedia: false, // Block all media elements (images, audio, video)
				maskAllText: false,
			}),
		],
		// tracesSampleRate: 0.2, // Adjust performance sampling rate
		replaysSessionSampleRate: 0, // Adjust session replay sampling rate
		replaysOnErrorSampleRate: 0,
		environment: isProduction ? 'production' : isStage ? 'stage' : 'development',
	})
}

const rootElement = document.getElementById('root') as HTMLElement

// Create root
const root = createRoot(rootElement)

root.render(
	<Provider store={store}>
		<App />
	</Provider>,
)
