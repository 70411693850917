import serviceCall from "../../services";
import phraseTableMapper from "../../services/mapper/vocabulary/phraseTableMapper";

const getPhraseTableTypeUseCase = async ({unitId, searchTerm, sortBy, sortType, paged, page, size, baseData}) => {
  const result = await serviceCall(
    'fetchPhrases',
    {unitId, searchTerm, sortBy, sortType, paged, page, size},
    'cloud',
  )

  return {
    ...result,
    content: phraseTableMapper(result?.content, baseData)
  }
}

export default getPhraseTableTypeUseCase