import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  inputCategoryName: '',
  selectedCategoryColor: '#FFFFFF'
}

const addCategoryCardSlice = createSlice({
  name: 'addCategoryCard',
  initialState,
  reducers: {
    setInputCategoryName: (state, action) => {
      state.inputCategoryName = action.payload
    },
    setSelectedCategoryColor: (state, action) => {
      state.selectedCategoryColor = action.payload
    },
    clearCategoryStates: (state) => {
      state.inputCategoryName = ''
      state.selectedCategoryColor = '#FFFFFF'
    }
  },
})

export const {setInputCategoryName, setSelectedCategoryColor, clearCategoryStates} = addCategoryCardSlice.actions

export default addCategoryCardSlice.reducer