import serviceCall from "../../services";

const studyLogUseCase = async ({unitId, sectionType, type, action}) => {
  const response = await serviceCall(
    'postStudyLog',
    {unitId, sectionType, type, action},
    'cloud',
  )

  return {data: response, action}
}

export default studyLogUseCase