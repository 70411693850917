import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import serviceCall from '../../services'
import {ALL} from "../../enums/globalEnums/globalEnums"
import getFavoritesCategoryUseCase from "../../useCase/favorite/getFavoritesCategoryUseCase";
import getFavoritesCategoryContentUseCase from "../../useCase/favorite/getFavoritesCategoryContentUseCase";
import updateFavoritesCategoryUseCase from "../../useCase/favorite/updateFavoritesCategoryUseCase";
import deleteFavoritesCategoryUseCase from "../../useCase/favorite/deleteFavoritesCategoryUseCase";
import {baseAsyncThunk} from "../baseAsyncThunk";

const initialState = {
  loading: false,
  getCategoriesLoading: false,
  getCategoryContentLoading: false,
  page: ALL,
  currentPage: 0,
  categories: [],
  categoryContents: [],
  categoryContentsTotalPage: 0,
  categoryContentsIsLastPage: false,
  refresh: false,
  error: '',
}

export const getCategories = createAsyncThunk(
  'favorite/getCategories',
  ({type}) => {
    return getFavoritesCategoryUseCase({type})
  },
)

export const getCategoryContent = createAsyncThunk(
  'favorite/getCategoryContent',
  async ({type, categoryId, currentPage, postsPerPage, searchTerm, sortKey, sortBy, replaceRemovedItem}) => {
    const response = await getFavoritesCategoryContentUseCase({
      type,
      categoryId,
      currentPage,
      postsPerPage,
      searchTerm,
      sortKey,
      sortBy
    })

    return {
      response,
      replaceRemovedItem
    }
  },
)

export const saveCategories = baseAsyncThunk(
  'favorite/saveCategories',
  ({name, color}) => {
    return serviceCall(
      'saveFavoriteCategories',
      {name, color},
      'cloud',
    )
  },
)

export const updateCategories = createAsyncThunk(
  'favorite/updateCategories',
  ({type, id, name, color}) => {
    return updateFavoritesCategoryUseCase({type, id, name, color})
  },
)

export const deleteCategories = createAsyncThunk(
  'favorite/deleteCategories',
  ({type, id}) => {
    return deleteFavoritesCategoryUseCase({type, id})
  },
)

export const addFavorite = baseAsyncThunk(
  'favorite/addFavorite',
  ({unitId, trackerNumber, sentenceOrVocab, categoryId}) => {
    return serviceCall(
      'addFavorite',
      {unitId, trackerNumber, sentenceOrVocab, categoryId},
      'cloud',
    )
  },
)

export const moveFavorite = createAsyncThunk(
  'favorite/moveFavorite',
  ({categoryId, sentenceOrVocab, trackerNumber}) => {
    return serviceCall(
      'moveFavorite',
      {categoryId, sentenceOrVocab, trackerNumber},
      'cloud',
    )
  },
)

export const deleteFavorite = createAsyncThunk(
  'favorite/deleteFavorite',
  async ({id}) => {
    const response = await serviceCall(
      'deleteFavorite',
      {id},
      'cloud',
    )

    return {data: response, id}
  },
)

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    },
    onRefresh: (state) => {
      state.refresh = true
    },
    loadPage: (state) => {
      if (!state.categoryContentsIsLastPage && !state.loading) {
        state.currentPage++
        state.refresh = true
      }
    },
    clear: (state) => {
      state.currentPage = 0
      state.categoryContents = []
      state.refresh = true
    },
    removeFavoriteItemById: (state, action) => {
      state.categoryContents = state.categoryContents?.filter(it => it.id !== action.payload)
    }
  },
  extraReducers: builder => {
    // Add to favorites
    builder.addCase(addFavorite.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addFavorite.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(addFavorite.rejected, (state, action) => {
      state.loading = false
    })
    // Remove from favorites
    builder.addCase(deleteFavorite.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteFavorite.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(deleteFavorite.rejected, (state, action) => {
      state.loading = false
    })
    //getCategories
    builder.addCase(getCategories.pending, (state) => {
      state.getCategoriesLoading = true,
        state.loading = true
    })
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.getCategoriesLoading = false
        state.loading = false
      state.categories = action.payload
      state.requestRefreshCategories = false
    })
    builder.addCase(getCategories.rejected, (state, action) => {
      state.getCategoriesLoading = false
      state.error = action.error.message
        state.loading = false
    })
    //getCategoryContent
    builder.addCase(getCategoryContent.pending, (state) => {
        state.loading = true
      state.getCategoryContentLoading = true
    })
    builder.addCase(getCategoryContent.fulfilled, (state, action) => {
        state.loading = false
      state.getCategoryContentLoading = false
      state.refresh = false

      const content = action.payload.response.content || []
      if (action.payload.replaceRemovedItem && content.length > 0) {
        state.categoryContents = [...state.categoryContents, content[content.length - 1]]
      } else {
        state.categoryContents = [...state.categoryContents, ...content]
      }

      state.categoryContentsTotalPage = action.payload.response.totalPages
      state.categoryContentsIsLastPage = action.payload.response.last
    })
    builder.addCase(getCategoryContent.rejected, (state, action) => {
      state.getCategoryContentLoading = false
      state.loading = false
      state.refresh = false
      state.error = action.error.message
    })
    //saveCategories
    builder.addCase(saveCategories.pending, (state) => {
      state.loading = true
    })
    builder.addCase(saveCategories.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(saveCategories.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    //updateCategories
    builder.addCase(updateCategories.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateCategories.fulfilled, (state, action) => {
      state.loading = false
      state.categories = action.payload
    })
    builder.addCase(updateCategories.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    //deleteCategories
    builder.addCase(deleteCategories.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteCategories.fulfilled, (state, action) => {
      state.loading = false
      state.categories = action.payload

    })
    builder.addCase(deleteCategories.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  },
})

export const {
  setPage, loading,  loadPage, clear, onRefresh, removeFavoriteItemById
} = favoriteSlice.actions

export default favoriteSlice.reducer