import serviceCall from "../../services";
import newspaperMapper from "../../services/mapper/newspapers/newspaperMapper";

const newspapersUseCase = async () => {
	const result = await serviceCall(
		'fetchNewspapers',
		null,
		'cloud',
	)

	return result.map((video) => { return newspaperMapper(video) });
}

export default newspapersUseCase