import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import serviceCall from '../../services'

const initialState = {
	loading: false,
	data: null,
	error: '',
}

export const getOrdinalNumberSection = createAsyncThunk(
	'numberSection/fetchOrdinalNumberSection',
	() => {
		return serviceCall(
			'fetchOrdinalNumberSection',
			null,
			'cloud',
		)
	},
)

const ordinalNumberSectionSlice = createSlice({
	name: 'ordinalNumberSectionSlice',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getOrdinalNumberSection.pending, (state) => {
			state.loading = true
		})
		builder.addCase(getOrdinalNumberSection.fulfilled, (state, action) => {
			state.loading = false
			state.data = action.payload.data
		})
		builder.addCase(getOrdinalNumberSection.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message
		})
	},
})

// export const {} = ordinalNumberSectionSlice.actions

export default ordinalNumberSectionSlice.reducer