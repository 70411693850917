import {Link} from "react-router-dom"
import React from "react"
import classNames from "classnames";

const SecondaryHeader = ({data, type, style, background, color, fixed}) => {
  require("./secondaryHeader.scss")

  const renderLinks = () => {
    return data.map((item, i) => {
      return (
        <React.Fragment key={i}>
          {type === "link" ?
            <Link
              to={item.path}
              style={{color}}
              className={classNames(
                {['active']: window.location.pathname === item.path},
                'res:fs-md'
              )}
            >
              {item.icon &&
                <div className='icon'>
                  {item.icon}
                </div>
              }
              {item.name}
            </Link> :
            <button
              onClick={item.path}
              style={{color}}
              disabled={item.disabled}
              className={classNames(
                {['active']: item.active},
                'res:fs-md'
              )}
            >
              {item.icon &&
                <div className='icon'>
                  {item.icon}
                </div>
              }
              {item.name}
            </button>
          }
          {(i + 1) !== data.length && <span style={{background: color}}/>}
        </React.Fragment>
      )
    })
  }

  return (
    <div
      className={classNames("secondary-header", {['fixed']: fixed})}
      style={{...style, background}}
      data-jsx="SecondaryHeader"
    >
      {renderLinks()}
    </div>
  )
}

export default SecondaryHeader