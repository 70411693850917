import { generateExerciseTemplateClassName, htmlTextConversion } from '../mapperConversions'
import htmlToText from '../../../operations/htmlToText'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'

const fetchComboBoxMapper = (data) => {
	return {
		pageType: pageGuidEnums.COMBO_BOX_EXERCISE,
		data: {
			immediateCheck: true,
			paginable: true,
			type: data.type,
			title: data.title ?? null,
			stacks: [...data.exerciseStacks].map(stack => {
				return {
					id: stack.id,
					title: stack.title,
					joined: stack.joined,
					items: [...stack.items].map(item => {
						const given = item.data.givens[0]
						return {
							id: item.id,
							checked: false,
							unlocked: false,
							given: {
								...given,
								index: given.index,
								type: given.type,
								template: given.template,
								templateClassName: generateExerciseTemplateClassName(given.type, given.template),
								text: htmlTextConversion(given.text, true),
								imageUri: given.imageUri ?? null,
								voiceUri: given.voiceUri ?? null,
							},
							answerIsCorrect: false,
							choices: [...item.data.choices].map((choices, choicesIndex) => {
								return {
									index: choices.index,
									userAnswer: null,
									items: [...choices.items].map(choice => {
										const isAnswer = item.data.answers[choicesIndex].items.find(answer =>
											htmlToText(answer.text.sentence) === htmlToText(choice.text.sentence),
										)
										return {
											index: choice.index,
											text: htmlTextConversion(choice.text),
											selected: false,
											isAnswer,
										}
									}),
								}
							}),
							fullTextAnswers: [...item.extra.fullTextAnswers].map(fullTextItem => {
								return htmlTextConversion(fullTextItem)
							}),
						}
					}),
				}
			}),
			checked: false,
			checkable: false,
		}
	}
}

export default fetchComboBoxMapper