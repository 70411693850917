import {
  getSpacedRepetitionDataByKey,
} from "../../../enums/userEnums/spacedRepetitionEnums";
import {getNativeLanguageFlagKey, translationsLanguagesData} from "../../../enums/languageEnums/languageEnums";
import {TANGERINE} from "../../../enums/base/themeEnums";
import settingStudyReminderMapper from "../setting/settingStudyReminderMapper";
import settingNotificationMapper from "../setting/settingNotificationMapper";
import {isEmpty} from "lodash";
import { REPEAT_THREE_TIMES } from '../../../enums/globalEnums/globalEnums'

const userSettingMapper = (data) => {
  return {
    ...data,
    aiTokens: data?.aiTokens,
    availableAccent: data?.availableAccent,
    avatar: !isEmpty(data?.avatar) ? data?.avatar : '0',
    notificationSetting: settingNotificationMapper(data?.notificationSetting),
    region: {
      countryCode: data?.region?.countryCode,
      countryName: data?.region?.countryName,
      phonePrefix: data?.region?.phonePrefix
    },
    speechPace: data?.speechPace,
    status: data?.status,
    studyHardshipLevel: data?.studyHardshipLevel,
    studyPreferences: {
      courseIntensity: data?.studyPreferences?.courseIntensity,
      learningType: data?.studyPreferences?.learningType,
      spacedRepetition: data?.studyPreferences?.spacedRepetition,
      startingLevel: data?.studyPreferences?.startingLevel
    },
    studyReminderSetting: settingStudyReminderMapper(data?.studyReminderSetting),
    theme: data?.theme ?? TANGERINE,
    userPrivacySetting: data?.userPrivacySetting,
    numberOfRepetitions: getSpacedRepetitionDataByKey(data?.studyPreferences?.spacedRepetition ?? REPEAT_THREE_TIMES).repeatNumber,
    nativeLanguage: data?.nativeLanguage,
    nativeLanguageFlagKey: getNativeLanguageFlagKey(data?.nativeLanguage),
    nativeLanguageTitle: translationsLanguagesData[data?.nativeLanguage]?.title ?? null,
    nativeLanguageFontDirection: (
      data?.nativeLanguage === 'PERSIAN' ||
      data?.nativeLanguage === 'ARABIC'
    ) ? 'rtl' : 'ltr'
  }
}

export default userSettingMapper