import { htmlTextConversion } from '../mapperConversions'
import { shuffle } from 'lodash'
// import response from '../../offlineServicesData/exercise/fetchDragAndDrop.json'

const fetchDragAndDropMapperOld = response => {
	let choosesList = []
	const data = {
		paginable: false,
		isSingleTemplate: true,
		type: response.data.type,
		title: response.data.title ?? null,
		stacks: [...response.data.exerciseStacks].map(stack => {
			return {
				id: stack.id,
				joined: stack.joined,
				items: [...stack.items].map(item => {
					const given = item.data.givens[0]
					const choices = item.data.choices[0]
					const answers = item.data.answers[0]
					return {
						id: item.id,
						checked: false,
						given: {
							index: given.index,
							type: given.type,
							text: htmlTextConversion(given.text),
							template: given.template ?? null,
							imageUri: given.imageUri ?? null,
							voiceUri: given.voiceUri ?? null,
						},
						choices: {
							choicesItems: [...choices.items].map(choice => {
								const item = {
									index: choice.index,
									text: htmlTextConversion(choice.text),
									selected: false,
									userAnswerStatus: '',
									correctAnswer: '',
									hasBubble: false,
									template: choice.template ?? null,
									imageUri: choice.imageUri ?? null,
									voiceUri: choice.voiceUri ?? null,
								}
								choosesList.push(item)
								return item
							}),
						},
						answers: [...answers.items].map(answer => {
							const answerItem = {
								index: answer.index,
								selected: false,
								userAnswerStatus: '',
								correctAnswer: '',
								skipped: true,
								isAnswered: false,
								hasBubble: false,
								text: htmlTextConversion(answer.text),
								imageUri: answer.imageUri ?? null,
								voiceUri: answer.voiceUri ?? null,
							}
							return answerItem
						}),
					}
				}),
				choices: shuffle(choosesList),
			}
		}),
		checked: false,
		checkable: false,
		selectedPage: 0,
	}
	return {
		...response,
		data,
	}
}

export default fetchDragAndDropMapperOld
