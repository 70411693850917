import avatarStyle from "./Avatar.module.scss"
import useAppUser from "../../hooks/useAppUser";
import makeAvatarUrl from "../../operations/makeAvatarUrl";
import ChangePhotoModal from "../ChangePhotoModal/ChangePhotoModal";
import {useState} from "react";

const Avatar = ({borderColor, isEditable}) => {
  const {appUserData} = useAppUser()
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <button
        onClick={() => {
          if (isEditable) {
            setIsModalOpen(true)
          }
        }}

        className={avatarStyle.avatar} style={{border: borderColor ? `2px solid ${borderColor}` : null }}>
        <img src={makeAvatarUrl(appUserData?.setting?.avatar)} alt=""/>
      </button>
      {isModalOpen &&
        <ChangePhotoModal onClose={() => setIsModalOpen(false)}/>
      }
    </>

  )
}

export default Avatar