import StatisticsSvg from '../../icons/StatisticsSvg'
import GameSvg from '../../icons/GameSvg'
import ReviewMistakesSvg from '../../icons/ReviewMistakesSvg'
import InNutshellSvg from '../../icons/InNutshellSvg'
import RoboChatSvg from '../../icons/RoboChatSvg'
import DrillingSvg from '../../icons/DrillingSvg'
import ReadingSvg from '../../icons/ReadingSvg'
import PointPlusSvg from '../../icons/PointPlusSvg'
import PictionarySvg from '../../icons/PictionarySvg'
import SpeakingSvg from '../../icons/SpeakingSvg'
import GrammarWrapSvg from '../../icons/GrammarWrapSvg'
import ConversationSvg from '../../icons/ConversationSvg'
import MoreExamplesSvg from '../../icons/MoreExamplesSvg'
import ListeningSvg from '../../icons/ListeningSvg'
import SpellingSvg from '../../icons/SpellingSvg'
import WritingSvg from '../../icons/WritingSvg'

export const GRAMMAR_WRAP = {
	id: 1,
	titleData: 'GRAMMAR_WRAP',
	sectionType: 'GRAMMAR_WRAP',
	title: 'GrammarWrap',
	icon: (props) => <GrammarWrapSvg {...props} />,
	isExercise: false,
}
export const MORE_EXAMPLE = {
	id: 2,
	titleData: 'MORE_EXAMPLE',
	sectionType: 'MORE_EXAMPLE',
	title: 'More Examples',
	icon: (props) => <MoreExamplesSvg {...props} />,
	isExercise: false,
}
export const CONVERSATION = {
	id: 3,
	titleData: 'CONVERSATION',
	sectionType: 'CONVERSATION',
	title: 'Conversation',
	type: 'Conversation',
	icon: (props) => <ConversationSvg {...props} />,
	isExercise: false,
}
export const CONVERSATION_EXERCISE_1 = {
	id: 4,
	titleData: 'CONVERSATION_EXERCISE_1',
	sectionType: 'CONVERSATION_EXERCISE_1',
	title: 'Conversation Exercise 1',
	type: 'Conversation',
	icon: (props) => <ConversationSvg {...props} />,
	isExercise: true,
}
export const CONVERSATION_EXERCISE_2 = {
	id: 5,
	titleData: 'UNDERSTANDING_CONVERSATION_EXERCISE_2',
	sectionType: 'UNDERSTANDING_CONVERSATION_EXERCISE_2',
	title: 'Conversation Exercise 2',
	type: 'Conversation',
	icon: (props) => <ConversationSvg {...props} />,
	isExercise: true,
}
export const GRAMMAR_EXERCISE_1 = {
	id: 6,
	titleData: 'GRAMMAR_EXERCISE_3',
	sectionType: 'GRAMMAR_EXERCISE_3',
	title: 'Grammar Exercise 1',
	type: 'Grammar',
	icon: (props) => <GrammarWrapSvg {...props} />,
	isExercise: true,
}
export const GRAMMAR_EXERCISE_2 = {
	id: 7,
	titleData: 'GRAMMAR_EXERCISE_4',
	sectionType: 'GRAMMAR_EXERCISE_4',
	title: 'Grammar Exercise 2',
	type: 'Grammar',
	icon: (props) => <GrammarWrapSvg {...props} />,
	isExercise: true,
}
export const LISTENING_EXERCISE = {
	id: 8,
	titleData: 'LISTENING_EXERCISE_5',
	sectionType: 'LISTENING_EXERCISE_5',
	title: 'Listening Practice',
	type: 'Listening',
	icon: (props) => <ListeningSvg {...props} />,
	isExercise: true,
}
export const SPEAKING_EXERCISE = {
	id: 9,
	titleData: 'SPEAKING_EXERCISE_6',
	sectionType: 'SPEAKING_EXERCISE_6',
	title: 'Speaking Practice',
	type: 'Speaking',
	icon: (props) => <SpeakingSvg {...props} />,
	isExercise: true,
}
export const SPELLING_EXERCISE = {
	id: 10,
	titleData: 'SPELLING_EXERCISE_7',
	sectionType: 'SPELLING_EXERCISE_7',
	title: 'Spelling Practice',
	type: 'Spelling',
	icon: (props) => <SpellingSvg {...props} />,
	isExercise: true,
}
export const PICTIONARY = {
	id: 11,
	titleData: 'PICTIONARY',
	sectionType: 'PICTIONARY',
	title: 'Pictionary',
	type: 'Pictionary',
	icon: (props) => <PictionarySvg {...props} />,
	isExercise: false,
}
export const PICTIONARY_EXERCISE = {
	id: 12,
	titleData: 'PICTIONARY_EXERCISE_8',
	sectionType: 'PICTIONARY_EXERCISE_8',
	title: 'Pictionary Exercise',
	type: 'Pictionary',
	icon: (props) => <PictionarySvg {...props} />,
	isExercise: true,
}
export const POINT_PLUS = {
	id: 13,
	titleData: 'POINT_PLUS',
	sectionType: 'POINT_PLUS',
	title: 'Point Plus',
	type: 'Point Plus',
	icon: (props) => <PointPlusSvg {...props} />,
	isExercise: false,
}
export const POINT_PLUS_EXERCISE = {
	id: 14,
	titleData: 'POINT_PLUS_EXERCISE_9',
	sectionType: 'POINT_PLUS_EXERCISE_9',
	title: 'Point Plus Exercise',
	type: 'Point Plus',
	icon: (props) => <PointPlusSvg {...props} />,
	isExercise: true,
}
export const READING = {
	id: 15,
	titleData: 'READING',
	sectionType: 'READING',
	title: 'Reading',
	type: 'Reading',
	icon: (props) => <ReadingSvg {...props} />,
	isExercise: false,
}
export const READING_EXERCISE = {
	id: 16,
	titleData: 'READING_EXERCISE_10',
	sectionType: 'READING_EXERCISE_10',
	title: 'Reading Exercise',
	type: 'Reading',
	icon: (props) => <ReadingSvg {...props} />,
	isExercise: true,
}
export const WRITING_EXERCISE = {
	id: 17,
	titleData: 'WRITING_EXERCISE_11',
	sectionType: 'WRITING_EXERCISE_11',
	title: 'Writing',
	type: 'Writing',
	icon: (props) => <WritingSvg {...props} />,
	isExercise: true,
}
export const DRILLING = {
	id: 18,
	titleData: 'DRILLING',
	sectionType: 'DRILLING',
	title: 'Drill',
	icon: (props) => <DrillingSvg {...props} />,
	isExercise: false,
}
export const ROBO_CHAT = {
	id: 19,
	titleData: 'ROBO_CHAT',
	sectionType: 'ROBO_CHAT',
	title: 'RoboChat',
	icon: (props) => <RoboChatSvg {...props} />,
	isExercise: false,
}
export const IN_A_NUTSHELL = {
	id: 20,
	titleData: 'IN_A_NUTSHELL',
	sectionType: 'IN_A_NUTSHELL',
	title: 'In a Nutshell',
	icon: (props) => <InNutshellSvg {...props} />,
	isExercise: false,
}
export const GAME = {
	id: 21,
	titleData: 'GAME',
	sectionType: 'GAME',
	title: 'Games',
	icon: (props) => <GameSvg {...props} />,
	isExercise: false,
}
export const REVIEW_THE_MISTAKES = {
	id: 22,
	titleData: 'REVIEW_THE_MISTAKES',
	sectionType: 'REVIEW_THE_MISTAKES',
	title: 'Review Mistakes',
	icon: (props) => <ReviewMistakesSvg {...props} />,
	isExercise: false,
}
export const STATISTICS = {
	id: 23,
	titleData: 'STATISTICS',
	sectionType: 'STATISTICS',
	title: 'Statistics',
	icon: (props) => <StatisticsSvg {...props} />,
	isExercise: false,
}