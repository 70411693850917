import serviceCall from "../../services";

const deleteAllUseCase = async () => {
	const response = await serviceCall(
		'deleteAllMessages',
		null,
		'cloud',
	)

	return response
}

export default deleteAllUseCase