import { isEmpty } from "lodash";
import { AnswerStatusEnums } from '../enums/globalEnums/globalEnums'
import { answerComparator } from "../util/answerComparator/answerComparator";

const checkSentenceWordByWord = (value = '', title = '') => {
  const comparator = answerComparator(title, value)

  return comparator.wordByWordResult?.map((item, index) => {
    let status

    if (isEmpty(item.userStr)) {
      status = AnswerStatusEnums.SKIPPED
    } else if (item.match) {
      status = AnswerStatusEnums.CORRECT
    } else {
      status = AnswerStatusEnums.INCORRECT
    }

    return {
      index: index,
      userStr: item.userStr,
      correctStr: item.correctStr,
      status: status,
    }
  })
}

export default checkSentenceWordByWord