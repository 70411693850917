import * as React from "react"

const MicrophoneOffSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <g>
              <path d="M419.8,264.3c0-10.2-7.9-18.1-18.1-18.1c-10.2,0-18.1,7.9-18.1,18.1c0,70.4-57.2,127.6-127.6,127.6 s-127.6-57.2-127.6-127.6c0-10.2-7.9-18.1-18.1-18.1s-18.1,7.9-18.1,18.1c0,83.7,62.6,154.2,145.7,163.2v48.2h-65.6 c-10.2,0-18.1,7.9-18.1,18.1S162,512,172.3,512h167.3c10.2,0,18.1-7.9,18.1-18.1s-7.9-18.1-18.1-18.1H274v-48.2 C357.2,418.4,419.8,348,419.8,264.3z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M256.1,0c-55.4,0-100.5,45.2-100.5,100.5v163.2c0,56,45.2,100.5,100.5,101.1c55.4,0,100.5-45.2,100.5-100.5V100.5 C356.7,45.2,311.5,0,256.1,0z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
)

export default MicrophoneOffSvg
