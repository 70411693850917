import React from "react";

const WritingSvg = ({color}) => {
    return (
        <svg
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={512}
            height={512}
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <g transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
                <g>
                    <g>
                        <g>
                            <g>
                                <g transform="translate(102.0908,247.002)">
                                    <path d="M-77.7-67.5c-2.1,0-4.3,0.6-6.2,1.7c-4.1,2.3-6.4,6.8-6.2,11.5l2.6,43c0.1,1.9,0.7,3.8,1.6,5.4l25.1,43.4 c3.4,5.9,11,7.9,16.9,4.5c5.9-3.4,7.9-11,4.5-16.9l-23.6-40.9l-0.9-15.4l12.8,8.4l26.5,46.2c3.4,5.9,11,8,16.9,4.6 c5.9-3.4,8-11,4.6-16.9l-28-48.7c-1-1.7-2.3-3.1-3.9-4.2l-35.9-23.7C-72.9-66.8-75.3-67.5-77.7-67.5z M-21.4,68.8 c-2.1,0-4.2,0.5-6.2,1.7c-5.9,3.4-8,11-4.5,16.9l80.6,139.7c0,0,0,0.1,0,0.1c8.7,14.9,28.1,20.1,43.1,11.6 c0.1,0,0.1-0.1,0.2-0.1c14.9-8.7,20.1-28.1,11.6-43.1c0,0,0-0.1,0-0.1L33.9,75c-3.4-5.9-11-8-16.9-4.5c-5.9,3.4-8,11-4.5,16.9 l69.4,120.4c1.9,3.3,0.8,7.5-2.5,9.5c-3.4,1.8-7.5,0.7-9.4-2.6L-10.7,75C-13,71-17.1,68.8-21.4,68.8z" />
                                </g>
                                <g transform="translate(205.2197,250.9736)">
                                    <path d="M44.6-26.5C27.2-26.5,13-23,2.3-18.9c-16.2,6.3-27,15-32.6,20.3h-96.3c-24.2,0-43.9,19.7-43.9,43.9 s19.7,43.9,43.9,43.9h45.9c10.9,0,19.1,0.5,26.2,3.1c6.8,2.5,14.3-1,16.8-7.8c2.5-6.8-1-14.3-7.8-16.8 c-10.7-3.9-21.8-4.7-35.2-4.7h-45.9c-9.6,0-17.7-8.1-17.7-17.7s8.1-17.7,17.7-17.7h101.9c4,0,7.7-1.7,10.2-4.8 c2-2.4,38.1-43.1,112.9-11c6.7,2.9,14.4-0.2,17.2-6.9c2.9-6.7-0.2-14.4-6.9-17.2C84-22.8,62.6-26.5,44.6-26.5z" />
                                </g>
                                <g transform="translate(56.8848,191.7402)">
                                    <path d="M313.1,3.6h-61.2c-7.2,0-13.1,5.9-13.1,13.1v156.4h-9.4c-1.7,0-3.4,0.3-5,1l-106.7,44.3c-6.7,2.8-9.9,10.5-7.1,17.2 c2.8,6.7,10.5,9.9,17.2,7.1L232,199.3h81.1c7.2,0,13.1-5.9,13.1-13.1V16.7C326.2,9.5,320.3,3.6,313.1,3.6z M265,29.8h35v143.2 h-35V29.8z" />
                                    <path d="M-21.6,80.6c-9.8,0-18.7,3.6-25.2,10.5c-10,10.7-12,26.7-5,40.7c0,0,0,0,0,0.1c8.7,17.2,21.9,46,30.2,70.5 c6.3,18.5,10.8,29.4,31.5,38.2c13.9,6.1,26.4,12.3,36.2,18c1.7,1,3.2,1.9,4.7,2.8c14,8.5,23.7,13,45.4,0.5 c6.3-3.6,8.4-11.6,4.8-17.9c-3.6-6.3-11.6-8.4-17.9-4.8c-6.9,4-9.3,4.2-10.1,4.2c-1.5-0.1-4.8-2.1-8.5-4.4 c-1.5-0.9-3.2-2-5.1-3.1c-13.5-7.9-28.6-14.9-38.9-19.4c0,0-0.1,0-0.1-0.1c-10.9-4.6-11.7-7-17-22.5 c-8.7-25.8-22.5-55.9-31.6-73.9c-2-4.1-1.8-8.4,0.6-11c2.7-2.9,7.7-3.1,13.6-0.5c6.7,2.8,14.4-0.3,17.2-6.9 C6,95,2.9,87.3-3.8,84.4C-9.8,81.9-15.8,80.6-21.6,80.6z" />
                                </g>
                                <g transform="translate(140.3145,313.6768)">
                                    <path d="M-59.5,2.3c-1.3,0-2.5,0.2-3.8,0.6c-6.9,2.1-10.9,9.4-8.8,16.3c2.6,8.6,4.4,15.1,5.9,20.3c2.8,9.9,4.1,14.8,6.7,19.1 c3.5,5.8,8.1,8.3,12.9,10.9c2.1,1.2,4.5,2.5,7.7,4.4c6.2,3.8,14.3,1.9,18.1-4.3c3.8-6.2,1.9-14.3-4.3-18.1 c-3.7-2.3-6.7-3.9-8.9-5.1c-1.2-0.7-2.5-1.4-3.2-1.8c-0.8-1.7-2.2-6.9-3.7-12.2c-1.4-5-3.3-11.9-6-20.8 C-48.6,5.9-53.9,2.3-59.5,2.3z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default WritingSvg