import serviceCall from "../../services";

const addCategoryUseCase = async ({ title, color }) => {
	const result = serviceCall(
		'addMemoryBoxCategory',
		{ title, color },
		'cloud',
	)

	return result
}

export default addCategoryUseCase 