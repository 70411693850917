import * as React from "react"

const VocabularySvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <g>
            <g>
                <g>
                    <g transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
                        <path d="M244.1,305.4H125.9c-23.9,0-43.3,19.4-43.3,43.3V467c0,23.9,19.4,43.3,43.3,43.3h118.3c23.9,0,43.3-19.4,43.3-43.3 V348.5c0,0,0-0.1,0-0.1C287.3,324.7,267.9,305.4,244.1,305.4z M125.9,482.5c-8.7,0-15.5-6.8-15.5-15.5V348.7 c0-8.7,6.8-15.5,15.5-15.5h118.2c8.6,0,15.6,6.9,15.6,15.4V467c0,8.7-6.8,15.5-15.5,15.5H125.9z" />
                        <path d="M219.8,352.4c-4.5,0-8.8,2.8-10.5,7.3l-24.2,64l-24.4-64c-2.2-5.8-8.7-8.7-14.5-6.5c-5.8,2.2-8.7,8.7-6.5,14.5 l33.1,86.8c0.1,0.2,0.1,0.3,0.2,0.5c2.1,4.8,6.9,7.9,12.2,7.9c5.3,0,10-3.1,12.2-7.9c0.1-0.2,0.1-0.4,0.2-0.5l32.8-86.8 c2.2-5.8-0.7-12.3-6.5-14.5C222.5,352.7,221.1,352.4,219.8,352.4z" />
                        <path d="M209.5,373.9h-49c-6.2,0-11.3,5-11.3,11.3c0,6.2,5,11.2,11.3,11.2h49c6.2,0,11.3-5,11.3-11.2 C220.8,379,215.7,373.9,209.5,373.9z" />
                        <path d="M162.6,128.4H44.5c-23.9,0-43.3,19.4-43.3,43.3v118.2c0,23.9,19.4,43.3,43.3,43.3h118.2c23.9,0,43.3-19.4,43.3-43.3 V171.6c0,0,0-0.1,0-0.1C205.8,147.8,186.4,128.4,162.6,128.4z M44.5,305.4c-8.7,0-15.5-6.8-15.5-15.5V171.7 c0-8.7,6.8-15.5,15.5-15.5h118.1c8.6,0,15.6,6.9,15.6,15.5v118.3c0,8.7-6.8,15.5-15.5,15.5H44.5z" />
                        <path d="M76.7,222.9c-3,0-5.8,1.2-7.9,3.3c-2.1,2.1-3.3,5-3.3,8v40.6c0,6.2,5,11.2,11.3,11.2h25.6c17.3,0,31.4-14.2,31.4-31.6 c0-17.3-14.1-31.4-31.4-31.4C96.8,223.1,77,223,76.7,222.9C76.7,222.9,76.7,222.9,76.7,222.9z M88,263.6v-18 c5.5,0,11.6,0,14.4,0c4.9,0,8.9,4.1,8.9,8.9c0,5-4,9.1-8.9,9.1H88z" />
                        <path d="M76.7,175.4c-3,0-5.8,1.2-7.9,3.3c-2.1,2.1-3.3,5-3.3,8v47.6c0,6.2,5,11.3,11.3,11.3h30c19.3,0,35-15.7,35-35.1 c0-19.3-15.7-34.9-35-34.9C100.3,175.6,77,175.5,76.7,175.4C76.7,175.4,76.7,175.4,76.7,175.4z M88,223.1V198 c7,0,15.3,0.1,18.7,0.1c6.9,0,12.5,5.6,12.5,12.4c0,6.8-5.7,12.6-12.5,12.6H88z" />
                        <path d="M339.6,128.4H221.4c-23.9,0-43.3,19.4-43.3,43.3v118.2c0,23.9,19.4,43.3,43.3,43.3h118.3c23.9,0,43.3-19.4,43.3-43.3 V171.6c0,0,0-0.1,0-0.1C382.8,147.8,363.4,128.4,339.6,128.4z M221.4,305.4c-8.7,0-15.5-6.8-15.5-15.5V171.7 c0-8.7,6.8-15.5,15.5-15.5h118.2c8.6,0,15.6,6.9,15.6,15.5v118.3c0,8.7-6.8,15.5-15.5,15.5H221.4z" />
                        <path d="M288.6,175.2c-30.5,0-55.4,24.8-55.4,55.3s24.8,55.3,55.4,55.3c11.1,0,21.8-3.3,31-9.5c5.1-3.5,6.5-10.5,3-15.6 c-3.5-5.1-10.5-6.5-15.6-3c-5.6,3.8-11.8,5.7-18.4,5.7c-18.1,0-32.8-14.7-32.8-32.8s14.7-32.8,32.8-32.8 c6.7,0,12.3,1.9,16.7,5.8c1.1,1,1.8,1.8,2.6,2.7c4,4.7,11.1,5.3,15.9,1.2c4.7-4,5.3-11.1,1.2-15.9c-1.4-1.6-2.7-3-4.5-4.6 c-0.1-0.1-0.1-0.1-0.2-0.2C311.8,179.2,300.8,175.2,288.6,175.2z" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default VocabularySvg
