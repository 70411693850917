import serviceCall from "../../services";
import plansMapper from "../../services/mapper/payment/plansMapper";

const getPlansUseCase = async ({asRenewal, purchaseType}) => {
  const result = await serviceCall(
    'fetchChooseCourse',
      {asRenewal,timezoneLocation:Intl.DateTimeFormat().resolvedOptions().timeZone},
    'cloud',
  )

  return plansMapper(result, purchaseType)
}

export default getPlansUseCase