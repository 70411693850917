import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	playerOneName: 'Player 1',
	playerOneCorrectCount: 0,
	playerOneIncorrectCount: 0,
	playerOneScore: 0,
	playerTwoName: 'Player 2',
	playerTwoCorrectCount: 0,
	playerTwoIncorrectCount: 0,
	playerTwoScore: 0,
	skippedCount: 0,
	dualGameFinished: false,
	gameMinUnit: 0,
	gameMaxUnit: 0
}

const dualGameSlice = createSlice({
	name: 'dualGameSlice',
	initialState,
	reducers: {
		setPlayerOneName: (state, action) => {
			state.playerOneName = action.payload
		},
		increasePlayerOneCorrectCount: (state) => {
			++state.playerOneCorrectCount
		},
		increasePlayerOneIncorrectCount: (state) => {
			++state.playerOneIncorrectCount
		},
		increasePlayerOneScore: (state) => {
			++state.playerOneScore
		},
		setPlayerTwoName: (state, action) => {
			state.playerTwoName = action.payload
		},
		increasePlayerTwoCorrectCount: (state) => {
			++state.playerTwoCorrectCount
		},
		increasePlayerTwoIncorrectCount: (state) => {
			++state.playerTwoIncorrectCount
		},
		increasePlayerTwoScore: (state) => {
			++state.playerTwoScore
		},
		increaseSkippedCount: (state) => {
			++state.skippedCount
		},
		setDualGameUnit: (state, action) => {
			state.gameMinUnit = action.payload.fromUnit
			state.gameMaxUnit = action.payload.toUnit
		},
		setDualGameFinished: (state, action) => {
			state.dualGameFinished = action.payload
		},
		resetPlayerScoresData: (state) => {
			state.playerOneCorrectCount = 0
			state.playerOneIncorrectCount = 0
			state.playerOneScore = 0
			state.playerTwoCorrectCount = 0
			state.playerTwoIncorrectCount = 0
			state.playerTwoScore = 0
			state.dualGameUnit = 0
			state.dualGameFinished = false
		},
	},
})

export const {
	setPlayerOneName,
	increasePlayerOneCorrectCount,
	increasePlayerOneIncorrectCount,
	increasePlayerOneScore,
	setPlayerTwoName,
	increasePlayerTwoCorrectCount,
	increasePlayerTwoIncorrectCount,
	increasePlayerTwoScore,
	resetPlayerScoresData,
	setDualGameUnit,
	setDualGameFinished,
	increaseSkippedCount,
} = dualGameSlice.actions

export default dualGameSlice.reducer