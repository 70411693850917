import React, { useContext, useState } from 'react'
import colorsContext from '../../../contexts/ColorsContext'
import SkipModal from '../../../pages/Sections/Conversation/SkipModalConversation/SkipModal'

const SkipRepeatButton = ({ isReviewMistake, sectionType }) => {
	const { colors } = useContext(colorsContext)
	const [isOpen, setIsOpen] = useState(false)

	return (
		<>
			<button className="link-style font-weight-600" onClick={() => setIsOpen(true)} style={{ color: colors['C2'] }}>
				Skip
			</button>
			{isOpen && (
				<SkipModal isReviewMistake={isReviewMistake} sectionType={sectionType} closeModal={() => setIsOpen(false)} />
			)}
		</>
	)
}

export default SkipRepeatButton
