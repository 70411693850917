import React from "react";
import FemaleSvg from "../icons/FemaleSvg";
import MaleSvg from "../icons/MaleSvg";
import { FEMALE, MALE } from '../enums/globalEnums/globalEnums'

const GenderIcon = ({type, color}) => {
    return (
        <div className="gender-icon-wrapper">
            {type === FEMALE &&
                <FemaleSvg color={color}/>
            }
            {type === MALE &&
                <MaleSvg color={color}/>
            }
        </div>
    )
}

export default GenderIcon;