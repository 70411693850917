import serviceCall from "../../services";

const sendContactUseCase = async ({ name, email, category, message }) => {
	return serviceCall(
		'sendContact',
		{ name, email, category, message },
		'cloud',
	)
}

export default sendContactUseCase 