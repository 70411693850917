import useAudioRecorder from "../useAudioRecorder";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTextFromAudio, setSpeechRecognitionType } from "../../redux/features/speechToTextSlice";
import useWebSpeechApi from "./useWebSpeechApi";
import { processSpeechText } from "./processSpeechText";
import { speechToTextTypeEnums } from "../../enums/speechToTextEnums/speechToTextEnums";

const useSpeechToTextApi = (props) => {
  const {
    webSpeechRecording,
    webSpeechResult,
    webSpeechRecordFinished,
    webSpeechNotSupported,
    webSpeechError,
    webSpeechStartRecording,
    webSpeechFinishRecording,
    webSpeechResetRecording,
    webSpeechFailed
  } = useWebSpeechApi()

  const { startRecording, stopRecording, recorderError, audioBlob, recording, emptyAudioBlob } =
    useAudioRecorder({
      encodeType: 'ogg',
      recognizeEndOfSpeech: true
    })

  const { speechRecognitionType, loading } = useSelector(state => state.speechToText)
  const [speechResult, setSpeechResult] = useState(null)
  const [recordFinished, setRecordFinished] = useState(false)
  const [recordCanceled, setRecordCanceled] = useState(false)
  const apiType = useRef(
    props.speechType ?? speechRecognitionType ??
    (webSpeechNotSupported ? speechToTextTypeEnums.whisper : speechToTextTypeEnums.speechRecognitionApi)
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (webSpeechFailed) {
      dispatch(setSpeechRecognitionType(speechToTextTypeEnums.whisper))
      apiType.current = speechToTextTypeEnums.whisper
      handleStartRecording()
    }
  }, [webSpeechFailed])

  useEffect(() => {
    if (!speechRecognitionType) {
      dispatch(setSpeechRecognitionType(
        webSpeechNotSupported
          ? speechToTextTypeEnums.whisper
          : speechToTextTypeEnums.speechRecognitionApi
      ))
    }
  }, [])

  useEffect(() => {
    apiType.current = props.speechType ?? speechRecognitionType ??
      (webSpeechNotSupported ? speechToTextTypeEnums.whisper : speechToTextTypeEnums.speechRecognitionApi)
  }, [speechRecognitionType, props.speechType])

  useEffect(() => {
    if (!recording && audioBlob instanceof Blob) {
      if (recordCanceled) {
        setRecordCanceled(false)
      } else {
        const file = new File(
          [audioBlob],
          'userVoice',
          { type: 'audio/ogg' }
        )

        let bodyFormData = new FormData()
        bodyFormData.append('file', file)

        const promise = dispatch(getTextFromAudio({ formData: bodyFormData }))

        promise.then(res => {
          setSpeechResult(res?.payload ? processSpeechText(res?.payload, props?.given) : null)
          setRecordFinished(true)
          emptyAudioBlob()
        })

        return () => {
          if (typeof promise?.abort === 'function') {
            promise.abort()
          }
        }
      }
    }
  }, [audioBlob, recording])

  useEffect(() => {
    if (webSpeechRecordFinished && !webSpeechRecording) {
      setSpeechResult(processSpeechText(webSpeechResult, props?.given))
      setRecordFinished(true)
    }
  }, [webSpeechRecordFinished])

  useEffect(() => {
    setSpeechResult(webSpeechResult)
  }, [webSpeechResult])

  const resetRecording = () => {
    emptyAudioBlob()
    setSpeechResult(null)
    setRecordFinished(false)
  }

  const handleStartRecording = () => {
    if (apiType.current === speechToTextTypeEnums.speechRecognitionApi) {
      setSpeechResult(null)
      setRecordFinished(false)
      webSpeechResetRecording()
      webSpeechStartRecording()
    } else {
      resetRecording()
      startRecording()
    }
  }

  const cancelRecording = () => {
    if (apiType.current === speechToTextTypeEnums.speechRecognitionApi) {
      webSpeechResetRecording()
      setRecordFinished(true)
    } else {
      setRecordCanceled(true)
      stopRecording()
      setRecordFinished(true)
    }
  }

  const handleStopRecording = () => {
    if (apiType.current === speechToTextTypeEnums.speechRecognitionApi) {
      webSpeechFinishRecording()
    } else {
      stopRecording()
    }
  }

  const handleResetRecording = () => {
    if (apiType.current === speechToTextTypeEnums.speechRecognitionApi) {
      webSpeechResetRecording()
    } else {
      resetRecording()
    }
  }

  return {
    startRecording: handleStartRecording,
    finishRecording: handleStopRecording,
    cancelRecording,
    resetRecording: handleResetRecording,
    recorderError: apiType.current === speechToTextTypeEnums.whisper ? recorderError : webSpeechError,
    recording: apiType.current === speechToTextTypeEnums.whisper ? recording : webSpeechRecording,
    speechResult,
    recordFinished,
    speechToTextLoading: loading
  }
}

export default useSpeechToTextApi