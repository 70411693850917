import moment from "moment";
import {
  FRIDAY,
  getDayByKey,
  MONDAY,
  SATURDAY,
  SUNDAY,
  THURSDAY,
  TUESDAY,
  WEDNESDAY
} from "../../../enums/base/daysEnums";
import {toNumber} from "lodash";

const settingStudyReminderMapper = (data) => {
  return {
    ...data,
    userOffDates: data?.userOffDates?.map((item) => {
      const check = moment(item.date, 'YYYY-MM-DD');
      return {day: toNumber(check.format('D')), month: toNumber(check.format('M')), year: toNumber(check.format('YYYY'))}
    }),
    allTime: {
      hour: moment(data?.allTime).hour() ?? '00',
      minute: moment(data?.allTime).minute() ?? '00'
    },
    config: [
      {
        type: getDayByKey(MONDAY),
        selected: data?.monday ?? true,
        time: {
          hour: moment(data?.mondayFromTime).hour(),
          minute: moment(data?.mondayFromTime).minute()
        },
      },
      {
        type: getDayByKey(TUESDAY),
        selected: data?.tuesday ?? true,
        time: {
          hour: moment(data?.tuesdayFromTime).hour(),
          minute: moment(data?.tuesdayFromTime).minute()
        },
      },
      {
        type: getDayByKey(WEDNESDAY),
        selected: data?.wednesday ?? true,
        time: {
          hour: moment(data?.wednesdayFromTime).hour(),
          minute: moment(data?.wednesdayFromTime).minute()
        },
      },
      {
        type: getDayByKey(THURSDAY),
        selected: data?.thursday ?? true,
        time: {
          hour: moment(data?.thursdayFromTime).hour(),
          minute: moment(data?.thursdayFromTime).minute()
        },
      },
      {
        type: getDayByKey(FRIDAY),
        selected: data?.friday ?? true,
        time: {
          hour: moment(data?.fridayFromTime).hour(),
          minute: moment(data?.fridayFromTime).minute()
        },
      },
      {
        type: getDayByKey(SATURDAY),
        selected: data?.saturday ?? false,
        time: {
          hour: moment(data?.saturdayFromTime).hour(),
          minute: moment(data?.saturdayFromTime).minute()
        },
      },
      {
        type: getDayByKey(SUNDAY),
        selected: data?.sunday ?? false,
        time: {
          hour: moment(data?.sundayFromTime).hour(),
          minute: moment(data?.sundayFromTime).minute()
        },
      },
    ]
  }
}
export default settingStudyReminderMapper