import React, {useContext} from 'react'
import styles from './ExerciseStatistics.module.scss'
import colorsContext from '../../contexts/ColorsContext'
import classNames from 'classnames'
import SecondaryToplineTitle from '../SecondaryToplineTitle/SecondaryToplineTitle'
import {
  CONVERSATION_EXERCISE_1,
  CONVERSATION_EXERCISE_2,
  GRAMMAR_EXERCISE_1,
  GRAMMAR_EXERCISE_2,
  LISTENING_EXERCISE,
  PICTIONARY_EXERCISE,
  POINT_PLUS_EXERCISE, READING_EXERCISE,
  SPEAKING_EXERCISE,
  SPELLING_EXERCISE, WRITING_EXERCISE,
} from '../../enums/sectionEnums/sectionEnums'
import {toNumber} from 'lodash'

const ExerciseStatistics = ({data}) => {
  const {colors} = useContext(colorsContext)

  const renderExerciseProgressItem = ({sectionData, exerciseNumber}) => {
    const exerciseStatistics = data?.exercises?.find(o => o.sectionType === sectionData.sectionType)

    return (
      <div className={styles.exerciseProgressItem}>
        <div className='d-flex align-items-center'>
					<span className={styles.sectionIcon}>
						{sectionData.icon({color: colors['C214']})}
					</span>
          <p className={styles.sectionTitle} style={{color: colors['C214']}}>({sectionData.type})</p>
        </div>
        <div className={styles.rightItemsContainer}>
          <span className={styles.exerciseNumber} style={{color: colors['C187']}}>E{exerciseNumber}</span>
          <div className={styles.progressBarContainer}>
            <div className={styles.progressBar} style={{borderColor: colors['C187']}}>
              <div
                className={styles.progressBarInner}
                style={{
                  width: `${exerciseStatistics?.correctPercentage ?? 0}%`,
                  background: toNumber(exerciseStatistics?.correctPercentage) === 100 ? colors['C30'] : colors['C187'],
                }}
              />
            </div>
            <span
              className={styles.percentage}
              style={{color: colors['C214']}}
            >
							{exerciseStatistics?.correctPercentage ?? 0}%
						</span>
          </div>
        </div>
      </div>
    )
  }

  const renderExerciseTimeInfo = ({firstText, badgeColor}) => {
    return (
      <div className={styles.exerciseTimeInfo}>
        <span className={styles.exerciseTimeInfoBadge} style={{background: badgeColor}}/>
        <div className={styles.exerciseTimeInfoContent}>
          {firstText &&
            <p className={styles.exerciseTimeInfoText} style={{color: colors['C2']}}>
              {firstText}
            </p>
          }
        </div>
      </div>
    )
  }

  return (
    <div>
      <SecondaryToplineTitle title='Exercises'/>
      <div className={classNames(styles.exerciseStatisticsContainer, 'container-fluid')}>
        <div className={styles.exerciseProgressCard} style={{background: colors['C193']}}>
          {renderExerciseProgressItem({
            exerciseNumber: 1,
            sectionData: CONVERSATION_EXERCISE_1
          })}
          {renderExerciseProgressItem({
            exerciseNumber: 2,
            sectionData: CONVERSATION_EXERCISE_2
          })}
          {renderExerciseProgressItem({
            exerciseNumber: 3,
            sectionData: GRAMMAR_EXERCISE_1
          })}
          {renderExerciseProgressItem({
            exerciseNumber: 4,
            sectionData: GRAMMAR_EXERCISE_2
          })}
          {renderExerciseProgressItem({
            exerciseNumber: 5,
            sectionData: LISTENING_EXERCISE
          })}
          {renderExerciseProgressItem({
            exerciseNumber: 6,
            sectionData: SPEAKING_EXERCISE
          })}
          {renderExerciseProgressItem({
            exerciseNumber: 7,
            sectionData: SPELLING_EXERCISE
          })}
          {renderExerciseProgressItem({
            exerciseNumber: 8,
            sectionData: PICTIONARY_EXERCISE
          })}
          {renderExerciseProgressItem({
            exerciseNumber: 9,
            sectionData: POINT_PLUS_EXERCISE
          })}
          {renderExerciseProgressItem({
            exerciseNumber: 10,
            sectionData: READING_EXERCISE
          })}
          {renderExerciseProgressItem({
            exerciseNumber: 11,
            sectionData: WRITING_EXERCISE
          })}
        </div>
        <div className={styles.exerciseStatisticsInfo}>
          <div className={styles.exerciseSuccessProgress}>
            <p style={{color: colors['C2']}}>Success:</p>
            <div className={styles.exerciseSuccessProgressBar} style={{background: colors['C193']}}>
              <div
                className={styles.exerciseSuccessProgressBarInner}
                style={{width: `${data?.correctPercentage ?? 0}%`, background: colors['C57']}}
              />
            </div>
            <p style={{color: colors['C2']}}>{data?.correctPercentage ?? 0}%</p>
          </div>
          {data?.startDate &&
            <div className='row row-cols-3 row-cols-lg-1'>
              {renderExerciseTimeInfo({
                firstText: `Start Date: ${data?.startDate ?? 'Not started yet'}`,
                badgeColor: colors['C58']
              })}
              {renderExerciseTimeInfo({
                firstText: `Finish Date: ${data?.endDate ?? 'Not finished yet'}`,
                badgeColor: colors['C18']
              })}
              {renderExerciseTimeInfo({
                firstText: `Duration: ${data?.duration ?? '-'}`,
                badgeColor: colors['C215']
              })}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ExerciseStatistics