import * as React from "react"

const FAQSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <g>
            <g>
                <g>
                    <g>
                        <g>
                            <g transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
                                <path d="M191.3,175.8c-4.4,0-8.5,2.1-11.1,5.6l-18,24h-127c-19.9,0-36.1,16.2-36.1,36.1v178.3c0,19.9,16.2,36.1,36.1,36.1 h312.2c19.7,0,35.9-16.1,36.1-36c0,0,0-0.1,0-0.1V241.5c0-19.9-16.2-36.1-36.1-36.1h-127l-18-24 C199.8,177.9,195.7,175.8,191.3,175.8z M35.2,428.1c-4.6,0-8.3-3.7-8.3-8.3V241.5c0-4.5,3.8-8.3,8.3-8.3h133.9 c4.4,0,8.5-2.1,11.1-5.6l11.1-14.8l11.1,14.8c2.6,3.5,6.7,5.6,11.1,5.6h133.9c4.5,0,8.3,3.8,8.3,8.3v178.2 c-0.1,4.6-3.9,8.4-8.3,8.4H35.2z" />
                                <path d="M129.5,277.1c-1.3,0-2.7,0.2-4,0.7c-5.8,2.2-8.7,8.7-6.5,14.5l31.6,83.1c0.1,0.2,0.1,0.3,0.2,0.5 c2.2,5,7,8.1,12.4,7.9c5.3-0.1,10-3.5,11.9-8.5l31.3-83.1c2.2-5.8-0.7-12.3-6.6-14.5c-5.8-2.2-12.3,0.7-14.5,6.6l-22.6,59.9 L140,284.4C138.3,279.9,134,277.1,129.5,277.1z M154.1,367.5C154.1,367.5,154.1,367.5,154.1,367.5L154.1,367.5z" />
                                <path d="M186.4,297.8h-47c-6.2,0-11.3,5-11.3,11.3c0,6.2,5,11.3,11.3,11.3h47c6.2,0,11.3-5,11.3-11.3 C197.7,302.9,192.6,297.8,186.4,297.8z" />
                                <path d="M68.8,277.3c-6.2,0-11.3,5-11.3,11.3v83.7c0,6.2,5,11.3,11.3,11.3h34c6.2,0,11.3-5,11.3-11.3c0-6.2-5-11.2-11.3-11.2 H80v-72.4C80,282.3,75,277.3,68.8,277.3z" />
                                <path d="M100,319.9H68.8c-6.2,0-11.3,5-11.3,11.3c0,6.2,5,11.3,11.3,11.3H100c6.2,0,11.3-5,11.3-11.3 C111.3,324.9,106.2,319.9,100,319.9z" />
                                <path d="M313.7,277.5c-2.9,0-5.8,1.1-8,3.3l-27,27c-4.4,4.4-4.4,11.5,0,15.9c4.4,4.4,11.5,4.4,15.9,0l27-27 c4.4-4.4,4.4-11.5,0-15.9C319.5,278.5,316.6,277.5,313.7,277.5z" />
                                <path d="M271.2,277.3c-29.4,0-53.3,23.9-53.3,53.3c0,29.4,23.9,53.4,53.3,53.4c0.1,0,0.2,0,0.2,0c14.1,0,27.4-5.5,37.4-15.5 c10.1-10.1,15.7-23.5,15.7-37.9C324.6,301.2,300.6,277.3,271.2,277.3z M271.4,361.5c0,0-0.1,0-0.1,0 c-17.1,0-30.9-13.8-30.9-30.9c0-17,13.8-30.8,30.8-30.8c17,0,30.9,13.8,30.9,30.8c0,8.3-3.2,16.1-9.1,21.9 C287.2,358.3,279.5,361.5,271.4,361.5z" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default FAQSvg
