import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getPaymentResultUseCase from "../../useCase/payment/getPaymentResultUseCase";

const initialState = {
	loading: false,
	error: '',
	result: null
}

export const getResult = createAsyncThunk(
	'callBackResult/getResult',
	({paymentId}) => getPaymentResultUseCase({paymentId}),
)


const callBackResultSlice = createSlice({
	name: 'callBackResult',
	initialState,
	reducers: {
	},
	extraReducers: builder => {
		//get Result
		builder.addCase(getResult.pending, (state) => {
			state.loading = true
		})
		builder.addCase(getResult.fulfilled, (state, action) => {
			state.loading = false
			state.result = action.payload

		})
		builder.addCase(getResult.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message
		})
	}
})

export const {
} = callBackResultSlice.actions

export default callBackResultSlice.reducer