import * as React from "react"

const AllTemplateSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={48} height={48}>
        <g>
            <g transform="translate(11 12)" fill={color}>
                <rect
                    width={5.464}
                    height={5.464}
                    rx={2.732}
                />
                <rect
                    width={5.464}
                    height={5.464}
                    rx={2.732}
                    transform="translate(0 9.368)"
                />
                <rect
                    width={5.464}
                    height={4.684}
                    rx={2.342}
                    transform="translate(0 19.516)"
                />
                <path d="M9.107 1.561H25.5v2.342H9.107z" />
                <path d="M9.107 10.929H25.5v3.123H9.107z" />
                <path d="M9.107 20.296H25.5v3.123H9.107z" />
            </g>
            <g
                fill="none"
                stroke={color}
                strokeWidth={1.5}
                opacity={0.7}
            >
                <rect width={48} height={48} rx={10} stroke="none" />
                <rect x={0.75} y={0.75} width={46.5} height={46.5} rx={9.25} />
            </g>
        </g>
    </svg>
)

export default AllTemplateSvg
