import * as React from "react"

const CancelSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <g>
            <path d="M25,512c-13.8,0-25-11.2-25-25c0-6.6,2.6-13,7.3-17.7l462-462c9.8-9.8,25.6-9.8,35.4,0c9.8,9.8,9.8,25.6,0,35.4l-462,462 C38,509.4,31.6,512,25,512z" />
            <path d="M487,512c-6.6,0-13-2.6-17.7-7.3l-462-462c-9.8-9.8-9.8-25.6,0-35.4s25.6-9.8,35.4,0l462,462c9.8,9.8,9.8,25.6,0,35.4 C500,509.4,493.6,512,487,512z" />
        </g>
    </svg>
)

export default CancelSvg
