import flashCardMapper from "./flashCardMapper";

const flashCardsMapper = (data) => {

	return {
		results: data?.results.map((flashCard) => {
			return flashCardMapper(flashCard)
		}) || [],
		total: data?.total || 0
	};
}

export default flashCardsMapper;