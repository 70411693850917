import React from 'react'
import SubTitleTemplate from '../../pages/Sections/StructuredComponents/SubTitleTemplate/SubTitleTemplate'
import GrammarFocusTemplate
	from '../../pages/Sections/StructuredComponents/GrammarFocusTemplate/GrammarFocusTemplate'
import GrammarFormulaTemplate
	from '../../pages/Sections/StructuredComponents/GrammarFormulaTemplate/GrammarFormulaTemplate'
import InstructionTemplate
	from '../../pages/Sections/StructuredComponents/InstructionTemplate/InstructionTemplate'
import MainTitleTemplate from '../../pages/Sections/StructuredComponents/MainTitleTemplate/MainTitleTemplate'
import SimpleTitleTemplate from '../../pages/Sections/StructuredComponents/SimpleTitleTemplate/SimpleTitleTemplate'
import CustomTitleTemplate from '../../pages/Sections/StructuredComponents/CustomTitleTemplate/CustomTitleTemplate'

const TitleTemplateModule = ({ template }) => {

	const renderTemplate = () => {
		switch (template?.config?.displayMode) {
			case 'MAIN_TITLE':
				return <MainTitleTemplate template={template} />
			case 'INSTRUCTION':
				return <InstructionTemplate template={template} />
			case 'SUB_TITLE':
				return <SubTitleTemplate template={template} />
			case 'GRAMMAR_FOCUS':
				return <GrammarFocusTemplate template={template} />
			case 'GRAMMAR_FORMULA':
				return <GrammarFormulaTemplate template={template} />
			case 'SIMPLE':
				return <SimpleTitleTemplate template={template} />
			case 'CUSTOM':
				return <CustomTitleTemplate template={template} />
			default:
				return null
		}
	}

	return renderTemplate()
}

export default TitleTemplateModule