import serviceCall from "../../services";

const removeFromSuperMemoryBoxUseCase = async ({id}) => {
	const result = await serviceCall(
		'removeFromSuperMemoryBox',
		{id},
		'cloud',
	)

	return result
}

export default removeFromSuperMemoryBoxUseCase