import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setPage} from "../../../redux/features/readingSlice";
import Image from "../../../components/Image";
import HtmlTextWrapper from "../../../components/HtmlTextWrapper/HtmlTextWrapper";

const ReadingBook = () => {
  const states = useSelector(state => state.reading)
  const dispatch = useDispatch()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setPage('reading'))
    }, 4600)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [dispatch])

  return (
    <div className="reading-book-container">
      <div className="reading-book">
        <div className="cover"/>
        <div className="page page-1">
          <div className="page-content revert paragraph">
            <p>
              Linda and her family usually do the shopping once a month.
              <br/>
              They normally do it on the weekend, but sometimes they go shopping during the week too.
            </p>
          </div>
        </div>
        <div className="page page-2"/>
        <div className="page page-3">
          <div className="page-content image">
            <Image uri={states.data.pictureUri}/>
          </div>
        </div>
        <div className="page page-4">
          {states.data.readingData.length > 0 &&
            <HtmlTextWrapper
              className="page-content revert paragraph"
              textColor="#000"
              data={states.data.readingData[0]}
            />
          }
        </div>
        <div className="page page-5"/>
        <div className="cover turn">
          <div className="cover-content">
            <HtmlTextWrapper
              className="cover-title revert"
              textColor="#FF8800"
              data={states.data.title}
            />
            <div className="cover-image">
              <Image uri={states.data.pictureUri}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReadingBook