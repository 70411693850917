import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Modal from '../../../components/Modal'
import ThreeDotModal from '../../../components/ThreeDot/ThreeDotModal'
import WordMoreModal from '../../../components/ThreeDot/WordMoreModal'
import { isEmpty } from 'lodash'
import useQueryParams from '../../../hooks/useQueryParams'
import useAppUser from 'hooks/useAppUser'

const PhraseOptionsModal = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const openPhraseOptions = searchParams.get('openPhraseOptions')
	const phraseType = searchParams.get('phraseType')
	const phraseUnit = searchParams.get('phraseUnit')
	const phraseOptions = searchParams.getAll('phraseOptions')
	const { removeQueryParams } = useQueryParams()
	const { appUserData } = useAppUser()

	const close = () => {
		navigate(-1)
		removeQueryParams(['phraseOptions', 'openPhraseOptions', 'phraseType', 'phraseUnit'])
	}

	return (
		<>
			{(openPhraseOptions && appUserData?.access?.hasPlan) &&
				(phraseType === 'dictionary' ? (
					<Modal isCenter closeModal={close}>
						<WordMoreModal
							unitId={phraseUnit}
							optionsList={!isEmpty(phraseOptions) ? phraseOptions : undefined}
							phrase={openPhraseOptions}
						/>
					</Modal>
				) : (
					<Modal isCenter closeModal={close}>
						<ThreeDotModal
							unitId={phraseUnit}
							optionsList={!isEmpty(phraseOptions) ? phraseOptions : undefined}
							data={{ trackerNumber: openPhraseOptions }}
						/>
					</Modal>
				))}
		</>
	)
}

export default PhraseOptionsModal
