import React, { useContext, useEffect, useState } from 'react'
import colorsContext from '../../contexts/ColorsContext'
import Modal from '../Modal'
import { isMobile } from 'react-device-detect'
import BottomSheet from '../BottomSheet/BottomSheet'
import componentStyle from './ProvideAIChatFeedbackModal.module.scss'
import ThumbUpSvg from '../../icons/ThumbUpSvg'
import classNames from 'classnames'
import RadioButton from '../RadioButton/RadioButton'
import { HARMFUL, LIKE, NOT_HELPFUL, NOT_TRUE } from '../../enums/AIFeedbackEnums/AIFeedbackEnums'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import ThumbDownSvg from '../../icons/ThumbDownSvg'
import { useDispatch, useSelector } from 'react-redux'
import { sendAiPromptFeedback } from '../../redux/features/aiChatSlice'
import SuccessCircleSvg from '../../icons/SuccessCircleSvg'
import { close } from '@sentry/react'
import TranslatedText from '../TranslatedText/TranslatedText'
import useTranslatedText from '../../hooks/useTranslatedText'

const ProvideAIChatFeedbackModal = ({ promptId, dialogId, isLiked, closeModal, isExerciseAssistance }) => {
	const { colors } = useContext(colorsContext)
	const [feedbackType, setFeedbackType] = useState(isLiked ? LIKE : HARMFUL)
	const [description, setDescription] = useState('')
	const [placeholderLike, setPlaceholderLike] = useState('')
	const [placeholderDislike, setPlaceholderDislike] = useState('')
	const { showBottomSheet } = useSelector(state => state.bottomSheet)
	const { dir } = useTranslatedText() // rtl, ltr
	const dispatch = useDispatch()

	const onSubmitFeedback = () => {
		dispatch(sendAiPromptFeedback({
			promptId: promptId,
			aiPromptDetailId: dialogId,
			feedbackType,
			text: description,
			isExerciseAssistance,
		}))
	}


	const renderModalContent = (props) => {
		console.log(props)
		const [isSubmitted, setIsSubmitted] = useState(false)
		useEffect(() => {
			if (isSubmitted) {
				setTimeout(() => {
					props.close()
				}, 2000)
			}
		}, [isSubmitted])
		return (
			<>
				<div className="d-none">
					<TranslatedText middleware={(text) => setPlaceholderLike(text)} translationKey={'like.about.response'} />
					<TranslatedText middleware={(text) => setPlaceholderDislike(text)} translationKey={'dislike.about.response'} />
				</div>
				{isSubmitted ?

					<div className={componentStyle.svgAndDescription}>
						<div className={componentStyle.svg}><SuccessCircleSvg /></div>
						<div
							dir={dir}
							className={componentStyle.description}
							style={{
								paddingBottom: isMobile ? '50px' : '0px',
								color: colors['C589'],
							}}>
							<TranslatedText translationKey={'thanks.for.feedback'} />
						</div>
					</div>
					:
					<div>
						<div dir={dir}
								 className={classNames(componentStyle.title, 'res:fs-lg')}
								 style={{ color: colors['C589'] }}
						>
							{isLiked ?
								<span className={componentStyle.icon} style={{ background: '#39B24A4C' }}>
              <ThumbUpSvg color="#39B24A" />
            </span> :
								<span className={componentStyle.icon} style={{ background: '#E206134C' }}>
              <ThumbDownSvg color="#E20613" />
            </span>
							}
							<TranslatedText translationKey={'provide.additional.feedback'} />
						</div>
						<textarea
							dir={dir}
							className={componentStyle.textarea}
							rows="5"
							style={{
								color: colors['C589'],
								'--placeholder-color': colors['C589'] + '80',
							}}
							value={description}
							onChange={e => setDescription(e.target.value)}
							placeholder={
								isLiked
									? placeholderLike
									: placeholderDislike
							}
						/>
						{!isLiked &&
							<div dir={dir} className={componentStyle.options} style={{ color: colors['C589'] }}>
								<button onClick={() => setFeedbackType(HARMFUL)} className={componentStyle.optionItem}>
									<RadioButton selected={feedbackType === HARMFUL} />
									<span><TranslatedText translationKey={'this.is.harmful'} /></span>
								</button>
								<button onClick={() => setFeedbackType(NOT_TRUE)} className={componentStyle.optionItem}>
									<RadioButton selected={feedbackType === NOT_TRUE} />
									<span><TranslatedText translationKey={'this.is.not.true'} /></span>
								</button>
								<button onClick={() => setFeedbackType(NOT_HELPFUL)} className={componentStyle.optionItem}>
									<RadioButton selected={feedbackType === NOT_HELPFUL} />
									<span><TranslatedText translationKey={'this.is.not.helpful'} /></span>
								</button>
							</div>
						}
						<PrimaryButton
							className="mx-auto"
							onClick={() => {
								onSubmitFeedback()
								setIsSubmitted(true)
							}}
						>
							<TranslatedText translationKey={'submit.feedback'} />
						</PrimaryButton>
					</div>
				}
			</>
		)
	}

	if (isMobile) {
		return (
			<BottomSheet close={closeModal} hasSpace>
				{(props) => renderModalContent(props)}
			</BottomSheet>
		)
	} else {
		return (
			<Modal closeModal={closeModal} isCenter>
				<div className="modal-type-2 p-4" style={{ background: colors['C73'], borderRadius: 10 }}>
					{renderModalContent({ close: closeModal })}
				</div>
			</Modal>
		)
	}
}

export default ProvideAIChatFeedbackModal