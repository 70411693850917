import {SENTENCE} from "../../../enums/globalEnums/globalEnums";

const sentenceDataMapper = (data) => {
  return {
    type: SENTENCE,
    title: data.englishSentence,
    unitId: data.unitId,
    translation: data.translate?.translation ?? '',
    trackerNumber: data?.trackerNumber ?? '',
    accentAndAudios: [],
    partOfSpeeches: [],
    syllable: null,
    phonetic: null,
    favoriteId: data?.favoriteId,
    memoryBoxId: data?.memoryBoxId,
    voiceUri: data?.voiceUri ?? ''
  }
}
export default sentenceDataMapper