import serviceCall from "../../services";
import favoritesCategoryMapper from "../../services/mapper/favorite/favoritesCategoryMapper";

const deleteFavoritesCategoryUseCase = async ({type, id}) => {
    const result = await serviceCall(
        'deleteFavoriteCategories',
        {id},
        'cloud',
    )

    return favoritesCategoryMapper(result, type)
}

export default deleteFavoritesCategoryUseCase