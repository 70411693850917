import serviceCall from "../../services";

const addFlashCardUseCase = async ({categoryId, front, back, hint, toggleSuperMemory}) => {

  const bodyFormData = new FormData();
  bodyFormData.append('categoryId', categoryId);
  bodyFormData.append('front.text', front.text);
  bodyFormData.append('front.alignment', front.alignment);
  bodyFormData.append('front.direction', front.direction);

  bodyFormData.append('back.text', back.text);
  bodyFormData.append('back.alignment', back.alignment);
  bodyFormData.append('back.direction', back.direction);

  bodyFormData.append('hint.text', hint.text);
  bodyFormData.append('hint.alignment', hint.alignment);
  bodyFormData.append('hint.direction', hint.direction);

  bodyFormData.append('toggleSuperMemory', toggleSuperMemory);

  bodyFormData.append('isVocab', false);

  if (front?.userSelectImage !== null) {
    bodyFormData.append('imageFront', front?.userSelectImage?.file);
  }

  if (front?.userRecordAudio !== null) {
    bodyFormData.append('soundFront', front?.userRecordAudio?.file);
  }

  if (back?.userSelectImage !== null) {
    bodyFormData.append('imageBack', back?.userSelectImage?.file);
  }

  if (back?.userRecordAudio !== null) {
    bodyFormData.append('soundBack', back?.userRecordAudio?.file);
  }

  if (hint?.userSelectImage !== null) {
    bodyFormData.append('imageHint', hint?.userSelectImage?.file);
  }

  if (hint?.userRecordAudio !== null) {
    bodyFormData.append('soundHint', hint?.userRecordAudio?.file);
  }

  const result = serviceCall(
    'addMemoryBoxFlashCard',
    {bodyFormData},
    'cloud',
  )

  return result
}

export default addFlashCardUseCase