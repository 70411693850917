import React from "react";
import HelpBorderSvg from "../../icons/HelpBorderSvg";
import HelpButtonStyle from "./HelpButton.module.scss";
import HelpPage from "../../pages/HelpAndGuide/HelpPage";
import { useLocation, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import useQueryParams from "../../hooks/useQueryParams";

const HelpButton = ({ color, pageType, disabled = false }) => {
  const [searchParams] = useSearchParams()
  const reviewIdParam = searchParams.get('help')
  const location = useLocation()
  const { addQueryParams } = useQueryParams()

  return (
    <>
      <button
        onClick={() => {
          addQueryParams({ help: pageType }, { state: location.state })
        }}
        className={classNames(HelpButtonStyle.helpButton, { [HelpButtonStyle.disabled]: disabled || !pageType })}
        data-tour='help-button'
      >
        <HelpBorderSvg color={color} />
      </button>
      {(reviewIdParam === pageType && !disabled) &&
        <HelpPage page={pageType} />
      }
    </>
  )
}

export default HelpButton;