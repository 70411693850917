import {Dropdown} from 'react-bootstrap'
import React, {useContext, useState} from 'react'
import {find, isArray, isEmpty} from 'lodash'
import styles from './ComboBoxDropdown.module.scss'
import colorsContext from '../../contexts/ColorsContext'
import ArrowMoreSvg from '../../icons/ArrowMoreSvg'
import {isMobile} from "react-device-detect";
import BottomSheet from "../BottomSheet/BottomSheet";
import RadioButton from "../RadioButton/RadioButton";

  const ComboBoxDropdown = ({dropdownItems, disabled}) => {
  const {colors} = useContext(colorsContext)
  const selectedItem = find(dropdownItems, obj => obj.selected)
  const [bottomSheetIsOpen, setBottomSheetIsOpen] = useState(false)

  const renderItems = () => {
    if (isArray(dropdownItems) && !isEmpty(dropdownItems)) {
      return (
        <Dropdown.Menu
          className={styles.comboBoxDropdown}
          style={{background: colors['C529'], borderColor: colors['C530']}}
        >
          {dropdownItems.map((item, i) => (
            <Dropdown.Item
              as='button'
              className={styles.comboBoxItem}
              style={{color: colors['C160'], borderColor: colors['C160']}}
              onClick={item.onClick}
              key={i}
            >
              {item.text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )
    } else {
      return null
    }
  }

  // ComboBox in mobile mode
  if (isMobile) {
    return (
      <>
        <button
          className={styles.comboBoxDropdownContainer}
          disabled={disabled}
          onClick={() => setBottomSheetIsOpen(true)}
        >
          <div className={styles.comboBoxContainer} style={{borderColor: colors['C530']}}>
            <p className={styles.comboBoxValue} style={{color: colors['C530']}}>
              {selectedItem ? selectedItem.text : <span>&nbsp;</span>}
            </p>
            <span className={styles.comboBoxArrow}>
              <ArrowMoreSvg color={colors['C530']}/>
            </span>
          </div>
        </button>
        {bottomSheetIsOpen &&
          <BottomSheet
            hasSpace={true} overlayOpacity={0.2}
            close={() => setBottomSheetIsOpen(false)}
          >
            {(props) => (
              <div className={styles.comboBoxSheetItems}>
                {dropdownItems.map((item, i) => (
                  <button
                    key={i}
                    className={styles.item}
                    style={{background: item.selected ? '#2622612B' : null}}
                    onClick={() => {
                      item.onClick()
                      props.close()
                    }}
                  >
                    <RadioButton selected={item.selected}/>
                    <div style={{color: colors['C589']}}>{item.text}</div>
                  </button>
                ))}
              </div>
            )}
          </BottomSheet>
        }
      </>
    )
  }

  // ComboBox in desktop mode
  return (
    <Dropdown className={styles.comboBoxDropdownContainer} autoClose={true}>
      <Dropdown.Toggle className={styles.comboBoxContainer} style={{borderColor: colors['C530']}} disabled={disabled}>
        <p className={styles.comboBoxValue} style={{color: colors['C530']}}>
          {selectedItem ? selectedItem.text : <span>&nbsp;</span>}
        </p>
        <span className={styles.comboBoxArrow}>
					<ArrowMoreSvg color={colors['C530']}/>
				</span>
      </Dropdown.Toggle>
      {renderItems()}
    </Dropdown>
  )
}

export default ComboBoxDropdown