import structureBaseImageMapper from "./StructureBaseImageMapper";

const StructureSingleImageTemplateMapper = ({item, events}) => {
  return {
    ...item,
    picture: structureBaseImageMapper({image: item?.picture, text: null, events}),
    hiddenTime: parseInt(events?.view?.find(it => it?.templateId === item?.id)?.startTime) || 0
  }
}

export default StructureSingleImageTemplateMapper