import styles from './HelpVideoSuggestions.module.scss'
import {useContext} from "react";
import colorsContext from "../../../contexts/ColorsContext";
import classNames from "classnames";
import {isMobile} from "react-device-detect";
import TranslatedText from '../../../components/TranslatedText/TranslatedText'
import useTranslatedText from "../../../hooks/useTranslatedText";

const HelpVideoSuggestions = ({data, onClickItem}) => {
  const {colors} = useContext(colorsContext)
  const {dir} = useTranslatedText() // rtl, ltr

  return (
    <div className={classNames(styles.card, {[styles.isMobile]: isMobile})} style={{background: colors['C73']}}>
      <p dir={dir} className={styles.cardTitle} style={{color: colors['C593']}}>
        <TranslatedText translationKey={'suggested.videos'}/>
      </p>
      <ul className={styles.list}>
        {data?.map((item, i) => {
          return (
            <li dir={item.direction} className={styles.item} style={{background: colors['C183']}} key={i}>
              <button onClick={() => onClickItem(item)}>
                <img
                  className={styles.videoCover}
                  src={item.videoCover}
                  alt=""
                />
                <div className={styles.itemTitle} style={{color: colors['C2']}}>
                  {item.title}
                </div>
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default HelpVideoSuggestions