import * as React from "react"

const PauseSvg = ({color}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={26}
    viewBox='0 0 22 26'
    fill={color}
  >
    <path
      d="M.917.168A.917.917 0 0 0 0 1.085v23.833c0 .506.41.917.917.917h5.5c.506 0 .916-.41.916-.917V1.085a.917.917 0 0 0-.916-.917h-5.5ZM15.583.168a.917.917 0 0 0-.916.917v23.833c0 .506.41.917.916.917h5.5c.507 0 .917-.41.917-.917V1.085a.917.917 0 0 0-.917-.917h-5.5Z"
    />
  </svg>
)

export default PauseSvg

