import componentStyle from './BuyTokenButton.module.scss'
import CartSvg from "../../icons/CartSvg";
import {useContext} from "react";
import colorsContext from "../../contexts/ColorsContext";
import {useNavigate} from "react-router-dom";

const BuyTokenButton = ({afterNavigate = () => {}}) => {
  const {colors} = useContext(colorsContext)

  const navigate = useNavigate()

  return (
    <button
      onClick={() => {
        navigate('/payment')
        afterNavigate()
      }}
      className={componentStyle.button}
      style={{background: '#F29100'}}
    >
      <div className={componentStyle.icon}>
        <CartSvg color={colors['C233']}/>
      </div>
      <p className='font-weight-800 res:fs-md' style={{color: colors['C233']}}>
        BUY
      </p>
    </button>
  )
}

export default BuyTokenButton