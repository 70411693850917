import React, {useContext} from "react"
import PauseSvg from "../../icons/PauseSvg"
import PlaySvg from "../../icons/PlaySvg"
import colorsContext from "../../contexts/ColorsContext";

const PlayButtonPrimary = ({setPlaying, playing, iconColor, bgColor, borderColor, loading}) => {
  require('./style.scss')
  const {colors} = useContext(colorsContext)

  return (
    <button
      className="play-button-primary position-relative"
      disabled={loading}
      style={{background: bgColor, borderColor: borderColor, opacity: loading ? 0.5 : 1}}
      onClick={() => setPlaying(!playing)}
    >
      {loading &&
        <span
          className='spinner-border position-absolute'
          style={{
            width: '100%', height: '100%',
            borderColor: colors["C2"],
            borderRightColor: 'transparent'
          }}
        />
      }
      {playing ?
        <span className="icon-container pause-icon">
          <PauseSvg color={iconColor}/>
        </span> :
        <span className="icon-container play-icon">
          <PlaySvg color={iconColor}/>
        </span>
      }
    </button>
  )
}

export default PlayButtonPrimary