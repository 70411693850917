import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStructuredSection } from '../../../redux/features/structuredSectionSlice'
import StructureModule from '../../../modules/StructuredSectionModules/StructureModule'
import { START_SECTION } from '../../../enums/studyLogEnums/studyLogEnums'
import useLog from '../../../hooks/logHooks/useLog'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import RoboTeacherNavigationModal from "../../../components/RoboTeacherNavigationModal/RoboTeacherNavigationModal";
import useActionRetryerWrapper from '../../../hooks/useDispatchWrapper'

const GrammarWrap = ({ sectionName }) => {
	const { unit } = useParams()

	const states = useSelector(state => state.structuredSection)

	const dispatch = useDispatch()
	const { networkLossRetryerDispatch } = useActionRetryerWrapper()
	const { sendServerLog } = useLog()

	useEffect(() => {
		networkLossRetryerDispatch(retryId => {
			dispatch(fetchStructuredSection({ unit, sectionType: sectionName, retryId })).then(() => {
				sendServerLog(START_SECTION)
			})
		})
	}, [])

	const noTeacherUnits = [21, 38, 55, 73]

	/* TODO: set showABC to true when it works */
	return (
		<>
			<StructureModule pageType={pageGuidEnums.GRAMMAR_WRAP_SECTION} structuresData={states.data} showAbc={false} showRoboTeacher={!noTeacherUnits.includes(parseInt(unit))} haveWordColor />
			<RoboTeacherNavigationModal unit={unit} />
		</>
	)
}

export default GrammarWrap
