import * as React from "react"

const GrammarWrapSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style type="text/css">
            {
                ".GrammarWrapSvg-st0{fill:none;stroke-width:21.3282;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.GrammarWrapSvg-st1{fill:none;stroke-width:30.0534;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.GrammarWrapSvg-st2{fill:none;stroke-width:32.9617;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"
            }
        </style>
        <g>
            <path
                className="GrammarWrapSvg-st0"
                stroke={color}
                d="M90.5,154.3l37-96c0.8-1.8,3.4-1.8,4.2,0l36.7,96"
            />
            <line
                className="GrammarWrapSvg-st0"
                stroke={color}
                x1={102.1}
                y1={130.4}
                x2={157}
                y2={130.4}
            />
            <path
                className="GrammarWrapSvg-st0"
                stroke={color}
                d="M276.7,79.4c0,12.4-9.9,22.4-22,22.4c-6,0-27.8,0.1-27.8,0.1c0-8,0-32.5,0-44.9 c0,0,20.6,0,27.8,0C266.8,57,276.7,67,276.7,79.4z"
            />
            <path
                className="GrammarWrapSvg-st0"
                stroke={color}
                d="M285.2,128.4c0-0.2,0-0.3,0-0.4c0-14.4-11.5-26.2-25.8-26.2c-9.6,0-17.8,0-32.7,0 c0,11.7,0,41.6,0,52.5c35-0.2,33.3,0,35.8-0.3"
            />
            <path
                className="GrammarWrapSvg-st0"
                stroke={color}
                d="M343.9,99.8c2.8-24.1,23-42.9,47.4-42.9c9.9,0,19.1,3.1,26.8,8.3"
            />
            <polyline
                className="GrammarWrapSvg-st1"
                stroke={color}
                points="299.1,220.7 347.3,268.9 436.8,179.4  "
            />
            <path
                className="GrammarWrapSvg-st2"
                stroke={color}
                d="M278.5,134.6c49.4-49.4,129.5-49.4,179,0c49.4,49.4,49.4,129.5,0,179c-49.4,49.4-129.5,49.4-179,0 S229,184.1,278.5,134.6z"
            />
            <path
                className="GrammarWrapSvg-st2"
                stroke={color}
                d="M104.2,487.8c-9.5-9.5-9.5-24.9,0-34.4L224.7,333l34.4,34.4L138.6,487.8C129.1,497.4,113.7,497.4,104.2,487.8z "
            />
            <line className="GrammarWrapSvg-st2"
                  stroke={color} x1={278.5} y1={313.6} x2={241.8} y2={350.1} />
            <path
                className="GrammarWrapSvg-st1"
                stroke={color}
                d="M494.5,222.5v-185c0-10.8-8.7-19.5-19.5-19.5H37c-10.8,0-19.5,8.7-19.5,19.5v136.3 c0,10.8,8.7,19.5,19.5,19.5h9.7l19.5,39l58.4-39h120.6"
            />
        </g>
    </svg>
)

export default GrammarWrapSvg
