import * as React from "react"

const ConversationSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style type="text/css">
            {
                ".ConversationSvg-st0{stroke-width:6;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"
            }
        </style>
        <g>
            <g>
                <path
                    className="ConversationSvg-st0"
                    stroke={color}
                    d="M508.4,306.2L473,265c25.2-28.4,38.9-63.1,38.9-99C512,73,421.8,0,316,0C208.5,0,121,74.5,121,166 c0,2.3,0.1,4.6,0.2,6.9C49.6,201.3,0,262.6,0,333.5c0,39.7,15.2,77.8,43,108.7L3.7,487.1c-3.9,4.4-4.8,10.7-2.4,16.1 S9.1,512,15,512h211c1.7,0,6.9-1,8-1.1c49.5-4.6,95.3-24,129.8-55.1C400.2,423,421.7,379,421,331h76 C509.8,331,516.7,315.9,508.4,306.2z M229,481.3c-1.3,0.1-5.8,0.5-6.7,0.7H48.1l26.6-30.5c5.3-6.1,4.9-15.2-1-20.8 C45.5,404,30,369.5,30,333.5C30,252.2,111.2,186,211,186c99.2,0,180,66.2,180,147.5C391,409.5,320.2,473.8,229,481.3z M417.5,301 L417.5,301C399.4,217.6,312.8,156,211,156c-20.8,0-40.9,2.6-60,7.3C152.8,89.6,226.1,30,316,30c91.5,0,166,61,166,136 c0,32.1-14,63.2-39.6,87.6c-5.8,5.5-6.2,14.5-1,20.6l23,26.8H417.5z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    className="ConversationSvg-st0"
                    stroke={color}
                    d="M121,301c-16.5,0-30,13.5-30,30s13.5,30,30,30s30-13.5,30-30S137.5,301,121,301z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    className="ConversationSvg-st0"
                    stroke={color}
                    d="M211,301c-16.5,0-30,13.5-30,30s13.5,30,30,30s30-13.5,30-30S227.5,301,211,301z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    className="ConversationSvg-st0"
                    stroke={color}
                    d="M301,301c-16.5,0-30,13.5-30,30s13.5,30,30,30s30-13.5,30-30S317.5,301,301,301z"
                />
            </g>
        </g>
    </svg>
)

export default ConversationSvg
