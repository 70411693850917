import { orderBy } from "lodash";
import serviceCall from "../../services";

const getGatewayUseCase = async () => {
  const result = await serviceCall(
    'fetchChooseGateway',
    null,
    'cloud',
  )

  return orderBy(result, ['availability.priority'], ['asc'])
}

export default getGatewayUseCase