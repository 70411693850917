import axios from 'axios'
import { currentMainAPI } from './mainAPIs'
import refreshAccessToken from './refreshAccessToken'
import axiosInstance from './axiosInstance'
import * as Sentry from '@sentry/react'
import { retryOnError } from './retryOnError'
import showToast from '../util/toastUtils/toastUtils'
import { getErrorTranslatedText } from '../util/getErrorTranslatedText'

let authToken = JSON.parse(localStorage.getItem('user_token') ?? null)
let refreshPromise = null

const userAxiosInstance = axios.create({
	baseURL: `${currentMainAPI}/user/api`,
	headers: {
		Authorization: `Bearer ${authToken?.access_token}`,
	},
})

// Axios interceptor to handle token
userAxiosInstance.interceptors.request.use(
	(config) => {
		const authToken = JSON.parse(localStorage.getItem('user_token') ?? null)

		if (authToken) {
			if (!authToken?.access_token || authToken?.access_token === 'undefined') {
				Sentry.captureMessage('Access token passed to request header but it\'s undefined', {
					level: 'warning',
					extra: {
						authToken: authToken,
						requestedUrl: config.baseURL + config.url,
					},
				})
			}
			config.headers['Authorization'] = `Bearer ${authToken?.access_token}`
		}
		return config
	},
	(error) => {
		return Promise.reject(error)
	},
)

// Axios interceptor to handle expired tokens and error translations
userAxiosInstance.interceptors.response.use(
	(response) => response.data,
	async (error) => {
		const originalRequest = error.config

		// Handle token expiration (status code 401)
		if (error.response?.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true
			if (!refreshPromise) {
				refreshPromise = refreshAccessToken().finally(() => {
					refreshPromise = null // Reset the promise after refresh is done
				})
			}

			try {
				await refreshPromise
				const newAuthToken = JSON.parse(localStorage.getItem('user_token') ?? null)
				originalRequest.headers.Authorization = `Bearer ${newAuthToken?.access_token}`

				return axiosInstance(originalRequest)
			} catch (err) {
				if (err?.response?.status === 500) {
					localStorage.removeItem('user_token')
					window.location.href = '/'
				}
			}
		}

		const suppressToast = error.config?.suppressToast
		const status = error?.response?.status
		if (status !== 401) {
			try {
				if (error.response) {
					const errorKey = error.response?.data?.error ?? error.response?.data?.detail
					if (errorKey) {
						const errorMessage = await getErrorTranslatedText(errorKey)
						if (errorMessage) {
							error.translatedMessage = errorMessage
							if (!suppressToast) {
								showToast(errorMessage, 'error')
							}
						}
					} else {
						const errorKey = 'OOPS_SOMETHING_WENT_WRONG'
						const errorMessage = await getErrorTranslatedText(errorKey)
						if (errorMessage && !suppressToast) {
							showToast(errorMessage, 'error')
						}
					}
				}
			} catch (err) {
				const errorKey = 'OOPS_SOMETHING_WENT_WRONG'
				const errorMessage = await getErrorTranslatedText(errorKey)
				if (errorMessage && !suppressToast) showToast(errorMessage)
			}
		}

		// Retry the request if applicable
		retryOnError(originalRequest, error)

		return Promise.reject(error)
	},
)

export default userAxiosInstance
