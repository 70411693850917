import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import tableOfContentUseCase from "../../useCase/tableOfContentUseCase/tableOfContentUseCase";
import searchTableOfContentUseCase from "../../useCase/tableOfContentUseCase/searchTableOfContentUseCase";

const initialState = {
  getTableOfContentDataLoading: false,
  tableOfContentData: [],
  searchResult: [],
  error: '',
}

export const getTableOfContentData = createAsyncThunk(
  'tableOfContent/getTableOfContentData',
  () => {
    return tableOfContentUseCase()
  },
)

const tableOfContentSlice = createSlice({
  name: 'tableOfContent',
  initialState,
  reducers: {
    searchTableOfContent: (state, action) => {
      state.searchResult = searchTableOfContentUseCase(action.payload, state.tableOfContentData)
    }
  },
  extraReducers: builder => {
    builder.addCase(getTableOfContentData.pending, (state) => {
      state.getTableOfContentDataLoading = true
    })
    builder.addCase(getTableOfContentData.fulfilled, (state, action) => {
      state.getTableOfContentDataLoading = false

      state.tableOfContentData = action.payload
    })

    builder.addCase(getTableOfContentData.rejected, (state, action) => {
      state.getTableOfContentDataLoading = false
      state.error = action.error.message
    })
  },
})

export const { searchTableOfContent } = tableOfContentSlice.actions

export default tableOfContentSlice.reducer