const EvaluationReportMapper = (data) => {
  console.log('data', data)
  return {
    ...data,
    data: {
      ...data.data,
      learningTypeTest: {
        ...data.data.learningTypeTest,
        testIsTaken: !!(data?.learningTypeTest?.auditoryPercentage || data?.learningTypeTest?.hapticPercentage || data?.learningTypeTest?.visualPercentage)
      }
    }
  }
}
export default EvaluationReportMapper