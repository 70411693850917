import React, { useContext, useEffect, useState } from 'react'
import Lock from '../../../components/Lock/Lock'
import { useDispatch, useSelector } from 'react-redux'
import ToplineTitle from '../../../components/ToplineTitle/ToplineTitle'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import {
  setSelectedAnswerBox,
  toggleUnlockAnswer,
  setUserData,
  checkWarmUp, resetWarmUp, setRemainingTime, unlockAllAnswers,
} from '../../../redux/features/readingSlice'
import htmlToText from '../../../operations/htmlToText'
import SkipButton from '../../../components/SkipButton/SkipButton'
import ResetButton from '../../../components/ResetButton/ResetButton'
import { moreOptionsList } from '../../../data'
import Header from '../../../components/Header/Header'
import { useParams } from 'react-router-dom'
import HelpButton from '../../../components/HelpButton/HelpButton'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import './../../../operations/dndPollyfill'
import colorsContext from '../../../contexts/ColorsContext'
import DraggableItemsCard from "../../../components/DraggableItemsCard/DraggableItemsCard";
import { htmlTextConversion } from "../../../services/mapper/mapperConversions";
import {  pageGuidEnums  } from '../../../enums/pageGuideEnums/pageGuideEnums'
import useQueryParams from '../../../hooks/useQueryParams'

const ReadingWarmUp = () => {
  const [draggingAnswer, setDraggingAnswer] = useState(null)
  const [lastClickedItemTime, setLastClickedItemTime] = useState(0)

  const { colors } = useContext(colorsContext)

  const states = useSelector(state => state.reading)
  const dispatch = useDispatch()

  const { removeQueryParams } = useQueryParams()

  const { unit, sectionId } = useParams()

  const { windowWidth } = useWindowDimensions()

  const onClickAnswerBox = index => {
    if (!states.answersChecked) {
      const time = new Date()
      if (states.selectedAnswerBox === index && time - lastClickedItemTime > 20) {
        dispatch(setSelectedAnswerBox(null))
      } else {
        setLastClickedItemTime(time)
        dispatch(setSelectedAnswerBox(index))
      }
    }
  }

  useEffect(() => {
    dispatch(setRemainingTime(0))
  })

  const onClickOption = (questionIndex) => {
    if (!states.answersChecked && states.selectedAnswerBox != null) {
      // If this option is used before
      if (states.userData.some(e => e.answerIndex === questionIndex)) {
        dispatch(setUserData(
          [...states.userData].map((obj, i) => {
            if (i === states.selectedAnswerBox) {
              return {
                ...obj,
                answerIndex: null,
                answerString: null,
              }
            } else {
              return obj
            }
          }),
        ))
      } else {
        dispatch(setUserData(
          [...states.userData].map((obj, i) => {
            if (i === states.selectedAnswerBox) {
              return {
                ...obj,
                answerIndex: questionIndex,
                answerString: htmlToText(states.data.warmUpQuiz[questionIndex].answer),
              }
            } else if (obj.answerIndex === questionIndex) {
              return {
                ...obj,
                answerIndex: null,
                answerString: null,
              }
            } else {
              return obj
            }
          }),
        ))
      }
      // Unselect answer box
      dispatch(setSelectedAnswerBox(null))
    }
  }

  const onDropOptionToAnswerBox = (e, quizIndex) => {
    if (e.stopPropagation) e.stopPropagation()
    if (!states.answersChecked && draggingAnswer != null) {
      // If this option is used before
      dispatch(setUserData(
        [...states.userData].map((obj, i) => {
          if (i === quizIndex) {
            return {
              ...obj,
              answerIndex: draggingAnswer,
              answerString: htmlToText(states.data.warmUpQuiz[draggingAnswer].answer),
            }
          } else if (obj.answerIndex === draggingAnswer) {
            return {
              ...obj,
              answerIndex: null,
              answerString: null,
            }
          } else {
            return obj
          }
        }),
      ))
      // Unselect answer box
      setDraggingAnswer(null)
    }
  }

  const onDropOptionToDefaultPlace = (e) => {
    if (e.stopPropagation) e.stopPropagation()
    if (!states.answersChecked && draggingAnswer != null) {
      // If this option is used before
      dispatch(setUserData(
        [...states.userData].map((obj) => {
          if (obj.answerIndex === draggingAnswer) {
            return {
              ...obj,
              answerIndex: null,
              answerString: null,
            }
          } else {
            return obj
          }
        }),
      ))
      // Unselect answer box
      setDraggingAnswer(null)
    }
  }

  const checkUserAnswers = () => {
    let matchCases = {}

    const createMatchCases = (value, index) => {
      const correctAnswerString = htmlToText(states.data.warmUpQuiz[index].answer)
      matchCases[correctAnswerString] = value.answerString
    }

    states.userData.forEach(createMatchCases)

    dispatch(checkWarmUp({
      unit,
      matchCases,
    }))
  }

  const unlockQuiz = (index) => {
    dispatch(toggleUnlockAnswer(index))
  }

  let allQuestionsAnswered = !states.userData.some(e => e.answerIndex === null)

  const answerIsCorrect = (quizIndex) => {
    if (states.answersChecked) {
      const quizAnswer = htmlToText(states.data.warmUpQuiz[quizIndex].answer)
      return states.checkResult.matchCasesMap[quizAnswer].match
    } else {
      return false
    }
  }

  const renderAnswerBox = (item, i) => {
    const keyUsed = states.userData[i].unlocked
    const answerBoxStyles = {
      default: {
        background: colors['C522'],
        color: colors['C523'],
      },
      correct: {
        background: colors['C522'],
        color: colors['C523'],
        borderColor: colors['C30'],
      },
      incorrect: {
        background: colors['C522'],
        color: colors['C523'],
        borderColor: colors['C29'],
      },
      unlocked: {
        background: colors['C522'],
        color: colors['C523'],
        borderColor: colors['C29'],
      },
    }

    const renderContent = () => {
      if (keyUsed) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: states.data.warmUpQuiz[i].answer ?? "<p>&nbsp;</p>",
            }}
          />
        )
      } else if (states.userData[i].answerIndex != null) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: states.data.warmUpQuiz[states.userData[i].answerIndex].answer ?? "<p>&nbsp;</p>",
            }}
          />
        )
      } else {
        return <div>&nbsp;</div>
      }
    }

    const getAnswerStatus = () => {
      if (states.answersChecked) {
        if (answerIsCorrect(i)) {
          return 'correct'
        } else if (keyUsed) {
          return 'unlocked'
        } else {
          return 'incorrect'
        }
      } else {
        return 'default'
      }
    }

    const filled = states.userData[i].answerIndex != null

    return (
      <div
        className={`answer-box ${filled ? 'filled' : ''} ${states.selectedAnswerBox === i ? 'selected' : ''}`}
        style={{
          borderColor: states.selectedAnswerBox === i ? '#FFF' : '',
          ...answerBoxStyles[getAnswerStatus()],
        }}
        onDrop={(e) => onDropOptionToAnswerBox(e, i)}
        onDragOver={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        onDragStart={() => {
          setDraggingAnswer(states.userData[i].answerIndex)
        }}
        draggable={filled}
        onClick={() => {
          onClickAnswerBox(i)
        }}
      >
        {renderContent()}
      </div>
    )
  }

  const renderPhraseOptions = (item, i) => {
    if (states.answersChecked) {
      return (
        <div className='answer-box-options-wrapper'>
          {!answerIsCorrect(i) &&
            <Lock
              isLocked={!states.userData[i].unlocked}
              onClickLock={() => unlockQuiz(i)}
            />
          }
        </div>
      )
    }
  }

  const redirectBackToReadingPage = () => {
    removeQueryParams('warmup')
  }

  return (
    <div className='minh-100vh pb-3' style={{ background: colors['C183'] }}>
      <Header
        titleColor={colors['C167']}
        backgroundColor={colors['C14']}
        withBackButton
        iconsColor={colors['C167']}
        title={states.answersChecked ? 'Warm-Up Results' : 'Warm-Up'}
      />
      {(unit && sectionId) &&
        <div data-jsx='ReadingWarmUp'>
          <div className='pb-2'>
            <div className='d-flex justify-content-end mt-3'>
              <div className='d-flex align-items-center me-3 me-md-5 ms-2'>
                <HelpButton color={colors['C2']} pageType={pageGuidEnums.READING_WARMUP}/>
              </div>
            </div>
          </div>
          <ToplineTitle titleData={{ sentence: 'Match the words and phrases.' }} />
          {states.userData.length > 0 &&
            <div className='container warm-up-container'>
              <div>
                <DraggableItemsCard
                  itemsData={states.data.shuffledArray.map(quizIndex => {
                    const quizItem = states.data.warmUpQuiz[quizIndex]
                    return {
                      index: quizIndex,
                      text: htmlTextConversion({
                        sentence: quizItem.answer
                      }),
                      used: states.userData.some(e => e.answerIndex === quizIndex)
                    }
                  })}
                  onClickOption={(item) => onClickOption(item.index)}
                  onDrop={e => onDropOptionToDefaultPlace(e)}
                  onDragStart={(item) => setDraggingAnswer(item.index)}
                // backgroundColor={colors['C232']}
                // itemBackgroundColo={colors['C70']}
                // itemColor={colors['C160']}
                />
                {states.data.warmUpQuiz.map((item, i) => {
                  return (
                    <div className='warm-up-quiz' key={item.questionNo}>
                      {windowWidth >= 768 && renderPhraseOptions(item, i)}
                      <div className='quiz-container' style={{ background: colors['C192'] }}>
                        <div className='d-md-flex align-items-baseline'>
                          <div className='align-items-center d-inline-flex fs-md me-2'>
                            {renderAnswerBox(item, i)}
                            <span className='font-size-40 font-weight-700' style={{ color: colors['C573'] }}>:</span>
                            {windowWidth < 768 && renderPhraseOptions(item, i)}
                          </div>
                          <HtmlTextWrapper
                            textColor={colors['C573']}
                            optionsColor={colors['C23']}
                            className='quiz-text'
                            moreOptionsList={moreOptionsList}
                            data={{
                              sentence: item.question,
                              trackerNumber: item.questionTrackerNumber,
                              more: !!item.questionTrackerNumber
                            }}
                          />
                        </div>
                      </div>
                      {/*<div className="translate-wrapper">*/}
                      {/*    <button className="translate-button" style={{backgroundColor: "#FFF"}}>*/}
                      {/*        <img src={window.location.origin + '/assets/images/translate.png'} alt=""/>*/}
                      {/*    </button>*/}
                      {/*</div>*/}
                    </div>
                  )
                })}
              </div>

              {states.answersChecked ?
                <>
                  <div>
                    <div className='d-flex justify-content-center'>
                      <PrimaryButton onClick={() => {
                        dispatch(unlockAllAnswers())
                        redirectBackToReadingPage()
                      }}>
                        Next
                      </PrimaryButton>
                    </div>
                    <div className='d-flex justify-content-start'>
                      <ResetButton color={colors['C2']} onClick={() => dispatch(resetWarmUp())} />
                    </div>
                  </div>
                </>
                :
                <>
                  <div>
                    <div className='d-flex justify-content-center mb-2'>
                      <PrimaryButton
                        disabled={!allQuestionsAnswered}
                        onClick={checkUserAnswers}
                      >
                        check
                      </PrimaryButton>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <SkipButton color={colors['C2']} onClick={redirectBackToReadingPage} />
                    </div>
                  </div>

                </>
              }
            </div>
          }
        </div>
      }
    </div>
  )
}
export default ReadingWarmUp