import {getLanguageDataByTitle} from "../../../enums/languageEnums/languageEnums";

const nativeLanguageMapper = (data) => {

  return data.map((item) => {
    const languageData = getLanguageDataByTitle(item.str)
    return {
      countryCode: item.countryCode,
      title: languageData.title,
      key: item.name,
      isUserNativeLanguage: item.isUserNativeLanguage,
    }
  })
}

export default nativeLanguageMapper