import twoDigitNumber from "../operations/twoDigitNumber";

export const secondsToHMS = (d) => {
  if (typeof d === 'number') {
    d = Number(d);
    const h = twoDigitNumber(Math.floor(d / 3600));
    const m = twoDigitNumber(Math.floor(d % 3600 / 60));
    const s = twoDigitNumber(Math.floor(d % 3600 % 60));

    const hDisplay = h > 0 ? `${h}:` : "";
    const mDisplay = m >= 0 ? `${m}:` : "";
    const sDisplay = s >= 0 ? s : "";
    return hDisplay + mDisplay + sDisplay;
  } else {
    return "00:00"
  }
}

export const secondsToDHM = (seconds) => {
  if (typeof seconds === 'number') {
    seconds = Number(seconds)
    let d = Math.floor(seconds / (3600 * 24))
    let h = Math.floor(seconds % (3600 * 24) / 3600)
    let m = Math.floor(seconds % 3600 / 60)
    let s = Math.floor(seconds % 60)

    let dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : ""
    let hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : ""
    let mDisplay = m > 0 ? m + (m === 1 ? " minute" : " minutes") : ""
    let result = dDisplay + hDisplay + mDisplay

    return result !== "" ? result : s > 0 ? "Less than a minute" : "00:00"
  } else {
    return "00:00"
  }
}

export const millisToMinutesAndSeconds = (millis) => {
  let minutes = Math.floor(millis / 60000)
  let seconds = ((millis % 60000) / 1000).toFixed(0)
  return minutes + ":" + (seconds < 10 ? '0' : '') + seconds
}

export const areTimesEqual = (time1, time2) => {
  // Extract hours and minutes from the first time
  const [hours1, minutes1] = time1.split(":").map(Number);
  // Extract hours and minutes from the second time
  const [hours2, minutes2] = time2.split(":").map(Number);

  // Compare the hours and minutes
  return hours1 === hours2 && minutes1 === minutes2;
}

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const isAbleToJsonStringify = (val) => {
  try {
    JSON.stringify(val);
  } catch (e) {
    return false;
  }
  return true;
}

export const toFarsiNumber = (n) => {
  const farsiDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹']

  return n
    .toString()
    .replace(/\d/g, x => farsiDigits[x])
}

export const getTimestampMilliSeconds = (timestamp) => {
  if (typeof timestamp === 'number') {
    return (timestamp > (Date.now() / 1000)) ? timestamp : timestamp * 1000
  } else {
    return timestamp
  }
}


export const removeQueryParamsAndNavigate = (paramsToRemove) => {
  // Create a URL object using the current page URL
  const urlObj = new URL(window.location.href);
  const params = new URLSearchParams(urlObj.search);

  // Remove the specified parameters
  paramsToRemove.forEach(param => params.delete(param));

  // Update the search property of the URL object
  urlObj.search = params.toString();

  if (urlObj.toString() !== window.location.href) {
    // Replace the current history state with the new URL (no page reload)
    history.replaceState(null, '', urlObj.toString());
  }
}