import moment from "moment/moment";

const notificationsMapper = (data) => {

	let result = {
		...data,
		createDate: moment(data?.createDate, 'hh:mm A').format('hh:mm A')
	};

	return result;
}

export default notificationsMapper;