import serviceCall from "../../services";
import levelsMapper from "../../services/mapper/levelAndUnit/levelsMapper";

const updateLevelStatusUseCase = async ({level}) => {
  const response = await serviceCall(
    'updateLevelStatus',
    {level},
    "cloud"
  )

  return levelsMapper(response)
}

export default updateLevelStatusUseCase