import serviceCall from "../../services";
import conversationMapper from "../../services/mapper/conversationSection/conversationMapper";

const conversationUseCase = async ({unit}) => {
  const response = await serviceCall(
    'fetchSection',
    {
      unit: unit,
      section: 'CONVERSATION',
    },
    'cloud',
  )

  return conversationMapper(response.data)
}

export default conversationUseCase