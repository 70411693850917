import serviceCall from "../../services";

const subscriptionGoogleUseCase = async ({psp, planId, asRenewal, purchaseType, purchaseToken}) => {
  const result = await serviceCall(
    'subscriptionGooglePlan',
    {psp, planId, asRenewal, purchaseType, purchaseToken},
    'cloud',
  )

  return result
}

export default subscriptionGoogleUseCase