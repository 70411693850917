import skipStyle from './skipModal.module.scss'
import Modal from '../../../../components/Modal'
import React, { useContext, useMemo } from 'react'
import colorsContext from '../../../../contexts/ColorsContext'
import SecondaryButton from '../../../../atomicComponents/atoms/SecondaryButton/SecondaryButton'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import classNames from 'classnames'
import AnimatedProgressProvider from '../../../../components/AnimatedProgressProvider'
import { easeQuadInOut } from 'd3-ease'
import { studyDifficultyLevelsData } from '../../../../enums/studyDifficultyEnums/studyDifficultyEnums'
import useAppUser from '../../../../hooks/useAppUser'
import useLog from '../../../../hooks/logHooks/useLog'
import { SKIP_EXERCISE } from '../../../../enums/studyLogEnums/studyLogEnums'
import { useSelector } from 'react-redux'
import useSectionEvents from '../../../../hooks/sectionHooks/useSectionEvents'
import TranslatedText from '../../../../components/TranslatedText/TranslatedText'
import useTranslatedText from '../../../../hooks/useTranslatedText'
import UnitDataStorage from '../../../../classes/UnitDataStorage'
import { useNavigate } from 'react-router-dom'

const SkipModal = ({ closeModal, isReviewMistake, sectionType }) => {
	const { colors } = useContext(colorsContext)
	const { appUserData } = useAppUser()
	const { sendServerLog } = useLog()
	const navigate = useNavigate()
	const { logPending } = useSelector(state => state.studyLog)
	const { navigateToNextSection } = useSectionEvents()
	const { dir } = useTranslatedText()

	const studyDifficultyPercentage = useMemo(() => {
		return studyDifficultyLevelsData.find(it => it.key === appUserData.setting.studyHardshipLevel)?.percentage ?? 0
	}, [])

	const skipSection = () => {
		if (isReviewMistake) {
			const unitDataStorage = new UnitDataStorage()
			let unitData = unitDataStorage.getUnitData()

			unitData = { ...unitData, sectionKey: sectionType }

			sendServerLog(SKIP_EXERCISE, unitData, () => {
				navigate(-1)
			})
		} else {
			sendServerLog(SKIP_EXERCISE, null, () => {
				navigateToNextSection()
			})
		}
	}

	const onCloseModal = () => {
		if (!logPending) {
			closeModal()
		}
	}

	return (
		<Modal closeModal={onCloseModal} isCenter>
			<div style={{ maxWidth: '100%', padding: '0 16px' }}>
				<div className={skipStyle.container} style={{ background: colors['C183'] }}>
					<div className={classNames(skipStyle.circlePercent)}>
						<AnimatedProgressProvider
							valueStart={0}
							valueEnd={studyDifficultyPercentage}
							duration={0.7}
							easingFunction={easeQuadInOut}>
							{value => {
								const roundedValue = Math.floor(value)
								return (
									<CircularProgressbar
										value={roundedValue}
										text={`${roundedValue}%`}
										strokeWidth={7}
										background
										backgroundPadding={3}
										styles={buildStyles({
											backgroundColor: 'transparent',
											textColor: colors['C2'],
											pathColor: '#13B708',
											trailColor: 'transparent',
											pathTransition: 'none',
											textSize: '21px',
										})}
									/>
								)
							}}
						</AnimatedProgressProvider>
					</div>
					<div dir={dir} className={skipStyle.description} style={{ color: colors['C591'] }}>
						<TranslatedText
							translationKey={'skip.modal.repeat.text'}
							middleware={value => {
								return (
									<p
										dir={dir}
										dangerouslySetInnerHTML={{
											__html: value?.replace('[num]', `${studyDifficultyPercentage}`),
										}}
									/>
								)
							}}
						/>
						<br />
						<TranslatedText translationKey={'skip.modal.repeat.question'} />
					</div>
					<div className={skipStyle.buttons}>
						<SecondaryButton loading={logPending} onClick={skipSection}>
							<TranslatedText translationKey={'skip.anyway'} />
						</SecondaryButton>
						<SecondaryButton disabled={logPending} onClick={closeModal}>
							<TranslatedText translationKey={'cancel'} />
						</SecondaryButton>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default SkipModal
