import fetchReviewMistakesMapper from "../../services/mapper/sections/fetchReviewMistakesMapper";
import serviceCall from "../../services";

const getReviewMistakesUseCase = async ({unit, studyType}) => {
  const response = await serviceCall(
    'fetchReviewMistake',
    { unit, studyType },
    'cloud',
  )

  return fetchReviewMistakesMapper(response)
}

export default getReviewMistakesUseCase