import {
  AMETHYST,
  AURORA, BUTTERFLY, CHERRY_BLOSSOM,
  FOREST,
  GLACIER,
  KUALA,
  LAVENDER,
  MINT, MOONLIGHT, MYSTERY, OCEAN_BREEZE,
  OPAL,
  TANGERINE,
  TWILIGHT
} from "../enums/base/themeEnums";

export const getAllColors = (theme) => {
  switch (theme) {
    case LAVENDER:
      return require(`./lavenderColors`)
    case AMETHYST:
      return require(`./amethystColors`)
    case OPAL:
      return require('./opalColors')
    case GLACIER:
      return require('./glacierColors')
    case KUALA:
      return require('./kualaColors')
    case TANGERINE:
      return require('./tangerineColors')
    case TWILIGHT:
      return require('./twilightColors')
    case FOREST:
      return require('./forestColors')
    case MINT:
      return require('./mintColors')
    case AURORA:
      return require('./auroraColors')
    case MYSTERY:
      return require('./mysteryColors')
    case OCEAN_BREEZE:
      return require('./oceanBreezeColors')
    case CHERRY_BLOSSOM:
      return require('./cherryBlossomColors')
    case BUTTERFLY:
      return require('./butterflyColors')
    case MOONLIGHT:
      return require('./moonlightColors')
    default:
      return require(`./tangerineColors`)
  }
}