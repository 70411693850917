import * as React from "react"

const BlinkSvg = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={22.451}>
      <g data-name="Group 7212">
        <g fill={color} data-name="Group 7211">
          <g data-name="Group 4671">
            <path d="M.448 13.898H9v8.552H.448z" data-name="Rectangle 1198" />
            <path
                d="M10.96 13.898h8.552v8.552H10.96z"
                data-name="Rectangle 1199"
            />
          </g>
          <text
              fontFamily="SegoeUI, Segoe UI"
              fontSize={10}
              transform="translate(10 11)"
          >
            <tspan x={-9.187} y={0}>
              {"ABC"}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
)

export default BlinkSvg
