import React from 'react'
import {useSelector} from 'react-redux'
import ChooseFromCategoryModule from '../../modules/ExerciseModules/ChooseFromCategoryModule/ChooseFromCategoryModule'
import MatchModule from '../../modules/ExerciseModules/MatchModule/MatchModule'
import SayTheFollowingModule from '../../modules/ExerciseModules/SayTheFollowingModule/SayTheFollowingModule'
import PutInOrderModule from '../../modules/ExerciseModules/PutInOrderModule/PutInOrderModule'
import PutInOrderConversationModule
  from '../../modules/ExerciseModules/PutInOrderConversationModule/PutInOrderConversationModule'
import MultipleChoiceModule from '../../modules/ExerciseModules/MultipleChoiceModule/MultipleChoiceModule'
import ComboBoxModule from '../../modules/ExerciseModules/ComboBoxModule/ComboBoxModule'
import ComboPictionaryModule from '../../modules/ExerciseModules/ComboPictionaryModule/ComboPictionaryModule'
import TypeTheAnswerModule from '../../modules/ExerciseModules/TypeTheAnswerModule/TypeTheAnswerModule'
import DragAndDropConversationModule
  from '../../modules/ExerciseModules/DragAndDropConversationModule/DragAndDropConversationModule'
import DragAndDropCategoryModule
  from '../../modules/ExerciseModules/DragAndDropCategoryModule/DragAndDropCategoryModule'
import DragAndDropModule from '../../modules/ExerciseModules/DragAndDropModule/DragAndDropModule'
import FirstTimeSettingStudyDifficulty
  from "../../components/FirstTimeSettingStudyDifficulty/FirstTimeSettingStudyDifficulty";
import exerciseTypeEnums from "../../enums/exerciseEnums/exerciseEnums";

const ExerciseUseCase = () => {
  const exerciseStates = useSelector(state => state.exercise)

  const renderExerciseModule = () => {
    if (exerciseStates.data) {
      switch (exerciseStates.data.type) {
        case exerciseTypeEnums.chooseFromCategory:
          return <ChooseFromCategoryModule data={exerciseStates.data}/>
        case exerciseTypeEnums.matchCase:
          return <MatchModule data={exerciseStates.data}/>
        case exerciseTypeEnums.sayTheFollowing:
          return <SayTheFollowingModule data={exerciseStates.data}/>
        case exerciseTypeEnums.putInOrder:
          return <PutInOrderModule data={exerciseStates.data}/>
        case exerciseTypeEnums.putInOrderConversation:
          return <PutInOrderConversationModule data={exerciseStates.data}/>
        case exerciseTypeEnums.multipleChoice:
          return <MultipleChoiceModule data={exerciseStates.data}/>
        case exerciseTypeEnums.dragAndDrop:
          return <DragAndDropModule data={exerciseStates.data}/>
        case exerciseTypeEnums.dragAndDropCategory:
          return <DragAndDropCategoryModule data={exerciseStates.data}/>
        case exerciseTypeEnums.dragAndDropConversation:
          return <DragAndDropConversationModule data={exerciseStates.data}/>
        case exerciseTypeEnums.comboBox:
          return <ComboBoxModule data={exerciseStates.data}/>
        case exerciseTypeEnums.comboPictionary:
          return <ComboPictionaryModule data={exerciseStates.data}/>
        case exerciseTypeEnums.typeTheAnswer:
          return <TypeTheAnswerModule data={exerciseStates.data}/>
        default:
          return null
      }
    }
    return null
  }

  return (
    <>
      {renderExerciseModule()}
      <FirstTimeSettingStudyDifficulty/>
    </>
  )
}

export default ExerciseUseCase
