import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const checkPutInOrderMapper = (stackId, response, data) => {
  const renderUserAnswer = (item) => {
    let userAnswerInput = item.userChoices?.map(it => it.text.sentence).join(' ')
    if (item.inputIsInsideGiven) {
      const givenText = item.given.text?.sentenceString
      userAnswerInput = givenText?.replace('###', userAnswerInput) + item.endMark
    }
    return userAnswerInput + item.endMark
  }

  return {
    ...data,
    stacks: [...data.stacks].map(stack => {
      if (stack.id === stackId) {
        return {
          ...stack,
          items: [...stack.items].map(item => {
            const responseItem = response.find(obj => obj.exerciseItemId === item.id)
            if (responseItem) {
              const userAnswer = responseItem.userAnswerItemResponse
              const skipped = (responseItem.userAnswerStatus && responseItem.userAnswerStatus === AnswerStatusEnums.SKIPPED)
              return {
                ...item,
                checked: true,
                userAnswer: renderUserAnswer(item),
                userAnswerStatus: skipped ? AnswerStatusEnums.SKIPPED
                  : userAnswer.every(obj => obj.userAnswerStatus === AnswerStatusEnums.CORRECT) ? AnswerStatusEnums.CORRECT : AnswerStatusEnums.INCORRECT,
                userChoices: skipped ? [] : item.userChoices.map((obj, i) => {
                  return {
                    ...obj,
                    answerStatus: userAnswer[i].userAnswerStatus,
                  }
                }),
              }
            }
            return item
          }),
        }
      }
      return stack
    }),
  }
}

export default checkPutInOrderMapper