import { unitStatusEnums } from "../../../enums/levelUnitEnums/levelUnitEnums";
import { sectionsData } from "../../../sources/sectionsData";

const unitStatusMapper = (data, sectionsList, sectionNumber) => {
  const descriptions = {
    'EASY': "status.page.easy.level",
    'MEDIUM': "status.page.medium.level",
    'HARD': "status.page.hard.level"
  }


  if (data?.sections?.length > 0) {
    const sections = sectionsData?.filter(section => {
      return (
        data?.sections?.find(it => it.sectionType === section.sectionType) &&
        sectionsList?.find(it => it.sectionNumber === section?.id)
      )
    })

    data.sections = [...data.sections].map(it => {
      if (it.sectionType === 'WRITING_EXERCISE_11') {
        return {
          ...it,
          isExercise: true
        }
      }
      return it
    })

    return {
      statusData: {
        unitId: data?.unitId,
        studyHardshipLevel: data?.studyHardshipLevel,
        overallPercentage: Math.floor((data?.unitProgress ?? 0)),
        description: descriptions[data?.studyHardshipLevel],
        isFinished: data?.finished,
        sections: sections?.map((item, i) => {
          const sectionData = data?.sections?.find(it => it.sectionType === item.sectionType)
          return {
            sectionType: item.sectionType,
            studyProgressStatus: sectionData?.isFinished ? unitStatusEnums.DONE : (sectionData?.isInProgress ? unitStatusEnums.ON_GOING : unitStatusEnums.NOT_START),
            progress: Math.floor((sectionData?.correctPercentage ?? 0)),
            hardShipPassed: sectionData?.isFinished,
            isCurrentSection: item.id === sectionNumber,
            isEligibleToPass:
              sectionData.sectionType === 'REVIEW_THE_MISTAKES'
                ? sectionData.isFinished && data?.sections?.filter(it => it.isExercise)?.every(it => it.isFinished)
                : sectionData.isFinished || (sectionData.isExercise && sectionData.correctPercentage > 0 && !sectionData.isSkipped),
            isEligibleToPassFromExercisePage:
              sectionData.sectionType === 'REVIEW_THE_MISTAKES'
                ? data?.sections?.filter(it => it.isExercise)?.every(it => it.correctPercentage >= 100)
                : (!sectionData.isExercise && sectionData.isFinished) || (sectionData.isExercise && sectionData.correctPercentage > 0 && !sectionData.isSkipped),
          }
        }),
      },
      pwaVersion: data?.pwaVersion
    }
  } else {
    return {
      statusData: null,
      pwaVersion: data?.pwaVersion
    }
  }
}

export default unitStatusMapper