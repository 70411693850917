import structureBaseTitleMapper from "./StructureBaseTitleMapper";
import { min } from "lodash";
import {ContentTypesStructureEnums} from "../../../enums/structureEnums/templateType";
import structureBaseImageMapper from "./StructureBaseImageMapper";

const StructureCompareSeparateTemplateMapper = ({ item, events }) => {
  const templateHiddenTime = min(events?.view?.filter(it => it?.templateId === item?.id).map(it => parseInt(it?.startTime) || 0)) || 0
  let leftCardsHiddenTimes = []
  let rightCardsHiddenTimes = []

  return {
    ...item,
    data: {
      leftCards: item.data.leftCards?.map(card => {
        const hiddenTime = min(events?.view?.filter(it => ((it?.templateId === item?.id) && (it?.cardId === card?.id))).map(it => parseInt(it?.startTime) || 0)) || 0
        leftCardsHiddenTimes.push(hiddenTime)
        switch (card.type) {
          case ContentTypesStructureEnums.TITLE:
            return {
              ...card,
              data: {
                ...card.data,
                text: structureBaseTitleMapper({ title: card?.data?.text, events })
              },
              hiddenTime
            }
          case ContentTypesStructureEnums.IMAGE:
            return {
              ...card,
              data: {
                ...card.data,
                picture: structureBaseImageMapper({ image: card?.data?.picture, events }),
                pictureText: structureBaseTitleMapper({ title: card?.data?.pictureText, events }),
              },
              hiddenTime
            }
          case ContentTypesStructureEnums.SUBJECT:
            return {
              ...card,
              data: {
                ...card.data,
                texts: card.data.texts.map(text => {
                  return {
                    ...text,
                    text: structureBaseTitleMapper({ title: text?.text, events })
                  }
                })
              },
              hiddenTime
            }
        }
      }),
      rightCards: item.data.rightCards?.map(card => {
        const hiddenTime = min(events?.view?.filter(it => ((it?.templateId === item?.id) && (it?.cardId === card?.id))).map(it => parseInt(it?.startTime) || 0)) || 0
        rightCardsHiddenTimes.push(hiddenTime)
        switch (card.type) {
          case ContentTypesStructureEnums.TITLE:
            return {
              ...card,
              data: {
                ...card.data,
                text: structureBaseTitleMapper({ title: card?.data?.text, events })
              },
              hiddenTime
            }
          case ContentTypesStructureEnums.IMAGE:
            return {
              ...card,
              data: {
                ...card.data,
                picture: structureBaseImageMapper({ image: card?.data?.picture, events }),
                pictureText: structureBaseTitleMapper({ title: card?.data?.pictureText, events }),
              },
              hiddenTime
            }
          case ContentTypesStructureEnums.SUBJECT:
            return {
              ...card,
              data: {
                ...card.data,
                texts: card.data.texts.map(text => {
                  return {
                    ...text,
                    text: structureBaseTitleMapper({ title: text?.text, events })
                  }
                })
              },
              hiddenTime
            }
        }
      }),
      leftCardsHiddenTime: Math.max(min(leftCardsHiddenTimes), templateHiddenTime) || 0,
      rightCardsHiddenTime: Math.max(min(rightCardsHiddenTimes), templateHiddenTime) || 0,
    },
    hiddenTime: templateHiddenTime
  }
}

export default StructureCompareSeparateTemplateMapper