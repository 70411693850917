export const languagesEnums = {
	ENGLISH_LANGUAGE : 'ENGLISH',
	PERSIAN_LANGUAGE : 'PERSIAN',
	ARABIC_LANGUAGE :'ARABIC',
	SPANISH_LANGUAGE : 'SPANISH',
	FRENCH_LANGUAGE :'FRENCH',
	RUSSIAN_LANGUAGE : 'RUSSIAN',
	TURKISH_LANGUAGE : 'TURKISH',
	ITALIAN_LANGUAGE : 'ITALIAN',
	GERMAN_LANGUAGE : 'GERMAN',
}

// If you want to change all languages translation version, change REACT_APP_TRANSLATION_DB_VERSION in .env file
// else if you are about to changing a specific translation,
// change second number in dbVersionCode in desired language object
// My suggestion is if you changed REACT_APP_TRANSLATION_DB_VERSION, change the second number in dbVersionCode to 1

export const ENGLISH_TRANSLATIONS = {
	titleInEnglish: 'English',
	title: 'English',
	key: languagesEnums.ENGLISH_LANGUAGE,
	dbTable: languagesEnums.ENGLISH_LANGUAGE,
	dbVersionCode: `${process.env.REACT_APP_TRANSLATION_DB_VERSION}.0`,
	fileName: 'english.json',
	alpha2: 'EN',
	flagKey: 'ENGLISH',
}

export const ARABIC_TRANSLATIONS = {
	titleInEnglish: 'Arabic',
	title: 'العربية',
	key: languagesEnums.ARABIC_LANGUAGE,
	dbTable: languagesEnums.ARABIC_LANGUAGE,
	dbVersionCode: `${process.env.REACT_APP_TRANSLATION_DB_VERSION}.6`,
	fileName: 'arabic.json',
	alpha2: 'AR',
	flagKey: 'ARABIC',
}
export const PERSIAN_TRANSLATIONS = {
	titleInEnglish: 'Persian',
	title: 'فارسی',
	key: languagesEnums.PERSIAN_LANGUAGE,
	dbTable: languagesEnums.PERSIAN_LANGUAGE,
	dbVersionCode: `${process.env.REACT_APP_TRANSLATION_DB_VERSION}.9`,
	fileName: 'persian.json',
	alpha2: 'FA',
	flagKey: 'PERSIAN',
}
export const SPANISH_TRANSLATIONS = {
	titleInEnglish: 'Spanish',
	title: 'Español',
	key: languagesEnums.SPANISH_LANGUAGE,
	dbTable: languagesEnums.SPANISH_LANGUAGE,
	dbVersionCode: `${process.env.REACT_APP_TRANSLATION_DB_VERSION}.0`,
	fileName: 'spanish.json',
	alpha2: 'SP',
	flagKey: 'SPANISH',
}
export const FRENCH_TRANSLATIONS = {
	titleInEnglish: 'French',
	title: 'Français',
	key: languagesEnums.FRENCH_LANGUAGE,
	dbTable: languagesEnums.FRENCH_LANGUAGE,
	dbVersionCode: `${process.env.REACT_APP_TRANSLATION_DB_VERSION}.0`,
	fileName: 'french.json',
	alpha2: 'FR',
	flagKey: 'FRENCH',
}
export const RUSSIAN_TRANSLATIONS = {
	titleInEnglish: 'Russian',
	title: 'Русский',
	key: languagesEnums.RUSSIAN_LANGUAGE,
	dbTable: languagesEnums.RUSSIAN_LANGUAGE,
	dbVersionCode: `${process.env.REACT_APP_TRANSLATION_DB_VERSION}.0`,
	fileName: 'russian.json',
	alpha2: 'RU',
	flagKey: 'RUSSIAN',
}
export const TURKISH_TRANSLATIONS = {
	titleInEnglish: 'Turkish',
	title: 'Türkçe',
	key: languagesEnums.TURKISH_LANGUAGE,
	dbTable: languagesEnums.TURKISH_LANGUAGE,
	dbVersionCode: `${process.env.REACT_APP_TRANSLATION_DB_VERSION}.0`,
	fileName: 'turkish.json',
	alpha2: 'TR',
	flagKey: 'TURKISH',
}
export const ITALIAN_TRANSLATIONS = {
	titleInEnglish: 'Italian',
	title: 'Italiano',
	key: languagesEnums.ITALIAN_LANGUAGE,
	dbTable: languagesEnums.ITALIAN_LANGUAGE,
	dbVersionCode: `${process.env.REACT_APP_TRANSLATION_DB_VERSION}.0`,
	fileName: 'italian.json',
	alpha2: 'IT',
	flagKey: 'ITALIAN',
}
export const GERMAN_TRANSLATIONS = {
	titleInEnglish: 'German',
	title: 'Deutsch',
	key: languagesEnums.GERMAN_LANGUAGE,
	dbTable: languagesEnums.GERMAN_LANGUAGE,
	dbVersionCode: `${process.env.REACT_APP_TRANSLATION_DB_VERSION}.1`,
	fileName: 'german.json',
	alpha2: 'DE',
	flagKey: 'GERMAN',
}

export const translationsLanguagesData = {
	[languagesEnums.ENGLISH_LANGUAGE]: ENGLISH_TRANSLATIONS,
	[languagesEnums.PERSIAN_LANGUAGE]: PERSIAN_TRANSLATIONS,
	[languagesEnums.ARABIC_LANGUAGE]: ARABIC_TRANSLATIONS,
	[languagesEnums.SPANISH_LANGUAGE]: SPANISH_TRANSLATIONS,
	[languagesEnums.FRENCH_LANGUAGE]: FRENCH_TRANSLATIONS,
	[languagesEnums.RUSSIAN_LANGUAGE]: RUSSIAN_TRANSLATIONS,
	[languagesEnums.TURKISH_LANGUAGE]: TURKISH_TRANSLATIONS,
	[languagesEnums.ITALIAN_LANGUAGE]: ITALIAN_TRANSLATIONS,
	[languagesEnums.GERMAN_LANGUAGE]: GERMAN_TRANSLATIONS,
}

export const getNativeLanguageFlagKey = (title) => {
	switch (title) {
		case ENGLISH_TRANSLATIONS.key:
			return ENGLISH_TRANSLATIONS.flagKey
		case PERSIAN_TRANSLATIONS.key:
			return PERSIAN_TRANSLATIONS.flagKey
		case ARABIC_TRANSLATIONS.key:
			return ARABIC_TRANSLATIONS.flagKey
		case SPANISH_TRANSLATIONS.key:
			return SPANISH_TRANSLATIONS.flagKey
		case FRENCH_TRANSLATIONS.key:
			return FRENCH_TRANSLATIONS.flagKey
		case RUSSIAN_TRANSLATIONS.key:
			return RUSSIAN_TRANSLATIONS.flagKey
		case TURKISH_TRANSLATIONS.key:
			return TURKISH_TRANSLATIONS.flagKey
		case ITALIAN_TRANSLATIONS.key:
			return ITALIAN_TRANSLATIONS.flagKey
		case GERMAN_TRANSLATIONS.key:
			return GERMAN_TRANSLATIONS.flagKey
		default:
			return ENGLISH_TRANSLATIONS.flagKey
	}
}

export const getLanguageDataByTitle = (title) => {
	switch (title) {
		case ENGLISH_TRANSLATIONS.titleInEnglish:
			return ENGLISH_TRANSLATIONS
		case PERSIAN_TRANSLATIONS.titleInEnglish:
			return PERSIAN_TRANSLATIONS
		case ARABIC_TRANSLATIONS.titleInEnglish:
			return ARABIC_TRANSLATIONS
		case SPANISH_TRANSLATIONS.titleInEnglish:
			return SPANISH_TRANSLATIONS
		case FRENCH_TRANSLATIONS.titleInEnglish:
			return FRENCH_TRANSLATIONS
		case RUSSIAN_TRANSLATIONS.titleInEnglish:
			return RUSSIAN_TRANSLATIONS
		case TURKISH_TRANSLATIONS.titleInEnglish:
			return TURKISH_TRANSLATIONS
		case ITALIAN_TRANSLATIONS.titleInEnglish:
			return ITALIAN_TRANSLATIONS
		case GERMAN_TRANSLATIONS.titleInEnglish:
			return GERMAN_TRANSLATIONS
		default:
			return ENGLISH_TRANSLATIONS
	}
}
