import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import serviceCall from "../../services";

const initialState = {
  loading: false,
  essentials: [],
  essentialData: null,
  error: '',
}

export const getEssentialTypes = createAsyncThunk(
  'essential/getEssentialTypes',
  () => {
    return serviceCall(
      'fetchEssentialTypes',
      null,
      'cloud',
    )
  }
)

export const getEssentialData = createAsyncThunk(
  'essential/getEssentialData',
  ({essentialType}) => {
    return serviceCall(
      'fetchEssential',
      {essentialType: `ESSENTIALS_${essentialType}`},
      'cloud',
    )
  }
)

const essentialSlice = createSlice({
  name: 'essential',
  initialState,
  reducers: {
    setAppUser: (state, action) => {
      state.appUserData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEssentialTypes.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getEssentialTypes.fulfilled, (state, action) => {
      state.loading = false
      state.essentials = action.payload
    })
    builder.addCase(getEssentialTypes.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(getEssentialData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getEssentialData.fulfilled, (state, action) => {
      state.loading = false
      state.essentialData = action.payload
    })
    builder.addCase(getEssentialData.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  },
})

export default essentialSlice.reducer