import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import sendContactUseCase from '../../useCase/contactUs/sendConactUseCase'


const initialState = {
  loading: false,
  formStatus: 'FORM',
  error: '',
}

export const sendContact = createAsyncThunk(
  'contactUs/sendContact',
  ({name, email, category, message}) => {
    return sendContactUseCase({name, email, category, message})
  },
)

const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    setContactFormStatus: (state, action) => {
      state.formStatus = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(sendContact.pending, (state) => {
      state.loading = true
    })
    builder.addCase(sendContact.fulfilled, (state, action) => {
      state.loading = false
      state.formStatus = 'SUCCEED'
    })

    builder.addCase(sendContact.rejected, (state, action) => {
      state.loading = false
      state.formStatus = 'FAILED'
      state.error = action.error.message
    })
  },
})

export const {setContactFormStatus} = contactUsSlice.actions

export default contactUsSlice.reducer