import serviceCall from "../../services";
import aiPromptMapper from "../../services/mapper/aiPrompt/aiPromptMapper";

const aiExerciseUseCase = async (data) => {
  const result = await serviceCall(
    'postAiExercisePrompt',
    data,
    'cloud',
  )

  return aiPromptMapper(result, data)
}

export default aiExerciseUseCase