import React from "react";

const Play = (props) => {
    return (
        <button className="play-button" onClick={props.handleClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 23">
                <path d="M11.5,0,23,16H0Z" transform="translate(16) rotate(90)" fill="#604581"/>
            </svg>
        </button>
    );
}

export default Play;