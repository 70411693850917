import React from 'react'
import StructureTemplateUseCase from './StructureTemplateUseCase'

const StructurePageUseCase = ({ structuresData }) => {
	const renderPage = (page) => {
		return page?.templateIds.map((item, i) => {
			let template = structuresData.sectionData.templates
				?.filter(o => o?.config?.platform === 'ALL' || o?.config?.platform === 'WEB')
				?.find(o => o.id === item.templateId)

			if (item.cardId) {
				template = {
					...template,
					data: {
						...template.data,
						cards: template.data.cards.filter(o => o.id === item.cardId),
					},
				}
			}

			return (
				<React.Fragment key={i}>
					<StructureTemplateUseCase template={ template } />
				</React.Fragment>
			)
		})
	}

	if (structuresData?.isSingleView && structuresData.sectionData?.singlePages?.length > 0) {
		const page = structuresData.sectionData?.singlePages?.[structuresData.currentPageIndex]
		if (page) {
			return (
				<div className={structuresData.disableWordColor ? 'textHolderColors-disabled' : ''}>
					{renderPage(page)}
				</div>
			)
		}
		return null
	} else {
		return structuresData?.sectionData?.templates
			?.filter(o => o?.config?.platform === 'ALL' || o?.config?.platform === 'WEB')
			?.map((template, i) => {
				return (
					<div className={structuresData.disableWordColor ? 'textHolderColors-disabled' : ''} key={i}>
						<StructureTemplateUseCase template={template} />
					</div>
				)
			}) ?? null
	}
}

export default StructurePageUseCase