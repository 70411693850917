import serviceCall from "../../services";

const deleteAppUserUseCase = async () => {
  return await serviceCall(
    'deleteAppUser',
    null,
    'cloud',
  )
}

export default deleteAppUserUseCase