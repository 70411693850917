import serviceCall from "../../services";

const getAllCategoryUseCase = async () => {
	const categories = await serviceCall(
		'fetchAllMemoryBoxCategory',
		null,
		'cloud',
	)

	return categories
}

export default getAllCategoryUseCase 