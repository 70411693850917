import { errorTranslationDb } from '../db/errorTranslationDb';
import {
	ERROR_ARABIC_LANGUAGE, ERROR_ENGLISH_LANGUAGE,
	ERROR_FRENCH_LANGUAGE, ERROR_GERMAN_LANGUAGE, ERROR_ITALIAN_LANGUAGE, ERROR_PERSIAN_LANGUAGE,
	ERROR_RUSSIAN_LANGUAGE, ERROR_SPANISH_LANGUAGE, ERROR_TURKISH_LANGUAGE,
	errorTranslationsLanguagesData
} from '../enums/errorLanguageEnums/errorLanguageEnums';
import errorEnglishTranslations from './error_english';
import errorPersianTranslations from './error_persian';
import errorArabicTranslations from './error_arabic';
import errorSpanishTranslations from './error_spanish';
import errorFrenchTranslations from './error_french';
import errorRussianTranslations from './error_russian';
import errorTurkishTranslations from './error_turkish';
import errorItalianTranslations from './error_italian';
import errorGermanTranslations from './error_german';


const localErrorTranslations = {
	[errorTranslationsLanguagesData[ERROR_ENGLISH_LANGUAGE].key]: errorEnglishTranslations,
	[errorTranslationsLanguagesData[ERROR_PERSIAN_LANGUAGE].key]: errorPersianTranslations,
	[errorTranslationsLanguagesData[ERROR_ARABIC_LANGUAGE].key]: errorArabicTranslations,
	[errorTranslationsLanguagesData[ERROR_SPANISH_LANGUAGE].key]: errorSpanishTranslations,
	[errorTranslationsLanguagesData[ERROR_FRENCH_LANGUAGE].key]: errorFrenchTranslations,
	[errorTranslationsLanguagesData[ERROR_RUSSIAN_LANGUAGE].key]: errorRussianTranslations,
	[errorTranslationsLanguagesData[ERROR_TURKISH_LANGUAGE].key]: errorTurkishTranslations,
	[errorTranslationsLanguagesData[ERROR_ITALIAN_LANGUAGE].key]: errorItalianTranslations,
	[errorTranslationsLanguagesData[ERROR_GERMAN_LANGUAGE].key]: errorGermanTranslations,

}

export async function addErrorTranslation(selectedTranslationLanguage) {
	const languageKey = selectedTranslationLanguage.key
	const translations = localErrorTranslations[languageKey]

	try {
		// Check if the version code has changed
		const storedVersion = localStorage.getItem(`errorTranslationVersion_${languageKey}`)
		const currentVersion = selectedTranslationLanguage.dbVersionCode;

		if (storedVersion !== currentVersion) {
			// Clear existing translations for this language
			await errorTranslationDb.table(selectedTranslationLanguage.dbTable).clear()

			// Insert updated translations into IndexedDB
			await errorTranslationDb
				.table(selectedTranslationLanguage.dbTable)
				.bulkPut(
					translations.map((item) => ({
						key: item.translationKey,
						value: item.translationValue,
					}))
				)

			// Update the stored version code
			localStorage.setItem(`errorTranslationVersion_${languageKey}`, currentVersion);
		}
	} catch (error) {
		console.error('Failed to add error translations to IndexedDB:', error)
	}
}