import { useDispatch, useSelector } from "react-redux";
import { getAppUser, setAppUser } from "../redux/features/appUserSlice";
import { logoutUserAccount } from "../redux/features/authenticationSlice";
import { useMemo } from "react";
import { setFirstTimeActions } from "../redux/features/firstTimeActionSlice";
import useUpdateVersion from "./useUpdateVersion";
import useActionRetryerWrapper from "./useDispatchWrapper";

const useAppUser = () => {
  const { appUserData } = useSelector(state => state.appUser)
  const { checkVersion } = useUpdateVersion()
  const dispatch = useDispatch()
  const { networkLossRetryerDispatch } = useActionRetryerWrapper()

  const isAdmin = useMemo(() => {
    return appUserData?.access?.roles?.includes('app_administrator')
  }, [appUserData?.access?.roles])

  const userHasPlan = useMemo(() => {
    return appUserData?.access?.hasPlan
  }, [appUserData?.access?.hasPlan])

  const setAppUserData = (data) => {
    dispatch(setAppUser(data))
    if (data) {
      dispatch(setFirstTimeActions(data?.action?.availableActions))
    }
  }

  const getAppUserData = () => {
    networkLossRetryerDispatch((retryId) => {
      dispatch(getAppUser({ retryId })).then((res) => {
        if (res.payload?.action) {
          dispatch(setFirstTimeActions(res?.payload?.action?.availableActions))
          checkVersion(res.payload?.pwaVersion)
        }
      })
    })
  }

  const logoutUser = () => {
    dispatch(logoutUserAccount()).then(() => {
      localStorage.removeItem('user_token')
      window.location.href = '/auth/login'
    })
  }

  return { appUserData, setAppUserData, getAppUserData, logoutUser, isAdmin, userHasPlan }
}

export default useAppUser