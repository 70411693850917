import componentStyle from './GrammarFormulaTemplate.module.scss'
import HtmlTextWrapper from '../../../../components/HtmlTextWrapper/HtmlTextWrapper'
import {useContext, useEffect, useRef} from 'react'
import colorsContext from '../../../../contexts/ColorsContext'
import {moreOptionsList} from '../../../../data'
import structuresContext from "../../../../contexts/StructuresContext";
import classNames from "classnames";
import {robotTeacherScrollIntoViewByElement} from "../../../../operations/robotTeacherScrollIntoViewByElement";
import {useSelector} from "react-redux";

const GrammarFormulaTemplate = ({template}) => {
  const {colors} = useContext(colorsContext)
  const {scrolling} = useSelector(state => state.roboTeacher)
  const {isRoboTeacher, roboTeacherData} = useContext(structuresContext)
  const isHidden = (isRoboTeacher ?? false) ? ((roboTeacherData.currentTime ?? 0) < (template?.hiddenTime ?? 0)) : false

  const elRef = useRef(null)

  useEffect(() => {
    if (template?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isHidden])


  return (
    <div className='container container-1'>
      <div
        ref={elRef}
        data-rt-id={template?.id}
        className={classNames(componentStyle.grammarFormulaTitle, {['hide-element']: isHidden})}
        style={{background: colors['C188'], borderColor: colors['C160']}}
      >
        <HtmlTextWrapper
          data={template.data.title}
          showPhraseOptions
          moreOptionsList={moreOptionsList}
          textColor={colors['C152']}
          optionsColor={colors['C160']}
        />
      </div>
    </div>
  )
}

export default GrammarFormulaTemplate