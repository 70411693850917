import getLevelBackgroundColorByNumber from "../../../operations/getLevelBackgroundColorByNumber";
import unitsMapper from "./unitsMapper";

const levelsMapper = (data, oldLevels = null) => {
  return data?.map((item, index) => {
    const levelNumber = index + 1
    return {
      id: index,
      level: item.level,
      title: item.levelStr,
      levelNumber: levelNumber,
      isOn: item.isOn,
      isActive: item.isActive,
      isFinished: item.isFinished,
      isInProgress: item.isInProgress,
      startDate: item.startDate,
      endDate: item.finishDate,
      completionPercentage: Math.floor(item.doneUnitsPercentage),
      finishedUnitCount: item.finishedUnitCount,
      unitsCount: item.totalUnits,
      availableItemCount: item.availableItemCount,
      levelStudyStatus: item.levelStudyStatus,
      backgroundColorCode: getLevelBackgroundColorByNumber(levelNumber),
      units: item.units?.length > 0
        ? unitsMapper(item.units, { level: item.level, title: item.title, levelNumber: levelNumber })
        : (oldLevels ? (oldLevels?.[index]?.units || null) : null)
    }
  })
}

export default levelsMapper