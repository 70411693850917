import styles from './cardTitleEnrollment.module.scss'
import useTranslatedText from "../../../hooks/useTranslatedText";

const CardTitleEnrollment = ({title, description, progress}) => {
  const {dir} = useTranslatedText()

  return (
    <>
      <div className={styles.progressBar}>
        <div className={styles.bar} style={{width: `${(100 * progress)/3}%`}}/>
        <div className={styles.steps} >
          <div className={styles.progressStepItem} style={{color: progress < 1 ? '#0000004d' : '#000000' }}>1</div>
          <div className={styles.progressStepItem} style={{color: progress < 2 ? '#0000004d' : '#000000' }}>2</div>
          <div className={styles.progressStepItem} style={{color: progress < 3 ? '#0000004d' : '#000000' }}>3</div>
        </div>
      </div>
      <div dir={dir} className={styles.card}>
        <p className={styles.text}>{title}</p>
        <div className={styles.description}>{description}</div>
      </div>
    </>
  )
}
export default CardTitleEnrollment