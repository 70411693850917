import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  currentGlobalAudioId: '',
}

const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    setGlobalAudioId: (state, action) => {
      state.currentGlobalAudioId = action.payload
    }
  }
})

export const {setGlobalAudioId} = audioSlice.actions

export default audioSlice.reducer