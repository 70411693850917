import axios from 'axios'
import { currentMainAPI } from './mainAPIs'
import { getErrorTranslatedText } from '../util/getErrorTranslatedText'
import showToast from '../util/toastUtils/toastUtils'

// Create the Axios instance
const publicAxiosInstance = axios.create({
	baseURL: `${currentMainAPI}/api`,
})


publicAxiosInstance.interceptors.response.use(
	(response) => response.data,
	async (error) => {
		const suppressToast = error.config?.suppressToast
		const status = error?.response?.status
		if (status !== 401) {
			if (error.response) {
				try {
					const errorKey = error.response?.data?.error ?? error.response?.data?.detail
					if (errorKey) {
						const errorMessage = await getErrorTranslatedText(errorKey)
						error.translatedMessage = errorMessage
						if (!suppressToast) {
							showToast(errorMessage, 'error')
						}
					} else {
						const errorKey = 'OOPS_SOMETHING_WENT_WRONG'
						const errorMessage = await getErrorTranslatedText(errorKey)
						if (errorMessage) showToast(errorMessage, 'error')
					}
				} catch (err) {
					const errorKey = 'OOPS_SOMETHING_WENT_WRONG'
					const errorMessage = await getErrorTranslatedText(errorKey)
					if (errorMessage && !suppressToast) showToast(errorMessage)
				}
			}
		}

		// Reject the promise to allow further handling if needed
		return Promise.reject(error)
	},
)

export default publicAxiosInstance
