import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import addFlashCardUseCase from "../../../useCase/memoryBoxUseCase/addFlashCardUseCase";
import editFlashCardUseCase from "../../../useCase/memoryBoxUseCase/editFlashCardUseCase";
import {baseAsyncThunk} from "../../baseAsyncThunk";

const initialState = {
  text_A: "",
  text_B: "",
  text_HINT: "",
  imageUrl_A: "",
  imageUrl_B: "",
  imageUrl_HINT: "",
  soundUrl_A: "",
  soundUrl_B: "",
  soundUrl_Hint: "",
  textAlign_A: 'LEFT',
  textAlign_B: 'LEFT',
  textAlign_HINT: 'LEFT',
  dir_A: 'LTR',
  dir_B: 'LTR',
  dir_HINT: 'LTR',
  pendingSaveFlashCard: false
}

export const addFlashCard = baseAsyncThunk(
  'addMemoryBoxCard/addFlashCard',
  ({categoryId, front, back, hint, toggleSuperMemory}) => {
    return addFlashCardUseCase({categoryId, front, back, hint, toggleSuperMemory})
  },
)

export const editFlashCard = baseAsyncThunk(
  'addMemoryBoxCard/editFlashCard',
  ({id, categoryId, front, back, hint, toggleSuperMemory}) => {
    return editFlashCardUseCase({id, categoryId, front, back, hint, toggleSuperMemory})
  },
)

const addMemoryBoxCardSlice = createSlice({
  name: 'addMemoryBoxCard',
  initialState,
  reducers: {
    set: (state, action) => {
      state.text_A = action.payload?.front?.text
      state.text_B = action.payload?.back?.text
      state.text_HINT = action.payload?.hint?.text
      state.imageUrl_A = action.payload?.front?.imageUrl
      state.imageUrl_B = action.payload?.back?.imageUrl
      state.imageUrl_HINT = action.payload?.hint?.imageUrl
      state.soundUrl_A = action.payload?.front?.soundUrl
      state.soundUrl_B = action.payload?.back?.soundUrl
      state.soundUrl_HINT = action.payload?.hint?.soundUrl
      state.textAlign_A = action.payload?.front?.textAlign
      state.textAlign_B = action.payload?.back?.textAlign
      state.textAlign_HINT = action.payload?.hint?.textAlign
      state.dir_A = action.payload?.front?.dir
      state.dir_B = action.payload?.back?.dir
      state.dir_HINT = action.payload?.hint?.dir
    },
    clear: (state, action) => {
      state.text_A = ""
      state.text_B = ""
      state.text_HINT = ""
      state.imageUrl_A = ""
      state.imageUrl_B = ""
      state.imageUrl_HINT = ""
      state.soundUrl_A = ""
      state.soundUrl_B = ""
      state.soundUrl_HINT = ""
      state.textAlign_A = "LEFT"
      state.textAlign_B = "LEFT"
      state.textAlign_HINT = "LEFT"
      state.dir_A = "LTR"
      state.dir_B = "LTR"
      state.dir_HINT = "LTR"
    },
    setText_A: (state, action) => {
      state.text_A = action.payload
    },
    setText_B: (state, action) => {
      state.text_B = action.payload
    },
    setText_HINT: (state, action) => {
      state.text_HINT = action.payload
    },
    setImageUrl_A: (state, action) => {
      state.imageUrl_A = action.payload
    },
    setImageUrl_B: (state, action) => {
      state.imageUrl_B = action.payload
    },
    setImageUrl_HINT: (state, action) => {
      state.imageUrl_HINT = action.payload
    },
    setSoundUrl_A: (state, action) => {
      state.soundUrl_A = action.payload
    },
    setSoundUrl_B: (state, action) => {
      state.soundUrl_B = action.payload
    },
    setSoundUrl_HINT: (state, action) => {
      state.soundUrl_HINT = action.payload
    },
    setTextAlign_A: (state, action) => {
      state.textAlign_A = action.payload
    },
    setTextAlign_B: (state, action) => {
      state.textAlign_B = action.payload
    },
    setTextAlign_HINT: (state, action) => {
      state.textAlign_HINT = action.payload
    },
    setDir_A: (state, action) => {
      state.dir_A = action.payload
    },
    setDir_B: (state, action) => {
      state.dir_B = action.payload
    },
    setDir_HINT: (state, action) => {
      state.dir_HINT = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(addFlashCard.pending, (state) => {
      state.pendingSaveFlashCard = true
    }).addCase(addFlashCard.fulfilled, (state) => {
      state.pendingSaveFlashCard = false
    }).addCase(addFlashCard.rejected, (state) => {
      state.pendingSaveFlashCard = false
    }).addCase(editFlashCard.pending, (state) => {
      state.pendingSaveFlashCard = true
    }).addCase(editFlashCard.fulfilled, (state) => {
      state.pendingSaveFlashCard = false
    }).addCase(editFlashCard.rejected, (state) => {
      state.pendingSaveFlashCard = false
    })
  }
})

export const {
  set,
  clear,
  setText_A,
  setText_B,
  setText_HINT,
  setImageUrl_A,
  setImageUrl_B,
  setImageUrl_HINT,
  setSoundUrl_A,
  setSoundUrl_B,
  setSoundUrl_HINT,
  setTextAlign_A,
  setTextAlign_B,
  setTextAlign_HINT,
  setDir_A,
  setDir_B,
  setDir_HINT,

} = addMemoryBoxCardSlice.actions

export default addMemoryBoxCardSlice.reducer