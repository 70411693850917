const exerciseStatisticsMapper = (data) => {
  return {
    totalUnitStudied: data?.totalUnitStudied ?? 0,
    lastUnitStudied: data?.lastUnitStudied ?? 0,
    allCoursesRatePercent: Math.floor(data?.allCoursesRatePercent) ?? 0,
    totalCorrect: data?.totalCorrect,
    totalIncorrect: data?.totalIncorrect,
    skipped: data?.skipped,
    totalCount: data?.totalCount,
    correctPercentage: Math.floor(data?.correctPercentage) ?? 0,
    incorrectPercentage: Math.floor(data?.incorrectPercentage) ?? 0,
    skippedPercentage: Math.floor(data?.skippedPercentage) ?? 0,
    exercises: data?.sections?.map(exercise => {
      return {
        id: exercise.id,
        sectionType: exercise.sectionType,
        exerciseGroup: exercise.exerciseGroup,
        totalCorrect: exercise.totalCorrect,
        totalIncorrect: exercise.totalIncorrect,
        skipped: exercise.skipped,
        totalCount: exercise.totalCount,
        correctPercentage: exercise.correctPercentage?.toFixed(0) ?? 0,
        incorrectPercentage: exercise.incorrectPercentage?.toFixed(0) ?? 0,
        skippedPercentage: exercise.skippedPercentage?.toFixed(0) ?? 0,
      }
    }) ?? [],
    units: data?.units?.sort((a, b) => a.unitId - b.unitId) ?? [],
    bestResultTime: data?.bestResultTime ?? [],
    mostStudyTime: data?.mostStudyTime ?? []
  }
}

export default exerciseStatisticsMapper