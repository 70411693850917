const filesAndFoldersMapper = (data) => {

	const result = {
		items: [],
		path: data?.path || []
	};

	data?.folderAndFiles?.forEach((item) => {
		result.items.push({
			id: item.id || '',
			userId: item.userId || '',
			title: item.title || '',
			color: item.color || '',
			parentFolderId: item.parentFolderId || '',
			fileType: item.fileType || 'FOLDER',
			date: item.createdDate || '2000-01-01',
			isSelected: false
		});
	});

	result.path.splice(0, 1);

	return result;
}

export default filesAndFoldersMapper