import React from 'react'
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react'
import {isMobile} from 'react-device-detect'

const OverlayScrollbar = ({children, isLight = false}) => {
  if (isMobile) {
    return children
  }

  return (
    <OverlayScrollbarsComponent
      style={{height: '100%'}}
      options={{
        className: isLight ? 'os-theme-light' : 'os-theme-dark',
        scrollbars: {
          autoHide: 'leave',
          autoHideDelay: 400,
        },
      }}
      defer
    >
      {children}
    </OverlayScrollbarsComponent>
  )
}

export default OverlayScrollbar