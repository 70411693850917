import { configureStore } from '@reduxjs/toolkit'
import { reducer as formReducer } from 'redux-form'
import sentryMiddleware from "./middlewares/sentryMiddleware";

import nativeLanguageSlice from './features/nativeLanguageSlice'
import visitorSlice from './features/visitorSlice'
import learningTypeTestSlice from './features/learningTypeTestSlice'
import placementTestSlice from './features/placementTestSlice'
import courseIntensitySlice from './features/courseIntensitySlice'
import authenticationSlice from './features/authenticationSlice'
import readingSlice from './features/readingSlice'
import conversationSlice from './features/conversationSlice'
import audioSlice from './features/audioSlice'
import drillSlice from './features/drillSlice'
import roboChatSlice from './features/roboChatSlice'
import writingSlice from './features/writingSlice'
import archiveSlice from './features/archiveSlice'
import mediaSlice from './features/mediaSlice'
import exerciseSlice from './features/exerciseSlice'
import structuredSectionSlice from './features/structuredSectionSlice'
import translationSlice from './features/translationSlice'
import memoryTestSlice from './features/memoryTestSlice'
import appUserSlice from './features/appUserSlice'
import reviewMistakeSlice from './features/reviewMistakeSlice'
import gameSlice from './features/gameSlice'
import dualGameSlice from './features/dualGameSlice'
import statisticsSlice from './features/statisticsSlice'
import alphabetSlice from './features/alphabetSlice'
import commonDigraphSlice from './features/commonDigraphSlice'
import numberSectionSlice from './features/numberSectionSlice'
import ordinalNumberSectionSlice from './features/ordinalNumberSectionSlice'
import yourGamesRecordsSlice from "./features/yourGamesRecordSlice";
import evaluationReportSlice from "./features/evaluationReportSlice";
import roboTeacherSlice from "./features/roboTeacherSlice";
import accentSlice from "./features/accentSlice";
import vocabularySlice from "./features/vocabulary/vocabularySlice";
import essentialSlice from "./features/essentialSlice";
import dictionarySlice from "./features/dictionarySlice";
import spellingWordPyramidSlice from "./features/tools/spelling/spellingWordPyramidSlice";
import sharedSpellingSlice from "./features/tools/spelling/sharedSpellingSlice";
import spellingWordLinearSlice from "./features/tools/spelling/spellingWordLinearSlice";
import spellingWordUnscrambleSlice from "./features/tools/spelling/spellingWordUnscrambleSlice";
import spellingSentenceSlice from "./features/tools/spelling/spellingSentenceSlice";
import listeningSlice from "./features/tools/listening/listeningSlice";
import speakingSlice from "./features/tools/speaking/speakingSlice";
import memorizeSlice from "./features/tools/memorize/memorizeSlice";
import chooseCourseSlice from "./payment/chooseCourseSlice";
import chooseGatewaySlice from "./payment/chooseGatewaySlice";
import sharedPaymentSlice from "./payment/sharedPaymentSlice";
import favoriteSlice from "./features/favoriteSlice";
import addCategoryCardSlice from "./features/addCategoryCardSlice";
import themeSlice from "./features/themeSlice";
import settingSlice from "./features/settingSlice";
import modalSlice from "./features/modalSlice";
import yourSelectionSlice from "./features/memoryBox/yourSelectionSlice";
import aboutSlice from "./features/aboutSlice";
import faqSlice from './features/faqSlice';
import boxSlice from './features/memoryBox/boxSlice'
import flashCardSlice from './features/memoryBox/flashCardSlice'
import levelUnitSlice from "./features/levelUnitSlice";
import tableOfContentSlice from "./features/tableOfContentSlice";
import radioStationSlice from "./features/radioStationSlice";
import tedVideoSlice from './features/tedVideoSlice'
import newspaperSlice from './features/newspaperSlice'
import contactUsSlice from './features/contactUsSlice'
import studyDifficultySlice from "./features/studyDifficultySlice";
import todayStudySlice from "./features/todayStudySlice";
import callBackResultSlice from "./payment/callBackResultSlice";
import sharedSectionSlice from "./features/sharedSectionSlice";
import notepadSlice from './features/notepadSlice'
import memoryBoxCategorySlice from "./features/memoryBox/memoryBoxCategorySlice";
import memoryBoxSharedSlice from "./features/memoryBox/memoryBoxSharedSlice";
import addMemoryBoxCardSlice from "./features/memoryBox/addMemoryBoxCardSlice";
import memoryBoxActionsSlice from "./features/memoryBox/memoryBoxActionsSlice";
import listSlice from "./features/memoryBox/listSlice";
import messagesCategorySlice from "./features/messages/messagesCategorySlice";
import statusSlice from "./features/statusSlice";
import exerciseStatisticSlice from "./features/exercise/exerciseStatisticSlice";
import toolsSlice from "./features/tools/toolsSlice";
import bottomSheetSlice from "./features/bottomSheetSlice";
import recommendationSlice from "./features/recommendationSlice";
import helpSlice from "./features/helpSlice";
import aiChatSlice from "./features/aiChatSlice";
import resetPasswordSlice from "./features/resetPasswordSlice";
import speechToTextSlice from "./features/speechToTextSlice";
import studyLogSlice from "./features/studyLogSlice";
import homepageSlice from "./features/homepageSlice";
import sidebarSlice from "./features/sidebarSlice";
import helpActionSlice from "./features/helpActionSlice";
import firstTimeActionSlice from "./features/firstTimeActionSlice";
import versionSlice from "./features/versionSlice";
import staticContentSlice from "./features/staticContentSlice";
import quickReviewSlice from './features/quickReviewSlice';
import retryMiddleware from './middlewares/retryMiddleware';

const store = configureStore({
	reducer: {
		homepage: homepageSlice,
		sidebar: sidebarSlice,
		form: formReducer,
		nativeLanguage: nativeLanguageSlice,
		visitor: visitorSlice,
		learningTypeTest: learningTypeTestSlice,
		memoryTest: memoryTestSlice,
		placementTest: placementTestSlice,
		courseIntensity: courseIntensitySlice,
		authentication: authenticationSlice,
		reading: readingSlice,
		conversation: conversationSlice,
		drill: drillSlice,
		roboChat: roboChatSlice,
		writing: writingSlice,
		audio: audioSlice,
		media: mediaSlice,
		archive: archiveSlice,
		exercise: exerciseSlice,
		structuredSection: structuredSectionSlice,
		translation: translationSlice,
		appUser: appUserSlice,
		reviewMistake: reviewMistakeSlice,
		game: gameSlice,
		dualGame: dualGameSlice,
		statistics: statisticsSlice,
		alphabet: alphabetSlice,
		commonDigraph: commonDigraphSlice,
		numberSection: numberSectionSlice,
		ordinalNumberSection: ordinalNumberSectionSlice,
		yourGamesRecord: yourGamesRecordsSlice,
		evaluationReport: evaluationReportSlice,
		roboTeacher: roboTeacherSlice,
		accent: accentSlice,
		vocabulary: vocabularySlice,
		essential: essentialSlice,
		dictionary: dictionarySlice,
		tools: toolsSlice,
		sharedSpelling: sharedSpellingSlice,
		spellingWordPyramid: spellingWordPyramidSlice,
		spellingWordLinear: spellingWordLinearSlice,
		spellingWordUnscramble: spellingWordUnscrambleSlice,
		spellingSentence: spellingSentenceSlice,
		listening: listeningSlice,
		speaking: speakingSlice,
		memorize: memorizeSlice,
		chooseCourse: chooseCourseSlice,
		chooseGateway: chooseGatewaySlice,
		sharedPayment: sharedPaymentSlice,
		callBackResult: callBackResultSlice,
		favorite: favoriteSlice,
		addCategoryCard: addCategoryCardSlice,
		theme: themeSlice,
		setting: settingSlice,
		modal: modalSlice,
		yourSelection: yourSelectionSlice,
		about: aboutSlice,
		faq: faqSlice,
		memoryBoxCategory: memoryBoxCategorySlice,
		memoryBoxShared: memoryBoxSharedSlice,
		memoryBoxBoxes: boxSlice,
		memoryBoxList: listSlice,
		memoryBoxFlashCard: flashCardSlice,
		levelUnit: levelUnitSlice,
		tableOfContent: tableOfContentSlice,
		radioStation: radioStationSlice,
		tedVideo: tedVideoSlice,
		newspapers: newspaperSlice,
		contactUs: contactUsSlice,
		todayStudy: todayStudySlice,
		studyDifficulty: studyDifficultySlice,
		sharedSection: sharedSectionSlice,
		notepad: notepadSlice,
		addMemoryBoxCard: addMemoryBoxCardSlice,
		memoryBoxActions: memoryBoxActionsSlice,
		messagesCategory: messagesCategorySlice,
		status: statusSlice,
		exerciseStatistic: exerciseStatisticSlice,
		bottomSheet: bottomSheetSlice,
		recommendation: recommendationSlice,
		help: helpSlice,
		aiChat: aiChatSlice,
		resetPassword: resetPasswordSlice,
		speechToText: speechToTextSlice,
		studyLog: studyLogSlice,
		helpAction: helpActionSlice,
		firstTimeAction: firstTimeActionSlice,
		version: versionSlice,
		staticContent: staticContentSlice,
		quickReview: quickReviewSlice
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sentryMiddleware, retryMiddleware),
})

export default store