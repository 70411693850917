import * as React from "react"

const AddSvg = ({color}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox='0 0 48 48'
    style={{transition: 'all 150ms ease-out'}}
  >
    <path
      d="M45.6 21.38H26.55V2.5a2.5 2.5 0 0 0-5 0v18.88H2.5a2.5 2.5 0 0 0 0 5h19.05V45.6a2.5 2.5 0 1 0 5 0V26.38H45.6a2.5 2.5 0 0 0 0-5Z"
      fill={color}
    />
  </svg>
)

export default AddSvg
