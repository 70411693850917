export const LEVEL_AND_UNIT = 'LEVEL_AND_UNIT'
export const TODAY_STUDY = 'TODAY_STUDY'
export const EXERCISES = 'EXERCISES'

export const studyPagesData = {
  [LEVEL_AND_UNIT]: {
    title: 'Levels and Units'
  },
  [TODAY_STUDY]: {
    title: 'Study Plan'
  },
  [EXERCISES]: {
    title: 'Exercises'
  },
}