import serviceCall from "../../services"

const matchingExactHintUseCase = async (
	{
		matchType,
		inputType,
		sectionType,
		toolsCheck,
		unitId,
		trackerNumber,
		matchCases,
		isVoiceRecognition,
		quizIndex,
		dialogNumber
	}
) => {
	const res = await serviceCall(
		'postMatchingExactHint',
		{
			matchType,
			sectionType,
			toolsCheck,
			unitId,
			trackerNumber,
			matchCases,
			isVoiceRecognition,
			inputType
		},
		'cloud',
	)

	const result = {
		data: res,
		quizIndex: quizIndex ?? null,
		dialogNumber: dialogNumber ?? null,
	}

	return result
}

export default matchingExactHintUseCase