import * as React from "react"

const EssentialsSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
       width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
      <style type="text/css">
        {
          `.EssentialsSvg-st0{fill:none;stroke:${color};stroke-width:22;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10.0119;}`
        }
      </style>
      <g>
        <path
            className="EssentialsSvg-st0"
            d="M187.2,59.9H35.1c-4.5,0-8.3,3.8-8.3,8.3V456c0,4.5,3.8,8.3,8.3,8.3H440c4.5,0,8.3-3.8,8.3-8.3V214.2"
        />
        <path className="EssentialsSvg-st0" d="M448.2,224.1v-156c0-4.5-3.8-8.3-8.3-8.3H287.8" />
        <path
            className="EssentialsSvg-st0"
            d="M159.1,503.5H477c4.5,0,8.3-3.8,8.3-8.3V107.4c0-4.5-3.8-8.3-8.3-8.3h-28.7"
        />
        <path className="EssentialsSvg-st0" d="M63.8,464.1v31.1c0,4.5,3.8,8.3,8.3,8.3h88" />
        <path
            className="EssentialsSvg-st0"
            d="M267.8,14.2v8.3c0,8.8-3.4,17.3-9.5,23.7l-2.1,2.2v18.2l8.6,12.1c1.2,1.7,0,4.1-2.1,4.1h-50.4 c-2.1,0-3.4-2.4-2.1-4.1l8.6-12.1V48.4l-2.1-2.2c-6-6.4-9.5-14.9-9.5-23.7v-8.3c0-1.2,1-2.2,2.2-2.2h56.1 C266.8,12,267.8,13,267.8,14.2z"
        />
        <line className="EssentialsSvg-st0" x1={237.5} y1={83.8} x2={237.5} y2={129.1} />
      </g>
    </svg>
)

export default EssentialsSvg
