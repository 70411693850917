import {htmlTextConversion} from '../mapperConversions'
import {shuffle} from 'lodash'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'

const fetchPutInOrderConversationMapper = (data) => {
  const exerciseStack = data.exerciseStacks[0]

  return {
    pageType: pageGuidEnums.PUT_IN_ORDER_CONVERSATION_EXERCISE,
    data: {
      paginable: false,
      type: 'PUT_IN_ORDER_CONVERSATION',
      title: data.title ?? null,
      checked: false,
      checkable: false,
      unlocked: false,
      stack: {
        id: exerciseStack.id,
        itemId: exerciseStack.items[0]?.id,
        title: exerciseStack.title ?? null,
        joined: exerciseStack.joined,
        givens: shuffle([...exerciseStack.items[0]?.data.givens]).map(given => {
          return {
            ...given,
            text: htmlTextConversion(given.text),
            selected: false,
            order: -1,
            userAnswerStatus: null,
          }
        }),
      },
    }
  }
}

export default fetchPutInOrderConversationMapper