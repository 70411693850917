const fetchLearningTypeTestMapper = (response) => {

	const renderDTO = (data) => {
		if (data) {
			return {
				...data,
				checkable: false,
				questions: [...data?.questions]?.map(question => {
					return {
						...question,
						answers: [...question?.answers]?.map(answer => {
							return {
								...answer,
								selected: false,
							}
						}),
						userAnswerStatus: null
					}
				}),
			}
		}
		return {
			checkable: false,
			questions: []
		}
	}

	return {
		english: renderDTO(response.english),
		native: renderDTO(response.nativeLanguage),
	}
}

export default fetchLearningTypeTestMapper