import confetti from 'canvas-confetti'

const useCanvasConfetti = () => {
	const runConfettiFireworks = (duration = 5) => {
		var duration = 2 * 1000
		var animationEnd = Date.now() + duration
		var defaults = { startVelocity: 30, spread: 360, ticks: 90, zIndex: 20001, scalar: 1.2 }

		function randomInRange(min, max) {
			return Math.random() * (max - min) + min
		}

		var interval = setInterval(function () {
			var timeLeft = animationEnd - Date.now()

			if (timeLeft <= 0) {
				return clearInterval(interval)
			}

			var particleCount = 100 * (timeLeft / duration)
			// since particles fall down, start a bit higher than random
			confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } })
			confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } })
		}, 250)
	}

	return { runConfettiFireworks }
}

export default useCanvasConfetti
