import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const reviewMistakeDragAndDropCategoryMapper = (data, filterByUserAnswerData = []) => {
  return {
    ...data,
    exerciseStacks: data.exerciseStacks?.map(stack => {
      return {
        ...stack,
        items: stack.items.map(item => {
          const choiceList = item.data.choices[0].items
          const itemUserAnswer = filterByUserAnswerData.filter(it => it.itemId === item.id)

          return {
            ...item,
            data: {
              ...item.data,
              choices: [{
                index: 0,
                items: [...choiceList].map(it => {
                  return {
                    ...it,
                    isSolvedBefore: itemUserAnswer.some(u => {
                      return u.userAnswer === it.text?.trackerNumber && (u.userAnswerStatus === AnswerStatusEnums.CORRECT)
                    }),
                  }
                })
              }],
            }
          }
        })
      }
    })
  }
}

export default reviewMistakeDragAndDropCategoryMapper