import serviceCall from "../../services";
import helpMapper from "../../services/mapper/help/helpMapper";

const helpUseCase = async ({page}) => {
	const result = await serviceCall(
		'fetchHelp',
		{page},
		'cloud',
	)
	return helpMapper(result, page)
}

export default helpUseCase