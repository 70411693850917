export const C1 = '#231F20CC'
export const C2 = '#FCFCFC'
export const C3 = '#1D5959'
export const C4 = '#226363'
export const C5 = '#277171'
export const C6 = '#2D8080'
export const C7 = '#318C8C'
export const C8 = '#3A9797'
export const C9 = '#40A1A1'
export const C10 = '#49ADAD'
export const C11 = '#1D5959'
export const C12 = '#0B2B40'
export const C13 = '#164772'
export const C14 = '#1D5959'
export const C15 = '#164772'
export const C16 = '#BFC932'
export const C17 = '#FFDD15'
export const C18 = '#FF8800'
export const C19 = '#FCFCFC'
export const C20 = '#0B2B40'
export const C21 = '#FCFCFC4D'
export const C22 = '#FCFCFCCC'
export const C23 = '#FCFCFC'
export const C24 = '#AEF359'
export const C25 = '#89D99C'
export const C26 = '#FCFCFC4D'
export const C27 = '#D0D2D3'
export const C28 = '#A2A1A1'
export const C29 = '#E20613'
export const C30 = '#1b982f'
export const C31 = '#AEF359'
export const C32 = '#2A2D3E80'
export const C33 = '#164772'
export const C34 = '#706F6F'
export const C35 = '#74CAAB66'
export const C36 = '#6F8E45'
export const C37 = '#1D5959'
export const C38 = '#48a483'
export const C39 = '#FCFCFC4D'
export const C40 = '#307777'
export const C41 = '#74CAAB'
export const C42 = '#164772'
export const C43 = '#FCFCFC'
export const C44 = '#FCFCFC'
export const C45 = '#3B8B6E'
export const C46 = '#E20613'
export const C47 = '#307777'
export const C48 = '#E20613'
export const C49 = '#3B8B6E'
export const C50 = '#EB008B'
export const C51 = '#E20613'
export const C52 = '#E20613'
export const C53 = '#E20613'
export const C54 = '#164772'
export const C55 = '#AEF359'
export const C56 = '#74CAAB'
export const C57 = 'linear-gradient(to right,#7C0EFF,#4F78FD,#09DEF8)'
export const C58 = '#09DEF8'
export const C59 = '#E1BD47'
export const C60 = '#6F8E45'
export const C61 = '#E20613'
export const C62 = '#1D5959'
export const C63 = '#307777'
export const C64 = '#B1B1B1'
export const C65 = '#10385B'
export const C66 = '#89D99C'
export const C67 = 'linear-gradient(to bottom,#FCFCFC,#D0D2D3)'
export const C68 = '#1D5959'
export const C69 = '#878787'
export const C70 = '#429f7d'
export const C71 = 'linear-gradient(to right,#37B34A,#E20613)'
export const C72 = '#164772'
export const C73 = '#217676'
export const C74 = '#0B2B40'
export const C75 = '#164772'
export const C76 = '#0B2B40'
export const C77 = '#4D7B7B'
export const C78 = '#307777'
export const C79 = '#3B8B6E'
export const C80 = '#89D99C'
export const C81 = '#74CAAB'
export const C82 = '#6F8E45'
export const C83 = '#54D4A6'
export const C84 = '#164772'
export const C85 = '#094273'
export const C86 = '#398686'
export const C87 = '#3AA9A9'
export const C88 = '#679829'
export const C89 = '#33536E'
export const C90 = '#348B6B'
export const C91 = '#165A42'
export const C92 = '#1E3D58'
export const C93 = '#2B7A5E'
export const C94 = '#6FAA21'
export const C95 = '#164772'
export const C96 = '#165A42'
export const C97 = '#3AA9A9'
export const C98 = '#89D99C'
export const C99 = '#E20613'
export const C100 = '#48CDF7'
export const C101 = '#FCFCFC'
export const C102 = '#89D99C'
export const C103 = '#AEF359'
export const C104 = 'linear-gradient(to bottom,#CDE7E5,#676C74)'
export const C105 = '#74CAAB'
export const C106 = 'linear-gradient(to right,#B1B1B1,#FCFCFC)'
export const C107 = '#89D99C'
export const C108 = '#E20613'
export const C109 = '#231F2080'
export const C110 = '#2f639f'
export const C111 = '#1C4E6B'
export const C112 = '#919191'
export const C113 = '#0D0C07'
export const C114 = '#B4C2C3'
export const C115 = '#F4EEE2'
export const C116 = '#FFFFCB'
export const C117 = '#EFECDE'
export const C118 = '#669ACC'
export const C119 = '#659EB9'
export const C120 = '#986665'
export const C121 = '#9A7E4E'
export const C122 = '#CC9866'
export const C123 = '#131A22'
export const C124 = '#3B3B3B'
export const C125 = '#030303'
export const C126 = '#666666'
export const C127 = '#616161'
export const C128 = '#D9C39A'
export const C129 = '#A69370'
export const C130 = '#979832'
export const C131 = '#A58C4C'
export const C132 = '#9D9686'
export const C133 = '#C3943B'
export const C134 = '#336667'
export const C135 = '#0C0E1A'
export const C136 = '#656700'
export const C137 = '#5D5115'
export const C138 = '#C0A93F'
export const C139 = '#D4D4D2'
export const C140 = '#A6A5A1'
export const C141 = '#8385B9'
export const C142 = '#6F8E45'
export const C143 = '#FCFCFC82'
export const C144 = '#FCFCFCCC'
export const C145 = '#164772'
export const C146 = '#39A93580'
export const C147 = '#868686'
export const C148 = '#89D99C'
export const C149 = '#AEF359'
export const C150 = '#89D99C'
export const C151 = '#231F20'
export const C152 = '#231F20'
export const C153 = '#FCFCFC'
export const C154 = '#89D99C'
export const C155 = '#3D4158'
export const C156 = 'linear-gradient(to right,#5BC5F1,#F8B133)'
export const C157 = '#89D99C'
export const C158 = '#89D99C'
export const C159 = '#6F8E45'
export const C160 = '#FCFCFC'
export const C161 = '#1D5959'
export const C162 = '#277171'
export const C163 = '#318C8C'
export const C164 = '#3A9797'
export const C165 = '#49ADAD'
export const C166 = '#55C6C6'
export const C167 = '#FCFCFC'
export const C168 = '#FCFCFC4D'
export const C169 = 'radial-gradient(#307777,#89D99C,#307777)'
export const C170 = '#FCFCFC80'
export const C171 = 'linear-gradient(to right,#D0D2D3,#FCFCFC)'
export const C172 = '#6F8E45B3'
export const C173 = '#706F6F'
export const C174 = '#89D99C9B'
export const C175 = '#0B2B40'
export const C176 = '#3B8B6E'
export const C177 = '#3B8B6E'
export const C178 = '#FCFCFC80'
export const C179 = '#FCFCFC'
export const C180 = '#164772'
export const C181 = '#E20613'
export const C182 = '#89D99C'
export const C183 = '#0B2B40'
export const C184 = '#1D5959'
export const C185 = '#307777'
export const C186 = '#3B8B6E'
export const C187 = '#FCFCFC'
export const C188 = '#89D99C'
export const C189 = '#E20613'
export const C190 = '#89D99C'
export const C191 = '#74CAAB'
export const C192 = '#1D5959CC'
export const C193 = '#307777'
export const C194 = '#164772'
export const C195 = '#E20613'
export const C196 = '#74CAAB4D'
export const C197 = '#FCFCFC4D'
export const C198 = '#FCFCFC'
export const C199 = '#1D5959'
export const C200 = '#1D5959'
export const C201 = '#429678'
export const C202 = '#FCFCFC'
export const C203 = '#1D595980'
export const C204 = '#FCFCFC80'
export const C205 = '#74CAAB'
export const C206 = '#E20613'
export const C207 = '#E20613'
export const C208 = '#E20613'
export const C209 = '#FCFCFC'
export const C210 = '#164772B3'
export const C211 = '#164772'
export const C212 = '#89D99C'
export const C213 = '#AEF359'
export const C214 = '#FCFCFC99'
export const C215 = '#7C0EFF'
export const C216 = '#E20613'
export const C217 = '#E20613'
export const C218 = '#FCFCFC80'
export const C219 = '#184040CC'
export const C220 = '#3B8B6E80'
export const C221 = '#D0D2D3'
export const C222 = '#FCFCFC40'
export const C223 = '#AEF359'
export const C224 = '#E20613'
export const C225 = '#89D99C'
export const C226 = '#E1E1E1'
export const C227 = '#164772'
export const C228 = '#bfc1c7'
export const C229 = '#89D99C'
export const C230 = '#FCFCFC'
export const C231 = '#164772'
export const C232 = '#164772'
export const C233 = '#231F20'
export const C234 = '#307777'
export const C235 = '#6DA67B'
export const C236 = '#307777'
export const C237 = '#E20613'
export const C238 = '#164772'
export const C239 = '#164772'
export const C240 = '#1D5959'
export const C241 = '#FCFCFCB3'
export const C242 = '#74CAAB'
export const C243 = '#6C66AF'
export const C244 = '#74CAAB80'
export const C245 = '#E20613'
export const C246 = '#E20613'
export const C247 = '#74CAABB8'
export const C248 = '#CCB058'
export const C249 = '#B0B0B0'
export const C250 = '#BB9090'
export const C251 = '#638088'
export const C252 = '#545F83'
export const C253 = '#89D99C'
export const C254 = '#74CAAB'
export const C255 = '#164772'
export const C256 = '#AEF359'
export const C257 = '#307777'
export const C258 = '#164772'
export const C259 = '#FCFCFC1A'
export const C260 = '#E20613'
export const C261 = '#3B8B6E'
export const C262 = '#8BC53F'
export const C263 = '#A6A9AB'
export const C264 = '#164772'
export const C265 = '#74CAAB'
export const C266 = '#AEF359'
export const C267 = '#1D5959'
export const C268 = '#89D99C'
export const C269 = '#164772'
export const C270 = '#307777'
export const C271 = '#C4679E'
export const C272 = '#00ADEE'
export const C273 = '#C2C73B'
export const C274 = '#D3D3D3'
export const C275 = '#214f60'
export const C276 = '#E20613'
export const C277 = '#214f60'
export const C278 = '#648AC999'
export const C279 = '#C02EF2'
export const C280 = '#A78233'
export const C281 = '#7D4848'
export const C282 = '#638088'
export const C283 = '#262261'
export const C284 = '#307777'
export const C285 = '#FCFCFC33'
export const C286 = '#1D5959'
export const C287 = 'linear-gradient(to right,#808080,#FCFCFC)'
export const C288 = '#AEF359'
export const C289 = '#89D99C'
export const C290 = '#E20613'
export const C291 = '#FCFCFC'
export const C292 = '#AEF359'
export const C293 = '#164772'
export const C294 = '#AEF359'
export const C295 = '#74CAAB'
export const C296 = '#EEC732'
export const C297 = '#FCFCFC80'
export const C298 = '#E20613'
export const C299 = '#E20613'
export const C300 = '#FCFCFC99'
export const C301 = '#3BA57F'
export const C302 = '#164772'
export const C303 = '#1D5959'
export const C304 = '#3B8B6E'
export const C305 = '#FCFCFC33'
export const C306 = '#87878799'
export const C307 = '#E20613'
export const C308 = '#DCA3CE'
export const C309 = '#E20613'
export const C310 = '#E20613'
export const C311 = '#89D99C'
export const C312 = '#6F8E45'
export const C313 = '#1D5959'
export const C314 = '#7CE1BD'
export const C315 = '#76D4B2'
export const C316 = '#71CAAA'
export const C317 = '#6AC4A4'
export const C318 = '#63B89A'
export const C319 = '#52B18F'
export const C320 = '#469F80'
export const C321 = '#37896C'
export const C322 = '#307B61'
export const C323 = '#2A6A53'
export const C324 = '#255B48'
export const C325 = '#1F4D3C'
export const C326 = '#03414D'
export const C327 = '#0C4D5A'
export const C328 = '#135C6A'
export const C329 = '#196877'
export const C330 = '#217484'
export const C331 = '#277F90'
export const C332 = '#2F8C9E'
export const C333 = '#3197AA'
export const C334 = '#277F90'
export const C335 = '#2F8C9E'
export const C336 = '#469F80'
export const C337 = '#37896C'
export const C338 = '#307B61'
export const C339 = '#2A6A53'
export const C340 = '#255B48'
export const C341 = '#093C46'
export const C342 = '#03414D'
export const C343 = '#0C4D5A'
export const C344 = '#135C6A'
export const C345 = '#196877'
export const C346 = '#217484'
export const C347 = '#277F90'
export const C348 = '#76D4B2'
export const C349 = '#71CAAA'
export const C350 = '#6AC4A4'
export const C351 = '#63B89A'
export const C352 = '#52B18F'
export const C353 = '#469F80'
export const C354 = '#37896C'
export const C355 = '#307B61'
export const C356 = '#2A6A53'
export const C357 = '#255B48'
export const C358 = '#093C46'
export const C359 = '#03414D'
export const C360 = '#0C4D5A'
export const C361 = '#135C6A'
export const C362 = '#196877'
export const C363 = '#217484'
export const C364 = '#277F90'
export const C365 = '#71CAAA'
export const C366 = '#6AC4A4'
export const C367 = '#63B89A'
export const C368 = '#52B18F'
export const C369 = '#469F80'
export const C370 = '#37896C'
export const C371 = '#307B61'
export const C372 = '#2A6A53'
export const C373 = '#255B48'
export const C374 = '#093C46'
export const C375 = '#03414D'
export const C376 = '#0C4D5A'
export const C377 = '#135C6A'
export const C378 = '#196877'
export const C379 = '#217484'
export const C380 = '#277F90'
export const C381 = '#6AC4A4'
export const C382 = '#63B89A'
export const C383 = '#52B18F'
export const C384 = '#469F80'
export const C385 = '#37896C'
export const C386 = '#307B61'
export const C387 = '#2A6A53'
export const C388 = '#255B48'
export const C389 = '#093C46'
export const C390 = '#03414D'
export const C391 = '#0C4D5A'
export const C392 = '#135C6A'
export const C393 = '#196877'
export const C394 = '#217484'
export const C395 = '#277F90'
export const C396 = '#6AC4A4'
export const C397 = '#52B18F'
export const C398 = '#469F80'
export const C399 = '#37896C'
export const C400 = '#307B61'
export const C401 = '#2A6A53'
export const C402 = '#255B48'
export const C403 = '#093C46'
export const C404 = '#03414D'
export const C405 = '#0C4D5A'
export const C406 = '#135C6A'
export const C407 = '#196877'
export const C408 = '#217484'
export const C409 = '#277F90'
export const C410 = '#6AC4A4'
export const C411 = '#52B18F'
export const C412 = '#469F80'
export const C413 = '#37896C'
export const C414 = '#307B61'
export const C415 = '#2A6A53'
export const C416 = '#255B48'
export const C417 = '#03414D'
export const C418 = '#0C4D5A'
export const C419 = '#135C6A'
export const C420 = '#196877'
export const C421 = '#217484'
export const C422 = '#277F90'
export const C423 = '#6AC4A4'
export const C424 = '#52B18F'
export const C425 = '#469F80'
export const C426 = '#37896C'
export const C427 = '#307B61'
export const C428 = '#255B48'
export const C429 = '#03414D'
export const C430 = '#0C4D5A'
export const C431 = '#135C6A'
export const C432 = '#196877'
export const C433 = '#217484'
export const C434 = '#277F90'
export const C435 = '#6AC4A4'
export const C436 = '#52B18F'
export const C437 = '#469F80'
export const C438 = '#37896C'
export const C439 = '#307B61'
export const C440 = '#03414D'
export const C441 = '#0C4D5A'
export const C442 = '#135C6A'
export const C443 = '#196877'
export const C444 = '#217484'
export const C445 = '#277F90'
export const C446 = '#6AC4A4'
export const C447 = '#52B18F'
export const C448 = '#469F80'
export const C449 = '#37896C'
export const C450 = '#307B61'
export const C451 = '#0C4D5A'
export const C452 = '#135C6A'
export const C453 = '#196877'
export const C454 = '#217484'
export const C455 = '#277F90'
export const C456 = '#6AC4A4'
export const C457 = '#52B18F'
export const C458 = '#469F80'
export const C459 = '#37896C'
export const C460 = '#0C4D5A'
export const C461 = '#135C6A'
export const C462 = '#196877'
export const C463 = '#217484'
export const C464 = '#277F90'
export const C465 = '#6AC4A4'
export const C466 = '#52B18F'
export const C467 = '#469F80'
export const C468 = '#37896C'
export const C469 = '#135C6A'
export const C470 = '#196877'
export const C471 = '#217484'
export const C472 = '#277F90'
export const C473 = '#0d344f'
export const C474 = '#12405e'
export const C475 = '#174D70'
export const C476 = '#1B567C'
export const C477 = '#2E7489'
export const C478 = '#1B5F73'
export const C479 = '#164E5E'
export const C480 = '#093D4D'
export const C481 = '#FCFCFC4D'
export const C482 = '#FCFCFC99'
export const C483 = '#231F20'
export const C484 = '#AEF359'
export const C485 = '#164772'
export const C486 = '#3B8B6E'
export const C487 = '#3B8B6E'
export const C488 = '#164772'
export const C489 = '#9D0606'
export const C490 = '#89D99C'
export const C491 = 'linear-gradient(to bottom,#89D99C,#164772)'
export const C492 = '#E20613'
export const C493 = '#E20613'
export const C494 = '#E20613'
export const C495 = '#E20613'
export const C496 = '#E20613'
export const C497 = '#E20613'
export const C498 = '#E20613'
export const C499 = '#009EE2'
export const C500 = '#5D2684'
export const C501 = '#46675B'
export const C502 = 'linear-gradient(to right,#74CAAB,#AEF359)'
export const C503 = 'linear-gradient(to right,#164772,#3B8B6E)'
export const C504 = '#307777'
export const C505 = '#3B8B6E'
export const C506 = '#CACBCF'
export const C507 = '#707070'
export const C508 = '#307777'
export const C509 = '#1D5959'
export const C510 = '#74CAAB'
export const C511 = '#FCFCFC4D'
export const C512 = '#BAB6B6'
export const C513 = '#BBBDBF'
export const C514 = '#E20613'
export const C515 = '#7448BC'
export const C516 = '#FCFCFCCC'
export const C517 = '#AEF359'
export const C518 = '#818181'
export const C519 = '#1d5959'
export const C520 = '#FCFCFCB3'
export const C521 = '#89D99C'
export const C522 = '#164772'
export const C523 = '#AEF359'
export const C524 = '#6F8E45'
export const C525 = '#307777'
export const C526 = '#1D5959'
export const C527 = '#1D5959'
export const C528 = '#E20613'
export const C529 = '#3B8B6E'
export const C530 = '#89D99C'
export const C531 = '#AEF359'
export const C532 = '#307777'
export const C533 = '#164772'
export const C534 = '#164772'
export const C535 = '#75b9a0'
export const C536 = '#74CAAB80'
export const C537 = '#52967e'
export const C538 = '#E20613'
export const C539 = '#E20613'
export const C540 = '#FCFCFC'
export const C541 = '#FCFCFC'
export const C542 = '#74CAAB33'
export const C543 = '#164772'
export const C544 = '#3B8B6E'
export const C545 = '#164772'
export const C546 = '#307777'
export const C547 = '#74CAAB'
export const C548 = '#164772'
export const C549 = '#89D99C'
export const C550 = '#64843B'
export const C551 = '#6FAA21'
export const C552 = '#D8BD1C'
export const C553 = '#231F20'
export const C554 = '#FCFCFC'
export const C555 = '#6F8E4545'
export const C556 = '#74CAAB'
export const C557 = '#FFDD154D'
export const C558 = '#00ADEE4D'
export const C559 = '#37B34A4D'
export const C560 = '#1D5959'
export const C561 = '#3B8B6E'
export const C562 = '#26624C'
export const C563 = '#FCFCFC1A'
export const C564 = '#74CAAB'
export const C565 = '#FCFCFC'
export const C566 = '#69B30F'

export const C567 = 'linear-gradient(to right,#1AA7DB,#067EAA)'
// export const C567 =	'#E98020'
export const C568 =	'#164772'
export const C569 =	'#89D99C'
export const C570 =	'#FCFCFC'
export const C571 =	'#E98020'
export const C572 =	'#89D99C'
export const C573 =	'#FCFCFC'
export const C574 = '#1D5959'
export const C575 =	'#FCFCFC'
export const C576 =	'#FCFCFC'
export const C577 =	'#FCFCFC'
export const C578 =	'#6F8E45FF'
export const C579 =	'#96be87'
export const C580 = '#FCFCFC'
export const C581 = '#FCFCFC'
export const C582 = '#a0f8d3'
export const C583 = '#C3C3C3'
export const C584 = '#DACA85'
export const C585 = '#C4C9CE'
export const C586 = '#E4E5EC'
export const C587 = '#9ee5e5'
export const C588 = '#0B2B40'
export const C589 = '#C1BED0'
export const C590 = '#64B4B4'
export const C591 = '#C4C9CE'
export const C592 = '#64B4B4'
export const C593 = '#FCFCFC'
export const C594 = '#055959'
export const C595 = '#8CC8D4'
export const C596 = '#FCFCFC'
export const C597 = '#46CEAD'
export const C598 = '#0B2B40'
export const C599 = '#FCFCFC'
export const C600 = '#C1BED0'
export const C601 = '#18434a'
export const C602 = '#37b34a'
export const C603 = '#477777'
export const C604 = '#ff3944'
export const C605 = '#00000080'
export const C606 = '#89D99C'
export const C607 = '#00000099'
export const C608 = '#055959'
export const C609 = '#217676'
export const C610 = '#0B2B40'
export const C611 = '#FCFCFC'
export const C612 = '#21252B'
export const C613 = '#9999A2FF'
export const C614 = '#FCFCFC'

export const MC1 = '#656985B5'
export const MC2 = '#2A2D3E'
export const MC3 = '#231F2040'
