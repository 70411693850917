import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import serviceCall from '../../services'
import {languagesEnums} from "../../enums/languageEnums/languageEnums";
import {ENGLISH, NATIVE} from "../../enums/roboTeacher/dataStatusEnums";
import userAxiosInstance from "../../apis/userAxiosInstance";

const initialState = {
  loading: false,
  fetchLearningTypeTestLoading: false,
  currentData: null,
  data: null,
  result: null,
  error: '',
}

export const fetchLearningTypeTest = createAsyncThunk(
  'learningTypeTest/fetchLearningTypeTest',
  async (arg, {getState}) => {
    const {translation} = getState()
    const response = await serviceCall('fetchLearningTypeTest', {}, 'cloud')

    return {
      response,
      selectedTranslationLanguage: translation.selectedTranslationLanguage
    }
  },
)

export const submitLearningTypeTest = createAsyncThunk(
  'learningTypeTest/submitLearningTypeTest',
  ({testNumber, userAnswers}) => {
    return userAxiosInstance.post('/v1/evaluation-tests/learning-type-test', {
      'testNumber': testNumber,
      'userAnswers': userAnswers,
      'testType': 'LEARNING_TYPE',
    }).then(response => {
      return {
        data: response.data,
        status: response.status,
      }
    })
  },
)

export const updateLearningTypeAction = createAsyncThunk(
  'memoryTest/updateLearningTypeAction',
  ({ learningType }) => {
    return serviceCall(
      'updateLearningTypeActionService',
      { learningType },
      'cloud'
    )
  },
)

const learningTypeTestSlice = createSlice({
  name: 'learningTypeTest',
  initialState,
  reducers: {
    selectAnswer: (state, action) => {
      state.currentData.questions = [...state.currentData.questions].map((question, questionIndex) => {
        if (questionIndex === action.payload.questionIndex) {
          return {
            ...question,
            answers: [...question.answers].map((answer, answerIndex) => {
              if (answerIndex === action.payload.answerIndex) {
                return {
                  ...answer,
                  selected: !answer.selected,
                }
              }
              return {
                ...answer,
                selected: false,
              }
            }),
          }
        }
        return question
      })
      state.currentData.checkable = state.currentData.questions.every(qo => qo.answers.some(ao => ao.selected))
    },
    setLearningTypeTestLanguage: (state, action) => {
      if (action.payload === ENGLISH)
        state.currentData = state.data?.english
      else if (action.payload === NATIVE)
        state.currentData = state.data?.native
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLearningTypeTest.pending, (state) => {
      state.fetchLearningTypeTestLoading = true
    })
    builder.addCase(fetchLearningTypeTest.fulfilled, (state, action) => {
      state.fetchLearningTypeTestLoading = false
      state.data = action.payload?.response
      if (action.payload.selectedTranslationLanguage?.key === languagesEnums.ENGLISH_LANGUAGE) {
        state.currentData = action.payload?.response?.english
      } else {
        state.currentData = action.payload?.response?.native
      }
    })
    builder.addCase(fetchLearningTypeTest.rejected, (state, action) => {
      state.fetchLearningTypeTestLoading = false
      state.error = action.error.message
    })
    builder.addCase(submitLearningTypeTest.pending, (state) => {
      state.loading = true
    })
    builder.addCase(submitLearningTypeTest.fulfilled, (state, action) => {
      state.loading = false
      state.result = action.payload.data
    })
    builder.addCase(submitLearningTypeTest.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  },
})

export const {selectAnswer, setLearningTypeTestLanguage} = learningTypeTestSlice.actions

export default learningTypeTestSlice.reducer