import serviceCall from "../../services";
import aiPackagesMapper from "../../services/mapper/payment/aiPackagesMapper";

const getAiPackageUseCase = async () => {
  const result = await serviceCall(
    'fetchAiPackages',
    null,
    'cloud',
  )

  return aiPackagesMapper(result)
}

export default getAiPackageUseCase