import * as React from "react"

const ChatBoxCornerSvg = ({color, borderColor}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14.41 15.66"
    >
        <path
            fill={color}
            stroke={borderColor}
            strokeLinejoin="round"
            strokeWidth="1.5px"
            d="M13.41,1h0C10.25,1,4.46,4,1,14.66a17,17,0,0,1,12.35-3.55"
        />
    </svg>
)

export default ChatBoxCornerSvg
