import serviceCall from "../../services";
import flashCardsMapper from "../../services/mapper/memoryBox/flashCardsMapper";
import {ASC} from "../../enums/globalEnums/globalEnums";

const getAllFlashCardUseCase = async ({categoryId, searchTerm, sortBy, sortKey}) => {
	const result = await serviceCall(
		'fetchAllMemoryBoxFlashCards',
		{
			categoryId: categoryId,
			frontText: searchTerm,
			sortAsc: sortBy === ASC,
			sortBy: sortKey
		},
		'cloud',
	)

	return flashCardsMapper(result)
}

export default getAllFlashCardUseCase