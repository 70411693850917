import AllTemplateSvg from "../../icons/AllTemplateSvg"
import SingleTemplateSvg from "../../icons/SingleTemplateSvg"
import styles from "./TemplateViewTypeButton.module.scss"

const TemplateViewTypeButton = ({color, isSingle, onClick}) => {

  return (
    <button onClick={onClick} className={styles.templateViewTypeButton}>
      {isSingle
        ? <SingleTemplateSvg color={color}/>
        : <AllTemplateSvg color={color}/>
      }
    </button>
  )
}

export default TemplateViewTypeButton