import serviceCall from "../../services";
import fetchDrillMapper from "../../services/mapper/sections/fetchDrillMapper";

const fetchDrillUseCase = async ({ unit, nativeLanguage }) => {
  const result = await serviceCall(
    'fetchSection',
    {
      unit: unit,
      section: 'DRILLING',
    },
    'cloud',
  )

  return {
    data: fetchDrillMapper(result?.data),
    unit,
    nativeLanguage
  }
}

export default fetchDrillUseCase