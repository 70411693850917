import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import removeFromBoxUseCase from "../../../useCase/memoryBoxUseCase/removeFromBoxUseCase";
import removeAllUnitFromBoxUseCase from "../../../useCase/memoryBoxUseCase/removeAllUnitFromBoxUseCase";
import addToSuperMemoryBoxUseCase from "../../../useCase/memoryBoxUseCase/addToSuperMemoryBoxUseCase";
import removeFromSuperMemoryBoxUseCase from "../../../useCase/memoryBoxUseCase/removeFromSuperMemoryBoxUseCase";
import addFlashCardInLessonUseCase from "../../../useCase/memoryBoxUseCase/addFlashCardInLessonUseCase";
import {baseAsyncThunk} from "../../baseAsyncThunk";

const initialState = {
  loading: false,
  isRemove: false,
  isChange: false,
  error: '',
}

export const removeFromBox = createAsyncThunk(
    'memoryBoxActions/removeFromBox',
    ({ids}) => {
      return removeFromBoxUseCase({ids})
    },
)

export const addToSuperMemory = createAsyncThunk(
    'memoryBoxActions/addToSuperMemory',
    ({id}) => {
      return addToSuperMemoryBoxUseCase({id})
    },
)

export const removeFromSuperMemory = createAsyncThunk(
    'memoryBoxActions/removeFromSuperMemory',
    ({id}) => {
      return removeFromSuperMemoryBoxUseCase({id})
    },
)

export const addFlashCardInLesson = baseAsyncThunk(
    'memoryBoxActions/addFlashCardInLesson',
    ({ isVocab, text, unitId }) => {
      return addFlashCardInLessonUseCase({ isVocab, text, unitId })
    },
)

export const removeAllUnitFlashCard = createAsyncThunk(
    'memoryBoxActions/removeAllUnitFlashCard',
    ({unitIds}) => {
      return removeAllUnitFromBoxUseCase({unitIds})
    },
)

const memoryBoxActionsSlice = createSlice({
  name: 'memoryBoxActions',
  initialState,
  reducers: {
    setIsRemove: (state, action) => {
      state.isRemove = action.payload
    },
    setIsChange: (state, action) => {
      state.isChange = action.payload
    }
  },
  extraReducers: builder => {
    //remove flashcard
    builder.addCase(removeFromBox.pending, (state) => {
      state.loading = true
      state.isRemove = false
    })
    builder.addCase(removeFromBox.fulfilled, (state, action) => {
      state.loading = false
      state.isRemove = true
    })
    builder.addCase(removeFromBox.rejected, (state, action) => {
      state.loading = false
      state.isRemove = false
      state.error = action.error.message
    })

    //remove all unit flashcard
    builder.addCase(addFlashCardInLesson.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addFlashCardInLesson.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(addFlashCardInLesson.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    builder.addCase(removeAllUnitFlashCard.pending, (state) => {
      state.loading = true
      state.isRemove = false
    })
    builder.addCase(removeAllUnitFlashCard.fulfilled, (state, action) => {
      state.loading = false
      state.isRemove = true
    })
    builder.addCase(removeAllUnitFlashCard.rejected, (state, action) => {
      state.loading = false
      state.isRemove = false
      state.error = action.error.message
    })

    //add to super memory box
    builder.addCase(addToSuperMemory.pending, (state) => {
      state.loading = true
      state.isChange = false
    })
    builder.addCase(addToSuperMemory.fulfilled, (state, action) => {
      state.loading = false
      state.isChange = true
    })
    builder.addCase(addToSuperMemory.rejected, (state, action) => {
      state.loading = false
      state.isChange = false
      state.error = action.error.message
    })

    //remove from super memory box
    builder.addCase(removeFromSuperMemory.pending, (state) => {
      state.loading = true
      state.isChange = false
    })
    builder.addCase(removeFromSuperMemory.fulfilled, (state, action) => {
      state.loading = false
      state.isChange = true
    })
    builder.addCase(removeFromSuperMemory.rejected, (state, action) => {
      state.loading = false
      state.isChange = false
      state.error = action.error.message
    })

  },
})

export const {
  setIsRemove,
  setIsChange
} = memoryBoxActionsSlice.actions

export default memoryBoxActionsSlice.reducer