export const ContentTypesStructureEnums = {
  TITLE: 'TITLE',
  TEXT: 'TEXT',
  TEXT_AREA: 'TEXT_AREA',
  TEXT_TITLE: 'TEXT_TITLE',
  DESC: 'DESC',
  GENERAL: 'GENERAL',
  BUBBLE_IMAGE: 'BUBBLE_IMAGE',
  TABLE: 'TABLE',
  BUTTON: 'BUTTON',
  COMPARE_SEPARATE: 'COMPARE_SEPARATE',
  COMPARE_MONOLITHIC: 'COMPARE_MONOLITHIC',
  IMAGES: 'IMAGES',
  SINGLE_IMAGE: 'SINGLE_IMAGE',
  COLLAPSE: 'COLLAPSE',
  DIVIDER: 'DIVIDER',
  IMAGE: 'IMAGE',
  SUBJECT: 'SUBJECT',
};
