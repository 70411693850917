import React, { useContext, useEffect, useState } from 'react'
import colorsContext from '../../../contexts/ColorsContext'
import { useDispatch, useSelector } from 'react-redux'
import exerciseStyles from '../ExerciseModules.module.scss'
import DraggableItemsCard from '../../../components/DraggableItemsCard/DraggableItemsCard'
import Lock from '../../../components/Lock/Lock'
import {
  checkExerciseItem,
  onClickAnswerBoxDAD, onClickContinueDAD,
  selectChoiceDND, setExerciseItemUpdated,
  toggleLockDADC,
} from '../../../redux/features/exerciseSlice'
import reactStringReplace from 'react-string-replace'
import classNames from 'classnames'
import DragDropInlineAnswerBox from '../../../components/DragDropInlineAnswerBox/DragDropInlineAnswerBox'
import Image from '../../../components/Image'
import removeWrapperTag from '../../../operations/removeWrapperTag'
import numberIsOdd from '../../../operations/numberIsOdd'
import htmlToText from '../../../operations/htmlToText'
import ExerciseFullTexts from '../Components/ExerciseFullTexts'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import Instruction from '../../../components/Instruction/Instruction'
import { ContentTypesStructureEnums } from '../../../enums/structureEnums/templateType'
import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const DragAndDropModule = ({ data }) => {
  const [draggingField, setDraggingField] = useState(null)
  const [draggingItem, setDraggingItem] = useState(null)

  const { checkingItem, exerciseItemUpdated, finished } = useSelector((state) => state.exercise)
  const { colors } = useContext(colorsContext)
  const dispatch = useDispatch()

  useEffect(() => {
    if (exerciseItemUpdated) {
      if (data.immediateCheck && data.stack.items[data.currentItemIndex].checkable) {
        checkItem()
      }
      dispatch(setExerciseItemUpdated(false))
    }
  }, [exerciseItemUpdated])

  const checkItem = () => {
    const currentExerciseItem = data.stack.items[data.currentItemIndex]

    const userAnswerData = {
      exerciseStackId: data.stack.id,
      userAnswerItems: [{
        exerciseItemId: currentExerciseItem.id,
        indexImportant: currentExerciseItem.type === ContentTypesStructureEnums.TEXT || currentExerciseItem.hasBubble,
        userAnswers: [...currentExerciseItem.answerFields].map(field => {
          if (currentExerciseItem.type === ContentTypesStructureEnums.TEXT) {
            console.log(field)
            return {
              listIndex: 0,
              index: field.index,
              text: field.isSolvedBefore ? field.correctAnswer?.text.sentence : field.userAnswer?.text.sentence,
            }
          } else {
            return {
              listIndex: 0,
              index: field.index,
              trackerNumber: field.isSolvedBefore ? field.correctAnswer?.text.trackerNumber : field.userAnswer?.text.trackerNumber,
            }
          }
        }),
      }],
    }
    dispatch(checkExerciseItem({ userAnswerData })).then((res) => {
      if (res.payload?.status === 200 && !data.paginable) {
        dispatch(onClickContinueDAD({ isConversation: false }))
      }
    })
  }

  const getSelectedAnswerBox = () => {
    for (let i = 0; i < data.stack.items.length; i++) {
      for (let j = 0; j < data.stack.items[i].answerFields.length; j++) {
        const field = data.stack.items[i].answerFields[j]
        if (field.selected) {
          return field
        }
      }
    }
    return null
  }

  const onClickOption = (choice) => {
    const selectedAnswerBox = getSelectedAnswerBox()
    if (!checkingItem && !data.checked && selectedAnswerBox) {
      dispatch(selectChoiceDND({ choice, field: selectedAnswerBox }))
    }
  }

  const onDropOptionToDefaultPlace = (e, choice) => {
    if (e.stopPropagation) e.stopPropagation()

    if (draggingField) {
      dispatch(selectChoiceDND({ choice, field: draggingField }))
    }

    setDraggingField(null)
  }

  const onDropOptionToAnswerBox = (field) => {
    if (draggingItem && field.userAnswer !== draggingItem) {
      dispatch(selectChoiceDND({ choice: draggingItem, field }))
    }

    setDraggingItem(null)
  }

  const renderGivenText = (exerciseItem) => {
    if (exerciseItem.checked && (exerciseItem.userAnswerStatus === AnswerStatusEnums.CORRECT || exerciseItem.unlocked || exerciseItem.isSolvedBefore)) {
      const userAnswerStatusList = exerciseItem.answerFields.map(field => {
        return field.userAnswerStatus
      })
      return <ExerciseFullTexts
        unlocked={exerciseItem.unlocked}
        fullTexts={exerciseItem.fullTextAnswers}
        userAnswerStatus={userAnswerStatusList}
        template='DROPBOX'
        textColor={colors['C23']}
      />
    }

    const renderGivenTextParagraph = (paragraph, pIndex) => {
      if (paragraph.containsField) {
        const cleanParagraph = removeWrapperTag(paragraph.string, 'p')
        const arr = reactStringReplace(cleanParagraph, '###', (match, index) => {

          const boxIndex = paragraph.fieldIndexStartsFrom + ((index - 1) / 2)
          const fieldData = exerciseItem.answerFields[boxIndex]

          if (fieldData.isSolvedBefore) {
            return <HtmlTextWrapper
              style={{ display: 'inline-block' }}
              data={fieldData.correctAnswer.text}
              hideMore
            />
          } else {
            return <DragDropInlineAnswerBox
              key={boxIndex}
              pending={checkingItem}
              checked={exerciseItem.checked}
              unlocked={exerciseItem.unlocked}
              userAnswerStatus={fieldData.userAnswerStatus}
              userAnswer={fieldData.userAnswer?.text}
              correctAnswer={fieldData.correctAnswer?.text}
              selected={fieldData.selected}
              onDragStart={() => {
                setDraggingField(fieldData)
                setDraggingItem(fieldData.userAnswer)
              }}
              onClick={() => {
                dispatch(onClickAnswerBoxDAD({ itemId: exerciseItem.id, fieldIndex: fieldData.index }))
              }}
              onDrop={() => onDropOptionToAnswerBox(fieldData)}
              bgColor={colors['C525']}
              style={{ marginBottom: '4px', marginTop: '4px' }}
            />
          }
        })
        /*
        * Example of arr:
        * ['<a href="text">Hello</a>', myCustomComponent, '.']
        * myCustomComponent is located in odd indexes (1, 3...)
        * */
        return arr.map((el, i) => (
          <React.Fragment key={i}>
            {numberIsOdd(i) ? el :
              <HtmlTextWrapper
                textColor={colors['C23']}
                optionsColor={colors['C23']}
                data={{
                  ...(exerciseItem.fullTextAnswers.length > pIndex ? exerciseItem.fullTextAnswers[pIndex] : {}),
                  sentence: el,
                }}
                phraseOptionsDisabled
                showPhraseOptions={exerciseItem.checked && i === 0}
                style={{ display: 'inline' }}
              />
            }
          </React.Fragment>
        ))
      } else {
        return (
          <p style={{ color: colors['C23'] }}>
            {htmlToText(paragraph.string)}
          </p>
        )
      }
    }

    const givenText = exerciseItem.given.text
    if (givenText) {
      return givenText.paragraphList.map((paragraph, pIndex) => {
        return (
          <div className='exercise-paragraph' style={{ ...paragraph.paragraphStyle }} key={pIndex}>
            {renderGivenTextParagraph(paragraph, pIndex)}
          </div>
        )
      })
    }
  }

  const renderAnswerFields = (exerciseItem) => {
    return exerciseItem.answerFields.map((field, fieldIndex) => {
      return (
        <div
          className={classNames(
            exerciseStyles.answerBoxWrapper,
            {
              ['justify-content-center']: !exerciseItem.checked,
              [exerciseStyles.oneColumn]: field.width === '100%',
              [exerciseStyles.twoColumn]: field.width === '50%',
            },
          )}
          style={{ width: field.width }}
          key={fieldIndex}
        >
          {field.isSolvedBefore ?
            <DragDropInlineAnswerBox
              pending={!exerciseItem.checked}
              hasBubble={exerciseItem.hasBubble}
              bubbleNumber={fieldIndex + 1}
              checked={exerciseItem.checked}
              userAnswerStatus={AnswerStatusEnums.NONE}
              userAnswer={field.correctAnswer?.text ?? null}
              correctAnswer={field.correctAnswer?.text ?? null}
              bgColor={colors['C525']}
              phraseOptions={field.correctAnswer?.text ?? null}
            /> :
            <DragDropInlineAnswerBox
              pending={checkingItem}
              hasBubble={exerciseItem.hasBubble}
              bubbleNumber={fieldIndex + 1}
              checked={exerciseItem.checked}
              unlocked={exerciseItem.unlocked}
              userAnswerStatus={field.userAnswerStatus}
              userAnswer={field.userAnswer ? field.userAnswer.text : null}
              correctAnswer={field.correctAnswer ? field.correctAnswer.text : null}
              selected={field.selected}
              onDragStart={() => {
                setDraggingField(field)
                setDraggingItem(field.userAnswer)
              }}
              onClick={() => {
                dispatch(onClickAnswerBoxDAD({
                  itemId: exerciseItem.id,
                  fieldIndex: field.index,
                }))
              }}
              onDrop={() => onDropOptionToAnswerBox(field)}
              bgColor={colors['C525']}
              phraseOptions={(exerciseItem.checked && field.correctAnswer) ? field.correctAnswer.text : null}
            />
          }

        </div>
      )
    })
  }

  const renderLock = (exerciseItem, itemIndex) => {
    if (exerciseItem.checked && exerciseItem.showLock) {
      return (
        <div className={exerciseStyles.exerciseCardFooter}>
          <Lock
            isLocked={!exerciseItem.unlocked}
            onClickLock={() => dispatch(toggleLockDADC({ itemIndex }))}
          />
        </div>
      )
    }
  }

  const renderExerciseItem = (exerciseItem, i) => {
    return (
      <div>
        <div className={exerciseStyles.dragDropCardContainer}>
          {exerciseItem.given.imageUri &&
            <Image
              onClickZoomIn
              className={classNames(exerciseStyles.dragDropImage, { [exerciseStyles.dragDropBubble]: exerciseItem.hasBubble })}
              uri={exerciseItem.given.imageUri}
            />
          }
          {exerciseItem.type === ContentTypesStructureEnums.TEXT ?
            <div className={exerciseStyles.dragDropCard} style={{ background: colors['C526'], color: colors['C23'] }}>
              <div className='d-flex'>
                <div
                  className={exerciseStyles.cardNumberBadge}
                  style={{
                    background: colors['C144'], color: colors['C233'],
                    borderColor: (!exerciseItem.isSolvedBefore && exerciseItem.checked)
                      ? (exerciseItem.userAnswerStatus === AnswerStatusEnums.CORRECT ? colors['C30'] : colors['C29'])
                      : null,
                  }}
                >
                  {i + 1}
                </div>
                <div style={{ width: '100%' }}>
                  {renderGivenText(exerciseItem)}
                  {renderLock(exerciseItem, i)}
                </div>
              </div>
            </div> :
            <div className={classNames(exerciseStyles.dragDropAnswerBoxContainer, 'justify-content-between')}>
              {renderAnswerFields(exerciseItem)}
            </div>
          }
        </div>
        {exerciseItem.type === ContentTypesStructureEnums.IMAGE && renderLock(exerciseItem, i)}
      </div>
    )
  }

  const renderExercise = () => {
    if (finished) {
      return data.stack.items.map((exerciseItem, i) => {
        return (
          <div
            className={classNames(
              exerciseStyles.exerciseCard,
              {
                [exerciseStyles.typeImage]: exerciseItem.type === ContentTypesStructureEnums.IMAGE,
                [exerciseStyles.typeBubble]: exerciseItem.hasBubble,
              },
            )}
            style={{ background: exerciseItem.type === ContentTypesStructureEnums.IMAGE ? colors['C62'] : null }}
            key={i}
          >
            {renderExerciseItem(exerciseItem, i)}
          </div>
        )
      })
    } else {
      const exerciseItem = data.stack.items[data.currentItemIndex]
      return (
        <div
          className={classNames(
            exerciseStyles.exerciseCard,
            { [exerciseStyles.typeImage]: exerciseItem.type === ContentTypesStructureEnums.IMAGE },
          )}
          style={{ background: exerciseItem.type === ContentTypesStructureEnums.IMAGE ? colors['C62'] : null }}
        >
          {renderExerciseItem(exerciseItem, data.currentItemIndex)}
        </div>
      )
    }
  }

  return (
    <>
      {data.stack.title && <Instruction data={data.stack.title} />}
      <div className={exerciseStyles.dragDropContainer}>
        <div className='container p-0'>
          {!finished &&
            <DraggableItemsCard
              backgroundColor={colors['C568']}
              optionsBackgroundColor={colors['C569']}
              optionsTextColor={colors['C570']}
              itemsData={data.stack.choices}
              hideCorrectlyUsedChoices
              onClickOption={onClickOption}
              onDragStart={item => setDraggingItem(item)}
              onDrop={onDropOptionToDefaultPlace}
              isNavMenu
            />
          }
          <div
            className={classNames(
              exerciseStyles.dragDropSimpleContainer,
              { [exerciseStyles.finished]: finished }
            )}
          >
            {renderExercise()}
          </div>
        </div>
      </div>
    </>
  )
}

export default DragAndDropModule
