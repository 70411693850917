import getDirectionFromLanguage from "../../../operations/getDirectionFromLanguage";
import {htmlTextConversion} from "../mapperConversions";
import {helpVideoLinks} from "../../../enums/pageGuideEnums/pageGuideEnums";
import {languagesEnums, getLanguageDataByTitle} from "../../../enums/languageEnums/languageEnums";

const helpMapper = (response, page) => {

  const dto = (data) => {
    const languageData = getLanguageDataByTitle(data?.nativeLanguage)
    return {
      id: data?.id,
			page: data?.page,
			nativeLanguage: data?.nativeLanguage,
      direction: getDirectionFromLanguage(data?.nativeLanguage),
      intro: {
        imageUri: data?.data?.imageUri,
        title: htmlTextConversion(data?.data?.title),
        description: htmlTextConversion(data?.data?.description),
        iconUri: data?.data?.iconUri,
      },
      helps: data?.data?.helps?.map((item, i) => {
        return {
          index: item.index,
          title: htmlTextConversion(item.title),
          description: htmlTextConversion(item.description),
          iconUri: item.iconUri
        }
      }),
      videoLink: helpVideoLinks[page]?.[languageData.key] ?? helpVideoLinks[page]?.[languagesEnums.ENGLISH_LANGUAGE]
    }
  }

  return {
    english: dto(response.englishLanguage),
    native: dto(response.nativeLanguage),
  }
}

export default helpMapper