import * as React from "react"

const SoundSettingSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={512}
        height={512}
        viewBox="0 0 512 512"
    >
        <path
            d="M8.6 324.9c-3.7 5.9-5 12.8-3.4 19.6L34.8 477c2.7 12.1 13.5 20.4 25.5 20.4 1.9 0 3.8-.2 5.7-.6l15.5-3.4c4.2 8.9 13.3 14.7 23.1 14.7 1.8 0 3.7-.2 5.6-.6l27-6c6.7-1.5 12.4-5.5 16.1-11.3 3.7-5.8 4.9-12.6 3.4-19.3l-38.5-172.2c-3.1-13.8-16.8-22.5-30.7-19.4l-8.2 1.8c-1.4-17.2-2.1-35.2-2.1-44.5 0-96.5 80.4-175 179.2-175 98.8 0 179.2 78.5 179.2 175 0 9.5-.7 27.3-2.2 44.6l-8.9-2c-13.8-3.1-27.6 5.7-30.7 19.4l-38.5 172.2c-1.5 6.7-.3 13.5 3.4 19.3 3.7 5.8 9.4 9.8 16.1 11.3l27 6c1.9.4 3.7.6 5.6.6 9.9 0 18.9-5.8 23.1-14.7l15.5 3.4c1.9.4 3.8.6 5.7.6 12 0 22.8-8.3 25.5-20.4l29.6-132.5c1.5-6.8.3-13.7-3.4-19.6-3.1-4.8-7.6-8.3-12.9-10.3 3.1-27.9 4.6-61.6 4.6-76.1C495.1 109.8 388 5 256.3 5S17.5 109.8 17.5 238.5c0 14.5 1.4 48 4.5 75.9-5.5 1.9-10.2 5.5-13.4 10.5zm81.6-33.4c1-.2 1.9-.3 2.9-.3 6 0 11.4 4.1 12.8 10.2l38.5 172.2c.8 3.4.1 6.9-1.7 9.8-1.9 3-4.8 5-8.2 5.8l-27 6c-7 1.6-14.1-2.9-15.7-9.9l-.1-.6-38.2-171-.1-.6c-.8-3.4-.1-6.9 1.7-9.8 1.9-3 4.8-5 8.2-5.8l26.9-6zm314.4 203.6-27-6c-3.4-.8-6.4-2.8-8.2-5.8-1.9-2.9-2.5-6.4-1.7-9.8l38.5-172.2c1.4-6.1 6.8-10.2 12.8-10.2.9 0 1.9.1 2.9.3l27.1 6c3.4.8 6.4 2.8 8.2 5.8 1.9 2.9 2.5 6.4 1.7 9.8l-.1.6-38.2 171-.1.5c-1.8 7.1-8.9 11.6-15.9 10zm88.2-163.4c1.9 3 2.6 6.6 1.8 10.1L465 474.3c-1.6 7.2-8.9 11.8-16.1 10.2l-14.8-3.3 35.5-158.8 14.8 3.3c3.5.8 6.5 2.9 8.4 6zM256.3 17.6c124.8 0 226.2 99.1 226.2 221 0 13.9-1.4 45.9-4.3 73l-6.7-1.5c0-4.8-1.3-9.4-3.9-13.6-3.7-5.8-9.4-9.8-16.1-11.3l-5.8-1.3c1.5-18.2 2.3-37.3 2.3-47.3 0-103.4-86-187.5-191.8-187.5-105.6-.1-191.6 84-191.6 187.5 0 10.1.8 29.1 2.3 47.2l-6.4 1.4c-6.7 1.5-12.4 5.5-16.1 11.3-2.6 4.1-3.9 8.8-3.9 13.6l-6.1 1.3c-2.8-26-4.3-58.4-4.3-72.9 0-121.8 101.5-220.9 226.2-220.9zM19.2 331.7c1.9-3 4.9-5.1 8.5-5.9l14.8-3.3L78 481.2l-14.8 3.3c-7.3 1.6-14.5-3-16.1-10.2L17.4 341.8c-.8-3.5-.1-7.1 1.8-10.1z"
            style={{
                stroke: color,
                strokeWidth: 9.8388,
                strokeMiterlimit: 10,
            }}
        />
    </svg>
)

export default SoundSettingSvg
