import htmlToText from "../../../operations/htmlToText";

const conversationMapper = (data) => {
  return {
    ...data,
    dialogData: [...data.dialogData].map(dialog => {
      return {
        ...dialog,
        sentenceString: htmlToText(dialog.sentence),
        speechType: null
      }
    })
  }
}

export default conversationMapper