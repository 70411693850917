import serviceCall from "../../services";
import unitSummeryMapper from "../../services/mapper/memoryBox/unitSummeryMapper";

const getUnitsSummeryUseCase = async ({ categoryId }) => {
	const response = await serviceCall(
		'fetchUnitSummeryInMemoryBox',
		{ categoryId },
		'cloud',
	)

	return response.map(item => unitSummeryMapper(item))
}

export default getUnitsSummeryUseCase