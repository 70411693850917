import {isMobile} from "react-device-detect";
import BottomSheet from "../../../components/BottomSheet/BottomSheet";
import componentStyle from './SelectSubjectModal.module.scss'
import {useContext, useState} from "react";
import colorsContext from "../../../contexts/ColorsContext";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import {useDispatch, useSelector} from "react-redux";
import {pushToDialogs, setSelectedSubject} from "../../../redux/features/aiChatSlice";
import Modal from "../../../components/Modal";
import {Spinner} from "react-bootstrap";

const SelectSubjectModal = ({closeModal}) => {
  const {colors} = useContext(colorsContext)
  const dispatch = useDispatch()
  const [active, setActive] = useState(null)
  const {subjects} = useSelector(state => state.aiChat)

  const onClickStart = (close) => {
    dispatch(setSelectedSubject(active))
    dispatch(pushToDialogs({
      content: active.greetingText,
      isRobot: true,
      isTyping: false
    }))
    close()
  }

  const renderModalContent = (props) => {
    return (
      <div>
        <h3 className='res:fs-lg text-center mb-3'>Subject:</h3>
        {subjects !== null ?
          <div className={componentStyle.subjectsList}>
            {subjects?.map((subject, i) => {
              return (
                <button
                  key={i}
                  className='res:fs-md'
                  onClick={() => setActive(subject)}
                  title={subject.description}
                  style={{
                    background: active?.id === subject.id ? '#EA95B4' : '#FCFCFC',
                    color: active?.id === subject.id ? colors['C160'] : colors['C233']
                  }}
                >
                  {subject.title}
                </button>
              )
            })}
          </div>
          : <div className='d-flex align-items-center justify-content-center my-2'><Spinner/></div>
        }
        <PrimaryButton
          disabled={!active}
          className='mx-auto'
          onClick={() => {
            if (active) {
              if (isMobile) {
                onClickStart(props.close)
              } else {
                onClickStart(closeModal)
              }
            }
          }}
        >
          Start
        </PrimaryButton>
      </div>
    )
  }

  if (isMobile) {
    return (
      <BottomSheet hasSpace>
        {(props) => renderModalContent(props)}
      </BottomSheet>
    )
  } else {
    return (
      <>
        <Modal isCenter closeModal={closeModal}>
          <div className='modal-type-2 p-4' style={{background: colors['C171'], borderRadius: 10}}>
            {renderModalContent()}
          </div>
        </Modal>
      </>
    )
  }
}

export default SelectSubjectModal