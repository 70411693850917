import structureBaseTitleMapper from "./StructureBaseTitleMapper";
import structureBaseImageMapper from "./StructureBaseImageMapper";

const structureBubbleImageTemplateMapper = ({item, events}) => {
  return {
    ...item,
    data: {
      ...item.data,
      picture: structureBaseImageMapper({image: item?.data?.picture, text: item?.data?.pictureText, events}),
      pictureText: structureBaseTitleMapper({ text: item?.data?.pictureText, events }),
      title: structureBaseTitleMapper({title: item?.data?.title, events}),
      bubbleDescriptions: item.data.bubbleDescriptions.map(description => {
        return {
          ...description,
          text: structureBaseTitleMapper({title: description?.text, events}),
        }
      }),
      texts: item.data.texts.map(text => {
        return {
          ...text,
          text: structureBaseTitleMapper({title: text?.text, events})
        }
      })
    },
    hiddenTime: parseInt(events?.view?.find(it => it?.templateId === item?.id)?.startTime) || 0
  }
}

export default structureBubbleImageTemplateMapper