import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const checkSayTheFollowingMapper = (stackId, response, data) => {
  return {
    ...data,
    stacks: [...data.stacks].map(stack => {
      if (stack.id === stackId) {
        return {
          ...stack,
          items: [...stack.items].map(item => {
            const responseItem = response.find(obj => obj.exerciseItemId === item.id)
            if (responseItem && responseItem.userAnswerItemResponse.length > 0) {
              const userAnswerData = responseItem.userAnswerItemResponse[0]
              return {
                ...item,
                checked: true,
                isCorrectWithMistake:
                  userAnswerData.userAnswerStatus === AnswerStatusEnums.CORRECT &&
                  userAnswerData.wordByWordResult?.some(it => it.actionToCorrect > 0 && it.usrStr !== ''),
                userAnswerData,
              }
            }
            return item
          }),
        }
      }
      return stack
    }),
  }
}

export default checkSayTheFollowingMapper