import * as React from "react"

const PictorialMarkLogoSvg = ({primaryColor, secondaryColor, oInnerColor}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 36.8 36.8"
    width={36.8}
    height={36.8}
    xmlSpace="preserve"
  >
    <g>
      <path fill={primaryColor} d="M-41.6,15.1"/>
      <path
        fill={primaryColor}
        d="M18.4,0.1C8.3,0.1,0,8.3,0,18.5s8.3,18.3,18.4,18.3s18.4-8.2,18.4-18.4C36.8,8.3,28.6,0.1,18.4,0.1z  M18.4,27.4c-4.9,0-9-4-9-9s4-9,9-9s9,4,9,9S23.4,27.4,18.4,27.4z"
      />
      <path
        fill={secondaryColor}
        d="M18.4,13.2c-2.9,0-5.2,2.3-5.2,5.2s2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2S21.3,13.2,18.4,13.2z M18.4,20.4c-1.1,0-2-0.9-2-2 s0.9-2,2-2s2,0.9,2,2C20.5,19.5,19.5,20.4,18.4,20.4z"/>
    </g>
    <circle fill={oInnerColor} cx={18.4} cy={18.4} r={2}/>
  </svg>
)

export default PictorialMarkLogoSvg
