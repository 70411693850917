const IphoneShareButtonSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
		<rect width={24} height={24} fill="#787880" fillOpacity={0.12} rx={3} />
		<path
			stroke="#007AFF"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="m15 8-3-3m0 0L9 8m3-3v9.75M14.625 11H16a2 2 0 0 1 2 2v5.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 6 18.5V13a2 2 0 0 1 2-2h1.375"
		/>
	</svg>
)
export default IphoneShareButtonSvg
