import serviceCall from "../../services";

const deleteCategoryUseCase = async (id) => {
	const result = serviceCall(
		'deleteMemoryBoxCategory',
		id,
		'cloud',
	)

	return result
}

export default deleteCategoryUseCase 