import * as React from "react"

const MessagesNotificationsSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style type="text/css">
            {
                ".MessagesNotificationsSvg-st0{stroke-width:1.9766;stroke-miterlimit:10;}"
            }
        </style>
        <g>
            <g>
                <path
                    className="MessagesNotificationsSvg-st0"
                    stroke={color}
                    d="M459.5,85.5h-407C25.2,85.5,3,107.8,3,135v242c0,27.3,22.2,49.5,49.5,49.5h407c27.3,0,49.5-22.2,49.5-49.5 V135C509,107.8,486.8,85.5,459.5,85.5z M458.2,118.5c-8.4,8.4-184.7,184.7-190.5,190.5c-6.4,6.4-16.9,6.4-23.3,0 c-5.8-5.8-182.1-182.1-190.5-190.5C53.8,118.5,458.2,118.5,458.2,118.5z M36,375.7V147.3l114.1,114.1L36,375.7z M64.9,393.5 l108.7-108.7l47.5,47.5c19.3,19.3,50.7,19.3,70,0l47.5-47.5l108.7,108.7H64.9z M476,375.7L361.8,261.5L476,147.3V375.7L476,375.7z "
                />
            </g>
        </g>
    </svg>
)

export default MessagesNotificationsSvg
