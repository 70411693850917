export const C1 = '#231F20CC'
export const C2 = '#FCFCFC'
export const C3 = '#DC73A2'
export const C4 = '#C45592'
export const C5 = '#AF439A'
export const C6 = '#923AA0'
export const C7 = '#5D2684'
export const C8 = '#421F7A'
export const C9 = '#2B0F70'
export const C10 = '#17065B'
export const C11 = 'linear-gradient(to right, #262261,#EA95B4)'
export const C12 = 'linear-gradient(to right, #EA95B4,#262261)'
export const C13 = '#FCFCFC80'
export const C14 = 'linear-gradient(to right, #D0D2D3,#FCFCFC)'
export const C15 = '#FCFCFC1A'
export const C16 = '#BFC932'
export const C17 = '#FFDD15'
export const C18 = '#FF8800'
export const C19 = '#FCFCFC'
export const C20 = '#FCFCFC66'
export const C21 = '#FCFCFC4D'
export const C22 = '#FCFCFCCC'
export const C23 = '#231F20'
export const C24 = '#6C66AF'
export const C25 = '#EA95B4'
export const C26 = '#231F204D'
export const C27 = '#D0D2D3'
export const C28 = '#A2A1A1'
export const C29 = '#E20613'
export const C30 = '#1b982f'
export const C31 = '#FF8800'
export const C32 = '#FCFCFC99'
export const C33 = '#FCFCFC33'
export const C34 = '#706F6F'
export const C35 = '#655A5A36'
export const C36 = '#93C01F'
export const C37 = '#7448BC'
export const C38 = '#70707061'
export const C39 = '#FCFCFC'
export const C40 = '#EA95B4'
export const C41 = '#421F7A'
export const C42 = '#262261'
export const C43 = '#FCFCFC'
export const C44 = '#FCFCFC'
export const C45 = '#FCFCFC33'
export const C46 = '#DBDEF1'
export const C47 = '#FFE6BB'
export const C48 = '#655A5A21'
export const C49 = '#CFCEEB'
export const C50 = '#EB008B'
export const C51 = '#7F3F97'
export const C52 = '#7F3F97B8'
export const C53 = '#7448BCB8'
export const C54 = '#E1E1E1'
export const C55 = '#FFE6BB'
export const C56 = 'linear-gradient(to right, #FF8800,#9510DB)'
export const C57 = 'linear-gradient(to right, #7C0EFF,#4F78FD,#09DEF8)'
export const C58 = '#09DEF8'
export const C59 = '#E1BD47'
export const C60 = '#8C5E8C'
export const C61 = 'linear-gradient(to bottom, #3D244B,#3D244B,#8C5E8C)'
export const C62 = '#FCFCFC66'
export const C63 = '#E1E1E1A8'
export const C64 = '#B1B1B1'
export const C65 = 'linear-gradient(to bottom, #EA95B4,#262261)'
export const C66 = '#F8B133'
export const C67 = 'linear-gradient(to bottom, #FCFCFC,#D0D2D3)'
export const C68 = '#00A099'
export const C69 = '#878787'
export const C70 = '#454094'
export const C71 = 'linear-gradient(to right, #37B34A,#E20613)'
export const C72 = 'linear-gradient(to right, #EA95B466,#26226166)'
export const C73 = '#262261'
export const C74 = '#FC6701'
export const C75 = '#F9A602'
export const C76 = '#DAA420'
export const C77 = '#FF7417'
export const C78 = '#EF820D'
export const C79 = '#B3672B'
export const C80 = '#BE5402'
export const C81 = '#FD6A02'
export const C82 = '#F9812A'
export const C83 = '#954000'
export const C84 = '#925C92'
export const C85 = '#823F0B'
export const C86 = '#CC7822'
export const C87 = '#F05E23'
export const C88 = '#CB5C0B'
export const C89 = '#883002'
export const C90 = '#8B4000'
export const C91 = '#FEBF00'
export const C92 = '#EF7216'
export const C93 = '#F79862'
export const C94 = '#F9AE08'
export const C95 = '#B445B2'
export const C96 = '#D6700B'
export const C97 = '#D86F26'
export const C98 = '#D35E0D'
export const C99 = '#F29100'
export const C100 = '#48CDF7'
export const C101 = '#282185'
export const C102 = 'linear-gradient(to right, #FD8A8F,#7F32A7)'
export const C103 = '#F47A86'
export const C104 = 'linear-gradient(to bottom, #D2CDE7,#696774)'
export const C105 = '#7E3CA1'
export const C106 = 'linear-gradient(to right, #B1B1B1,#FCFCFC)'
export const C107 = '#B98BAD'
export const C108 = '#9D6B82CF'
export const C109 = '#231F2080'
export const C110 = '#6a4b77'
export const C111 = '#1C4E6B'
export const C112 = '#919191'
export const C113 = '#0D0C07'
export const C114 = '#B4C2C3'
export const C115 = '#F4EEE2'
export const C116 = '#FFFFCB'
export const C117 = '#EFECDE'
export const C118 = '#669ACC'
export const C119 = '#659EB9'
export const C120 = '#986665'
export const C121 = '#9A7E4E'
export const C122 = '#CC9866'
export const C123 = '#131A22'
export const C124 = '#3B3B3B'
export const C125 = '#030303'
export const C126 = '#666666'
export const C127 = '#616161'
export const C128 = '#D9C39A'
export const C129 = '#A69370'
export const C130 = '#979832'
export const C131 = '#A58C4C'
export const C132 = '#9D9686'
export const C133 = '#C3943B'
export const C134 = '#336667'
export const C135 = '#0C0E1A'
export const C136 = '#656700'
export const C137 = '#5D5115'
export const C138 = '#C0A93F'
export const C139 = '#D4D4D2'
export const C140 = '#A6A5A1'
export const C141 = '#8385B9'
export const C142 = '#94A5D8'
export const C143 = '#FCFCFC80'
export const C144 = '#FCFCFCCC'
export const C145 = '#FCFCFC1A'
export const C146 = '#39A93580'
export const C147 = '#868686'
export const C148 = '#FCFCFC'
export const C149 = '#652D90'
export const C150 = '#ea95b4'
export const C151 = '#FCFCFC'
export const C152 = '#FCFCFC'
export const C153 = '#231F20'
export const C154 = '#FCFCFCCC'
export const C155 = '#E84E1B'
export const C156 = 'linear-gradient(to right,#5BC5F1,#F8B133)'
export const C157 = '#FCFCFC'
export const C158 = '#FF8800'
export const C159 = 'linear-gradient(to right, #8C5E8C,#3D244B)'
export const C160 = '#FCFCFC'
export const C161 = '#C45592'
export const C162 = '#AF439A'
export const C163 = '#5D2684'
export const C164 = '#421F7A'
export const C165 = '#2B0F70'
export const C166 = '#17065B'
export const C167 = '#231F20'
export const C168 = '#FCFCFC4D'
export const C169 = 'radial-gradient(#282560,#503C71,#E893B1)'
export const C170 = '#FCFCFC80'
export const C171 = 'linear-gradient(to right, #D0D2D3,#FCFCFC)'
export const C172 = '#FCFCFC33'
export const C173 = '#706F6F'
export const C174 = '#FCFCFC'
export const C175 = '#923AA0'
export const C176 = '#FCFCFCCC'
export const C177 = '#FCFCFC66'
export const C178 = '#FCFCFC80'
export const C179 = '#FCFCFC'
export const C180 = '#FCFCFC1A'
export const C181 = '#FCFCFC1A'
export const C182 = '#FCFCFC33'
export const C183 = 'linear-gradient(to right, #262261,#EA95B4)'
export const C184 = 'linear-gradient(to right, #EA95B4,#262261)'
export const C185 = '#FCFCFC4D'
export const C186 = 'linear-gradient(to right, #FCFCFC,#D0D2D3)'
export const C187 = '#FCFCFC'
export const C188 = '#FF8800'
export const C189 = '#EF7C014D'
export const C190 = '#FF8800'
export const C191 = '#FF8800'
export const C192 = '#FCFCFCCC'
export const C193 = '#FCFCFC33'
export const C194 = '#FCFCFCCC'
export const C195 = '#FF8800'
export const C196 = '#FCFCFC80'
export const C197 = '#FCFCFC4D'
export const C198 = '#231F20'
export const C199 = 'linear-gradient(to right, #262261,#EA95B4)'
export const C200 = '#FCFCFC'
export const C201 = '#FF8800'
export const C202 = '#231F20'
export const C203 = '#FCFCFC80'
export const C204 = '#231F2080'
export const C205 = '#FCFCFC1A'
export const C206 = '#FCFCFC4D'
export const C207 = '#37B34A'
export const C208 = 'linear-gradient(to right, #D0D2D3,#FCFCFC)'
export const C209 = '#231F20'
export const C210 = '#FCFCFCB3'
export const C211 = 'linear-gradient(to right, #D0D2D3,#FCFCFC)'
export const C212 = '#93C01F'
export const C213 = '#FCFCFC'
export const C214 = '#FCFCFC99'
export const C215 = '#7C0EFF'
export const C216 = '#DDC136'
export const C217 = '#421F7A'
export const C218 = '#FCFCFC80'
export const C219 = '#FCFCFCCC'
export const C220 = '#FCFCFC80'
export const C221 = '#D0D2D3'
export const C222 = '#231F2040'
export const C223 = '#231F20'
export const C224 = '#F8B133'
export const C225 = '#FF8800'
export const C226 = '#E1E1E1'
export const C227 = '#231F20'
export const C228 = '#bfc1c7'
export const C229 = '#F8B133'
export const C230 = '#231F20'
export const C231 = '#FCFCFCB3'
export const C232 = '#FCFCFCCC'
export const C233 = '#231F20'
export const C234 = '#D0D2D3'
export const C235 = '#F8B133'
export const C236 = 'linear-gradient(to right, #262261,#EA95B4)'
export const C237 = '#E20613'
export const C238 = 'linear-gradient(to right, #262261,#EA95B4)'
export const C239 = 'linear-gradient(to right, #D0D2D3,#FCFCFC)'
export const C240 = '#FCFCFC'
export const C241 = '#FCFCFCB3'
export const C242 = '#FCFCFC'
export const C243 = '#6C66AF'
export const C244 = '#FCFCFC'
export const C245 = '#5D2684'
export const C246 = '#DC73A2'
export const C247 = '#FCFCFCB3'
export const C248 = '#CCB058'
export const C249 = '#B0B0B0'
export const C250 = '#BB9090'
export const C251 = '#638088'
export const C252 = '#545F83'
export const C253 = '#FCFCFC1A'
export const C254 = 'linear-gradient(to right, #B1B1B1,#DADADA,#FCFCFC)'
export const C255 = 'linear-gradient(to right, #F4B6CF,#C797D7)'
export const C256 = '#7C0EFF'
export const C257 = '#FCFCFC4D'
export const C258 = '#FCFCFC99'
export const C259 = '#FCFCFC1A'
export const C260 = '#FCFCFC66'
export const C261 = '#FCFCFC4D'
export const C262 = '#8BC53F'
export const C263 = '#A6A9AB'
export const C264 = 'linear-gradient(to right, #F4B6CF,#C797D7)'
export const C265 = 'linear-gradient(to right, #F4B6CF66,#C797D766)'
export const C266 = '#D99FE0'
export const C267 = '#9A94D04D'
export const C268 = '#F29100'
export const C269 = '#FCFCFC26'
export const C270 = '#FCFCFC33'
export const C271 = '#C4679E'
export const C272 = '#00ADEE'
export const C273 = '#C2C73B'
export const C274 = '#D3D3D3'
export const C275 = '#EA95B44D'
export const C276 = '#7F3F97'
export const C277 = '#FCFCFCA8'
export const C278 = '#FCFCFC66'
export const C279 = '#C02EF2'
export const C280 = '#A78233'
export const C281 = '#7D4848'
export const C282 = '#638088'
export const C283 = '#262261'
export const C284 = '#FCFCFC4D'
export const C285 = '#FCFCFC33'
export const C286 = 'linear-gradient(to right, #D0D2D3,#FCFCFC)'
export const C287 = 'linear-gradient(to right, #808080,#FCFCFC)'
export const C288 = '#FF8800'
export const C289 = '#FCFCFC80'
export const C290 = '#FCFCFC80'
export const C291 = '#FCFCFC'
export const C292 = '#262261'
export const C293 = '#FCFCFC4D'
export const C294 = '#EB008B'
export const C295 = '#EB9605'
export const C296 = '#EEC732'
export const C297 = '#FCFCFC80'
export const C298 = 'linear-gradient(to right, #EA95B4,#262261)'
export const C299 = 'radial-gradient(#262261,#EA95B4,#262261)'
export const C300 = '#FCFCFC99'
export const C301 = '#EB9605'
export const C302 = '#E1E1E1'
export const C303 = '#D0D2D3'
export const C304 = '#FCFCFC66'
export const C305 = '#FCFCFC33'
export const C306 = '#87878799'
export const C307 = '#87878733'
export const C308 = '#DCA3CE'
export const C309 = '#F9AE08'
export const C310 = '#F9AE08'
export const C311 = '#009EE2'
export const C312 = '#282185'
export const C313 = '#221A42'
export const C314 = '#C67FB4'
export const C315 = '#C06FAB'
export const C316 = '#B260A6'
export const C317 = '#A555A1'
export const C318 = '#964E9D'
export const C319 = '#8A4B9C'
export const C320 = '#7C429B'
export const C321 = '#733593'
export const C322 = '#672C8C'
export const C323 = '#5D2684'
export const C324 = '#532676'
export const C325 = '#482770'
export const C326 = '#362368'
export const C327 = '#2F1F5E'
export const C328 = '#292051'
export const C329 = '#1E1D44'
export const C330 = '#191839'
export const C331 = '#151432'
export const C332 = '#12112E'
export const C333 = '#0B0A21'
export const C334 = '#151432'
export const C335 = '#12112E'
export const C336 = '#7C429B'
export const C337 = '#733593'
export const C338 = '#672C8C'
export const C339 = '#5D2684'
export const C340 = '#532676'
export const C341 = '#482770'
export const C342 = '#362368'
export const C343 = '#2F1F5E'
export const C344 = '#292051'
export const C345 = '#1E1D44'
export const C346 = '#191839'
export const C347 = '#151432'
export const C348 = '#C67FB4'
export const C349 = '#C06FAB'
export const C350 = '#B260A6'
export const C351 = '#A555A1'
export const C352 = '#964E9D'
export const C353 = '#8A4B9C'
export const C354 = '#7C429B'
export const C355 = '#733593'
export const C356 = '#672C8C'
export const C357 = '#5D2684'
export const C358 = '#532676'
export const C359 = '#482770'
export const C360 = '#362368'
export const C361 = '#2F1F5E'
export const C362 = '#292051'
export const C363 = '#1E1D44'
export const C364 = '#191839'
export const C365 = '#C67FB4'
export const C366 = '#C06FAB'
export const C367 = '#B260A6'
export const C368 = '#A555A1'
export const C369 = '#964E9D'
export const C370 = '#8A4B9C'
export const C371 = '#7C429B'
export const C372 = '#733593'
export const C373 = '#672C8C'
export const C374 = '#5D2684'
export const C375 = '#532676'
export const C376 = '#482770'
export const C377 = '#362368'
export const C378 = '#2F1F5E'
export const C379 = '#292051'
export const C380 = '#1E1D44'
export const C381 = '#C67FB4'
export const C382 = '#C06FAB'
export const C383 = '#B260A6'
export const C384 = '#A555A1'
export const C385 = '#964E9D'
export const C386 = '#8A4B9C'
export const C387 = '#733593'
export const C388 = '#672C8C'
export const C389 = '#5D2684'
export const C390 = '#532676'
export const C391 = '#482770'
export const C392 = '#362368'
export const C393 = '#2F1F5E'
export const C394 = '#292051'
export const C395 = '#1E1D44'
export const C396 = '#C67FB4'
export const C397 = '#C06FAB'
export const C398 = '#B260A6'
export const C399 = '#A555A1'
export const C400 = '#964E9D'
export const C401 = '#8A4B9C'
export const C402 = '#733593'
export const C403 = '#672C8C'
export const C404 = '#5D2684'
export const C405 = '#532676'
export const C406 = '#482770'
export const C407 = '#2F1F5E'
export const C408 = '#292051'
export const C409 = '#1E1D44'
export const C410 = '#C67FB4'
export const C411 = '#C06FAB'
export const C412 = '#B260A6'
export const C413 = '#964E9D'
export const C414 = '#8A4B9C'
export const C415 = '#733593'
export const C416 = '#672C8C'
export const C417 = '#5D2684'
export const C418 = '#532676'
export const C419 = '#482770'
export const C420 = '#2F1F5E'
export const C421 = '#292051'
export const C422 = '#1E1D44'
export const C423 = '#C67FB4'
export const C424 = '#C06FAB'
export const C425 = '#B260A6'
export const C426 = '#964E9D'
export const C427 = '#8A4B9C'
export const C428 = '#733593'
export const C429 = '#672C8C'
export const C430 = '#5D2684'
export const C431 = '#482770'
export const C432 = '#2F1F5E'
export const C433 = '#292051'
export const C434 = '#1E1D44'
export const C435 = '#C67FB4'
export const C436 = '#C06FAB'
export const C437 = '#964E9D'
export const C438 = '#8A4B9C'
export const C439 = '#733593'
export const C440 = '#672C8C'
export const C441 = '#5D2684'
export const C442 = '#482770'
export const C443 = '#2F1F5E'
export const C444 = '#292051'
export const C445 = '#1E1D44'
export const C446 = '#C67FB4'
export const C447 = '#C06FAB'
export const C448 = '#964E9D'
export const C449 = '#8A4B9C'
export const C450 = '#733593'
export const C451 = '#672C8C'
export const C452 = '#5D2684'
export const C453 = '#482770'
export const C454 = '#292051'
export const C455 = '#1E1D44'
export const C456 = '#C06FAB'
export const C457 = '#964E9D'
export const C458 = '#8A4B9C'
export const C459 = '#733593'
export const C460 = '#672C8C'
export const C461 = '#5D2684'
export const C462 = '#482770'
export const C463 = '#292051'
export const C464 = '#1E1D44'
export const C465 = '#C06FAB'
export const C466 = '#964E9D'
export const C467 = '#8A4B9C'
export const C468 = '#672C8C'
export const C469 = '#5D2684'
export const C470 = '#482770'
export const C471 = '#292051'
export const C472 = '#1E1D44'
export const C473 = '#C67FB4'
export const C474 = '#B260A6'
export const C475 = '#964E9D'
export const C476 = '#733593'
export const C477 = '#532676'
export const C478 = '#43226D'
export const C479 = '#362368'
export const C480 = '#292051'
export const C481 = '#231F204D'
export const C482 = '#FCFCFC99'
export const C483 = '#FCFCFC'
export const C484 = '#FF8800'
export const C485 = '#9E219D'
export const C486 = '#8887B3'
export const C487 = '#FCFCFC33'
export const C488 = '#FCFCFC4D'
export const C489 = '#9D0606'
export const C490 = '#262261'
export const C491 = 'linear-gradient(to bottom, #8C5A98,#FF8800)'
export const C492 = '#A8149D'
export const C493 = '#A19C06'
export const C494 = '#A56FE6'
export const C495 = '#AC618F'
export const C496 = '#2697AE'
export const C497 = '#16BFE0'
export const C498 = '#93C01F'
export const C499 = '#009EE2'
export const C500 = '#5D2684'
export const C501 = '#FCFCFCCC'
export const C502 = 'linear-gradient(to right, #FF8800,#9510DB)'
export const C503 = 'linear-gradient(to right, #9510DB,#FF8800)'
export const C504 = '#FF8800'
export const C505 = '#262261'
export const C506 = '#CACBCF'
export const C507 = '#707070'
export const C508 = '#FCFCFC33'
export const C509 = '#FCFCFCCC'
export const C510 = 'linear-gradient(to right, #FD8A8F,#7F32A7)'
export const C511 = '#FCFCFC4D'
export const C512 = '#BAB6B6'
export const C513 = '#BBBDBF'
export const C514 = '#FCFCFC80'
export const C515 = '#7448BC'
export const C516 = '#FCFCFCCC'
export const C517 = '#EB9605'
export const C518 = '#818181'
export const C519 = '#c67fb4'
export const C520 = '#FCFCFCB3'
export const C521 = 'linear-gradient(to right, #EA95B4,#262261)'
export const C522 = '#FCFCFC80'
export const C523 = '#262261'
export const C524 = '#FCFCFC'
export const C525 = '#FCFCFCCC'
export const C526 = '#FCFCFCCC'
export const C527 = '#FCFCFCCC'
export const C528 = '#FCFCFC80'
export const C529 = 'linear-gradient(to right, #8C5E8C,#3D244B)'
export const C530 = '#8C5E8C'
export const C531 = '#EB9605'
export const C532 = '#FCFCFCCC'
export const C533 = '#FCFCFC80'
export const C534 = '#8C5E8C'
export const C535 = '#262261'
export const C536 = '#FCFCFC80'
export const C537 = 'linear-gradient(to right, #8C5E8C,#3D244B)'
export const C538 = 'linear-gradient(to right, #8C5E8CDE,#3D244BDE)'
export const C539 = '#BBBDC0'
export const C540 = '#652D90'
export const C541 = '#FCFCFC'
export const C542 = '#FCFCFC26'
export const C543 = '#DBDEF1B3'
export const C544 = '#FCFCFC'
export const C545 = '#B29BBCB3'
export const C546 = '#8072AE4D'
export const C547 = '#7448BC'
export const C548 = '#FCFCFC60'
export const C549 = 'linear-gradient(to right, #ac7aa1, #7f608e)'
export const C550 = '#E7A27B'
export const C551 = '#EBBC8E'
export const C552 = '#D8BD1C'
export const C553 = '#231F20'
export const C554 = '#FCFCFC'
export const C555 = '#F2910045'
export const C556 = '#FCFCFC'
export const C557 = '#FFDD154D'
export const C558 = '#00ADEE4D'
export const C559 = '#37B34A4D'
export const C560 = 'linear-gradient(to right,#EA95B4,#262261)'
export const C561 = '#493670'
export const C562 = 'linear-gradient(to bottom,#251B38,#493670)'
export const C563 = '#FCFCFC1A'
export const C564 = '#FF8800'
export const C565 = '#FCFCFC'
export const C566 = '#592575'
export const C567 = 'linear-gradient(to right,#1AA7DB,#067EAA)'
export const C568 = '#FCFCFC42'
export const C569 = '#EA95B4E6'
export const C570 = '#FCFCFC'
export const C571 = '#E98020'
export const C572 = '#FCFCFC'
export const C573 = '#000000'
export const C574 = '#FCFCFC'
export const C575 = '#000000'
export const C576 = '#000000'
export const C577 = '#000000'
export const C578 = '#FCFCFCCC'
export const C579 = '#FCFCFC'
export const C580 = '#FCFCFC'
export const C581 = '#000000'
export const C582 = '#E8B7B7FF'
export const C583 = '#C3C3C3'
export const C584 = '#DACA85'
export const C585 = '#000000'
export const C586 = '#58595B'
export const C587 = '#FCFCFC'
export const C588 = 'linear-gradient(to right, #262261,#EA95B4)'
export const C589 = '#C1BED0'
export const C590 = '#e7c9e2'
export const C591 = '#C4C9CE'
export const C592 = '#F7D3F1'
export const C593 = '#FCFCFC'
export const C594 = '#5d2684'
export const C595 = '#3A358A'
export const C596 = '#FCFCFC'
export const C597 = '#F14DB0'
export const C598 = '#D4B3C2'
export const C599 = '#000000'
export const C600 = '#575756'
export const C601 = '#262261'
export const C602 = '#37B34A'
export const C603 = '#262261'
export const C604 = '#FF3944'
export const C605 = '#00000080'
export const C606 = '#FF8800'
export const C607 = '#0000002E'
export const C608 = '#5d2684'
export const C609 = '#262261'
export const C610 = '#835888'
export const C611 = '#FFFAEC'
export const C612 = '#57679A'
export const C613 = '#00000080'
export const C614 = '#FCFCFC'



export const MC1 = 'linear-gradient(to right, #d46d97b5, #a84f9fb5)'
export const MC2 = '#262261'
export const MC3 = '#231F2040'