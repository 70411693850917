import React, {useContext, useEffect, useRef} from 'react'
import structureStyle from './../StructuredComponents.module.scss'
import colorsContext from '../../../../contexts/ColorsContext'
import StructureImageComponent from '../Components/StructureImageComponent/StructureImageComponent'
import classNames from 'classnames'
import {isEmpty, isNumber} from 'lodash'
import componentStyle from './SingleImageTemplate.module.scss'
import structuresContext from "../../../../contexts/StructuresContext";
import {robotTeacherScrollIntoViewByElement} from "../../../../operations/robotTeacherScrollIntoViewByElement";
import {useSelector} from "react-redux";

const SingleImageTemplate = ({template}) => {
  const {colors} = useContext(colorsContext)
  const elRef = useRef(null)
  const {scrolling} = useSelector(state => state.roboTeacher)
  const {isRoboTeacher, roboTeacherData} = useContext(structuresContext)
  const isHidden = (isRoboTeacher ?? false) ? ((roboTeacherData.currentTime ?? 0) < (template?.hiddenTime ?? 0)) : false

  useEffect(() => {
    if (template?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isHidden])

  return (
    <div className='container container-1'>
      <div
        ref={elRef}
        data-rt-id={template?.id}
        className={classNames(
          structureStyle.templateContainer, componentStyle.singleImageTemplateContainer,
          {[structureStyle.border]: !isEmpty(template?.config?.borderColor)},
          {['hide-element']: isHidden}
        )}
        style={{
          background: isEmpty(template?.config?.backgroundColor) ? colors['C194'] : template.config.backgroundColor,
          borderColor: template?.config?.borderColor,
        }}
      >
        <StructureImageComponent picture={template?.data?.picture}/>
      </div>
    </div>
  )
}

export default SingleImageTemplate