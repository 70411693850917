export const C1 = '#231F20CC'
export const C2 = '#231F20'
export const C3 = '#EAD6D6'
export const C4 = '#EAD6E5'
export const C5 = '#DFD6EA'
export const C6 = '#D6D7EA'
export const C7 = '#D6E8EA'
export const C8 = '#D6EADF'
export const C9 = '#FCF0C5'
export const C10 = '#FFE7BB'
export const C11 = '#D291BC'
export const C12 = '#F5F1E6'
export const C13 = '#FCFCFC'
export const C14 = '#D6D7EA'
export const C15 = '#ECDCDA'
export const C16 = '#BFC932'
export const C17 = '#FFDD15'
export const C18 = '#FF8800'
export const C19 = '#231F20'
export const C20 = '#FCFCFCB3'
export const C21 = '#231F204D'
export const C22 = '#D6EADF'
export const C23 = '#231F20'
export const C24 = '#98BF64'
export const C25 = '#B4ECF9'
export const C26 = '#231F204D'
export const C27 = '#D0D2D3'
export const C28 = '#A2A1A1'
export const C29 = '#E20613'
export const C30 = '#1b982f'
export const C31 = '#957DAD'
export const C32 = '#DFD6EACC'
export const C33 = '#FCFCFC'
export const C34 = '#706F6F'
export const C35 = '#D6E8EA'
export const C36 = '#98BF64'
export const C37 = '#957DAD'
export const C38 = '#9569CCFF'
export const C39 = '#FCFCFC'
export const C40 = '#957DAD'
export const C41 = '#B4ECF9'
export const C42 = '#FEEFB8'
export const C43 = '#FCFCFC'
export const C44 = '#231F20'
export const C45 = '#FEEFB8'
export const C46 = '#E20613'
export const C47 = '#98BF64'
export const C48 = '#E20613'
export const C49 = '#EEF9BF'
export const C50 = '#EB008B'
export const C51 = '#E20613'
export const C52 = '#E20613'
export const C53 = '#E20613'
export const C54 = '#D6EADF'
export const C55 = '#D6C7EC'
export const C56 = '#98BF64'
export const C57 = 'linear-gradient(to right,#7C0EFF,#4F78FD,#09DEF8)'
export const C58 = '#09DEF8'
export const C59 = '#E1BD47'
export const C60 = '#98BF64'
export const C61 = '#E20613'
export const C62 = '#FCFCFC'
export const C63 = '#D6E8EA'
export const C64 = '#B1B1B1'
export const C65 = '#957DAD'
export const C66 = '#c09c38'
export const C67 = 'linear-gradient(to bottom,#FCFCFC,#D0D2D3)'
export const C68 = '#D6C7EC'
export const C69 = '#878787'
export const C70 = '#98BF64'
export const C71 = 'linear-gradient(to right,#37B34A,#E20613)'
export const C72 = '#EBDCFF'
export const C73 = '#F5F1E6'
export const C74 = '#EAD6E5'
export const C75 = '#EAD6D6'
export const C76 = '#EAD6E5'
export const C77 = '#DFD6EA'
export const C78 = '#D6D7EA'
export const C79 = '#D6E8EA'
export const C80 = '#D6EADF'
export const C81 = '#FCF0C5'
export const C82 = '#FFE7BB'
export const C83 = '#BCDFE7'
export const C84 = '#D291BC'
export const C85 = '#CFE0B8'
export const C86 = '#E1DBCB'
export const C87 = '#EBDDC4'
export const C88 = '#C9CAEB'
export const C89 = '#BFE6D1'
export const C90 = '#DFD6EA'
export const C91 = '#FCF2D0'
export const C92 = '#FFE1F5'
export const C93 = '#C6FCFE'
export const C94 = '#FFE7BB'
export const C95 = '#98BF64'
export const C96 = '#D6E8EA'
export const C97 = '#D6D7EA'
export const C98 = '#EAD6E5'
export const C99 = '#E20613'
export const C100 = '#48CDF7'
export const C101 = '#231F20'
export const C102 = '#FEEFB8'
export const C103 = '#D6D7EA'
export const C104 = 'linear-gradient(to bottom,#CDE7E5,#676C74)'
export const C105 = '#957DAD'
export const C106 = 'linear-gradient(to right,#B1B1B1,#FCFCFC)'
export const C107 = '#957DAD'
export const C108 = '#E20613'
export const C109 = '#231F2080'
export const C110 = '#957DAD'
export const C111 = '#1C4E6B'
export const C112 = '#919191'
export const C113 = '#0D0C07'
export const C114 = '#B4C2C3'
export const C115 = '#F4EEE2'
export const C116 = '#FFFFCB'
export const C117 = '#EFECDE'
export const C118 = '#669ACC'
export const C119 = '#659EB9'
export const C120 = '#986665'
export const C121 = '#9A7E4E'
export const C122 = '#CC9866'
export const C123 = '#131A22'
export const C124 = '#3B3B3B'
export const C125 = '#030303'
export const C126 = '#666666'
export const C127 = '#616161'
export const C128 = '#D9C39A'
export const C129 = '#A69370'
export const C130 = '#979832'
export const C131 = '#A58C4C'
export const C132 = '#9D9686'
export const C133 = '#C3943B'
export const C134 = '#336667'
export const C135 = '#0C0E1A'
export const C136 = '#656700'
export const C137 = '#5D5115'
export const C138 = '#C0A93F'
export const C139 = '#D4D4D2'
export const C140 = '#A6A5A1'
export const C141 = '#8385B9'
export const C142 = '#B4ECF9'
export const C143 = '#FCFCFC80'
export const C144 = '#D6D7EA'
export const C145 = '#D6EADF'
export const C146 = '#39A93580'
export const C147 = '#868686'
export const C148 = '#FEEFB8'
export const C149 = '#957DAD'
export const C150 = '#D291BC'
export const C151 = '#231F20'
export const C152 = '#231F20'
export const C153 = '#231F20'
export const C154 = '#FCF0C5'
export const C155 = '#948761'
export const C156 = 'linear-gradient(to right,#B1B1B1,#FCFCFC)'
export const C157 = '#EEF9BF'
export const C158 = '#98BF64'
export const C159 = '#98BF64'
export const C160 = '#FCFCFC'
export const C161 = '#EAD6E5'
export const C162 = '#DFD6EA'
export const C163 = '#D6E8EA'
export const C164 = '#D6EADF'
export const C165 = '#FCF0C5'
export const C166 = '#FFE7BB'
export const C167 = '#231F20'
export const C168 = '#231F204D'
export const C169 = 'radial-gradient(#FF889B,#FF889B,#FCFCFC)'
export const C170 = '#957DAD80'
export const C171 = 'linear-gradient(to right,#D0D2D3,#FCFCFC)'
export const C172 = '#D6D7EA'
export const C173 = '#706F6F'
export const C174 = '#FEEFB8'
export const C175 = '#231F20'
export const C176 = '#DFD6EA'
export const C177 = '#D6EADF'
export const C178 = '#231F2080'
export const C179 = '#231F20'
export const C180 = '#FEEFB8'
export const C181 = '#E20613'
export const C182 = '#957DAD'
export const C183 = '#FFDFD4'
export const C184 = '#D291BC'
export const C185 = '#D7C7EC'
export const C186 = '#D6C7EC'
export const C187 = '#957DAD'
export const C188 = '#B4ECF9'
export const C189 = '#E20613'
export const C190 = '#FEEFB8'
export const C191 = '#D291BC'
export const C192 = '#FCFCFCCC'
export const C193 = '#D6EADF'
export const C194 = '#FCFCFC'
export const C195 = '#E20613'
export const C196 = '#D291BC4D'
export const C197 = '#231F204D'
export const C198 = '#231F20'
export const C199 = '#D291BC'
export const C200 = '#FCFCFC'
export const C201 = '#c09f2b'
export const C202 = '#957DAD'
export const C203 = '#FCFCFC80'
export const C204 = '#957DAD80'
export const C205 = '#FEEFB8'
export const C206 = '#E20613'
export const C207 = '#E20613'
export const C208 = '#E20613'
export const C209 = '#FCFCFC'
export const C210 = '#957DADB3'
export const C211 = '#D6EADF'
export const C212 = '#B4ECF9'
export const C213 = '#98BF64'
export const C214 = '#957DADCC'
export const C215 = '#7C0EFF'
export const C216 = '#E20613'
export const C217 = '#E20613'
export const C218 = '#957DAD80'
export const C219 = '#DFD6EA'
export const C220 = '#D6EADF'
export const C221 = '#B1B1B1'
export const C222 = '#231F2040'
export const C223 = '#98BF64'
export const C224 = '#E20613'
export const C225 = '#98BF64'
export const C226 = '#E1E1E1'
export const C227 = '#F5F1E6'
export const C228 = '#bfc1c7'
export const C229 = '#98BF64'
export const C230 = '#231F20'
export const C231 = '#FEEFB8'
export const C232 = '#E1DBCB'
export const C233 = '#231F20'
export const C234 = '#FEEFB8'
export const C235 = '#957DAD'
export const C236 = '#a354a563'
export const C237 = '#E20613'
export const C238 = '#957DAD'
export const C239 = 'linear-gradient(to right,#D0D2D3,#FCFCFC)'
export const C240 = '#D6D7EA'
export const C241 = '#FCFCFCB3'
export const C242 = '#E2DFE5'
export const C243 = '#6C66AF'
export const C244 = '#D6EADF'
export const C245 = '#E20613'
export const C246 = '#E20613'
export const C247 = '#957DAD'
export const C248 = '#CCB058'
export const C249 = '#B0B0B0'
export const C250 = '#BB9090'
export const C251 = '#638088'
export const C252 = '#545F83'
export const C253 = '#D6E8EA'
export const C254 = '#FCF0C5'
export const C255 = '#98BF64'
export const C256 = '#957DAD'
export const C257 = '#D6E8EA'
export const C258 = '#D6E8EA'
export const C259 = '#FCFCFC1A'
export const C260 = '#E20613'
export const C261 = '#D6EADF'
export const C262 = '#E20613'
export const C263 = '#A6A9AB'
export const C264 = '#957DAD'
export const C265 = '#B4ECF9'
export const C266 = '#98BF64'
export const C267 = '#E1DBCB'
export const C268 = '#98BF64'
export const C269 = '#FEEFB8'
export const C270 = '#D6E8EA'
export const C271 = '#C4679E'
export const C272 = '#00ADEE'
export const C273 = '#C2C73B'
export const C274 = '#D3D3D3'
export const C275 = '#F3E875BF'
export const C276 = '#E20613'
export const C277 = '#FCF0C5FF'
export const C278 = '#F3E875BF'
export const C279 = '#C02EF2'
export const C280 = '#A78233'
export const C281 = '#7D4848'
export const C282 = '#638088'
export const C283 = '#262261'
export const C284 = '#D7C7EC'
export const C285 = '#FCFCFC33'
export const C286 = '#D6D7EA'
export const C287 = 'linear-gradient(to right,#808080,#FCFCFC)'
export const C288 = '#98BF64'
export const C289 = '#98BF64'
export const C290 = '#E20613'
export const C291 = '#FCFCFC'
export const C292 = '#957DAD'
export const C293 = '#E1DBCB'
export const C294 = '#98BF64'
export const C295 = '#D7C7EC'
export const C296 = '#EEC732'
export const C297 = '#231F2080'
export const C298 = '#E20613'
export const C299 = '#E20613'
export const C300 = '#FCFCFC99'
export const C301 = '#C4B0D8'
export const C302 = '#E1E1E1'
export const C303 = '#D0D2D3'
export const C304 = '#E1DBCB'
export const C305 = '#231F2033'
export const C306 = '#87878799'
export const C307 = '#E20613'
export const C308 = '#DCA3CE'
export const C309 = '#E20613'
export const C310 = '#E20613'
export const C311 = '#D291BC'
export const C312 = '#01ADD5'
export const C313 = '#F5F1E6'
export const C314 = '#EAD6D6'
export const C315 = '#F7DCE2'
export const C316 = '#F7DCF7'
export const C317 = '#DFD6EA'
export const C318 = '#D6DDEA'
export const C319 = '#D6E5EA'
export const C320 = '#D6EADE'
export const C321 = '#E1EAD6'
export const C322 = '#EAE6D6'
export const C323 = '#EADED6'
export const C324 = '#EAD7D6'
export const C325 = '#D6EAE9'
export const C326 = '#D6EAD6'
export const C327 = '#D6DAEA'
export const C328 = '#E7D6EA'
export const C329 = '#EAD6D7'
export const C330 = '#EAE7D6'
export const C331 = '#D6DCEA'
export const C332 = '#EAD6D8'
export const C333 = '#E3D6EA'
export const C334 = '#D6DCEA'
export const C335 = '#EAD6D8'
export const C336 = '#D6EADE'
export const C337 = '#E1EAD6'
export const C338 = '#EAE6D6'
export const C339 = '#EADED6'
export const C340 = '#EAD7D6'
export const C341 = '#D6EAE9'
export const C342 = '#D6EAD6'
export const C343 = '#D6DAEA'
export const C344 = '#E7D6EA'
export const C345 = '#EAD6D7'
export const C346 = '#EAE7D6'
export const C347 = '#D6DCEA'
export const C348 = '#EAD6D6'
export const C349 = '#F7DCE2'
export const C350 = '#F7DCF7'
export const C351 = '#DFD6EA'
export const C352 = '#D6DDEA'
export const C353 = '#D6E5EA'
export const C354 = '#D6EADE'
export const C355 = '#E1EAD6'
export const C356 = '#EAE6D6'
export const C357 = '#EADED6'
export const C358 = '#EAD7D6'
export const C359 = '#D6EAE9'
export const C360 = '#D6EAD6'
export const C361 = '#D6DAEA'
export const C362 = '#E7D6EA'
export const C363 = '#EAD6D7'
export const C364 = '#EAE7D6'
export const C365 = '#F7DCE2'
export const C366 = '#F7DCF7'
export const C367 = '#DFD6EA'
export const C368 = '#D6DDEA'
export const C369 = '#D6E5EA'
export const C370 = '#D6EADE'
export const C371 = '#E1EAD6'
export const C372 = '#EAE6D6'
export const C373 = '#EADED6'
export const C374 = '#EAD7D6'
export const C375 = '#D6EAE9'
export const C376 = '#D6EAD6'
export const C377 = '#D6DAEA'
export const C378 = '#E7D6EA'
export const C379 = '#EAD6D7'
export const C380 = '#EAE7D6'
export const C381 = '#F7DCF7'
export const C382 = '#DFD6EA'
export const C383 = '#D6DDEA'
export const C384 = '#D6E5EA'
export const C385 = '#D6EADE'
export const C386 = '#E1EAD6'
export const C387 = '#EAE6D6'
export const C388 = '#EADED6'
export const C389 = '#EAD7D6'
export const C390 = '#D6EAE9'
export const C391 = '#D6EAD6'
export const C392 = '#D6DAEA'
export const C393 = '#E7D6EA'
export const C394 = '#EAD6D7'
export const C395 = '#EAE7D6'
export const C396 = '#F7DCF7'
export const C397 = '#DFD6EA'
export const C398 = '#D6E5EA'
export const C399 = '#D6EADE'
export const C400 = '#E1EAD6'
export const C401 = '#EAE6D6'
export const C402 = '#EADED6'
export const C403 = '#EAD7D6'
export const C404 = '#D6EAE9'
export const C405 = '#D6EAD6'
export const C406 = '#D6DAEA'
export const C407 = '#E7D6EA'
export const C408 = '#EAD6D7'
export const C409 = '#EAE7D6'
export const C410 = '#DFD6EA'
export const C411 = '#D6E5EA'
export const C412 = '#D6EADE'
export const C413 = '#E1EAD6'
export const C414 = '#EAE6D6'
export const C415 = '#EADED6'
export const C416 = '#EAD7D6'
export const C417 = '#D6EAE9'
export const C418 = '#D6EAD6'
export const C419 = '#D6DAEA'
export const C420 = '#E7D6EA'
export const C421 = '#EAD6D7'
export const C422 = '#EAE7D6'
export const C423 = '#DFD6EA'
export const C424 = '#D6E5EA'
export const C425 = '#E1EAD6'
export const C426 = '#EAE6D6'
export const C427 = '#EADED6'
export const C428 = '#EAD7D6'
export const C429 = '#D6EAE9'
export const C430 = '#D6EAD6'
export const C431 = '#D6DAEA'
export const C432 = '#E7D6EA'
export const C433 = '#EAD6D7'
export const C434 = '#EAE7D6'
export const C435 = '#DFD6EA'
export const C436 = '#D6E5EA'
export const C437 = '#E1EAD6'
export const C438 = '#EAE6D6'
export const C439 = '#EADED6'
export const C440 = '#EAD7D6'
export const C441 = '#D6EAE9'
export const C442 = '#D6EAD6'
export const C443 = '#D6DAEA'
export const C444 = '#E7D6EA'
export const C445 = '#EAD6D7'
export const C446 = '#D6E5EA'
export const C447 = '#E1EAD6'
export const C448 = '#EAE6D6'
export const C449 = '#EADED6'
export const C450 = '#EAD7D6'
export const C451 = '#D6EAE9'
export const C452 = '#D6EAD6'
export const C453 = '#D6DAEA'
export const C454 = '#E7D6EA'
export const C455 = '#EAD6D7'
export const C456 = '#D6E5EA'
export const C457 = '#E1EAD6'
export const C458 = '#EAE6D6'
export const C459 = '#EADED6'
export const C460 = '#EAD7D6'
export const C461 = '#D6EAE9'
export const C462 = '#D6EAD6'
export const C463 = '#D6DAEA'
export const C464 = '#E7D6EA'
export const C465 = '#E1EAD6'
export const C466 = '#EAE6D6'
export const C467 = '#EADED6'
export const C468 = '#EAD7D6'
export const C469 = '#D6EAE9'
export const C470 = '#D6EAD6'
export const C471 = '#D6DAEA'
export const C472 = '#E7D6EA'
export const C473 = '#EBC9C9'
export const C474 = '#EEC0E2'
export const C475 = '#CBB9E2'
export const C476 = '#B7B9E2'
export const C477 = '#B2DFE6'
export const C478 = '#C0E8D2'
export const C479 = '#F5E5A9'
export const C480 = '#F6D79E'
export const C481 = '#231F204D'
export const C482 = '#231F2099'
export const C483 = '#231F20'
export const C484 = '#98BF64'
export const C485 = '#957DAD'
export const C486 = '#957DAD'
export const C487 = '#FEEFB8'
export const C488 = '#F5F1E6'
export const C489 = '#9D0606'
export const C490 = '#98BF64'
export const C491 = 'linear-gradient(to bottom,#98BF64,#75E5FF)'
export const C492 = '#E20613'
export const C493 = '#E20613'
export const C494 = '#E20613'
export const C495 = '#E20613'
export const C496 = '#E20613'
export const C497 = '#E20613'
export const C498 = '#E20613'
export const C499 = '#009EE2'
export const C500 = '#5D2684'
export const C501 = '#D6E8EA'
export const C502 = 'linear-gradient(to right,#B4ECF9,#98BF64)'
export const C503 = 'linear-gradient(to right,#FCF0C5,#957DAD)'
export const C504 = '#D291BC'
export const C505 = '#98BF64'
export const C506 = '#CACBCF'
export const C507 = '#707070'
export const C508 = '#caeeda'
export const C509 = '#FCF0C5'
export const C510 = '#957DAD'
export const C511 = '#98BF64'
export const C512 = '#BAB6B6'
export const C513 = '#BBBDBF'
export const C514 = '#E20613'
export const C515 = '#7448BC'
export const C516 = '#FCFCFCCC'
export const C517 = '#957DAD'
export const C518 = '#818181'
export const C519 = '#FCFCFCCC'
export const C520 = '#FCFCFCB3'
export const C521 = '#FCF0C5'
export const C522 = '#D6D7EA'
export const C523 = '#231F20'
export const C524 = '#FFE7BB'
export const C525 = '#FEEFB8'
export const C526 = '#FCFCFC'
export const C527 = '#D6D7EA'
export const C528 = '#E20613'
export const C529 = '#D6C7EC'
export const C530 = '#957DAD'
export const C531 = '#84A656FF'
export const C532 = '#D6EADF'
export const C533 = '#D6D7EA'
export const C534 = '#957DAD'
export const C535 = '#FEEFB8'
export const C536 = '#D6EADF'
export const C537 = '#957DAD'
export const C538 = '#E20613'
export const C539 = '#E20613'
export const C540 = '#231F20'
export const C541 = '#231F20'
export const C542 = '#B4ECF94D'
export const C543 = '#D6E8EA'
export const C544 = '#D6EADF'
export const C545 = '#98BF64'
export const C546 = '#D6EADF'
export const C547 = '#957DAD'
export const C548 = '#FCFCFC'
export const C549 = '#D6E8EA'
export const C550 = '#D4F0F7'
export const C551 = '#DFECF9'
export const C552 = '#D8BD1C'
export const C553 = '#231F20'
export const C554 = '#231F20'
export const C555 = '#957DAD45'
export const C556 = '#B4ECF9'
export const C557 = '#FFDD154D'
export const C558 = '#00ADEE4D'
export const C559 = '#37B34A4D'
export const C560 = '#D291BC'
export const C561 = '#DFD6EA'
export const C562 = '#FEEFB8'
export const C563 = '#FCFCFC33'
export const C564 = '#957DAD'
export const C565 = '#231F20'
export const C566 = '#957DAD'
export const C567 = 'linear-gradient(to right,#1AA7DB,#067EAA)'
// export const C567 =	'#E98020'
export const C568 = '#D6D7EA'
export const C569 = '#FCF0C5'
export const C570 = '#D291BC'
export const C571 = '#E98020'
export const C572 = '#FEEFB8'
export const C573 = '#000000'
export const C574 = '#FCFCFC'
export const C575 = '#FCF0C5'
export const C576 = '#FCFCFC'
export const C577 = '#000000'
export const C578 = '#6f8d47'
export const C579 = '#957DAD'
export const C580 = '#FCFCFC'
export const C581 = '#000000'
export const C582 = '#D6E8EA'
export const C583 = '#58595B'
export const C584 = '#00000024'
export const C585 = '#000000'
export const C586 = '#58595B'
export const C587 = '#D291BC'
export const C588 = '#e6c2dc'
export const C589 = '#4A4A4A'
export const C590 = '#E9E35E'
export const C591 = '#575756'
export const C592 = '#957DAD'
export const C593 = '#000000'
export const C594 = '#D291BC'
export const C595 = '#DE9ED0'
export const C596 = '#231F20'
export const C597 = '#A142FF'
export const C598 = '#EAD6D6'
export const C599 = '#000000'
export const C600 = '#575756'
export const C601 = '#f5f1e6'
export const C602 = '#37B34A'
export const C603 = '#FFF4D4'
export const C604 = '#DE9ED0'
export const C605 = '#00000080'
export const C606 = '#d75fbe'
export const C607 = '#0000002E'
export const C608 = '#D291BC'
export const C609 = '#F5F1E6'
export const C610 = '#C884B1'
export const C611 = '#FFFAEC'
export const C612 = '#21252B'
export const C613 = '#00000080'
export const C614 = '#000000'

export const MC1 = '#656985B5'
export const MC2 = '#2A2D3E'
export const MC3 = '#231F2040'