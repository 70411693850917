import React, {useContext} from "react";
import colorsContext from "../../contexts/ColorsContext";
import ClickHintSvg from "../../icons/ClickHintSvg";

const HintClueButton = ({onClickHint, disabled, loading}) => {
  const {colors} = useContext(colorsContext)
  require('./hintClueButton.scss')

  return (
    <button
      className="hint-clue-button"
      disabled={disabled || loading}
      onClick={() => !disabled ? onClickHint() : null}
      style={{
        background: disabled ?
          "linear-gradient(#FFF, #D0D2D3)" :
          colors["C65"],
        opacity: loading ? 0.5 : 1
      }}
      data-jsx="HintClueButton"
    >
      <ClickHintSvg color={disabled ? "#C4C9CE" : colors["C174"]}/>
    </button>
  );
}

export default HintClueButton;