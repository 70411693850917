import classNames from "classnames";
import useAppUser from "../../hooks/useAppUser";

const NativeText = ({children, className, ...props}) => {
  const {appUserData} = useAppUser()

  return (
    <p
      dir={appUserData?.setting?.nativeLanguageFontDirection}
      className={classNames(className, {['font-sahel']: appUserData?.setting?.nativeLanguageFontDirection === 'rtl'})}
      {...props}
    >
      {children}
    </p>
  )
}

export default NativeText