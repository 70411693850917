import * as React from "react"

const HelpBorderSvg = ({color}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        xmlSpace="preserve"
    >
        <path
            fill={color}
            d="M24 .23C10.9.23.23 10.9.23 24S10.9 47.77 24 47.77 47.77 37.11 47.77 24C47.77 10.9 37.1.23 24 .23zm0 44.54C12.55 44.77 3.23 35.45 3.23 24S12.55 3.23 24 3.23 44.77 12.55 44.77 24 35.45 44.77 24 44.77z"
        />
        <path
            fill={color}
            d="M24 11.62c-3.83 0-6.94 3.11-6.94 6.94 0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5c0-2.17 1.77-3.94 3.94-3.94s3.94 1.77 3.94 3.94S26.17 22.5 24 22.5c-.83 0-1.5.67-1.5 1.5v6.85c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-5.51c3.11-.69 5.44-3.46 5.44-6.77 0-3.84-3.11-6.95-6.94-6.95zM24 33.57c-1.01 0-1.84.82-1.84 1.84 0 1.01.82 1.84 1.84 1.84s1.84-.82 1.84-1.84c0-1.01-.83-1.84-1.84-1.84z"
        />
    </svg>
)

export default HelpBorderSvg
