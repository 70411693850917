import axios from "axios";
import { currentMainAPI } from "./mainAPIs";
import * as Sentry from "@sentry/react";

const refreshAccessToken = async () => {
  const authToken = JSON.parse(localStorage.getItem('user_token') ?? null)
  const { refresh_token } = authToken

  if (!refresh_token || refresh_token === 'undefined') {
    Sentry.captureMessage(
      'access token requested but refresh_token is undefined',
      {
        level: 'warning',
        extra: { authToken }
      }
    )
  }

  try {
    const { data } = await axios.post(`${currentMainAPI}/api/v1/token/refresh`, null, {
      params: { refresh_token }
    });
    if (data?.data) {
      localStorage.setItem('user_token', JSON.stringify(data?.data))
    }

    return data?.data;
  } catch (e) {
    if (e?.response?.status === 500) {
      localStorage.removeItem('user_token')
      window.location.href = '/auth/login'
    }
  }
}

export default refreshAccessToken