import { answerComparator } from "../util/answerComparator/answerComparator"

const exerciseCheckFactory = (data) => {
  const modifiedUserAnswer = answerComparator(
    data?.exerciseNormalizedDTO?.normalizedSystemAnswer,
    data?.exerciseNormalizedDTO?.normalizedUserAnswer,
    data?.exerciseNormalizedDTO?.specialNamesCount,
  )

  data.exerciseCheckItemResponse[0].userAnswerItemResponse[0].wordByWordResult = modifiedUserAnswer.wordByWordResult
  data.exerciseCheckItemResponse[0].userAnswerItemResponse[0].userAnswerStatus = modifiedUserAnswer.status
  return data
}

export default exerciseCheckFactory