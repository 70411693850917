import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import serviceCall from '../../services'

const initialState = {
	loading: false,
	data: null,
	error: '',
}

export const getAlphabetSection = createAsyncThunk(
	'alphabet/fetchAlphabetSection',
	() => {
		return serviceCall(
			'fetchAlphabetSection',
			null,
			'cloud',
		)
	},
)

const alphabetSlice = createSlice({
	name: 'alphabet',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getAlphabetSection.pending, (state) => {
			state.loading = true
		})
		builder.addCase(getAlphabetSection.fulfilled, (state, action) => {
			state.loading = false
			state.data = action.payload.data
		})
		builder.addCase(getAlphabetSection.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message
		})
	},
})

export const {} = alphabetSlice.actions

export default alphabetSlice.reducer