import React, {useContext, useEffect} from 'react'
import exerciseStyles from './../ExerciseModules.module.scss'
import classNames from 'classnames'
import colorsContext from '../../../contexts/ColorsContext'
import {useDispatch, useSelector} from 'react-redux'
import ComboBoxDropdown from '../../../components/ComboBoxDropdown/ComboBoxDropdown'
import {
  checkExerciseItem,
  goToNextItem,
  selectChoiceCombo, setExerciseItemUpdated,
  toggleLockPaginable,
} from '../../../redux/features/exerciseSlice'
import Image from '../../../components/Image'
import Lock from '../../../components/Lock/Lock'
import ExerciseItemCheck from '../../../components/ExerciseItemCheck/ExerciseItemCheck'
import getUserStatusColor from '../../../operations/getUserStatusColor'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import {moreOptionsList} from '../../../data'
import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const ComboPictionaryModule = () => {
  const {colors} = useContext(colorsContext)
  const dispatch = useDispatch()
  const {data, isSingleTemplate, currentItem, checkingItem, exerciseItemUpdated} = useSelector((state) => state.exercise)

  useEffect(() => {
    if (exerciseItemUpdated) {
      if (data.immediateCheck) {
        const currentEnableStack = data.stacks[currentItem.stackIndex]
        const currentEnableItem = currentEnableStack.items[currentItem.itemIndex]

        const allFieldsAreFilled = currentEnableItem.choices.every(choice => choice.items.some(it => it.selected))

        if (allFieldsAreFilled) {
          onCheckItem(currentEnableStack.id, currentEnableItem)
        }
      }
      dispatch(setExerciseItemUpdated(false))
    }
  }, [exerciseItemUpdated])

  const onCheckItem = (stackId, exerciseItem) => {
    const userAnswerData = {
      exerciseStackId: stackId,
      userAnswerItems: [
        {
          exerciseItemId: exerciseItem.id,
          userAnswers: exerciseItem.choices.map(choice => {
            const selectedChoice = choice.items.find(choiceItem => choiceItem.selected)
            return {
              listIndex: choice.index,
              index: selectedChoice ? selectedChoice.index : null,
              skipped: !selectedChoice,
            }
          }),
        },
      ],
    }
    dispatch(checkExerciseItem({userAnswerData})).then((res) => {
      if (res.payload?.status === 200 && !isSingleTemplate) {
        dispatch(goToNextItem())
      }
    })
  }

  const renderComboBox = (exerciseItem, stackIndex, itemIndex) => {
    if (exerciseItem.checked) {
      const choiceList = exerciseItem.choices[0]
      const answerStatus = choiceList.userAnswer.userAnswerStatus
      const answerStatusColor = getUserStatusColor(answerStatus)
      const selectedAnswer = choiceList.items.find(obj => obj.selected)
      const correctAnswer = choiceList.items.find(obj => obj.isAnswer)

      return (
        <div
          className={exerciseStyles.comboBox}
          style={{
            borderColor: answerStatus === AnswerStatusEnums.SKIPPED ? colors['C29'] : colors[answerStatusColor],
            justifyContent: 'center',
          }}
        >
          <HtmlTextWrapper
            className={exerciseStyles.comboBoxValue}
            textColor={exerciseItem.unlocked ? colors['C23'] : colors[answerStatusColor]}
            data={{
              sentence: (exerciseItem.unlocked || answerStatus === AnswerStatusEnums.SKIPPED)
                ? correctAnswer.text.sentence
                : (selectedAnswer ? selectedAnswer.text.sentence : <span>&nbsp;</span>),
            }}
          />
        </div>
      )
    } else {
      return (
        <ComboBoxDropdown
          disabled={checkingItem}
          dropdownItems={exerciseItem.choices[0].items.map(option => {
            return {
              text: option.text.sentenceString,
              selected: option.selected,
              onClick: () => {
                dispatch(selectChoiceCombo({
                  stackIndex,
                  itemIndex,
                  choicesIndex: 0,
                  choiceIndex: option.index,
                }))
              },
            }
          })}
        />
      )
    }
  }

  const renderExerciseItem = (exerciseItem, stackIndex, itemIndex, enabled) => {
    return (
      <div
        key={itemIndex}
        className={
          isSingleTemplate ?
            classNames(exerciseStyles.exerciseCard, exerciseStyles.comboBoxCard) :
            classNames(
              exerciseItem.given.templateClassName,
              {[exerciseStyles.cardDisabled]: (!enabled && !exerciseItem.checked)},
              'mb-1 mt-auto',
            )
        }
        style={{background: isSingleTemplate ? colors['C527'] : 'transparent', borderColor: colors['C160']}}
      >
        <div
          className={classNames(exerciseStyles.givenTemplateContainer, 'giventype-column d-flex align-items-center')}>
          {exerciseItem.given.text &&
            <HtmlTextWrapper
              data={exerciseItem.given.text}
              textColor={colors['C23']}
              optionsColor={colors['C23']}
              moreOptionsList={moreOptionsList}
              showPhraseOptions
            />
          }
          <Image
            onClickZoomIn
            className={exerciseStyles.image}
            uri={exerciseItem.given.imageUri}
          />
        </div>
        <div className='d-flex justify-content-center'>
          {renderComboBox(exerciseItem, stackIndex, itemIndex)}
        </div>
        <div
          className={classNames(
            exerciseStyles.exerciseCardFooter,
            exerciseStyles.footerLarge,
            'justify-content-center',
          )}
        >
          {exerciseItem.checked ? (
            <div className='w-100 d-flex justify-content-center'>
              <HtmlTextWrapper
                data={{...exerciseItem.fullTextAnswer, sentence: null}}
                moreOptionsList={moreOptionsList}
                showPhraseOptions
                phraseOptionsDisabled={(exerciseItem.userAnswerStatus === AnswerStatusEnums.INCORRECT && !exerciseItem.unlocked)}
                optionsColor={colors['C23']}
              />
              {exerciseItem.choices.some(obj => obj.userAnswer.userAnswerStatus === AnswerStatusEnums.INCORRECT) &&
                <Lock
                  onClickLock={() => dispatch(toggleLockPaginable({stackIndex, itemIndex}))}
                  isLocked={!exerciseItem.unlocked}
                />
              }
            </div>
          ) : (
            !data?.immediateCheck ?
              <div className='w-100 d-flex justify-content-center mt-1'>
                <ExerciseItemCheck
                  disabled={!enabled || checkingItem}
                  checking={enabled && checkingItem}
                  onClick={() => onCheckItem(data.stacks[stackIndex].id, exerciseItem)}
                />
              </div> :
              null
          )}
        </div>
      </div>
    )
  }

  const renderStack = (stack, stackIndex) => {
    return (
      <div
        className={classNames(exerciseStyles.exerciseCard, exerciseStyles.comboBoxCard, 'row')}
        style={{background: colors['C527']}}
        key={stackIndex}
      >
        {stack.items.map((item, i) => {
          let enabled = false
          if (currentItem.stackIndex === stackIndex && currentItem.itemIndex === i) {
            enabled = true
          }
          return renderExerciseItem(item, stackIndex, i, enabled)
        })}
      </div>
    )
  }

  const renderExercises = () => {
    if (isSingleTemplate) {
      // Give and display current page item in single view
      const item = data.stacks[currentItem.stackIndex].items[currentItem.itemIndex]
      return renderExerciseItem(item, currentItem.stackIndex, currentItem.itemIndex, true)
    } else {
      // Render all stacks in list view
      return data.stacks.map((stack, i) => {
        return renderStack(stack, i)
      })
    }
  }


  return (
    <div className='container container-1'>
      <div className={exerciseStyles.comboBoxContainer}>
        {renderExercises()}
      </div>
    </div>
  )
}

export default ComboPictionaryModule