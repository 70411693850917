import {isRejected} from '@reduxjs/toolkit';
import * as Sentry from "@sentry/react";

const sentryMiddleware = (storeAPI) => (next) => (action) => {
  if (isRejected(action)) {
    const error = action.error;
    const sentryError = new Error(error.message);
    sentryError.name = error.name;
    sentryError.stack = error.stack;
    Sentry.captureException(sentryError);
  }
  return next(action);
};

export default sentryMiddleware;