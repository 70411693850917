import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import serviceCall from "../../services";

const initialState = {
  loading: false,
  getThemesLoading: false,
  themesData: null,
  error: '',
}

export const getThemes = createAsyncThunk(
  'theme/getThemes',
  () => {
    return serviceCall(
      'fetchThemes',
      null,
      'cloud',
    )
  },
)

export const setThemeToServer = createAsyncThunk(
  'theme/setThemeToServer',
  ({type}) => {
    return serviceCall(
      'setTheme',
      {type},
      'cloud',
    )
  },
)

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setThemeToServer.pending, (state) => {
      state.loading = true
    })
    builder.addCase(setThemeToServer.fulfilled, (state, action) => {
      state.loading = false

    })
    builder.addCase(setThemeToServer.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(getThemes.pending, (state) => {
      state.getThemesLoading = true
    })
    builder.addCase(getThemes.fulfilled, (state, action) => {
      state.getThemesLoading = false
      state.themesData = action.payload
    })
    builder.addCase(getThemes.rejected, (state, action) => {
      state.getThemesLoading = false
      state.error = action.error.message
    })
  }
})

export const {} = themeSlice.actions

export default themeSlice.reducer