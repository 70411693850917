import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import useLocalStorage from "../../hooks/useLocalStorage";
import TranslatedText from "../TranslatedText/TranslatedText";

const VersionUpdateModal = () => {
  const [pwaVersion, setPwaVersion] = useLocalStorage('pwa-version', null)
  const {newVersionAvailable} = useSelector(state => state.version)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (newVersionAvailable) {
      setIsOpen(true)
    }
  }, [newVersionAvailable])

  if (isOpen) {
    return (
      <ConfirmationModal
        closeModal={() => {
          setPwaVersion(newVersionAvailable)
          setIsOpen(false)
        }}
        text={<TranslatedText translationKey={'new.version'}/>}
        confirmText={<TranslatedText translationKey={'reload'}/>}
        cancelText={<TranslatedText translationKey={'update.later'}/>}
        onConfirm={() => {
          setPwaVersion(newVersionAvailable)
          window.location?.reload()
        }}
      />
    )
  }
  return null
}

export default VersionUpdateModal