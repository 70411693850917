import { createSlice } from "@reduxjs/toolkit";
import dictionaryUseCase from "../../useCase/dictionaryUseCase/dictionaryUseCase";
import { VELLSO_DICTIONARY } from "../../enums/dictionaryEnums/dictionaryEnums";
import { baseAsyncThunk } from "../baseAsyncThunk";

const initialState = {
  loading: false,
  selectedDictionary: VELLSO_DICTIONARY,
  wordSearchResult: {},
  sentenceSearchResult: [],
  error: '',
}

export const getDictionary = baseAsyncThunk(
  'dictionary/fetchDictionary',
  ({ phrase }) => {
    return dictionaryUseCase({ phrase })
  },
)

const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState,
  reducers: {
    setWordVoice: (state, action) => {
      if (state.wordSearchResult) {
        if (action.payload.accent === 'UK') {
          state.wordSearchResult.voices.UK = action.payload.voiceUrl
        } else if (action.payload.accent === 'US') {
          state.wordSearchResult.voices.US = action.payload.voiceUrl
        }
      }
    },
    setSelectedDictionary: (state, action) => {
      state.selectedDictionary = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getDictionary.pending, (state) => {
      state.loading = true
      state.wordSearchResult = null
    })
    builder.addCase(getDictionary.fulfilled, (state, action) => {
      state.loading = false
      state.wordSearchResult = action.payload
    })
    builder.addCase(getDictionary.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const { setWordVoice, setSelectedDictionary } = dictionarySlice.actions

export default dictionarySlice.reducer