import useAudioPlayer from "../../hooks/audio/useAudioPlayer"
import { useEffect } from "react"
import PlayButtonPrimary from "./PlayButtonPrimary"
import useAppUser from "../../hooks/useAppUser"

const PlayButtonPrimaryAudio = (
  {
    audioUrl,
    handlePlaying,
    handleCurrentTime,
    iconColor,
    bgColor,
    borderColor,
    loading,
    onPlayed = () => {
    },
    onEnded = () => {
    }
  }
) => {
  const { appUserData } = useAppUser()
  const { curTime, playing, setPlaying } = useAudioPlayer({ audioUrl, onPlayed, onEnded, accent: appUserData.setting.availableAccent })

  useEffect(() => {
    if (handlePlaying) {
      handlePlaying(playing)
    }
  }, [handlePlaying, playing])

  useEffect(() => {
    if (handleCurrentTime) {
      handleCurrentTime(curTime * 1000)
    }
  }, [handleCurrentTime, curTime])

  return <PlayButtonPrimary
    loading={loading}
    iconColor={iconColor}
    bgColor={bgColor}
    borderColor={borderColor}
    playing={playing}
    setPlaying={setPlaying}
  />
}

export default PlayButtonPrimaryAudio