import { AnswerStatusEnums } from '../enums/globalEnums/globalEnums'

const getUserAnswerStatusBorderColor = (status) => {
	if (status === AnswerStatusEnums.CORRECT) {
		return "C30"
	} else {
		return "C29"
	}
}

export default getUserAnswerStatusBorderColor