import * as React from "react"

const TranslationSvg = ({color}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={512}
        height={512}
        viewBox="0 0 512 512"
    >
        <style>
            {
                ".TranslationSvg-st0{fill:none;stroke-width:28;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:17.7777}"
            }
        </style>
        <path
            strokeWidth={37.333239999999996}
            d="M42.533 14.935H207.6c15.2 0 27.467 12.267 27.467 27.467v165.066c0 15.2-12.267 27.467-27.467 27.467H42.533c-15.2 0-27.466-12.267-27.466-27.467V42.402c0-15.067 12.266-27.467 27.466-27.467zM304.533 276.935h165.066c15.2 0 27.466 12.266 27.466 27.466v165.067c0 15.2-12.266 27.466-27.466 27.466H304.533c-15.2 0-27.467-12.267-27.467-27.466V304.4c0-15.066 12.267-27.466 27.467-27.466z"
            className="TranslationSvg-st0"
            stroke={color}
        />
        <path
            strokeWidth={37.333239999999996}
            d="m77.6 184.935 45.066-118.266c.934-2.267 4.134-2.267 5.067 0L172.4 184.935M91.733 155.468h66.8M327.066 356.934h120M387.066 326.935v30M417.066 356.934h0c0 49.734-40.267 90-90 90"
            className="TranslationSvg-st0"
            stroke={color}
        />
        <path
            strokeWidth={37.333239999999996}
            d="M357.066 356.934h0c0 49.734 40.266 90 90 90M56 316.001v70c0 22.133 17.866 40 40 40h100"
            className="TranslationSvg-st0"
            stroke={color}
        />
        <path
            strokeWidth={37.333239999999996}
            d="m166 396.001 30 30-30 30M455.999 196.002v-70c0-22.134-17.867-40-40-40h-100"
            className="TranslationSvg-st0"
            stroke={color}
        />
        <path
            strokeWidth={37.333239999999996}
            d="m346 116.002-30-30 30-30"
            className="TranslationSvg-st0"
            stroke={color}
        />
    </svg>
)

export default TranslationSvg
