import useAppUser from '../../../hooks/useAppUser'
import userActionEnums from '../../../enums/userActionEnums/userActionEnums'
import { useEffect } from 'react'
import HelpVideoModal from '../../organisms/HelpVideoModal/HelpVideoModal'
import { useDispatch, useSelector } from 'react-redux'
import {
	updateHelpAfterSetNativeLanguageAction,
	updateHelpAfterSetThemeAction,
} from '../../../redux/features/helpActionSlice'
import { setAppUser } from '../../../redux/features/appUserSlice'
import { useTour } from '@reactour/tour'
import { helpVideoLinks } from '../../../enums/pageGuideEnums/pageGuideEnums'
import { languagesEnums } from '../../../enums/languageEnums/languageEnums'
import { finishFirstTimeAction, setDisplayingAction } from '../../../redux/features/firstTimeActionSlice'
import useFirstTimeActions from '../../../hooks/useFirstTimeActions'

const FirstTimeHelpActions = () => {
	const { appUserData } = useAppUser()
	const dispatch = useDispatch()
	const { displayingAction } = useSelector(state => state.firstTimeAction)
	const { currentFirstTimeAction } = useFirstTimeActions()
	const tour = useTour()

	const updateAppUser = data => {
		if (data) {
			dispatch(setAppUser(data))
		}
	}

	useEffect(() => {
		if (currentFirstTimeAction === userActionEnums.helpAfterNativeLanguage) {
			dispatch(updateHelpAfterSetNativeLanguageAction()).then(res => {
				updateAppUser(res?.payload)
			})
			dispatch(setDisplayingAction(userActionEnums.helpAfterNativeLanguage))
		} else if (currentFirstTimeAction === userActionEnums.helpAfterUpdateTheme) {
			dispatch(updateHelpAfterSetThemeAction()).then(res => {
				updateAppUser(res?.payload)
			})
			dispatch(setDisplayingAction(userActionEnums.helpAfterUpdateTheme))
		}
	}, [currentFirstTimeAction])

	useEffect(() => {
		if (displayingAction === userActionEnums.helpAfterNativeLanguage) {
			const helpData = helpVideoLinks[userActionEnums.helpAfterNativeLanguage]
			const videos = helpData?.[appUserData?.setting?.nativeLanguage] || helpData?.[languagesEnums.ENGLISH_LANGUAGE]

			if (videos?.length === 0) {
				dispatch(setDisplayingAction(null))
				dispatch(finishFirstTimeAction(userActionEnums.helpAfterNativeLanguage))
			}
		} else if (displayingAction === userActionEnums.helpAfterUpdateTheme) {
			const helpData = helpVideoLinks[userActionEnums.helpAfterUpdateTheme]
			const videos = helpData?.[appUserData?.setting?.nativeLanguage] || helpData?.[languagesEnums.ENGLISH_LANGUAGE]

			if (videos?.length === 0) {
				dispatch(setDisplayingAction(null))
				dispatch(finishFirstTimeAction(userActionEnums.helpAfterUpdateTheme))
			}
		}
	}, [displayingAction])

	if (displayingAction === userActionEnums.helpAfterNativeLanguage) {
		const helpData = helpVideoLinks[userActionEnums.helpAfterNativeLanguage]
		const videos = helpData?.[appUserData?.setting?.nativeLanguage] || helpData?.[languagesEnums.ENGLISH_LANGUAGE]

		if (videos?.length > 0) {
			return (
				<HelpVideoModal
					videos={videos}
					onClose={() => {
						dispatch(setDisplayingAction(null))
						dispatch(finishFirstTimeAction(userActionEnums.helpAfterNativeLanguage))
					}}
				/>
			)
		}
	} else if (displayingAction === userActionEnums.helpAfterUpdateTheme) {
		const helpData = helpVideoLinks[userActionEnums.helpAfterUpdateTheme]
		const videos = helpData?.[appUserData?.setting?.nativeLanguage] || helpData?.[languagesEnums.ENGLISH_LANGUAGE]

		if (videos?.length > 0) {
			return (
				<HelpVideoModal
					videos={videos}
					onClose={() => {
						dispatch(setDisplayingAction(null))
						dispatch(finishFirstTimeAction(userActionEnums.helpAfterUpdateTheme))
						setTimeout(() => {
							tour.setSteps([
								{
									selector: "[data-tour='help-button']",
									content: <p>You can always watch videos here.</p>,
									stepInteraction: false,
								},
							])
							tour.setIsOpen(true)
						}, 10)
					}}
				/>
			)
		}
	}
	return null
}

export default FirstTimeHelpActions
