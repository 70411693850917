import { languagesEnums } from '../enums/languageEnums/languageEnums'
import {useSelector} from "react-redux";
import useAppUser from "./useAppUser";

const useTranslatedText = () => {
  const {appUserData} = useAppUser()
  const {selectedTranslationLanguage} = useSelector(state => state.translation)

  const direction = selectedTranslationLanguage?.key === languagesEnums.ENGLISH_LANGUAGE
    ? 'ltr'
    : appUserData?.setting?.nativeLanguageFontDirection

  return {
    dir: direction
  }
}

export default useTranslatedText