import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { v4 } from 'uuid'
import inquiryUseCase from "../../useCase/inquiryUseCase/inquiryUseCase";
import {
  deleteUserActionEnums, deleteUserStateEnums,
  inquiryActionEnums,
  inquiryStateEnums, resendCodeFlowTypeEnums,
  setPasswordActionEnums,
  setPasswordStateEnums
} from "../../enums/inquiryEnums/inquiryEnums";
import inquiryResendCodeUseCase from "../../useCase/inquiryResendCodeUseCase/inquiryResendCodeUseCase";
import inquiryResetPasswordUseCase from "../../useCase/inquiryResetPasswordUseCase/inquiryResetPasswordUseCase";
import deleteUserAccountUseCase from "../../useCase/deleteUserAccountUseCase/deleteUserAccountUseCase";
import logoutUserAccountUseCase from "../../useCase/logoutUserAccountUseCase/logoutUserAccountUseCase";
import { baseAsyncThunk } from "../baseAsyncThunk";

const initialState = {
  loading: false,
  authData: {
    platform: 'WEB',
    deviceId: v4(),
    username: '',
    password: '',
    rePassword: '',
    code: null,
    region: null,
    state: inquiryStateEnums.INQUIRY,
    phoneNumberPrefix: null,
    authenticationType: null
  },
  captchaToken: null,
  result: null,
  uid: null,
  timer: 120,
  countDown: '',
  error: '',
}

export const inquiry = baseAsyncThunk(
  'authentication/inquiry',
  async (arg, { getState }) => {
    const { authentication } = getState()
    const { authData, captchaToken } = authentication
    return await inquiryUseCase({ authData, captchaToken })
  },
)

export const inquiryResetPassword = baseAsyncThunk(
  'authentication/inquiryResetPassword',
  (arg, { getState }) => {
    const { authentication } = getState()
    const { authData, captchaToken } = authentication
    return inquiryResetPasswordUseCase({ authData, captchaToken })
  },
)

export const resendConfirmationCode = createAsyncThunk(
  'authentication/resendConfirmationCode',
  (arg, { getState }) => {
    const { authentication } = getState()
    return inquiryResendCodeUseCase({
      uid: authentication.uid,
      flowType: resendCodeFlowTypeEnums.INQUIRY_USER
    })
  }
)

export const deleteUserAccountPermanently = baseAsyncThunk(
  'authentication/deleteUserAccountPermanently',
  (arg, { getState }) => {
    const { authentication } = getState()
    return deleteUserAccountUseCase(authentication.authData)
  }
)

export const logoutUserAccount = createAsyncThunk(
  'authentication/logoutUserAccount',
  (arg, { getState }) => {
    const { authentication } = getState()
    return logoutUserAccountUseCase(authentication.authData)
  }
)

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    updateTime: (state) => {
      const newTimer = state.timer - 1
      if (state.timer > 0) {
        state.timer = newTimer
        const minutes = Math.floor(newTimer / 60)
        const seconds = (newTimer - minutes * 60).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })
        state.countDown = `${minutes}:${seconds}`
      }
    },
    resetTimer: (state) => {
      state.confirmationCodeSent = false
      state.timer = 120
      state.countDown = ''
      state.error = ''
      state.checkCodeResponse = null
    },
    setAuthData: (state, action) => {
      state.authData.username = action.payload?.username ?? state.authData.username
      state.authData.password = action.payload?.password ?? state.authData.password
      state.authData.rePassword = action.payload?.rePassword ?? state.authData.rePassword
      state.authData.code = action.payload?.code ?? state.authData.code
      state.authData.region = action.payload?.region ?? state.authData.region
      state.authData.state = action.payload?.state ?? state.authData.state
      state.authData.phoneNumberPrefix = action.payload?.phoneNumberPrefix ?? state.authData.phoneNumberPrefix
    },
    clearAuthenticationStates: (state) => {
      state.loading = false
      state.authData = {
        platform: 'WEB',
        deviceId: state.authData.deviceId,
        username: '',
        password: '',
        rePassword: '',
        code: null,
        region: null,
        state: inquiryStateEnums.INQUIRY,
        phoneNumberPrefix: null,
        authenticationType: null
      }
      state.result = null
      state.uid = null
      state.timer = 120
      state.countDown = ''
      state.error = ''
    },
    setCaptchaToken: (state, action) => {
      state.captchaToken = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(inquiry.pending, (state) => {
      state.loading = true
    })
    builder.addCase(inquiry.fulfilled, (state, action) => {
      state.loading = false

      switch (action.payload?.action) {
        case inquiryActionEnums.CONFIRM_MOBILE:
          state.authData.region = action.payload.payload.countryCode
          state.authData.username = action.payload.payload.phoneNumber
          state.authData.phoneNumberPrefix = action.payload.payload.phoneNumberPrefix
          state.authData.state = inquiryStateEnums.CONFIRMING_MOBILE
          break
        case inquiryActionEnums.CONFIRM_USERNAME:
          state.authData.authenticationType = action.payload.payload.authenticationType
          state.authData.username = action.payload.payload.username
          state.uid = action.payload.uid
          state.authData.state = inquiryStateEnums.CONFIRMING_USERNAME
          break
        case inquiryActionEnums.REQUEST_PASSWORD:
          state.authData.state = inquiryStateEnums.CONFIRMING_PASSWORD
          break
        case null:
          state.result = action.payload.payload
          state.authData = {
            ...state.authData,
            username: '',
            password: '',
            rePassword: '',
            code: null,
            region: null,
            phoneNumberPrefix: null,
          }
          state.authData.state = inquiryStateEnums.INQUIRED
          break
        default:
          state.authData.state = inquiryStateEnums.CONFIRMING_MOBILE
      }
    })
    builder.addCase(inquiry.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(inquiryResetPassword.pending, (state) => {
      state.loading = true
    })
    builder.addCase(inquiryResetPassword.fulfilled, (state, action) => {
      state.loading = false

      switch (action.payload?.action) {
        case setPasswordActionEnums.REQUEST_PASSWORD:
          state.authData.state = setPasswordStateEnums.SET_PASSWORD
          break
        case setPasswordActionEnums.CONFIRM_USERNAME:
          state.authData.authenticationType = action.payload.authenticationType
          state.authData.state = setPasswordStateEnums.CONFIRMING_USERNAME
          state.authData.username = action.payload.payload.username
          state.uid = action.payload.uid
          break
        case null:
          state.authData.password = ''
          state.authData.rePassword = ''
          state.authData.state = inquiryStateEnums.INQUIRY
          break
        default:
          state.authData.state = inquiryStateEnums.CONFIRMING_MOBILE
      }
    })
    builder.addCase(inquiryResetPassword.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(resendConfirmationCode.pending, (state) => {
      state.loading = true
    })
    builder.addCase(resendConfirmationCode.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(resendConfirmationCode.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(deleteUserAccountPermanently.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteUserAccountPermanently.fulfilled, (state, action) => {
      state.loading = false
      switch (action.payload?.action) {
        case deleteUserActionEnums.CONFIRM_USERNAME:
          state.authData.state = deleteUserStateEnums.CONFIRMING_USERNAME
          state.uid = action.payload?.uid
          break
        case deleteUserActionEnums.LOGOUT:
          state.authData.state = deleteUserStateEnums.CONFIRMED_USERNAME
          break
      }
    })
    builder.addCase(deleteUserAccountPermanently.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  },
})

export const { updateTime, resetTimer, setAuthData, clearAuthenticationStates, setCaptchaToken } = authenticationSlice.actions

export default authenticationSlice.reducer