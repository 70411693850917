import { useContext } from "react";
import colorsContext from "../../contexts/ColorsContext";
import SpeakerOffSvg from "../../icons/SpeakerOffSvg";
import componentStyle from './DictionaryVoiceButton.module.scss'
import useTextToSpeech from "../../hooks/useTextToSpeech";
import useAudioPlayer from "../../hooks/audio/useAudioPlayer";
import SpeakerOnSvg from "../../icons/SpeakerOnSvg";

const VoiceButton = ({ audioUrl, accent }) => {
  const { colors } = useContext(colorsContext)
  const { playing, setPlaying } = useAudioPlayer({ audioUrl, stopOnPause: true })

  return (
    <button className={componentStyle.voiceButton} onClick={() => setPlaying(!playing)}>
      <div className={componentStyle.icon}>
        <img
          className={componentStyle.flag}
          src={window.location.origin + `/assets/images/dictionary/${accent.toLowerCase()}-circle.png`}
          alt=""
        />
        <div className={componentStyle.speaker}>
          {playing
            ? <SpeakerOnSvg playing color={colors['C160']} />
            : <SpeakerOffSvg color={colors['C160']} />
          }
        </div>
      </div>
      <span className="res:fs-md" style={{ color: colors['C593'] }}>{accent}</span>
    </button>
  )
}

const DictionaryVoiceButton = ({ accent = 'US', phrase, audioUrl, setAudioUrl }) => {
  const { colors } = useContext(colorsContext)

  const voiceIds = {
    'US': 'Salli',
    'UK': 'Amy'
  }

  const { pendingTextToSpeech } = useTextToSpeech({
    audioUrl,
    text: phrase,
    voiceId: voiceIds[accent],
    setAudioUrl: (data) => {
      setAudioUrl(data)
    }
  })

  if (pendingTextToSpeech) {
    return (
      <div className={componentStyle.voiceButton} style={{ opacity: 0.5 }}>
        <div className={componentStyle.icon}>
          <img
            className={componentStyle.flag}
            src={window.location.origin + `/assets/images/dictionary/${accent.toLowerCase()}-circle.png`}
            alt=""
          />
          <div className={componentStyle.speaker}>
            <SpeakerOffSvg color={colors['C160']} />
          </div>
        </div>
        <span className="res:fs-md" style={{ color: colors['C593'] }}>{accent}</span>
      </div>
    )
  } else {
    return (
      <VoiceButton audioUrl={audioUrl} accent={accent} />
    )
  }
}

export default DictionaryVoiceButton