import styles from './backButton.module.scss'
import BackArrowSvg from '../../../icons/BackArrowSvg'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

const BackButton = ({ isNext, textButton, to }) => {
	return (
		<Link to={to} className={classNames(styles.backButton, { [styles.isNext]: isNext })}>
			<div>
				<BackArrowSvg color={'#575756'} />
			</div>
			<span>{textButton}</span>
		</Link>
	)
}

export default BackButton
