import serviceCall from "../../services";
import fetchExerciseMapperSwitch from "../../services/mapper/exercise/fetchExerciseMapperSwitch";
import {toNumber} from "lodash";

const sectionExerciseUseCase = async ({unit, section, sectionNumber, groupType}) => {
  const response = await serviceCall(
    'fetchSectionExercise',
    {unit, section, groupType},
    'cloud',
  )

  return {
    ...fetchExerciseMapperSwitch(response),
    unitNumber: toNumber(unit),
    sectionNumber: toNumber(sectionNumber),
  }
}

export default sectionExerciseUseCase