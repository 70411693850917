const exerciseTypeEnums = {
  chooseFromCategory: 'CHOOSE_FROM_CATEGORY',
  matchCase: 'MATCH_CASE',
  sayTheFollowing: 'SAY_THE_FOLLOWING',
  putInOrder: 'PUT_IN_ORDER',
  putInOrderConversation: 'PUT_IN_ORDER_CONVERSATION',
  multipleChoice: 'MULTIPLE_CHOICE',
  dragAndDrop: 'DRAG_AND_DROP',
  dragAndDropCategory: 'DRAG_AND_DROP_CATEGORY',
  dragAndDropConversation: 'DRAG_AND_DROP_CONVERSATION',
  comboBox: 'COMBO_BOX',
  comboPictionary: 'COMBO_PICTIONARY',
  typeTheAnswer: 'TYPE_THE_ANSWER'
}

export default exerciseTypeEnums