import componentStyle from "./AddToCategoryModal.module.scss"
import Modal from "../Modal";
import classNames from "classnames";
import React, {useContext, useEffect, useState} from "react";
import colorsContext from "../../contexts/ColorsContext";
import AddSvg from "../../icons/AddSvg";
import BackPageSvg from "../../icons/BackPageSvg";
import AddCategoryCard from "../AddCategory/AddCategoryCard";
import {useDispatch, useSelector} from "react-redux";
import {getCategories, getCategoryContent, moveFavorite, saveCategories} from "../../redux/features/favoriteSlice";
import {ALL} from "../../enums/globalEnums/globalEnums";
import {isEmpty} from "lodash";

const AddToCategoryModal = ({text = '', trackerNumber = '', closeModal}) => {
  const dispatch = useDispatch()
  const {colors} = useContext(colorsContext)
  const [isAddCategoryPage, setIsAddCategoryPage] = useState(false)
  const {categories} = useSelector(state => state.favorite)
  const {inputCategoryName, selectedCategoryColor} = useSelector(state => state.addCategoryCard)

  useEffect(() => {
    dispatch(getCategories({type: ALL}))
  }, [])

  const onSave = () => {
    dispatch(saveCategories({
      name: inputCategoryName,
      color: selectedCategoryColor
    })).then((res) => {
      dispatch(moveFavorite({
        categoryId: res.payload?.id,
        sentenceOrVocab: text,
        trackerNumber: trackerNumber
      })).then(() => {
        closeModal()
      })
    })
  }

  const renderFavoriteCategoryCard = () => {
    if (categories) {
      return categories?.filter(it => !isEmpty(it.id)).map((item, index) => {
        return (
          <button
            key={index}
            className={componentStyle.categoryItem}
            onClick={() => {
              dispatch(moveFavorite({
                categoryId: item?.id,
                sentenceOrVocab: text,
                trackerNumber: trackerNumber
              })).then(() => {
                closeModal()
              })
            }}
          >
            <div className={componentStyle.categoryColor} style={{background: item?.color ?? '#FFFFFF'}}/>
            <span
              className={classNames(componentStyle.categoryName, 'res:fs-sm')}
              style={{color: colors['C589']}}
            >
              {item?.categoryName}
            </span>
          </button>
        )
      })
    }
  }

  return (
    <Modal closeModal={closeModal} isCenter>
      <div className='modal-type-2'>
        {isAddCategoryPage
          ? (
            <div className={classNames(componentStyle.card, 'modal-card overflow-hidden p-3')}
                 style={{background: colors['C73']}}>
              <div className={componentStyle.cardHeader} style={{borderColor: colors['C589']}}>
                <button className={componentStyle.backButton}
                        onClick={() => setIsAddCategoryPage(false)}>
                  <BackPageSvg color={colors['C589']}/>
                </button>
                <p
                  className={classNames(componentStyle.headerTitle, 'res:fs-md')}
                  style={{color: colors['C589']}}
                >
                  New Category
                </p>
              </div>
              <AddCategoryCard onSave={onSave}/>
            </div>
          )
          : (
            <div className={classNames(componentStyle.card, 'modal-card overflow-hidden p-3')}
                 style={{background: colors['C73']}}>
              <div className={componentStyle.cardHeader} style={{borderColor: colors['C589'], color: colors['C589']}}>
                <p className={classNames(componentStyle.headerTitle, 'res:fs-md')}>Add to</p>
              </div>
              <div className={componentStyle.categories}>
                {renderFavoriteCategoryCard()}
              </div>
              <button
                className='bubble-add-btn position-absolute'
                onClick={() => setIsAddCategoryPage(!isAddCategoryPage)}
              >
                <span className='plus-icon'>
                  <AddSvg/>
                </span>
              </button>
            </div>
          )
        }
      </div>
    </Modal>
  )
}

export default AddToCategoryModal