import serviceCall from "../../services";
import userUnitStatusMapper from "../../services/mapper/statistics/userUnitStatusMapper";

const userUnitStatusUseCase = async ({unitId, numberOfRepetitions}) => {
  const response = await serviceCall(
    'fetchUserUnitStatusUseCase',
    {unitId},
    'cloud',
  )

  return userUnitStatusMapper(response, numberOfRepetitions)
}

export default userUnitStatusUseCase