import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import serviceCall from "../../services";

const initialState = {
    loading: false,
    data: null,
    error: '',
}

export const fetchWriting = createAsyncThunk(
    'writing/fetchWriting',
    ({unit}) => {
        return serviceCall(
            'fetchSection',
            {
                unit: unit,
                section: "WRITING_EXERCISE_11"
            },
            "cloud"
        )
    }
)

const writingSlice = createSlice({
    name: 'courseIntensity',
    initialState,
    reducers: {
        //
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWriting.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchWriting.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data
        })
        builder.addCase(fetchWriting.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    }
})

export const {} = writingSlice.actions

export default writingSlice.reducer