import PlayButtonPrimary from "./PlayButtonPrimary";
import useAudioPlaylist from "../../hooks/audio/useAudioPlaylist";

const PlayButtonPrimaryPlaylist = (
    { playlistUrls, playLimit, playing, handlePlaying, setCurrentIndex, iconColor, bgColor, borderColor }
) => {
    const { currentIndex } = useAudioPlaylist({
        playlistUrls, playLimit, playing, handlePlaying
    })

    return <PlayButtonPrimary
        iconColor={iconColor}
        bgColor={bgColor}
        borderColor={borderColor}
        playing={playing}
        setPlaying={handlePlaying}
    />
}

export default PlayButtonPrimaryPlaylist