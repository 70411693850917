import useLocalStorage from "./useLocalStorage";
import {useDispatch} from "react-redux";
import {setNewVersionAvailable} from "../redux/features/versionSlice";

const useUpdateVersion = () => {
  const [pwaVersion, setPwaVersion] = useLocalStorage('pwa-version', null)
  const dispatch = useDispatch()

  const checkVersion = (newVersion) => {
    if (newVersion) {
      if (!pwaVersion) {
        setPwaVersion(newVersion)
      } else if (pwaVersion !== newVersion) {
        dispatch(setNewVersionAvailable(newVersion))
      }
    }
  }

  return {checkVersion}
}

export default useUpdateVersion