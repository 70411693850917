import React, {useContext, useEffect, useRef, useState} from 'react'
import structureStyle from './../StructuredComponents.module.scss'
import componentStyle from './TableTemplate.module.scss'
import colorsContext from '../../../../contexts/ColorsContext'
import HtmlTextWrapper from '../../../../components/HtmlTextWrapper/HtmlTextWrapper'
import classNames from 'classnames'
import FullscreenSvg from '../../../../icons/FullscreenSvg'
import StructureImageComponent from '../Components/StructureImageComponent/StructureImageComponent'
import Modal from '../../../../components/Modal'
import OverlayScrollbar from '../../../../layouts/OverlayScrollbar'
import structuresContext from "../../../../contexts/StructuresContext";
import {robotTeacherScrollIntoViewByElement} from "../../../../operations/robotTeacherScrollIntoViewByElement";
import {useSelector} from "react-redux";
import { ContentTypesStructureEnums } from '../../../../enums/structureEnums/templateType'

const textRotationClassName = {
  'TEXT_ROTATE_90': componentStyle.rotated90,
  'TEXT_ROTATE_270': componentStyle.rotated270,
}

const textAlignClassName = {
  'LEFT': componentStyle.alignLeft,
  'CENTER': componentStyle.alignCenter,
  'RIGHT': componentStyle.alignRight,
}

const TableTemplate = ({template}) => {
  const {colors} = useContext(colorsContext)
  const [fullScreen, setFullScreen] = useState(false)
  const [loadedImagesCount, setLoadedImagesCount] = useState(0)
  const {scrolling} = useSelector(state => state.roboTeacher)
  const {isRoboTeacher, roboTeacherData} = useContext(structuresContext)
  const isHidden = (isRoboTeacher ?? false) ? ((roboTeacherData.currentTime ?? 0) < (template?.hiddenTime ?? 0)) : false
  const innerTableRef = useRef(null)

  const elRef = useRef(null)
  useEffect(() => {
    if (template?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isHidden])

  useEffect(() => {
    if (loadedImagesCount && innerTableRef) {
      innerTableRef.current.style.height = `${innerTableRef.current.firstChild.offsetHeight}px`
      innerTableRef.current.style.height = 'unset'
    }
  }, [loadedImagesCount])

  const renderTable = (cells, fromModal) => {
    const renderCellData = (cell, isHeader) => {
      if (cell.displayType === ContentTypesStructureEnums.IMAGE) {
        if (cell.data?.picture) {
          return (
            <div className={componentStyle.pictureContainer}>
              <StructureImageComponent
                picture={cell.data.picture}
                fromModal={fromModal}
                setImageLoaded={() => setLoadedImagesCount(old => old + 1)}
              />
            </div>
          )
        }
      } else {
        if (cell.data?.text) {
          return (
            <div className={classNames(componentStyle.textContainer, textRotationClassName[cell.displayType])}>
              <HtmlTextWrapper
                data={cell.data.text}
                textColor={isHeader ? colors['C2'] : colors['C23']}
              />
            </div>
          )
        }
      }
      return null
    }

    return (
      <div className={componentStyle.tableContainer} style={{borderColor: colors['C2']}}>
        <div className={componentStyle.outer}>
          <OverlayScrollbar>
            <div className={componentStyle.inner} ref={innerTableRef}>
              <div
                className={classNames(componentStyle.table, textAlignClassName[template.config.textAlign])}
                style={{
                  gridTemplateAreas: template.config.gridTemplateAreas,
                }}
              >
                {cells.filter(it => !!it.name).map((cell, i) => {
                  const isHeader = cell.name.startsWith('hh')
                  return (
                    <div
                      key={i} className={classNames(
                      componentStyle.tableCell,
                      {[componentStyle.tableHeaderCell]: isHeader},
                    )}
                      style={{
                        gridArea: cell.name,
                        background: isHeader ? 'transparent' : colors['C20'],
                        borderColor: colors['C2'],
                      }}
                    >
                      {renderCellData(cell, isHeader)}
                    </div>
                  )
                })}
              </div>
            </div>
          </OverlayScrollbar>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='container container-1'>
        <div
          ref={elRef}
          data-rt-id={template?.id}
          className={classNames(structureStyle.templateContainer, {['hide-element']: isHidden})}
          style={{background: colors['C180']}}
        >
          <div className={componentStyle.fullScreenButtonWrapper}>
            <button className={componentStyle.fullScreenButton} onClick={() => setFullScreen(true)}>
              <FullscreenSvg color={colors['C2']}/>
            </button>
          </div>
          {renderTable(template.data.cells, false)}
        </div>
      </div>
      {fullScreen &&
        <Modal closeModal={() => setFullScreen(false)}>
          <div className={classNames(componentStyle.tableModal)} onClick={e => {
            if (e.currentTarget === e.target) {
              setFullScreen(false)
            }
          }}>
            {renderTable(template.data.cells, true)}
          </div>
        </Modal>
      }
    </>
  )
}

export default TableTemplate