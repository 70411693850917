import React, {useContext, useEffect, useState} from 'react'
import style from './SpeechModal.module.scss'
import classNames from 'classnames'
import Modal from '../Modal'
import SpeechCircleButton from '../SpeechCircleButton/SpeechCircleButton'
import colorsContext from '../../contexts/ColorsContext'
import useSpeechToTextApi from "../../hooks/speechToText/useSpeechToTextApi";
import WarningSvg from "../../icons/WarningSvg";
import useTranslatedText from '../../hooks/useTranslatedText'

const SpeechModal = ({speechType, sentence, onRecordFinished, loading, given, nativeSentence}) => {
  const {colors} = useContext(colorsContext)
  const [isOn, setIsOn] = useState(false)
  const {dir} = useTranslatedText() // rtl, ltr
  
  const {
    startRecording,
    finishRecording,
    cancelRecording,
    recorderError,
    recording,
    speechResult,
    recordFinished,
    speechToTextLoading
  } = useSpeechToTextApi({speechType, given})

  useEffect(() => {
    setTimeout(() => {
      startRecording()
    }, 300)

    setTimeout(() => setIsOn(true),1000)
  }, [])

  useEffect(() => {
    let isMounted = true

    if (recordFinished && isMounted) {
      onRecordFinished(speechResult)
    }

    return () => {
      isMounted = false
    }
  }, [recordFinished])

  return (
    <Modal closeModal={() => {
      if (!loading) {
        if (recording) {
          cancelRecording()
        } else {
          onRecordFinished(null)
        }
      }
    }}>
      <div className={classNames(style.speechModal, 'modal-type-1')} style={{background: colors['C227']}}>
        {sentence &&
          <>
          <p dir={dir}
            className='text-center res:fs-md mb-3'
            style={{color: colors['C179']}}>{nativeSentence}</p>
          <p
            className='text-center res:fs-md mb-3'
            style={{color: colors['C179']}}
            dangerouslySetInnerHTML={{__html: sentence}}
          />
          </>
        }
        <div className={style.micButton}>
          <SpeechCircleButton
            recording={recording}
            bgColor='transparent'
            borderColor={colors['C229']}
            iconColor={colors['C229']}
            onClick={finishRecording}
            isLarge
            startLoading={!isOn}
          />
          {(loading || speechToTextLoading) &&
            <div className={style.loadingContainer}>
              <div
                className={classNames('spinner-border', style.spinner)}
                style={{
                  borderColor: colors['C229'],
                  borderRightColor: 'transparent',
                }}
              />
            </div>
          }
        </div>
        {recorderError
          ? <p className='text-center mt-4 py-1 px-2 rounded' style={{background: '#ffe5f1', color: '#e7026e'}}>
            <span className={style.warningIcon}><WarningSvg color='#e7026e'/></span> {recorderError}
          </p>
          : speechResult
            ? <p style={{color: colors['C179']}} className='text-center mt-4'>{speechResult}</p>
            : <span className={style.pendingDots} style={{color: colors['C179']}}>...</span>
        }
      </div>
    </Modal>
  )
}

export default SpeechModal