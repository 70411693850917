import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const matchingWordByWordMapper = (response) => {
  let matchCasesMap = {}

  response.data.matchResultDtos.forEach((dto) => {
    matchCasesMap[dto.correctAnswer] = {
      ...dto,
      userAnswerStatus: dto.userAnswerStatus ?? (dto.wordByWordResult?.every(it => it.match) ? AnswerStatusEnums.CORRECT : AnswerStatusEnums.INCORRECT)
    }
  })

  return {
    ...response,
    data: {
      ...response.data,
      matchCasesMap
    }
  }
}

export default matchingWordByWordMapper
