import { fetchVocabularies } from "../../services/cloudServices";
import serviceCall from "../../services";
import vocabularyFullTableMapper from "../../services/mapper/vocabulary/vocabularyFullTableMapper";
import vocabularySimpleTableMapper from "../../services/mapper/vocabulary/vocabularySimpleTableMapper";

const getVocabularyTableTypeUseCase = async ({ unitId, searchTerm, sortBy, sortType, paged, page, size, isFullTable = false, baseData }) => {
	const result = await serviceCall(
		'fetchVocabularies',
		{
			unitId, searchTerm, sortBy, sortType, paged, page, size
		},
		'cloud',
	)

	if (isFullTable) {
		return {
			...result,
			content: vocabularyFullTableMapper(result?.content, baseData),
		}
	} else {
		return {
			...result,
			content: vocabularySimpleTableMapper(result?.content)
		}
	}
}

export default getVocabularyTableTypeUseCase