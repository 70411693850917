import HomeSvg from "../../icons/HomeSvg";
import TodayStudySvg from "../../icons/TodayStudySvg";
import LevelUnitSvg from "../../icons/LevelUnitSvg";
import VocabularySvg from "../../icons/VocabularySvg";
import StatisticsSvg from "../../icons/StatisticsSvg";
import MemoryBoxSvg from "../../icons/MemoryBoxSvg";
import GameSvg from "../../icons/GameSvg";
import BlinkSvg from "../../icons/BlinkSvg";
import MatchUpSvg from "../../icons/MatchUpSvg";
import LimbicPowerSvg from "../../icons/LimbicPowerSvg";
import PhonoTypeSvg from "../../icons/PhonoTypeSvg";
import PhonoMetrixSvg from "../../icons/PhonoMetrixSvg";
import VellsoDualSvg from "../../icons/VellsoDualSvg";
import DictionarySvg from "../../icons/DictionarySvg";
import NewspaperSvg from "../../icons/NewspaperSvg";
import TedVideosSvg from "../../icons/TedVideosSvg";
import MyNotepadSvg from "../../icons/MyNotepadSvg";
import MessagesNotificationsSvg from "../../icons/MessagesNotificationsSvg";
import SettingsSvg from "../../icons/SettingsSvg";
import RadioStationSvg from "../../icons/RadioStationSvg";
import AccountRenewalSvg from "../../icons/AccountRenewalSvg";
import FavoritesSvg from "../../icons/FavoritesSvg";
import GrammarWrapSvg from "../../icons/GrammarWrapSvg";
import MoreExamplesSvg from "../../icons/MoreExamplesSvg";
import ConversationSvg from "../../icons/ConversationSvg";
import PictionarySvg from "../../icons/PictionarySvg";
import PointPlusSvg from "../../icons/PointPlusSvg";
import ReadingSvg from "../../icons/ReadingSvg";
import WritingSvg from "../../icons/WritingSvg";
import DrillingSvg from "../../icons/DrillingSvg";
import RoboChatSvg from "../../icons/RoboChatSvg";
import InNutshellSvg from "../../icons/InNutshellSvg";
import ReviewMistakesSvg from "../../icons/ReviewMistakesSvg";
import ListeningSvg from "../../icons/ListeningSvg";
import SpeakingSvg from "../../icons/SpeakingSvg";
import MemorizeSvg from "../../icons/MemorizeSvg";
import TableListSvg from "../../icons/TableListSvg";
import EssentialsSvg from "../../icons/EssentialsSvg";
import ToolsOutLineSvg from "../../icons/ToolsOutLineSvg";
import SectionsOutLineSvg from "../../icons/SectionsOutLineSvg";
import {languagesEnums} from "../languageEnums/languageEnums";
import userActionEnums from "../userActionEnums/userActionEnums";


export const pageGuidEnums = {
  LEARNING_TYPE_TEST_EXAM: 'LEARNING_TYPE_TEST_EXAM',
  MEMORY_TEST_EXAM: 'MEMORY_TEST_EXAM',
  MEMORY_TEST_RESULT: 'MEMORY_TEST_RESULT',
  HOME_PAGE: 'HOME_PAGE',
  TODAY_STUDY_PAGE: 'TODAY_STUDY_PAGE',
  LEVEL_AND_UNIT_PAGE: 'LEVEL_AND_UNIT_PAGE',
  LEVEL_AND_UNIT_OPEN: 'LEVEL_AND_UNIT_OPEN',
  LEVEL_AND_UNIT_TURN_OFF_UNITS: 'LEVEL_AND_UNIT_TURN_OFF_UNITS',
  LEVEL_UNIT_CONTENTS: 'LEVEL_UNIT_CONTENTS',
  BASICS_ALPHABET: 'BASICS_ALPHABET',
  BASICS_COMMON_DIGRAPHS: 'BASICS_COMMON_DIGRAPHS',
  BASICS_NUMBERS: 'BASICS_NUMBERS',
  BASICS_ORDINAL_NUMBERS: 'BASICS_ORDINAL_NUMBERS',
  CHOOSE_FROM_CATEGORY_EXERCISE: 'CHOOSE_FROM_CATEGORY_EXERCISE',
  MATCH_CASE_EXERCISE: 'MATCH_CASE_EXERCISE',
  SAY_THE_FOLLOWING_EXERCISE: 'SAY_THE_FOLLOWING_EXERCISE',
  PUT_IN_ORDER_CONVERSATION_EXERCISE: 'PUT_IN_ORDER_CONVERSATION_EXERCISE',
  PUT_IN_ORDER_EXERCISE: 'PUT_IN_ORDER_EXERCISE',
  TYPE_THE_ANSWER_EXERCISE: 'TYPE_THE_ANSWER_EXERCISE',
  MULTIPLE_CHOICE_EXERCISE: 'MULTIPLE_CHOICE_EXERCISE',
  COMBO_BOX_EXERCISE: 'COMBO_BOX_EXERCISE',
  COMBO_PICTIONARY_EXERCISE: 'COMBO_PICTIONARY_EXERCISE',
  DRAG_AND_DROP_EXERCISE: 'DRAG_AND_DROP_EXERCISE',
  DRAG_AND_DROP_CATEGORY_EXERCISE: 'DRAG_AND_DROP_CATEGORY_EXERCISE',
  DRAG_AND_DROP_CONVERSATION_EXERCISE: 'DRAG_AND_DROP_CONVERSATION_EXERCISE',
  MEMORY_BOX_HOME: 'MEMORY_BOX_HOME',
  MEMORY_BOX_ADD_NEW_CATEGORY: 'MEMORY_BOX_ADD_NEW_CATEGORY',
  MEMORY_BOX_ALL_CATEGORIES: 'MEMORY_BOX_ALL_CATEGORIES',
  MEMORY_BOX_NORMAL_CARD: 'MEMORY_BOX_NORMAL_CARD',
  MEMORY_BOX_SUPER_MEMORY_CARD: 'MEMORY_BOX_SUPER_MEMORY_CARD',
  MEMORY_BOX_FROM_UNITS_BOXES: 'MEMORY_BOX_FROM_UNITS_BOXES',
  MEMORY_BOX_FROM_UNITS_LIST_OF_CARDS: 'MEMORY_BOX_FROM_UNITS_LIST_OF_CARDS',
  MEMORY_BOX_CUSTOM_CATEGORY_BOXES: 'MEMORY_BOX_CUSTOM_CATEGORY_BOXES',
  MEMORY_BOX_CUSTOM_CATEGORY_LIST_OF_CARDS: 'MEMORY_BOX_CUSTOM_CATEGORY_LIST_OF_CARDS',
  MEMORY_BOX_ADD_CARD: 'MEMORY_BOX_ADD_CARD',
  DUAL_GAME_PLAY: 'DUAL_GAME_PLAY',
  DUAL_GAME_OFFLINE_SELECT_UNITS_PAGE: 'DUAL_GAME_OFFLINE_SELECT_UNITS_PAGE',
  DUAL_GAME_ONLINE_FIRST_PAGE: 'DUAL_GAME_ONLINE_FIRST_PAGE',
  DUAL_GAME_ONLINE_PLAY_RANDOM: 'DUAL_GAME_ONLINE_PLAY_RANDOM',
  DUAL_GAME_ONLINE_SEND_REQUEST: 'DUAL_GAME_ONLINE_SEND_REQUEST',
  GAME_BLINK: 'GAME_BLINK',
  GAME_MATCH_UP: 'GAME_MATCH_UP',
  GAME_LIMBIC_POWER: 'GAME_LIMBIC_POWER',
  GAME_PHONOMETRIX: 'GAME_PHONOMETRIX',
  GAME_PHONOTYPE: 'GAME_PHONOTYPE',
  GRAMMAR_WRAP_SECTION: 'GRAMMAR_WRAP_SECTION',
  MORE_EXAMPLES_SECTION: 'MORE_EXAMPLES_SECTION',
  CONVERSATION_MAIN: 'CONVERSATION_MAIN',
  CONVERSATION_MEMORIZE: 'CONVERSATION_MEMORIZE',
  CONVERSATION_ROLE_PLAY: 'CONVERSATION_ROLE_PLAY',
  PICTIONARY_SECTION: 'PICTIONARY_SECTION',
  POINT_PLUS_SECTION: 'POINT_PLUS_SECTION',
  READING_SECTION: 'READING_SECTION',
  READING_WARMUP: 'READING_WARMUP',
  WRITING_SECTION: 'WRITING_SECTION',
  DRILLING_SECTION: 'DRILLING_SECTION',
  ROBOCHAT_SECTION: 'ROBOCHAT_SECTION',
  CHAT_WITH_AI: 'CHAT_WITH_AI',
  IN_A_NUTSHELL_SECTION: 'IN_A_NUTSHELL_SECTION',
  GAME_SECTION: 'GAME_SECTION',
  REVIEW_MISTAKES_SECTION: 'REVIEW_MISTAKES_SECTION',
  STATISTICS_SECTION: 'STATISTICS_SECTION',
  ROBOT_TEACHER_SECTION: 'ROBOT_TEACHER_SECTION',
  TOOLS_SPELLING_SENTENCE: 'TOOLS_SPELLING_SENTENCE',
  TOOLS_MENU: 'TOOLS_MENU',
  TOOLS_SPEAKING_SENTENCE: 'TOOLS_SPEAKING_SENTENCE',
  TOOLS_LISTENING_SENTENCE: 'TOOLS_LISTENING_SENTENCE',
  TOOLS_MEMORIZE_SENTENCE: 'TOOLS_MEMORIZE_SENTENCE',
  TOOLS_SPELLING_WORD_PYRAMID: 'TOOLS_SPELLING_WORD_PYRAMID',
  TOOLS_SPELLING_WORD_LINEAR: 'TOOLS_SPELLING_WORD_LINEAR',
  TOOLS_SPELLING_WORD_UNSCRAMBLE: 'TOOLS_SPELLING_WORD_UNSCRAMBLE',
  TOOLS_SPEAKING_WORD: 'TOOLS_SPEAKING_WORD',
  TOOLS_LISTENING_WORD: 'TOOLS_LISTENING_WORD',
  TOOLS_MEMORIZE_WORD: 'TOOLS_MEMORIZE_WORD',
  ESSENTIALS: 'ESSENTIALS',
  FAVORITES_MAIN_PAGE: 'FAVORITES_MAIN_PAGE',
  FAVORITES_CONTENT_PAGE: 'FAVORITES_CONTENT_PAGE',
  CONVERSATION_ARCHIVE_MAIN_PAGE: 'CONVERSATION_ARCHIVE_MAIN_PAGE',
  EXERCISE_MAIN_PAGE: 'EXERCISE_MAIN_PAGE',
  VOCABULARY_SHOW_BY_UNIT_MAIN: 'VOCABULARY_SHOW_BY_UNIT_MAIN',
  VOCABULARY_SHOW_BY_UNIT_WORDS: 'VOCABULARY_SHOW_BY_UNIT_WORDS',
  VOCABULARY_SHOW_BY_UNIT_PHRASES: 'VOCABULARY_SHOW_BY_UNIT_PHRASES',
  VOCABULARY_SHOW_ALL_WORDS: 'VOCABULARY_SHOW_ALL_WORDS',
  VOCABULARY_SHOW_ALL_PHRASES: 'VOCABULARY_SHOW_ALL_PHRASES',
  STATISTICS_MAIN: 'STATISTICS_MAIN',
  DICTIONARY: 'DICTIONARY',
  RADIO_STATION: 'RADIO_STATION',
  NEWSPAPER: 'NEWSPAPER',
  TED_VIDEOS: 'TED_VIDEOS',
  OFFLINE_GAME_RECORDS: 'OFFLINE_GAME_RECORDS',
  AI_TEACHER: 'AI_TEACHER',
  READING_MAIN_PAGE: 'READING_MAIN_PAGE',
  CONVERSATION_INTRO: 'CONVERSATION_INTRO',
  SETTINGS_NOTIFICATIONS: 'SETTINGS_NOTIFICATIONS',
  SETTINGS_MAIN_PAGE: 'SETTINGS_MAIN_PAGE',
  MESSAGES_AND_NOTIFICATIONS: 'MESSAGES_AND_NOTIFICATIONS',
  ACCOUNT_RENEWAL: 'ACCOUNT_RENEWAL',
  CONTACT_US: 'CONTACT_US',
  HELP_AND_GUIDE: 'HELP_AND_GUIDE',
  ROLE_PLAY_BEFORE_SELECTION: 'ROLE_PLAY_BEFORE_SELECTION',
  MEMORIZE_BEFORE_SELECTION: 'MEMORIZE_BEFORE_SELECTION',
  STUDY_PREFERENCES_MAIN_PAGE: 'STUDY_PREFERENCES_MAIN_PAGE',
  SETTINGS_STUDY_PREFERENCES_STUDY_REMINDER: 'SETTINGS_STUDY_PREFERENCES_STUDY_REMINDER',
  SETTINGS_STUDY_PREFERENCES_STUDY_DIFFICULTY_LEVEL: 'SETTINGS_STUDY_PREFERENCES_STUDY_DIFFICULTY_LEVEL',
  MY_NOTEPAD_MAIN_PAGE: 'MY_NOTEPAD_MAIN_PAGE',
  MY_NOTEPAD_ADD_NEW_FOLDER: 'MY_NOTEPAD_ADD_NEW_FOLDER',
  MY_NOTEPAD_ADD_NEW_NOTE: 'MY_NOTEPAD_ADD_NEW_NOTE',
}

export const helpAndGuideList = [
  {
    title: "Home",
    icon: (props) => <HomeSvg {...props} />,
    key: pageGuidEnums.HOME_PAGE
  },
  {
    title: "Study Plan",
    icon: (props) => <TodayStudySvg {...props} />,
    key: pageGuidEnums.TODAY_STUDY_PAGE
  },
  {
    title: "Levels & Units",
    icon: (props) => <LevelUnitSvg {...props} />,
    children: [
      {
        title: "Levels & Units Closed 01",
        key: pageGuidEnums.LEVEL_AND_UNIT_PAGE
      },
      {
        title: "Levels & Units Opened 02",
        key: pageGuidEnums.LEVEL_AND_UNIT_OPEN
      }
    ]
  },
  {
    title: "Vocabulary",
    icon: (props) => <VocabularySvg {...props} />,
    children: [
      {
        title: "Words",
        children: [
          {
            title: "Show by Unit",
            key: pageGuidEnums.VOCABULARY_SHOW_BY_UNIT_WORDS
          },
          {
            title: "Show all",
            key: pageGuidEnums.VOCABULARY_SHOW_BY_UNIT_WORDS
          },
        ]
      },
      {
        title: "Phrases",
        children: [
          {
            title: "Show by Unit",
            key: pageGuidEnums.VOCABULARY_SHOW_BY_UNIT_PHRASES
          },
          {
            title: "Show all",
            key: pageGuidEnums.VOCABULARY_SHOW_BY_UNIT_PHRASES
          },
        ]
      },
    ]
  },
  {
    title: "Statistics",
    icon: (props) => <StatisticsSvg {...props} />,
    key: pageGuidEnums.STATISTICS_MAIN
  },
  {
    title: "Memory Box",
    icon: (props) => <MemoryBoxSvg {...props} />,
    key: pageGuidEnums.MEMORY_BOX_HOME
  },
  {
    title: "Games",
    icon: (props) => <GameSvg {...props} />,
    children: [
      {
        title: "Blink",
        icon: (props) => <BlinkSvg {...props} />,
        key: pageGuidEnums.GAME_BLINK
      },
      {
        title: "Match-up",
        icon: (props) => <MatchUpSvg {...props} />,
        key: pageGuidEnums.GAME_MATCH_UP
      },
      {
        title: "Limbic Power",
        icon: (props) => <LimbicPowerSvg {...props} />,
        key: pageGuidEnums.GAME_LIMBIC_POWER
      },
      {
        title: "PhonoMetrix",
        icon: (props) => <PhonoMetrixSvg {...props} />,
        key: pageGuidEnums.GAME_PHONOMETRIX
      },
      {
        title: "PhonoType",
        icon: (props) => <PhonoTypeSvg {...props} />,
        key: pageGuidEnums.GAME_PHONOTYPE
      },
      {
        title: "Vellso Dual",
        icon: (props) => <VellsoDualSvg {...props} />,
        key: pageGuidEnums.DUAL_GAME_PLAY
      },
    ]
  },
  {
    title: 'Dictionary',
    icon: (props) => <DictionarySvg {...props} />,
    key: pageGuidEnums.DICTIONARY
  },
  {
    title: 'Radio Station',
    icon: (props) => <RadioStationSvg {...props} />,
    key: pageGuidEnums.RADIO_STATION
  },
  {
    title: 'Newspaper',
    icon: (props) => <NewspaperSvg {...props} />,
    key: pageGuidEnums.NEWSPAPER
  },
  {
    title: 'TED Videos',
    icon: (props) => <TedVideosSvg {...props} />,
    key: pageGuidEnums.TED_VIDEOS
  },
  {
    title: 'My Notepad',
    icon: (props) => <MyNotepadSvg {...props} />,
    key: pageGuidEnums.MY_NOTEPAD_MAIN_PAGE
  },
  {
    title: 'Messages & Notifications',
    icon: (props) => <MessagesNotificationsSvg {...props} />,
    key: pageGuidEnums.MESSAGES_AND_NOTIFICATIONS
  },
  {
    title: 'Settings',
    icon: (props) => <SettingsSvg {...props} />,
    children: [
      {
        title: 'Personal Info',
        icon: (props) => <MessagesNotificationsSvg {...props} />,
        key: pageGuidEnums.MESSAGES_AND_NOTIFICATIONS
      },
    ]
  },
  {
    title: 'Account Renewal',
    icon: (props) => <AccountRenewalSvg {...props} />,
    key: pageGuidEnums.ACCOUNT_RENEWAL
  },
  {
    title: 'Favorites',
    icon: (props) => <FavoritesSvg {...props} />,
    key: pageGuidEnums.FAVORITES_MAIN_PAGE
  },
  {
    title: "Unit's Sections",
    icon: (props) => <SectionsOutLineSvg {...props} />,
    children: [
      {
        title: 'GrammarWrap',
        icon: (props) => <GrammarWrapSvg {...props} />,
        key: pageGuidEnums.GRAMMAR_WRAP_SECTION
      },
      {
        title: 'More Examples',
        icon: (props) => <MoreExamplesSvg {...props} />,
        key: pageGuidEnums.MORE_EXAMPLES_SECTION
      },
      {
        title: 'Conversation',
        icon: (props) => <ConversationSvg {...props} />,
        children: [
          {
            title: 'Conversation',
            key: pageGuidEnums.CONVERSATION_MAIN
          },
          {
            title: 'Memorize',
            key: pageGuidEnums.CONVERSATION_MEMORIZE
          },
          {
            title: 'Role-play',
            key: pageGuidEnums.CONVERSATION_ROLE_PLAY
          },
        ]
      },
      {
        title: 'Pictionary',
        icon: (props) => <PictionarySvg {...props} />,
        key: pageGuidEnums.PICTIONARY_SECTION
      },
      {
        title: 'Point Plus',
        icon: (props) => <PointPlusSvg {...props} />,
        key: pageGuidEnums.POINT_PLUS_SECTION
      },
      {
        title: 'Reading',
        icon: (props) => <ReadingSvg {...props} />,
        key: pageGuidEnums.READING_SECTION
      },
      {
        title: 'Writing',
        icon: (props) => <WritingSvg {...props} />,
        key: pageGuidEnums.WRITING_SECTION
      },
      {
        title: 'Drill',
        icon: (props) => <DrillingSvg {...props} />,
        key: pageGuidEnums.DRILLING_SECTION
      },
      {
        title: 'RoboChat ',
        icon: (props) => <RoboChatSvg {...props} />,
        key: pageGuidEnums.ROBOCHAT_SECTION
      },
      {
        title: 'In a Nutshell',
        icon: (props) => <InNutshellSvg {...props} />,
        key: pageGuidEnums.IN_A_NUTSHELL_SECTION
      },
      {
        title: 'Review Mistakes',
        icon: (props) => <ReviewMistakesSvg {...props} />,
        key: pageGuidEnums.REVIEW_MISTAKES_SECTION
      },
      {
        title: 'Statistics',
        icon: (props) => <StatisticsSvg {...props} />,
        key: pageGuidEnums.STATISTICS_SECTION
      },
    ]
  },
  {
    title: "Tools",
    icon: (props) => <ToolsOutLineSvg {...props} />,
    children: [
      {
        title: 'Listening',
        icon: (props) => <ListeningSvg {...props} />,
        children: [
          {
            title: "Listening Word",
            key: pageGuidEnums.TOOLS_LISTENING_WORD
          },
          {
            title: "Listening Sentence",
            key: pageGuidEnums.TOOLS_LISTENING_SENTENCE
          },
        ]
      },
      {
        title: 'Speaking',
        icon: (props) => <SpeakingSvg {...props} />,
        children: [
          {
            title: "Speaking Word",
            key: pageGuidEnums.TOOLS_SPEAKING_WORD
          },
          {
            title: "Speaking Sentence",
            key: pageGuidEnums.TOOLS_SPEAKING_SENTENCE
          },
        ]
      },
      {
        title: 'Spelling',
        icon: (props) => <SpeakingSvg {...props} />,
        children: [
          {
            title: "Spelling Word",
            children: [
              {
                title: 'Pyramid',
                key: pageGuidEnums.TOOLS_SPELLING_WORD_PYRAMID
              },
              {
                title: 'Linear',
                key: pageGuidEnums.TOOLS_SPELLING_WORD_LINEAR
              },
              {
                title: 'Unscramble',
                key: pageGuidEnums.TOOLS_SPELLING_WORD_UNSCRAMBLE
              }
            ]
          },
          {
            title: "Spelling Sentence",
            key: pageGuidEnums.TOOLS_SPELLING_SENTENCE
          },
        ]
      },
      {
        title: 'Memorize',
        icon: (props) => <MemorizeSvg {...props} />,
        children: [
          {
            title: "Memorize Word",
            key: pageGuidEnums.TOOLS_MEMORIZE_WORD
          },
          {
            title: "Memorize Sentence",
            key: pageGuidEnums.TOOLS_MEMORIZE_SENTENCE
          },
        ]
      },
      {
        title: 'Contents',
        icon: (props) => <TableListSvg {...props} />,
        key: pageGuidEnums.LEVEL_UNIT_CONTENTS
      },
      {
        title: 'Essentials',
        icon: (props) => <EssentialsSvg {...props} />,
        key: pageGuidEnums.ESSENTIALS
      },
    ]
  },
]

export const helpVideoLinks = {
  [pageGuidEnums.HOME_PAGE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'Home یا صفحهٔ اصلی در Vellso',
          videoLink: 'https://www.namasha.com/embed/RxsgdL14',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'راهنمای برنامه ریزی تحصیلی (Study Plan)',
          videoLink: 'https://www.namasha.com/embed/cLLwQFKG',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'برچسب درس‌ها',
          videoLink: 'https://www.namasha.com/embed/gEItUYgS',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'الصفحة الرئيسية (Home)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/hac2h41/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'قسم Study Plan',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/mrpneuf/vt/frame',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'تسميات الوحدات',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/tqg0774/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.LEVEL_AND_UNIT_PAGE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/1erxdZhE',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'ممیزات الصفحة Levels & Units',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/fqfn2v1/vt/frame',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ]
    },
  [pageGuidEnums.LEVEL_AND_UNIT_OPEN]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/1erxdZhE',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'ممیزات الصفحة Levels & Units',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/fqfn2v1/vt/frame',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ]
    },
  [pageGuidEnums.GAME_SECTION]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/r3I9tbDL',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم الألعاب (Games)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/nes28bc/vt/frame',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ]
    },

  [pageGuidEnums.TODAY_STUDY_PAGE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/cLLwQFKG',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]:[
        {
          title: 'قسم Study Plan',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/mrpneuf/vt/frame',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ]
    },
  [pageGuidEnums.GRAMMAR_WRAP_SECTION]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'بخش اول: یادگیری گرامر به زبان ساده و قابل فهم در Grammar Wrap',
          videoLink: 'https://www.namasha.com/embed/HcKnn7HI',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: ' قسم النحو (Grammar Wrap)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/fzkxhnc/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.MORE_EXAMPLES_SECTION]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: ' بخش دوم: مثال های کاربردی برای درک بهتر گرامر هر درس در More Examples',
          videoLink: 'https://www.namasha.com/embed/jbUCi9bJ',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم More Examples',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ykx0prn/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.CONVERSATION_MAIN]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'بخش سوم: مکالمهٔ موضوع محور همراه با تصویرسازی برای درک بهتر',
          videoLink: 'https://www.namasha.com/embed/LBP9a7BX',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم المحادثة (Conversation)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/kuud5yz/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.CONVERSATION_INTRO]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'بخش سوم: مکالمهٔ موضوع محور همراه با تصویرسازی برای درک بهتر',
          videoLink: 'https://www.namasha.com/embed/LBP9a7BX',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم المحادثة (Conversation)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/kuud5yz/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.CONVERSATION_MEMORIZE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'بخش Memorize در مکالمه برای تقویت حافظه',
          videoLink: 'https://www.namasha.com/embed/bYoh7kdg',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم Memorize في المحادثة أو Conversation',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/eyg64v7/vt/frame',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.CONVERSATION_ROLE_PLAY]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: ' role play- شبیه‌ساز مکالمهٔ واقعی با امکان ضبط صدا',
          videoLink: 'https://www.namasha.com/embed/9guBBql2',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم Roleplay في المحادثة أو Conversation',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ojy1031/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.SAY_THE_FOLLOWING_EXERCISE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: '',
      [languagesEnums.ENGLISH_LANGUAGE]: ''
    },
  [pageGuidEnums.PICTIONARY_SECTION]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'بخش یازدهم: یادگیری کلمات جدید هر درس به همراه تصویر',
          videoLink: 'https://www.namasha.com/embed/APf1WpIb',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم Pictionary',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/tdqz0k6/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.POINT_PLUS_SECTION]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'بخش سیزدهم: یادگیری نکات انگلیسی به‌همراه بالا بردن اطلاعات عمومی',
          videoLink: 'https://www.namasha.com/embed/LuLjiVlk',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم Point Plus',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/wsbw142/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },

  [pageGuidEnums.READING_SECTION]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'بخش پانزدهم: تقویت مهارت Reading یا خواندن به روش اصولی و پیشرفته',
          videoLink: 'https://www.namasha.com/embed/x5IeJWax',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم Reading',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/slmpb2o/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.READING_MAIN_PAGE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'بخش پانزدهم: تقویت مهارت Reading یا خواندن به روش اصولی و پیشرفته',
          videoLink: 'https://www.namasha.com/embed/x5IeJWax',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم Reading',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/slmpb2o/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },

  [pageGuidEnums.WRITING_SECTION]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'بخش هفدهم: تقویت گام به گام مهارت نوشتاری در انگلیسی در بخش Writing',
          videoLink: 'https://www.namasha.com/embed/kgou18mh',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم الكتابة (Writing)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/bfw7dg0/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.DRILLING_SECTION]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'بخش هجدهم: با این تمرین مطالب هر درس رو توی مغزت حک کن!',
          videoLink: 'https://www.namasha.com/embed/ek3sEtuV',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم Drilling',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/qvllxy7/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.ROBOCHAT_SECTION]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'بخش نوزدهم: چت با ربات ولسو',
          videoLink: 'https://www.namasha.com/embed/Kos73RYk',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم RoboChat',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/swf6zp2/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.IN_A_NUTSHELL_SECTION]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'بخش بیستم: مرور کلی مطالب هر درس در انتهای آن',
          videoLink: 'https://www.namasha.com/embed/JsSXitC3',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم In a Nutshell',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/gnq2u92/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.REVIEW_MISTAKES_SECTION]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: ' بخش بیست و دو: در بخش Review mistakes اشتباهاتت رو جبران کن!',
          videoLink: 'https://www.namasha.com/embed/RzGGLW7s',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/PSU8hqrK',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم Review Mistakes',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/cea7l3e/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'شريط الأدوات في أعلى الصفحة (Toolbar)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ltni671/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.TOOLS_SPELLING_WORD_PYRAMID]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: 'https://www.namasha.com/embed/MAzmQ6Bq',
      [languagesEnums.ARABIC_LANGUAGE]: 'https://www.aparat.com/video/video/embed/videohash/nhcozx0/vt/frame'
    },
  [pageGuidEnums.TOOLS_SPELLING_WORD_LINEAR]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: 'https://www.namasha.com/embed/H6vUdFbn',
      [languagesEnums.ARABIC_LANGUAGE]: 'https://www.aparat.com/video/video/embed/videohash/cckat1i/vt/frame'
    },
  [pageGuidEnums.TOOLS_SPELLING_WORD_UNSCRAMBLE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: 'https://www.namasha.com/embed/JQLGqrna',
      [languagesEnums.ARABIC_LANGUAGE]: 'https://www.aparat.com/video/video/embed/videohash/hma760m/vt/frame'
    },
  [pageGuidEnums.TOOLS_LISTENING_SENTENCE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: 'https://www.namasha.com/embed/dut1yZlB',
      [languagesEnums.ARABIC_LANGUAGE]: 'https://www.aparat.com/video/video/embed/videohash/bvj7gkn/vt/frame'
    },
  [pageGuidEnums.TOOLS_LISTENING_WORD]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: 'https://www.namasha.com/embed/dut1yZlB',
      [languagesEnums.ARABIC_LANGUAGE]: 'https://www.aparat.com/video/video/embed/videohash/bvj7gkn/vt/frame'
    },
  [pageGuidEnums.TOOLS_MEMORIZE_SENTENCE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: 'https://www.namasha.com/embed/vlhKEmad',
      [languagesEnums.ARABIC_LANGUAGE]: 'https://www.aparat.com/video/video/embed/videohash/gieaqn3/vt/frame'
    },
  [pageGuidEnums.TOOLS_MEMORIZE_WORD]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: 'https://www.namasha.com/embed/vlhKEmad',
      [languagesEnums.ARABIC_LANGUAGE]: 'https://www.aparat.com/video/video/embed/videohash/gieaqn3/vt/frame'
    },
  [pageGuidEnums.TOOLS_MENU]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'با امکاناتی که توی درس در اختیارتونه، آشنا شید.',
          videoLink: 'https://www.namasha.com/embed/4dwr2jIl',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم Tools',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/gwe192z/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.TOOLS_MEMORIZE_WORD]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/vlhKEmad',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
        title: 'قسم Memorize في Tools',
        videoLink: 'https://www.aparat.com/video/video/embed/videohash/gieaqn3/vt/frame'  ,
        direction: 'rtl',
        videoCover: window.location.origin + '/assets/images/vellso-logo.png',
      }
      ]
    },
  [pageGuidEnums.TOOLS_LISTENING_SENTENCE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/dut1yZlB',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/bvj7gkn/vt/frame',
          direction: 'rtl'
        }
      ]
    },
  [pageGuidEnums.TOOLS_LISTENING_WORD]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/dut1yZlB',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/bvj7gkn/vt/frame',
          direction: 'rtl'
        }
      ]
    },
  [pageGuidEnums.TOOLS_MEMORIZE_SENTENCE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/vlhKEmad',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]:[
        {
          title: 'قسم Memorize في Tools',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/gieaqn3/vt/frame'  ,
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ]
    },

  [pageGuidEnums.TOOLS_SPEAKING_SENTENCE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/VAQrYoEA',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/mxc2924/vt/frame',
          direction: 'rtl'
        }
      ]
    },
  [pageGuidEnums.TOOLS_SPEAKING_WORD]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/VAQrYoEA',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/mxc2924/vt/frame',
          direction: 'rtl'
        }
      ]
    },
  [pageGuidEnums.TOOLS_SPELLING_SENTENCE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/NYGqHHlq',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم Spelling Sentences في Tools',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/kcmy6m4/vt/frame',
          direction: 'rtl'
        }
      ]
    },
  [pageGuidEnums.TOOLS_SPELLING_WORD_LINEAR]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'تمرین خطی، دیکته‌تون رو تقویت می‌کنه.',
          videoLink: 'https://www.namasha.com/embed/b4cXHQ2M',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'تمرين التهجئة بطريقة خطية (Linear)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/cckat1i/vt/frame',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        }
      ]
    },
  [pageGuidEnums.TOOLS_SPELLING_WORD_UNSCRAMBLE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'با چیدن حروف توی دیکته بهتر بشیم.',
          videoLink: 'https://www.namasha.com/embed/Cl6F5KXm',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'تمرين التهجئة عن طريق ترتيب الحروف المبعثرة (Unscramble)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/hma760m/vt/frame',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
      ]
    },
  [pageGuidEnums.TOOLS_SPELLING_WORD_PYRAMID]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'با تمرین هرمی، دیکته‌ت رو تقویت کن.',
          videoLink: 'https://www.namasha.com/embed/WeruJ4BU',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'تمرين التهجئة بطريقة هرمية (Pyramid)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/nhcozx0/vt/frame',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
      ]
    },
  [userActionEnums.helpAfterNativeLanguage]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/LelkcYYc',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        // {
        //   title: 'چگونه از ابزار های ولسو استفاده کنیم؟',
        //   videoLink: 'https://www.namasha.com/embed/z9PAtUFr',
        //   videoCover: window.location.origin + '/assets/images/about/WhereAreWe.png',
        //   direction: 'rtl'
        // },
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/ftl6f1g/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [userActionEnums.helpAfterUpdateTheme]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: 'Home یا صفحهٔ اصلی در Vellso',
          videoLink: 'https://www.namasha.com/embed/RxsgdL14',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'راهنمای Study plan',
          videoLink: 'https://www.namasha.com/embed/cLLwQFKG',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'برچسب درس‌ها',
          videoLink: 'https://www.namasha.com/embed/gEItUYgS',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'الصفحة الرئيسية (Home)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/hac2h41/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
        {
          title: 'قسم Study Plan',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/mrpneuf/vt/frame',
          direction: 'rtl',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
        },
        {
          title: 'تسميات الوحدات',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/tqg0774/vt/frame',
          videoCover: window.location.origin + '/assets/images/vellso-logo.png',
          direction: 'rtl'
        },
      ]
    },
  [pageGuidEnums.ACCOUNT_RENEWAL]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/j2FD7WLj',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'اشتراك الشراء (Account Renewal)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/dcxz480/vt/frame',
          direction: 'rtl'
        }
      ]
    },
  [pageGuidEnums.MY_NOTEPAD_MAIN_PAGE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/RkuXKMhc',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم My Notepad',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/bvqib29/vt/frame',
          direction: 'rtl'
        }
      ]
    },
  [pageGuidEnums.SETTINGS_MAIN_PAGE]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/2QtwW2ZL',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم Settings',
          videoLink: "https://www.aparat.com/video/video/embed/videohash/ams15vu/vt/frame",
          direction: 'rtl'
        }
      ]
    },
  [pageGuidEnums.STATISTICS_SECTION]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/DfmfrFql',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم الإحصاءات (Statistics)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/shb1p38/vt/frame',
          direction: 'rtl'
        }
      ]
    },
  [pageGuidEnums.VOCABULARY_SHOW_BY_UNIT_MAIN]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/zMqq2PdD',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم المفردات (Vocabulary)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/adfi85a/vt/frame?autoplay=true',
          direction: 'rtl'
        }
      ]
    },
  [pageGuidEnums.VOCABULARY_SHOW_BY_UNIT_WORDS]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/zMqq2PdD',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/adfi85a/vt/frame?autoplay=true',
          direction: 'rtl'
        }
      ]
    },
  [pageGuidEnums.VOCABULARY_SHOW_BY_UNIT_PHRASES]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/zMqq2PdD',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/adfi85a/vt/frame?autoplay=true',
          direction: 'rtl'
        }
      ]
    },
  [pageGuidEnums.VOCABULARY_SHOW_ALL_PHRASES]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/zMqq2PdD',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/adfi85a/vt/frame?autoplay=true',
          direction: 'rtl'
        }
      ]
    },
  [pageGuidEnums.VOCABULARY_SHOW_ALL_WORDS]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/zMqq2PdD',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/adfi85a/vt/frame?autoplay=true',
          direction: 'rtl'
        }
      ]
    },
  [pageGuidEnums.MEMORY_BOX_HOME]:
    {
      [languagesEnums.PERSIAN_LANGUAGE]: [
        {
          title: '',
          videoLink: 'https://www.namasha.com/embed/ViES8mCm',
          direction: 'rtl'
        }
      ],
      [languagesEnums.ARABIC_LANGUAGE]: [
        {
          title: 'قسم صندوق الذاكرة (Memory Box)',
          videoLink: 'https://www.aparat.com/video/video/embed/videohash/kxci7z9/vt/frame',
          direction: 'rtl'
        }
      ]
    },

}

// {
//   title: 'انتخاب اشتراک و خرید',
//   videoLink: 'https://www.namasha.com/embed/X7sHK136',
//   videoCover: window.location.origin + '/assets/images/vellso-logo.png',
//   direction: 'rtl'
// },
// {
//   title: 'چطور در Vellso شروع به درس خوندن کنیم.',
//   videoLink: 'https://www.namasha.com/embed/NZCjEH1D',
//   videoCover: window.location.origin + '/assets/images/vellso-logo.png',
//   direction: 'rtl',
// },
// {
//   title: 'معلم هوشمند Vellso چیه و چطور برامون برنامه‌ریزی تحصیلی انجام میده؟',
//   videoLink: 'https://www.namasha.com/embed/skbqNStB',
//   videoCover: window.location.origin + '/assets/images/vellso-logo.png',
//   direction: 'rtl',
// },
// {
//   title: 'امکانات صفحهٔ Study Plan',
//   videoLink: 'https://www.namasha.com/embed/h8Fz71Em',
//   videoCover: window.location.origin + '/assets/images/vellso-logo.png',
//   direction: 'rtl',
// }
