import React, { useContext } from 'react'
import removeWrapperTag from '../../../../operations/removeWrapperTag'
import reactStringReplace from 'react-string-replace'
import InlineInputField from '../../../../components/InlineInputField/InlineInputField'
import colorsContext from '../../../../contexts/ColorsContext'
import htmlToText from '../../../../operations/htmlToText'
import numberIsOdd from '../../../../operations/numberIsOdd'
import ExerciseFullTexts from '../../Components/ExerciseFullTexts'
import HtmlTextWrapper from '../../../../components/HtmlTextWrapper/HtmlTextWrapper'

const ExerciseGivenTextWithField = (
	{
		givenText,
		fullTexts = [],
		checked = false,
	},
) => {
	const { colors } = useContext(colorsContext)

	if (checked) {
		return <ExerciseFullTexts
			template={'INPUT'}
			fullTexts={fullTexts}
			textColor={colors['C23']}
		/>
	}

	const renderGivenTextParagraph = (paragraph, pIndex) => {
		if (paragraph.containsField) {
			const cleanParagraph = removeWrapperTag(paragraph.string, 'p')
			const arr = reactStringReplace(cleanParagraph, '###', (match, index) => {
				return <InlineInputField key={index} />
			})
			/*
			* Example of arr:
			* ['<a href="text">Hello</a>', myCustomComponent, '.']
			* myCustomComponent is located in odd indexes (1, 3...)
			* */
			return arr.map((el, i) => (
				<React.Fragment key={i}>
					{numberIsOdd(i) ? el :
						<HtmlTextWrapper
							textColor={colors['C23']}
							optionsColor={colors['C23']}
							data={{
								...fullTexts.length > pIndex ? fullTexts[pIndex] : {},
								sentence: el,
							}}
							phraseOptionsDisabled
							showPhraseOptions={checked && i === 0}
							style={{ display: 'inline' }}
						/>
					}
				</React.Fragment>
			))
		} else {
			return (
				<HtmlTextWrapper
					textColor={colors['C23']}
					optionsColor={colors['C23']}
					data={{
						...fullTexts.length > pIndex ? fullTexts[pIndex] : {},
						sentence: paragraph.string,
					}}
					phraseOptionsDisabled
					showPhraseOptions={checked}
					style={{ display: 'inline' }}
				/>
			)
		}
	}

	if (givenText && givenText.paragraphList.length > 0) {
		return givenText.paragraphList.map((paragraph, pIndex) => {
			return (
				<div className='exercise-paragraph' style={{...paragraph.paragraphStyle}} key={pIndex}>
					{renderGivenTextParagraph(paragraph, pIndex)}
				</div>
			)
		})
	}

	return null
}

export default ExerciseGivenTextWithField