import React, {useEffect, useState} from "react"
import ChatBoxCornerSvg from "../../icons/ChatBoxCornerSvg";
import TypingIndicator from "../TypingIndicator/TypingIndicator";

const ChatBox = ({bgColor, borderColor, children, toLeft, delay, isTyping = false, contentColor, className}) => {
  require("./chatBox.scss")
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (delay) {
      const timeout = setTimeout(() => {
        setReady(true)
      }, delay)

      return () => {
        clearTimeout(timeout)
      }
    } else {
      setReady(true)
    }
  }, [])

  return (
    <>
      {ready &&
        <div
          className={`chat-box ${toLeft ? 'toLeft' : 'toRight'} ${className}`}
          style={{backgroundColor: bgColor, borderColor: borderColor ?? "transparent"}}
          data-jsx="ChatBox"
        >
          <div className="shape">
            <ChatBoxCornerSvg color={bgColor} borderColor={borderColor ?? "transparent"}/>
          </div>
          <div className="text-container">
            {isTyping
              ? <div className='h-100 d-flex justify-content-center align-items-center'>
                <TypingIndicator color={contentColor}/>
              </div>
              : <div>{children}</div>
            }
          </div>
        </div>
      }
    </>
  )
}

export default ChatBox