import * as React from "react"

const NewspaperSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style type="text/css">
            {
                ".NewspaperSvg-st0{stroke-width:13.8165;stroke-miterlimit:10;}"
            }
        </style>
        <path
            className="NewspaperSvg-st0"
            stroke={color}
            d="M459.8,461.3H53.2c-25,0-45.2-20.2-45.2-45.2V111.2c0-31.2,25.3-56.5,56.5-56.5h316.3 c31.2,0,56.5,25.3,56.5,56.5h22.6c25,0,45.2,20.2,45.2,45.2v259.8C505,441.1,484.8,461.3,459.8,461.3z M437.2,133.8v282.4 c0,12.4,10.1,22.6,22.6,22.6c12.4,0,22.6-10.1,22.6-22.6V156.4c0-12.4-10.1-22.6-22.6-22.6H437.2z M64.5,77.3 c-18.8,0-33.9,15.2-33.9,33.9V416c0,12.4,10.1,22.6,22.6,22.6h367.5c-3.9-6.9-6.1-14.6-6-22.6V111.2c0-18.8-15.2-33.9-33.9-33.9 L64.5,77.3L64.5,77.3z"
        />
        <path
            className="NewspaperSvg-st0"
            stroke={color}
            d="M188.7,269.3H87.1c-12.4,0-22.6-10.1-22.6-22.6V145c0-12.4,10.1-22.6,22.6-22.6h101.7 c12.4,0,22.6,10.1,22.6,22.6v101.7C211.3,259.2,201.2,269.3,188.7,269.3z M87.1,145.1v101.7h101.7V145.1H87.1z"
        />
        <path
            className="NewspaperSvg-st0"
            stroke={color}
            d="M370.5,146.1h-113c-6.2,0-11.3-5-11.3-11.3s5-11.3,11.3-11.3h113c6.2,0,11.3,5,11.3,11.3 S376.7,146.1,370.5,146.1z"
        />
        <path
            className="NewspaperSvg-st0"
            stroke={color}
            d="M370.5,263.1h-113c-6.2,0-11.3-5-11.3-11.3s5-11.3,11.3-11.3h113c6.2,0,11.3,5,11.3,11.3 S376.7,263.1,370.5,263.1z"
        />
        <path
            className="NewspaperSvg-st0"
            stroke={color}
            d="M372.5,370.8H78.8c-6.2,0-11.3-5-11.3-11.3c0-6.2,5-11.3,11.3-11.3h293.7c6.2,0,11.3,5,11.3,11.3 C383.8,365.7,378.7,370.8,372.5,370.8z"
        />
    </svg>
)

export default NewspaperSvg
