import React, {useContext, useEffect, useState} from 'react'
import colorsContext from '../../../contexts/ColorsContext'
import exerciseStyles from './../ExerciseModules.module.scss'
import classNames from 'classnames'
import {useDispatch} from 'react-redux'
import {
  checkExerciseItem, finishExercise,
  matchOptions,
} from '../../../redux/features/exerciseSlice'
import {find, orderBy} from 'lodash'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import reactStringReplace from 'react-string-replace'
import {moreOptionsList} from '../../../data'
import ExerciseFullTexts from '../Components/ExerciseFullTexts'
import InlineInputField from '../../../components/InlineInputField/InlineInputField'
import numberIsOdd from '../../../operations/numberIsOdd'
import removeWrapperTag from '../../../operations/removeWrapperTag'
import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const MatchModule = ({data}) => {
  const {colors} = useContext(colorsContext)
  const [selectedGiven, setSelectedGiven] = useState(null)
  const [selectedChoice, setSelectedChoice] = useState(null)
  const [matchStatus, setMatchStatus] = useState(AnswerStatusEnums.NONE)
  const dispatch = useDispatch()

  useEffect(() => {
    if (data.userData?.length === data.stack.givens?.length && !data.checked) {
      const userAnswerData = {
        exerciseStackId: data.stack.id,
        userAnswerItems: [
          {
            exerciseItemId: data.stack.itemId,
            userAnswers: orderBy(data.userData, o => o.given.index).map(u => {
              return {
                listIndex: u.choice.index,
              }
            }),
          },
        ],
      }
      dispatch(checkExerciseItem({userAnswerData})).then((res) => {
        if (res.payload?.status === 200) {
          dispatch(finishExercise())
        }
      })
    }
  }, [data.userData?.length])

  const renderGivenText = (color, given, choice, isFullText) => {
    if (isFullText) {
      return <ExerciseFullTexts
        userAnswerStatus={[AnswerStatusEnums.CORRECT]}
        textColor={colors['C23']}
        fullTexts={[given.answerText]}
        template='INPUT'
      />
    }

    const cleanGivenText = removeWrapperTag(given?.text.sentence, 'p')

    const arr = reactStringReplace(data.checked ? cleanGivenText : given?.text.sentenceString, '###', (match, index) => {
      return (
        <InlineInputField
          key={index}
          status={AnswerStatusEnums.CORRECT}
          value={
            choice ?
              <HtmlTextWrapper
                style={{display: 'inline'}}
                textColor={data.checked ? colors['C29'] : color}
                data={{sentence: choice.text.sentence}}
              /> : null
          }
        />
      )
    })

    return arr.map((el, i) => (
      <React.Fragment key={i}>
        {numberIsOdd(i) ? el :
          <HtmlTextWrapper
            textColor={data.checked ? colors['C233'] : color}
            optionsColor={data.checked ? colors['C233'] : colors['C23']}
            data={{
              ...given.answerText,
              sentence: el,
            }}
            phraseOptionsDisabled
            showPhraseOptions={data.checked && i === 0}
            style={{display: 'inline'}}
          />
        }
      </React.Fragment>
    ))
  }

  const onTwoOptionMatch = (givenIndex, choiceIndex) => {
    if (choiceIndex === givenIndex) {
      setMatchStatus(AnswerStatusEnums.CORRECT)
      setTimeout(() => {
        dispatch(matchOptions({givenIndex, choiceIndex}))
      }, 1000)
    } else {
      setMatchStatus(AnswerStatusEnums.INCORRECT)
    }

    setTimeout(() => {
      setSelectedChoice(null)
      setSelectedGiven(null)
      setMatchStatus(AnswerStatusEnums.NONE)
    }, 1000)
  }

  const onClickOptionMatch = (side, index) => {
    if (matchStatus === AnswerStatusEnums.NONE) {
      if (side === 'given') {
        if (selectedChoice != null) {
          onTwoOptionMatch(index, selectedChoice)
          setSelectedGiven(index)
        } else {
          setSelectedGiven(selectedGiven !== index ? index : null)
        }
      } else if (side === 'choice') {
        if (selectedGiven != null) {
          onTwoOptionMatch(selectedGiven, index)
          setSelectedChoice(index)
        } else {
          setSelectedChoice(selectedChoice !== index ? index : null)
        }
      }
    }
  }

  const renderGivens = () => {
    return data.stack.givens.filter(o => !Boolean(find(data.userData, u => u.given.index === o.index))).map((given, i) => {
      const selected = given.index === selectedGiven

      const color = () => {
        if (selected) {
          if (matchStatus === AnswerStatusEnums.CORRECT) {
            return colors['C30']
          } else if (matchStatus === AnswerStatusEnums.INCORRECT) {
            return colors['C29']
          } else {
            return colors['C312']
          }
        } else {
          return colors['C23']
        }
      }

      return (
        <div
          className={classNames(
            exerciseStyles.categoryBox,
            {[exerciseStyles.selected]: given.index === selectedGiven},
          )}
          style={{borderColor: colors['C160']}}
          onClick={() => onClickOptionMatch('given', given.index)}
          key={i}
        >
          {renderGivenText(color(), given)}
        </div>
      )
    })
  }

  const renderChoices = () => {
    return data.stack.choices.map((choice, i) => {
      const matched = Boolean(find(data.userData, (obj) => obj.choice.index === choice.index))
      if (!matched) {

        const selected = choice.index === selectedChoice
        const color = () => {
          if (selected) {
            if (matchStatus === AnswerStatusEnums.CORRECT) {
              return colors['C30']
            } else if (matchStatus === AnswerStatusEnums.INCORRECT) {
              return colors['C29']
            } else {
              return colors['C312']
            }
          } else {
            return colors['C23']
          }
        }

        return (
          <div
            className={classNames(
              exerciseStyles.categoryBox,
              {[exerciseStyles.selected]: choice.index === selectedChoice},
            )}
            style={{
              borderColor: colors['C160'],
              color: color(),
            }}
            onClick={() => onClickOptionMatch('choice', choice.index)}
            key={i}
          >
            {choice.text.sentenceString}
          </div>
        )
      }
      return null
    })
  }

  const renderAnswerCards = () => {
    return data.userData.map((obj, i) => {
      const given = find(data.stack.givens, (given) => given.index === obj.given.index)
      const choice = find(data.stack.choices, (choice) => choice.index === obj.choice.index)
      const correctChoice = find(data.stack.choices, (choice) => choice.index === obj.given.index)

      return (
        <div
          className={classNames(exerciseStyles.matchResultCard, exerciseStyles.exerciseCard)}
          style={{
            background: colors['C527'],
            borderColor: colors['C30'],
          }}
          key={i}
        >
          {given.isBlank ?
            <p
              className={exerciseStyles.text}
              style={{color: colors['C30']}}
            >
              {renderGivenText(colors['C23'], given, choice, true)}
            </p>
            : <>
              <HtmlTextWrapper
                className={exerciseStyles.text}
                showPhraseOptions
                moreOptionsList={moreOptionsList}
                optionsColor={colors['C23']}
                textColor={colors['C23']}
                data={given.text}
              />
              <HtmlTextWrapper
                className={exerciseStyles.text}
                showPhraseOptions
                moreOptionsList={moreOptionsList}
                optionsColor={colors['C23']}
                textColor={colors['C30']}
                data={correctChoice.text}
              />
            </>
          }
        </div>
      )
    })
  }

  return (
    <div className={exerciseStyles.matchContainer}>
      <div className='container-fluid'>
        {data.userData.length < data.stack.givens.length &&
          <div className={exerciseStyles.categoriesBoxContainer}>
            <div
              className={classNames(exerciseStyles.exerciseCard, exerciseStyles.matchCard)}
              style={{background: colors['C527']}}
            >
              {renderGivens()}
            </div>
            <div
              className={classNames(exerciseStyles.exerciseCard, exerciseStyles.matchCard)}
              style={{background: colors['C527']}}
            >
              {renderChoices()}
            </div>
          </div>
        }
        {renderAnswerCards()}
      </div>
    </div>
  )
}

export default MatchModule