// baseAsyncThunk.js
import { createAsyncThunk } from '@reduxjs/toolkit';

export const baseAsyncThunk = (typePrefix, payloadCreator, options) => {
  return createAsyncThunk(typePrefix, async (arg, thunkAPI) => {
    try {
      return await payloadCreator(arg, thunkAPI);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
        status: error.code,
        response: error.response?.data
      }, {
        retry: options?.retry,
        thunkId: typePrefix,
        retryId: arg?.retryId
      })
    }
  });
};