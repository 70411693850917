import React, { useContext, useEffect, useRef } from 'react'
import structureStyle from './../StructuredComponents.module.scss'
import componentStyle from './CompareMonolithicTemplate.module.scss'
import colorsContext from '../../../../contexts/ColorsContext'
import StructureTitleComponent from '../Components/StructureTitleComponent/StructureTitleComponent'
import StructureImageComponent from '../Components/StructureImageComponent/StructureImageComponent'
import StructureSubjectsComponent from '../Components/StructureSubjectsComponent/StructureSubjectsComponent'
import classNames from 'classnames'
import { isEmpty, isNumber } from 'lodash'
import structuresContext from '../../../../contexts/StructuresContext'
import { robotTeacherScrollIntoViewByElement } from '../../../../operations/robotTeacherScrollIntoViewByElement'
import { useSelector } from 'react-redux'
import { ContentTypesStructureEnums } from '../../../../enums/structureEnums/templateType'

const CompareMonolithicTemplate = ({ template }) => {
	const { colors } = useContext(colorsContext)
	const { scrolling } = useSelector(state => state.roboTeacher)
	const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
	const elRef = useRef(null)

	const renderCardContent = card => {
		const cardElRef = useRef(null)
		const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
		const cardIsHidden = isRoboTeacher ?? false ? (roboTeacherData.currentTime ?? 0) < (card?.hiddenTime ?? 0) : false

		useEffect(() => {
			if (card?.hiddenTime > 0 && !cardIsHidden && isRoboTeacher && !scrolling) {
				robotTeacherScrollIntoViewByElement(cardElRef?.current)
			}
		}, [cardIsHidden])

		switch (card?.type) {
			case ContentTypesStructureEnums.TITLE:
				return (
					<div
						ref={cardElRef}
						className={classNames(structureStyle.roboTeacherView, { ['hide-element']: cardIsHidden })}>
						<StructureTitleComponent text={card.data?.text} />
					</div>
				)
			case ContentTypesStructureEnums.IMAGE:
				return (
					<div
						ref={cardElRef}
						className={classNames(componentStyle.cardsContainer, componentStyle.picturesContainer, {
							['hide-element']: cardIsHidden,
						})}>
						<StructureImageComponent picture={{ ...card.data?.pictureLeft, text: card.data?.pictureTextLeft }} />
						<StructureImageComponent picture={{ ...card.data?.pictureRight, text: card.data?.pictureTextRight }} />
					</div>
				)
			case ContentTypesStructureEnums.SUBJECT:
				return (
					<div
						ref={cardElRef}
						className={classNames('componentStyle.cardsContainer', { ['hide-element']: cardIsHidden })}>
						<StructureSubjectsComponent subjects={[{ texts: card.data.textsLeft }]} />
						<StructureSubjectsComponent subjects={[{ texts: card.data.textsRight }]} />
					</div>
				)
		}
	}

	const renderCards = cards => {
		const isHidden = isRoboTeacher ?? false ? (roboTeacherData.currentTime ?? 0) < (template?.hiddenTime ?? 0) : false

		useEffect(() => {
			if (template?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
				robotTeacherScrollIntoViewByElement(elRef?.current)
			}
		}, [isHidden])

		return (
			<div
				data-rt-id={template?.id}
				ref={elRef}
				className={classNames(
					structureStyle.templateContainer,
					{ [structureStyle.border]: !isEmpty(template?.config?.borderColor) },
					{ ['hide-element']: isHidden },
				)}
				style={{
					background: isEmpty(template?.config?.backgroundColor) ? colors['C194'] : template.config.backgroundColor,
					borderColor: template?.config?.borderColor,
				}}>
				{cards.map((card, i) => {
					return <React.Fragment key={i}>{renderCardContent(card)}</React.Fragment>
				})}
			</div>
		)
	}

	return <div className="container container-1">{renderCards(template.data.cards)}</div>
}

export default CompareMonolithicTemplate
