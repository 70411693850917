import { isEmpty } from "lodash";
import getUnitBackgroundColorByNumber from "../../../operations/getUnitBackgroundColorByNumber";
import { sectionsData } from "../../../sources/sectionsData";
import { TYPE_A } from "../../../enums/exerciseEnums/exerciseGroupTypeEnums";

const unitsMapper = (data, level) => {
  let units = data?.map((item, index) => {
    return {
      isBasic: false,
      id: item.unitId,
      level: level.level,
      levelTitle: level.title,
      levelNumber: level.levelNumber,
      unitNumber: item.unitId,
      active: item.isOn,
      startDate: item.startDate,
      endDate: item.finishDate,
      title: `Unit ${item.unitId}`,
      desc1: item.unitTitle,
      desc2: item.unitDescription,
      studiedPercentage: Math.floor(item.doneSectionsPercentage),
      sectionStudied: isEmpty(item.doneSections) ? item.doneSections : 0,
      studyStatus: item.studyType ?? null,
      group: item.group ?? TYPE_A,
      backgroundColor: getUnitBackgroundColorByNumber(item.unitId),
      sections: sectionsData.map(() => {
        return {
          studyProgressStatus: null,
        }
      }),
      doneSectionsIndexList: [...Array(item.doneSections).keys()],
      isFinished: item.isFinished,
      doneSections: item.doneSections,
      totalSections: item.totalSections || 23
    }
  })

  if (level.levelNumber === 1) {
    units.unshift({
      isBasic: true,
      id: 0,
      level: 'BASICS',
      levelTitle: level.title,
      levelNumber: level.levelNumber,
      unitNumber: 0,
      title: 'Basics',
      desc1: 'Alphabet / Numbers',
      desc2: '',
      background: 'unit1.jpg',
    })
  }

  return units
}

export default unitsMapper