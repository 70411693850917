import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const checkChooseFromCategoryMapper = (stackId, response, data) => {
	return {
		...data,
		checkable: false,
		checked: true,
		stack: {
			...data.stack,
			choices: [...data.stack.choices].map(choice => {
				const userAnswerData = response[0].userAnswerItemResponse.find(o => o.index === choice.index)
				if (userAnswerData) {
					return {
						...choice,
						selected: userAnswerData.userAnswerStatus !== AnswerStatusEnums.OVERLOOKED,
						userAnswerStatus: userAnswerData.userAnswerStatus,
					}
				}
				return choice
			}),
		},
	}
}

export default checkChooseFromCategoryMapper