import serviceCall from "../../services";
import flashCardsMapper from "../../services/mapper/memoryBox/flashCardsMapper";
import { ASC, HISTORY } from '../../enums/globalEnums/globalEnums'

const getAllUnitHistoryFlashCardUseCase = async ({categoryId, searchTerm, sortBy, sortKey, unitId, isVocab}) => {
	const result = await serviceCall(
		'fetchAllUnitHistoryMemoryBoxFlashCards',
		{
			boxType: HISTORY,
			categoryId: categoryId,
			frontText: searchTerm,
			sortAsc: sortBy === ASC,
			sortBy: sortKey,
			unitId: unitId,
			isVocab: isVocab
		},
		'cloud',
	)

	return flashCardsMapper(result)
}

export default getAllUnitHistoryFlashCardUseCase