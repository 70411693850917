import * as React from "react"

const MoreExamples = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style type="text/css">
            {
                ".MoreExamplesSvg-st0{fill:none;stroke-width:37;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"
            }
        </style>
        <path
            className="MoreExamplesSvg-st0"
            stroke={color}
            d="M69.9,464.9V49.1C69.9,32.5,83.4,19,99.9,19h246c7.1,0,14,2.5,19.4,7.1l69.6,59c6.5,5.5,10.4,13.6,10.6,22.2 l8.6,356.9c0.4,16.9-13.2,30.8-30,30.8H99.9C83.4,495,69.9,481.5,69.9,464.9z"
        />
        <path
            className="MoreExamplesSvg-st0"
            stroke={color}
            d="M348,38c-0.4,3.4-3,30,17,50c15.4,15.4,38.4,21.1,60,15"
        />
        <line className="MoreExamplesSvg-st0"
              stroke={color} x1={124} y1={281.5} x2={293.3} y2={281.5} />
        <line className="MoreExamplesSvg-st0"
              stroke={color} x1={124} y1={337} x2={293.3} y2={337} />
        <line className="MoreExamplesSvg-st0"
              stroke={color} x1={124} y1={394} x2={293.3} y2={394} />
        <g>
            <g>
                <g>
                    <path d="M261.6,251.9c-6.7,0-12.6-1.3-17.7-3.7c-1.4-0.7-4-2.1-6.1-4.1c-0.4-0.3-0.8-0.7-1.1-1.1l0,0c0.1,0.1,0.2,0.2,0.3,0.3 c-0.9-1.1-1.7-2.2-2-3.5c-0.9-3.4,0.8-7,4-8.6c0.9-0.4,5.3-2.3,9.1,2c0.8,0.7,5.4,4.2,15.3,3.8c11.5-0.5,11.7-12.8,11.7-15.3 c0-0.1,0-0.2,0-0.3v-54.3c0-4.1,3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5v54.2c0.4,11.9-6.2,29.9-26,30.7 C263.1,251.9,262.4,251.9,261.6,251.9z" />
                </g>
                <path d="M259.2,220.9c-7.9,0-15.7-3-21.7-9c-5.8-5.8-9-13.5-9-21.7s3.2-15.9,9-21.7s13.5-9,21.7-9c8.2,0,15.9,3.2,21.7,9 s9,13.5,9,21.7s-3.2,15.9-9,21.7C274.9,217.9,267.1,220.9,259.2,220.9z M259.2,174.5c-4,0-8.1,1.5-11.1,4.6 c-6.1,6.1-6.1,16.1,0,22.3c6.1,6.1,16.1,6.1,22.3,0c3-3,4.6-6.9,4.6-11.1s-1.6-8.2-4.6-11.1C267.2,176,263.2,174.5,259.2,174.5z" />
            </g>
            <g>
                <path d="M173.4,201.1L173.4,201.1L127,201c-4,0-7.3-3.2-7.5-7.2l-0.1-3.5c0-0.1,0-0.2,0-0.3c0-17,13.8-30.8,30.7-30.8 s30.7,13.8,30.7,30.7v3.6c0,2-0.8,3.9-2.2,5.3C177.3,200.3,175.4,201.1,173.4,201.1z M134.9,186l30.5,0.1 c-1.7-6.8-7.9-11.8-15.2-11.8C142.9,174.2,136.7,179.2,134.9,186z" />
                <g>
                    <path d="M153,226.2c-18.5,0-33.5-15-33.5-33.5c0-4.1,3.4-7.5,7.5-7.5c4.1,0,7.5,3.4,7.5,7.5c0,10.2,8.3,18.5,18.5,18.5 c3.7,0,6.8-1.1,9.3-3.3c0.5-0.4,0.9-0.9,1.3-1.4c2.7-3.2,7.4-3.6,10.6-0.9c3.2,2.7,3.6,7.4,0.9,10.6c-0.9,1.1-1.8,2-2.9,3 C167,223.7,160.4,226.2,153,226.2z" />
                </g>
            </g>
            <path d="M313.5,220.9c-0.5,0-1,0-1.5-0.2c-0.5-0.1-1-0.2-1.4-0.4c-0.5-0.2-0.9-0.4-1.3-0.7s-0.8-0.6-1.1-0.9 c-0.3-0.3-0.7-0.7-0.9-1.1c-0.3-0.4-0.5-0.8-0.7-1.3s-0.3-0.9-0.4-1.4s-0.2-1-0.2-1.5s0-1,0.2-1.5c0.1-0.5,0.2-0.9,0.4-1.4 s0.4-0.9,0.7-1.3c0.3-0.4,0.6-0.8,0.9-1.2c0.3-0.3,0.7-0.6,1.1-0.9c0.4-0.3,0.8-0.5,1.3-0.7s0.9-0.3,1.4-0.4c2.4-0.5,5,0.3,6.8,2 c0.4,0.4,0.7,0.7,0.9,1.2c0.3,0.4,0.5,0.8,0.7,1.3s0.3,0.9,0.4,1.4s0.1,1,0.1,1.5s0,1-0.1,1.5s-0.2,1-0.4,1.4 c-0.2,0.4-0.4,0.9-0.7,1.3s-0.6,0.8-0.9,1.1C317.4,220.1,315.5,220.9,313.5,220.9z" />
            <path d="M204.7,220.9c-2,0-3.9-0.8-5.3-2.2c-0.4-0.3-0.7-0.7-0.9-1.1c-0.3-0.4-0.5-0.8-0.7-1.3s-0.3-0.9-0.4-1.4 c-0.1-0.5-0.1-1-0.1-1.5s0-1,0.1-1.5s0.2-0.9,0.4-1.4s0.4-0.9,0.7-1.3s0.6-0.8,0.9-1.2c1.7-1.7,4.3-2.5,6.8-2 c0.5,0.1,0.9,0.2,1.4,0.4c0.4,0.2,0.9,0.4,1.3,0.7c0.4,0.3,0.8,0.6,1.1,0.9c0.4,0.4,0.7,0.7,0.9,1.2c0.3,0.4,0.5,0.8,0.7,1.3 s0.3,0.9,0.4,1.4c0.1,0.5,0.1,1,0.1,1.5s0,1-0.1,1.5s-0.2,1-0.4,1.4s-0.4,0.9-0.7,1.3s-0.6,0.8-0.9,1.1c-0.3,0.3-0.7,0.7-1.1,0.9 c-0.4,0.3-0.8,0.5-1.3,0.7s-0.9,0.3-1.4,0.4C205.6,220.9,205.2,220.9,204.7,220.9z" />
        </g>
    </svg>
)

export default MoreExamples
