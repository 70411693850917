import React, { useContext, useEffect } from 'react'
import HelpButton from '../../../components/HelpButton/HelpButton'
import colorsContext from '../../../contexts/ColorsContext'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import { useParams } from 'react-router-dom'
import styles from './ReviewMistakes.module.scss'
import ReviewMistakeCircle from '../../../components/ReviewMistakeCircle/ReviewMistakeCircle'
import { useDispatch, useSelector } from 'react-redux'
import { getMistakes, resetReviewMistakesData } from '../../../redux/features/reviewMistakeSlice'
import useSectionEvents from "../../../hooks/sectionHooks/useSectionEvents";
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import { FINISH_SECTION, START_SECTION } from "../../../enums/studyLogEnums/studyLogEnums";
import useLog from "../../../hooks/logHooks/useLog";
import classNames from "classnames";
import UnitDataStorage from "../../../classes/UnitDataStorage";
import { getUnitStatus } from "../../../redux/features/statusSlice";
import { toNumber } from "lodash";
import { studyTypeEnum } from "../../../enums/userStudyEnums/userStudyEnums";
import useUpdateVersion from "../../../hooks/useUpdateVersion";
import useActionRetryerWrapper from '../../../hooks/useDispatchWrapper'

const ReviewMistakes = () => {
  const { colors } = useContext(colorsContext)
  const { unit, sectionId } = useParams()
  const { loading, mistakesData } = useSelector(state => state.reviewMistake)
  const { logPending } = useSelector(state => state.studyLog)
  const { navigateToNextSection } = useSectionEvents()
  const { checkVersion } = useUpdateVersion()

  const dispatch = useDispatch()
  const { networkLossRetryerDispatch } = useActionRetryerWrapper()
  const { sendServerLog } = useLog()

  const getData = () => {
    const unitDataStorage = new UnitDataStorage()
    const unitData = unitDataStorage.getUnitData()

    networkLossRetryerDispatch(retryId => {
      dispatch(getMistakes({ unit, studyType: unitData?.studyStatus, retryId })).then(() => {
        sendServerLog(START_SECTION)

        setTimeout(() => {
          sendServerLog(FINISH_SECTION)
        }, 1000)
      })
    })
  }

  const getStatusData = () => {
    const unitDataStorage = new UnitDataStorage()
    const unitData = unitDataStorage.getUnitData()

    networkLossRetryerDispatch(retryId => {
      dispatch(getUnitStatus({
        unitId: toNumber(unit),
        sectionNumber: toNumber(sectionId),
        studyType: unitData?.studyStatus ?? studyTypeEnum.NEW,
        retryId
      })).then(res => {
        checkVersion(res.payload?.pwaVersion)
      })
    })
  }

  const resetData = () => {
    dispatch(resetReviewMistakesData())
  }

  useEffect(() => {
    getData()
  }, [])

  const onCloseExercisePage = () => {
    resetData()
    getData()
    getStatusData()
  }

  const renderMistakeCards = () => {
    return [...mistakesData].map((item, i) => {
      return (
        <ReviewMistakeCircle
          data={item}
          onCloseExercisePage={onCloseExercisePage}
          key={i}
        />
      )
    })
  }

  return (
    <div>
      <div className='container-fluid pt-3'>
        <div className='d-flex justify-content-end'>
          <div className='me-3 me-md-5 ms-2'>
            <HelpButton color={colors['C2']} pageType={pageGuidEnums.REVIEW_MISTAKES_SECTION} />
          </div>
        </div>
      </div>
      <div className={classNames(
        styles.pageContainer,
        { ['d-flex align-items-center justify-content-center']: !loading && mistakesData?.length === 0 }
      )}>
        {(!loading && mistakesData?.length === 0) ?
          <p
            className='text-center res:fs-2xl opacity-75 font-weight-600'
            style={{ color: colors['C2'] }}
          >
            You are all done!
          </p> :
          <div className={styles.reviewMistakesContainer}>
            {renderMistakeCards()}
          </div>
        }
        <div className={styles.image}>
          <img src={`${window.location.origin}/assets/images/ReviewMistakes.png`} />
        </div>
      </div>
      <div className='container'>
        <div className='pagebelow-buttons-container'>
          <PrimaryButton
            loading={logPending}
            onClick={navigateToNextSection}
          >
            Next
          </PrimaryButton>
        </div>
      </div>
    </div>
  )
}

export default ReviewMistakes