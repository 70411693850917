import structureBaseTitleMapper from "./StructureBaseTitleMapper";
import structureBaseImageMapper from "./StructureBaseImageMapper";

const structureTableTemplateMapper = ({item, events}) => {
  return {
    ...item,
    data: {
      ...item.data,
      cells: item.data?.cells?.map(cell => {
        return {
          ...cell,
          data: {
            text: structureBaseTitleMapper({title: cell?.data?.text, events}),
            picture: structureBaseImageMapper({image: cell?.data?.picture, text: null, events}),
          }

        }
      })
    },
    hiddenTime: parseInt(events?.view?.find(it => it.templateId === item.id)?.startTime) || 0
  }
}

export default structureTableTemplateMapper