import React, { useContext } from 'react'
import exerciseStyles from '../../ExerciseModules.module.scss'
import { selectOptionPIO, unselectOptionPIO } from '../../../../redux/features/exerciseSlice'
import { useDispatch, useSelector } from 'react-redux'
import colorsContext from '../../../../contexts/ColorsContext'
import classNames from 'classnames'

const PutInOrderSelectedChoices = ({ stackIndex, itemIndex, choices, enabled, display = true }) => {
	const { colors } = useContext(colorsContext)
	const { checkingItem } = useSelector((state) => state.exercise)
	const dispatch = useDispatch()

	return choices.map((choice, i) => {
		return (
			<button
				className={classNames(exerciseStyles.phraseCard, { [exerciseStyles.used]: choice.selected })}
				onClick={() => {
					if (enabled && !checkingItem)
						dispatch(selectOptionPIO({ stackIndex, itemIndex, choiceIndex: choice.index }))
				}}
				disabled={choice.selected || !enabled}
				style={{
					background: choice.selected ? colors['C533'] : colors['C532'],
					borderColor: 'rgba(182,183,184,0.8)',
					color: colors['C23'],
					visibility: display ? 'unset' : 'hidden',
				}}
				key={i}
			>
				{choice.text.sentence}
			</button>
		)
	})
}

export default PutInOrderSelectedChoices