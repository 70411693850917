import {createSlice} from '@reduxjs/toolkit'
import memorizeFactory from "../../../../factory/memorizeFactory";
import checkSentenceWordByWord from "../../../../operations/checkSentenceWordByWord";
import { AnswerStatusEnums } from '../../../../enums/globalEnums/globalEnums'
import {playCheckSound} from "../../../../util/playCheckSound/playCheckSound";

const initialState = {
  memorizeData: null,
  currentIndex: 0,
}

const memorizeSlice = createSlice({
  name: 'memorize',
  initialState,
  reducers: {
    createMemorizeData: (state, action) => {
      state.memorizeData = memorizeFactory(action.payload.data, action.payload.type)
    },
    setInputValue: (state, action) => {
      state.memorizeData[state.currentIndex].items[action.payload.itemIndex].input = action.payload.value
    },
    setDisableInputs: (state, action) => {
      state.memorizeData[state.currentIndex].isDisabled = action.payload
    },
    setActiveInputType: (state, action) => {
      if (action.payload === state.memorizeData[state.currentIndex].activeInputType) {
        state.memorizeData[state.currentIndex].activeInputType = null
        state.memorizeData[state.currentIndex].items = state.memorizeData[state.currentIndex].items.map(item => {
          if (item.type === action.payload) {
            return {
              ...item,
              input: ''
            }
          }
          return item
        })
      } else {
        if (state.memorizeData[state.currentIndex].activeInputType == null) {
          state.memorizeData[state.currentIndex].items = state.memorizeData[state.currentIndex].items.map(item => {
            if (item.type === action.payload) {
              return {
                ...item,
                input: item.value
              }
            }
            return item
          })
        } else {
          state.memorizeData[state.currentIndex].items = state.memorizeData[state.currentIndex].items.map(item => {
            if (item.type === action.payload) {
              return {
                ...item,
                input: item.value
              }
            }
            return {
              ...item,
              input: ''
            }
          })
        }
        state.memorizeData[state.currentIndex].activeInputType = action.payload
      }
    },
    resetMemorizeCurrentItem: (state) => {
      const current = state.memorizeData[state.currentIndex]
      state.memorizeData[state.currentIndex] = {
        ...current,
        status: AnswerStatusEnums.NONE,
        usedHint: false,
        isFinished: false,
        unlocked: false,
        userAnswer: null,
        isDisabled: false,
        items: current.items.map(it => {
          return {
            ...it,
            input: '',
            status: AnswerStatusEnums.NONE,
          }
        })
      }
    },
    checkAnswer: (state, action) => {
      let result
      if (action.payload?.result) {
        result = action.payload.result
      } else {
        let value = ''
        state.memorizeData[state.currentIndex].items.forEach(item => {
          value += item.input
          if (item.hasWhiteSpace) {
            value += ' '
          }
        })
        result = checkSentenceWordByWord(value, state.memorizeData[state.currentIndex].title)
      }
      const wordData = state.memorizeData[state.currentIndex]
      const isCorrect = result.every(item => item.status === AnswerStatusEnums.CORRECT)
      playCheckSound(isCorrect)

      state.memorizeData[state.currentIndex] = {
        ...wordData,
        isFinished: true,
        status: isCorrect ? AnswerStatusEnums.CORRECT : AnswerStatusEnums.INCORRECT,
        userAnswer: result,
      }
    },
    increaseCurrentIndex: (state) => {
      if (state.currentIndex < state.memorizeData?.length) {
        state.currentIndex++
      }
    },
    decreaseCurrentIndex: (state) => {
      if (state.currentIndex > 0) {
        state.currentIndex--
      }
    },
    toggleLock: (state) => {
      state.memorizeData[state.currentIndex].unlocked = !state.memorizeData[state.currentIndex].unlocked
    },
    clearMemorizeStates: (state) => {
      state.memorizeData = null
      state.currentIndex = 0
    }
  },
})

export const {
  createMemorizeData, setInputValue, setDisableInputs, setActiveInputType, resetMemorizeCurrentItem,
  checkAnswer, increaseCurrentIndex, decreaseCurrentIndex, toggleLock, clearMemorizeStates
} = memorizeSlice.actions

export default memorizeSlice.reducer