import React, { useContext, useState, useEffect } from 'react'
import HelpButton from '../../../components/HelpButton/HelpButton'
import OverlayScrollbar from '../../../layouts/OverlayScrollbar'
import AudioSpeakerButtonPlaylist from '../../../components/AudioSpeakerButton/AudioSpeakerButtonPlaylist'
import ResetButton from '../../../components/ResetButton/ResetButton'
import {
  fetchRoboChat, resetChat, setDisplayInputText,
  setInputText, setPendingRoboChat, setPlaylist,
  setRecordModalIsOpen, setTypeMethod, setUserDialog,
} from '../../../redux/features/roboChatSlice'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import SendButton from '../../../components/SendButton'
import SpeechCircleButton from '../../../components/SpeechCircleButton/SpeechCircleButton'
import SpeechModal from '../../../components/SpeechModal/SpeechModal'
import colorsContext from '../../../contexts/ColorsContext'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import htmlToText from '../../../operations/htmlToText'
import removeExtraSpace from '../../../operations/removeExtraSpace'
import ChatBox from '../../../components/ChatBox/ChatBox'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import PhrasesOptions from '../../../components/PhrasesOptions/PhrasesOptions'
import { moreOptionsList } from '../../../data'
import classNames from 'classnames'
import getCachedMediaUrl from '../../../operations/getCachedMediaUrl'
import makeUrl from '../../../operations/makeUrl'
import { gsap } from 'gsap'
import { matchIgnoringCase } from '../../../operations/matchIgnoringCase'
import { START_SECTION } from "../../../enums/studyLogEnums/studyLogEnums";
import useLog from "../../../hooks/logHooks/useLog";
import useSectionEvents from "../../../hooks/sectionHooks/useSectionEvents";
import { OverlayTrigger, Popover } from "react-bootstrap";
import AISvg from "../../../icons/AISvg";
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import makeAvatarUrl from "../../../operations/makeAvatarUrl";
import useAppUser from "../../../hooks/useAppUser";
import { isMobile } from "react-device-detect";
import SkipButton from "../../../components/SkipButton/SkipButton";
import useActionRetryerWrapper from '../../../hooks/useDispatchWrapper'

const RoboChat = () => {
  require('./roboChat.scss')

  const { colors } = useContext(colorsContext)

  const [playlistPlaying, setPlaylistPlaying] = useState(false)

  const { unit } = useParams()

  const { logPending } = useSelector(state => state.studyLog)
  const states = useSelector(state => state.roboChat)

  const { appUserData } = useAppUser()
  const dispatch = useDispatch()
  const { networkLossRetryerDispatch } = useActionRetryerWrapper()
  const { sendServerLog } = useLog()

  const { navigateToNextSection } = useSectionEvents()

  const onFetchRoboChat = () => {
    setTimeout(() => {
      dispatch(setPendingRoboChat(false))
    }, 1500)
  }

  /*
  * first render
  * fetch first dialog
  * */
  useEffect(() => {
    if (states.dialogs.length === 0) {
      networkLossRetryerDispatch(retryId => {
        dispatch(fetchRoboChat({ unitId: unit, dialogId: 0, retryId })).then(() => {
          sendServerLog(START_SECTION)
          onFetchRoboChat()
        })
      })
    }

    return () => {
      dispatch(resetChat())
    }
  }, [])

  const handleScrollChatViewBox = () => {
    let chatScrollContainer
    if (isMobile) {
      chatScrollContainer = document.querySelector('.chat-preview-wrapper')
    } else {
      chatScrollContainer = document.querySelector('.chat-preview-inner').parentNode
    }

    if (chatScrollContainer) {
      gsap.to(
        chatScrollContainer, {
        scrollTop: chatScrollContainer.scrollHeight,
        duration: 2,
      },
      )
    }
  }

  useEffect(() => {
    let isMounted = true

    const createPlaylist = async () => {
      const arr = []
      for (let i = 0; i < states.dialogs.length; i++) {
        const r = await getCachedMediaUrl(makeUrl(states.dialogs[i]?.text?.voiceUri))
        if (r) {
          arr.push(r)
        }
      }
      return arr
    }

    createPlaylist().then(res => {
      if (isMounted) {
        dispatch(setPlaylist(res))
      }
    })

    handleScrollChatViewBox()
  }, [states.dialogs])

  useEffect(() => {
    if (states.displayInputText) {
      const timeoutId = setTimeout(() => {
        dispatch(setDisplayInputText(null))
      }, 1800)

      handleScrollChatViewBox()
      return () => {
        clearTimeout(timeoutId)
      }
    }

  }, [states.displayInputText])

  const checkInputAnswer = (value) => {
    let answeredCorrectly = false

    let text = states.inputText

    if (value) {
      text = value
    }

    if (text !== '') {
      states.dialogChoices.forEach(item => {
        const sentenceString = htmlToText(item?.text?.sentence)
        if (matchIgnoringCase(sentenceString, removeExtraSpace(text))) {
          dispatch(setUserDialog({ dialog: item }))
          networkLossRetryerDispatch(retryId => {
            dispatch(fetchRoboChat({ unitId: unit, dialogId: item.id, retryId })).then(() => {
              onFetchRoboChat()
            })
          })
          answeredCorrectly = true
        }
      })

      if (!answeredCorrectly) {
        dispatch(setDisplayInputText(text))
      } else {
        dispatch(setInputText(''))
      }
    }
  }

  const onSubmitChatInput = (e) => {
    e.preventDefault()
    checkInputAnswer()
  }

  const onRecordFinished = (result) => {
    dispatch(setInputText(result ?? ''))
    dispatch(setRecordModalIsOpen(false))
  }

  const onSelectDialog = (dialog) => {
    if (!states.pendingRoboChat) {
      dispatch(setUserDialog({ dialog }))
      networkLossRetryerDispatch(retryId => {
        dispatch(fetchRoboChat({ unitId: unit, dialogId: dialog.id, retryId })).then(() => {
          onFetchRoboChat()
        })
      })
    }
  }

  const renderWrongAnswerPopover = (
    <Popover
      className='hint-popover py-1 px-2' onClick={() => dispatch(setDisplayInputText(null))}
      style={{ background: '#FEDC14', borderColor: '#FEDC14' }}
    >
      <span style={{ color: colors['C233'] }}>⚠️ Just choose from Vellso's offers</span>
    </Popover>
  )

  const renderDialogs = () => {
    return states.dialogs.map((dialog, i) => {
      return (
        <div
          className={classNames('chat-box-wrapper', {
            ['left chat-box-motion-with-delay motion-delay-1000']: dialog.isRobot,
            ['right motion-opacity-1 motion-scale-1']: !dialog.isRobot,
          })}
          key={i}
        >
          <div className='profile'>
            <div className='avatar'>
              <img
                src={dialog?.isRobot ? `${window.location.origin}/assets/images/mascot-head.png` : makeAvatarUrl(appUserData?.setting?.avatar)}
                alt=''
              />
            </div>
            <span className='username font-size-16' style={{ color: colors['C2'] }}>
              {dialog.isRobot ? 'Robot' : 'Me'}
            </span>
          </div>
          <ChatBox bgColor={dialog.isRobot ? colors['C234'] : colors['C235']} toLeft={!dialog.isRobot}>
            <HtmlTextWrapper
              className='font-size-30'
              textColor={dialog.isRobot ? colors['C23'] : colors['C209']}
              data={dialog.text}
            />
          </ChatBox>
          <PhrasesOptions
            color={colors['C2']}
            moreOptionsList={moreOptionsList}
            data={dialog.text}
          />
        </div>
      )
    })
  }

  const renderDialogChoices = () => {
    return states.dialogChoices.map((dialog, i) => {
      return (
        <div className='ready-chat-container motion-opacity-1' key={i}>
          <button
            className='ready-chat'
            disabled={states.pendingRoboChat}
            onClick={() => onSelectDialog(dialog)}
            style={{ background: colors['C184'], opacity: states.pendingRoboChat ? 0 : 1 }}
          >
            <div
              className='robo-chat-option font-size-24'
              style={{
                width: 'inherit',
                textAlign: 'left',
                color: colors['C179'],
              }}
              dangerouslySetInnerHTML={{ __html: dialog.text.sentence }}
            />
            <div className='tools'>
              <PhrasesOptions color={colors['C179']} moreOptionsList={moreOptionsList} data={dialog} />
            </div>
          </button>
        </div>
      )
    })
  }

  return (
    <div data-jsx='RoboChat' className='pt-3'>
      <div className='container-fluid'>
        <div className='d-flex justify-content-end'>
          <div className='me-3 me-md-5 ms-2'>
            <HelpButton color={colors['C2']} pageType={pageGuidEnums.ROBOCHAT_SECTION} />
          </div>
        </div>
      </div>
      <div className='container container-xlg'>
        <div className='robo-chat-container'>
          <div
            className='chat-preview-wrapper'
            style={{ background: (colors['C183'].match('gradient') ? colors['C183'] + ', ' : '') + colors['C72'] }}
          >
            <OverlayScrollbar>
              <div className='chat-preview-inner'>
                <AudioSpeakerButtonPlaylist
                  className='speaker-playlist'
                  playing={playlistPlaying}
                  handlePlaying={setPlaylistPlaying}
                  playlistUrls={states.playlist}
                  color={colors['C187']}
                />
                <div>
                  {renderDialogs()}
                </div>
              </div>
            </OverlayScrollbar>
          </div>
          {states.chatFinished ?
            <div className='finish-buttons motion-opacity-1'>
              <ResetButton
                color={colors['C2']}
                onClick={() => {
                  dispatch(resetChat())
                  networkLossRetryerDispatch(retryId => {
                    dispatch(fetchRoboChat({ unitId: unit, dialogId: 0, retryId })).then(() => {
                      onFetchRoboChat()
                    })
                  })
                }}
              />
              <PrimaryButton loading={logPending} onClick={navigateToNextSection}>
                Next
              </PrimaryButton>
            </div> :
            <>
              <div className='ready-chats'>
                {renderDialogChoices()}
              </div>
              <form onSubmit={(e) => onSubmitChatInput(e)}>
                <div className='chat-input-container'>
                  <div className='input-wrapper'>
                    <input
                      type='text'
                      value={states.inputText}
                      onChange={(e) => {
                        if (e.target.value === '') {
                          dispatch(setDisplayInputText(null))
                        }
                        dispatch(setInputText(e.target.value))
                      }}
                      style={{ background: colors['C160'], color: colors['C233'] }}
                    />
                  </div>
                  <div className='submit-button-wrapper'>
                    {states.inputText?.length > 0
                      ?
                      <OverlayTrigger show={states.displayInputText} overlay={renderWrongAnswerPopover} placement='top'>
                        <div>
                          <SendButton type='submit' />
                        </div>
                      </OverlayTrigger>
                      : <SpeechCircleButton
                        isLarge
                        onClick={() => {
                          dispatch(setTypeMethod(states.typeMethod === 'voice' ? null : 'voice'))
                          dispatch(setRecordModalIsOpen(true))
                        }}
                        borderColor={colors['C160']}
                        bgColor={colors['C160']}
                        iconColor={colors['C233']}
                      />
                    }
                  </div>
                  <Link to={`/ai-chat/${unit}`} className='ai-chat-button' style={{ background: colors['C160'] }}>
                    <AISvg color={colors['C233']} />
                  </Link>
                </div>
              </form>
            </>
          }
          <div className='d-flex justify-content-end mt-3'>
            <SkipButton
              color={colors['C2']}
              translate={false}
              onClick={navigateToNextSection}
              disabled={logPending}
            />
          </div>
        </div>
      </div>
      {states.recordModalIsOpen &&
        <SpeechModal
          onRecordFinished={(result) => onRecordFinished(result)}
          loading={states.spellPending}
        />
      }
    </div>
  )
}

export default RoboChat