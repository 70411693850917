import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import radioStationUseCase from "../../useCase/radioStationUseCase/radioStationUseCase";

const initialState = {
  radioStations: [],
  selectedStation: {},
  error: null,
  loading: false
}

export const getRadioStations = createAsyncThunk(
  'radioStation/getRadioStations',
  () => {
    return radioStationUseCase()
  }
)

const RadioStationSlice = createSlice({
  name: 'radioStation',
  initialState,
  reducers: {
    setSelectedStation: (state, action) => {
      state.selectedStation = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRadioStations.pending, (state) => {
        state.loading = true
      })
      .addCase(getRadioStations.fulfilled, (state, action) => {
        state.loading = false
        state.radioStations = action.payload
        if (action.payload?.length > 0) {
          state.selectedStation = action.payload[0]
        }
      })
      .addCase(getRadioStations.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export const {setSelectedStation} = RadioStationSlice.actions
export default RadioStationSlice.reducer