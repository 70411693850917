import React from "react";

const SendButton = ({onClick = () => {}, type = 'button', disabled = false}) => {
  return (
    <button type={type} onClick={onClick} disabled={disabled}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.874 90.874">
        <g transform="translate(0 0)">
          <ellipse cx="45.437" cy="45.437" rx="45.437" ry="45.437" fill="#FCFCFC"/>
        </g>
        <g transform="translate(11.303 26.924)">
          <g transform="translate(11.623 0)">
            <g>
              <path
                d="M441.207,816.743l-5.814,5.661c-.989.963-1.98,1.923-2.966,2.893a1.127,1.127,0,0,1-2.028-.651q-1.272-7.879-2.535-15.761a1.126,1.126,0,0,0-.781-1.008c-4.752-1.955-9.485-3.948-14.227-5.926-.6-.246-1.174-.5-1.119-1.279s.66-.941,1.285-1.11q21.369-5.7,42.732-11.411,3.6-.961,7.208-1.929c1.336-.357,1.98.274,1.623,1.6l-9.7,36.082c-.3,1.126-1.027,1.4-1.993.743l-11.14-7.552C441.583,816.989,441.417,816.88,441.207,816.743Zm11.886,5.412,8.519-31.694-.1-.048L437.038,811.27Zm2.58-31.633-.035-.079L416.251,800.96c.176.1.233.14.3.166,3.92,1.639,7.836,3.282,11.762,4.9a.982.982,0,0,0,.734-.1q11.51-6.64,23.007-13.309Q453.863,791.573,455.673,790.522Zm-2.51,4.13-.061-.086a2.061,2.061,0,0,0-.226.1q-11.309,6.549-22.624,13.092c-.309.179-.319.37-.271.663.472,2.867.928,5.734,1.394,8.6.128.781.262,1.563.4,2.341.829-2.622,1.572-5.24,2.29-7.864a2.4,2.4,0,0,1,.8-1.25q8.95-7.606,17.882-15.232C452.882,794.9,453.023,794.776,453.163,794.652Zm-17.285,18.475-2.165,7.727.093.054,5.562-5.422Z"
                transform="translate(-411.732 -786.124)" fill="#1d1d1b"/>
            </g>
          </g>
          <g transform="translate(0 23.378)">
            <rect width="29.331" height="2.364" fill="#1d1d1b"/>
          </g>
          <g transform="translate(4.517 28.35)">
            <rect width="24.815" height="2.364" fill="#1d1d1b"/>
          </g>
          <g transform="translate(9.529 33.373)">
            <rect width="20.177" height="2.364" fill="#1d1d1b"/>
          </g>
        </g>
      </svg>
    </button>
  );
}

export default SendButton;