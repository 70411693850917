import serviceCall from "../../services";
import exerciseStatisticsMapper from "../../services/mapper/exerciseStatistics/exerciseStatisticsMapper";

const exerciseStatisticsUseCase = async () => {
  const response = await serviceCall (
    'fetchExerciseStatistics',
    null,
    'cloud',
  )

  return exerciseStatisticsMapper(response)
}

export default exerciseStatisticsUseCase