import React from "react";

const ClockTimer = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={124.518} height={124.518}
      viewBox="0 0 124.518 124.518"
    >
      <defs>
        <filter id='ClockTimerSvg_Filter1' x="15.576" y="19.729" width="93.797" height="93.797" filterUnits="userSpaceOnUse">
          <feOffset dy="3"/>
          <feGaussianBlur stdDeviation="3" result="blur-2"/>
          <feFlood floodOpacity="0.071"/>
          <feComposite operator="in" in2="blur-2"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g transform="translate(-12.364 -12.722)">
        <g transform="translate(12.364 12.722)">
          <g>
            <path
              d="M204.356,289.89a39.356,39.356,0,1,0,39.356,39.356,39.4,39.4,0,0,0-39.356-39.356Zm31.261,54.822-3.033-1.75a2.236,2.236,0,1,0-2.236,3.874l3.026,1.746a35.035,35.035,0,0,1-9.69,9.667l-1.739-3.01a2.236,2.236,0,1,0-3.874,2.236l1.741,3.014a34.849,34.849,0,0,1-13.221,3.568v-7.977a2.236,2.236,0,0,0-4.472,0v7.977a34.817,34.817,0,0,1-13.23-3.55l1.75-3.031a2.236,2.236,0,1,0-3.873-2.236l-1.747,3.027a35.187,35.187,0,0,1-9.683-9.683l3.027-1.747a2.236,2.236,0,1,0-2.236-3.874l-3.031,1.75a34.81,34.81,0,0,1-3.55-13.23h7.977a2.236,2.236,0,0,0,0-4.472h-7.975a34.631,34.631,0,0,1,3.548-13.231l3.031,1.751a2.237,2.237,0,0,0,2.236-3.874l-3.026-1.747a35.152,35.152,0,0,1,9.682-9.682l1.747,3.026a2.236,2.236,0,1,0,3.873-2.236l-1.75-3.032a34.647,34.647,0,0,1,13.23-3.548v7.975a2.236,2.236,0,1,0,4.472,0v-7.978a34.846,34.846,0,0,1,13.23,3.55l-1.751,3.033a2.236,2.236,0,1,0,3.874,2.235l1.747-3.025a35.16,35.16,0,0,1,9.683,9.683l-3.027,1.747a2.236,2.236,0,0,0,2.236,3.874l3.033-1.751a34.815,34.815,0,0,1,3.549,13.231h-7.977a2.235,2.235,0,1,0,0,4.47h7.977a34.831,34.831,0,0,1-3.549,13.233Zm0,0"
              transform="translate(-141.909 -265.976)" fill="#f80"
            />
            <g>
              <g clipPath="url(#clip-path)">
                <path
                  d="M199.364,234.362a20.058,20.058,0,0,0-16.762,8.982,48.363,48.363,0,0,0-18.122-4.451v-4.531h6.708a2.236,2.236,0,0,0,0-4.472H153.3a2.236,2.236,0,0,0,0,4.472h6.708v4.531a48.372,48.372,0,0,0-18.12,4.45A20.128,20.128,0,1,0,116.2,272.528a48.3,48.3,0,0,0,23,57.041l-5.788,11.574a2.236,2.236,0,1,0,4,2l5.809-11.615a48.264,48.264,0,0,0,38.049,0l5.809,11.615a2.236,2.236,0,1,0,4-2l-5.785-11.569a48.342,48.342,0,0,0,13.057-10.386,2.237,2.237,0,1,0-3.344-2.971,43.631,43.631,0,1,1,9.264-16.625,2.237,2.237,0,0,0,4.288,1.273,48.4,48.4,0,0,0-.272-28.338,20.125,20.125,0,0,0-8.922-38.164Zm-81.6,33.943A15.655,15.655,0,1,1,137.9,245.431,48.329,48.329,0,0,0,117.768,268.3Zm88.953,0a48.338,48.338,0,0,0-20.13-22.872,15.655,15.655,0,1,1,20.13,22.872Zm0,0"
                  transform="translate(-99.798 -223.865)" fill="#f80"
                />
              </g>
              <path
                d="M428.972,491.627a2.236,2.236,0,1,1-2.236-2.237,2.237,2.237,0,0,1,2.236,2.237Zm0,0"
                transform="translate(-324.039 -405.995)" fill="#f80"
              />
            </g>
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#ClockTimerSvg_Filter1)">
            <circle cx="37.898" cy="37.898" r="37.898" transform="translate(24.58 25.73)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ClockTimer;