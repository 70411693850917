import serviceCall from "../../services";

const removeFromBoxUseCase = async ({ids}) => {
	const result = await serviceCall(
		'removeFromBoxFlashCard',
		{
			ids: ids
		},
		'cloud',
	)

	return result
}

export default removeFromBoxUseCase