import React, {useContext} from 'react'
import classNames from 'classnames'
import styles from './DraggableItemsCard.module.scss'
import colorsContext from '../../contexts/ColorsContext'
import {useSelector} from "react-redux";

const DraggableItemsCard = (
  {
    backgroundColor,
    optionsBackgroundColor,
    optionsTextColor,
    itemsData,
    onClickOption,
    onDragStart,
    onDrop,
    isNavMenu = false,
  }
) => {
  const {colors} = useContext(colorsContext)
  const {hideNavMenu} = useSelector(state => state.sharedSection)

  const renderItems = () => {
    return itemsData.map((item, i) => {
      return (
        <div
          key={i}
          className={classNames(styles.choiceItem, {[styles.used]: item.used})}
          draggable={!item.used}
          onDragStart={() => {
            if (!item.used) {
              onDragStart(item)
            }
          }}
          onDragOver={(e) => {
            if (item.used) {
              e.stopPropagation()
              e.preventDefault()
            }
          }}
          onDrop={e => onDrop(e, item)}
          style={{
            background: optionsBackgroundColor ?? colors['C522'],
            color: optionsTextColor ?? colors['C523'],
          }}
          onClick={() => {
            if (!item.used) {
              onClickOption(item, i)
            }
          }}
          dangerouslySetInnerHTML={{__html: item.text ? item.text.sentenceString : null}}
        />
      )
    })
  }

  return (
    /*
    * style={{background: colors['C183'] }}
    * */
    <div className={classNames(styles.choicesCardContainer, {[styles.sectionNavMenu]: isNavMenu && !hideNavMenu})}>
      <div className={styles.choicesCard} style={{background: backgroundColor ?? colors['C521']}}>
        {renderItems()}
      </div>
    </div>
  )
}

export default DraggableItemsCard