import { AnswerStatusEnums, INLINE } from '../../../enums/globalEnums/globalEnums'

const checkTypeTheAnswerMapper = (stackId, response, data) => {
  return {
    ...data,
    stacks: [...data.stacks].map(stack => {
      if (stack.id === stackId) {
        return {
          ...stack,
          items: [...stack.items].map(item => {
            const responseItem = response.find(obj => obj.exerciseItemId === item.id)
            const answerStatus = () => {
              if (responseItem.userAnswerItemResponse.every(obj => obj.userAnswerStatus === AnswerStatusEnums.CORRECT)) {
                return AnswerStatusEnums.CORRECT
              } else if (responseItem.userAnswerItemResponse.some(obj => obj.userAnswerStatus === AnswerStatusEnums.INCORRECT)) {
                return AnswerStatusEnums.INCORRECT
              } else {
                return AnswerStatusEnums.SKIPPED
              }
            }
            if (responseItem) {
              const userAnswerStatus = answerStatus()
              return {
                ...item,
                checked: true,
                showLock: userAnswerStatus === AnswerStatusEnums.INCORRECT,
                userAnswer: {
                  userInputText: item.inputType === INLINE
                    ? [...item.userAnswer.userInputText].map((o, i) => {
                      const userAnswerData = responseItem.userAnswerItemResponse.find(obj => obj.listIndex === i)
                      return { ...o, userAnswerData: userAnswerData ?? null }
                    })
                    : { ...item.userAnswer.userInputText, userAnswerData: responseItem.userAnswerItemResponse[0] },
                  answerIsCorrect: userAnswerStatus === AnswerStatusEnums.CORRECT,
                  userAnswerStatus: userAnswerStatus,
                  hasAlsoCorrect: responseItem.userAnswerItemResponse.some(obj => obj.exerciseHasAlsoCorrect),
                  isCorrectWithMistake: userAnswerStatus === AnswerStatusEnums.CORRECT && responseItem.userAnswerItemResponse?.some(obj => obj.wordByWordResult?.some(it => it.match && it.actionToCorrect === 1)),
                },
              }
            }
            return item
          }),
        }
      }
      return stack
    }),
  }
}

export default checkTypeTheAnswerMapper