import componentStyle from './TypingIndicator.module.scss'

const TypingIndicator = ({color}) => {
	return (
		<div className={componentStyle.container}>
			<span style={{background: color}}></span>
			<span style={{background: color}}></span>
			<span style={{background: color}}></span>
		</div>
	)
}

export default TypingIndicator