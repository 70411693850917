import * as React from "react"

const RadioStationSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <g>
            <g>
                <path d="M333.6,276.8c7.1,7.1,18.7,7.1,25.9,0c57.1-57.1,57.1-149.8,0-206.9c-7-7.3-18.6-7.5-25.9-0.4c-7.3,7-7.5,18.6-0.4,25.9 c0.1,0.2,0.3,0.3,0.4,0.4c42.9,42.9,42.9,112.4,0,155.2C326.5,258,326.5,269.6,333.6,276.8z M152.5,276.8c7.3,7,18.8,6.8,25.9-0.5 c6.8-7.1,6.8-18.3,0-25.4c-42.9-42.9-42.9-112.4,0-155.2c7.3-7,7.5-18.6,0.4-25.9c-7-7.3-18.6-7.5-25.9-0.4 c-0.2,0.1-0.3,0.3-0.4,0.4C95.4,127,95.4,219.6,152.5,276.8z" />
                <path d="M113.7,31c7-7.3,6.8-18.8-0.4-25.9c-7.1-6.8-18.3-6.8-25.4,0C-4.8,98-4.8,248.5,87.9,341.4c7,7.3,18.6,7.5,25.9,0.4 c7.3-7,7.5-18.6,0.4-25.9c-0.1-0.2-0.3-0.3-0.4-0.4C35.3,236.9,35.3,109.6,113.7,31z M424.2,5.1c-7.3-7-18.8-6.8-25.9,0.4 c-6.8,7.1-6.8,18.3,0,25.4c78.5,78.6,78.5,205.9,0,284.6c-7.3,7-7.5,18.6-0.5,25.9s18.6,7.5,25.9,0.5c0.2-0.1,0.3-0.3,0.5-0.5 C516.9,248.5,516.9,98.1,424.2,5.1L424.2,5.1z M277.5,223.8c27.9-11.9,40.9-44.1,29-72s-44.1-40.9-72-29s-40.9,44.1-29,72 c5.6,13.1,15.9,23.4,29,29L129.2,486.9c-3.8,9.4,0.8,20,10.2,23.8c9.4,3.8,20-0.8,23.8-10.2l17.5-43.8c0.7,0.2,1.4,0.3,2.1,0.4 h146.3c0.7-0.1,1.4-0.2,2.1-0.4l17.5,43.8c2.8,6.9,9.5,11.5,17,11.5c2.3,0,4.6-0.4,6.8-1.3c9.4-3.8,13.9-14.4,10.2-23.8l0,0 L277.5,223.8z M256,155c10.1,0,18.3,8.2,18.3,18.3s-8.2,18.3-18.3,18.3c-10.1,0-18.3-8.2-18.3-18.3S245.9,155,256,155z  M287.5,347.3h-63l31.5-78.8L287.5,347.3z M195.2,420.5l14.6-36.6h92.3l14.6,36.6H195.2z" />
            </g>
        </g>
    </svg>
)

export default RadioStationSvg
