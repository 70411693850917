const faqMapper = (data) => {
  const contentData = {
    native: data?.nativeLanguage?.faqData,
    english: data?.englishLanguage?.faqData
  }
  return contentData

  if (contentData)
    contentData.forEach((data) => {
      data.FAQs = data.FAQs.sort(compare);
    });

  return contentData.sort(compare);
}

const compare = (a, b) => {
  return Math.sign(a.priority - b.priority);
}

export default faqMapper