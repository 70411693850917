import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import serviceCall from '../../services'
import getTimeFromSeconds from '../../operations/getTimeFromSeconds'
import {baseAsyncThunk} from "../baseAsyncThunk";
import gameUseCase from "../../useCase/gameUseCase/gameUseCase";

const initialState = {
  loading: false,
  data: null,
  recordData: null,
  seconds: 0,
  timer: '00:00',
  error: '',
  result: null,
}

export const getGameWords = createAsyncThunk(
  'game/getGameWords',
  ({minUnitId, maxUnitId, number, gameType}) => {
    return serviceCall(
      'fetchGameWords',
      {minUnitId, maxUnitId, number, gameType},
      'cloud',
    )
  },
)

export const sendGameResult = createAsyncThunk(
  'game/postGameResult',
  ({unitId, userScore, gameType}, {getState}) => {
    const {game} = getState()
    return serviceCall(
      'postGameResult',
      {unitId, userScore: userScore ?? (game.seconds * 1000), gameType},
      'cloud',
    )
  },
)

export const getGamesStatistics = createAsyncThunk(
  'game/postGameResult',
  ({unitId, userScore, gameType}) => {
    return serviceCall(
      'postGameResult',
      {unitId, userScore, gameType},
      'cloud',
    )
  },
)

export const getGameAllUnitsRecord = baseAsyncThunk(
  'game/getGameAllUnitsRecord',
  ({gameType}) => {
    return gameUseCase({gameType});
  }
);


const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    increaseTime: (state, action) => {
      if (action.payload > 0) {
        state.seconds += action.payload
      } else {
        ++state.seconds
      }
      state.timer = getTimeFromSeconds(state.seconds)
    },
    resetGameData: (state) => {
      state.loading = false
      state.seconds = 0
      state.timer = '00:00'
      // state.data = {}
      state.error = ''
    },
    resetGameTimer: (state) => {
      state.seconds = 0
      state.timer = '00:00'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getGameWords.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getGameWords.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data
    })
    builder.addCase(getGameWords.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(sendGameResult.pending, (state) => {
      state.loading = true
    })
    builder.addCase(sendGameResult.fulfilled, (state, action) => {
      state.loading = false
      state.result = action.payload.data
    })
    builder.addCase(sendGameResult.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(getGameAllUnitsRecord.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getGameAllUnitsRecord.fulfilled, (state, action) => {
      console.log('Reducer action payload:', action.payload)
      state.loading = false
      state.recordData = action.payload
    });
    builder.addCase(getGameAllUnitsRecord.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  },
})

export const {increaseTime, setTime, resetGameData, resetGameTimer} = gameSlice.actions

export default gameSlice.reducer