import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import visol from '../../apis/publicAxiosInstance'

const initialState = {
	loading: false,
	data: {},
	error: '',
}

export const createVisitor = createAsyncThunk(
	'visitor/createVisitor',
	({ deviceId, languageTitle }) => {
		return visol.post('/enrollment/visitors', {
			'deviceId': deviceId,
			'nativeLanguage': languageTitle,
			'platform': 'web',
		}).then((response) => {
			return {
				data: response.data,
				status: response.status,
			}
		})
	},
)

const visitorSlice = createSlice({
	name: 'visitor',
	initialState,
	extraReducers: (builder) => {
		builder.addCase(createVisitor.pending, (state) => {
			state.loading = true
		})
		builder.addCase(createVisitor.fulfilled, (state, action) => {
			state.loading = false
			state.data = action.payload.data
		})
		builder.addCase(createVisitor.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message
		})
	},
})

export default visitorSlice.reducer