const BackArrowSvg = ({color}) => {
  return(
    <>
      <svg xmlns="http://www.w3.org/2000/svg"
           width={14}
           height={28}
           viewBox={'0 0 14 28'}
           fill="none"
      >
        <path
          stroke={color}
          strokeLinecap="round"
          d="M13 1 1.07 12.93a.1.1 0 0 0 0 .14L13 25"
        />
      </svg>
    </>
  )
}
export default BackArrowSvg