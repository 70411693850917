import React, {useContext, useEffect, useRef, useState} from 'react'
import Image from '../../../../../components/Image'
import HtmlTextWrapper from '../../../../../components/HtmlTextWrapper/HtmlTextWrapper'
import {moreOptionsList} from '../../../../../data'
import colorsContext from '../../../../../contexts/ColorsContext'
import componentStyle from './StructureImageComponent.module.scss'
import structureStyle from './../../StructuredComponents.module.scss'
import Modal from '../../../../../components/Modal'
import structuresContext from "../../../../../contexts/StructuresContext";
import classNames from "classnames";
import {robotTeacherScrollIntoViewByElement} from "../../../../../operations/robotTeacherScrollIntoViewByElement";
import {useSelector} from "react-redux";

const StructureImageComponent = ({picture, setImageLoaded}) => {
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false)
  const {colors} = useContext(colorsContext)
  const {scrolling} = useSelector(state => state.roboTeacher)
  const {isRoboTeacher, roboTeacherData} = useContext(structuresContext)
  const isHidden = (isRoboTeacher ?? false) ? ((roboTeacherData.currentTime ?? 0) < (picture.hiddenTime ?? 0)) : false
  const textIsHidden = (isRoboTeacher ?? false) ? ((roboTeacherData.currentTime ?? 0) < (picture?.text?.hiddenTime ?? 0)) : false
  const elRef = useRef(null)
  const textElRef = useRef(null)
  const imageContainerRef = useRef(null)

  useEffect(() => {
    if (picture?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isHidden])

  useEffect(() => {
    if (picture?.text?.hiddenTime > 0 && !textIsHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(textElRef?.current)
    }
  }, [textIsHidden])

  return (
    <>
      <div
        ref={imageContainerRef}
        className={classNames(structureStyle.roboTeacherView, 'text-center')}
      >
        {picture?.uri &&
          <button
            ref={elRef}
            data-rt-id={picture?.id}
            className={classNames(componentStyle.imageContainer, {['hide-element']: isHidden})}
            onClick={() => setImageModalIsOpen(true)}
          >
            <Image uri={picture.uri} setLoaded={setImageLoaded}/>
          </button>
        }
        {picture?.text &&
          <div ref={textElRef} className={classNames(componentStyle.imageCaption, {['hide-element']: textIsHidden})}>
            <HtmlTextWrapper
              data={picture?.text}
              showPhraseOptions
              moreOptionsList={moreOptionsList}
              textColor={colors['C23']}
              optionsColor={colors['C23']}
              highlightScrollInto={imageContainerRef}
            />
          </div>
        }
      </div>
      {imageModalIsOpen &&
        <Modal closeModal={() => setImageModalIsOpen(false)}>
          <div className={componentStyle.imageModal}>
            <Image uri={picture?.uri}/>
            {picture?.text &&
              <div className={componentStyle.imageCaption}>
                <HtmlTextWrapper
                  data={picture?.text}
                  showPhraseOptions
                  moreOptionsList={moreOptionsList}
                  textColor={colors['C160']}
                  optionsColor={colors['C160']}
                />
              </div>
            }
          </div>
        </Modal>
      }
    </>
  )
}

export default StructureImageComponent