import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import publicAxiosInstance from '../../apis/publicAxiosInstance'
import {v4} from 'uuid'
import nativeLanguageUseCase from "../../useCase/nativeLanguageUseCase/nativeLanguageUseCase";
import serviceCall from "../../services";

const initialState = {
  loading: false,
  fetchNativeLanguages: false,
  languages: [],
  error: '',
}

export const fetchNativeLanguages = createAsyncThunk(
  'nativeLanguage/fetchNativeLanguages',
  () => {
    return nativeLanguageUseCase()
  },
)

export const setNativeLanguage = createAsyncThunk(
  'nativeLanguage/setNativeLanguages',
  ({language}) => {
    return serviceCall(
      'updateNativeLanguage',
      {language},
      'cloud'
    )
  },
)

const nativeLanguageSlice = createSlice({
  name: 'nativeLanguage',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchNativeLanguages.pending, (state) => {
      state.fetchNativeLanguagesLoading = true
    })
    builder.addCase(fetchNativeLanguages.fulfilled, (state, action) => {
      state.fetchNativeLanguagesLoading = false
      state.languages = action.payload
    })
    builder.addCase(fetchNativeLanguages.rejected, (state, action) => {
      state.fetchNativeLanguagesLoading = false
      state.error = action.error.message
    })
    builder.addCase(setNativeLanguage.pending, (state) => {
      state.loading = true
    })
    builder.addCase(setNativeLanguage.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(setNativeLanguage.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  },
})

export default nativeLanguageSlice.reducer