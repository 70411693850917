import styles from "./PillSmButton.module.scss"

const PillSmButton = ({children, disabled, color, onClick}) => {
  return (
    <button
      className={styles.pillSmButton}
      onClick={onClick}
      disabled={disabled}
      style={{color: color, borderColor: color}}
    >
      {children}
    </button>
  )
}

export default PillSmButton