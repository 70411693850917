import removeExtraSpace from "../operations/removeExtraSpace";
import {SPELLING_CUSTOM, SPELLING_SYLLABUS, SPELLING_SYSTEM} from "../enums/toolsEnums/spellingSeparationEnums";
import {AnswerStatusEnums} from "../enums/globalEnums/globalEnums";

const createSystemType = (data, initialNumberOfRepetitions) => {
  return data.map(((word, wordIndex) => {
    const clearWord = removeExtraSpace(word.title)

    return {
      index: wordIndex,
      title: clearWord,
      numberOfRepetitions: initialNumberOfRepetitions,
      status: AnswerStatusEnums.NONE,
      isFinished: false,
      wrongAnswersCount: 0,
      correctAnswersCount: 0,
      items: [{
        index: 0,
        value: clearWord,
        input: ''
      }],
    }
  }))
}
const createSyllableType = (data, type = SPELLING_SYLLABUS, initialNumberOfRepetitions) => {
  return data.map(((word, wordIndex) => {
    const clearWord = removeExtraSpace(word.title)

    let syllable = ''
    switch (type) {
      case SPELLING_SYLLABUS:
        syllable = word?.syllable
        break
      case SPELLING_CUSTOM:
        syllable = word?.customSyllable
        break
    }

    const items = syllable?.split('.')

    return {
      index: wordIndex,
      title: clearWord,
      numberOfRepetitions: initialNumberOfRepetitions,
      status: AnswerStatusEnums.NONE,
      isFinished: false,
      wrongAnswersCount: 0,
      correctAnswersCount: 0,
      items: items.map((item, itemIndex) => {
        return {
          index: itemIndex,
          value: item,
          input: ''
        }
      }),
    }
  }))
}
const spellingWordPyramidFactory = (data, initialNumberOfRepetitions, type) => {
  switch (type) {
    case SPELLING_SYSTEM:
      return createSystemType(data, initialNumberOfRepetitions)
    case SPELLING_SYLLABUS:
      return createSyllableType(data, SPELLING_SYLLABUS, initialNumberOfRepetitions)
    case SPELLING_CUSTOM:
      return createSyllableType(data, SPELLING_CUSTOM, initialNumberOfRepetitions)
  }
}
export default spellingWordPyramidFactory
