import { useSearchParams } from 'react-router-dom'

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getCurrentQueryParamsObject = () => {
    return Array.from(searchParams.entries()).reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
  }

  const addQueryParams = (params, options) => {
    const currentParams = getCurrentQueryParamsObject()
    setSearchParams({ ...currentParams, ...params }, options)
  }

  const removeQueryParams = (keys) => {
    const removeSingleParam = (key) => {
      // Check if the parameter exists
      if (searchParams.has(key)) {
        // Remove the parameter
        searchParams.delete(key);
      }
    }

    // Check keys type ( string[] | string )
    if (typeof keys === 'object' && keys.length > 0) {
      keys.forEach(key => {
        removeSingleParam(key)
      })
    } else if (typeof keys === 'string') {
      removeSingleParam(keys)
    }

    // Update the search parameters in the URL
    setSearchParams(searchParams)
  }

  return { getCurrentQueryParamsObject, addQueryParams, removeQueryParams }
}

export default useQueryParams