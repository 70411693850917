import { Link } from 'react-router-dom'
import TranslatedText from '../TranslatedText/TranslatedText'
import styles from './SkipButton.module.scss'
import SkipSvg from '../../icons/SkipSvg'
import classNames from 'classnames'

const SkipButton = ({ linkTo, disabled, onClick, fixed, color, translate = true }) => {
	if (linkTo) {
		return (
			<Link
				to={linkTo}
				className={classNames(styles.skip, { [styles.fixed]: fixed })}
				style={{ color }}
			>
				{translate ? <TranslatedText translationKey='skip'/> : 'Skip'}
				<SkipSvg color={color} />
			</Link>
		)
	} else {
		return (
			<button
				onClick={onClick}
				disabled={disabled}
				className={classNames(styles.skip, { [styles.fixed]: fixed })}
				style={{ color, opacity: disabled ? 0.5 : 1 }}
			>
				{translate ? <TranslatedText translationKey='skip'/> : 'Skip'}
				<SkipSvg color={color} />
			</button>
		)
	}
}

export default SkipButton