import * as React from "react"

const MaleSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <g>
            <g>
                <path d="M256,328c-27.2,0-36.8-6.4-45.6-12.8c-2.4-1.6-4-3.2-6.4-4c-24-14.4-41.6-39.2-49.6-74.4c-11.2-4-26.4-16-26.4-44.8 c0-22.4,10.4-29.6,11.2-30.4c4-2.4,8.8-1.6,11.2,2.4s1.6,8.8-2.4,11.2s-4.8,4-4.8,17.6c0,27.2,16.8,30.4,18.4,30.4 c3.2,0.8,6.4,3.2,7.2,6.4c4.8,22.4,15.2,52.8,42.4,68.8c3.2,1.6,5.6,3.2,8,4.8c8,5.6,14.4,9.6,36,9.6c22.4,0,28-4,36-9.6 c2.4-1.6,4.8-3.2,8-4.8c27.2-16,38.4-45.6,42.4-68.8c0.8-3.2,4-6.4,7.2-6.4c1.6,0,18.4-3.2,18.4-30.4c0-13.6-4.8-17.6-4.8-17.6 c-4-2.4-4.8-7.2-1.6-11.2c2.4-4,8-4.8,11.2-1.6c0.8,0.8,11.2,8.8,11.2,30.4c0,28.8-15.2,40.8-26.4,44.8c-8,34.4-24.8,60-49.6,74.4 c-2.4,1.6-4.8,3.2-6.4,4C292.8,321.6,283.2,328,256,328L256,328z" />
            </g>
            <g>
                <path d="M144,176c-3.2,0-5.6-1.6-7.2-4.8c-1.6-4,0-8.8,4-10.4c20.8-9.6,36.8-30.4,52-68c0.8-2.4,3.2-4,5.6-4.8s5.6,0,7.2,2.4 c23.2,21.6,56,16.8,77.6,13.6c6.4-0.8,12-1.6,16-1.6c32,0,44.8,23.2,53.6,40c5.6,11.2,9.6,18.4,15.2,18.4c4.8,0,8,3.2,8,8 s-3.2,8-8,8c-15.2,0-22.4-13.6-29.6-26.4c-8.8-16-16.8-31.2-39.2-31.2c-3.2,0-8,0.8-13.6,1.6c-20.8,3.2-55.2,8-82.4-10.4 c-16,35.2-33.6,56-56,66.4C146.4,176,144.8,176,144,176L144,176z" />
            </g>
            <g>
                <path d="M496,504c-4.8,0-8-3.2-8-8c0-44,0-79.2-62.4-94.4c-52-12-113.6-36-113.6-76.8V292c0-4.8,3.2-8,8-8s8,3.2,8,8v32.8 c0,23.2,36.8,45.6,100.8,61.6C504,404,504,452.8,504,495.2C504,500,500.8,504,496,504z" />
            </g>
            <g>
                <path d="M16,496H8c0-43.2,0-92,75.2-109.6c64-15.2,100.8-37.6,100.8-61.6V292c0-4.8,3.2-8,8-8s8,3.2,8,8v32.8 c0,40.8-61.6,64.8-113.6,76.8C24,416.8,24,452,24,495.2L16,496z" />
            </g>
            <g>
                <path d="M256,440c-45.6,0-89.6-20.8-120-56.8c-3.2-3.2-2.4-8,0.8-11.2s8-2.4,11.2,0.8c28,32.8,67.2,51.2,108,51.2 s80-18.4,108-51.2c3.2-3.2,8-4,11.2-0.8s4,8,0.8,11.2C345.6,419.2,301.6,440,256,440z" />
            </g>
            <g>
                <path d="M141.6,178.4c-3.2,0-6.4-1.6-7.2-4.8c-6.4-16.8-6.4-22.4-6.4-61.6c0-33.6,20.8-60.8,42.4-70.4C187.2,18.4,213.6,8,256,8 c20.8,0,40.8,4,57.6,11.2c28,8,52.8,4.8,52.8,4.8c4-0.8,8,2.4,8.8,6.4c0,0.8,4,18.4-6.4,33.6c10.4,16.8,14.4,40.8,14.4,57.6 c0,15.2,0,35.2-6.4,52c-1.6,4-6.4,6.4-10.4,4.8s-6.4-6.4-4.8-10.4c5.6-13.6,5.6-31.2,5.6-46.4c0-16-4.8-40-14.4-52.8 c-2.4-3.2-2.4-7.2,0-10.4c4.8-6.4,6.4-12.8,6.4-18.4c-10.4,0-29.6,0-50.4-5.6c-0.8,0-0.8,0-1.6-0.8c-14.4-6.4-32-9.6-51.2-9.6 c-47.2,0-63.2,13.6-73.6,28.8c-0.8,1.6-2.4,2.4-4,3.2c-16.8,6.4-34.4,28-34.4,56c0,38.4,0,41.6,5.6,55.2c1.6,4-0.8,8.8-4.8,10.4 C144,178.4,142.4,178.4,141.6,178.4L141.6,178.4z" />
            </g>
        </g>
    </svg>
)

export default MaleSvg
