import PageLayer from "../../components/PageLayer/PageLayer";
import Header from "../../components/Header/Header";
import React, {useContext, useEffect, useMemo, useState} from "react";
import colorsContext from "../../contexts/ColorsContext";
import helpStyle from './HelpPage.module.scss'
import classNames from "classnames";
import FramerMotion from "../../layouts/FramerMotion";
import { useDispatch, useSelector } from "react-redux";
import { getHelpPageData, setHelpPageLanguage } from "../../redux/features/helpSlice";
import Image from "../../components/Image";
import HtmlTextWrapper from "../../components/HtmlTextWrapper/HtmlTextWrapper";
import SwitchLanguageButton from "../../components/SwitchLanguageButton/SwitchLanguageButton";
import { languagesEnums } from "../../enums/languageEnums/languageEnums";
import { ENGLISH, NATIVE } from "../../enums/roboTeacher/dataStatusEnums";
import HelpPageShimmer from "./HelpPageShimmer";
import VideoSvg from "../../icons/VideoSvg";
import TranslatedText from '../../components/TranslatedText/TranslatedText';
import HelpVideoModal from "../../atomicComponents/organisms/HelpVideoModal/HelpVideoModal";
import htmlToText from "../../operations/htmlToText";

const HelpPage = ({ page }) => {
  const { colors } = useContext(colorsContext)
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const { selectedTranslationLanguage } = useSelector(state => state.translation)
  const { helpData, getHelpPageDataLoading } = useSelector(state => state.help)

  useEffect(() => {
    dispatch(getHelpPageData({ page }))
  }, [])

  useEffect(() => {
    if (selectedTranslationLanguage.key === languagesEnums.ENGLISH_LANGUAGE) {
      dispatch(setHelpPageLanguage(ENGLISH))
    } else {
      dispatch(setHelpPageLanguage(NATIVE))
    }
  }, [selectedTranslationLanguage])

  return (
    <>
      <PageLayer>
        <FramerMotion
          className='minh-100vh'
          style={{ background: colors['C183'] }}
        >
          <Header
            title='Help'
            withBackButton
            rightItems={<SwitchLanguageButton />}
          />

          {helpData?.videoLink?.length > 0 &&
            <button
              onClick={() => setIsOpen(true)}
              className={helpStyle.watchVideosContainer}
              style={{ background: colors['C73'], borderColor: colors['C590'] }}
            >
              <span style={{ color: colors['C593'] }}><TranslatedText translationKey='watch.the.video' /></span>
              <div><VideoSvg color={colors['C593']} /></div>
            </button>
          }
          <div className='pt-4 pb-3'>
            <div className='container container-1 position-relative'>
              {helpData &&
                <>
                  <div className={helpStyle.topImage}>
                    <Image onClickZoomIn uri={helpData?.intro?.imageUri} />
                  </div>
                  <div className={helpStyle.descCard} style={{ borderColor: '#FFBB61' }} dir={helpData?.direction}>
                    <h2 className={classNames(helpStyle.title, 'res:fs-lg ')}>
                      {helpData?.intro?.iconUri &&
                        <div className={helpStyle.iconContainer} style={{ backgroundColor: '#FFBB61' }}>
                          <Image uri={helpData?.intro?.iconUri} className={helpStyle.icon} />
                        </div>
                      }
                      <HtmlTextWrapper
                        data={helpData?.intro?.title}
                        textColor={colors['C2']}
                      />
                    </h2>
                    <HtmlTextWrapper
                      data={helpData?.intro?.description}
                      className='text-center res:fs-md'
                      textColor={colors['C2']}
                    />
                  </div>
                  {/*{getHelpPageDataLoading ? <HelpPageShimmer /> :*/}
                    <div>
                      {helpData?.helps?.map((help, i) => {
                        return (
                          <div className={helpStyle.helpItemContainer} dir={helpData?.direction} key={i}>
                            <div className={helpStyle.itemNumber} style={{ background: '#FCFCFCCC', borderColor: "#FCFCFC" }}>
                              {help.index + 1}
                            </div>
                            <div className={helpStyle.itemCard} style={{ background: '#FCFCFCCC', borderColor: "#FCFCFC" }}>
                              {htmlToText(help?.title?.sentence) &&
                                <h3 className={classNames(helpStyle.title, 'res:fs-lg')} style={{ color: '#000' }}>
                                  {help?.iconUri &&
                                    <Image uri={help?.iconUri} className={helpStyle.icon} />
                                  }
                                  <HtmlTextWrapper
                                    data={help?.title}
                                    textColor={'#000'}
                                  />
                                </h3>
                              }
                              <HtmlTextWrapper
                                data={help?.description}
                                className='res:fs-md'
                                style={{ direction: helpData?.direction }}
                                textColor={'#000'}
                              />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  {/*}*/}
                </>
              }
            </div>
          </div>
        </FramerMotion>
      </PageLayer>
      {isOpen &&
        <HelpVideoModal videos={helpData?.videoLink || []} onClose={() => setIsOpen(false)} />
      }
    </>
  )
}

export default HelpPage