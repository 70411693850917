import serviceCall from "../../services";

const inquiryResendCodeUseCase = async (data) => {
  return await serviceCall(
    'postInquiryResendCode',
    data,
    'cloud'
  )
}

export default inquiryResendCodeUseCase