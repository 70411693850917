import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  newVersionAvailable: null,
  error: '',
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setNewVersionAvailable: (state, action) => {
      state.newVersionAvailable = action.payload
    }
  }
})

export const {setNewVersionAvailable} = globalSlice.actions

export default globalSlice.reducer