import { createSlice } from '@reduxjs/toolkit'
import studySectionsListFactory from "../../factory/studySectionsListFactory";
import { toNumber } from "lodash";
import getUnitUseCase from '../../useCase/levelAndUnitsUseCase/getUnitUseCase';
import { baseAsyncThunk } from '../baseAsyncThunk';

const initialState = {
  sectionsList: [],
  currentUnitData: [],
  studyType: null,
  showSectionIntroduction: false,
  hideNavMenu: false,
  previousUnitNumber: null
}

export const getUnitData = baseAsyncThunk(
  'sharedSection/getUnitData',
  ({ unitId }) => getUnitUseCase({ unitId })
)

const sharedSectionSlice = createSlice({
  name: 'sharedSection',
  initialState,
  reducers: {
    setSectionsList: (state, action) => {
      state.sectionsList = studySectionsListFactory(action.payload.studyType, action.payload.selectedSectionsToReview)
    },
    setCurrentSection: (state, action) => {
      state.sectionsList = [...state.sectionsList].map((item) => {
        if (item.sectionNumber === toNumber(action.payload)) {
          return { ...item, isCurrentSection: true }
        }
        return { ...item, isCurrentSection: false }
      })
    },
    setShowSectionIntroduction: (state, action) => {
      state.showSectionIntroduction = action.payload
    },
    setStudyType: (state, action) => {
      state.studyType = action.payload
    },
    setHideNavMenu: (state, action) => {
      state.hideNavMenu = action.payload
    },
    setPreviousUnitNumber: (state, action) => {
      state.previousUnitNumber = action.payload
    }
  }
})

export const {
  setSectionsList,
  setCurrentSection,
  setShowSectionIntroduction,
  setStudyType,
  setHideNavMenu,
  setPreviousUnitNumber
} = sharedSectionSlice.actions

export default sharedSectionSlice.reducer