import cloudServices from './cloudServices'
import localServices from './localServices'
import {CLOUD, MOCK} from "../enums/base/serviceTypeEnums";

const serviceCall = (serviceName, data, serviceType = 'cloud') => {
	switch (serviceType) {
		case CLOUD: {
			return cloudServices(serviceName, data)
		}
		case MOCK: {
			return localServices(serviceName, data)
		}
	}
}

export default serviceCall