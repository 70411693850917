import publicAxiosInstance from '../apis/publicAxiosInstance'
import fetchReadingMapper from './mapper/sections/fetchReadingMapper'
import fetchConversationMapper from './mapper/sections/fetchConversationMapper'
import matchingWordByWordMapper from './mapper/sections/matchingWordByWordMapper'
import fetchReviewMistakesMapper from './mapper/sections/fetchReviewMistakesMapper'
import matchingSentenceMapper from './mapper/sections/matchingSentenceMapper'
import fetchMoreSectionsMapper from './mapper/sections/fetchMoreSectionsMapper'
import fetchLearningTypeTestMapper from './mapper/evaluationTests/fetchLearningTypeTestMapper'
import blinkGameMapper from './mapper/games/blinkGameMapper'
import exerciseStatisticsMapper from './mapper/statistics/exerciseStatisticsMapper'
import alphabetMapper from './mapper/basic/alphabetMapper'
import commonDigraphsMapper from './mapper/basic/commonDigraphsMapper'
import numbersSectionMapper from './mapper/basic/numbersSectionMapper'
import ordinalNumbersSectionMapper from './mapper/basic/ordinalNumbersSectionMapper'
import {paymentCallbackResultURL} from "../apis/mainAPIs"
import userAxiosInstance from "../apis/userAxiosInstance"
import studentAxiosInstance from "../apis/studentAxiosInstance"
import unitStatisticMapper from "./mapper/statistics/unitStatisticMapper";
import unitGameStatisticMapper from "./mapper/statistics/unitGameStatisticMapper";
import gameStatisticMapper from "./mapper/statistics/gameStatisticsMapper";

const cloudServices = (serviceName, data) => {
  const serviceFunc = eval(serviceName)
  return serviceFunc(data)
}

export const fetchNativeLanguages = async () => {
  return await userAxiosInstance.get('/v1/user-settings/native-languages').then((response) => {
    return response.data
  })
}

export const updateNativeLanguage = async ({language}) => {
  return userAxiosInstance.post('/v1/user_management/setting/action/update/native-language', null, {
    params: {
      nativeLanguage: language
    }
  }).then((response) => {
    return response.data
  })
}

export const postHelpAfterSetNativeLanguageAction = async () => {
  return userAxiosInstance.post('/v1/user_management/setting/action/update/helpAfterSetNativeLanguage').then((response) => {
    return response.data
  })
}

export const postHelpAfterSetThemeAction = async () => {
  return userAxiosInstance.post('/v1/user_management/setting/action/update/helpAfterTheme').then((response) => {
    return response.data
  })
}

export const updateStudyPreferences = ({learningType, spacedRepetition, courseIntensity}) => {
  return userAxiosInstance.post('/v1/user_management/setting/action/update/study-preferences', {
    spacedRepetition,
    courseIntensity,
    learningType,
  }).then(response => {
    return {
      data: response.data,
      status: response.status,
    }
  })
}

export const updateCourseIntensityActionService = ({courseIntensity}) => {
  return userAxiosInstance.post('/v1/user_management/setting/action/update/course-intensity', null, {params: {courseIntensity}}).then(response => {
    return {
      data: response.data,
      status: response.status,
    }
  })
}

export const updateSpacedRepetitionActionService = ({spacedRepetition}) => {
  return userAxiosInstance.post('/v1/user_management/setting/action/update/space-repetition', null, {params: {spacedRepetition}}).then(response => {
    return {
      data: response.data,
      status: response.status,
    }
  })
}

export const updateLearningTypeActionService = ({learningType}) => {
  return userAxiosInstance.post('/v1/user_management/setting/action/update/learning-type', null, {params: {learningType}}).then(response => {
    return {
      data: response.data,
      status: response.status,
    }
  })
}

export const fetchMemoryTypeTest = async () => {
  return await userAxiosInstance.get('/v1/evaluation-tests/memory-type-test').then((response) => {
    return response.data
  })
}

export const fetchLearningTypeTest = async () => {
  const response = await userAxiosInstance.get('/v1/evaluation-tests/learning-type-test').then(response => {
    return response.data
  })

  return fetchLearningTypeTestMapper(response)
}

export const fetchPlacementTypeTest = async () => {
  return userAxiosInstance.get('/v1/evaluation-tests/placement-type-test').then(response => {
    const userAnswers = {};
    response.data.questions.map(q => {
      return userAnswers[q.questionNumber] = 0
    });
    return {
      data: response.data,
      testNumber: response.data.testNumber,
      questions: response.data.questions,
      status: response.status,
      userAnswers,
    }
  })
}

export const postPlacementTypeTest = async (data) => {
  return userAxiosInstance.post('/v1/evaluation-tests/placement-type-test', data).then(response => {
    return response.data
  })
}

/*
 * send contact
 */
export const sendContact = async ({name, email, category, message}) => {
  return await userAxiosInstance.post('/v1/contact-us',
    {name, email, category, message},
    null
  ).then(response => {
    return response.data
  })
}
/*
 * Fetch Static Content
 */
export const fetchStaticContentData = async ({language, type}) => {
  return await publicAxiosInstance.get('/v1/public-static-content', {params: {language, type}}).then(response => {
    return response.data
  })
}

/*
 * Fetch app user
 */
export const fetchAppUser = async () => {
  return await userAxiosInstance.get(`/v1/user_management/user`).then(response => {
    return response.data
  })
}

/*
 * Delete app user
 */
/* TODO: CHANGE_END_POINT */
export const deleteAppUser = async () => {
  return await publicAxiosInstance.delete(`/app-userss`).then(response => {
    return response.data
  })
}

/*
 * Fetch app user
 */
export const fetchTableOfContent = async () => {
  return await studentAxiosInstance.get(`/v1/static-content`, {params: {key: 'TABLE_OF_CONTENT'}}).then(response => {
    return response.data
  })
}

/*
 * Fetch radio stations
 */
export const fetchRadioStations = async () => {
  return await userAxiosInstance.get(`/v1/extra-facilities/`, {params: {extraFacilityType: 'RADIO'}})
    .then(response => {
      return response.data
    })
}

/*
 * Fetch TED stations
 */
export const fetchTedVideos = async () => {
  return await userAxiosInstance.get('/v1/extra-facilities/', {params: {extraFacilityType: 'TED'}})
    .then(response => {
      return response.data
    })
}

/*
 * Fetch TED stations
 */
export const fetchNewspapers = async () => {
  return await userAxiosInstance.get('/v1/extra-facilities/', {params: {extraFacilityType: 'NEWS'}})
    .then(response => {
      return response.data
    })
}

/*
 * fetch about data
 */
export const fetchAboutDescription = async () => {
  return await studentAxiosInstance.get('/v1/static-content?key=ABOUT_VELLSO').then(response => {
    return response.data
  })
}

/*
 * fetch FAQ data
 */
export const fetchFaqList = async ({nativeLanguage}) => {
  return await publicAxiosInstance.get('/v1/faq/form', {params: {nativeLanguage}}).then(response => {
    return response.data
  })
}

/*
 * fetch box data
 */
export const fetchBoxes = async ({categoryId}) => {
  const query = (categoryId) ? '?categoryId=' + categoryId : '';
  const response = await studentAxiosInstance.get('/v1/memory-box/boxes', {
    params: {
      categoryId
    },
  }).then(response => {
    return response.data
  })

  return response
}

export const fetchYourSelectionBoxes = async ({categoryId, isVocab}) => {
  const response = await studentAxiosInstance.get('/v1/memory-box/boxes', {
    params: {
      categoryId, isVocab
    },
  }).then(response => {
    return response.data
  })

  return response
}

export const fetchUnitSummeryInMemoryBox = async ({categoryId}) => {
  const query = (categoryId) ? '?categoryId=' + categoryId : '';
  const response = await studentAxiosInstance.get('/v1/memory-box/units' + query).then(response => {
    return response.data
  })

  return response
}

/*
 * fetch memory box category data
 */
export const fetchAllMemoryBoxCategory = async () => {
  const response = await studentAxiosInstance.get('/v1/memory-box/categories').then(response => {
    return response.data
  })

  return response
}

/*
 * fetch memory box category summery data
 */
export const fetchAllCategoriesSummeryUseCase = async () => {
  const response = await studentAxiosInstance.get('/v1/memory-box/categories/summery').then(response => {
    return response.data
  })

  return response
}

/*
 * add memory box category
 */
export const addMemoryBoxCategory = async ({title, color}) => {
  return await studentAxiosInstance.post('/v1/memory-box/categories',
    {
      title,
      color
    }
  ).then(response => {
    return response.data
  })
}

export const addMemoryBoxFlashCard = async ({bodyFormData}) => {
  return await studentAxiosInstance.post('/v1/memory-box/flashcards',
    bodyFormData
  ).then(response => {
    return response.data
  })
}

export const editMemoryBoxFlashCard = async ({id, bodyFormData}) => {
  return await studentAxiosInstance.put(`/v1/memory-box/flashcards/${id}`,
    bodyFormData
  ).then(response => {
    return response.data
  })
}

/*
 * edit memory box category
 */
export const editMemoryBoxCategory = async ({id, title, color, sideToShow, orderOfDisplay}) => {
  return await studentAxiosInstance.put(`/v1/memory-box/categories/${id}`,
    {
      title,
      color,
      sideToShow,
      orderOfDisplay
    }).then(response => {
    return response.data
  })
}

/*
 * delete memory box category
 */
export const deleteMemoryBoxCategory = async (id) => {
  return await studentAxiosInstance.delete('/v1/memory-box/categories/' + id,
    null,
    null).then(response => {
    return response.data
  })
}

/*
 * search memory box flashcard
 */
export const searchMemoryBoxFlashCard = async (searchCrieria) => {
  return await studentAxiosInstance.post('/v1/memory-box/flashcards/search?size=100000',
    searchCrieria,
    null).then(response => {
    return response.data
  })
}

export const fetchAllMemoryBoxFlashCards = async ({categoryId, frontText, sortAsc, sortBy}) => {
  return await studentAxiosInstance.post('/v1/memory-box/flashcards/search?size=100000',
    {categoryId, frontText, sortAsc, sortBy},
    null).then(response => {
    return response.data
  })
}

export const fetchAllMemoryBoxFlashCardsByIsVocab = async ({categoryId, frontText, sortAsc, sortBy, isVocab}) => {
  return await studentAxiosInstance.post('/v1/memory-box/flashcards/search?size=100000',
    {categoryId, frontText, sortAsc, sortBy, isVocab},
    null).then(response => {
    return response.data
  })
}

export const fetchAllUnitMemoryBoxFlashCards = async ({categoryId, searchTerm, sortBy, sortKey, unitId, isVocab}) => {
  return await studentAxiosInstance.post('/v1/memory-box/flashcards/search?size=100000',
    {categoryId, searchTerm, sortBy, sortKey, unitId, isVocab},
    null).then(response => {
    return response.data
  })
}

export const fetchAllHistoryMemoryBoxFlashCards = async ({boxType, categoryId, frontText, sortAsc, sortBy}) => {
  return await studentAxiosInstance.post('/v1/memory-box/flashcards/search?size=100000',
    {boxType, categoryId, frontText, sortAsc, sortBy},
    null).then(response => {
    return response.data
  })
}

export const fetchAllUnitHistoryMemoryBoxFlashCards = async (
  {
    boxType,
    categoryId,
    frontText,
    sortAsc,
    sortBy,
    unitId,
    isVocab
  }
) => {
  return await studentAxiosInstance.post('/v1/memory-box/flashcards/search?size=100000',
    {boxType, categoryId, frontText, sortAsc, sortBy, unitId, isVocab},
    null).then(response => {
    return response.data
  })
}

export const fetchAllHistoryMemoryBoxFlashCardsByIsVocab = async (
  {
    boxType,
    categoryId,
    frontText,
    sortAsc,
    sortBy,
    isVocab
  }
) => {
  return await studentAxiosInstance.post('/v1/memory-box/flashcards/search?size=100000',
    {boxType, categoryId, frontText, sortAsc, sortBy, isVocab},
    null).then(response => {
    return response.data
  })
}

export const studyMemoryBoxFlashCard = async ({id, success}) => {
  return await studentAxiosInstance.post(`/v1/memory-box/flashcards/${id}/study`, null, {
    params: {
      success
    }
  })
    .then(response => {
      return response.data
    })
}

export const removeFromBoxFlashCard = async (deleteRequestDTO) => {
  return await studentAxiosInstance.post(`/v1/memory-box/flashcards/delete`, deleteRequestDTO)
    .then(response => {
      return response.data
    })
}

export const addToSuperMemoryBox = async ({id}) => {
  return await studentAxiosInstance.post(`/v1/memory-box/flashcards/${id}/addToSuperMemory`)
    .then(response => {
      return response.data
    })
}

export const removeFromSuperMemoryBox = async ({id}) => {
  return await studentAxiosInstance.post(`/v1/memory-box/flashcards/${id}/removeFromSuperMemory`)
    .then(response => {
      return response.data
    })
}


/*
 * Fetch study hardship
 */

/*
 * Post study hardship
 */
export const postStudyHardship = async ({studyHardshipLevel}) => {
  return await userAxiosInstance.post(`/v1/user_management/setting/action/update/study-hardship-level`, null, {
    params: {
      studyHardshipLevel
    }
  }).then(response => {
    return response.data
  })
}

/*
 * fetch all notepad files and folders
 */
export const fetchAllNotepadFileAndFolder = async ({folderId}) => {
  return await userAxiosInstance.get('/v1/notepad/folders_notes', {params: {parentFolderId: folderId || ''}}).then(response => {
    return response.data
  })
}

/*
 * fetch note
 */
export const fetchNote = async ({noteId}) => {
  return await userAxiosInstance.get('/v1/notepad/notes/' + noteId, null).then(response => {
    return response.data
  })
}

/*
 * add notepad folder
 */
export const notepadAddFolder = async ({parentFolderId, title, color}) => {
  const path = '?parentFolderId=' + (parentFolderId || '');
  return await userAxiosInstance.post('/v1/notepad/folders' + path, {title, color}).then(response => {
    return response.data
  })
}

/*
 * add notepad file
 */
export const notepadAddFile = async (data) => {

  const path = '?parentFolderId=' + (data.parentFolderId || '');
  const {parentFolderId, ...body} = data

  return await userAxiosInstance.post('/v1/notepad/notes' + path,
    body,
    null
  ).then(response => {
    return response.data
  })
}

/*
 * edit notepad file
 */
export const notepadEditFile = async (data) => {
  return await userAxiosInstance.put(`/v1/notepad/notes/${data.id}`,
    data.body,
    {params: {parentFolderId: data.parentFolderId || ''}}
  ).then(response => {
    return response.data
  })
}

/*
 * move file and folder
 */
export const notepadMoveItem = async ({currentFolderId, destinationFolderId, data}) => {
  return await userAxiosInstance.put('/v1/notepad/folder-notes/action/move',
    {
      currentFolderId: currentFolderId ?? '',
      destinationFolderId: destinationFolderId ?? '',
      dtos: data
    },
    null
  ).then(response => {
    return response.data
  })
}

/*
 * edit notepad folder
 */
export const notepadEditFolder = async ({parentFolderId, folderId, title, color}) => {
  return await userAxiosInstance.put(`/v1/notepad/folders/${folderId}`,
    {title, color},
    {params: {parentFolderId: parentFolderId ?? ''}}
  ).then(response => {
    return response.data
  })
}

/*
 * delete notepad items
 */
export const notepadDeleteItems = async ({parentFolderId, items}) => {
  return await userAxiosInstance
    .delete(
      '/v1/notepad/folders_notes',
      {data: items, params: {parentFolderId: parentFolderId ?? ''}}
    )
    .then(response => {
      return response.data
    })
}

/*
 * Home page services
 */
export const fetchHomeTodayStudy = async () => {
  return await studentAxiosInstance.get('/v1/user-data/homepage/today-study').then(response => {
    return response.data
  })
}

export const fetchHomeStatistics = async () => {
  return await studentAxiosInstance.get('/v1/user-data/homepage/statistics/exercise').then(response => {
    return response.data
  })
}

/*
 * Fetch Today's Study Plans
 */
export const fetchTodayStudy = async () => {
  return await studentAxiosInstance.get('/v1/user-data/today-study').then(response => {
    return response.data
  })
}

export const skipToNextStudyPlan = async () => {
  return await studentAxiosInstance.post('/v1/user-data/finish-day').then(response => {
    return response.data
  })
}

export const fetchTodayStudyPlan = async ({pageIndex}) => {
  return await studentAxiosInstance.get(
    '/v1/user-data/today-study-plan',
    {
      params: {
        pageIndex
      }
    }
  ).then(response => {
    return response.data
  })
}

/*
 * Levels & units endpoints
 */
export const fetchLevelsAndUnits = async () => {
  return await studentAxiosInstance.get('v1/user-data/turn-off-unit/units').then(response => {
    return response.data
  })
}

export const fetchLevels = async () => {
  return await userAxiosInstance.get('/v1/user-data/levels').then(response => {
    return response.data
  })
}

export const fetchUnits = async ({level}) => {
  return await userAxiosInstance.get('/v1/user-data/levels/units', {
    params: {
      level
    }
  }).then(response => {
    return response.data
  })
}

export const fetchUnitById = async ({unitId}) => {
  return await userAxiosInstance.get('/v1/user-data/levels/unit', {
    params: {
      unitId
    }
  }).then(response => {
    return response.data
  })
}

export const fetchSections = async ({unitId, studyType}) => {
  return await studentAxiosInstance.get('/v1/user-data/sections', {
    params: {
      unitId, studyType
    }
  }).then(response => {
    return response.data
  })
}

export const updateLevelStatus = async ({level}) => {
  return await studentAxiosInstance.post('/v1/user-data/change-level-study-plan', null, {
    params: {
      level
    }
  }).then(response => {
    return response.data
  })
}

export const updateUnitStatus = async ({unitId}) => {
  return await studentAxiosInstance.post('/v1/user-data/change-unit-study-plan', null, {
    params: {
      unitId
    }
  }).then(response => {
    return response.data
  })
}

/*
 * Fetch Section
 */
export const fetchSection = async ({unit, section}) => {
  const response = await studentAxiosInstance.get(`/v1/content/unit/${unit}/${section}`).then(response => {
    return {
      data: response.data,
      status: response.status,
    }
  })

  switch (section) {
    case 'READING':
      return fetchReadingMapper(response)
    case 'CONVERSATION':
      return fetchConversationMapper(response)
    default:
      return response
  }
}

/*
 * Fetch RoboChat dialogs
 */
export const fetchRoboChatDialog = async ({unitId, dialogId}) => {
  return await studentAxiosInstance.get(`/v1/content/roboChat/unit/${unitId}/${dialogId}`).then(response => {
    return {
      data: response.data,
      status: response.status,
    }
  })
}

/*
 * Fetch More Sections (Button template data in structures)
 */
export const fetchMoreSections = async ({moreSectionId}) => {
  const response = await studentAxiosInstance.get(`/v1/more-sections/${moreSectionId}`).then(response => {
    return {
      data: response.data,
      status: response.status,
      moreSectionId,
    }
  })

  return fetchMoreSectionsMapper(response)
}

/*
 * Check Section
 */
export const checkSection = async (
  {
    matchType,
    sectionType,
    toolsCheck,
    unitId,
    trackerNumber,
    matchCases,
    isVoiceRecognition,
    inputType
  },
) => {
  const url = isVoiceRecognition ? "/v1/exercises/matching-exact-voice" : "/v1/exercises/matching-exact"

  return await studentAxiosInstance
    .post(url, {
      matchType,
      sectionType,
      toolsCheck,
      unitId,
      trackerNumber,
      matchCases,
      inputType
    })
    .then(response => {
      return response.data
    })
}

/*
 * Matching exact hint
 */
export const postMatchingExactHint = async (
  {
    matchType,
    sectionType,
    toolsCheck,
    unitId,
    trackerNumber,
    matchCases,
    inputType
  },
) => {
  return await studentAxiosInstance
    .post("/v1/exercises/matching-exact/hint", {
      matchType,
      sectionType,
      toolsCheck,
      unitId,
      trackerNumber,
      matchCases,
      inputType
    })
    .then(response => {
      return response
    })
}

/*
 * Convert speech to text
 */
export const spell = async ({formData, callbackData}) => {
  return await studentAxiosInstance
    .post('/v1/spell', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      return {
        data: response.data,
        status: response.status,
        callbackData: callbackData ?? null,
      }
    })
}

export const postArchiveRolePlay = async ({formData}) => {
  return await studentAxiosInstance
    .post('/v1/content/archive/conversation', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      return {
        data: response.data,
        status: response.status,
      }
    })
}

export const saveArchiveFile = async ({hashValue, plainValue}) => {
  return await studentAxiosInstance
    .put('/v1/content/archive/conversation', null, {
      params: {
        hashValue,
        plainValue,
      },
    })
    .then(response => {
      return {
        data: response.data,
        status: response.status,
      }
    })
}

export const deleteArchiveFile = async ({fileId, sectionType, unitId}) => {
  return await studentAxiosInstance
    .delete('/v1/content/archive/conversation', {
      params: {
        fileId,
        unitId,
        sectionType,
      },
    })
    .then(response => {
      return {
        data: response.data,
        status: response.status,
        fileId,
      }
    })
}

export const fetchArchive = async () => {
  return await studentAxiosInstance.get('/v1/content/archive/conversation').then(response => {
    return {
      data: response.data,
      status: response.status,
    }
  })
}

export const fetchGameWords = async ({minUnitId = 1, maxUnitId = null, gameType}) => {
  return await studentAxiosInstance.get('/v1/games/words', {
    params: {
      minUnitId,
      maxUnitId: maxUnitId ?? minUnitId,
      gameType,
    },
  }).then(response => {
    return {
      data: blinkGameMapper(response.data),
      status: response.status,
    }
  })
}

export const postGameResult = async ({unitId = 1, userScore, gameType}) => {
  return await studentAxiosInstance.post('/v1/games/result', {
    unitId,
    userScore,
    gameType
  }).then(response => {
    return {
      data: response.data,
      status: response.status,
    }
  })
}

export const fetchGameStatisticsByUnit = async ({unitId, studyType}) => {
  return studentAxiosInstance.get(`/v1/games/record/${unitId}`).then(response => {
    return unitGameStatisticMapper(response.data)
  })
}

export const fetchGameStatisticsAllUnits = async ({gameType}) => {
  return studentAxiosInstance.get(`/v1/games/records/units`, {params: {gameType}}).then(response => {
    return response.data
  })
}

export const fetchGameStatistics = async ({studyType}) => {
  return studentAxiosInstance.get('/v1/games/records').then(response => {
    return gameStatisticMapper(response.data)
  })
}

export const fetchUserUnitStatusUseCase = async ({unitId}) => {
  return studentAxiosInstance.get(`/v1/user-data/unit/status/${unitId}`).then(response => {
    return response.data
  })
}

export const fetchStatistics = async ({studyType}) => {
  return studentAxiosInstance.get('/v1/user-data/statistics', {params: {studyType}}).then(response => {
    return {
      exerciseData: exerciseStatisticsMapper(response.data),
    }
  })
}

export const fetchExerciseStatistics = async () => {
  return studentAxiosInstance.get(`/v1/user-data/statistics/exercise`).then(response => {
    return response.data
  })
}

export const fetchUnitStatistics = async ({unitId = 1, studyType}) => {
  return studentAxiosInstance.get(`/v1/user-data/statistics/${unitId}`, {params: {studyType}}).then(response => {
    return {
      exerciseData: unitStatisticMapper(response.data),
    }
  })
}

export const fetchEvaluationResult = async () => {
  return await userAxiosInstance.get('/v1/evaluation-tests/result').then(response => {
    return {
      data: response.data,
      status: response.status,
    }
  })
}

export const fetchYourGamesRecords = async () => {
  return await studentAxiosInstance.get(`/v1/games/records`).then(response => {
    return {
      data: response.data,
      status: response.status,
    }
  })
}

export const fetchAlphabetSection = async () => {
  return await studentAxiosInstance.get(`/v1/content/unit/0/ALPHABET`).then(response => {
    return {
      data: alphabetMapper(response.data),
      status: response.status,
    }
  })
}

export const fetchCommonDigraphSection = async () => {
  return await studentAxiosInstance.get(`/v1/content/unit/0/COMMON_DIGRAPHS`).then(response => {
    return {
      data: commonDigraphsMapper(response.data),
      status: response.status,
    }
  })
}

export const fetchNumbersSection = async () => {
  return await studentAxiosInstance.get(`/v1/content/unit/0/NUMBERS`).then(response => {
    return {
      data: numbersSectionMapper(response.data),
      status: response.status,
    }
  })
}

export const fetchOrdinalNumberSection = async () => {
  return await studentAxiosInstance.get(`/v1/content/unit/0/ORDINAL_NUMBERS`).then(response => {
    return {
      data: ordinalNumbersSectionMapper(response.data),
      status: response.status,
    }
  })
}

export const renameArchiveFile = async ({fileId, newName}) => {
  return await studentAxiosInstance
    .put('/v1/content/archive/conversation/rename', {fileId, newName})
    .then(response => {
      return {
        data: response.data,
        status: response.status,
        fileId,
        newName,
      }
    })
}

/*
 * Fetch section exercise
 */
export const fetchSectionExercise = async ({unit, section, groupType}) => {
  return await studentAxiosInstance
    .get(`/v1/exercises/unit/${unit}/section/${section}/group/${groupType}`)
    .then(response => {
      return response.data
    })
}

export const exerciseMatch = async ({data, studyType, isVoiceRecognition}) => {
  const url = isVoiceRecognition ? '/v1/exercises/check-voice' : '/v1/exercises/check'
  return await studentAxiosInstance.post(url, data, {
    params: {studyType}
  }).then(response => {
    return {
      stackId: data.exerciseStackId,
      data: response.data,
      status: response.status,
    }
  })
}

export const exerciseMatchV2 = async ({data, studyType, isVoiceRecognition}) => {
  const url = isVoiceRecognition ? '/v1/exercises/check-voice-v2' : '/v1/exercises/check'
  return await studentAxiosInstance.post(url, data, {
    params: {studyType}
  }).then(response => {
    return {
      stackId: data.exerciseStackId,
      data: response.data,
      status: response.status,
    }
  })
}

export const saveExerciseCheckResult = async ({data, studyType}) => {
  const url = '/v1/exercises/save-voice-result'
  return await studentAxiosInstance.post(url, data, {
    params: {studyType}
  }).then(response => {
    return {
      stackId: data.exerciseStackId,
      data: data.exerciseCheckItemResponse,
      status: response.status,
    }
  })
}

export const fetchReviewMistake = async ({unit, studyType}) => {
  return await studentAxiosInstance.get(`/v1/user-data/review-mistakes/${unit}`, {
    params: {
      studyType
    }
  }).then(response => {
    return response.data
  })
}

// User study logs
export const postStudyLog = async ({unitId, sectionType, type, action}) => {
  return await studentAxiosInstance.post('/v1/user-data/log', null, {
    params: {unitId, sectionType, studyType: type, action},
    __retry: true
  }).then(response => {
    return response.data
  })
}

export const fetchSentenceData = async ({trackerNumber}) => {
  return await studentAxiosInstance.get(`/v1/content/user-base`, {
    params: {
      trackerNumber,
    },
  }).then(response => {
    return response.data
  })
}

export const fetchWordDictionary = async ({phrase}) => {
  return await studentAxiosInstance.get(`/v1/vocabularies/search`, {
    params: {title: phrase, flagType: 'EXACT_SEARCH'}
  }).then(response => {
    return response.data?.content
  })
}

export const fetchVocabulariesSummaries = async () => {
  return await studentAxiosInstance.get(`/v1/vocabularies/summaries`).then(response => {
    return response.data
  })
}

export const fetchPhrases = async ({unitId, searchTerm, sortBy, sortType, paged, page, size}) => {
  let sort = (sortBy && sortType) ? (sortBy + ',' + sortType) : undefined
  return await studentAxiosInstance.post('/v1/phrases/search', {
    unitId, title: searchTerm || null
  }, {
    params: {sort, paged, page, size}
  }).then(response => {
    return response.data
  })
}

export const fetchToolsSentences = async ({unitId}) => {
  return await studentAxiosInstance.get(
    '/v1/tools/sentences',
    {params: {unitId}},
  ).then(response => {
    return response.data
  })
}

export const fetchVocabularies = async ({unitId, searchTerm, sortBy, sortType, paged, page, size}) => {
  let sort = (sortBy && sortType) ? (sortBy + ',' + sortType) : undefined
  return await studentAxiosInstance.get('/v1/vocabularies/search', {
    params: {unitId, flagType: 'INCLUDE_SEARCH', title: searchTerm || null, sort, paged, page, size}
  }).then(response => {
    return response.data
  })
}

export const putUserAccent = async (data) => {
  return await userAxiosInstance.post(`/v1/user_management/setting/action/update/available-accent`, data).then(response => {
    return {
      data: response.data,
      status: response.status,
    }
  })
}

// Fetch Essential Items

export const fetchAccents = async () => {
  return await userAxiosInstance.get(`/v1/user-settings/available-accents`).then(response => {
    return response.data
  })
}
export const fetchEssentialTypes = async () => {
  return await studentAxiosInstance.get('/v1/tools/essential-types').then(response => {
    return response.data
  })
}

export const fetchEssential = async ({essentialType}) => {
  return await studentAxiosInstance.get('/v1/static-content/by-language', {
    params: {
      key: essentialType,
      nativeLanguage: 'English'
    }
  }).then(response => {
    return response.data
  })
}

export const fetchFavoriteCategories = async ({type}) => {
  return await studentAxiosInstance.get('/v1/favorites/categories', {
    params: {
      favoriteType: type
    }
  }).then(response => {
    return response.data
  })
}

export const saveFavoriteCategories = async ({name, color}) => {
  return await studentAxiosInstance.post('/v1/favorites/categories', {
    categoryName: name,
    color: color
  }).then(response => {
    return response.data
  })
}

export const updateFavoriteCategories = async ({id, name, color}) => {
  return await studentAxiosInstance.put('/v1/favorites/categories', {
    id: id,
    categoryName: name,
    color: color
  }).then(response => {
    return response.data
  })
}

export const deleteFavoriteCategories = async ({id}) => {
  return await studentAxiosInstance.delete('/v1/favorites/categories', {
    params: {
      id: id
    }
  }).then(response => {
    return response.data
  })
}

export const addFavorite = async ({unitId, trackerNumber, sentenceOrVocab, categoryId}) => {
  return await studentAxiosInstance.post('/v1/favorites', {
    unitId: unitId,
    trackerNumber: trackerNumber,
    sentenceOrVocab: sentenceOrVocab,
    categoryId: categoryId
  }).then(response => {
    return response.data
  })
}

export const moveFavorite = async ({categoryId, sentenceOrVocab, trackerNumber}) => {
  return await studentAxiosInstance.post('/v1/favorites/move', {
    categoryId: categoryId,
    sentenceOrVocab: sentenceOrVocab,
    trackerNumber: trackerNumber
  }).then(response => {
    return response.data
  })
}

export const deleteFavorite = async ({id}) => {
  return await studentAxiosInstance.delete('/v1/favorites', {
    params: {
      id: id
    }
  }).then(response => {
    return response.data
  })
}

export const fetchFavoriteCategoryContent = async (
  {
    type,
    categoryId,
    currentPage,
    postsPerPage,
    searchTerm,
    sortKey,
    sortBy
  }
) => {
  return await studentAxiosInstance.get('/v1/favorites', {
    params: {
      favoriteType: type,
      categoryId: categoryId,
      paged: true,
      page: currentPage,
      size: postsPerPage,
      title: searchTerm,
      sort: (sortKey === 'UNIT' ? 'unitId' : 'title') + ',' + sortBy,
    }
  }).then(response => {
    return response.data
  })
}

export const fetchRoboTeacher = async ({sectionType, unitId, platform}) => {
  return await studentAxiosInstance.get(`/v1/robot-teacher`, {
    params: {
      sectionType,
      unitId,
      platform
    },
  }).then(response => {
    return response.data
  }).catch(function (error) {
    // console.log(error);
  })
}

export const fetchAiPackages = async () => {
  return await userAxiosInstance.get('/v1/payment/ai_package').then(response => {
    return response.data
  })
}

export const fetchChooseCourse = async ({asRenewal, timezoneLocation}) => {
  return await userAxiosInstance.get('/v1/payment/plans', {
    params: {asRenewal, timezoneLocation}
  }).then(response => {
    return response.data
  })
}

export const fetchPaymentResult = async ({paymentId}) => {
  return await userAxiosInstance.get('/v1/payment/result', {
    params: {
      paymentId
    }
  }).then(response => {
    return response.data
  })
}

export const fetchChooseGateway = async () => {
  return await userAxiosInstance.get('/v1/payment/gateways').then(response => {
    return response.data
  })
}

export const fetchDiscounted = async ({discountCode, asRenewal}) => {
  return await userAxiosInstance.get('/v1/payment/discounts/codes/action/apply', {
    suppressToast: true,
    params: {
      discountCode: discountCode,
      asRenewal: asRenewal
    }
  }).then(response => {
    return response.data
  })
}

export const subscriptionZarinPalPlan = async ({asRenewal, discountCode, planId, psp, purchaseType}) => {
  return await userAxiosInstance.post('/v1/payment/subscription/zarinpal', {
    psp,
    platform: "WEB",
    refId: planId,
    discountCode,
    callBackUrl: paymentCallbackResultURL,
    asRenewal,
    purchaseType,
  }).then(response => {
    return response.data
  })
}

export const subscriptionSamanPlan = async ({ asRenewal, discountCode, planId, psp, purchaseType }) => {
  return await userAxiosInstance.post('/v1/payment/subscription/saman', {
    psp,
    platform: "WEB",
    refId: planId,
    discountCode,
    callBackUrl: paymentCallbackResultURL,
    asRenewal,
    purchaseType,
  }).then(response => {
    return response.data
  })
}

export const subscriptionPayPalPlan = async ({asRenewal, discountCode, planId, psp, purchaseType}) => {
  return await userAxiosInstance.post('/v1/payment/subscription/paypal', {
    psp,
    platform: "WEB",
    refId: planId,
    discountCode,
    callBackUrl: paymentCallbackResultURL,
    asRenewal,
    purchaseType
  }).then(response => {
    return response.data
  })
}

export const subscriptionStripePlan = async ({asRenewal, discountCode, planId, psp, paymentMethodId, purchaseType}) => {
  return await userAxiosInstance.post('/v1/payment/subscription/stripe', {
    psp,
    platform: "WEB",
    refId: planId,
    discountCode,
    callBackUrl: paymentCallbackResultURL,
    asRenewal,
    purchaseType
  }, {
    params: {
      paymentMethodId: paymentMethodId
    }
  }).then(response => {
    return response.data
  })
}

export const subscriptionGooglePlan = async ({psp, planId, asRenewal, purchaseType, purchaseToken}) => {
  return await userAxiosInstance.post('/v1/payment/subscription/google', {
    psp,
    platform: "ANDROID",
    refId: planId,
    discountCode: '',
    callBackUrl: paymentCallbackResultURL,
    asRenewal,
    purchaseType,
    purchaseToken
  }).then(response => {
    return response.data
  })
}

export const confirmStripe = async ({paymentIntentId}) => {
  return await userAxiosInstance.get('/v1/payment/confirm/stripe', {
    params: {
      paymentIntentId: paymentIntentId
    }
  }).then(response => {
    return response.data
  })
}

export const setTheme = async ({type}) => {
  return await userAxiosInstance.post('/v1/user_management/setting/action/update/theme', null, {
    params: {
      theme: type
    }
  }).then(response => {
    return response.data
  })
}

export const fetchThemes = async () => {
  return await userAxiosInstance.get('/v1/user-settings/themes').then(response => {
    return response.data
  })
}

export const postPrivacySettings = async ({onlineGame, profilePhoto, identifyAs}) => {
  return await userAxiosInstance.post('/v1/user_management/setting/action/update/privacy-settings', {
    onlineGame,
    profilePhoto,
    identifyAs
  }).then(response => {
    return response.data
  })
}

export const updateNotificationSettings = async ({values}) => {
  return await userAxiosInstance.post('/v1/user_management/setting/action/update/notification-setting', {
    allowAllTheTime: values?.allowAllTheTime,
    fromTime: values?.fromTime,
    toTime: values?.toTime,
    appBadges: values?.appBadges,
    floating: values?.floating,
    lockScreen: values?.lockScreen,
    allowSound: values?.allowSound,
    allowVibration: values?.allowVibration,
    newUnitAvailable: values?.newUnitAvailable,
    incompleteUnit: values?.incompleteUnit,
    newUnitOverdue: values?.newUnitOverdue,
    reviewAvailable: values?.reviewAvailable,
    reviewCountdown: values?.reviewCountdown,
    reviewOverdue: values?.reviewOverdue,
    exerciseAvailable: values?.exerciseAvailable,
    repetitionIncompleteExercise: values?.repetitionIncompleteExercise,
    repetitionCountdown: values?.repetitionCountdown,
    repetitionOverdue: values?.repetitionOverdue,
    cardAvailable: values?.cardAvailable,
    memoryBoxOverdue: values?.memoryBoxOverdue,
    acceptedRequests: values?.acceptedRequests,
    incomingRequests: values?.incomingRequests,
    vocabMaster: values?.vocabMaster,
    accountRenewal: values?.accountRenewal,
    festival: values?.festival,
    afterRegistration: values?.afterRegistration,
  }).then(response => {
    return response.data
  })
}

export const postStudyReminderSettings = async ({param}) => {
  return await userAxiosInstance.post('/v1/user_management/setting/action/update/study-reminder-settings', param).then(response => {
    return response.data
  })
}

// Update avatar
export const updateUserAvatar = async ({avatarType}) => {
  return await userAxiosInstance.post('/v1/user_management/setting/action/update/avatar', null, {
    params: {avatarType}
  }).then(response => {
    return response.data
  })
}

export const postFirstNameSettings = async ({firstName}) => {
  return await userAxiosInstance.post('/v1/user_management/setting/action/update/first-name', {firstName: firstName}).then(response => {
    return response.data
  })
}

export const postLastNameSettings = async ({lastName}) => {
  return await userAxiosInstance.post('/v1/user_management/setting/action/update/last-name', {lastName: lastName}).then(response => {
    return response.data
  })
}

export const postPhoneNumberSettings = async ({phoneNumber}) => {

  return await userAxiosInstance.post('/v1/user_management/setting/action/update/mobile', {mobile: phoneNumber}).then(response => {
    return response.data
  })
}

export const postEmailSettings = async ({email}) => {

  return await userAxiosInstance.post('/v1/user_management/setting/action/update/email', {email: email}).then(response => {
    return response.data
  })
}

export const postAddressSettings = async ({address}) => {
  return await userAxiosInstance.post('/v1/user_management/setting/action/update/address', {address: address}).then(response => {
    return response.data
  })
}

/* TODO: CHANGE_END_POINT */
export const fetchMessagesSummery = async () => {
  return await publicAxiosInstance.get('/notifications/summary').then(response => {
    return response.data
  })
}

/* TODO: CHANGE_END_POINT */
export const fetchMessagesCategoryNotifications = async ({category}) => {
  return await publicAxiosInstance.get(`/notifications`, {params: {notificationType: category}}).then(response => {
    return response.data
  })
}

/* TODO: CHANGE_END_POINT */
export const deleteAllMessages = async () => {
  return await publicAxiosInstance.delete('/notifications/deleteAll').then(response => {
    return response.data
  })
}

export const fetchDictionary = async (
  {phrase}
) => {
  return await studentAxiosInstance.get('/v1/dictionary/word', {
    suppressToast: true,
    params: {word: phrase}
  }).then(response => {
    return response.data
  })
}

export const fetchRecommendation = async ({type}) => {
  return await userAxiosInstance.get('/v1/test-recommendations/form', {
    params: {type}
  }).then(response => {
    return response.data
  })
}

export const fetchUnitStatus = async ({unitId, studyType}) => {
  return await studentAxiosInstance.get(`/v1/user-data/status/${unitId}`, {
    params: {
      studyType
    }
  }).then(response => {
    return response.data
  })
}

export const fetchHelp = async ({page}) => {
  return await userAxiosInstance.get('/v1/help-and-guide/form/' + page).then(response => {
    return response.data
  })
}

export const fetchTextFromAudio = async ({formData, signal}) => {
  return await studentAxiosInstance.post('/v1/speech-to-text', formData, {signal}).then(response => {
    return response.data
  })
}

/* Authentication APIs */
export const postInquiry = async ({authData, captchaToken}) => {
  return await publicAxiosInstance.post('/v1/authentication/inquiry', authData, {suppressToast: true}, {
    headers: {'Captcha-Token': captchaToken}
  }).then(response => {
    return response.data
  })
}

// resend confirmation code for login
export const postInquiryResendCode = async ({uid, flowType}) => {
  return await publicAxiosInstance.post('/v1/authentication/inquiry/action/resend_code', null, {
    params: {
      uid,
      flowType
    }
  }).then(response => {
    return response.data
  })
}

// reset password
export const postInquiryResetPassword = async ({authData, captchaToken}) => {
  return await publicAxiosInstance.post('/v1/authentication/inquiry/action/reset_password', authData, {suppressToast: true}, {
    params: {uid: authData?.uid},
    headers: {'Captcha-Token': captchaToken}
  }).then(response => {
    return response.data
  })
}

// reset password
export const postResetPassword = async (data) => {
  return await userAxiosInstance.post('/v1/user_management/user/action/set_password', data, {suppressToast: true}).then(response => {
    return response.data
  })
}

export const postDeleteUserAccount = async (data) => {
  return await userAxiosInstance.post('/v1/user_management/user/delete', null, {
    suppressToast: true,
    params: data
  }).then(response => {
    return response.data
  })
}

export const postLogoutUser = async (data) => {
  return await userAxiosInstance.post('/v1/user_management/user/logout', null, {
    params: data
  }).then(response => {
    return response.data
  })
}

export const fetchAiChatRoleDescriptions = async ({unitId, promptType}) => {
  return await studentAxiosInstance
    .get(
      `/v1/ai/role-description`,
      {
        params: {
          unitId,
          promptType
        }
      }
    )
    .then(response => {
      return response.data
    })
}

export const postAiChatPrompt = async (data) => {
  return await studentAxiosInstance.post('/v1/ai/prompt', data).then(response => {
    return response.data
  })
}

export const postAiExercisePrompt = async (data) => {
  return await studentAxiosInstance.post('/v1/ai/check-exercise', data).then(response => {
    return response.data
  })
}

export const postAiPromptFeedback = async (data) => {
  return await studentAxiosInstance.post('/v1/ai/feedback', data).then(response => {
    return response.data
  })
}

export const putNotificationReceiver = async ({registrationToken, deviceId}) => {
  return await userAxiosInstance.put('/v1/notification/receiver/update', null, {
    params: {
      receiverToken: registrationToken,
      deviceId: deviceId
    }
  })
}

export default cloudServices
