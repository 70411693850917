import {shuffle} from "lodash";

const fetchReadingMapper = (response) => {
    if (response.status !== 200) {
        return response
    }

    const data = {
        ...response.data,
        shuffledArray: shuffle([...Array(response.data.warmUpQuiz.length).keys()])
    }

    const userData = response.data.warmUpQuiz.map(item => ({
        questionNo: item.questionNo,
        answerIndex: null,
        answerString: null,
        unlocked: false
    }))

    return {
        data,
        userData,
        status: response.status
    }
}

export default fetchReadingMapper