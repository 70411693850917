const matchingSentenceMapper = (response) => {
  let matchCasesMap = {}

  response.data.matchResultDtos.forEach((dto) => {
    matchCasesMap[dto.correctAnswer] = dto.matchCaseResponseDto
  })

  return {
    ...response,
    data: {
      ...response.data,
      matchCasesMap
    }
  }
}

export default matchingSentenceMapper
