import serviceCall from "../../services";

const paymentConfirmStripeUseCase = async ({paymentIntentId}) => {
    const result = await serviceCall(
        'confirmStripe',
        {paymentIntentId},
        'cloud',
    )

    return result
}

export default paymentConfirmStripeUseCase