import removeNonWordCharacters from "./removeNonWordCharacters";

const cleanSentenceForCheck = (str) => {
  if (typeof str === 'string') {
    let strWithoutSC = removeNonWordCharacters(str)
    strWithoutSC = strWithoutSC.trim()
    if (strWithoutSC === '') {
      return ''
    } else {
      return str
    }
  }
}

export default cleanSentenceForCheck