const ColorPalette = ({ setSelectedColor }) => {
	require('./style.scss')

	return (
		<div className='circle-color-palette'>
			<svg xmlns='http://www.w3.org/2000/svg' width='462.951' height='462.946' viewBox='0 0 462.951 462.946'>
				<g transform='translate(-304.525 -342.5)'>
					<g transform='translate(306 344)'>
						<g>
							<g transform='translate(122.119)'>
								<g className='color-item' onClick={() => setSelectedColor('#662482')}>
									<path d='M1371.736,868.093,1263.881,665.248c34.393-18.287,68.9-26.892,107.855-26.892Z'
												transform='translate(-1263.881 -638.356)' fill='#662482' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(39.512 26.891)'>
								<g className='color-item' onClick={() => setSelectedColor('#4248ff')}>
									<path d='M1383.788,864.169,1193.327,735.7c21.782-32.293,48.213-56.091,82.606-74.378Z'
												transform='translate(-1193.327 -661.324)' fill='#4248ff' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(1.494 101.269)'>
								<g className='color-item' onClick={() => setSelectedColor('#2bb7f2')}>
									<path d='M1389.335,853.318,1160.856,829.3c4.072-38.739,16.236-72.16,38.018-104.454Z'
												transform='translate(-1160.856 -724.85)' fill='#2bb7f2' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(0 205.723)'>
								<g className='color-item' onClick={() => setSelectedColor('#10b2bd')}>
									<path d='M1389.554,838.078l-214.479,82.331c-13.959-36.365-18.072-67.606-14-106.345Z'
												transform='translate(-1159.58 -814.064)' fill='#10b2bd' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(15.495 229.737)'>
								<g className='color-item' onClick={() => setSelectedColor('#079f6c')}>
									<path d='M1387.293,834.574,1236.571,1007.96c-29.4-25.556-49.8-54.691-63.758-91.055Z'
												transform='translate(-1172.814 -834.574)' fill='#079f6c' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(79.252 229.737)'>
								<g className='color-item' onClick={() => setSelectedColor('#d0be12')}>
									<path d='M1377.99,834.574l-51.68,223.85c-37.953-8.763-69.644-24.909-99.041-50.464Z'
												transform='translate(-1227.269 -834.574)' fill='#d0be12' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(178.294 229.737)'>
								<g className='color-item' onClick={() => setSelectedColor('#faa818')}>
									<path d='M1363.54,834.574l55.579,222.915c-37.8,9.423-69.3,9.7-107.26.936Z'
												transform='translate(-1311.86 -834.574)' fill='#faa818' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(229.974 229.737)'>
								<g className='color-item' onClick={() => setSelectedColor('#ff692a')}>
									<path d='M1356,834.574,1509.725,1005.3c-28.948,26.065-60.35,42.762-98.146,52.186Z'
												transform='translate(-1356 -834.574)' fill='#ff692a' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(229.974 229.737)'>
								<g className='color-item' onClick={() => setSelectedColor('#e73737')}>
									<path d='M1356,834.574l215.883,78.576c-13.322,36.6-33.211,66.089-62.158,92.153Z'
												transform='translate(-1356 -834.574)' fill='#e73737' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(229.974 201.739)'>
								<g className='color-item' onClick={() => setSelectedColor('#ef458c')}>
									<path d='M1356,838.659l228.025-28c4.748,38.663,1.181,69.97-12.142,106.573Z'
												transform='translate(-1356 -810.661)' fill='#ef458c' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(229.974 97.965)'>
								<g className='color-item' onClick={() => setSelectedColor('#e72ed4')}>
									<path d='M1356,853.8l188.19-131.772c22.341,31.908,35.088,65.112,39.834,103.774Z'
												transform='translate(-1356 -722.028)' fill='#e72ed4' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(229.974 25.039)'>
								<g className='color-item' onClick={() => setSelectedColor('#8e958d')}>
									<path d='M1356,864.44l104.3-204.7c34.707,17.684,61.55,41.019,83.892,72.926Z'
												transform='translate(-1356 -659.742)' fill='#8e958d' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(229.974)'>
								<g className='color-item' onClick={() => setSelectedColor('#000000')}>
									<path d='M1356,868.093V638.356c38.952,0,69.592,7.355,104.3,25.039Z'
												transform='translate(-1356 -638.356)' stroke='#fff' strokeMiterlimit='10'
												strokeWidth='3' />
								</g>
							</g>
						</g>
					</g>
					<g transform='translate(376.25 414.25)'>
						<g transform='translate(0 0)'>
							<g transform='translate(84.731)'>
								<g className='color-item' onClick={() => setSelectedColor('#ac65e5')}>
									<path d='M1338.715,797.757l-74.834-140.743a154.077,154.077,0,0,1,74.834-18.659Z'
												transform='translate(-1263.881 -638.356)' fill='#ac65e5' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(27.415 18.658)'>
								<g className='color-item' onClick={() => setSelectedColor('#6067f6')}>
									<path d='M1325.477,802.067l-132.15-89.136a154.082,154.082,0,0,1,57.316-51.607Z'
												transform='translate(-1193.327 -661.324)' fill='#6067f6' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(1.037 70.265)'>
								<g className='color-item' onClick={() => setSelectedColor('#60c9f6')}>
									<path d='M1319.385,813.986l-158.529-16.662a154.087,154.087,0,0,1,26.379-72.475Z'
												transform='translate(-1160.856 -724.85)' fill='#60c9f6' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(0 142.74)'>
								<g className='color-item' onClick={() => setSelectedColor('#46cbd2')}>
									<path d='M1319.145,830.726l-148.815,57.125c-9.686-25.231-12.539-46.908-9.715-73.787Z'
												transform='translate(-1159.579 -814.064)' fill='#46cbd2' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(10.751 159.401)'>
								<g className='color-item' onClick={() => setSelectedColor('#11ba81')}>
									<path d='M1321.628,834.574l-104.577,120.3a154.089,154.089,0,0,1-44.238-63.178Z'
												transform='translate(-1172.814 -834.574)' fill='#11ba81' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(54.989 159.401)'>
								<g className='color-item' onClick={() => setSelectedColor('#ead830')}>
									<path d='M1331.846,834.574l-35.858,155.317a154.078,154.078,0,0,1-68.719-35.014Z'
												transform='translate(-1227.269 -834.574)' fill='#ead830' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(123.708 159.401)'>
								<g className='color-item' onClick={() => setSelectedColor('#fdbb4a')}>
									<path d='M1347.718,834.574l38.563,154.668c-26.224,6.538-48.086,6.729-74.421.649Z'
												transform='translate(-1311.86 -834.574)' fill='#fdbb4a' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(159.566 159.401)'>
								<g className='color-item' onClick={() => setSelectedColor('#fd804a')}>
									<path d='M1356,834.574l106.661,118.459a154.077,154.077,0,0,1-68.1,36.209Z'
												transform='translate(-1356 -834.574)' fill='#fd804a' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(159.566 159.401)'>
								<g className='color-item' onClick={() => setSelectedColor('#e36566')}>
									<path d='M1356,834.574l149.788,54.519a154.062,154.062,0,0,1-43.128,63.94Z'
												transform='translate(-1356 -834.574)' fill='#e36566' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(159.566 139.975)'>
								<g className='color-item' onClick={() => setSelectedColor('#ee69a0')}>
									<path d='M1356,830.087l158.213-19.426c3.294,26.826.819,48.548-8.425,73.945Z'
												transform='translate(-1356 -810.661)' fill='#ee69a0' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(159.566 67.973)'>
								<g className='color-item' onClick={() => setSelectedColor('#e465d8')}>
									<path d='M1356,813.457l130.574-91.429a154.078,154.078,0,0,1,27.639,72Z'
												transform='translate(-1356 -722.028)' fill='#e465d8' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(159.566 17.373)'>
								<g className='color-item' onClick={() => setSelectedColor('#dfdfdf')}>
									<path d='M1356,801.77l72.366-142.028a154.087,154.087,0,0,1,58.208,50.6Z'
												transform='translate(-1356 -659.742)' fill='#dfdfdf' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
							<g transform='translate(159.566)'>
								<g className='color-item' onClick={() => setSelectedColor('#fff')}>
									<path d='M1356,797.757v-159.4c27.026,0,48.285,5.1,72.366,17.374Z'
												transform='translate(-1356 -638.356)' fill='#fff' stroke='#fff'
												strokeMiterlimit='10' strokeWidth='3' />
								</g>
							</g>
						</g>
					</g>
					<circle cx='69.555' cy='69.555' r='69.555' transform='translate(466.242 504.242)' fill='#fff' />
				</g>
			</svg>
		</div>
	)
}

export default ColorPalette