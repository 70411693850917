import React, {useContext, useEffect} from 'react'
import ReactDOM from 'react-dom'
import colorsContext from '../contexts/ColorsContext'
import CancelSvg from '../icons/CancelSvg'
import classNames from "classnames";
import useTranslatedText from "../hooks/useTranslatedText";

const Modal = ({children, isCenter, closeModal, closeButtonColor}) => {
  require('../styles/modal.scss')
  const {colors} = useContext(colorsContext)

  return ReactDOM.createPortal(
    <div
         className={classNames('modal', {['d-flex align-items-center justify-content-center']: isCenter})}
         onClick={e => {
           if (closeModal && e.target === e.currentTarget) {
             closeModal()
           }
         }}>
      {closeModal && (
        <button className='close-modal' style={{zIndex: '10'}} onClick={closeModal}>
          <CancelSvg color={closeButtonColor ?? colors['C160']}/>
        </button>
      )}
      {children}
    </div>,
    document.getElementById('modal'),
  )
}

export default Modal
