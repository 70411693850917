import serviceCall from "../../services";
import accentsMapper from "../../services/mapper/accents/accentsMapper";

const accentsUseCase = async () => {
  const response = await serviceCall(
    'fetchAccents',
    null,
    'cloud',
  )

  return accentsMapper(response)
}

export default accentsUseCase