import React, { useContext, useEffect, useState } from 'react'
import componentStyle from './AddToFavoriteButton.module.scss'
import HeartSvg from '../../icons/HeartSvg'
import colorsContext from '../../contexts/ColorsContext'
import classNames from 'classnames'
import AddToCategoryModal from '../AddToCategoryModal/AddToCategoryModal'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { addFavorite, deleteFavorite } from '../../redux/features/favoriteSlice'
import { setFavoriteId } from '../../redux/features/translationSlice'

const AddToFavoriteButton = ({ unitId, favoriteId, text = '', trackerNumber = '', title }) => {
	const dispatch = useDispatch()
	const { colors } = useContext(colorsContext)
	const [isFavorite, setIsFavorite] = useState(false)
	const [showAddToCategory, setShowAddToCategory] = useState(false)
	const [showSelectCategoryModal, setShowSelectCategoryModal] = useState(false)
	const [timeoutId, setTimeoutId] = useState(0)
	const [currentFavoriteId, setCurrentFavoriteId] = useState(null)
	const [favoriteButtonTitle, setFavoriteButtonTitle] = useState('')
	const { loading } = useSelector(state => state.favorite)

	useEffect(() => {
		setIsFavorite(!isEmpty(favoriteId))
	}, [favoriteId])

	useEffect(() => {
		if (isFavorite) {
			setFavoriteButtonTitle('Remove from Favorites')
		} else {
			setFavoriteButtonTitle('Add to Favorites')
		}
	}, [isFavorite])

	useEffect(() => {
		setCurrentFavoriteId(favoriteId)
		setIsFavorite(!isEmpty(favoriteId))
	}, [favoriteId])

	useEffect(() => {
		return () => {
			clearTimeout(timeoutId)
		}
	}, [isFavorite])

	const onChangeFavorite = id => {
		dispatch(
			setFavoriteId({
				trackerNumber: trackerNumber,
				title,
				favoriteId: id,
			}),
		)
	}

	const onClickFavorite = () => {
		if (isFavorite) {
			dispatch(deleteFavorite({ id: currentFavoriteId })).then(() => {
				setCurrentFavoriteId(null)
				onChangeFavorite(null)
				setIsFavorite(!isFavorite)
				setShowAddToCategory(false)
				clearTimeout(timeoutId)
			})
		} else {
			dispatch(
				addFavorite({
					unitId: unitId,
					trackerNumber: trackerNumber,
					sentenceOrVocab: text,
					categoryId: null,
				}),
			).then(res => {
				if (res?.meta?.requestStatus !== 'rejected') {
					// Set id in states
					setCurrentFavoriteId(res.payload.id)
					onChangeFavorite(res.payload.id)

					// Display add to category button for 2 seconds
					setShowAddToCategory(true)
					const timeoutId = setTimeout(() => {
						setShowAddToCategory(false)
					}, 2000)
					setTimeoutId(timeoutId)
				}
			})
		}
	}

	return (
		<>
			<div style={{ position: 'relative' , opacity: loading ? 0.5 : 1}}>
				<button
					className={classNames(componentStyle.addToCategoryButton, 'res:fs-sm')}
					disabled={!showAddToCategory || loading}
					onClick={() => setShowSelectCategoryModal(true)}
					style={{
						color: colors['C160'],
						opacity: showAddToCategory ? 1 : 0
					}}>
					Add to category
				</button>
				<button
					className="circle-button"
					onClick={onClickFavorite}
					disabled={loading}
					style={{ borderColor: colors['C160'] }}>
					<HeartSvg stroke={colors['C160']} fill={isFavorite ? colors['C160'] : 'transparent'} />
				</button>
			</div>
			<button onClick={onClickFavorite} disabled={loading} className="label" style={{ color: colors['C160'], opacity: loading ? 0.5 : 1 }}>
				{favoriteButtonTitle}
			</button>
			{showSelectCategoryModal && (
				<AddToCategoryModal
					text={text}
					trackerNumber={trackerNumber}
					closeModal={() => setShowSelectCategoryModal(false)}
				/>
			)}
		</>
	)
}

export default AddToFavoriteButton
