import serviceCall from "../../services";

const skipStudyPlanUseCase = async () => {
  return await serviceCall(
    'skipToNextStudyPlan',
    null,
    'cloud',
  )
}

export default skipStudyPlanUseCase