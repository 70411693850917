import { useDispatch, useSelector } from 'react-redux'
import { setSelectedTranslationLanguage } from '../../redux/features/translationSlice'
import { languagesEnums, translationsLanguagesData } from '../../enums/languageEnums/languageEnums'
import style from './SwitchLanguageButton.module.scss'
import { useContext, useEffect } from 'react'
import useAppUser from '../../hooks/useAppUser'
import SecondaryButton from '../../atomicComponents/atoms/SecondaryButton/SecondaryButton'
import colorsContext from '../../contexts/ColorsContext'

const SwitchLanguageButton = ({textColor}) => {
	const { selectedTranslationLanguage } = useSelector(state => state.translation)
	const { appUserData } = useAppUser()
	const dispatch = useDispatch()
	const english = selectedTranslationLanguage?.key === languagesEnums.ENGLISH_LANGUAGE
	const {colors} = useContext(colorsContext)

	useEffect(() => {
		return () => {
			const languageData = translationsLanguagesData[appUserData?.setting?.nativeLanguage]
			if (languageData) {
				dispatch(setSelectedTranslationLanguage(languageData))
			}
		}
	}, [])

	return (
		<SecondaryButton
			style={{ background: 'transparent', color: textColor ?? colors['C179'], border: `1px solid ${textColor ?? colors['C179']}` }}
			onClick={() => {
				if (english) {
					dispatch(setSelectedTranslationLanguage(translationsLanguagesData[appUserData?.setting?.nativeLanguage]))
				} else {
					dispatch(setSelectedTranslationLanguage(translationsLanguagesData[languagesEnums.ENGLISH_LANGUAGE]))
				}
			}}>
			<div className={style.button}>
				<img
					alt={'Native Language Flag'}
					src={
						english
							? `${window.location.origin}/assets/flags/gb.svg`
							: `${window.location.origin}/assets/images/languages/${appUserData?.setting?.nativeLanguageFlagKey}.svg`
					}
				/>
				<span>{selectedTranslationLanguage?.alpha2.toLowerCase()}</span>
			</div>
		</SecondaryButton>
	)
}

export default SwitchLanguageButton
