import serviceCall from "../../services";
import notificationsMapper from "../../services/mapper/messages/notificationsMapper";

const getCategoryNotificationsUseCase = async ({category}) => {
	const response = await serviceCall(
		'fetchMessagesCategoryNotifications',
		{category},
		'cloud',
	)

	return response.map(it => notificationsMapper(it))
}

export default getCategoryNotificationsUseCase