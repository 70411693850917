import { motion } from 'framer-motion'

const FramerMotion = ({ children, ...rest }) => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.4 }}
			{...rest}
		>
			{children}
		</motion.div>
	)
}

export default FramerMotion