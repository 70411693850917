import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import getFaqUseCase from '../../useCase/faqUseCase/getFaqUseCase'
import searchFaqUseCase from '../../useCase/faqUseCase/searchFaqUseCase'
import {languagesEnums} from "../../enums/languageEnums/languageEnums";
import {ENGLISH, NATIVE} from "../../enums/roboTeacher/dataStatusEnums";
import serviceCall from "../../services";


const initialState = {
  loading: false,
  data: null,
  currentData: [],
  error: ''
}

export const getFaq = createAsyncThunk(
  'faq/getFaq',
  async ({nativeLanguage}, {getState}) => {
    const {translation} = getState()
    const response = await getFaqUseCase({nativeLanguage})
    return {
      response,
      selectedTranslationLanguage: translation.selectedTranslationLanguage
    }
  },
)

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    searchFaq: (state, action) => {
      const selectedLanguageData = state.selectedTranslationLanguage?.key === languagesEnums.ENGLISH_LANGUAGE
        ? state.data?.english
        : state.data?.native;

      state.currentData = searchFaqUseCase(action.payload.searchTerm, selectedLanguageData || []);

    },
    setFaqLanguage: (state, action) => {

      if (action.payload === ENGLISH) {
        state.selectedTranslationLanguage = { key: languagesEnums.ENGLISH_LANGUAGE };
        state.currentData = state.data?.english || [];
      } else if (action.payload === NATIVE) {
        state.selectedTranslationLanguage = { key: 'NATIVE_LANGUAGE' };
        state.currentData = state.data?.native || [];
      }
    }

  },
  extraReducers: builder => {
    builder.addCase(getFaq.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getFaq.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.response
      if (action.payload.selectedTranslationLanguage?.key === languagesEnums.ENGLISH_LANGUAGE) {
        state.currentData = action.payload?.response?.english || []
      } else {
        state.currentData = action.payload?.response?.native || []
      }
    })

    builder.addCase(getFaq.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  },
})

export const {searchFaq, setFaqLanguage} = faqSlice.actions

export default faqSlice.reducer
