import React, { useContext } from 'react'
import style from './ToplineTitle.module.scss'
import HtmlTextWrapper from '../HtmlTextWrapper/HtmlTextWrapper'
import { moreOptionsList } from '../../data'
import colorsContext from '../../contexts/ColorsContext'

const ToplineTitle = ({titleData}) => {
	const { colors } = useContext(colorsContext)


	return (
		<div className={style.toplineTitleWrapper + ' mt-2'}>
			<div className={style.toplineTitle} style={{ background: colors['C185'], color: colors['C160'] }}>
				<span className={style.toplineTitleBefore} style={{ background: colors['C185'] }} />
				{titleData &&
					<HtmlTextWrapper
						showPhraseOptions
						className={`${style.text} res:fs-lg`}
						moreOptionsList={moreOptionsList}
						data={titleData}
						textColor={colors['C160']}
						optionsColor={colors['C160']}
					/>
				}
				<span className={style.toplineTitleAfter} style={{ background: colors['C185'] }} />
			</div>
		</div>
	)
}
export default ToplineTitle
