import * as React from "react"

const ClickHintSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style type="text/css">
            {
            `.ClickHintSvg-st0{fill:none;stroke-width:19;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.ClickHintSvg-st1{stroke-width:19;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`
            }
        </style>
        <g>
            <g transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
                <g>
                    <g>
                        <g>
                            <g transform="translate(497,327)">
                                <path
                                    className="ClickHintSvg-st0"
                                    stroke={color}
                                    d="M-120.1,47.3c0-72-58.3-130.4-130.3-130.4S-380.7-24.7-380.7,47.3s58.3,130.4,130.3,130.4 S-120.1,119.3-120.1,47.3z"
                                />
                            </g>
                            <g transform="translate(181.5811,238.9004)">
                                <path
                                    className="ClickHintSvg-st0"
                                    stroke={color}
                                    d="M-46.4,67.8l-112.8-96.8c-18.8-16.2-19.9-44.9-2.4-62.5c17.5-17.6,46.3-16.4,62.4,2.4 L-2.5,23.9"
                                />
                            </g>
                            <g transform="translate(286.1133,376.1133)">
                                <path
                                    className="ClickHintSvg-st0"
                                    stroke={color}
                                    d="M-70.8,35.9c0,1.5,0.1,2.9,0.3,4.4c2.2,15.5,15.6,27.3,31.7,27C-22.6,66.9-9,53.8-8.2,37.5 c0.9-18.1-13.4-33-31.3-33v-29.3"
                                />
                            </g>
                            <g transform="translate(181.5811,238.9004)">
                                <path
                                    className="ClickHintSvg-st0"
                                    stroke={color}
                                    d="M-46.4,67.8l-39.5-33.9l49.6-49.6l33.9,39.5"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <circle className="ClickHintSvg-st1" stroke={color} cx={330} cy={249.9} r={1} />
        </g>
    </svg>
)

export default ClickHintSvg
