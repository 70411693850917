import {useState} from "react";

const useReversedDebounce = () => {
  const [lastExecuteTime, setLastExecuteTime] = useState(0)

  const reverseDebounce = (func, threshold = 100) => {
    const now = Date.now()

    if (now - lastExecuteTime < threshold) {
      return // Too soon, ignore this execute
    }

    setLastExecuteTime(now) // Update last execute time
    func()
  }

  return {reverseDebounce}
}

export default useReversedDebounce