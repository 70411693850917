import structureBaseTitleMapper from "./StructureBaseTitleMapper";
import {min} from "lodash";
import structureBaseImageMapper from "./StructureBaseImageMapper";

const StructureImageTemplateMapper = ({item, events}) => {
  return {
    ...item,
    data: {
      cards: item.data.cards.map(card => {
        return {
          ...card,
          title: structureBaseTitleMapper({title: card?.title, events}),
          images: card.images.map(image => {
            return {
              ...image,
              text: image?.text ? structureBaseTitleMapper({title: image?.text, events}) : null,
              picture: structureBaseImageMapper({image: image?.picture, text: image?.text, events})
            }
          }),
          hiddenTime: min(events?.view?.filter(it => ((it?.templateId === item?.id) && (it?.cardId === card?.id))).map(it => parseInt(it?.startTime) || 0)) || 0
        }
      })
    },
    hiddenTime: parseInt(events?.view?.find(it => it.templateId === item.id)?.startTime) || 0
  }
}

export default StructureImageTemplateMapper