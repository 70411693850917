import {studyTypeEnum} from "../../../enums/userStudyEnums/userStudyEnums";
import { unitStatusEnums } from "../../../enums/levelUnitEnums/levelUnitEnums";

const userUnitStatusMapper = (data, numberOfRepetitions) => {
  const userUnitStudyTypesList = {
    3: [studyTypeEnum.NEW, studyTypeEnum.REPEAT_1, studyTypeEnum.REPEAT_2, studyTypeEnum.REPEAT_3],
    5: [studyTypeEnum.NEW, studyTypeEnum.REPEAT_1, studyTypeEnum.REPEAT_2, studyTypeEnum.REPEAT_3, studyTypeEnum.REPEAT_4, studyTypeEnum.REPEAT_5],
    7: [studyTypeEnum.NEW, studyTypeEnum.REPEAT_1, studyTypeEnum.REPEAT_2, studyTypeEnum.REPEAT_3, studyTypeEnum.REPEAT_4, studyTypeEnum.REPEAT_5, studyTypeEnum.REPEAT_6, studyTypeEnum.REPEAT_7]
  }

  return data?.filter(it => userUnitStudyTypesList[numberOfRepetitions]?.includes(it.studyType)).map(item => {
    return {
      ...item,
      percentage: item.correctPercentage,
      studyProgressStatus: item.isFinished ? unitStatusEnums.DONE : (item.isInProgress ? unitStatusEnums.ON_GOING : unitStatusEnums.NOT_START)
    }
  })
}

export default userUnitStatusMapper