import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getGatewayUseCase from "../../useCase/payment/getGatewayUseCase";

const initialState = {
	plan: null,
	referenceCode: '',
	pspName: null,
	asRenewal: false
}

const sharedPaymentSlice = createSlice({
	name: 'sharedPayment',
	initialState,
	reducers: {
		setAsRenewal: (state, action) => {
			state.asRenewal = action.payload
		},
		setPlan: (state, action) => {
			state.plan = action.payload
		},
		setReferenceCode: (state, action) => {
			state.referenceCode = action.payload
		},
		setPspName: (state, action) => {
			state.pspName = action.payload
		},
		clearSelected: (state, action) => {
			state.plan = null
			state.referenceCode = ''
			state.pspName = null
			state.asRenewal = false
		}
	}
})

export const {
	setAsRenewal, setPlan, setReferenceCode, setPspName, clearSelected
} = sharedPaymentSlice.actions

export default sharedPaymentSlice.reducer