import componentStyle from './DividerTemplate.module.scss'
import React, {useContext, useEffect, useRef} from 'react'
import colorsContext from '../../../../contexts/ColorsContext'
import classNames from 'classnames'
import { LayoutConstantsEnums } from '../../../../enums/gameEnums/dividerTemplateEnums'
import structuresContext from "../../../../contexts/StructuresContext";
import {robotTeacherScrollIntoViewByElement} from "../../../../operations/robotTeacherScrollIntoViewByElement";
import {useSelector} from "react-redux";

const DividerTemplate = ({template}) => {
  const {colors} = useContext(colorsContext)
  const elRef = useRef(null)
  const {scrolling} = useSelector(state => state.roboTeacher)
  const {isRoboTeacher, roboTeacherData} = useContext(structuresContext)
  const isHidden = (isRoboTeacher ?? false) ? ((roboTeacherData.currentTime ?? 0) < (template?.hiddenTime ?? 0)) : false

  useEffect(() => {
    if (template?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isHidden])

  const renderTemplate = () => {
    switch (template.config?.displayMode) {
			case LayoutConstantsEnums.SPACE_SMALL:
				return <div style={{ height: 1 }} />
			case LayoutConstantsEnums.SPACE_NORMAL:
				return <div style={{ height: 32 }} />
			case LayoutConstantsEnums.SPACE_LARGE:
				return <div style={{ height: 64 }} />
			case LayoutConstantsEnums.LINE_TEMPLATE_WIDTH:
				return <hr style={{ borderColor: colors['C2'] }} />
			case LayoutConstantsEnums.LINE_SCREEN_WIDTH:
				return <hr style={{ borderColor: colors['C2'] }} />
			default:
				return null
		}
  }
  if (template) {
    return (
      <div
        ref={elRef}
        data-rt-id={template?.id}
        className={classNames(
          componentStyle.dividerContainer, {
            ['container container-1']: template.config?.displayMode === LINE_TEMPLATE_WIDTH,
          },
          {['hide-element']: isHidden})
        }>
        {renderTemplate()}
      </div>
    )
  } else {
    return null
  }
}

export default DividerTemplate