import serviceCall from "../../services";
import fileMapper from "../../services/mapper/notepad/fileMapper";

const getNoteUseCase = async ({ noteId }) => {
	const result = await serviceCall(
		'fetchNote',
		{ noteId },
		'cloud',
	)

	return fileMapper(result);
}

export default getNoteUseCase