import React, {useContext, useEffect, useRef} from 'react'
import componentStyle from './InstructionTemplate.module.scss'
import Instruction from '../../../../components/Instruction/Instruction'
import structuresContext from "../../../../contexts/StructuresContext";
import classNames from "classnames";
import {robotTeacherScrollIntoViewByElement} from "../../../../operations/robotTeacherScrollIntoViewByElement";
import {useSelector} from "react-redux";

const InstructionTemplate = ({template}) => {
  const elRef = useRef(null)
  const {scrolling} = useSelector(state => state.roboTeacher)
  const {isRoboTeacher, roboTeacherData} = useContext(structuresContext)
  const isHidden = (isRoboTeacher ?? false) ? ((roboTeacherData.currentTime ?? 0) < (template?.hiddenTime ?? 0)) : false

  useEffect(() => {
    if (template?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isHidden])

  return (
    <div ref={elRef} data-rt-id={template.id} className={classNames(componentStyle.container, {['hide-element']: isHidden})}>
      <Instruction data={template.data?.title}/>
    </div>
  )
}

export default InstructionTemplate