import {createSlice} from "@reduxjs/toolkit";
import {SPELLING_SYSTEM} from "../../../../enums/toolsEnums/spellingSeparationEnums";
import spellingWordUnscrambleFactory from "../../../../factory/spellingWordUnscrambleFactory";
import {shuffle} from "lodash";

const initialState = {
  initialNumberOfRepetitions: 5,
  letterSeparation: SPELLING_SYSTEM,
  currentIndex: 0,
  unscrambleData: []
}

const spellingWordUnscrambleSlice = createSlice({
  name: 'spellingWordUnscramble',
  initialState,
  reducers: {
    createUnscrambleData: (state, action) => {
      state.unscrambleData =
        spellingWordUnscrambleFactory(
          action.payload.data,
          state.initialNumberOfRepetitions,
          state.letterSeparation
        )
    },
    setFieldValue: (state, action) => {
      state.unscrambleData[state.currentIndex].fields[action.payload.fieldIndex].value = action.payload.character
      state.unscrambleData[state.currentIndex].characters[action.payload.characterIndex].used = true
    },
    rollBackCharacters: (state) => {
      state.unscrambleData[state.currentIndex].fields =
        state.unscrambleData[state.currentIndex].fields.map(item => {
          return {
            ...item,
            value: null
          }
        })
      state.unscrambleData[state.currentIndex].characters =
        state.unscrambleData[state.currentIndex].characters.map(item => {
          return {
            ...item,
            used: false
          }
        })
    },
    setStatus: (state, action) => {
      state.unscrambleData[state.currentIndex].status = action.payload.status
    },
    setFinished: (state, action) => {
      state.unscrambleData[state.currentIndex].isFinished = action.payload.isFinished
    },
    clearInputItems: (state) => {
      state.unscrambleData[state.currentIndex].items =
        [...state.unscrambleData[state.currentIndex].items].map(item => {
          return {
            ...item,
            input: ''
          }
        })
    },
    increaseNumberOfRepetitions: (state, action) => {
      const currentWordNumberOfRepetitions = state.unscrambleData[state.currentIndex].numberOfRepetitions
      if (currentWordNumberOfRepetitions < 999) {
        if (action.payload?.setToAll) {
          state.initialNumberOfRepetitions = currentWordNumberOfRepetitions + 1
          state.unscrambleData = [...state.unscrambleData].map(word => {
            if (!word.isFinished) {
              return {
                ...word,
                numberOfRepetitions: currentWordNumberOfRepetitions + 1
              }
            }
            return word
          })
        } else {
          ++state.unscrambleData[state.currentIndex].numberOfRepetitions
        }
      }
    },
    decreaseNumberOfRepetitions: (state, action) => {
      const currentWordNumberOfRepetitions = state.unscrambleData[state.currentIndex].numberOfRepetitions
      if (currentWordNumberOfRepetitions > 0) {
        if (action.payload?.setToAll) {
          state.initialNumberOfRepetitions = currentWordNumberOfRepetitions - 1
          state.unscrambleData = [...state.unscrambleData].map(word => {
            if (!word.isFinished) {
              return {
                ...word,
                numberOfRepetitions: currentWordNumberOfRepetitions - 1
              }
            }
            return word
          })
        } else {
          --state.unscrambleData[state.currentIndex].numberOfRepetitions
        }
      }
    },
    setLetterSeparation: (state, action) => {
      if (action.payload === state.letterSeparation) {
        state.letterSeparation = SPELLING_SYSTEM
      } else {
        state.letterSeparation = action.payload
      }
    },
    resetNumberOfRepetitions: (state) => {
      state.unscrambleData[state.currentIndex].numberOfRepetitions = state.initialNumberOfRepetitions
    },
    setWrongAnswersCount: (state, action) => {
      state.unscrambleData[state.currentIndex].wrongAnswersCount = action.payload
    },
    setCorrectAnswersCount: (state, action) => {
      state.unscrambleData[state.currentIndex].correctAnswersCount = action.payload
    },
    shuffleCharacters:(state) => {
      state.unscrambleData[state.currentIndex].characters = shuffle(state.unscrambleData[state.currentIndex].characters)
    },
    increaseCurrentIndex: (state) => {
      if ((state.currentIndex + 1) < state.unscrambleData.length) {
        ++state.currentIndex
      }
    },
    decreaseCurrentIndex: (state) => {
      if (state.currentIndex > 0) {
        --state.currentIndex
      }
    },
    clearSpellingUnscrambleStates: (state) => {
      state.initialNumberOfRepetitions = 5
      state.letterSeparation = SPELLING_SYSTEM
      state.currentIndex = 0
      state.unscrambleData = []
    }
  }
})

export const {
  createUnscrambleData,
  setFieldValue,
  rollBackCharacters,
  setStatus,
  setFinished,
  increaseNumberOfRepetitions,
  decreaseNumberOfRepetitions,
  setLetterSeparation,
  resetNumberOfRepetitions,
  setWrongAnswersCount,
  setCorrectAnswersCount,
  increaseCurrentIndex,
  decreaseCurrentIndex,
  clearSpellingUnscrambleStates,
  shuffleCharacters
} = spellingWordUnscrambleSlice.actions

export default spellingWordUnscrambleSlice.reducer