import serviceCall from "../../services";
import todayStudyMapper from "../../services/mapper/todayStudy/todayStudyMapper";

const todayStudyUseCase = async () => {
  const result = await serviceCall(
    'fetchTodayStudy',
    null,
    'cloud',
  )

  return {
    todayStudy: todayStudyMapper(result?.todayStudy),
    quickReview: result?.quickReview
  }
}

export default todayStudyUseCase