import fetchStructureMapper from './fetchStructureMapper'

const fetchMoreSectionsMapper = (response) => {
	if (response.status !== 200) {
		return response
	}

	const structureData = fetchStructureMapper(response.data)

	const data = {
		...response.data,
		...structureData,
	}

	return { ...response, data }
}

export default fetchMoreSectionsMapper