import serviceCall from "../../services";

const editCategoryUseCase = async ({ id, title, color, sideToShow, orderOfDisplay }) => {
	const result = await serviceCall(
		'editMemoryBoxCategory',
		{ id, title, color, sideToShow, orderOfDisplay },
		'cloud',
	);

	return result;
}

export default editCategoryUseCase 