    const levelBgColors = {
  1: 'C473',
  2: 'C474',
  3: 'C475',
  4: 'C476',
  5: 'C477',
  6: 'C478',
  7: 'C479',
}

const getLevelBackgroundColorByNumber = (levelNumber = 1) => {
  return levelBgColors[levelNumber]
}

export default getLevelBackgroundColorByNumber