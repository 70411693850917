export default [
  {
    translationKey: "GATEWAY_CAPTCHA_VALIDATION_FAILED",
    translationValue: "Captcha validation failed.",
  },
  {
    translationKey: "USER_MANAGEMENT_USER_CREATE_FAILED",
    translationValue: "Failed to create user.",
  },
  {
    translationKey: "USER_MANAGEMENT_USER_NOT_FOUND",
    translationValue: "Username does not exist.",
  },
  {
    translationKey: "USER_MANAGEMENT_PASSWORD_INCORRECT",
    translationValue: "Password is incorrect!",
  },
  {
    translationKey: "USER_MANAGEMENT_PASSWORD_EMPTY",
    translationValue: "Password field cannot be empty.",
  },
  {
    translationKey: "USER_MANAGEMENT_PASSWORD_LENGTH",
    translationValue: "Password must contain a minimum of three characters.",
  },
  {
    translationKey: "USER_MANAGEMENT_REPASSWORD_EMPTY",
    translationValue: "Re-Password cannot be empty.",
  },
  {
    translationKey: "USER_MANAGEMENT_REPASSWORD_NOT_MATCH",
    translationValue: "Password and Re-password must be the same.",
  },
  {
    translationKey: "USER_MANAGEMENT_USERNAME_EMPTY",
    translationValue: "Username field cannot be empty.",
  },
  {
    translationKey: "USER_MANAGEMENT_USER_INVALID",
    translationValue: "User is not valid.",
  },
  {
    translationKey: "USER_MANAGEMENT_CODE_EXPIRED",
    translationValue: "Confirmation Code has been expired.",
  },
  {
    translationKey: "USER_MANAGEMENT_CODE_INVALID",
    translationValue: "Confirmation Code is not valid.",
  },
  {
    translationKey: "USER_MANAGEMENT_CODE_EMPTY",
    translationValue: "Please enter the confirmation code.",
  },
  {
    translationKey: "USER_MANAGEMENT_OTP_RETRY",
    translationValue: "Please wait two Minutes",
  },
  {
    translationKey: "PAYMENT_IP_INVALID",
    translationValue: "Your IP is not valid.",
  },
  {
    translationKey: "PAYMENT_REGION_INVALID",
    translationValue: "The discount code is not valid in your region.",
  },
  {
    translationKey: "PAYMENT_CODE_EXPIRED",
    translationValue: "Discount code is expired.",
  },
  {
    translationKey: "PAYMENT_CODE_UNAVAILABLE",
    translationValue: "The discount code is no longer available.",
  },
  {
    translationKey: "PAYMENT_CODE_USED",
    translationValue: "This discount code has been used before.",
  },
  {
    translationKey: "CORE_AI_TOKEN_AMOUNT",
    translationValue: "You don't have enough tokens",
  },
  {
    translationKey: "CORE_AI_ROLE_DESCRIPTION_INVALID",
    translationValue: "There is no role description with this ID",
  },
  {
    translationKey: "CORE_AI_PROMPT_ID_INVALID",
    translationValue: "There is no prompt with this ID",
  },
  {
    translationKey: "CORE_AI_FEEDBACK_NOT_FOUND",
    translationValue: "Feedback not found",
  },
  {
    translationKey: "CORE_FILE_NOT_ENOUGH_SPACE",
    translationValue: "Not enough space in storage",
  },
  {
    translationKey: "CORE_FAVORITE_CATEGORY_EXISTS",
    translationValue: "Category with this name already exists!",
  },
  {
    translationKey: "CORE_FAVORITE_CATEGORY_NOT_FOUND",
    translationValue: "Category not found!",
  },
  {
    translationKey: "CORE_FAVORITE_ITEM_EXISTS",
    translationValue: "This item already exists in selected category.",
  },
  {
    translationKey: "CORE_MEMORY_BOX_CARD_EXISTS",
    translationValue: "This card already exists.",
  },
  {
    translationKey: "CORE_MEMORY_BOX_CATEGORY_EXISTS",
    translationValue: "Flash card category with this name already exists.",
  },
  {
    translationKey: "CORE_MEMORY_NOTEPAD_TITLE_EXISTS",
    translationValue: "The title already exists! Choose another title.",
  },
  {
    translationKey: "CORE_DICTIONARY_WORD_NOT_FOUND",
    translationValue: "Word has not been found in our library.",
  },
  {
    translationKey: "PAYMENT_CODE_INVALID",
    translationValue: "The discount code is wrong. Please check and try again.",
  },
  {
    translationKey: "OOPS_SOMETHING_WENT_WRONG",
    translationValue: "Oops! Something went wrong.",
  },
  {
    translationKey: "CORE_TURN_OFF_LEVEL_INVALID",
    translationValue: "You can not turn on previous levels.",
  },
  {
    translationKey: "CORE_TURN_OFF_UNIT_INVALID",
    translationValue: "You can not turn off previous units.",
  },
  {
    translationKey: "CORE_TURN_ON_UNIT_INVALID",
    translationValue: "You can not turn on previous units.",
  }
]
