import { createSlice } from '@reduxjs/toolkit'
import removeExtraSpace from "../../../../operations/removeExtraSpace";

const initialState = {
  initialNumberOfRepetitions: 5,
  loading: false,
  listeningData: null,
  currentIndex: 0,
  isAutoPlaying: true,
  isRepeat: false,
  error: '',
}

const listeningSlice = createSlice({
  name: 'listening',
  initialState,
  reducers: {
    createListeningData: (state, action) => {
      state.listeningData = action.payload.data?.map((item, i) => {
        return {
          index: i,
          title: removeExtraSpace(item.title),
          voiceUri: item.voiceUri,
          numberOfRepetitions: state.initialNumberOfRepetitions,
          voiceType: action.payload.voiceType,
          wrongAnswersCount: 0,
          correctAnswersCount: 0,
          isFinished: false,
        }
      })
    },
    increaseNumberOfRepetitions: (state, action) => {
      const currentPhraseNumberOfRepetitions = state.listeningData[state.currentIndex].numberOfRepetitions
      if (currentPhraseNumberOfRepetitions < 999) {
        if (action.payload?.setToAll) {
          state.initialNumberOfRepetitions = currentPhraseNumberOfRepetitions + 1
          state.listeningData = [...state.listeningData].map(word => {
            if (!word.isFinished) {
              return {
                ...word,
                numberOfRepetitions: currentPhraseNumberOfRepetitions + 1
              }
            }
            return word
          })
        } else {
          ++state.listeningData[state.currentIndex].numberOfRepetitions
        }
      }
    },
    decreaseNumberOfRepetitions: (state, action) => {
      const currentPhraseNumberOfRepetitions = state.listeningData[state.currentIndex].numberOfRepetitions
      if (currentPhraseNumberOfRepetitions > 0) {
        if (action.payload?.setToAll) {
          state.initialNumberOfRepetitions = currentPhraseNumberOfRepetitions - 1
          state.listeningData = [...state.listeningData].map(word => {
            if (!word.isFinished) {
              return {
                ...word,
                numberOfRepetitions: currentPhraseNumberOfRepetitions - 1
              }
            }
            return word
          })
        } else {
          --state.listeningData[state.currentIndex].numberOfRepetitions
        }
      }
    },
    increaseCurrentIndex: (state) => {
      if ((state.currentIndex + 1) < state.listeningData.length) {
        ++state.currentIndex
      }
    },
    decreaseCurrentIndex: (state) => {
      if (state.currentIndex > 0) {
        --state.currentIndex
      }
    },
    resetNumberOfRepetitions: (state) => {
      state.listeningData[state.currentIndex].numberOfRepetitions = state.initialNumberOfRepetitions
    },
    setWrongAnswersCount: (state, action) => {
      state.listeningData[state.currentIndex].wrongAnswersCount = action.payload
    },
    setCorrectAnswersCount: (state, action) => {
      state.listeningData[state.currentIndex].correctAnswersCount = action.payload
    },
    setIsRepeat: (state, action) => {
      state.isRepeat = action.payload
    },
    setIsFinished: (state, action) => {
      state.listeningData[state.currentIndex].isFinished = action.payload
    },
    setAudioUrl: (state, action) => {
      if (state.listeningData?.length > 0) {
        state.listeningData[action.payload.currentIndex].voiceUri = action.payload.data
      }
    },
    handleAudioEnded: (state) => {
      if (state.isAutoPlaying && state.listeningData[state.currentIndex].numberOfRepetitions > 1) {
        state.isRepeat = true
      }

      if (state.listeningData[state.currentIndex].numberOfRepetitions > 0) {
        --state.listeningData[state.currentIndex].numberOfRepetitions
        if (state.listeningData[state.currentIndex].numberOfRepetitions === 0) {
          state.listeningData[state.currentIndex].isFinished = true
          state.isRepeat = false
        }
      }
    },
    toggleAutoPlaying: (state) => {
      state.isAutoPlaying = !state.isAutoPlaying
    },
    clearListeningStates: (state) => {
      state.initialNumberOfRepetitions = 5
      state.loading = false
      state.listeningData = null
      state.currentIndex = 0
      state.isAutoPlaying = true
      state.isRepeat = false
      state.error = ''
    }
  },
})

export const {
  createListeningData, increaseNumberOfRepetitions, decreaseNumberOfRepetitions, resetNumberOfRepetitions,
  setWrongAnswersCount, setCorrectAnswersCount, handleAudioEnded, setIsRepeat, setIsFinished, increaseCurrentIndex,
  decreaseCurrentIndex, toggleAutoPlaying, clearListeningStates, setAudioUrl
} = listeningSlice.actions

export default listeningSlice.reducer