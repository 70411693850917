import {useContext} from "react"
import colorsContext from "../../contexts/ColorsContext"
import componentStyle from './Switch.module.scss'
import classNames from "classnames";
import {Spinner} from "react-bootstrap";

const Switch = ({text, onChange, value, backgroundColor, textColor, className, pending, disabled}) => {
  const {colors} = useContext(colorsContext)

  return (
    <button
      className={classNames(componentStyle.toggleOptionWrapper, {[componentStyle.pending]: pending}, className)}
      style={{opacity: (pending || disabled) ? 0.5 : 1}}
      disabled={pending || disabled}
      onClick={onChange}
    >
      {text &&
        <span className={componentStyle.text} style={{color: textColor ?? colors['C2']}}>
          {text}
        </span>
      }
      <div className={componentStyle.toggleContainer}>
        <div
          className={classNames(componentStyle.toggleOption, {[componentStyle.active]: value})}
          style={{background: value ? (backgroundColor ?? colors['C25']) : null}}
        />
        {pending &&
          <div className={classNames(componentStyle.spinner, {[componentStyle.active]: value})}>
            <Spinner size='sm'/>
          </div>
        }
      </div>
    </button>
  )
}

export default Switch
