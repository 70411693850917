import {endsWith} from "lodash";
import removeExtraSpace from "../operations/removeExtraSpace";
import {SPELLING_CUSTOM, SPELLING_SYLLABUS, SPELLING_SYSTEM} from "../enums/toolsEnums/spellingSeparationEnums";
import { AnswerStatusEnums } from '../enums/globalEnums/globalEnums'

const createSystemType = (data, initialNumberOfRepetitions) => {
  return data.map((wordObj, wordIndex) => {
    const pyramidLines = []
    for (let i = 1; i <= wordObj?.title?.length; i++) {
      const clearedTitle = removeExtraSpace(wordObj.title)
      pyramidLines.push(clearedTitle.substring(0, i))
    }

    return {
      index: wordIndex,
      lines: pyramidLines.filter(str => !endsWith(str, ' ')).map((line, lineIndex) => {
        return {
          index: lineIndex,
          numberOfRepetitions: initialNumberOfRepetitions,
          isFinished: false,
          isEnabled: lineIndex < 3,
          status: AnswerStatusEnums.NONE,
          items: line.split(' ').map((lineItem, lineItemIndex) => {
            return {
              index: lineItemIndex,
              title: lineItem,
              input: ''
            }
          })
        }
      })
    }
  })

}
const createSyllableType = (data, type = SPELLING_SYLLABUS, initialNumberOfRepetitions) => {
  return data.map((wordObj, wordIndex) => {
    let syllable = ''

    switch (type) {
      case SPELLING_SYLLABUS:
        syllable = wordObj?.syllable
        break
      case SPELLING_CUSTOM:
        syllable = wordObj?.customSyllable
        break
    }

    let pyramidLines = []
    const pyramidChars = syllable?.split('.')

    for (let i = 1; i <= pyramidChars.length; i++) {
      const arr = pyramidChars.slice(0, i).map(item => {
        return removeExtraSpace(item)
      })
      pyramidLines.push(arr.join(' '))
    }

    return {
      index: wordIndex,
      lines: pyramidLines.filter(str => !endsWith(str, ' ')).map((line, lineIndex) => {
        return {
          index: lineIndex,
          numberOfRepetitions: initialNumberOfRepetitions,
          isFinished: false,
          isEnabled: lineIndex < 1,
          status: AnswerStatusEnums.NONE,
          items: line.split(' ').map((lineItem, lineItemIndex) => {
            return {
              index: lineItemIndex,
              title: lineItem,
              input: ''
            }
          })
        }
      })
    }
  })
}
const spellingWordPyramidFactory = (data, initialNumberOfRepetitions, type) => {
  switch (type) {
    case SPELLING_SYSTEM:
      return createSystemType(data, initialNumberOfRepetitions)
    case SPELLING_SYLLABUS:
      return createSyllableType(data, SPELLING_SYLLABUS, initialNumberOfRepetitions)
    case SPELLING_CUSTOM:
      return createSyllableType(data, SPELLING_CUSTOM, initialNumberOfRepetitions)
  }
}
export default spellingWordPyramidFactory
