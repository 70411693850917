import ColorPalette from "../ColorPalette/ColorPalette";
import { useContext, useEffect, useState } from "react";
import SelectedColorSvg from "./SelectedColorSvg";
import colorsContext from "../../contexts/ColorsContext";
import RoundedInput from "../RoundedInput/RoundedInput";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCategoryStates,
  setSelectedCategoryColor,
  setInputCategoryName
} from "../../redux/features/addCategoryCardSlice";
import CircleAddSvg from "../../icons/CircleAddSvg";
import { isEmpty } from "lodash";
import SecondaryButton from "../../atomicComponents/atoms/SecondaryButton/SecondaryButton";

const AddCategoryCard = (
  {
    inputPlaceholder,
    hideInput,
    icon,
    maxLength = 50,
    onSave = () => {
    }
  }
) => {
  require("./style.scss")

  const dispatch = useDispatch()
  const { colors } = useContext(colorsContext)

  const [colorPaletteIsOpen, setColorPaletteIsOpen] = useState(false)
  const { inputCategoryName, selectedCategoryColor } = useSelector(state => state.addCategoryCard)

  useEffect(() => {
    return () => {
      dispatch(clearCategoryStates())
    }
  }, [])

  return (
    <div className='mw-100'>
      <div className="add-category-card" style={{ background: colors['C73'] }}>
        {!hideInput &&
          <div className="mb-4">
            <RoundedInput
              autoFocus
              maxLength={maxLength}
              value={inputCategoryName}
              type='text'
              backgroundColor={colors['C160']}
              color={colors['C233']}
              placeholder={inputPlaceholder ?? 'Type category name'}
              onChange={(input) => {
                dispatch(setInputCategoryName(input.target.value.trimStart()))
              }}
            />
          </div>
        }
        <div className="selectcolor-wrapper">
          <span className="selected-color-icon">
            {icon
              ? icon(selectedCategoryColor)
              : <SelectedColorSvg selectedColor={selectedCategoryColor} />
            }
          </span>
          <p className="select-color-text fs-md" style={{ color: colors['C589'] }}>Select Color:</p>
          <div className="color-badges-wrapper">
            <span
              className="color-badge" style={{ background: "#39A935" }}
              onClick={() => dispatch(setSelectedCategoryColor("#39A935"))}
            />
            <span
              className="color-badge" style={{ background: "#009EE2" }}
              onClick={() => dispatch(setSelectedCategoryColor("#009EE2"))}
            />
            <span
              className="color-badge" style={{ background: "#E20613" }}
              onClick={() => dispatch(setSelectedCategoryColor("#E20613"))}
            />
            <span
              className="color-badge" style={{ background: "#E5007E" }}
              onClick={() => dispatch(setSelectedCategoryColor("#E5007E"))}
            />
            <span
              className="color-badge" style={{ background: "#EF7D00" }}
              onClick={() => dispatch(setSelectedCategoryColor("#EF7D00"))}
            />
            <span
              className="color-badge" style={{ background: "#1D1D1B" }}
              onClick={() => dispatch(setSelectedCategoryColor("#1D1D1B"))}
            />
            <span
              className="color-badge" style={{ background: "#B1B1B1" }}
              onClick={() => dispatch(setSelectedCategoryColor("#B1B1B1"))}
            />
          </div>
          <button
            className="open-colorpalette-btn"
            onClick={() => setColorPaletteIsOpen(oldState => !oldState)}
          >
            <span className={`add-circular-icon ${colorPaletteIsOpen ? "open" : "close"}`}>
              <CircleAddSvg color={colors['C589']} />
            </span>
          </button>
        </div>
        <div className='d-flex justify-content-center mt-4'>
          <SecondaryButton
            disabled={!hideInput && isEmpty(inputCategoryName)}
            onClick={onSave}
          >
            Save
          </SecondaryButton>
        </div>
      </div>

      {colorPaletteIsOpen &&
        <div className="mt-4">
          <ColorPalette setSelectedColor={(color) => {
            dispatch(setSelectedCategoryColor(color))
          }} />
        </div>
      }
    </div>
  )
}

export default AddCategoryCard