import React from 'react'
import ResetSvg from '../../icons/ResetSvg'

const ResetButton = ({ onClick, color }) => {
	require('./resetButton.scss')

	return (
		<button className='reset-button' data-jsx='ResetButton' style={{ color }} onClick={onClick}>
      <span className='reset-svg-icon'>
          <ResetSvg color={color} />
      </span>
			Reset
		</button>
	)
}

export default ResetButton