
export const C1 = '#231F20CC'
export const C2 = '#231F20'
export const C3 = '#75B79E'
export const C4 = '#75B79E'
export const C5 = '#75B79E'
export const C6 = '#75B79E'
export const C7 = '#75B79E'
export const C8 = '#75B79E'
export const C9 = '#75B79E'
export const C10 = '#75B79E'
export const C11 = '#00A86B'
export const C12 = '#D6EADF'
export const C13 = '#FCFCFC'
export const C14 = '#00A86B'
export const C15 = '#C0DFCA'
export const C16 = '#BFC932'
export const C17 = '#FFDD15'
export const C18 = '#FF8800'
export const C19 = '#231F20'
export const C20 = '#FCFCFCB3'
export const C21 = '#FCFCFC4D'
export const C22 = '#EEF9BF'
export const C23 = '#231F20'
export const C24 = '#98BF64'
export const C25 = '#75B79E'
export const C26 = '#FCFCFC4D'
export const C27 = '#D0D2D3'
export const C28 = '#A2A1A1'
export const C29 = '#E20613'
export const C30 = '#1b982f'
export const C31 = '#AFCBA5'
export const C32 = '#AFCBA5CC'
export const C33 = '#FCFCFC'
export const C34 = '#706F6F'
export const C35 = '#AFCBA5'
export const C36 = '#93C01F'
export const C37 = '#4A9F80'
export const C38 = '#00A86BFF'
export const C39 = '#FCFCFC'
export const C40 = '#75B79E'
export const C41 = '#AFCBA5'
export const C42 = '#C0DFCA'
export const C43 = '#231F20'
export const C44 = '#231F20'
export const C45 = '#AFCBA5'
export const C46 = '#E20613'
export const C47 = '#4A9F80'
export const C48 = '#E20613'
export const C49 = '#EEF9BF'
export const C50 = '#EB008B'
export const C51 = '#E20613'
export const C52 = '#E20613'
export const C53 = '#E20613'
export const C54 = '#99d7ac'
export const C55 = '#4A9F80'
export const C56 = '#4A9F80'
export const C57 = 'linear-gradient(to right,#7C0EFF,#4F78FD,#09DEF8)'
export const C58 = '#09DEF8'
export const C59 = '#E1BD47'
export const C60 = '#4A9F80'
export const C61 = '#E20613'
export const C62 = '#C0DFCA'
export const C63 = '#E1E1E1A8'
export const C64 = '#B1B1B1'
export const C65 = '#73e8bd'
export const C66 = '#75B79E'
export const C67 = 'linear-gradient(to bottom,#FCFCFC,#D0D2D3)'
export const C68 = '#4A9F80'
export const C69 = '#878787'
export const C70 = '#4A9F80'
export const C71 = 'linear-gradient(to right,#37B34A,#E20613)'
export const C72 = '#E7F4ED'
export const C73 = '#B5E0C9'
export const C74 = '#176448'
export const C75 = '#00A86B'
export const C76 = '#176448'
export const C77 = '#5DE0B0'
export const C78 = '#AFCBA5'
export const C79 = '#294E41'
export const C80 = '#95C0A3'
export const C81 = '#70A782'
export const C82 = '#31894D'
export const C83 = '#1A6D3F'
export const C84 = '#46BE93'
export const C85 = '#4EB96F'
export const C86 = '#67A37A'
export const C87 = '#9FD9B1'
export const C88 = '#7CC293'
export const C89 = '#4A8B5E'
export const C90 = '#2AA24F'
export const C91 = '#147D35'
export const C92 = '#5BBB79'
export const C93 = '#9CC28E'
export const C94 = '#AFCBA5'
export const C95 = '#477E6B'
export const C96 = '#70A782'
export const C97 = '#5C8D72'
export const C98 = '#466956'
export const C99 = '#E20613'
export const C100 = '#48CDF7'
export const C101 = '#231F20'
export const C102 = '#C0DFCA'
export const C103 = '#75B79E'
export const C104 = 'linear-gradient(to bottom,#CDE7E5,#676C74)'
export const C105 = '#75B79E'
export const C106 = 'linear-gradient(to right,#B1B1B1,#FCFCFC)'
export const C107 = '#4A9F80'
export const C108 = '#E20613'
export const C109 = '#231F2080'
export const C110 = '#26a22d'
export const C111 = '#1C4E6B'
export const C112 = '#919191'
export const C113 = '#0D0C07'
export const C114 = '#B4C2C3'
export const C115 = '#F4EEE2'
export const C116 = '#FFFFCB'
export const C117 = '#EFECDE'
export const C118 = '#669ACC'
export const C119 = '#659EB9'
export const C120 = '#986665'
export const C121 = '#9A7E4E'
export const C122 = '#CC9866'
export const C123 = '#131A22'
export const C124 = '#3B3B3B'
export const C125 = '#030303'
export const C126 = '#666666'
export const C127 = '#616161'
export const C128 = '#D9C39A'
export const C129 = '#A69370'
export const C130 = '#979832'
export const C131 = '#A58C4C'
export const C132 = '#9D9686'
export const C133 = '#C3943B'
export const C134 = '#336667'
export const C135 = '#0C0E1A'
export const C136 = '#656700'
export const C137 = '#5D5115'
export const C138 = '#C0A93F'
export const C139 = '#D4D4D2'
export const C140 = '#A6A5A1'
export const C141 = '#8385B9'
export const C142 = '#AFCBA5'
export const C143 = '#FCFCFC80'
export const C144 = '#FCFCFCCC'
export const C145 = '#C0DFCA'
export const C146 = '#39A93580'
export const C147 = '#868686'
export const C148 = '#75B79E'
export const C149 = '#00A86B'
export const C150 = '#29654f'
export const C151 = '#231F20'
export const C152 = '#231F20'
export const C153 = '#231F20'
export const C154 = '#AFCBA5'
export const C155 = '#F2EFF5'
export const C156 = 'linear-gradient(to right,#5BC5F1,#F8B133)'
export const C157 = '#4A9F80'
export const C158 = '#4A9F80'
export const C159 = '#4A9F80'
export const C160 = '#FCFCFC'
export const C161 = '#C0DFCA'
export const C162 = '#C0DFCA'
export const C163 = '#C0DFCA'
export const C164 = '#C0DFCA'
export const C165 = '#C0DFCA'
export const C166 = '#C0DFCA'
export const C167 = '#FCFCFC'
export const C168 = '#231F204D'
export const C169 = 'radial-gradient(#F2EFF5,#4A9F80,#F2EFF5)'
export const C170 = '#4A9F8080'
export const C171 = 'linear-gradient(to right,#D0D2D3,#FCFCFC)'
export const C172 = '#75B79E'
export const C173 = '#706F6F'
export const C174 = '#EDFFF3FF'
export const C175 = '#75b79e'
export const C176 = '#AFCBA5'
export const C177 = '#C0DFCA'
export const C178 = '#FCFCFC80'
export const C179 = '#FCFCFC'
export const C180 = '#C0DFCA'
export const C181 = '#E20613'
export const C182 = '#4A9F80'
export const C183 = '#D6EADF'
export const C184 = '#00A86B'
export const C185 = '#4A9F80'
export const C186 = '#EEF9BF'
export const C187 = '#00A86B'
export const C188 = '#AFCBA5'
export const C189 = '#E20613'
export const C190 = '#00A86B'
export const C191 = '#4A9F80'
export const C192 = '#FCFCFCCC'
export const C193 = '#C0DFCA'
export const C194 = '#FCFCFC'
export const C195 = '#E20613'
export const C196 = '#4A9F804D'
export const C197 = '#231F204D'
export const C198 = '#231F20'
export const C199 = '#00A86B'
export const C200 = '#FCFCFC'
export const C201 = '#8ba681'
export const C202 = '#4A9F80'
export const C203 = '#FCFCFC80'
export const C204 = '#4A9F8080'
export const C205 = '#AFCBA5'
export const C206 = '#E20613'
export const C207 = '#E20613'
export const C208 = '#E20613'
export const C209 = '#231F20'
export const C210 = '#4A9F80B3'
export const C211 = '#AFCBA5'
export const C212 = '#AFCBA5'
export const C213 = '#4A9F80'
export const C214 = '#00A86B99'
export const C215 = '#7C0EFF'
export const C216 = '#E20613'
export const C217 = '#E20613'
export const C218 = '#00A86B80'
export const C219 = '#FCFCFCCC'
export const C220 = '#FCFCFC80'
export const C221 = '#848D92'
export const C222 = '#231F2040'
export const C223 = '#4A9F80'
export const C224 = '#E20613'
export const C225 = '#4A9F80'
export const C226 = '#E1E1E1'
export const C227 = '#4A9F80'
export const C228 = '#bfc1c7'
export const C229 = '#EEF9BF'
export const C230 = '#231F20'
export const C231 = '#BCE4CE'
export const C232 = '#C0DFCA'
export const C233 = '#231F20'
export const C234 = '#AFCBA5'
export const C235 = '#75B79E'
export const C236 = '#75b79e'
export const C237 = '#E20613'
export const C238 = '#4A9F80'
export const C239 = 'linear-gradient(to right,#D0D2D3,#FCFCFC)'
export const C240 = '#00A86B'
export const C241 = '#FCFCFCB3'
export const C242 = '#E7E8E8'
export const C243 = '#6C66AF'
export const C244 = '#C0DFCAB3'
export const C245 = '#E20613'
export const C246 = '#E20613'
export const C247 = '#4A9F80'
export const C248 = '#CCB058'
export const C249 = '#B0B0B0'
export const C250 = '#BB9090'
export const C251 = '#638088'
export const C252 = '#545F83'
export const C253 = '#C0DFCA'
export const C254 = '#AFCBA5'
export const C255 = '#4A9F80'
export const C256 = '#294E41'
export const C257 = '#C0DFCA'
export const C258 = '#C0DFCA'
export const C259 = '#FCFCFC1A'
export const C260 = '#E20613'
export const C261 = '#AFCBA5'
export const C262 = '#8BC53F'
export const C263 = '#A6A9AB'
export const C264 = '#75B79E'
export const C265 = '#C0DFCA'
export const C266 = '#00A86B'
export const C267 = '#C0DFCA'
export const C268 = '#294E41'
export const C269 = '#AFCBA5'
export const C270 = '#C0DFCA'
export const C271 = '#C4679E'
export const C272 = '#00ADEE'
export const C273 = '#C2C73B'
export const C274 = '#D3D3D3'
export const C275 = '#4A9F80'
export const C276 = '#E20613'
export const C277 = '#b0e8d4'
export const C278 = '#A3DAB58C'
export const C279 = '#C02EF2'
export const C280 = '#A78233'
export const C281 = '#7D4848'
export const C282 = '#638088'
export const C283 = '#262261'
export const C284 = '#4A9F80'
export const C285 = '#FCFCFC33'
export const C286 = '#00A86B'
export const C287 = 'linear-gradient(to right,#808080,#FCFCFC)'
export const C288 = '#00A86B'
export const C289 = '#AFCBA5'
export const C290 = '#E20613'
export const C291 = '#231F20'
export const C292 = '#294E41'
export const C293 = '#C0DFCA'
export const C294 = '#294E41'
export const C295 = '#4A9F80'
export const C296 = '#EEC732'
export const C297 = '#231F2080'
export const C298 = '#E20613'
export const C299 = '#E20613'
export const C300 = '#FCFCFC99'
export const C301 = '#458160'
export const C302 = '#E1E1E1'
export const C303 = '#D0D2D3'
export const C304 = '#FCFCFC'
export const C305 = '#231F2033'
export const C306 = '#87878799'
export const C307 = '#E20613'
export const C308 = '#DCA3CE'
export const C309 = '#E20613'
export const C310 = '#E20613'
export const C311 = '#294E41'
export const C312 = '#30c68f'
export const C313 = '#D6EADF'
export const C314 = '#C5FAE7'
export const C315 = '#B8FCE3'
export const C316 = '#A5FDDD'
export const C317 = '#90FCD5'
export const C318 = '#69F9C4'
export const C319 = '#57F2BA'
export const C320 = '#50E1AD'
export const C321 = '#4ED8A6'
export const C322 = '#4BCB9C'
export const C323 = '#44BD91'
export const C324 = '#3EB288'
export const C325 = '#3BAA81'
export const C326 = '#37A17A'
export const C327 = '#259F73'
export const C328 = '#1CA372'
export const C329 = '#189467'
export const C330 = '#16885F'
export const C331 = '#138159'
export const C332 = '#127450'
export const C333 = '#116949'
export const C334 = '#0C7750'
export const C335 = '#0A6544'
export const C336 = '#50E1AD'
export const C337 = '#4ED8A6'
export const C338 = '#4BCB9C'
export const C339 = '#44BD91'
export const C340 = '#3EB288'
export const C341 = '#3BAA81'
export const C342 = '#37A17A'
export const C343 = '#259F73'
export const C344 = '#1CA372'
export const C345 = '#189467'
export const C346 = '#16885F'
export const C347 = '#0C7750'
export const C348 = '#A5FDDD'
export const C349 = '#90FCD5'
export const C350 = '#69F9C4'
export const C351 = '#57F2BA'
export const C352 = '#50E1AD'
export const C353 = '#4ED8A6'
export const C354 = '#4BCB9C'
export const C355 = '#44BD91'
export const C356 = '#3EB288'
export const C357 = '#3BAA81'
export const C358 = '#37A17A'
export const C359 = '#259F73'
export const C360 = '#1CA372'
export const C361 = '#189467'
export const C362 = '#16885F'
export const C363 = '#0C7750'
export const C364 = '#0A6544'
export const C365 = '#A5FDDD'
export const C366 = '#90FCD5'
export const C367 = '#69F9C4'
export const C368 = '#57F2BA'
export const C369 = '#50E1AD'
export const C370 = '#4ED8A6'
export const C371 = '#4BCB9C'
export const C372 = '#44BD91'
export const C373 = '#3EB288'
export const C374 = '#3BAA81'
export const C375 = '#37A17A'
export const C376 = '#259F73'
export const C377 = '#1CA372'
export const C378 = '#189467'
export const C379 = '#16885F'
export const C380 = '#0C7750'
export const C381 = '#90FCD5'
export const C382 = '#69F9C4'
export const C383 = '#57F2BA'
export const C384 = '#50E1AD'
export const C385 = '#4ED8A6'
export const C386 = '#4BCB9C'
export const C387 = '#44BD91'
export const C388 = '#3EB288'
export const C389 = '#3BAA81'
export const C390 = '#37A17A'
export const C391 = '#259F73'
export const C392 = '#1CA372'
export const C393 = '#189467'
export const C394 = '#16885F'
export const C395 = '#0C7750'
export const C396 = '#90FCD5'
export const C397 = '#69F9C4'
export const C398 = '#57F2BA'
export const C399 = '#50E1AD'
export const C400 = '#4ED8A6'
export const C401 = '#4BCB9C'
export const C402 = '#44BD91'
export const C403 = '#3EB288'
export const C404 = '#3BAA81'
export const C405 = '#37A17A'
export const C406 = '#259F73'
export const C407 = '#1CA372'
export const C408 = '#189467'
export const C409 = '#16885F'
export const C410 = '#90FCD5'
export const C411 = '#69F9C4'
export const C412 = '#57F2BA'
export const C413 = '#50E1AD'
export const C414 = '#4ED8A6'
export const C415 = '#4BCB9C'
export const C416 = '#44BD91'
export const C417 = '#3EB288'
export const C418 = '#3BAA81'
export const C419 = '#37A17A'
export const C420 = '#259F73'
export const C421 = '#189467'
export const C422 = '#16885F'
export const C423 = '#90FCD5'
export const C424 = '#69F9C4'
export const C425 = '#57F2BA'
export const C426 = '#50E1AD'
export const C427 = '#4ED8A6'
export const C428 = '#4BCB9C'
export const C429 = '#44BD91'
export const C430 = '#3EB288'
export const C431 = '#3BAA81'
export const C432 = '#259F73'
export const C433 = '#189467'
export const C434 = '#16885F'
export const C435 = '#90FCD5'
export const C436 = '#69F9C4'
export const C437 = '#57F2BA'
export const C438 = '#50E1AD'
export const C439 = '#4ED8A6'
export const C440 = '#4BCB9C'
export const C441 = '#44BD91'
export const C442 = '#3EB288'
export const C443 = '#3BAA81'
export const C444 = '#259F73'
export const C445 = '#189467'
export const C446 = '#90FCD5'
export const C447 = '#69F9C4'
export const C448 = '#57F2BA'
export const C449 = '#50E1AD'
export const C450 = '#4ED8A6'
export const C451 = '#4BCB9C'
export const C452 = '#44BD91'
export const C453 = '#3BAA81'
export const C454 = '#259F73'
export const C455 = '#189467'
export const C456 = '#90FCD5'
export const C457 = '#69F9C4'
export const C458 = '#57F2BA'
export const C459 = '#50E1AD'
export const C460 = '#4ED8A6'
export const C461 = '#4BCB9C'
export const C462 = '#44BD91'
export const C463 = '#3BAA81'
export const C464 = '#259F73'
export const C465 = '#90FCD5'
export const C466 = '#69F9C4'
export const C467 = '#57F2BA'
export const C468 = '#50E1AD'
export const C469 = '#4ED8A6'
export const C470 = '#4BCB9C'
export const C471 = '#44BD91'
export const C472 = '#3BAA81'
export const C473 = '#294E41'
export const C474 = '#31604F'
export const C475 = '#35745E'
export const C476 = '#398B6E'
export const C477 = '#3B9E7B'
export const C478 = '#39AE85'
export const C479 = '#36C392'
export const C480 = '#3ECC9A'
export const C481 = '#231F204D'
export const C482 = '#231F2099'
export const C483 = '#231F20'
export const C484 = '#AFCBA5'
export const C485 = '#4A9F80'
export const C486 = '#4A9F80'
export const C487 = '#EEF9BF'
export const C488 = '#C0DFCA'
export const C489 = '#9D0606'
export const C490 = '#07734C'
export const C491 = 'linear-gradient(to bottom,#AFCBA5,#4A9F80)'
export const C492 = '#E20613'
export const C493 = '#E20613'
export const C494 = '#E20613'
export const C495 = '#E20613'
export const C496 = '#E20613'
export const C497 = '#E20613'
export const C498 = '#E20613'
export const C499 = '#009EE2'
export const C500 = '#5D2684'
export const C501 = '#AAD7BF'
export const C502 = 'linear-gradient(to right,#4A9F80,#AFCBA5)'
export const C503 = 'linear-gradient(to right,#EEF9BF,#4A9F80)'
export const C504 = '#294E41'
export const C505 = '#F5FFCB'
export const C506 = '#CACBCF'
export const C507 = '#707070'
export const C508 = '#C0DFCA'
export const C509 = '#AFCBA5'
export const C510 = '#294E41'
export const C511 = '#AFCBA5'
export const C512 = '#BAB6B6'
export const C513 = '#BBBDBF'
export const C514 = '#E20613'
export const C515 = '#7448BC'
export const C516 = '#FCFCFCCC'
export const C517 = '#4A9F80'
export const C518 = '#818181'
export const C519 = '#FCFCFCCC'
export const C520 = '#FCFCFCB3'
export const C521 = '#AFCBA5'
export const C522 = '#D6EADF'
export const C523 = '#4A9F80'
export const C524 = '#AFCBA5'
export const C525 = '#FCFCFC'
export const C526 = '#C0DFCA'
export const C527 = '#C0DFCA'
export const C528 = '#E20613'
export const C529 = '#4A9F80'
export const C530 = '#4A9F80'
export const C531 = '#00A86B'
export const C532 = '#FCFCFCCC'
export const C533 = '#C0DFCA'
export const C534 = '#4A9F80'
export const C535 = '#AFCBA5'
export const C536 = '#C0DFCAB3'
export const C537 = '#4A9F80'
export const C538 = '#E20613'
export const C539 = '#E20613'
export const C540 = '#FCFCFC'
export const C541 = '#FCFCFC'
export const C542 = '#AFCBA566'
export const C543 = '#D6EADF'
export const C544 = '#C0DFCA'
export const C545 = '#00A86B'
export const C546 = '#EEF9BF'
export const C547 = '#294E4180'
export const C548 = '#FCFCFC'
export const C549 = '#C0DFCA'
export const C550 = '#7CAC6B'
export const C551 = '#6EB654'
export const C552 = '#D8BD1C'
export const C553 = '#FCFCFC'
export const C554 = '#231F20'
export const C555 = '#0B662345'
export const C556 = '#AFCBA5'
export const C557 = '#FFDD154D'
export const C558 = '#00ADEE4D'
export const C559 = '#37B34A4D'
export const C560 = '#00A86B'
export const C561 = '#B7DBC3'
export const C562 = '#A2D0B1'
export const C563 = '#FCFCFC33'
export const C564 = '#0F441D'
export const C565 = '#231F20'
export const C566 = '#3A9171'
export const C567 = 'linear-gradient(to right,#1AA7DB,#067EAA)'
export const C568 = '#4A9F80CC'
export const C569 = '#C0DFCA'
export const C570 = '#4A9F80CC'
export const C571 = '#E98020'
export const C572 = '#75B79E'
export const C573 = '#000000'
export const C574 = '#FCFCFC'
export const C575 = '#000000'
export const C576 = '#FCFCFC'
export const C577 = '#000000'
export const C578 = '#4A9F80FF'
export const C579 = '#00A86B'
export const C580 = '#000000'
export const C581 = '#000000'
export const C582 = '#C0DFCA'
export const C583 = '#58595B'
export const C584 = '#00000024'
export const C585 = '#000000'
export const C586 = '#58595B'
export const C587 = '#00A86B'
export const C588 = '#D6EADF'
export const C589 = '#4A4A4A'
export const C590 = '#75B79E'
export const C591 = '#575756'
export const C592 = '#3C886C'
export const C593 = '#000000'
export const C594 = '#00A86B'
export const C595 = '#066945'
export const C596 = '#FCFCFC'
export const C597 = '#23B17D'
export const C598 = '#C5DFD1'
export const C599 = '#000000'
export const C601 = '#a3cbb6'
export const C602 = '#37B34A'
export const C603 = '#B5E0C9'
export const C604 = '#B3261E'
export const C605 = '#00000080'
export const C606 = '#0da86f'
export const C607 = '#0000002E'
export const C608 = '#00A86B'
export const C609 = '#B5E0C9'
export const C610 = '#00A86B'
export const C611 = '#FFFAEC'
export const C612 = '#21252B'
export const C613 = '#00000080'
export const C614 = '#FCFCFC'


export const MC1 = '#656985B5'
export const MC2 = '#2A2D3E'
export const MC3 = '#231F2040'