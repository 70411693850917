import GameSvg from './icons/GameSvg'
import HomeSvg from './icons/HomeSvg'
import TodayStudySvg from './icons/TodayStudySvg'
import LevelUnitSvg from './icons/LevelUnitSvg'
import ExerciseSvg from './icons/ExerciseSvg'
import VocabularySvg from './icons/VocabularySvg'
import MemoryBoxSvg from './icons/MemoryBoxSvg'
import DictionarySvg from './icons/DictionarySvg'
import RadioStationSvg from './icons/RadioStationSvg'
import NewspaperSvg from './icons/NewspaperSvg'
import TedVideosSvg from './icons/TedVideosSvg'
import MyNotepadSvg from './icons/MyNotepadSvg'
import MessagesNotificationsSvg from './icons/MessagesNotificationsSvg'
import SettingsSvg from './icons/SettingsSvg'
import AccountRenewalSvg from './icons/AccountRenewalSvg'
import HelpSvg from './icons/HelpSvg'
import FAQSvg from './icons/FAQSvg'
import ContactUsSvg from './icons/ContactUsSvg'
import StatisticsSvg from "./icons/StatisticsSvg";
import AboutVellsoSvg from "./icons/AboutVellsoSvg";

export const levelsData = [
	{
		'id': 'LEVEL_1',
		'title': 'Beginner 1 (A1)',
		'backgroundColorCode': 'C473',
		'unitsCount': '10 Units',
		'units': [
			{
				'isBasic': true,
				'unitNumber': 0,
				'title': 'Basics',
				'desc': 'Alphabet / Numbers',
				'background': 'unit1.jpg',
			},
			{
				'isBasic': false,
				'unitNumber': 1,
				'title': 'Unit 1',
				'desc1': 'To Be 1 (am/is)',
				'desc2': 'Greetings 1',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C446',
			},
			{
				'isBasic': false,
				'unitNumber': 2,
				'title': 'Unit 2',
				'desc1': 'To Be 2 (am/is)',
				'desc2': 'Greetings 2',
				'picture': 'unit2.jpg',
				'backgroundColor': 'C447',
			},
			{
				'isBasic': false,
				'unitNumber': 3,
				'title': 'Unit 3',
				'desc1': 'To be 3 (- ?)',
				'desc2': 'Greetings 3',
				'picture': 'unit3.jpg',
				'backgroundColor': 'C448',
			},
			{
				'isBasic': false,
				'unitNumber': 4,
				'title': 'Unit 4',
				'desc1': 'Present simple (+)',
				'desc2': 'Daily routine',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C449',
			},
			{
				'isBasic': false,
				'unitNumber': 5,
				'title': 'Unit 5',
				'desc1': 'Present simple (- ?)',
				'desc2': 'Weekdays',
				'picture': 'unit5.jpg',
				'backgroundColor': 'C450',
			},
			{
				'isBasic': false,
				'unitNumber': 6,
				'title': 'Unit 6',
				'desc1': 'Singular/Plural',
				'desc2': '(Fruits & Vegetables)',
				'picture': 'unit6.jpg',
				'backgroundColor': 'C451',
			},
			{
				'isBasic': false,
				'unitNumber': 7,
				'title': 'Unit 7',
				'desc1': 'Demonstratives (This/That/These/Those)',
				'desc2': 'School supplies',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C452',
			},
			{
				'isBasic': false,
				'unitNumber': 8,
				'title': 'Unit 8',
				'desc1': 'Present Continuous 1',
				'desc2': 'Countries & Nationalities',
				'picture': 'unit2.jpg',
				'backgroundColor': 'C453',
			},
			{
				'isBasic': false,
				'unitNumber': 9,
				'title': 'Unit 9',
				'desc1': 'Present Continuous 2',
				'desc2': 'What are you doing?',
				'picture': 'unit3.jpg',
				'backgroundColor': 'C454',
			},
			{
				'isBasic': false,
				'unitNumber': 10,
				'title': 'Unit 10',
				'desc1': 'Frequency Adverbs 1',
				'desc2': 'What time is it?',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C455',
			},
		],
	},
	{
		'id': 'LEVEL_2',
		'title': 'Beginner 2 (A1)',
		'backgroundColorCode': 'C474',
		'unitsCount': '11 Units',
		'units': [

			{
				'isBasic': false,
				'unitNumber': 11,
				'title': 'Unit 11',
				'desc1': 'Frequency Adverbs 2',
				'desc2': 'Around the house 1',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C435',
			},
			{
				'isBasic': false,
				'unitNumber': 12,
				'title': 'Unit 12',
				'desc1': 'Frequency Adverbs 3 (- ?)',
				'desc2': 'Hobbies & Interests 1',
				'picture': 'unit2.jpg',
				'backgroundColor': 'C436',
			},
			{
				'isBasic': false,
				'unitNumber': 13,
				'title': 'Unit 13',
				'desc1': 'Present simple vs. continuous',
				'desc2': 'Into sports?',
				'picture': 'unit3.jpg',
				'backgroundColor': 'C437',
			},
			{
				'isBasic': false,
				'unitNumber': 14,
				'title': 'Unit 14',
				'desc1': 'Wh-Questions 1',
				'desc2': 'Season',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C438',
			},
			{
				'isBasic': false,
				'unitNumber': 15,
				'title': 'Unit 15',
				'desc1': 'Wh-Questions 2',
				'desc2': 'Months',
				'picture': 'unit5.jpg',
				'backgroundColor': 'C439',
			},
			{
				'isBasic': false,
				'unitNumber': 16,
				'title': 'Unit 16',
				'desc1': 'Wh-Questions 3',
				'desc2': 'Weather',
				'picture': 'unit6.jpg',
				'backgroundColor': 'C440',
			},
			{
				'isBasic': false,
				'unitNumber': 17,
				'title': 'Unit 17',
				'desc1': 'Possessive Adjectives',
				'desc2': 'Family Tree',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C441',
			},
			{
				'isBasic': false,
				'unitNumber': 18,
				'title': 'Unit 18',
				'desc1': 'Adjective 1',
				'desc2': 'Colors',
				'picture': 'unit2.jpg',
				'backgroundColor': 'C442',
			},
			{
				'isBasic': false,
				'unitNumber': 19,
				'title': 'Unit 19',
				'desc1': 'How+Adjective',
				'desc2': 'The Solar System',
				'picture': 'unit3.jpg',
				'backgroundColor': 'C443',
			},
			{
				'isBasic': false,
				'unitNumber': 20,
				'title': 'Unit 20',
				'desc1': 'Can/Can’t',
				'desc2': 'Who Can…?',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C444',
			},
			{
				'isBasic': false,
				'unitNumber': 21,
				'title': 'Unit 21',
				'desc1': 'Review 1',
				'desc2': 'Hobbies and Interests 2',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C445',
			},
		],
	},
	{
		'id': 'LEVEL_3',
		'title': 'Elementary (A2)',
		'backgroundColorCode': 'C475',
		'unitsCount': '17 Units',
		'units': [

			{
				'isBasic': false,
				'unitNumber': 22,
				'title': 'Unit 22',
				'desc1': 'Prepositions 1',
				'desc2': 'Around the house 2',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C348',
			},
			{
				'isBasic': false,
				'unitNumber': 23,
				'title': 'Unit 23',
				'desc1': 'Prepositions 2',
				'desc2': 'Around the City',
				'picture': 'unit2.jpg',
				'backgroundColor': 'C349',
			},
			{
				'isBasic': false,
				'unitNumber': 24,
				'title': 'Unit 24',
				'desc1': 'Adverbs',
				'desc2': 'Transportation',
				'picture': 'unit3.jpg',
				'backgroundColor': 'C350',
			},
			{
				'isBasic': false,
				'unitNumber': 25,
				'title': 'Unit 25',
				'desc1': 'How+Adverb',
				'desc2': 'About Animals',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C351',
			},
			{
				'isBasic': false,
				'unitNumber': 26,
				'title': 'Unit 26',
				'desc1': 'Object pronouns',
				'desc2': 'Musical Instruments',
				'picture': 'unit5.jpg',
				'backgroundColor': 'C352',
			},
			{
				'isBasic': false,
				'unitNumber': 27,
				'title': 'Unit 27',
				'desc1': 'Quantifiers 1',
				'desc2': 'At the supermarket',
				'picture': 'unit6.jpg',
				'backgroundColor': 'C353',
			},
			{
				'isBasic': false,
				'unitNumber': 28,
				'title': 'Unit 28',
				'desc1': 'Quantifiers 2',
				'desc2': 'Shopping',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C354',
			},
			{
				'isBasic': false,
				'unitNumber': 29,
				'title': 'Unit 29',
				'desc1': 'Possessive pronouns',
				'desc2': 'Clothes & Accessories',
				'picture': 'unit2.jpg',
				'backgroundColor': 'C355',
			},
			{
				'isBasic': false,
				'unitNumber': 30,
				'title': 'Unit 30',
				'desc1': 'Enough/Too',
				'desc2': 'Food',
				'picture': 'unit3.jpg',
				'backgroundColor': 'C356',
			},
			{
				'isBasic': false,
				'unitNumber': 31,
				'title': 'Unit 31',
				'desc1': 'Feel/Have',
				'desc2': 'Health Problems',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C357',
			},
			{
				'isBasic': false,
				'unitNumber': 32,
				'title': 'Unit 32',
				'desc1': 'Was/Were',
				'desc2': 'Opposites',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C358',
			},
			{
				'isBasic': false,
				'unitNumber': 33,
				'title': 'Unit 33',
				'desc1': 'Past Simple 1',
				'desc2': 'Action Verbs',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C359',
			},
			{
				'isBasic': false,
				'unitNumber': 34,
				'title': 'Unit 34',
				'desc1': 'Past Simple 2',
				'desc2': 'Time to Travel',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C360',
			},
			{
				'isBasic': false,
				'unitNumber': 35,
				'title': 'Unit 35',
				'desc1': 'Present/Past Simple',
				'desc2': 'Getting Married',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C361',
			},
			{
				'isBasic': false,
				'unitNumber': 36,
				'title': 'Unit 36',
				'desc1': 'Past continuous',
				'desc2': 'Car Troubles',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C362',
			},
			{
				'isBasic': false,
				'unitNumber': 37,
				'title': 'Unit 37',
				'desc1': 'Either/Neither',
				'desc2': 'Clothes & Accessories 2',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C363',
			},
			{
				'isBasic': false,
				'unitNumber': 38,
				'title': 'Unit 38',
				'desc1': 'Review 2',
				'desc2': 'School Subjects',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C364',
			},
		],
	},
	{
		'id': 'LEVEL_4',
		'title': 'Pre intermediate (A2)',
		'backgroundColorCode': 'C476',
		'unitsCount': '17 Units',
		'units': [

			{
				'isBasic': false,
				'unitNumber': 39,
				'title': 'Unit 39',
				'desc1': 'Giving suggestion',
				'desc2': 'Special Occasions & Holidays!',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C348',
			},
			{
				'isBasic': false,
				'unitNumber': 40,
				'title': 'Unit 40',
				'desc1': 'a/an/the',
				'desc2': 'Cooking Let’s cook',
				'picture': 'unit2.jpg',
				'backgroundColor': 'C349',
			},
			{
				'isBasic': false,
				'unitNumber': 41,
				'title': 'Unit 41',
				'desc1': 'The same/different',
				'desc2': 'Face Parts',
				'picture': 'unit3.jpg',
				'backgroundColor': 'C350',
			},
			{
				'isBasic': false,
				'unitNumber': 42,
				'title': 'Unit 42',
				'desc1': 'Infinitive',
				'desc2': 'Occupations 1',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C351',
			},
			{
				'isBasic': false,
				'unitNumber': 43,
				'title': 'Unit 43',
				'desc1': 'Future',
				'desc2': 'Occupations 2',
				'picture': 'unit5.jpg',
				'backgroundColor': 'C352',
			},
			{
				'isBasic': false,
				'unitNumber': 44,
				'title': 'Unit 44',
				'desc1': 'Adjective order',
				'desc2': 'Materials & Shapes',
				'picture': 'unit6.jpg',
				'backgroundColor': 'C353',
			},
			{
				'isBasic': false,
				'unitNumber': 45,
				'title': 'Unit 45',
				'desc1': 'Comparatives',
				'desc2': 'City Life',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C354',
			},
			{
				'isBasic': false,
				'unitNumber': 46,
				'title': 'Unit 46',
				'desc1': 'Superlatives',
				'desc2': 'Characteristics 1',
				'picture': 'unit2.jpg',
				'backgroundColor': 'C355',
			},
			{
				'isBasic': false,
				'unitNumber': 47,
				'title': 'Unit 47',
				'desc1': 'As ... as',
				'desc2': 'Stages of Life',
				'picture': 'unit3.jpg',
				'backgroundColor': 'C356',
			},
			{
				'isBasic': false,
				'unitNumber': 48,
				'title': 'Unit 48',
				'desc1': 'Can/Could',
				'desc2': 'At the hotel 1',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C357',
			},
			{
				'isBasic': false,
				'unitNumber': 49,
				'title': 'Unit 49',
				'desc1': 'May/Might',
				'desc2': 'At the hotel 2',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C358',
			},
			{
				'isBasic': false,
				'unitNumber': 50,
				'title': 'Unit 50',
				'desc1': 'Will/Would',
				'desc2': 'Future plans',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C359',
			},
			{
				'isBasic': false,
				'unitNumber': 51,
				'title': 'Unit 51',
				'desc1': 'Would you like',
				'desc2': 'At the restaurant',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C360',
			},
			{
				'isBasic': false,
				'unitNumber': 52,
				'title': 'Unit 52',
				'desc1': 'Should',
				'desc2': 'Healthy life',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C361',
			},
			{
				'isBasic': false,
				'unitNumber': 53,
				'title': 'Unit 53',
				'desc1': 'Must/Have to',
				'desc2': 'Air travel 1',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C362',
			},
			{
				'isBasic': false,
				'unitNumber': 54,
				'title': 'Unit 54',
				'desc1': ' First Conditional',
				'desc2': 'Air travel 2',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C363',
			},
			{
				'isBasic': false,
				'unitNumber': 55,
				'title': 'Unit 55',
				'desc1': 'Review 3',
				'desc2': 'Online world',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C364',
			},
		],
	},
	{
		'id': 'LEVEL_5',
		'title': 'intermediate (B1/B2)',
		'backgroundColorCode': 'C477',
		'unitsCount': '18 Units',
		'units': [

			{
				'isBasic': false,
				'unitNumber': 56,
				'title': 'Unit 56',
				'desc1': 'Negative questions',
				'desc2': 'Our planet',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C314',
			},
			{
				'isBasic': false,
				'unitNumber': 57,
				'title': 'Unit 57',
				'desc1': 'Prepositions 3',
				'desc2': 'Party time!',
				'picture': 'unit2.jpg',
				'backgroundColor': 'C315',
			},
			{
				'isBasic': false,
				'unitNumber': 58,
				'title': 'Unit 58',
				'desc1': 'Infinitive/Gerund 1',
				'desc2': 'Dating',
				'picture': 'unit3.jpg',
				'backgroundColor': 'C316',
			},
			{
				'isBasic': false,
				'unitNumber': 59,
				'title': 'Unit 59',
				'desc1': 'Clauses',
				'desc2': 'About cars',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C317',
			},
			{
				'isBasic': false,
				'unitNumber': 60,
				'title': 'Unit 60',
				'desc1': 'Relative clause 1',
				'desc2': 'Discovery vs. Invention',
				'picture': 'unit5.jpg',
				'backgroundColor': 'C318',
			},
			{
				'isBasic': false,
				'unitNumber': 61,
				'title': 'Unit 61',
				'desc1': 'Relative clause 2',
				'desc2': 'Art',
				'picture': 'unit6.jpg',
				'backgroundColor': 'C319',
			},
			{
				'isBasic': false,
				'unitNumber': 62,
				'title': 'Unit 62',
				'desc1': 'Passive 1',
				'desc2': 'Wonders of the World',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C336',
			},
			{
				'isBasic': false,
				'unitNumber': 63,
				'title': 'Unit 63',
				'desc1': 'Passive 2',
				'desc2': 'Technology',
				'picture': 'unit2.jpg',
				'backgroundColor': 'C337',
			},
			{
				'isBasic': false,
				'unitNumber': 64,
				'title': 'Unit 64',
				'desc1': 'Present Perfect 1',
				'desc2': 'Beauty Salon',
				'picture': 'unit3.jpg',
				'backgroundColor': 'C338',
			},
			{
				'isBasic': false,
				'unitNumber': 65,
				'title': 'Unit 65',
				'desc1': 'Present Perfect 2',
				'desc2': 'Cosmetics',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C339',
			},
			{
				'isBasic': false,
				'unitNumber': 66,
				'title': 'Unit 66',
				'desc1': 'Adjective 2',
				'desc2': 'Movies',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C340',
			},
			{
				'isBasic': false,
				'unitNumber': 67,
				'title': 'Unit 67',
				'desc1': 'Present Perfect/Past Simple',
				'desc2': 'Job interview',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C341',
			},
			{
				'isBasic': false,
				'unitNumber': 68,
				'title': 'Unit 68',
				'desc1': 'Reflexive pronouns',
				'desc2': 'Work time!',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C342',
			},
			{
				'isBasic': false,
				'unitNumber': 69,
				'title': 'Unit 69',
				'desc1': 'Present Perfect Continuous',
				'desc2': 'At the dentist’s',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C343',
			},
			{
				'isBasic': false,
				'unitNumber': 70,
				'title': 'Unit 70',
				'desc1': 'Phrasal Verbs',
				'desc2': 'Telephone terms',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C344',
			},
			{
				'isBasic': false,
				'unitNumber': 71,
				'title': 'Unit 71',
				'desc1': 'Indirect Speech 1',
				'desc2': 'Personality traits',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C345',
			},
			{
				'isBasic': false,
				'unitNumber': 72,
				'title': 'Unit 72',
				'desc1': 'Indirect Speech 2',
				'desc2': 'Emergencies 1',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C346',
			},
			{
				'isBasic': false,
				'unitNumber': 73,
				'title': 'Unit 73',
				'desc1': 'Review 4',
				'desc2': 'Living abroad',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C347',
			},
		],
	},
	{
		'id': 'LEVEL_6',
		'title': 'Upper intermediate (B2)',
		'backgroundColorCode': 'C478',
		'unitsCount': '9 Units',
		'units': [

			{
				'isBasic': false,
				'unitNumber': 74,
				'title': 'Unit 74',
				'desc1': 'Indefinite Pronouns',
				'desc2': 'Emergencies 2',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C456',
			},
			{
				'isBasic': false,
				'unitNumber': 75,
				'title': 'Unit 75',
				'desc1': 'Second Conditional',
				'desc2': 'Natural disaster',
				'picture': 'unit2.jpg',
				'backgroundColor': 'C457',
			},
			{
				'isBasic': false,
				'unitNumber': 76,
				'title': 'Unit 76',
				'desc1': 'Used to',
				'desc2': 'Pet peeves',
				'picture': 'unit3.jpg',
				'backgroundColor': 'C458',
			},
			{
				'isBasic': false,
				'unitNumber': 77,
				'title': 'Unit 77',
				'desc1': 'Past Perfect',
				'desc2': 'Types of thefts',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C459',
			},
			{
				'isBasic': false,
				'unitNumber': 78,
				'title': 'Unit 78',
				'desc1': 'Causative Verbs',
				'desc2': 'Addiction',
				'picture': 'unit5.jpg',
				'backgroundColor': 'C460',
			},
			{
				'isBasic': false,
				'unitNumber': 79,
				'title': 'Unit 79',
				'desc1': 'Past Modals',
				'desc2': 'Crime',
				'picture': 'unit6.jpg',
				'backgroundColor': 'C461',
			},
			{
				'isBasic': false,
				'unitNumber': 80,
				'title': 'Unit 80',
				'desc1': 'Third Conditional',
				'desc2': 'Superstitions',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C462',
			},
			{
				'isBasic': false,
				'unitNumber': 81,
				'title': 'Unit 81',
				'desc1': 'Question Tag',
				'desc2': 'Law & lawsuit',
				'picture': 'unit2.jpg',
				'backgroundColor': 'C463',
			},
			{
				'isBasic': false,
				'unitNumber': 82,
				'title': 'Unit 82',
				'desc1': 'Adverbial Clause 1',
				'desc2': 'Health Facilities',
				'picture': 'unit3.jpg',
				'backgroundColor': 'C464',
			},
		],
	},
	{
		'id': 'LEVEL_7',
		'title': 'Advanced (C1)',
		'backgroundColorCode': 'C479',
		'unitsCount': '8 Units',
		'units': [
			{
				'isBasic': false,
				'unitNumber': 83,
				'title': 'Unit 83',
				'desc1': 'Future Continuous/Perfect',
				'desc2': 'Future generations',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C473',
			},
			{
				'isBasic': false,
				'unitNumber': 84,
				'title': 'Unit 84',
				'desc1': 'Adverbial Clause 2',
				'desc2': 'Wildlife',
				'picture': 'unit2.jpg',
				'backgroundColor': 'C474',
			},
			{
				'isBasic': false,
				'unitNumber': 85,
				'title': 'Unit 85',
				'desc1': 'Reporting Verbs',
				'desc2': 'Languages',
				'picture': 'unit3.jpg',
				'backgroundColor': 'C475',
			},
			{
				'isBasic': false,
				'unitNumber': 86,
				'title': 'Unit 86',
				'desc1': 'Mixed Conditionals',
				'desc2': 'Body language',
				'picture': 'unit4.jpg',
				'backgroundColor': 'C476',
			},
			{
				'isBasic': false,
				'unitNumber': 87,
				'title': 'Unit 87',
				'desc1': 'Noun Clause',
				'desc2': 'Harassment',
				'picture': 'unit5.jpg',
				'backgroundColor': 'C477',
			},
			{
				'isBasic': false,
				'unitNumber': 88,
				'title': 'Unit 88',
				'desc1': 'There is.../There are…',
				'desc2': 'Idioms & Proverbs',
				'picture': 'unit6.jpg',
				'backgroundColor': 'C478',
			},
			{
				'isBasic': false,
				'unitNumber': 89,
				'title': 'Unit 89',
				'desc1': 'Wish',
				'desc2': 'Maintenance of the Mind',
				'picture': 'unit1.jpg',
				'backgroundColor': 'C479',
			},
			{
				'isBasic': false,
				'unitNumber': 90,
				'title': 'Unit 90',
				'desc1': 'Ellipsis/Substitution',
				'desc2': 'At the bank',
				'picture': 'unit2.jpg',
				'backgroundColor': 'C480',
			},
		],
	},
]

export const sidebarData = [
	{
		backgroundCode: 'C3',
		items: [
			{
				title: 'Home',
				icon: (props) => <HomeSvg {...props} />,
				url: '/',
				isPremium: false
			},
		],
	},
	{
		backgroundCode: 'C4',
		items: [
			{
				title: 'Study Plan',
				icon: (props) => <TodayStudySvg {...props} />,
				url: '/study-plan',
				isPremium: true
			},
		],
	},
	{
		backgroundCode: 'C5',
		items: [
			{
				title: 'Levels & Units',
				icon: (props) => <LevelUnitSvg {...props} />,
				url: '/level-unit',
				isPremium: false
			},
			{
				title: 'Exercises',
				icon: (props) => <ExerciseSvg {...props} />,
				url: '/exercise',
				isPremium: true
			},
			{
				title: 'Vocabulary',
				icon: (props) => <VocabularySvg {...props} />,
				url: '/vocabulary/units',
				isPremium: true
			},
			{
				title: 'Statistics',
				icon: (props) => <StatisticsSvg {...props} />,
				url: '/statistics',
				isPremium: true
			},
		],
	},
	{
		backgroundCode: 'C6',
		items: [
			{
				title: 'Memory Box',
				icon: (props) => <MemoryBoxSvg {...props} />,
				url: '/memory-box',
				isPremium: true
			},
		],
	},
	{
		backgroundCode: 'C7',
		items: [
			{
				title: 'Games',
				icon: (props) => <GameSvg {...props} />,
				url: '/games',
				isPremium: true
			},
			{
				title: 'Dictionary',
				icon: (props) => <DictionarySvg {...props} />,
				url: '/dictionary',
				isPremium: true
			},
			// {
			// 	title: 'Radio Station',
			// 	icon: (props) => <RadioStationSvg {...props} />,
			// 	url: '/radio-station',
			// 	isPremium: true,
			// 	isLocked: true
			// },
			// {
			// 	title: 'Newspaper',
			// 	icon: (props) => <NewspaperSvg {...props} />,
			// 	url: '/newspaper',
			// 	isPremium: true,
			// 	isLocked: true
			// },
			// {
			// 	title: 'TED Videos',
			// 	icon: (props) => <TedVideosSvg {...props} />,
			// 	url: '/ted-videos',
			// 	isPremium: true,
			// 	isLocked: true
			// },
		],
	},
	{
		backgroundCode: 'C8',
		items: [
			{
				title: 'My Notepad',
				icon: (props) => <MyNotepadSvg {...props} />,
				url: '/notepad',
				isPremium: true
			},
			{
				title: 'Messages & Notifications',
				icon: (props) => <MessagesNotificationsSvg {...props} />,
				url: '/messages',
				isPremium: true
			},
		],
	},
	{
		backgroundCode: 'C9',
		items: [
			{
				title: 'Settings',
				icon: (props) => <SettingsSvg {...props} />,
				url: '/settings',
				isPremium: false
			},
			{
				title: 'Account Renewal',
				icon: (props) => <AccountRenewalSvg {...props} />,
				url: '/payment',
				isPremium: false
			},
		],
	},
	{
		backgroundCode: 'C10',
		items: [
			{
				title: 'Help & Guide',
				icon: (props) => <HelpSvg {...props} />,
				url: '/help-and-guide',
				isPremium: false
			},
			{
				title: 'FAQ',
				icon: (props) => <FAQSvg {...props} />,
				url: '/faq',
				isPremium: false
			},
			{
				title: 'About Vellso',
				icon: (props) => <AboutVellsoSvg {...props} />,
				url: '/about',
				isPremium: false,
				isBeta: true
			},
			{
				title: 'Contact Us',
				icon: (props) => <ContactUsSvg {...props} />,
				url: '/contact-us',
				isPremium: false
			},
		],
	},
]

export const moreOptionsList = [
	'LISTENING',
	'SPEAKING',
	'SPELLING',
	'BOX',
	'FAVORITES',
	'TRANSLATE',
]