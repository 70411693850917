import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSidebarIsOpen} from "../redux/features/sidebarSlice";

const Navbar = ({backgroundColor}) => {
  const dispatch = useDispatch()
  const {sidebarIsOpen} = useSelector(state => state.sidebar)

  const toggleSidebar = () => {
    dispatch(setSidebarIsOpen(!sidebarIsOpen))
  }

  return (
    <>
      <button className="menu-btn cursor-pointer" onClick={toggleSidebar}>
        <span style={{background: backgroundColor}}/>
        <span
          style={{
            background: backgroundColor,
            transform: sidebarIsOpen ? "translateX(-10px)" : "translateX(0)"
          }}
        />
        <span style={{background: backgroundColor}}/>
      </button>
    </>
  );
}

export default Navbar;