import serviceCall from "../../services";
import dictionaryMapper from "../../services/mapper/dictionary/dictionaryMapper";
import { transliterate } from "transliteration";
import nativeDictionaryMapper from "../../services/mapper/dictionary/nativeDictionaryMapper";
import * as Sentry from "@sentry/react";

const dictionaryUseCase = async ({ phrase }) => {
  const data = await serviceCall(
    'fetchDictionary',
    { phrase: transliterate(phrase) },
    'cloud'
  )

  if (data?.freeDictionaryDTO === null) {
    Sentry.withScope(function (scope) {
      scope.addEventProcessor(function (event, hint) {
        // Add anything to the event here
        console.log("event from dictionary:", event)
        // returning `null` will drop the event
        return event;
      });

      Sentry.captureMessage(
        `Word data from vellso-dict service is empty for: ${phrase}`,
        {
          level: 'warning',
          extra: { phrase, url: window.location.href },
          tags: {
            "captureSession": true
          }
        }
      )
    });
  }

  return {
    vellsoDictionary: data?.vellsoDictionary ? nativeDictionaryMapper(data?.vellsoDictionary) : null,
    freeDictionary: data?.freeDictionaryDTO ? dictionaryMapper(data?.freeDictionaryDTO) : null,
    phrase,
    voices: {
      US: null,
      UK: null
    },
  }
}

export default dictionaryUseCase