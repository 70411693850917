import React, { useContext, useEffect, useState } from 'react'
import HelpButton from '../../../components/HelpButton/HelpButton'
import ExerciseStatistics from '../../../components/ExerciseStatistics/ExerciseStatistics'
import GameStatistics from '../../../components/GameStatistics/GameStatistics'
import colorsContext from '../../../contexts/ColorsContext'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUnitGameStatistics, getUnitStatistics } from '../../../redux/features/statisticsSlice'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import { FINISH_SECTION, START_SECTION } from "../../../enums/studyLogEnums/studyLogEnums";
import useLog from "../../../hooks/logHooks/useLog";
import UnitDataStorage from "../../../classes/UnitDataStorage";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import useSectionEvents from "../../../hooks/sectionHooks/useSectionEvents";
import useActionRetryerWrapper from '../../../hooks/useDispatchWrapper'

const StatisticsSection = () => {
  const { colors } = useContext(colorsContext)
  const { unit } = useParams()
  const dispatch = useDispatch()
  const { networkLossRetryerDispatch } = useActionRetryerWrapper()
  const { unitExerciseData, unitGameData } = useSelector(state => state.statistics)
  const { logPending } = useSelector(state => state.studyLog)
  const { navigateToNextSection } = useSectionEvents()
  const { sendServerLog } = useLog()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unitDataStorage = new UnitDataStorage()
    const unitData = unitDataStorage.getUnitData()

    sendServerLog(START_SECTION, null, () => {
      setTimeout(() => {
        sendServerLog(FINISH_SECTION, null, () => {
          setTimeout(() => {
            networkLossRetryerDispatch(retryId => {
              dispatch(getUnitStatistics({ unitId: unit, studyType: unitData?.studyStatus, retryId })).then(() => setLoading(false))
            })
            networkLossRetryerDispatch(retryId => {
              dispatch(getUnitGameStatistics({ unitId: unit, studyType: unitData?.studyStatus, retryId }))
            })
          }, 300)
        })
      }, 300)
    })
  }, [])

  return (
    <div className='py-3'>
      <div className='d-flex justify-content-end container-fluid'>
        <div className='me-3 me-md-5 ms-2'>
          <HelpButton color={colors['C2']} pageType={pageGuidEnums.STATISTICS_SECTION} />
        </div>
      </div>
      {!loading ?
        <>
          <ExerciseStatistics data={unitExerciseData} />
          <GameStatistics data={unitGameData} />
        </> :
        <div className='d-flex align-items-center justify-content-center' style={{ height: "calc(100dvh - 263px)" }}>
          <div
            className='spinner spinner-border'
            style={{ borderColor: colors['C2'], borderRightColor: 'transparent' }}
          />
        </div>
      }
      <div className='container-fluid d-flex justify-content-center'>
        <PrimaryButton
          loading={logPending}
          onClick={navigateToNextSection}
        >
          Done
        </PrimaryButton>
      </div>
    </div>
  )
}

export default StatisticsSection