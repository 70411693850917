import React from "react";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

export default function Bar(props) {
  const {duration, curTime, onTimeUpdate, elementsColor} = props;

  const curPercentage = (curTime / duration) * 100;

  function formatDuration(duration) {
    return moment
      .duration(duration, "seconds")
      .format("mm:ss", {trim: false});
  }

  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX;
    const bar = document.querySelector(".bar__progress");
    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  }

  function handleTimeDrag(e) {
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = eMove => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener("mousemove", updateTimeOnMove);

    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", updateTimeOnMove);
    });
  }

  return (
    <div className="bar">
      <span className="bar__time font-size-18" style={{color: elementsColor}}>
        {formatDuration(curTime)}
      </span>
      <div
        className="bar__progress"
        onMouseDown={e => handleTimeDrag(e)}
        style={{
          // background: `linear-gradient(to right, ${elementsColor} ${curPercentage}%, ${elementsColor} 0)`
          background: elementsColor
        }}
      >
        <span
          className="bar__progress__before"
          style={{width: `${curPercentage}%`, backgroundColor: elementsColor}}
        />
        <span
          className="bar__progress__knob"
          style={{
            left: `${curPercentage - 1}%`,
            backgroundColor: elementsColor
          }}
        />
      </div>
      <span className="bar__time font-size-18" style={{color: elementsColor}}>
        {formatDuration(duration)}
      </span>
    </div>
  );
}
