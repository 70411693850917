import {isMobile} from "react-device-detect"
import {useEffect, useState} from "react"
import HelpVideoMobile from "./HelpVideoMobile"
import HelpVideoDesktop from "./HelpVideoDesktop";

const HelpVideoModal = ({onClose, videos = []}) => {
  const renderMobileComponent = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
      setIsOpen(true)
    }, [])

    return (
      <>
        {isOpen &&
          <HelpVideoMobile
            videos={videos}
            close={() => {
              setIsOpen(false)
              onClose()
            }}
          />
        }
      </>
    )
  }

  const renderDesktopComponent = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
      setIsOpen(true)
    }, [])

    return (
      <>
        {isOpen &&
          <HelpVideoDesktop
            videos={videos}
            close={() => {
              setIsOpen(false)
              onClose()
            }}
          />
        }
      </>
    )

  }

  if (isMobile) {
    return renderMobileComponent()
  } else {
    return renderDesktopComponent()
  }
}

export default HelpVideoModal