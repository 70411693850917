import {sectionsData} from '../../../sources/sectionsData'

const fetchReviewMistakesMapper = (data) => {
	return [...data].map(item => {
		const sectionObject = sectionsData.find(o => o.sectionType === item.sectionType)
		return {
			...item,
			userAnswerItemResponse: [...item.userAnswerItemResponse].map((it) => {
				return {
					...it,
					itemId: it.id,
					index: it.index,
					listIndex: it.listIndex,
					userAnswerStatus: it.userAnswerStatus
				}
			}),
			sectionName: sectionObject?.title ?? item.sectionType,
			sectionNumber: sectionObject?.id ?? null,
		}
	})
}

export default fetchReviewMistakesMapper