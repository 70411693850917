import serviceCall from "../../services";
import boxMapper from "../../services/mapper/memoryBox/boxMapper";
import {ALL, VOCABULARY} from "../../enums/globalEnums/globalEnums";

const getYourSelectionBoxesUseCase = async ({ page, categoryId }) => {
	const boxes = await serviceCall(
		'fetchYourSelectionBoxes',
		{
			categoryId,
			isVocab: page === ALL ? '' : page === VOCABULARY
		},
		'cloud',
	)

	return boxes.map((item) => {
		return boxMapper(item);
	});
}

export default getYourSelectionBoxesUseCase