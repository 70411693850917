import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setGlobalAudioId } from '../../redux/features/audioSlice'
import { v4 as uuidv4 } from 'uuid'
import getCachedMediaUrl from '../../operations/getCachedMediaUrl'
import { isNumber } from "lodash"
import * as ogv from 'ogv'
import usePageVisibility from '../usePageVisibility'
import { isMobile } from 'react-device-detect'

ogv.OGVLoader.base = '/assets/js/ogvjs-1.8.9'

function useOgvAudioPlayer(
  {
    audioUrl,
    preloadOnRender,
    autoplay,
    audioElementId,
    stopOnPause = false,
    defaultSeek = 0,
    group = true, // When group is true, it will be considered as a sound in global group; Only one sound in a group could be played at the same time
    onPlayed = () => {
    },
    onEnded = () => {
    },
    disabled = false,
    accent
  }
) {
  const pageVisibilityHidden = usePageVisibility()
  const loadTypes = useMemo(() => ({
    notLoaded: 0,
    loading: 1,
    loadSuccess: 2,
    loadError: 3
  }), [])

  const sound = useRef(null)
  // Generate unique sound id for this component (purpose: play one sound in a group at the same time)
  // With prefix "ACI_" => Audio Component Id
  const soundId = useRef(`ACI_${uuidv4()}`)

  const isMounted = useRef(true); // New ref to keep track of component's mount status

  const [duration, setDuration] = useState(0)
  const [curTime, setCurTime] = useState()
  const [playing, setPlaying] = useState(false)
  const [playerStatus, setPlayerStatus] = useState('STOP')
  const [loadingState, setLoadingState] = useState(loadTypes.notLoaded)
  const startTime = useRef(0)
  const waitingToPlay = useRef(false)

  const { currentGlobalAudioId: gSoundId } = useSelector(state => state.audio)
  const dispatch = useDispatch()

  useEffect(() => {
    // Set mounted on mount
    isMounted.current = true;
    return () => {
      // Set unmounted on cleanup
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    soundId.current = `ACI_${uuidv4()}`
  }, [audioUrl, accent])

  const onPlayEvent = () => {
    if (startTime.current > 0) {
      setAudioSeekTime(startTime.current)
      startTime.current = 0
    }

    onPlayed((isPlaying) => setPlaying(isPlaying))
    setPlayerStatus('START_PLAYING')

    if (group) {
      dispatch(setGlobalAudioId(soundId.current))
    }
  }

  useEffect(() => {
    if (isMobile && pageVisibilityHidden && playing) {
      setPlaying(false)
    }
  }, [pageVisibilityHidden])

  const onPauseEvent = () => {
    setPlayerStatus('PAUSED')
  }

  const onEndEvent = () => {
    onEnded((isPlaying) => setPlaying(isPlaying))
    setPlaying(false)
    setPlayerStatus('ENDED')
  }

  const onLoadEvent = (autoplay) => {
    if (isMounted.current && sound.current) {
      setAudioSeekTime(defaultSeek)
      if (!disabled && (autoplay || waitingToPlay.current)) {
        setTimeout(() => {
          sound.current.play()
          waitingToPlay.current = false
        }, 180)
      }
      setDuration(sound.current.duration)
      setCurTime(sound.current.currentTime)
      setPlayerStatus('LOADED')
      setLoadingState(loadTypes.loadSuccess)
    }
  }

  const onTimeUpdateEvent = () => {
    setCurTime(sound.current.currentTime)
  }

  const onLoadFailedEvent = () => {
    setLoadingState(loadTypes.loadError)
    waitingToPlay.current = false
  }

  const createSound = async (autoplay) => {
    setLoadingState(loadTypes.loading)

    if (sound.current) {
      sound.current.unload()
      document.body.removeChild(sound.current)
      sound.current = null
    }

    const res = await getCachedMediaUrl(audioUrl)

    if (res && isMounted.current) { // Check if component is still mounted
      sound.current = new ogv.OGVPlayer()
      document.body.appendChild(sound.current)
      sound.current.src = res

      sound.current.addEventListener('error', onLoadFailedEvent)
      sound.current.addEventListener('loadeddata', () => onLoadEvent(autoplay))
      sound.current.addEventListener('timeupdate', onTimeUpdateEvent)
      sound.current.addEventListener('ended', onEndEvent)
      sound.current.addEventListener('pause', onPauseEvent)
      sound.current.addEventListener('play', onPlayEvent)

      sound.current.unload = () => {
        sound.current.stop()
        sound.current.removeEventListener('error', onLoadFailedEvent)
        sound.current.removeEventListener('loadeddata', () => onLoadEvent(autoplay))
        sound.current.removeEventListener('timeupdate', onTimeUpdateEvent)
        sound.current.removeEventListener('ended', onEndEvent)
        sound.current.removeEventListener('pause', onPauseEvent)
        sound.current.removeEventListener('play', onPlayEvent)
      }
    }
  }

  useEffect(() => {
    if (audioUrl && preloadOnRender) {
      createSound(autoplay)
    }
  }, [audioUrl, preloadOnRender, accent])

  useEffect(() => {
    // effect cleanup
    if (sound.current) {
      return () => {
        sound.current.unload()
        setDuration(0)
        setCurTime(0)
        setPlaying(false)
        setPlayerStatus('STOP')
        document.body.removeChild(sound.current)
        sound.current = null
        setLoadingState(loadTypes.notLoaded)
      }
    }
  }, [sound.current, audioElementId, audioUrl, accent])

  useEffect(() => {
    if (gSoundId !== soundId.current && sound.current) {
      setPlaying(false)
    }
  }, [gSoundId])

  useEffect(() => {
    if (!disabled) {
      if (playing && isMounted.current) {
        onPlay()
      } else {
        onPause()
      }
    }
  }, [playing])

  useEffect(() => {
    if (playerStatus === 'START_PLAYING' && isNumber(curTime))
      setPlayerStatus('PLAYED')
  }, [curTime])

  const setAudioSeekTime = (newTime) => {
    if (sound.current && isNumber(newTime) && newTime !== sound.current.currentTime) {
      sound.current.currentTime = Math.max(0, Math.min(sound.current.duration, newTime))
      setCurTime(sound.current.currentTime)
    }
  }

  const onPlay = () => {
    if (loadingState === loadTypes.notLoaded) {
      createSound(true)
    } else if (loadingState === loadTypes.loadSuccess) {
      sound.current?.play()
    } else if (loadingState === loadTypes.loading) {
      waitingToPlay.current = true
    } else {
      setPlaying(false)
    }
  }

  const onPause = () => {
    if (sound.current && !sound.current.paused) {
      if (stopOnPause) {
        sound.current.stop()
      } else {
        sound.current.pause()
      }
    }
  }

  const setPlayingFunc = (isPlaying, playTime) => {
    if (typeof playTime === 'number') {
      startTime.current = playTime
    }
    setPlaying(isPlaying)
  }


  return {
    curTime,
    duration,
    playing: playing && sound.current && sound.current.currentTime > 0,
    playerStatus,
    setPlaying: setPlayingFunc,
    setAudioSeekTime,
  }
}

export default useOgvAudioPlayer