import serviceCall from "../../services";
import aiPromptMapper from "../../services/mapper/aiPrompt/aiPromptMapper";

const aiPromptUseCase = async (data) => {
  const result = await serviceCall(
    'postAiChatPrompt',
    data,
    'cloud',
  )

  return aiPromptMapper(result)
}

export default aiPromptUseCase