import GrammarWrap from '../pages/Sections/GrammarWrap/GrammarWrap'
import MoreExamples from '../pages/Sections/MoreExamples/MoreExamples'
import Conversation from '../pages/Sections/Conversation/Conversation'
import ExerciseSection from '../pages/Sections/Exercises/ExerciseSection'
import Pictionary from '../pages/Sections/Pictionary'
import PointPlus from '../pages/Sections/PointPlus'
import Reading from '../pages/Sections/Reading/Reading'
import Writing from '../pages/Sections/Writing'
import Drill from '../pages/Sections/Drill'
import RoboChat from '../pages/Sections/RoboChat/RoboChat'
import Nutshell from '../pages/Sections/Nutshell'
import ReviewMistakes from '../pages/Sections/ReviewMistakes/ReviewMistakes'
import GamesSectionPage from '../pages/Sections/GamesSection/GamesSectionPage'
import StatisticsSection from '../pages/Sections/Statistics/StatisticsSection'
import {
	CONVERSATION,
	CONVERSATION_EXERCISE_1,
	CONVERSATION_EXERCISE_2, DRILLING, GAME,
	GRAMMAR_EXERCISE_1,
	GRAMMAR_EXERCISE_2,
	GRAMMAR_WRAP, IN_A_NUTSHELL,
	LISTENING_EXERCISE,
	MORE_EXAMPLE,
	PICTIONARY,
	PICTIONARY_EXERCISE,
	POINT_PLUS,
	POINT_PLUS_EXERCISE,
	READING, READING_EXERCISE, REVIEW_THE_MISTAKES, ROBO_CHAT,
	SPEAKING_EXERCISE,
	SPELLING_EXERCISE, STATISTICS, WRITING_EXERCISE,
} from '../enums/sectionEnums/sectionEnums'

export const sectionsData = [
	{
		...GRAMMAR_WRAP,
		element: <GrammarWrap sectionName='GRAMMAR_WRAP' />,
	},
	{
		...MORE_EXAMPLE,
		element: <MoreExamples sectionName='MORE_EXAMPLE' />,
	},
	{
		...CONVERSATION,
		element: <Conversation sectionName='CONVERSATION' />,
	},
	{
		...CONVERSATION_EXERCISE_1,
		element: <ExerciseSection sectionName='CONVERSATION_EXERCISE_1' />,
	},
	{
		...CONVERSATION_EXERCISE_2,
		element: <ExerciseSection sectionName='UNDERSTANDING_CONVERSATION_EXERCISE_2' />,
	},
	{
		...GRAMMAR_EXERCISE_1,
		element: <ExerciseSection sectionName='GRAMMAR_EXERCISE_3' />,
	},
	{
		...GRAMMAR_EXERCISE_2,
		element: <ExerciseSection sectionName='GRAMMAR_EXERCISE_4' />,
	},
	{
		...LISTENING_EXERCISE,
		element: <ExerciseSection sectionName='LISTENING_EXERCISE_5' />,
	},
	{
		...SPEAKING_EXERCISE,
		element: <ExerciseSection sectionName='SPEAKING_EXERCISE_6' />,
	},
	{
		...SPELLING_EXERCISE,
		element: <ExerciseSection sectionName='SPELLING_EXERCISE_7' />,
	},
	{
		...PICTIONARY,
		element: <Pictionary sectionName='PICTIONARY' />,
	},
	{
		...PICTIONARY_EXERCISE,
		element: <ExerciseSection sectionName='PICTIONARY_EXERCISE_8' />,
	},
	{
		...POINT_PLUS,
		element: <PointPlus sectionName='POINT_PLUS' />,
	},
	{
		...POINT_PLUS_EXERCISE,
		element: <ExerciseSection sectionName='POINT_PLUS_EXERCISE_9' />,
	},
	{
		...READING,
		element: <Reading sectionName='READING' />,
	},
	{
		...READING_EXERCISE,
		element: <ExerciseSection sectionName='READING_EXERCISE_10' />,
	},
	{
		...WRITING_EXERCISE,
		element: <Writing sectionName='WRITING_EXERCISE_11' />,
	},
	{
		...DRILLING,
		element: <Drill sectionName='DRILL' />,
	},
	{
		...ROBO_CHAT,
		element: <RoboChat sectionName='ROBO_CHAT' />,
	},
	{
		...IN_A_NUTSHELL,
		element: <Nutshell sectionName='IN_A_NUTSHELL' />,
	},
	{
		...GAME,
		element: <GamesSectionPage />,
	},
	{
		...REVIEW_THE_MISTAKES,
		element: <ReviewMistakes />,
	},
	{
		...STATISTICS,
		element: <StatisticsSection/>,
	},
]