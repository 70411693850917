import serviceCall from "../../services";
import EvaluationReportMapper from "../../services/mapper/evaluationReport/evaluationReportMapper";

const EvaluationReportUseCase = async () => {

  const response = await serviceCall(
    'fetchEvaluationResult',
    null,
    'cloud',
  )
  return EvaluationReportMapper(response)
}
export default EvaluationReportUseCase