import { isEmpty } from "lodash"
import { AnswerStatusEnums } from '../../enums/globalEnums/globalEnums'
import { getLevenshteinDistance } from "../levenshtein/levenshtein"
import _ from 'lodash'

export const answerComparator = (correctAnswer = '', userAnswer = '', specialNamesCount = 0) => {
  const tokenize = (sentence) => {
    // Split the sentence into words using whitespace as the separator.
    return sentence.split(/\s+/)
  }

  const getLevenshteinDistanceInfo = (userToken = '', correctWord = '') => {
    const levenshteinDistance = getLevenshteinDistance(userToken.toLowerCase(), correctWord.toLowerCase())

    const isMatchPerfect = levenshteinDistance === 0
    const isMatchWithTypo = levenshteinDistance === 1
    const isIncorrect = levenshteinDistance > 1

    return {
      levenshteinDistance,
      isMatchPerfect,
      isMatchWithTypo,
      isIncorrect
    }
  }

  const processUserAnswer = () => {
    // Variables
    let correctTokens = tokenize(correctAnswer)
    let userTokens = tokenize(userAnswer)

    let userIndex = 0
    let startIndex = 0

    let final = []
    let result = []


    const findMatchWord = (userTokenIndex, startIndex) => {
      const remainingCorrectWords = [...correctTokens.slice(startIndex)]
      const userToken = userTokens[userTokenIndex]

      let defaultLevenshteinInfo = {
        userIndex: userTokenIndex,
        correctIndex: null,
        correctWord: null,
        userWord: userToken,
        distance: -1
      }

      let matchedWords = []

      remainingCorrectWords.every((correctWord, i) => {
        const {
          levenshteinDistance,
          isMatchPerfect,
          isMatchWithTypo
        } = getLevenshteinDistanceInfo(userToken, correctWord)

        const levenshteinInfo = {
          userIndex: userTokenIndex,
          correctIndex: startIndex + i,
          correctWord: correctWord,
          userWord: userToken,
          distance: levenshteinDistance
        }

        if (isMatchPerfect || isMatchWithTypo) {
          if (i === 0 || startIndex + i === userTokenIndex) {
            // save first word as matched
            matchedWords.push(levenshteinInfo)
            // break the loop
            return false
          } else {
            const nextUserToken = userTokens[userTokenIndex + 1]
            const nextCorrectToken = remainingCorrectWords[i + 1]

            const {
              isMatchPerfect: nextIsMatchPerfect,
              isMatchWithTypo: nextIsMatchWithTypo
            } = getLevenshteinDistanceInfo(nextUserToken, nextCorrectToken)

            if (nextIsMatchWithTypo || nextIsMatchPerfect) {
              // save match
              matchedWords.push({
                userIndex: userTokenIndex,
                correctIndex: startIndex + i,
                correctWord: correctWord,
                userWord: userToken,
                distance: levenshteinDistance
              })
              // break the loop
              return false
            }
          }
        }

        return true
      })

      if (matchedWords.length === 0) {
        return defaultLevenshteinInfo
      } else {
        return _.minBy(matchedWords, 'distance')
      }
    }

    while (userIndex < userTokens.length && correctTokens.length > 0) {
      const matchedWord = findMatchWord(userIndex, startIndex)
      final.push(matchedWord)
      final = _.sortBy(final, o => o.userIndex)

      userIndex++
      startIndex = (_.isNumber(matchedWord?.correctIndex) ? matchedWord?.correctIndex + 1 : startIndex)
    }

    // Create an array to track which correct words have been used
    let usedCorrectIndices = new Array(correctTokens.length).fill(false);

    // First pass to mark used correct indices
    for (let obj of final) {
      if (obj.correctIndex !== null) {
        usedCorrectIndices[obj.correctIndex] = true;
      }
    }

    // Second pass to try to match words that are mistakes but not null
    for (let i = 0; i < final.length; i++) {
      const obj = final[i]
      if (obj.correctIndex === null && obj.userWord !== null) {
        let previousCorrectIndex = Math.max.apply(Math, [...final].slice(0, i).filter(o => typeof o.correctIndex === 'number').map(o => o.correctIndex))
        let nextCorrectIndex = Math.min.apply(Math, [...final].slice(i + 1).filter(o => typeof o.correctIndex === 'number').map(o => o.correctIndex))

        previousCorrectIndex = Math.abs(previousCorrectIndex) === Infinity ? -1 : previousCorrectIndex
        nextCorrectIndex = Math.abs(nextCorrectIndex) === Infinity ? -1 : nextCorrectIndex

        let fixedCorrectIndex

        if (previousCorrectIndex === -1 && nextCorrectIndex >= 1) { // At first
          fixedCorrectIndex = 0
        } else if (previousCorrectIndex >= 0) {
          const currentCorrectIndex = previousCorrectIndex + 1
          if ((nextCorrectIndex === -1 && currentCorrectIndex < final.length) || nextCorrectIndex > currentCorrectIndex) {
            fixedCorrectIndex = currentCorrectIndex
          }
        }

        if (typeof fixedCorrectIndex === 'number') {
          obj.correctIndex = fixedCorrectIndex;
          obj.correctWord = correctTokens[fixedCorrectIndex];
          usedCorrectIndices[i] = true;
        }
      }
    }

    const correctIndices = new Set(final.map(item => item.correctIndex).filter(index => index !== null))

    const missingObjects = [];

    correctTokens.forEach((word, index) => {
      if (!correctIndices.has(index)) {
        missingObjects.push({
          userIndex: null,
          correctIndex: index,
          correctWord: word,
          userWord: null,
          distance: -1
        })
      }
    })

    let modifiedUserArray = [...final]
    let integratedMissedObjects = 0

    // Insert the missing objects back into userArray at the correct positions
    const attachMissedObjects = (missedObjects, i) => {
      for (let j = 0; j < missedObjects.length; j++) {
        const correctIndex = missedObjects[j]
        const missingObject = missingObjects.find(o => o.correctIndex === correctIndex)

        modifiedUserArray.splice(i + integratedMissedObjects, 0, missingObject)
        integratedMissedObjects++
      }
    }
    final.forEach((item, i) => {
      if (_.isNumber(item.correctIndex)) {
        if (i === 0) {
          const missedObjects = _.range(0, item.correctIndex, 1)
          attachMissedObjects(missedObjects, i)
        } else {
          const previousCorrectIndex = final[i - 1]?.correctIndex
          if (_.isNumber(previousCorrectIndex)) {
            const missedObjects = _.range(previousCorrectIndex + 1, item.correctIndex, 1)
            attachMissedObjects(missedObjects, i)
          }
        }
        // Check missed object at the end of array
        if (i === final.length - 1 && (item.correctIndex + 1 < correctTokens.length)) {
          const missedObjects = _.range(item.correctIndex + 1, correctTokens.length, 1)
          attachMissedObjects(missedObjects, i + 1)
        }
      }
    })

    // Create final result
    modifiedUserArray.forEach((item) => {
      const correctStr = item.correctWord ?? ''
      const userStr = item.userWord ?? ''

      const actionToCorrect = item.distance === -1 ? getLevenshteinDistance(userStr, correctStr) : item.distance

      result.push({
        correctStr,
        userStr,
        match: item.distance !== -1,
        specialChar: null,
        actionToCorrect
      })
    })

    return { result }
  }

  if (isEmpty(userAnswer)) {
    return {
      wordByWordResult: [],
      correctPercentage: 0,
      status: AnswerStatusEnums.SKIPPED,
      userAnswer: ''
    }
  } else {

    const { result } = processUserAnswer()
    const correctPercentage = Math.floor((result.filter(it => it.match).length - specialNamesCount) * 100 / (result.length - specialNamesCount))
    const correctPercentageThreshold = result.length <= 2 ? 50 : 66

    const returningResult = {
      wordByWordResult: result,
      correctPercentage,
      status: correctPercentage >= correctPercentageThreshold ? AnswerStatusEnums.CORRECT : AnswerStatusEnums.INCORRECT
    }

    return returningResult
  }
}