import serviceCall from "../../services";
import wordsMapper from "../../services/mapper/tools/wordsMapper";

const wordsUseCase = async ({unitId}) => {
  const response = await serviceCall(
    'fetchVocabularies',
    {unitId},
    'cloud',
  )
  return wordsMapper(response)
}

export default wordsUseCase