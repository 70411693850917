import Dexie from 'dexie'
import { languagesEnums } from '../enums/languageEnums/languageEnums'

export const translationDb = new Dexie('translations_db')

export const indexedDB_translation_tables = {
	english: languagesEnums.ENGLISH_LANGUAGE,
	persian: languagesEnums.PERSIAN_LANGUAGE,
	arabic:languagesEnums. ARABIC_LANGUAGE,
	spanish: languagesEnums.SPANISH_LANGUAGE,
	french: languagesEnums.FRENCH_LANGUAGE,
	russian: languagesEnums.RUSSIAN_LANGUAGE,
	turkish: languagesEnums.TURKISH_LANGUAGE,
	italian: languagesEnums.ITALIAN_LANGUAGE,
	german: languagesEnums.GERMAN_LANGUAGE,
}

translationDb.version(26).stores({
	[indexedDB_translation_tables.english]: 'key, value',
	[indexedDB_translation_tables.persian]: 'key, value',
	[indexedDB_translation_tables.arabic]: 'key, value',
	[indexedDB_translation_tables.spanish]: 'key, value',
	[indexedDB_translation_tables.french]: 'key, value',
	[indexedDB_translation_tables.russian]: 'key, value',
	[indexedDB_translation_tables.turkish]: 'key, value',
	[indexedDB_translation_tables.italian]: 'key, value',
	[indexedDB_translation_tables.german]: 'key, value',
})