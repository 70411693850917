import React, {useContext, useRef} from 'react'
import exerciseStyles from './../ExerciseModules.module.scss'
import classNames from 'classnames'
import colorsContext from '../../../contexts/ColorsContext'
import {useDispatch, useSelector} from 'react-redux'
import GivenTemplate from '../../../components/GivenTemplate/GivenTemplate'
import PaginableExerciseModule from '../PaginableExerciseModule'
import {
  checkExerciseItem,
  goToNextItem,
  onChangeInputTTA,
  toggleLockPaginable,
} from '../../../redux/features/exerciseSlice'
import Lock from '../../../components/Lock/Lock'
import ExerciseItemCheck from '../../../components/ExerciseItemCheck/ExerciseItemCheck'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import TypeTheAnswerGivenTextTemplateInline from './Components/TypeTheAnswerGivenTextTemplateInline'
import renderUserAnswer from './Components/renderUserAnswer'
import TypeTheAnswerGivenTextTemplateInput from './Components/TypeTheAnswerGivenTextTemplateInput'
import ExerciseFullTexts from '../Components/ExerciseFullTexts'
import TipCard from '../../../components/TipCard/TipCard'
import showFullTextAnswer from './Components/getShowFullTextAnswer'
import ExerciseAIAssistantButton from "../../../components/ExerciseAIAssistantButton/ExerciseAIAssistantButton";
import ExerciseMistakeInfoButton from "../../../components/ExerciseMistakeInfoButton/ExerciseMistakeInfoButton";
import getAnswerWithCorrectedMistake from "./getAnswerWithCorrectedMistake";
import cleanSentenceForCheck from "../../../operations/cleanSentenceForCheck";
import { AnswerStatusEnums, INLINE, INPUT, TEXTAREA, VOICE } from '../../../enums/globalEnums/globalEnums'

const TypeTheAnswerModule = ({data}) => {
  const {colors} = useContext(colorsContext)
  const dispatch = useDispatch()
  const {isSingleTemplate, checkingItem} = useSelector((state) => state.exercise)
  const textareaRef = useRef(null)

  const renderInput = (exerciseItem, stackIndex, itemIndex, enabled) => {
    const handleChange = (e) => {
      dispatch(onChangeInputTTA({
        value: e.currentTarget.textContent, stackIndex, itemIndex,
      }))
    }

    if (!exerciseItem.checked) {
      return (
        <div className='w-100'>
          <div
            contentEditable={enabled}
            className={exerciseStyles.middleInput}
            onInput={handleChange}
            onKeyPress={(e) => {
              return e.key === 'Enter' ? e.preventDefault() : true
            }}
            style={{
              display: 'inline',
              textAlign: 'unset',
              borderColor: colors['C222'],
              color: colors['C23'],
            }}
          />
        </div>
      )
    } else {
      return null
    }
  }

  const renderGivenText = (textString, stackIndex, itemIndex, exerciseItem, enabled) => {
    const renderSentence = () => {
      if (exerciseItem.inputType === INLINE) {
        return <TypeTheAnswerGivenTextTemplateInline
          stackIndex={stackIndex}
          itemIndex={itemIndex}
          exerciseItem={exerciseItem}
        />
      } else if (exerciseItem.inputType === INPUT) {
        return (
          <>
            <TypeTheAnswerGivenTextTemplateInput
              stackIndex={stackIndex}
              itemIndex={itemIndex}
              exerciseItem={exerciseItem}
            />
            {renderInput(exerciseItem, stackIndex, itemIndex, enabled)}
          </>
        )
      } else {
        return (
          <HtmlTextWrapper
            style={{display: 'inline-block'}}
            data={exerciseItem.given.text}
            textColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
          />
        )
      }
    }
    return (
      <div>
        {renderSentence()}
      </div>
    )
  }

  const renderAlsoCorrect = (exerciseItem) => {
    let userAnswerStatusList = []

    if (exerciseItem.inputType === INLINE) {
      userAnswerStatusList = exerciseItem.userAnswer.userInputText.map(field => {
        return field.userAnswerData.userAnswerStatus
      })
    } else {
      userAnswerStatusList = [exerciseItem.userAnswer.userInputText.userAnswerData.userAnswerStatus]
    }

    return (
      <TipCard title='Also Correct'>
        <ExerciseFullTexts
          fullTexts={exerciseItem.fullTextAnswers?.slice(1) || []}
          userAnswerStatus={userAnswerStatusList}
          template='INPUT'
          textColor={colors['C233']}
          inputColorCode={'MC3'}
          showOptionsList={false}
        />
      </TipCard>
    )
  }

  const renderTextArea = (exerciseItem, stackIndex, itemIndex, enabled) => {
    const userAnswerData = exerciseItem.userAnswer.userInputText.userAnswerData

    const handleChange = (e) => {
      // textareaRef.current.style.height = e.target.scrollHeight + 2 + 'px'
      dispatch(onChangeInputTTA({
        value: e.target.value, stackIndex, itemIndex,
      }))
    }

    if (exerciseItem.checked) {
      const showFullText = showFullTextAnswer(
        exerciseItem.checked,
        exerciseItem.userAnswer.hasAlsoCorrect,
        exerciseItem.userAnswer.userAnswerStatus,
        exerciseItem.unlocked,
      )
      if (showFullText) {
        return (
          <div
            className={classNames(exerciseStyles.textarea, 'mt-3')}
            style={{borderColor: colors['C534']}}
          >
            <ExerciseFullTexts
              unlocked={exerciseItem.unlocked}
              fullTexts={exerciseItem.fullTextAnswers}
              userAnswerStatus={[userAnswerData.userAnswerStatus]}
              textColor={userAnswerData.userAnswerStatus === AnswerStatusEnums.CORRECT ? colors['C30'] : colors['233']}
              optionsColor={colors['C233']}
            />
          </div>
        )
      } else {
        return (
          <div
            className={classNames(exerciseStyles.textarea, 'mt-3')}
            style={{borderColor: colors['C534'], color: colors['C233']}}
          >
            {renderUserAnswer(userAnswerData)}
          </div>
        )
      }
    } else {
      return (
        <textarea
          ref={textareaRef}
          placeholder='Type here'
          disabled={!enabled}
          className={exerciseStyles.textarea}
          onChange={handleChange}
          style={{
            color: colors['C23'],
            borderColor: colors['C534'],
          }}
          value={exerciseItem.userAnswer.userInputText.userAnswer}
        />
      )
    }
  }

  const onCheckItem = (stackId, exerciseItem, enabled) => {
    if (enabled && !checkingItem) {
      const userAnswerData = {
        exerciseStackId: stackId,
        userAnswerItems: [
          {
            exerciseItemId: exerciseItem.id,
            userAnswers: exerciseItem.inputType === INLINE
              ? exerciseItem.userAnswer.userInputText.map(u => {
                return {
                  listIndex: u.index,
                  text: u.userAnswer,
                  skipped: !Boolean(cleanSentenceForCheck(u.userAnswer)),
                }
              })
              : [{
                listIndex: 0,
                text: exerciseItem.userAnswer.userInputText.userAnswer,
                skipped: !Boolean(cleanSentenceForCheck(exerciseItem.userAnswer.userInputText.userAnswer)),
              }],
          },
        ],
      }
      dispatch(checkExerciseItem({userAnswerData})).then((res) => {
        if (res.payload?.status === 200 && !isSingleTemplate) {
          dispatch(goToNextItem())
        }
      })
    }
  }

  const renderExerciseItem = (exerciseItem, exerciseItemClass, stackIndex, itemIndex, enabled) => {
    const renderFooter = () => {
      if (exerciseItem.checked) {
        if (exerciseItem.userAnswer.isCorrectWithMistake) {
          return (
            <ExerciseMistakeInfoButton
              content={getAnswerWithCorrectedMistake(
                exerciseItem.given.text.paragraphList,
                exerciseItem.userAnswer.userInputText,
                exerciseItem.inputType === INPUT
              )}
              popoverPlacement='left'
            />
          )
        } else if (exerciseItem.showLock) {
          return (
            <div className={classNames(exerciseStyles.exerciseCardFooter)}>
              <div className='d-flex gap-3 align-items-center'>
                {/*<ExerciseAIAssistantButton/>*/}
                <Lock
                  onClickLock={() => dispatch(toggleLockPaginable({stackIndex, itemIndex}))}
                  isLocked={!exerciseItem.unlocked}
                />
              </div>
            </div>
          )
        }
      } else {
        return (
          <div className={classNames(exerciseStyles.exerciseCardFooter)}>
            <ExerciseItemCheck
              disabled={!enabled}
              checking={enabled && checkingItem}
              onClick={() => onCheckItem(data.stacks[stackIndex].id, exerciseItem, enabled)}
            />
          </div>
        )
      }
    }

    return (
      <div
        className={classNames(
          exerciseItemClass, exerciseStyles.typeInAnswerCard,
          {
            [exerciseStyles.cardDisabled]: (!enabled && !exerciseItem.checked),
            [exerciseStyles.checked]: exerciseItem.checked,
          },
        )}
        style={{
          background: exerciseItem.checked ? colors['C516'] : colors['C527'],
          borderColor: !data.stacks[stackIndex].joined && exerciseItem.checked
            ? exerciseItem.userAnswer.answerIsCorrect ? colors['C30'] : colors['C29']
            : null,
        }}
        key={itemIndex}
      >
        <div className='d-flex'>
          <div
            className={exerciseStyles.cardNumberBadge}
            style={{
              background: colors['C144'], color: colors['C233'],
              borderColor: exerciseItem.checked
                ? exerciseItem.userAnswer.answerIsCorrect ? colors['C30'] : colors['C29']
                : null,
            }}>
            {itemIndex + 1}
          </div>
          <GivenTemplate
            hideVoice={exerciseItem.checked}
            exerciseItem={exerciseItem}
            givenText={renderGivenText(exerciseItem.given?.text?.sentenceString, stackIndex, itemIndex, exerciseItem, enabled)}
          />
          {exerciseItem.given.type.includes(VOICE) &&
            <div className={exerciseStyles.cardNumberBadge}/>
          }
        </div>
        {exerciseItem.inputType === TEXTAREA &&
          <div className='d-flex justify-content-center'>
            {renderTextArea(exerciseItem, stackIndex, itemIndex, enabled)}
          </div>
        }
        {(exerciseItem.checked && exerciseItem.userAnswer.userAnswerStatus === AnswerStatusEnums.CORRECT &&
            exerciseItem.userAnswer.hasAlsoCorrect) &&
          <div className='mt-2'>
            {renderAlsoCorrect(exerciseItem)}
          </div>
        }
        {renderFooter()}
      </div>
    )
  }

  return (
    <div className={exerciseStyles.typeInAnswerContainer}>
      <PaginableExerciseModule
        renderExerciseItem={renderExerciseItem}
      />
    </div>
  )
}

export default TypeTheAnswerModule