import ReactDOM from "react-dom";
import bottomSheetStyles from './BottomSheet.module.scss';
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import colorsContext from "../../contexts/ColorsContext";
import classNames from "classnames";
import useBottomSheet from "../../hooks/useBottomSheet";
import {useDispatch, useSelector} from "react-redux";
import {v4} from "uuid";
import {addDisplayingBottomSheet} from "../../redux/features/bottomSheetSlice";

const BottomSheet = ({hasSpace = false, overlayOpacity = 0.8, children, close, background, topLineBackground}) => {
  const {colors} = useContext(colorsContext)
  const dispatch = useDispatch()

  const bottomSheetCardRef = useRef(null)

  const [isDragging, setIsDragging] = useState(false)
  const [startY, setStartY] = useState(0)
  const [startHeight, setStartHeight] = useState(0)

  const id = useMemo(() => v4(), [])
  const overlayId = `bottomSheetOverlay_${id}`
  const headerId = `bottomSheetOverlay_${id}`

  useEffect(() => {
    setTimeout(() => {
      dispatch(addDisplayingBottomSheet(id))
    }, 1)
  }, [])

  const {displayingBottomSheets} = useSelector(state => state.bottomSheet)
  const {closeBottomSheet} = useBottomSheet()

  const showBottomSheet = useMemo(() => {
    return displayingBottomSheets.includes(id)
  }, [JSON.stringify(displayingBottomSheets)])

  const updateSheetHeight = (height) => {
    bottomSheetCardRef.current.style.transform = null;
    if (height) {
      bottomSheetCardRef.current.style.height = height;
    } else {
      bottomSheetCardRef.current.style.height = startHeight + 'px';
      setTimeout(() => {
        bottomSheetCardRef.current.style.height = null;
      }, 300);
    }
  }

  const updateSheetTranslateY = (y) => {
    if (y) {
      bottomSheetCardRef.current.style.transform = `translateY(${y})`;
    } else {
      bottomSheetCardRef.current.style.transform = null;
    }
  }

  const hideBottomSheet = () => {
    closeBottomSheet(close, id);
  }

  const dragStart = (e) => {
    if (close && e.target.id === headerId) {
      setIsDragging(true);
      setStartHeight(bottomSheetCardRef.current.clientHeight);
      setStartY(e.pageY || e.touches?.[0].pageY);
    }
  }

  const dragging = (e) => {
    if (!isDragging) return;
    const delta = startY - (e.pageY || e.touches?.[0].pageY);
    if (delta < 0) {
      updateSheetTranslateY(-delta + 'px');
    } else {
      updateSheetHeight((startHeight + Math.pow(delta, 0.5)) + 'px');
    }
  }

  const dragStop = (e) => {
    if (isDragging) {
      setIsDragging(false)
      if ((e.pageY || e.changedTouches?.[0]?.pageY) - startY > 25) {
        updateSheetTranslateY();
        hideBottomSheet();
      } else {
        updateSheetTranslateY();
        updateSheetHeight();
      }
    }
  }

  const onClickSheetOverlay = (e) => {
    if (close && e.target.id === overlayId) {
      hideBottomSheet()
    }
  }

  return ReactDOM.createPortal(
    <div
      className={classNames(
        bottomSheetStyles.container,
        {[bottomSheetStyles.show]: showBottomSheet, [bottomSheetStyles.dragging]: isDragging}
      )}
      onMouseDown={e => dragStart(e)}
      onMouseMove={e => dragging(e)}
      onMouseUp={e => dragStop(e)}
      onTouchStart={e => dragStart(e)}
      onTouchMove={e => dragging(e)}
      onTouchEnd={e => dragStop(e)}
    >
      <div
        className={bottomSheetStyles.sheetOverlay}
        id={overlayId}
        style={{opacity: overlayOpacity}}
        onClick={(e) => onClickSheetOverlay(e)}
      />
      <div
        ref={bottomSheetCardRef}
        className={classNames(bottomSheetStyles.card, {[bottomSheetStyles.hasSpace]: hasSpace})}
        style={{background: background ?? colors['C73']}}
      >
        <div className={bottomSheetStyles.header} id={headerId}>
          <div className={bottomSheetStyles.topLine} style={{background: topLineBackground ?? colors['C589'] + '80'}}/>
        </div>
        <div className={bottomSheetStyles.body}>
          {children({close: hideBottomSheet})}
        </div>
      </div>
    </div>,
    document.getElementById('modal'),
  )
}

export default BottomSheet;