import serviceCall from "../../services";

const addToSuperMemoryBoxUseCase = async ({id}) => {
	const result = await serviceCall(
		'addToSuperMemoryBox',
		{id},
		'cloud',
	)

	return result
}

export default addToSuperMemoryBoxUseCase