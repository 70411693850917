import React from "react";
import LockOpenSvg from "../../icons/LockOpenSvg";
import LockOpenWithKeySvg from "../../icons/LockOpenWithKeySvg";
import classNames from "classnames";

const Lock = ({onClickLock, isLocked, className}) => {
  require('./lock.scss');
  return (
    <button
      className={classNames((isLocked ? "locked-svg" : "unlocked-svg"), className)}
      onClick={onClickLock}
      data-jsx="Lock"
    >
      {isLocked ?
        <LockOpenWithKeySvg/> :
        <LockOpenSvg/>
      }
    </button>
  );
}

export default Lock;