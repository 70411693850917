import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { hideLoader } from './helper.js'
import { getAllColors } from './colors/colors.js'
import { ColorsProvider } from './contexts/ColorsContext.js'
import { isEmpty, toNumber } from 'lodash'
import AppRoutes from './routes/AppRoutes.js'
import Sidebar from "./components/Sidebar.js";
import 'overlayscrollbars/overlayscrollbars.css';
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import setCookie from './operations/setCookie.js'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css'
import './styles/style.scss'
import GlobalModals from "./components/GlobalModals/GlobalModals.jsx";
import { LogProvider } from "./contexts/LogContext.js";
import SystemLog from "./log/Log.js";
import { KUALA, MYSTERY, TANGERINE, TWILIGHT } from "./enums/base/themeEnums.js";
import { useDispatch } from "react-redux";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.jsx";
import { setSelectedTranslationLanguage } from "./redux/features/translationSlice.js";
import { translationsLanguagesData } from "./enums/languageEnums/languageEnums.js";
import useAppUser from "./hooks/useAppUser.js";
import useLocalStorage from "./hooks/useLocalStorage.js";
import { v4 } from "uuid";
import * as Sentry from "@sentry/react";
import { Helmet } from "react-helmet";
import { initGA, logPageView } from './components/analytics.js';
import FirstTimeHelpActions from "./atomicComponents/templates/FirstTimeHelpActions/FirstTimeHelpActions.jsx";
import { getFirebaseMessagingToken } from './firebase.js'
import { updateNotificationReceiver } from "./redux/features/appUserSlice.js";
import './firebase.js'
import { TourProvider } from "@reactour/tour";
import VersionUpdateModal from "./components/VersionUpdateModal/VersionUpdateModal.jsx";
import PhraseOptionsModal from './atomicComponents/templates/modals/PhraseOptionsModal.jsx'
import NetworkStatus from "./atomicComponents/molecules/NetworkStatus/NetworkStatus.jsx";
import { retryFailedRequests } from './apis/retryFailedRequest.js'
import { isMobileSafari } from 'react-device-detect'
import { setCurrentOpenModal } from './redux/features/modalSlice.js'
import { globalModalEnums } from './enums/globalEnums/globalEnums.js'
import {errorTranslationsLanguagesData} from "./enums/errorLanguageEnums/errorLanguageEnums";
import CelebrateUnitCompletion from './atomicComponents/templates/CelebrateUnitCompletion/CelebrateUnitCompletion'

const App = () => {
  const [colors, setColors] = useState({})
  const [theme, setTheme] = useState('')
  const [log] = useState(new SystemLog())
  // const pageVisibilityHidden = usePageVisibility()
  const { appUserData } = useAppUser()
  const [deviceId] = useLocalStorage('deviceId', v4())
  const [addedToHomeScreen, setAddedToHomeScreen] = useLocalStorage('addedToHomeScreen', false)
  const [displayAddToHomeScreenGuideTime, setDisplayAddToHomeScreenGuideTime] = useLocalStorage('displayAddToHomeScreenGuideTime')
  const [fcmToken, setFcmToken] = useLocalStorage('fcmToken', null)
  const [isFirebaseTokenFound, setFirebaseTokenFound] = useState(false)
  const dispatch = useDispatch()

  const checkAddToHomeScreen = () => {
    if (isMobileSafari && !addedToHomeScreen) {
      if (window.navigator.standalone == true) {
        setAddedToHomeScreen(true)
        setDisplayAddToHomeScreenGuideTime(null)
      } else {
        const currentTime = new Date().getTime()
        const limitDuration = 1000 * 60 * 60 * 24 * 30 // Milli-seconds to 30 days

        if (displayAddToHomeScreenGuideTime && currentTime - toNumber(displayAddToHomeScreenGuideTime) < limitDuration) {
          return;
        } else {
          dispatch(setCurrentOpenModal(globalModalEnums.addToHomeScreenGuide))
          setDisplayAddToHomeScreenGuideTime(new Date().getTime())
        }
      }
    }
  }

  useEffect(() => {
    hideLoader()

    // Initialize Google Analytics
    initGA()

    // // Log the initial page view
    // logPageView()

    // // Add event listener to track page changes
    // const handlePageChange = () => {
    //   logPageView()
    // }

    retryFailedRequests();

    const modal = document.getElementById("modal")

    const handleModalContentChange = () => {
      const pageLayerEl = document.getElementById("page-layer")
      document.body.style.overflow = modal?.childNodes?.length > 0 || pageLayerEl?.childNodes?.length > 0 ? 'hidden' : null
    }

    checkAddToHomeScreen()

    // Listen for changes in the URL (react-router example)
    // window.addEventListener('hashchange', handlePageChange);

    modal.addEventListener("modalContentChanged", handleModalContentChange)

    return () => {
      // Clean up event listener on component unmount
      // window.removeEventListener('hashchange', handlePageChange);
      modal.removeEventListener("modalContentChanged", handleModalContentChange)
    }
  }, [])


  useEffect(() => {
    if (appUserData?.setting?.nativeLanguage) {
      const nativeLanguageKey = appUserData.setting.nativeLanguage

      if (errorTranslationsLanguagesData[nativeLanguageKey]) {
        localStorage.setItem('selectedErrorTranslationLanguageKey', nativeLanguageKey)
      }
    } else {
      // If no native language is set, default to English
      localStorage.setItem('selectedErrorTranslationLanguageKey', 'ENGLISH')
    }
  }, [appUserData]);



  useEffect(() => {
    document.querySelector('meta[name="theme-color"]').setAttribute('content', colors['C54'])
  }, [colors])

  useEffect(() => {
    let userTheme = TANGERINE
    if (appUserData?.setting?.theme) {
      userTheme = appUserData?.setting?.theme
      setCookie('theme', appUserData?.setting?.theme, 24 * 7)
    } else {
      setCookie('theme', TANGERINE, 24 * 7)
    }
    setTheme(userTheme)
    const colorsData = getAllColors(userTheme)
    setColors(colorsData)

    document.body.style.background = colorsData['C183']

    if (userTheme === KUALA || userTheme === TWILIGHT || userTheme === MYSTERY) {
      document.documentElement.classList.add('tw-dark')
    } else {
      document.documentElement.classList.remove('tw-dark')
    }
  }, [appUserData?.setting?.theme])

  useEffect(() => {
    const languageData = translationsLanguagesData[appUserData?.setting?.nativeLanguage]
    if (languageData) {
      dispatch(setSelectedTranslationLanguage(languageData))
    }
  }, [appUserData?.setting?.nativeLanguage])

  // Set sentry user data
  useEffect(() => {
    Sentry.setUser({
      id: appUserData?.info?.id,
      // username: appUserData?.info?.username // Sending username can put users privacy at risk
    })
  }, [appUserData?.info?.id])

  useEffect(() => {
    if (deviceId && appUserData) {
      getFirebaseMessagingToken(setFirebaseTokenFound).then(givenToken => {
        if (givenToken && givenToken !== fcmToken) {
          setFcmToken(givenToken)
          dispatch(updateNotificationReceiver({ registrationToken: givenToken, deviceId }))
        }
      })
    }
  }, [deviceId, appUserData])

  if (!isEmpty(colors)) {
    return (
      <>
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-4G3CQ5GV5W"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-4G3CQ5GV5W');
            `}
          </script>
        </Helmet>
        <ColorsProvider value={{ colors, setColors, theme, setTheme }}>
          <LogProvider value={{ log }}>
            <TourProvider
              steps={[]}
              showBadge={false}
              showNavigation={false}
              showPrevNextButtons={false}
              showDots={false}
              styles={{
                popover: base => ({
                  ...base,
                  borderRadius: 5,
                  border: '1px solid #E98020',
                  fontWeight: 200,
                  fontFamily: '"Roboto Flex", "Segoe UI", sans-serif',
                  padding: '16px 20px'
                }),
                close: (base) => ({ ...base, left: 'auto', right: 8, top: 8, color: '#C1C7C9', }),
              }}
            >
              <BrowserRouter>
                <ScrollToTop />
                <NetworkStatus />
                {/*{Boolean(authToken) && !Boolean(userData) && <Navigate to="/"/>}*/}
                <AppRoutes />
                <ToastContainer />
                <GlobalModals />
                <FirstTimeHelpActions />
                <VersionUpdateModal />
                <Sidebar />
                <PhraseOptionsModal />
                <CelebrateUnitCompletion />
              </BrowserRouter>
            </TourProvider>
          </LogProvider>
        </ColorsProvider>
      </>
    )
  } else {
    return null
  }
}

export default App
