import serviceCall from "../../services";
import StaticContentMapper from "../../services/mapper/staticContent/staticContentMapper";

const staticContentUseCase = async ({language, type}) => {
  const response = await serviceCall(
    'fetchStaticContentData',
    {language, type},
    'cloud',
  )

  return StaticContentMapper(response)
}

export default staticContentUseCase