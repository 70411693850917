import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  quickReviewSections: [],
  redirectBackTo: '/'
}

const quickReviewSlice = createSlice({
  name: 'quickReview',
  initialState,
  reducers: {
    setQuickReviewSections: (state, action) => {
      state.quickReviewSections = action.payload
    },
    setQuickReviewRedirectBackTo: (state, action) => {
      state.redirectBackTo = action.payload
    }
  },
})

export const { setQuickReviewSections, setQuickReviewRedirectBackTo } = quickReviewSlice.actions

export default quickReviewSlice.reducer