import * as React from "react"

const HeartSvg = ({stroke, fill}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={54.953}
    height={48.748}
    viewBox="0 0 54.953 48.748"
  >
    <path
      d="M26.476,76.748a1.653,1.653,0,0,1-1.089-.409c-2.285-2-4.483-3.871-6.423-5.524C7.544,61.082,0,54.653,0,45.256,0,36.559,6.047,30,14.066,30a12.537,12.537,0,0,1,7.83,2.706,17.835,17.835,0,0,1,4.581,5.509,17.836,17.836,0,0,1,4.581-5.509A12.537,12.537,0,0,1,38.887,30c8.019,0,14.066,6.559,14.066,15.256,0,9.4-7.544,15.825-18.963,25.557-1.941,1.654-4.139,3.527-6.424,5.525A1.653,1.653,0,0,1,26.476,76.748Z"
      transform="translate(1 -29)"
      fill={fill}
      stroke={stroke}
      strokeWidth={2}
    />
  </svg>
)

export default HeartSvg
