import {useEffect} from "react";
import {hideLoader, showLoader} from "../../helper";
import FramerMotion from "../../layouts/FramerMotion";

const LoadingPage = () => {
  useEffect(() => {
    showLoader()
    return () => {
      hideLoader()
    }
  }, [])

  return (
    <FramerMotion className='minh-100vh w-100'/>
  )
}

export default LoadingPage