import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import htmlToText from '../../operations/htmlToText'
import removeExtraSpace from '../../operations/removeExtraSpace'
import removeSpecialCharacters from "../../operations/removeSpecialCharacters";
import { matchIgnoringCase } from "../../operations/matchIgnoringCase";
import { playCheckSound } from "../../util/playCheckSound/playCheckSound";
import fetchDrillUseCase from "../../useCase/drillUseCase/fetchDrillUseCase";
import matchingExactUseCase from '../../useCase/matchingExactUseCase.js/matchingExactUseCase';
import { AnswerStatusEnums } from '../../enums/globalEnums/globalEnums'
import matchingExactHintUseCase from '../../useCase/matchingExactUseCase.js/matchingExactHintUseCase';
import { baseAsyncThunk } from '../baseAsyncThunk';

const initialState = {
  loading: false,
  fetchedBy: {
    unitId: null,
    nativeLanguage: null,
  },
  data: null,
  userData: [],
  currentIndex: 0,
  recordModalIsOpen: false,
  spellPending: false,
  error: '',
}

export const fetchDrill = baseAsyncThunk(
  'drill/fetchDrill',
  ({ unit }, {getState}) => {
    const {appUser} = getState()
    return fetchDrillUseCase({ unit, nativeLanguage: appUser.appUserData.setting.nativeLanguage })
  }
)

export const checkDrillAnswer = createAsyncThunk(
  'drill/checkDrillAnswer',
  ({ unit, matchCases, trackerNumber, isVoiceRecognition }, { getState }) => {
    const state = getState()

    return matchingExactUseCase({
      matchType: 'EXACT_NON_CASE_SENSITIVE',
      inputType: 'SENTENCE_WORD_BY_WORD_MATCH',
      sectionType: 'DRILLING',
      toolsCheck: false,
      unitId: unit,
      trackerNumber,
      matchCases,
      isVoiceRecognition,
      quizIndex: state.drill.currentIndex
    })
  }
)

export const getDrillHint = createAsyncThunk(
  'drill/getDrillHint',
  ({ unit, matchCases, trackerNumber, isVoiceRecognition }, { getState }) => {
    const state = getState()

    return matchingExactHintUseCase({
      matchType: 'EXACT_NON_CASE_SENSITIVE',
      inputType: 'SENTENCE_WORD_BY_WORD_MATCH',
      sectionType: 'DRILLING',
      toolsCheck: false,
      unitId: unit,
      trackerNumber,
      matchCases,
      isVoiceRecognition,
      quizIndex: state.drill.currentIndex
    })
  },
)

const drillSlice = createSlice({
  name: 'drill',
  initialState,
  reducers: {
    increaseCurrentIndex: (state) => {
      if (state.currentIndex + 1 < state.data.length)
        state.currentIndex++
    },
    decreaseCurrentIndex: (state) => {
      if (state.currentIndex > 0)
        state.currentIndex--
    },
    setCurrentIndex: (state, action) => {
      state.currentIndex = action.payload
    },
    setUserData: (state, action) => {
      state.userData = action.payload
    },
    setDrillSpeechType: (state, action) => {
      state.data[action.payload.currentIndex].speechType = action.payload.speechType
    },
    tryAgain: (state) => {
      state.userData = [...state.userData].map((obj, i) => {
        if (i === state.currentIndex) {
          return {
            ...obj,
            userAnswer: '',
            bySpeech: false,
            unlocked: false,
            clueIndexUsed: -1,
            showClue: false,
            checked: false,
            answerIsCorrect: false,
            checkResult: null,
          }
        } else {
          return obj
        }
      })
    },
    hint: (state, action) => {
      state.userData = [...state.userData].map((obj, i) => {
        if (i === state.currentIndex) {
          const currentUserAnswer = obj.userAnswer
          const correctUserAnswer = obj.sentenceCover

          const splitUserAnswer = currentUserAnswer.split(' ')
          const splitCorrectAnswer = correctUserAnswer.split(' ')

          for (let i = 0; i < splitCorrectAnswer.length; i++) {
            const thisAnswer = splitUserAnswer?.[i]
            if (
              thisAnswer &&
              matchIgnoringCase(
                removeExtraSpace(removeSpecialCharacters(thisAnswer)),
                removeSpecialCharacters(splitCorrectAnswer[i])
              )
            ) {
            } else {
              break;
            }
          }

          const userAnswer = currentUserAnswer

          return {
            ...obj,
            userAnswer
          }
        }
        return obj
      })
    },
    setShowClue: (state, action) => {
      state.userData = [...state.userData].map((obj, i) => {
        if (i === state.currentIndex) {
          return {
            ...obj,
            showClue: action.payload,
          }
        } else {
          return obj
        }
      })
    },
    toggleUnlockAnswer: (state) => {
      state.userData = [...state.userData].map((obj, i) => {
        if (i === state.currentIndex) {
          return {
            ...obj,
            unlocked: !obj.unlocked,
          }
        } else {
          return obj
        }
      })
    },
    setRecordModalIsOpen: (state, action) => {
      state.recordModalIsOpen = action.payload
    },
    setUserAnswer: (state, action) => {
      state.userData = [...state.userData].map((obj, i) => {
        if (i === state.currentIndex) {
          return {
            ...obj,
            userAnswer: action.payload.text,
            bySpeech: action.payload.bySpeech
          }
        } else {
          return obj
        }
      })
      state.recordModalIsOpen = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDrill.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchDrill.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data
      state.fetchedBy = {
        unit: action.payload.unit,
        nativeLanguage: action.payload.nativeLanguage
      }

      state.userData = Array.from(Array(state.data.length), (_, i) => {
        const sentenceCover = removeExtraSpace(htmlToText(state.data[i].sentence))
        return {
          userAnswer: '',
          bySpeech: false,
          sentenceCover,
          unlocked: false,
          clueIndexUsed: -1,
          showClue: false,
          checked: false,
          answerIsCorrect: false,
          checkResult: null,
        }
      })
    })
    builder.addCase(fetchDrill.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(checkDrillAnswer.pending, (state) => {
      state.loading = true
    })
    builder.addCase(checkDrillAnswer.fulfilled, (state, action) => {
      state.loading = false

      const matchCasesMap = action.payload.data.matchCasesMap
      const matchCase = matchCasesMap[Object.keys(matchCasesMap)[0]]

      state.userData = [...state.userData].map((obj, i) => {
        if (i === action.payload.quizIndex) {
          const answerIsCorrect = matchCase.userAnswerStatus === AnswerStatusEnums.CORRECT
          const answerIsSkipped = matchCase.userAnswerStatus === AnswerStatusEnums.SKIPPED

          playCheckSound(answerIsCorrect, answerIsSkipped)

          return {
            ...obj,
            checked: true,
            answerIsCorrect,
            isCorrectWithMistake: answerIsCorrect && matchCase.wordByWordResult?.some(it => it.actionToCorrect > 0 && it.usrStr !== ''),
            checkResult: matchCase.wordByWordResult,
          }
        } else {
          return obj
        }
      })
    })
    builder.addCase(checkDrillAnswer.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(getDrillHint.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDrillHint.fulfilled, (state, action) => {
      state.loading = false

      state.userData = [...state.userData].map((obj, i) => {
        if (i === action.payload.quizIndex) {
          return {
            ...obj,
            userAnswer: action.payload.data,
          }
        } else {
          return obj
        }
      })
    })
    builder.addCase(getDrillHint.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  },
})

export const {
  increaseCurrentIndex, decreaseCurrentIndex, setCurrentIndex, setUserData, setDrillSpeechType, tryAgain, hint,
  toggleUnlockAnswer, setShowClue, setRecordModalIsOpen, setUserAnswer,
} = drillSlice.actions

export default drillSlice.reducer