import serviceCall from "../../services";
import unitsMapper from "../../services/mapper/levelAndUnit/unitsMapper";

const updateUnitStatusUseCase = async ({level, unitId}) => {
  const response = await serviceCall(
    'updateUnitStatus',
    {unitId},
    "cloud"
  )

  return unitsMapper(response, level)
}

export default updateUnitStatusUseCase