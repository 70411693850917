import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import serviceCall from "../../../services";
import phrasesUseCase from "../../../useCase/tools/phrasesUseCase";
import wordsUseCase from "../../../useCase/tools/wordsUseCase";

const initialState = {
  loading: false,
  toolsData: null,
  toolsGivenStates: null,
  error: ''
}

export const getToolsSentences = createAsyncThunk(
  'tools/getToolsSentences',
  async ({ unitId }) => {
    return await phrasesUseCase({ unitId })
  },
)

export const getToolsWords = createAsyncThunk(
  'tools/getToolsWords',
  ({ unitId }) => {
    return wordsUseCase({ unitId })
  },
)

const toolsSlice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    setToolsData: (state, action) => {
      state.toolsData = action.payload
    },
    setToolsGivenStates: (state, action) => {
      state.toolsGivenStates = { unitId: action.payload.unitId, data: action.payload.data }
    },
    clearToolsData: (state) => {
      state.toolsData = null
    }
  },
  extraReducers: builder => {
    builder.addCase(getToolsSentences.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getToolsSentences.fulfilled, (state, action) => {
      state.toolsData = action.payload
      state.loading = false
    })
    builder.addCase(getToolsSentences.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getToolsWords.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getToolsWords.fulfilled, (state, action) => {
      state.toolsData = action.payload
      state.loading = false
    })
    builder.addCase(getToolsWords.rejected, (state) => {
      state.loading = false
    })
  }
})

export const { setToolsData, setToolsGivenStates, clearToolsData } = toolsSlice.actions

export default toolsSlice.reducer