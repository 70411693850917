import serviceCall from "../../services";
import settingStudyReminderMapper from "../../services/mapper/setting/settingStudyReminderMapper";
import setSettingStudyReminderMapper from "../../services/mapper/setting/setSettingStudyReminderMapper";

const setSettingStudyReminderUseCase = async ({param}) => {
    const data = await serviceCall(
        'postStudyReminderSettings',
        ({param: setSettingStudyReminderMapper({param})}),
        'cloud',
    )
    return settingStudyReminderMapper(data)
}

export default setSettingStudyReminderUseCase