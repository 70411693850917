import { isNumber } from "lodash";
import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'
import { ContentTypesStructureEnums } from "../../../enums/structureEnums/templateType";

const reviewMistakeDragAndDropMapper = (data, filterByUserAnswerData = []) => {
  return {
    ...data,
    exerciseStacks: data.exerciseStacks?.map(stack => {
      return {
        ...stack,
        items: stack.items.map(item => {
          const given = item.data.givens[0]
          const choiceList = item.data.choices[0].items
          const itemUserAnswer = filterByUserAnswerData.filter(it => it.itemId === item.id)
          const completelySolved = !itemUserAnswer?.some(it => it.userAnswerStatus === AnswerStatusEnums.INCORRECT || it.userAnswerStatus === AnswerStatusEnums.SKIPPED)
          const type = (given.text && given.text.sentence.includes('###')) ? ContentTypesStructureEnums.TEXT : ContentTypesStructureEnums.IMAGE

          return {
            ...item,
            type,
            isSolvedBefore: completelySolved,
            data: {
              ...item.data,
              choices: [{
                index: 0,
                items: [...choiceList].map(it => {
                  return {
                    ...it,
                    isSolvedBefore: !itemUserAnswer.some(u => {
                      return (!isNumber(u.index) || u.index === it.index) && (u.userAnswerStatus === AnswerStatusEnums.INCORRECT || u.userAnswerStatus === AnswerStatusEnums.SKIPPED)
                    }),
                  }
                })
              }]
            }
          }
        })
      }
    })
  }
}

export default reviewMistakeDragAndDropMapper