import React, {useContext} from "react"
import HelpButton from "../components/HelpButton/HelpButton"
import Image from "../components/Image";
import colorsContext from "../contexts/ColorsContext";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import SpeakingSvg from "../icons/SpeakingSvg";
import SoundSettingSvg from "../icons/SoundSettingSvg";
import classNames from "classnames";

const ConversationCharacterSelect = (
  {
    characters,
    onClickDone,
    selectText,
    title,
    RolePlay,
    selectedCharacters,
    onSelectCharacter,
    helpCharacterSelectLink
  }
) => {
  const {colors} = useContext(colorsContext)
  return (
    <div className="motion-opacity-1" data-jsx="Conversation">
      <div className="container-fluid">
        <div className="d-flex justify-content-end mt-3">
          <div className="me-3 me-md-5 ms-2">
            <HelpButton color={colors["C2"]} pageType={helpCharacterSelectLink}/>
          </div>
        </div>
        {characters.length > 0 &&
          <>
            <div className="select-character-page-container">
              <p className="select-title-text" style={{color: colors["C2"]}}>
                {title}
              </p>
              <p className="select-text" style={{color: colors["C2"]}}>
                {selectText}
              </p>
              <div className="characters-wrapper">
                {characters.map((c, i) => {
                  return (
                    <div
                      className="character-container"
                      onClick={() => onSelectCharacter(c.name)}
                      key={i}
                    >
                      <div className={selectedCharacters.includes(c.name) ?
                        'selected' : 'unselected'}>
                        <div
                          className="avatar"
                          style={{
                            borderColor: selectedCharacters.includes(c.name)
                              ? colors['C66'] : "transparent",
                            background: "#ccc"
                          }}
                        >
                          <Image uri={c.pictureUri}/>
                        </div>
                        <div className="character-name" style={{color: selectedCharacters.includes(c.name) ? colors['C66']: colors['C187']}}>
                          {c.name}
                        </div>
                        {RolePlay &&
                          <div
                          >
                            {selectedCharacters.includes(c.name) ?
                              <div
                                className={classNames("character-role-play-svg", {'selected-role-play-svg': selectedCharacters.includes(c.name)})}>
                                <SpeakingSvg
                                  color={selectedCharacters.includes(c.name) ? colors['C66'] : colors['C187']}/>
                              </div>
                              :
                              <div
                                className={classNames("character-role-play-svg", {'selected-role-play-svg': selectedCharacters.includes(c.name)})}>
                                <SoundSettingSvg
                                  color={selectedCharacters.includes(c.name) ? colors['C66'] : colors['C2']}/>
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <PrimaryButton
              className="d-block mx-auto"
              style={{
                opacity: selectedCharacters.length > 0 ? 1 : 0
              }}
              disabled={selectedCharacters.length === 0}
              onClick={onClickDone}
            >
              Done
            </PrimaryButton>
          </>
        }
      </div>
    </div>
  )
}

export default ConversationCharacterSelect