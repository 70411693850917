import * as React from "react"

const InNutshellSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={512}
        height={512}
        viewBox="0 0 448 512"
    >
        <path
            d="M309.2 141.7 154.9 295.9c-10.4 10.4-16.1 24.3-16.1 38.9h0c0 30.4 24.7 55 55 55h1.5c14.6 0 28.7-5.8 38.9-16.1l170.6-170.6c10.4-10.4 16.1-24.3 16.1-38.9v-48.8c0-3.7-.4-23.2-16.1-38.9-10-10-23.7-16.1-38.9-16.1h-84.1c-4.6 0-12.4.6-21 4.2-6.6 2.8-12.7 6.8-17.9 12L38 281.4c-13.7 13.7-19.2 33.6-14.4 52.4 3.4 13.3 6.7 26.7 10.1 40 3.7 14.5 7.3 29.1 11 43.6 17 25.6 34 39.9 47.9 41.3 21.7 2.2 43.5 4.3 65.2 6.5 20.6 2.1 41.1 4.2 61.8 6.1 4.7.5 13.7.7 24-3 7.6-2.7 14.6-7.1 20.5-12.9l162.1-162.1"
            style={{
                fill: "none",
                stroke: color,
                strokeWidth: 41.5031,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 10,
            }}
        />
    </svg>
)

export default InNutshellSvg
