import useAppUser from "../../hooks/useAppUser";
import userActionEnums from "../../enums/userActionEnums/userActionEnums";
import {isMobile} from "react-device-detect";
import {useContext, useEffect} from "react";
import BottomSheet from "../BottomSheet/BottomSheet";
import SettingStudyHardShipLevelComponent
  from "../../pages/Settings/SettingStudy/StudyDifficultyLevel/SettingStudyHardShipLevelComponent";
import Modal from "../Modal";
import colorsContext from "../../contexts/ColorsContext";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {changeStudyHardship} from "../../redux/features/studyDifficultySlice";
import {useDispatch, useSelector} from "react-redux";
import {setAppUser} from "../../redux/features/appUserSlice";
import useFirstTimeActions from "../../hooks/useFirstTimeActions";
import {finishFirstTimeAction, setDisplayingAction} from "../../redux/features/firstTimeActionSlice";

const DesktopSettingStudyDifficultyComponent = () => {
  const {colors} = useContext(colorsContext)
  const {appUserData} = useAppUser()
  const dispatch = useDispatch()
  const {displayingAction} = useSelector(state => state.firstTimeAction)
  const {currentFirstTimeAction} = useFirstTimeActions()

  useEffect(() => {
    if (currentFirstTimeAction === userActionEnums.updateStudyHardShipLevel) {
      dispatch(setDisplayingAction(userActionEnums.updateStudyHardShipLevel))
    }
  }, [currentFirstTimeAction])

  const closeModal = () => {
    dispatch(changeStudyHardship({studyHardshipLevel: appUserData?.setting?.studyHardshipLevel})).then(res => {
      if (res?.payload) {
        dispatch(setAppUser(res.payload))
      }
    })
    dispatch(setDisplayingAction(null))
    dispatch(finishFirstTimeAction(userActionEnums.updateStudyHardShipLevel))
  }

  return <>
    {displayingAction === userActionEnums.updateStudyHardShipLevel &&
      <Modal isCenter closeModal={closeModal}>
        <div className="modal-type-2 w-fit">
          <div
            className="modal-card p-3"
            style={{
              maxHeight: 'none',
              background: colors['C12']
            }}
          >
            <SettingStudyHardShipLevelComponent/>
            <div className='mt-4 d-flex justify-content-center'>
              <PrimaryButton onClick={closeModal}>
                Save
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Modal>
    }
  </>
}

const MobileSettingStudyDifficultyComponent = () => {
  const {colors} = useContext(colorsContext)
  const {appUserData} = useAppUser()
  const dispatch = useDispatch()
  const {displayingAction} = useSelector(state => state.firstTimeAction)
  const {currentFirstTimeAction} = useFirstTimeActions()

  useEffect(() => {
    if (currentFirstTimeAction === userActionEnums.updateStudyHardShipLevel) {
      dispatch(setDisplayingAction(userActionEnums.updateStudyHardShipLevel))
    }
  }, [currentFirstTimeAction])

  const closeModal = () => {
    dispatch(changeStudyHardship({studyHardshipLevel: appUserData?.setting?.studyHardshipLevel})).then(res => {
      if (res?.payload) {
        dispatch(setAppUser(res.payload))
      }
    })
    dispatch(setDisplayingAction(null))
    dispatch(finishFirstTimeAction(userActionEnums.updateStudyHardShipLevel))

  }

  return <>
    {displayingAction === userActionEnums.updateStudyHardShipLevel &&
      <BottomSheet
        background={colors['C239']}
        overlayOpacity={0.8}
        close={closeModal}
      >
        {(props) => (
          <>
            <SettingStudyHardShipLevelComponent/>
            <div className='mt-4 d-flex justify-content-center'>
              <PrimaryButton onClick={props.close}>
                Save
              </PrimaryButton>
            </div>
          </>
        )}
      </BottomSheet>
    }
  </>
}

const FirstTimeSettingStudyDifficulty = () => {
  const renderModal = () => {
    if (isMobile) {
      return <MobileSettingStudyDifficultyComponent/>
    } else {
      return <DesktopSettingStudyDifficultyComponent/>
    }
  }

  return renderModal()
}

export default FirstTimeSettingStudyDifficulty