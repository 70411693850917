import * as React from "react"

const InfoSvg = ({color}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} viewBox="0 0 48 48">
    <path
      d="M23.73 0a23.73 23.73 0 1 0 23.73 23.73A23.725 23.725 0 0 0 23.73 0Zm1.91 36h-4.5V21.75h4.5Zm0-19.41h-4.5v-4.5h4.5Z"
      fill={color}
    />
  </svg>
)

export default InfoSvg
