import { ContentTypesStructureEnums } from "../../../enums/structureEnums/templateType";
import structureTitleTemplateMapper from "./StructureTitleTemplateMapper";
import StructureTextTemplateMapper from "./StructureTextTemplateMapper";
import StructureGeneralTemplateMapper from "./StructureGeneralTemplateMapper";
import structureBubbleImageTemplateMapper from "./StructureBubbleImageTemplateMapper";
import structureTableTemplateMapper from "./StructureTableTemplateMapper";
import StructureSingleImageTemplateMapper from "./StructureSingleImageTemplateMapper";
import StructureImageTemplateMapper from "./StructureImageTemplateMapper";
import StructureCompareMonolithicTemplateMapper from "./StructureCompareMonolithicTemplateMapper";
import StructureCompareSeparateTemplateMapper from "./StructureCompareSeparateTemplateMapper";
import StructureDividerTemplateMapper from "./StructureDividerTemplateMapper";
import makeUrl from "../../../operations/makeUrl";
import getCachedMediaUrl from "../../../operations/getCachedMediaUrl";
import structureButtonTemplateMapper from "./StructureButtonTemplateMapper";

const roboTeacherMapper = async (data) => {
  const englishVoiceUri = data.englishRobotTeacher?.editedVoiceUri ?? data.englishRobotTeacher?.robotTeacherData?.voiceUri
  const nativeVoiceUri = data.nativeRobotTeacher?.editedVoiceUri ?? data.nativeRobotTeacher?.robotTeacherData?.voiceUri

  const englishVoiceURL = await getCachedMediaUrl(makeUrl(englishVoiceUri))
  const nativeVoiceURL = await getCachedMediaUrl(makeUrl(nativeVoiceUri))

  const templatesMapper = (d) => {
    return d?.templates?.map(item => {
      switch (item.type) {
        case ContentTypesStructureEnums.TITLE:
          return structureTitleTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.TEXT:
          return StructureTextTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.GENERAL:
          return StructureGeneralTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.SINGLE_IMAGE:
          return StructureSingleImageTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.IMAGES:
          return StructureImageTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.BUBBLE_IMAGE:
          return structureBubbleImageTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.TABLE:
          return structureTableTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.BUTTON:
          return structureButtonTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.COMPARE_MONOLITHIC:
          return StructureCompareMonolithicTemplateMapper({
            item,
            events: d.events
          })
        case ContentTypesStructureEnums.COMPARE_SEPARATE:
          return StructureCompareSeparateTemplateMapper({
            item,
            events: d.events
          })
        case ContentTypesStructureEnums.DIVIDER:
          return StructureDividerTemplateMapper({ item, events: d.events })
      }
    })
  }

  return {
    english: {
      voice: englishVoiceURL,
      description: data.englishRobotTeacher.robotTeacherData?.description,
      templates: templatesMapper(data.englishRobotTeacher.robotTeacherData),
      view: data.englishRobotTeacher.robotTeacherData?.events?.view,
      subTitles: data.englishRobotTeacher.robotTeacherData?.events?.subtitle,
      pauseTimes: data.englishRobotTeacher.robotTeacherData?.events?.voice,
      robot: data.englishRobotTeacher.robotTeacherData?.events?.robot?.sort((a, b) => parseInt(a.startTime) - parseInt(b.startTime)) ?? []
    },
    nativeData: {
      voice: nativeVoiceURL,
      description: data.nativeRobotTeacher.robotTeacherData?.description,
      templates: templatesMapper(data.nativeRobotTeacher.robotTeacherData),
      view: data.nativeRobotTeacher.robotTeacherData?.events?.view,
      subTitles: data.nativeRobotTeacher.robotTeacherData?.events?.subtitle,
      pauseTimes: data.nativeRobotTeacher.robotTeacherData?.events?.voice,
      robot: data.nativeRobotTeacher.robotTeacherData?.events?.robot?.sort((a, b) => parseInt(a.startTime) - parseInt(b.startTime)) ?? [],
      nativeLanguage: data.nativeRobotTeacher?.nativeLanguage
    }
  }
}

export default roboTeacherMapper