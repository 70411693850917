const ThumbDownSvg = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.039" height="23.037" viewBox="0 0 24.039 23.037">
      <g>
        <path
          fill={color}
          d="M0,19.931a2.233,2.233,0,0,0,.651,1.578,2.487,2.487,0,0,0-.64,1.912,2.588,2.588,0,0,0,2.611,2.271H8.808a14.478,14.478,0,0,0-.8,4.006c0,2.172,1.846,4.006,3,4.006a3.139,3.139,0,0,0,1.816-.61.5.5,0,0,0,.187-.392v-3.4l2.885-6.251.12-.06v.191a.5.5,0,0,0,.5.5h5.008a2.508,2.508,0,0,0,2.5-2.5V13.171a2.508,2.508,0,0,0-2.5-2.5h-3a2.512,2.512,0,0,0-2.358,1.655,6.529,6.529,0,0,0-2.651-.654H4.327a2.3,2.3,0,0,0-2.269,1.75,2.259,2.259,0,0,0,.18,1.5,2.244,2.244,0,0,0-1,3.006A2.244,2.244,0,0,0,0,19.931Zm17.027-6.76a1.5,1.5,0,0,1,1.5-1.5h3a1.5,1.5,0,0,1,1.5,1.5v8.013a1.5,1.5,0,0,1-1.5,1.5v-.005H17.029v-.5s0-.005,0-.008ZM2.113,18.694a.5.5,0,0,0,.286-.86,1.242,1.242,0,0,1,.714-2.143.5.5,0,0,0,.286-.86,1.24,1.24,0,0,1-.364-1.2,1.3,1.3,0,0,1,1.291-.964h9.194a5.108,5.108,0,0,1,2.5.739v8.464l-.722.361a.507.507,0,0,0-.23.238l-3,6.51a.5.5,0,0,0-.046.209v3.233a2.111,2.111,0,0,1-1,.272c-.549,0-2-1.365-2-3a15.569,15.569,0,0,1,.969-4.329.5.5,0,0,0-.469-.678H2.625a1.581,1.581,0,0,1-1.615-1.36,1.494,1.494,0,0,1,.712-1.417.5.5,0,0,0-.034-.871,1.24,1.24,0,0,1,.425-2.343Z"
          transform="translate(0 -10.667)"
        />
      </g>
    </svg>
  )
}

export default ThumbDownSvg