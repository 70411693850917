import serviceCall from "../../services";

const phrasesUseCase = async ({unitId}) => {
  const response = await serviceCall(
    'fetchToolsSentences',
    {unitId},
    'cloud'
  )

  return response
}

export default phrasesUseCase