export const C1 = '#231F20CC'
export const C2 = '#FCFCFC'
export const C3 = '#34D5F5'
export const C4 = '#29C8E7'
export const C5 = '#21B3D0'
export const C6 = '#0494B0'
export const C7 = '#007991'
export const C8 = '#036A7F'
export const C9 = '#066274'
export const C10 = '#034451'
export const C11 = 'linear-gradient(to right, #2E4A66,#007991)'
export const C12 = 'linear-gradient(to right, #007991,#2E4A66)'
export const C13 = '#DBDEF1'
export const C14 = 'linear-gradient(to right, #D0D2D3,#FCFCFC)'
export const C15 = '#FCFCFC1A'
export const C16 = '#BFC932'
export const C17 = '#FFDD15'
export const C18 = '#FF8800'
export const C19 = '#FCFCFC'
export const C20 = '#FCFCFC7F'
export const C21 = '#FCFCFC4D'
export const C22 = '#FCFCFCCC'
export const C23 = '#231F20'
export const C24 = '#0494B0'
export const C25 = '#11B2D2'
export const C26 = '#231F204D'
export const C27 = '#D0D2D3'
export const C28 = '#A2A1A1'
export const C29 = '#E20613'
export const C30 = '#1b982f'
export const C31 = '#00D2FC'
export const C32 = '#FCFCFC99'
export const C33 = '#FCFCFC33'
export const C34 = '#706F6F'
export const C35 = '#655A5A36'
export const C36 = '#11B2D2'
export const C37 = '#066274'
export const C38 = '#70707061'
export const C39 = '#FCFCFC'
export const C40 = '#007991'
export const C41 = '#21B3D0'
export const C42 = '#04323B'
export const C43 = '#FCFCFC'
export const C44 = '#FCFCFC'
export const C45 = '#FCFCFC33'
export const C46 = '#DBF1ED'
export const C47 = '#00FFFF'
export const C48 = '#E4E5EC'
export const C49 = '#CEE1EB'
export const C50 = '#EB008B'
export const C51 = '#007991'
export const C52 = '#11B2D2B8'
export const C53 = '#036A7FB8'
export const C54 = '#E1E1E1'
export const C55 = '#007991'
export const C56 = 'linear-gradient(to right, #11B2D2,#034451)'
export const C57 = 'linear-gradient(to right, #7C0EFF,#4F78FD,#09DEF8)'
export const C58 = '#09DEF8'
export const C59 = '#E1BD47'
export const C60 = '#2E4A66'
export const C61 = 'linear-gradient(to bottom, #2E4A66,#007991,#2E4A66)'
export const C62 = '#FCFCFC66'
export const C63 = '#E1E1E1A8'
export const C64 = '#B1B1B1'
export const C65 = 'linear-gradient(to bottom, #007991,#2E4A66)'
export const C66 = '#0C9BB9'
export const C67 = 'linear-gradient(to bottom, #FCFCFC,#D0D2D3)'
export const C68 = '#00A099'
export const C69 = '#878787'
export const C70 = '#034451'
export const C71 = 'linear-gradient(to right, #37B34A,#E20613)'
export const C72 = 'linear-gradient(to right, #00799166,#2E4A6666)'
export const C73 = '#2E4A66'
export const C74 = '#21364A'
export const C75 = '#076173'
export const C76 = '#2D7685'
export const C77 = '#11B2D2'
export const C78 = '#10A4C1'
export const C79 = '#2D4E6F'
export const C80 = '#036A7F'
export const C81 = '#073B45'
export const C82 = '#78B8C5'
export const C83 = '#087185'
export const C84 = '#2F8EA1'
export const C85 = '#2E4A66'
export const C86 = '#218FA5'
export const C87 = '#96B9C0'
export const C88 = '#41727C'
export const C89 = '#477781'
export const C90 = '#073C47'
export const C91 = '#2A9BB1'
export const C92 = '#81A0A6'
export const C93 = '#82989C'
export const C94 = '#81D6E7'
export const C95 = '#2F8EA1'
export const C96 = '#33ACC3'
export const C97 = '#036A7F'
export const C98 = '#023640'
export const C99 = '#81D6E7'
export const C100 = '#48CDF7'
export const C101 = '#FCFCFC'
export const C102 = 'linear-gradient(to right, #2E4A66,#007991)'
export const C103 = '#FCFCFC'
export const C104 = 'linear-gradient(to bottom, #CDE7E5,#676C74)'
export const C105 = '#043958'
export const C106 = 'linear-gradient(to right, #B1B1B1,#FCFCFC)'
export const C107 = '#11B2D2'
export const C108 = '#2E4A66CF'
export const C109 = '#231F2080'
export const C110 = '#3e567e'
export const C111 = '#1C4E6B'
export const C112 = '#919191'
export const C113 = '#0D0C07'
export const C114 = '#B4C2C3'
export const C115 = '#F4EEE2'
export const C116 = '#FFFFCB'
export const C117 = '#EFECDE'
export const C118 = '#669ACC'
export const C119 = '#659EB9'
export const C120 = '#986665'
export const C121 = '#9A7E4E'
export const C122 = '#CC9866'
export const C123 = '#131A22'
export const C124 = '#3B3B3B'
export const C125 = '#030303'
export const C126 = '#666666'
export const C127 = '#616161'
export const C128 = '#D9C39A'
export const C129 = '#A69370'
export const C130 = '#979832'
export const C131 = '#A58C4C'
export const C132 = '#9D9686'
export const C133 = '#C3943B'
export const C134 = '#336667'
export const C135 = '#0C0E1A'
export const C136 = '#656700'
export const C137 = '#5D5115'
export const C138 = '#C0A93F'
export const C139 = '#D4D4D2'
export const C140 = '#A6A5A1'
export const C141 = '#8385B9'
export const C142 = '#81D6E7'
export const C143 = '#FCFCFC80'
export const C144 = '#FCFCFCCC'
export const C145 = '#FCFCFC1A'
export const C146 = '#39A93580'
export const C147 = '#868686'
export const C148 = '#FCFCFC'
export const C149 = '#066274'
export const C150 = '#69b6cf'
export const C151 = '#FCFCFC'
export const C152 = '#FCFCFC'
export const C153 = '#231F20'
export const C154 = '#FCFCFCCC'
export const C155 = '#DBF1ED'
export const C156 = 'linear-gradient(to right,#5BC5F1,#F8B133)'
export const C157 = '#FCFCFC'
export const C158 = '#21B3D0'
export const C159 = '#11B2D2'
export const C160 = '#FCFCFC'
export const C161 = '#21B3D0'
export const C162 = '#0494B0'
export const C163 = '#036A7F'
export const C164 = '#066274'
export const C165 = '#034451'
export const C166 = '#04323B'
export const C167 = '#231F20'
export const C168 = '#FCFCFC4D'
export const C169 = 'radial-gradient(#034451,#036578,#0494B0)'
export const C170 = '#FCFCFC80'
export const C171 = 'linear-gradient(to right, #D0D2D3,#FCFCFC)'
export const C172 = '#FCFCFC33'
export const C173 = '#706F6F'
export const C174 = '#FCFCFC'
export const C175 = '#007991'
export const C176 = '#FCFCFCCC'
export const C177 = '#FCFCFC4D'
export const C178 = '#FCFCFC80'
export const C179 = '#FCFCFC'
export const C180 = '#FCFCFC1A'
export const C181 = '#FCFCFC1A'
export const C182 = '#FCFCFC33'
export const C183 = 'linear-gradient(to right, #2E4A66,#007991)'
export const C184 = 'linear-gradient(to right, #007991,#2E4A66)'
export const C185 = '#FCFCFC4D'
export const C186 = 'linear-gradient(to right, #FCFCFC,#D0D2D3)'
export const C187 = '#FCFCFC'
export const C188 = '#21B3D0'
export const C189 = '#21B3D04D'
export const C190 = '#0494B0'
export const C191 = '#0494B0'
export const C192 = '#FCFCFCCC'
export const C193 = '#FCFCFC33'
export const C194 = '#FCFCFCCC'
export const C195 = '#11B2D2'
export const C196 = '#FCFCFC80'
export const C197 = '#FCFCFC4D'
export const C198 = '#231F20'
export const C199 = 'linear-gradient(to right, #2E4A66,#007991)'
export const C200 = '#FCFCFC'
export const C201 = '#11B2D2'
export const C202 = '#231F20'
export const C203 = '#FCFCFC80'
export const C204 = '#231F2080'
export const C205 = '#FCFCFC1A'
export const C206 = '#FCFCFC4D'
export const C207 = '#007991'
export const C208 = 'linear-gradient(to right, #D0D2D3,#FCFCFC)'
export const C209 = '#FCFCFC'
export const C210 = '#FCFCFCB3'
export const C211 = 'linear-gradient(to right, #D0D2D3,#FCFCFC)'
export const C212 = '#11B2D2'
export const C213 = '#FCFCFC'
export const C214 = '#FCFCFC99'
export const C215 = '#7C0EFF'
export const C216 = '#DDC136'
export const C217 = '#034451'
export const C218 = '#FCFCFC80'
export const C219 = '#FCFCFCCC'
export const C220 = '#FCFCFC80'
export const C221 = '#D0D2D3'
export const C222 = '#231F2040'
export const C223 = '#231F20'
export const C224 = '#FCFCFC'
export const C225 = '#066274'
export const C226 = '#E1E1E1'
export const C227 = '#231F20'
export const C228 = '#bfc1c7'
export const C229 = '#11B2D2'
export const C230 = '#231F20'
export const C231 = '#FCFCFCB3'
export const C232 = '#FCFCFCCC'
export const C233 = '#231F20'
export const C234 = '#D0D2D3'
export const C235 = '#11B2D2'
export const C236 = 'linear-gradient(to right, #2E4A66,#007991)'
export const C237 = '#11B2D2'
export const C238 = 'linear-gradient(to right, #2E4A66,#007991)'
export const C239 = 'linear-gradient(to right, #D0D2D3,#FCFCFC)'
export const C240 = '#FCFCFC'
export const C241 = '#FCFCFCB3'
export const C242 = '#FCFCFC'
export const C243 = '#6C66AF'
export const C244 = '#FCFCFC'
export const C245 = '#007991'
export const C246 = '#2E4A66'
export const C247 = '#FCFCFCB3'
export const C248 = '#CCB058'
export const C249 = '#B0B0B0'
export const C250 = '#BB9090'
export const C251 = '#638088'
export const C252 = '#545F83'
export const C253 = '#FCFCFC1A'
export const C254 = 'linear-gradient(to right, #B1B1B1,#DADADA,#FCFCFC)'
export const C255 = '#81D6E7'
export const C256 = '#007991'
export const C257 = '#FCFCFC4D'
export const C258 = '#FCFCFC99'
export const C259 = '#FCFCFC1A'
export const C260 = '#FCFCFC66'
export const C261 = '#FCFCFC4D'
export const C262 = '#8BC53F'
export const C263 = '#A6A9AB'
export const C264 = '#81D6E7'
export const C265 = '#81D6E74D'
export const C266 = '#81D6E7'
export const C267 = '#0662744D'
export const C268 = '#2E4A66'
export const C269 = '#FCFCFC26'
export const C270 = '#FCFCFC33'
export const C271 = '#C4679E'
export const C272 = '#00ADEE'
export const C273 = '#C2C73B'
export const C274 = '#D3D3D3'
export const C275 = '#0344518C'
export const C276 = '#007991'
export const C277 = '#FCFCFCA8'
export const C278 = '#78B8C566'
export const C279 = '#C02EF2'
export const C280 = '#A78233'
export const C281 = '#7D4848'
export const C282 = '#638088'
export const C283 = '#262261'
export const C284 = '#FCFCFC4D'
export const C285 = '#FCFCFC33'
export const C286 = 'linear-gradient(to right, #D0D2D3,#FCFCFC)'
export const C287 = 'linear-gradient(to right, #808080,#FCFCFC)'
export const C288 = '#81D6E7'
export const C289 = '#FCFCFC80'
export const C290 = '#FCFCFC80'
export const C291 = '#FCFCFC'
export const C292 = '#81D6E7'
export const C293 = '#FCFCFC4D'
export const C294 = '#036A7F'
export const C295 = '#034451'
export const C296 = '#EEC732'
export const C297 = '#FCFCFC80'
export const C298 = 'linear-gradient(to right, #007991,#2E4A66)'
export const C299 = 'radial-gradient(#2E4A66,#007991,#2E4A66)'
export const C300 = '#FCFCFC99'
export const C301 = '#226D7D'
export const C302 = '#E1E1E1'
export const C303 = '#D0D2D3'
export const C304 = '#FCFCFC66'
export const C305 = '#FCFCFC33'
export const C306 = '#87878799'
export const C307 = '#87878733'
export const C308 = '#DCA3CE'
export const C309 = '#78B8C5'
export const C310 = '#F9AE08'
export const C311 = '#81D6E7'
export const C312 = '#005b6d'
export const C313 = '#065564'
export const C314 = '#14CBF1'
export const C315 = '#14C3E6'
export const C316 = '#14BADC'
export const C317 = '#11AECE'
export const C318 = '#0EA4C2'
export const C319 = '#0C99B6'
export const C320 = '#0C91AC'
export const C321 = '#0B88A1'
export const C322 = '#0B7E94'
export const C323 = '#087387'
export const C324 = '#07687B'
export const C325 = '#055B6B'
export const C326 = '#034D5B'
export const C327 = '#03414D'
export const C328 = '#023640'
export const C329 = '#022C34'
export const C330 = '#02242A'
export const C331 = '#011D23'
export const C332 = '#01181C'
export const C333 = '#011114'
export const C334 = '#011D23'
export const C335 = '#011114'
export const C336 = '#0B88A1'
export const C337 = '#0B7E94'
export const C338 = '#087387'
export const C339 = '#07687B'
export const C340 = '#055B6B'
export const C341 = '#034D5B'
export const C342 = '#03414D'
export const C343 = '#023640'
export const C344 = '#022C34'
export const C345 = '#02242A'
export const C346 = '#011D23'
export const C347 = '#011114'
export const C348 = '#14CBF1'
export const C349 = '#14C3E6'
export const C350 = '#11AECE'
export const C351 = '#0EA4C2'
export const C352 = '#0C99B6'
export const C353 = '#0B88A1'
export const C354 = '#0B7E94'
export const C355 = '#087387'
export const C356 = '#07687B'
export const C357 = '#055B6B'
export const C358 = '#034D5B'
export const C359 = '#03414D'
export const C360 = '#023640'
export const C361 = '#022C34'
export const C362 = '#02242A'
export const C363 = '#011D23'
export const C364 = '#011114'
export const C365 = '#14CBF1'
export const C366 = '#14C3E6'
export const C367 = '#11AECE'
export const C368 = '#0C99B6'
export const C369 = '#0B88A1'
export const C370 = '#0B7E94'
export const C371 = '#087387'
export const C372 = '#07687B'
export const C373 = '#055B6B'
export const C374 = '#034D5B'
export const C375 = '#03414D'
export const C376 = '#023640'
export const C377 = '#022C34'
export const C378 = '#02242A'
export const C379 = '#011D23'
export const C380 = '#011114'
export const C381 = '#14CBF1'
export const C382 = '#14C3E6'
export const C383 = '#11AECE'
export const C384 = '#0C99B6'
export const C385 = '#0B88A1'
export const C386 = '#0B7E94'
export const C387 = '#07687B'
export const C388 = '#055B6B'
export const C389 = '#034D5B'
export const C390 = '#03414D'
export const C391 = '#023640'
export const C392 = '#022C34'
export const C393 = '#02242A'
export const C394 = '#011D23'
export const C395 = '#011114'
export const C396 = '#14CBF1'
export const C397 = '#14C3E6'
export const C398 = '#11AECE'
export const C399 = '#0C99B6'
export const C400 = '#0B88A1'
export const C401 = '#07687B'
export const C402 = '#055B6B'
export const C403 = '#034D5B'
export const C404 = '#03414D'
export const C405 = '#023640'
export const C406 = '#022C34'
export const C407 = '#02242A'
export const C408 = '#011D23'
export const C409 = '#011114'
export const C410 = '#14CBF1'
export const C411 = '#14C3E6'
export const C412 = '#11AECE'
export const C413 = '#0C99B6'
export const C414 = '#07687B'
export const C415 = '#055B6B'
export const C416 = '#034D5B'
export const C417 = '#03414D'
export const C418 = '#023640'
export const C419 = '#022C34'
export const C420 = '#02242A'
export const C421 = '#011D23'
export const C422 = '#011114'
export const C423 = '#14CBF1'
export const C424 = '#11AECE'
export const C425 = '#0C99B6'
export const C426 = '#07687B'
export const C427 = '#055B6B'
export const C428 = '#034D5B'
export const C429 = '#03414D'
export const C430 = '#023640'
export const C431 = '#022C34'
export const C432 = '#02242A'
export const C433 = '#011D23'
export const C434 = '#011114'
export const C435 = '#14CBF1'
export const C436 = '#11AECE'
export const C437 = '#0C99B6'
export const C438 = '#07687B'
export const C439 = '#055B6B'
export const C440 = '#034D5B'
export const C441 = '#03414D'
export const C442 = '#022C34'
export const C443 = '#02242A'
export const C444 = '#011D23'
export const C445 = '#011114'
export const C446 = '#14CBF1'
export const C447 = '#0C99B6'
export const C448 = '#07687B'
export const C449 = '#055B6B'
export const C450 = '#034D5B'
export const C451 = '#03414D'
export const C452 = '#022C34'
export const C453 = '#02242A'
export const C454 = '#011D23'
export const C455 = '#011114'
export const C456 = '#14CBF1'
export const C457 = '#0C99B6'
export const C458 = '#07687B'
export const C459 = '#055B6B'
export const C460 = '#03414D'
export const C461 = '#022C34'
export const C462 = '#02242A'
export const C463 = '#011D23'
export const C464 = '#011114'
export const C465 = '#14CBF1'
export const C466 = '#0C99B6'
export const C467 = '#07687B'
export const C468 = '#055B6B'
export const C469 = '#03414D'
export const C470 = '#02242A'
export const C471 = '#011D23'
export const C472 = '#011114'
export const C473 = '#53CBE3'
export const C474 = '#49BFD5'
export const C475 = '#33ACC3'
export const C476 = '#0494B0'
export const C477 = '#007991'
export const C478 = '#036A7F'
export const C479 = '#066274'
export const C480 = '#034451'
export const C481 = '#231F204D'
export const C482 = '#FCFCFC99'
export const C483 = '#231F20'
export const C484 = '#E4E5EC'
export const C485 = '#19869C'
export const C486 = '#034451'
export const C487 = '#FCFCFC33'
export const C488 = '#FCFCFC4D'
export const C489 = '#9D0606'
export const C490 = '#204162'
export const C491 = 'linear-gradient(to bottom, #034451,#0494B0)'
export const C492 = '#A8149D'
export const C493 = '#A19C06'
export const C494 = '#A56FE6'
export const C495 = '#AC618F'
export const C496 = '#2697AE'
export const C497 = '#16BFE0'
export const C498 = '#93C01F'
export const C499 = '#009EE2'
export const C500 = '#5D2684'
export const C501 = '#FCFCFCCC'
export const C502 = 'linear-gradient(to right, #11B2D2,#034451)'
export const C503 = 'linear-gradient(to right, #034451,#11B2D2)'
export const C504 = '#11B2D2'
export const C505 = '#2E4A66'
export const C506 = '#CACBCF'
export const C507 = '#707070'
export const C508 = '#FCFCFC33'
export const C509 = '#FCFCFCCC'
export const C510 = 'linear-gradient(to right, #034451,#11B2D2)'
export const C511 = '#FCFCFC4D'
export const C512 = '#BAB6B6'
export const C513 = '#BBBDBF'
export const C514 = '#FCFCFC80'
export const C515 = '#7448BC'
export const C516 = '#FCFCFCCC'
export const C517 = '#11B2D2'
export const C518 = '#818181'
export const C519 = '#005879'
export const C520 = '#FCFCFCB3'
export const C521 = 'linear-gradient(to right, #007991,#2E4A66)'
export const C522 = '#FCFCFC80'
export const C523 = '#034451'
export const C524 = '#FCFCFC'
export const C525 = '#FCFCFCCC'
export const C526 = '#FCFCFCCC'
export const C527 = '#FCFCFCCC'
export const C528 = '#FCFCFC80'
export const C529 = 'linear-gradient(to right, #007991,#2E4A66)'
export const C530 = '#036A7F'
export const C531 = '#11B2D2'
export const C532 = '#FCFCFCCC'
export const C533 = '#FCFCFC80'
export const C534 = '#033451'
export const C535 = '#2E4A66'
export const C536 = '#FCFCFC80'
export const C537 = 'linear-gradient(to right, #007991,#2E4A66)'
export const C538 = 'linear-gradient(to right, #007991DE,#2E4A66DE)'
export const C539 = '#BBBDC0'
export const C540 = '#066274'
export const C541 = '#FCFCFC'
export const C542 = '#0E4157FF'
export const C543 = '#DBDEF1B3'
export const C544 = '#FCFCFC'
export const C545 = '#2E4A6680'
export const C546 = '#2E4A664D'
export const C547 = '#034451'
export const C548 = '#FCFCFC99'
export const C549 = 'linear-gradient(to right, #247d93, #39677f)'
// export const C550 = '#231F20'
export const C550 = '#59767B'
export const C551 = '#2A6874'
export const C552 = '#D8BD1C'
export const C553 = '#FCFCFC'
export const C554 = '#231F20'
export const C555 = '#2E4A6645'
export const C556 = '#FCFCFC'
export const C557 = '#FFDD154D'
export const C558 = '#00ADEE4D'
export const C559 = '#37B34A4D'
export const C560 = 'linear-gradient(to right,#007991,#0E4157)'
export const C561 = '#0E4157'
export const C562 = 'linear-gradient(to bottom,#0A2B38,#098AA3)'
export const C563 = '#FCFCFC1A'
export const C564 = '#066274'
export const C565 = '#FCFCFC'
export const C566 = '#007991'
export const C567 = 'linear-gradient(to right,#1AA7DB,#067EAA)'
export const C568 = '#FCFCFC'
export const C569 = '#007991CC'
export const C570 = '#FCFCFC'
export const C571 = '#E98020'
export const C572 = '#FCFCFC'
export const C573 = '#000000'
export const C574 = '#FCFCFC'
export const C575 = '#000000'
export const C576 = '#000000'
export const C577 = '#000000'
export const C578 = '#FCFCFCCC'
export const C579 = '#C3F5F5E8'
export const C580 = '#FCFCFC'
export const C581 = '#000000'
export const C582 = '#86CEDCFF'
export const C583 = '#C3C3C3'
export const C584 = '#FFFFFF7F'
export const C585 = '#000000'
export const C586 = '#58595B'
export const C587 = '#D4ECF1CC'
export const C588 = '#0a2e3d'
export const C589 = '#C1BED0'
export const C590 = '#05ccf5'
export const C591 = '#C4C9CE'
export const C592 = '#A0D8E3'
export const C593 = '#FCFCFC'
export const C594 = '#398E9F'
export const C595 = '#085F7A'
export const C596 = '#FCFCFC'
export const C597 = '#29D0F1'
export const C598 = '#1E3144'
export const C599 = '#FCFCFC'
export const C600 = '#C1BED0'
export const C601 = '#2e4a66'
export const C602 = '#37B34A'
export const C603 = '#043958'
export const C604 = '#FF3944'
export const C605 = '#00000080'
export const C606 = '#2bb1cb'
export const C607 = '#0000002E'
export const C608 = '#398E9F'
export const C609 = '#2E4A66'
export const C610 = '#398E9F'
export const C611 = '#FFFAEC'
export const C612 = '#21252B'
export const C613 = '#00000080'
export const C614 = '#FCFCFC'


export const MC1 = 'linear-gradient(to right, #086e82b5, #0c89a2b5)'
export const MC2 = '#2E4A66'
export const MC3 = '#231F2040'