import componentStyle from "./ExerciseMistakeInfoButton.module.scss"
import React, {useContext, useRef, useState} from "react";
import WarningSvg from "../../icons/WarningSvg";
import {isMobile} from "react-device-detect";
import {Overlay, Popover} from "react-bootstrap";
import colorsContext from "../../contexts/ColorsContext";
import BottomSheet from "../BottomSheet/BottomSheet";
import classNames from "classnames";
import exerciseMistakeInfoStyle from "./ExerciseMistakeInfoButton.module.scss";
import TranslatedText from '../TranslatedText/TranslatedText'
import useTranslatedText from '../../hooks/useTranslatedText'

const ExerciseMistakeInfoButton = ({wordByWordResult, content, popoverPlacement = 'left'}) => {
  const {colors} = useContext(colorsContext)
  const {dir} = useTranslatedText() // rtl, ltr

	const renderCorrectedAnswer = () => {
		const correctedSentence = () => {
			if (wordByWordResult?.length > 0) {
				return wordByWordResult?.map((word, i) => (
					<>
						<span
							className={classNames({
								[exerciseMistakeInfoStyle.typoWord]: word?.actionToCorrect > 0,
							})}
							key={i}>
							{word?.userStr || word?.correctStr}
						</span>
						{wordByWordResult.length > i + 1 && <span> </span>}
					</>
				))
			} else {
				return content || ''
			}
		}

    return (
      <div style={{color: colors['C589']}}>
        <p dir={dir} className='fs-lg mb-1'><TranslatedText translationKey={'well.done.consider.this'}/></p>
        <div
          className='res:fs-md font-weight-300'
        >
          {correctedSentence()}
        </div>
      </div>
    )
  }

	const PopoverStyleButton = () => {
		const target = useRef(null)
		const [showPopover, setShowPopover] = useState(false)

		return (
			<>
				<button ref={target} onClick={() => setShowPopover(!showPopover)} className={componentStyle.mistakeInfoButton}>
					<WarningSvg color="#e98020" />
				</button>
				<Overlay
					onHide={() => setShowPopover(false)}
					target={target.current}
					rootClose={true}
					show={showPopover}
					placement={popoverPlacement}>
					{({ style, ...props }) => (
						<Popover
							className="hint-popover py-2 px-3"
							style={{
								...style,
								background: colors['C73'],
								color: colors['C589'],
								borderColor: colors['C73'],
								minWidth: 285,
							}}
							{...props}>
							{renderCorrectedAnswer()}
						</Popover>
					)}
				</Overlay>
			</>
		)
	}

	const BottomSheetStyleButton = () => {
		const [bottomSheetIsOpen, setBottomSheetIsOpen] = useState(false)

		return (
			<>
				<button className={componentStyle.mistakeInfoButton} onClick={() => setBottomSheetIsOpen(true)}>
					<WarningSvg color="#e98020" />
				</button>
				{bottomSheetIsOpen && (
					<BottomSheet
						hasSpace={true}
						overlayOpacity={0.5}
						close={() => {
							setBottomSheetIsOpen(false)
						}}>
						{() => renderCorrectedAnswer()}
					</BottomSheet>
				)}
			</>
		)
	}

	return (
		<>
			<div className="d-flex justify-content-end">{isMobile ? <BottomSheetStyleButton /> : <PopoverStyleButton />}</div>
		</>
	)
}

export default ExerciseMistakeInfoButton
