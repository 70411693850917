import structureBaseTitleMapper from "./StructureBaseTitleMapper";
import {min} from "lodash";
import { ContentTypesStructureEnums } from '../../../enums/structureEnums/templateType'
import structureBaseImageMapper from "./StructureBaseImageMapper";

const StructureCompareMonolithicTemplateMapper = ({item, events}) => {
  return {
    ...item,
    data: {
      cards: item.data.cards?.map(card => {
        switch (card.type) {
          case ContentTypesStructureEnums.TITLE:
            return {
              ...card,
              data: {
                ...card.data,
                text: structureBaseTitleMapper({title: card?.data?.text, events})
              },
              hiddenTime: min(events?.view?.filter(it => ((it?.templateId === item?.id) && (it?.cardId === card?.id))).map(it => parseInt(it?.startTime) || 0)) || 0
            }
          case ContentTypesStructureEnums.IMAGE:
            return {
              ...card,
              data: {
                ...card.data,
                pictureRight: structureBaseImageMapper({
                  image: card?.data?.pictureRight,
                  text: structureBaseTitleMapper({text: card?.data?.pictureTextRight, events}),
                }),
                pictureLeft: structureBaseImageMapper({
                  image: card?.data?.pictureLeft,
                  text: structureBaseTitleMapper({text: card?.data?.pictureTextLeft, events}),
                })
              },
              hiddenTime: min(events?.view?.filter(it => ((it?.templateId === item?.id) && (it?.cardId === card?.id))).map(it => parseInt(it?.startTime) || 0)) || 0
            }
          case ContentTypesStructureEnums.SUBJECT:
            return {
              ...card,
              data: {
                ...card.data,
                textsRight: card.data.textsRight.map(text => {
                  return {
                    ...text,
                    text: structureBaseTitleMapper({title: text?.text, events})
                  }
                }),
                textsLeft: card.data.textsLeft.map(text => {
                  return {
                    ...text,
                    text: structureBaseTitleMapper({title: text?.text, events})
                  }
                }),
              },
              hiddenTime: min(events?.view?.filter(it => ((it?.templateId === item?.id) && (it?.cardId === card?.id))).map(it => parseInt(it?.startTime) || 0)) || 0
            }
        }

      })
    },
    hiddenTime: parseInt(events?.view?.find(it => it.templateId === item.id)?.startTime) || 0
  }
}

export default StructureCompareMonolithicTemplateMapper