import * as React from "react"

const MoreSvg = ({color}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="100%"
    height="100%"
    xmlSpace="preserve"
  >
    <path
      fill={color}
      d="M6.55 17.45C2.94 17.45 0 20.39 0 24s2.94 6.55 6.55 6.55S13.1 27.61 13.1 24s-2.94-6.55-6.55-6.55zm0 11.05c-2.48 0-4.5-2.02-4.5-4.5s2.02-4.5 4.5-4.5 4.5 2.02 4.5 4.5-2.02 4.5-4.5 4.5zM24 17.45c-3.61 0-6.55 2.94-6.55 6.55s2.94 6.55 6.55 6.55 6.55-2.94 6.55-6.55-2.94-6.55-6.55-6.55zm0 11.05c-2.48 0-4.5-2.02-4.5-4.5s2.02-4.5 4.5-4.5 4.5 2.02 4.5 4.5-2.02 4.5-4.5 4.5zM41.45 17.45c-3.61 0-6.55 2.94-6.55 6.55s2.94 6.55 6.55 6.55S48 27.61 48 24s-2.94-6.55-6.55-6.55zm0 11.05c-2.48 0-4.5-2.02-4.5-4.5s2.02-4.5 4.5-4.5 4.5 2.02 4.5 4.5-2.02 4.5-4.5 4.5z"
    />
  </svg>
)

export default MoreSvg
