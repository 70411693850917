import {htmlTextConversion} from '../mapperConversions'
import {shuffle} from 'lodash'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'

const fetchMatchMapper = (data) => {
  const exerciseStack = data.exerciseStacks[0]

  return {
    pageType: pageGuidEnums.MATCH_CASE_EXERCISE,
    data: {
      paginable: false,
      type: 'MATCH_CASE',
      title: data.title ?? null,
      checked: false,
      checkable: false,
      stack: {
        id: exerciseStack.id,
        itemId: exerciseStack.items[0]?.id,
        title: exerciseStack.title ?? null,
        givens: [...exerciseStack.items[0]?.data.givens].map((given, i) => {
          return {
            ...given,
            text: htmlTextConversion(given.text),
            isBlank: given.text.sentence.includes("###"),
            answerText: exerciseStack.items[0]?.data.answers[i].items[0]?.text
          }
        }),
        choices: shuffle([...exerciseStack.items[0]?.data.choices]).map(choice => {
          return {
            ...choice.items[0],
            index: choice.index,
            text: htmlTextConversion(choice.items[0]?.text),
          }
        }),
      },
      userData: [],
    }
  }
}

export default fetchMatchMapper