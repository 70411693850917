import serviceCall from "../../services";
import filesAndFoldersMapper from "../../services/mapper/notepad/filesAndFoldersMapper";

const getAllNotepadFileAndFolderUseCase = async ({ folderId }) => {

	const result = await serviceCall(
		'fetchAllNotepadFileAndFolder',
		{ folderId },
		'cloud',
	)

	return filesAndFoldersMapper(result);
}

export default getAllNotepadFileAndFolderUseCase