import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const blinkGameMapper = (data) => {
	return {
		...data,
		vocabularies: [...data.vocabularies]?.map((vocab, vocabIndex) => {
			return {
				...vocab,
				vocabIndex,
				status: AnswerStatusEnums.NONE
			}
		}) ?? []
	}
}

export default blinkGameMapper