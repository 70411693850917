import React, { useContext } from 'react'
import removeWrapperTag from '../../../operations/removeWrapperTag'
import reactStringReplace from 'react-string-replace'
import InlineInputField from '../../../components/InlineInputField/InlineInputField'
import colorsContext from '../../../contexts/ColorsContext'
import numberIsOdd from '../../../operations/numberIsOdd'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import { moreOptionsList } from '../../../data'
import DragDropInlineAnswerBox from '../../../components/DragDropInlineAnswerBox/DragDropInlineAnswerBox'
import ComboBox from '../../../components/ComboBox/ComboBox'
import { AnswerStatusEnums, COMBOBOX, DROPBOX, INPUT } from '../../../enums/globalEnums/globalEnums'

const ExerciseFullTexts = (
	{
		fullTexts = [],
		userAnswerStatus = [AnswerStatusEnums.SKIPPED],
		template = INPUT,
		unlocked = false,
		textColor,
		optionsColor,
		inputColorCode = 'C222',
		showOptionsList = true
	},
) => {
	const { colors } = useContext(colorsContext)

	let count = 0

	const renderGivenTextParagraph = (paragraph, pIndex) => {
		const cleanParagraph = removeWrapperTag(paragraph.sentence, 'p')
		let i = 0

		// const re1 = new RegExp(/((?:(?:<s>\s*[,.!?\\-]\s*<\/s>)*<a[^>]*>\s*<s>(?:\w*)<\/s>\s*<\/a>(?:\s|<s>\W+<\/s>)*)+|<a[^>]*><s>\S*<\/s><\/a>|###|<s>\s*[,.!?\\-]\s*<\/s>)/, 'g')
		// const re2 = new RegExp(/((?:(?:<s>\s*[,.!?\\-]\s*<\/s>)*<a[^>]*>\s*<s>(?:.*?)<\/s>\s*<\/a>(?:\s<a[^>]*>\s*<s>(?:\w*)<\/s>\s*<\/a>|<s>\W+<\/s>)*)+|<a[^>]*><s>\S*<\/s><\/a>|###|<s>\s*[,.!'?\\-]\s*<\/s>)/, 'g')
		// const re3 = new RegExp(/((?:(?:<s>\s*[,.!?\\-]\s*<\/s>)*<a[^>]*>\s*<s>(?:.*?)<\/s>\s*<\/a>(?:\s<a[^>]*>\s*<s>(?:\w*(?:\W\w*)?)<\/s>\s*<\/a>|<s>\W+<\/s>)*)+|<a[^>]*><s>\S*<\/s><\/a>|###|<s>(?:\S+)<\/s>)/, 'g')
		const re = new RegExp(/((?:(?:<s>\s*[,.!?\\-]\s*<\/s>)*<a[^>]*>\s*<s>(?:.*?)<\/s>\s*<\/a>(?:\s<a[^>]*>\s*<s>(?:\w*(?:\W\w*)?)<\/s>\s*<\/a>|<s>.*?<\/s>)*)+|<a[^>]*><s>\S*<\/s><\/a>|###|<s>(?:\S+)<\/s>)/, 'g')

		const arr = reactStringReplace(cleanParagraph, re, (match, index) => {
			const fieldIndex = ((index - 1) / 2) + count
			const cleanMatch = removeWrapperTag(match, 's').replace('###', '-')
			i++
			switch (template) {
				case INPUT:
					return <InlineInputField
						value={
							<HtmlTextWrapper
								style={{ display: 'inline' }}
								textColor={userAnswerStatus[fieldIndex] === AnswerStatusEnums.CORRECT ? colors['C30'] : (textColor ?? colors['C23'])}
								data={{ sentence: cleanMatch }}
							/>
						}
						inputColorCode={inputColorCode}
						status={userAnswerStatus?.[fieldIndex]}
						key={index}
					/>
				case DROPBOX:
					return <DragDropInlineAnswerBox
						key={index}
						checked
						unlocked={unlocked}
						userAnswerStatus={userAnswerStatus?.[fieldIndex]}
						correctAnswer={{ sentence: cleanMatch }}
						bgColor={colors['C525']}
						style={{ marginBottom: '4px', marginTop: '4px' }}
					/>
				case COMBOBOX:
					return <ComboBox
						textColor={userAnswerStatus?.[fieldIndex] === AnswerStatusEnums.CORRECT ? colors['C30'] : colors['C233']}
						borderColor={userAnswerStatus?.[fieldIndex] === AnswerStatusEnums.CORRECT ? colors['C30'] : colors['C29']}
						value={cleanMatch}
					/>
				default:
					return cleanMatch
			}
		})
		/*
		* Example of arr:
		* ['<a href="text">Hello</a>', myCustomComponent, '.']
		* myCustomComponent is located in odd indexes (1, 3...)
		* */

		if (i > 0) count++

		return arr.map((el, i) => (
			<React.Fragment key={i}>
				{numberIsOdd(i) ? el :
					<HtmlTextWrapper
						style={{ display: 'inline' }}
						textColor={textColor}
						optionsColor={optionsColor ?? textColor}
						data={{
							...paragraph,
							sentence: el,
						}}
						showPhraseOptions={showOptionsList && i === 0}
						moreOptionsList={moreOptionsList}
					/>
				}
			</React.Fragment>
		))
	}

	if (fullTexts && fullTexts.length > 0) {
		return fullTexts.map((paragraph, pIndex) => {
			return (
				<div className='exercise-paragraph' style={{...paragraph.paragraphStyle}} key={pIndex}>
					{renderGivenTextParagraph(paragraph, pIndex)}
				</div>
			)
		})
	}

	return null
}

export default ExerciseFullTexts