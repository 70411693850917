import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import serviceCall from '../../services'
import userUnitStatusUseCase from "../../useCase/statistics/userUnitStatusUseCase";
import { baseAsyncThunk } from '../baseAsyncThunk';

const initialState = {
  loading: false,
  exerciseData: null,
  gameData: null,
  unitExerciseData: null,
  unitGameData: null,
  userUnitStatusData: [],
  error: '',
}

export const getUserUnitStatus = baseAsyncThunk(
  'statistics/getUserUnitStatus',
  ({ unitId }, thunkAPI) => {
    const { appUser } = thunkAPI.getState()
    const numberOfRepetitions = appUser.appUserData.setting.numberOfRepetitions
    return userUnitStatusUseCase({ unitId, numberOfRepetitions })
  },
)

export const getStatistics = baseAsyncThunk(
  'statistics/getStatistics',
  ({ studyType }) => {
    return serviceCall(
      'fetchStatistics',
      { studyType },
      'cloud',
    )
  },
)

export const getGameStatistics = baseAsyncThunk(
  'statistics/getGameStatistics',
  ({ studyType }) => {
    return serviceCall(
      'fetchGameStatistics',
      { studyType },
      'cloud',
    )
  },
)

export const getUnitGameStatistics = baseAsyncThunk(
  'statistics/getUnitGameStatistics',
  ({ unitId, studyType }) => {
    return serviceCall(
      'fetchGameStatisticsByUnit',
      { unitId, studyType },
      'cloud',
    )
  },
)

export const getUnitStatistics = baseAsyncThunk(
  'statistics/getUnitStatistics',
  ({ unitId, studyType }) => {
    return serviceCall(
      'fetchUnitStatistics',
      { unitId, studyType },
      'cloud',
    )
  },
)

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //get user unit status
    builder.addCase(getUserUnitStatus.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getUserUnitStatus.fulfilled, (state, action) => {
      state.loading = false
      state.userUnitStatusData = action.payload
    })
    builder.addCase(getUserUnitStatus.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    //get statistics
    builder.addCase(getStatistics.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getStatistics.fulfilled, (state, action) => {
      state.loading = false
      state.exerciseData = action.payload.exerciseData
    })
    builder.addCase(getStatistics.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    //get unit statistics
    builder.addCase(getUnitStatistics.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getUnitStatistics.fulfilled, (state, action) => {
      state.loading = false
      state.unitExerciseData = action.payload.exerciseData
    })
    builder.addCase(getUnitStatistics.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    // get unit game statistics
    builder.addCase(getUnitGameStatistics.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getUnitGameStatistics.fulfilled, (state, action) => {
      state.loading = false
      state.unitGameData = action.payload
    })
    builder.addCase(getUnitGameStatistics.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    // get game statistics
    builder.addCase(getGameStatistics.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getGameStatistics.fulfilled, (state, action) => {
      state.loading = false
      state.gameData = action.payload
    })
    builder.addCase(getGameStatistics.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const { } = statisticsSlice.actions

export default statisticsSlice.reducer