import {useEffect, useState} from "react";
import getStudentBinaryData from "../operations/getStudentBinaryData";

const useTextToSpeech = ({audioUrl, text, setAudioUrl, voiceId = 'Salli'}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (text && !audioUrl) {
      getPollySpeech()
    }
  }, [text, audioUrl])

  const getPollySpeech = async () => {
    setLoading(true)
    const blobUrl = await getStudentBinaryData(`text-to-speech?voiceId=${voiceId}&text=${text}`)
    setAudioUrl(blobUrl)
    setLoading(false)
  }

  return {pendingTextToSpeech: loading}
}

export default useTextToSpeech