export const C1 = '#231F20CC'
export const C2 = '#FCFCFC'
export const C3 = '#A1A930'
export const C4 = '#A1A930'
export const C5 = '#A1A930'
export const C6 = '#A1A930'
export const C7 = '#A1A930'
export const C8 = '#A1A930'
export const C9 = '#A1A930'
export const C10 = '#A1A930'
export const C11 = '#A1A930'
export const C12 = '#587560'
export const C13 = '#F5FFCB'
export const C14 = '#A1A930'
export const C15 = '#F5FFCB'
export const C16 = '#BFC932'
export const C17 = '#FFDD15'
export const C18 = '#FF8800'
export const C19 = '#231F20'
export const C20 = '#C3D9BBB2'
export const C21 = '#FCFCFC4D'
export const C22 = '#D8EAD2'
export const C23 = '#231F20'
export const C24 = '#6F8E45'
export const C25 = '#A1A930'
export const C26 = '#FCFCFC4D'
export const C27 = '#D0D2D3'
export const C28 = '#A2A1A1'
export const C29 = '#E20613'
export const C30 = '#1b982f'
export const C31 = '#EEF3AD'
export const C32 = '#D8EAD2CC'
export const C33 = '#EEF3AD'
export const C34 = '#706F6F'
export const C35 = '#587560'
export const C36 = '#93C01F'
export const C37 = '#0B6623'
export const C38 = '#DAE367FF'
export const C39 = '#FCFCFC'
export const C40 = '#94B28A'
export const C41 = '#0B6623'
export const C42 = '#D8EAD2'
export const C43 = '#FCFCFC'
export const C44 = '#231F20'
export const C45 = '#94B28A66'
export const C46 = '#E20613'
export const C47 = '#EEF3AD'
export const C48 = '#289165'
export const C49 = '#C9CE85'
export const C50 = '#EB008B'
export const C51 = '#E20613'
export const C52 = '#E20613'
export const C53 = '#E20613'
export const C54 = '#728C69'
export const C55 = '#EEF3AD'
export const C56 = '#A1A930'
export const C57 = 'linear-gradient(to right,#7C0EFF,#4F78FD,#09DEF8)'
export const C58 = '#09DEF8'
export const C59 = '#E1BD47'
export const C60 = '#0B6623'
export const C61 = '#E20613'
export const C62 = '#F5FFCB'
export const C63 = '#307777A8'
export const C64 = '#B1B1B1'
export const C65 = '#D8EAD2'
export const C66 = '#82A875FF'
export const C67 = 'linear-gradient(to bottom,#FCFCFC,#D0D2D3)'
export const C68 = '#728C69'
export const C69 = '#878787'
export const C70 = '#0B6623'
export const C71 = 'linear-gradient(to right,#37B34A,#E20613)'
export const C72 = '#94B28A'
export const C73 = '#F5FFCB'
export const C74 = '#679473'
export const C75 = '#587560'
export const C76 = '#679473'
export const C77 = '#7DC390'
export const C78 = '#27803F'
export const C79 = '#A1A930'
export const C80 = '#6C7308'
export const C81 = '#C9CE85'
export const C82 = '#728C69'
export const C83 = '#3E971F'
export const C84 = '#728C69'
export const C85 = '#4E7541'
export const C86 = '#3B9653'
export const C87 = '#BEC990'
export const C88 = '#A7B860'
export const C89 = '#76872F'
export const C90 = '#4D5B13'
export const C91 = '#2A9847'
export const C92 = '#6E7B3A'
export const C93 = '#4F853C'
export const C94 = '#679473'
export const C95 = '#0B6623'
export const C96 = '#A1A930'
export const C97 = '#6E7A39'
export const C98 = '#555F2A'
export const C99 = '#E20613'
export const C100 = '#48CDF7'
export const C101 = '#FCFCFC'
export const C102 = '#F5FFCB'
export const C103 = '#F5FFCB'
export const C104 = 'linear-gradient(to bottom,#CDE7E5,#676C74)'
export const C105 = '#94B28A'
export const C106 = 'linear-gradient(to right,#B1B1B1,#FCFCFC)'
export const C107 = '#0B6623'
export const C108 = '#E20613'
export const C109 = '#231F2080'
export const C110 = '#44650a'
export const C111 = '#1C4E6B'
export const C112 = '#919191'
export const C113 = '#0D0C07'
export const C114 = '#B4C2C3'
export const C115 = '#F4EEE2'
export const C116 = '#FFFFCB'
export const C117 = '#EFECDE'
export const C118 = '#669ACC'
export const C119 = '#659EB9'
export const C120 = '#986665'
export const C121 = '#9A7E4E'
export const C122 = '#CC9866'
export const C123 = '#131A22'
export const C124 = '#3B3B3B'
export const C125 = '#030303'
export const C126 = '#666666'
export const C127 = '#616161'
export const C128 = '#D9C39A'
export const C129 = '#A69370'
export const C130 = '#979832'
export const C131 = '#A58C4C'
export const C132 = '#9D9686'
export const C133 = '#C3943B'
export const C134 = '#336667'
export const C135 = '#0C0E1A'
export const C136 = '#656700'
export const C137 = '#5D5115'
export const C138 = '#C0A93F'
export const C139 = '#D4D4D2'
export const C140 = '#A6A5A1'
export const C141 = '#8385B9'
export const C142 = '#D8EAD2'
export const C143 = '#FCFCFC80'
export const C144 = '#FCFCFCCC'
export const C145 = '#415e49'
export const C146 = '#39A93580'
export const C147 = '#868686'
export const C148 = '#F5FFCB'
export const C149 = '#0B6623'
export const C150 = '#cbd44e'
export const C151 = '#231F20'
export const C152 = '#231F20'
export const C153 = '#231F20'
export const C154 = '#94B28A'
export const C155 = '#F2EFF5'
export const C156 = 'linear-gradient(to right,#5BC5F1,#F8B133)'
export const C157 = '#728C69'
export const C158 = '#F5FFCB'
export const C159 = '#0B6623'
export const C160 = '#FCFCFC'
export const C161 = '#728C69'
export const C162 = '#728C69'
export const C163 = '#728C69'
export const C164 = '#728C69'
export const C165 = '#728C69'
export const C166 = '#728C69'
export const C167 = '#FCFCFC'
export const C168 = '#231F204D'
export const C169 = 'radial-gradient(#587560,#98BF64,#587560)'
export const C170 = '#728C6980'
export const C171 = 'linear-gradient(to right,#D0D2D3,#FCFCFC)'
export const C172 = '#0B662366'
export const C173 = '#706F6F'
export const C174 = '#8FB99BFF'
export const C175 = '#F5FFCB'
export const C176 = '#D8EAD2'
export const C177 = '#668F72FF'
export const C178 = '#FCFCFC80'
export const C179 = '#FCFCFC'
export const C180 = '#94B28A'
export const C181 = '#E20613'
export const C182 = '#F5FFCB'
export const C183 = '#587560'
export const C184 = '#A1A930'
export const C185 = '#728C69'
export const C186 = '#DBE27C'
export const C187 = '#F5FFCB'
export const C188 = '#EEF3AD'
export const C189 = '#E20613'
export const C190 = '#A1A930'
export const C191 = '#A1A930'
export const C192 = '#F5FFCBCC'
export const C193 = '#96a94e'
export const C194 = '#F5FFCB'
export const C195 = '#E20613'
export const C196 = '#838A244C'
export const C197 = '#FCFCFC4D'
export const C198 = '#231F20'
export const C199 = '#A1A930'
export const C200 = '#F5FFCB'
export const C201 = '#91964a'
export const C202 = '#0B6623'
export const C203 = '#F5FFCB80'
export const C204 = '#0B662380'
export const C205 = '#D8EAD280'
export const C206 = '#E20613'
export const C207 = '#E20613'
export const C208 = '#075920'
export const C209 = '#FCFCFC'
export const C210 = '#F5FFCBB3'
export const C211 = '#D8EAD2'
export const C212 = '#94B28A'
export const C213 = '#bdd7b5'
export const C214 = '#F5FFCB99'
export const C215 = '#7C0EFF'
export const C216 = '#E20613'
export const C217 = '#E20613'
export const C218 = '#F5FFCB80'
export const C219 = '#C4D8AA'
export const C220 = '#D8EAD2'
export const C221 = '#848D92'
export const C222 = '#231F2040'
export const C223 = '#587560'
export const C224 = '#E20613'
export const C225 = '#0B6623'
export const C226 = '#E1E1E1'
export const C227 = '#0B6623'
export const C228 = '#bfc1c7'
export const C229 = '#F5FFCB'
export const C230 = '#FCFCFC'
export const C231 = '#D8EAD2'
export const C232 = '#F5FFCB'
export const C233 = '#231F20'
export const C234 = '#D8EAD2'
export const C235 = '#0B6623'
export const C236 = '#08531c61'
export const C237 = '#E20613'
export const C238 = '#587560'
export const C239 = 'linear-gradient(to right,#D0D2D3,#FCFCFC)'
export const C240 = '#A1A930'
export const C241 = '#FCFCFCB3'
export const C242 = '#D0EBC6'
export const C243 = '#6C66AF'
export const C244 = '#D8EAD2'
export const C245 = '#E20613'
export const C246 = '#E20613'
export const C247 = '#A1A930'
export const C248 = '#CCB058'
export const C249 = '#B0B0B0'
export const C250 = '#BB9090'
export const C251 = '#638088'
export const C252 = '#545F83'
export const C253 = '#F5FFCB'
export const C254 = '#94B28A'
export const C255 = '#0B6623'
export const C256 = '#0B6623'
export const C257 = '#D8EAD24D'
export const C258 = '#F5FFCB'
export const C259 = '#FCFCFC1A'
export const C260 = '#E20613'
export const C261 = '#F5FFCB'
export const C262 = '#8BC53F'
export const C263 = '#A6A9AB'
export const C264 = '#0B6623'
export const C265 = '#94B28A'
export const C266 = '#F5FFCB'
export const C267 = '#D8EAD2'
export const C268 = '#0B6623'
export const C269 = '#728C69'
export const C270 = '#94B28A'
export const C271 = '#C4679E'
export const C272 = '#00ADEE'
export const C273 = '#C2C73B'
export const C274 = '#D3D3D3'
export const C275 = '#65BE7DFF'
export const C276 = '#E20613'
export const C277 = '#728C69'
export const C278 = '#A1CC9580              '
export const C279 = '#C02EF2'
export const C280 = '#A78233'
export const C281 = '#7D4848'
export const C282 = '#638088'
export const C283 = '#262261'
export const C284 = '#728C69'
export const C285 = '#FCFCFC33'
export const C286 = '#A1A930'
export const C287 = 'linear-gradient(to right,#808080,#FCFCFC)'
export const C288 = '#F5FFCB'
export const C289 = '#F5FFCB'
export const C290 = '#E20613'
export const C291 = '#231F20'
export const C292 = '#F5FFCB'
export const C293 = '#0B6623'
export const C294 = '#A1A930'
export const C295 = '#94B28A'
export const C296 = '#EEC732'
export const C297 = '#FCFCFC80'
export const C298 = '#E20613'
export const C299 = '#E20613'
export const C300 = '#FCFCFC99'
export const C301 = '#94B28A'
export const C302 = '#728C69'
export const C303 = '#6E7B3A'
export const C304 = '#FCFCFC'
export const C305 = '#FCFCFC33'
export const C306 = '#87878799'
export const C307 = '#E20613'
export const C308 = '#DCA3CE'
export const C309 = '#E20613'
export const C310 = '#E20613'
export const C311 = '#A1A930'
export const C312 = '#477854'
export const C313 = '#D8EAD2'
export const C314 = '#B9DB98'
export const C315 = '#ADD08B'
export const C316 = '#A2C57E'
export const C317 = '#96B973'
export const C318 = '#89AE64'
export const C319 = '#80A55B'
export const C320 = '#769B52'
export const C321 = '#6B8F47'
export const C322 = '#618340'
export const C323 = '#5C8038'
export const C324 = '#5C8533'
export const C325 = '#52772C'
export const C326 = '#4B6D29'
export const C327 = '#426123'
export const C328 = '#39531F'
export const C329 = '#344D1B'
export const C330 = '#2D4318'
export const C331 = '#233413'
export const C332 = '#1D2C0E'
export const C333 = '#162409'
export const C334 = '#233413'
export const C335 = '#1D2C0E'
export const C336 = '#769B52'
export const C337 = '#6B8F47'
export const C338 = '#618340'
export const C339 = '#5C8038'
export const C340 = '#5C8533'
export const C341 = '#52772C'
export const C342 = '#4B6D29'
export const C343 = '#426123'
export const C344 = '#39531F'
export const C345 = '#344D1B'
export const C346 = '#2D4318'
export const C347 = '#233413'
export const C348 = '#B9DB98'
export const C349 = '#ADD08B'
export const C350 = '#A2C57E'
export const C351 = '#96B973'
export const C352 = '#89AE64'
export const C353 = '#769B52'
export const C354 = '#6B8F47'
export const C355 = '#618340'
export const C356 = '#5C8038'
export const C357 = '#5C8533'
export const C358 = '#52772C'
export const C359 = '#4B6D29'
export const C360 = '#426123'
export const C361 = '#39531F'
export const C362 = '#344D1B'
export const C363 = '#2D4318'
export const C364 = '#233413'
export const C365 = '#B9DB98'
export const C366 = '#ADD08B'
export const C367 = '#A2C57E'
export const C368 = '#96B973'
export const C369 = '#89AE64'
export const C370 = '#769B52'
export const C371 = '#6B8F47'
export const C372 = '#618340'
export const C373 = '#5C8038'
export const C374 = '#5C8533'
export const C375 = '#52772C'
export const C376 = '#4B6D29'
export const C377 = '#426123'
export const C378 = '#39531F'
export const C379 = '#344D1B'
export const C380 = '#2D4318'
export const C381 = '#A2C57E'
export const C382 = '#96B973'
export const C383 = '#89AE64'
export const C384 = '#80A55B'
export const C385 = '#769B52'
export const C386 = '#6B8F47'
export const C387 = '#618340'
export const C388 = '#5C8038'
export const C389 = '#5C8533'
export const C390 = '#52772C'
export const C391 = '#4B6D29'
export const C392 = '#426123'
export const C393 = '#39531F'
export const C394 = '#344D1B'
export const C395 = '#2D4318'
export const C396 = '#A2C57E'
export const C397 = '#96B973'
export const C398 = '#89AE64'
export const C399 = '#80A55B'
export const C400 = '#769B52'
export const C401 = '#6B8F47'
export const C402 = '#618340'
export const C403 = '#5C8038'
export const C404 = '#5C8533'
export const C405 = '#52772C'
export const C406 = '#4B6D29'
export const C407 = '#426123'
export const C408 = '#39531F'
export const C409 = '#344D1B'
export const C410 = '#A2C57E'
export const C411 = '#96B973'
export const C412 = '#89AE64'
export const C413 = '#80A55B'
export const C414 = '#769B52'
export const C415 = '#6B8F47'
export const C416 = '#618340'
export const C417 = '#5C8038'
export const C418 = '#52772C'
export const C419 = '#4B6D29'
export const C420 = '#426123'
export const C421 = '#39531F'
export const C422 = '#344D1B'
export const C423 = '#A2C57E'
export const C424 = '#96B973'
export const C425 = '#89AE64'
export const C426 = '#80A55B'
export const C427 = '#769B52'
export const C428 = '#6B8F47'
export const C429 = '#618340'
export const C430 = '#5C8038'
export const C431 = '#52772C'
export const C432 = '#4B6D29'
export const C433 = '#426123'
export const C434 = '#344D1B'
export const C435 = '#A2C57E'
export const C436 = '#96B973'
export const C437 = '#89AE64'
export const C438 = '#80A55B'
export const C439 = '#769B52'
export const C440 = '#6B8F47'
export const C441 = '#618340'
export const C442 = '#5C8038'
export const C443 = '#52772C'
export const C444 = '#4B6D29'
export const C445 = '#426123'
export const C446 = '#A2C57E'
export const C447 = '#96B973'
export const C448 = '#89AE64'
export const C449 = '#80A55B'
export const C450 = '#769B52'
export const C451 = '#6B8F47'
export const C452 = '#618340'
export const C453 = '#5C8038'
export const C454 = '#4B6D29'
export const C455 = '#426123'
export const C456 = '#A2C57E'
export const C457 = '#96B973'
export const C458 = '#89AE64'
export const C459 = '#80A55B'
export const C460 = '#769B52'
export const C461 = '#6B8F47'
export const C462 = '#618340'
export const C463 = '#5C8038'
export const C464 = '#4B6D29'
export const C465 = '#A2C57E'
export const C466 = '#96B973'
export const C467 = '#89AE64'
export const C468 = '#80A55B'
export const C469 = '#769B52'
export const C470 = '#6B8F47'
export const C471 = '#5C8038'
export const C472 = '#4B6D29'
export const C473 = '#415E49'
export const C474 = '#436A4D'
export const C475 = '#457953'
export const C476 = '#49895B'
export const C477 = '#4C9A62'
export const C478 = '#4BA864'
export const C479 = '#48B566'
export const C480 = '#55C574'
export const C481 = '#231F204D'
export const C482 = '#FCFCFC99'
export const C483 = '#231F20'
export const C484 = '#C9CE85'
export const C485 = '#A1A930'
export const C486 = '#A1A930'
export const C487 = '#0B6623'
export const C488 = '#728C69'
export const C489 = '#9D0606'
export const C490 = '#FFFE00'
export const C491 = 'linear-gradient(to bottom,#A1A930,#596C52)'
export const C492 = '#E20613'
export const C493 = '#E20613'
export const C494 = '#E20613'
export const C495 = '#E20613'
export const C496 = '#E20613'
export const C497 = '#E20613'
export const C498 = '#E20613'
export const C499 = '#009EE2'
export const C500 = '#5D2684'
export const C501 = '#F5FFCBCC'
export const C502 = 'linear-gradient(to right,#F5FFCB,#0B6623)'
export const C503 = 'linear-gradient(to right,#94B28A,#EEF3AD)'
export const C504 = '#0B6623'
export const C505 = '#A1A930'
export const C506 = '#CACBCF'
export const C507 = '#707070'
export const C508 = '#94B28A'
export const C509 = '#728C69'
export const C510 = '#0B6623'
export const C511 = '#EEF3AD4D'
export const C512 = '#BAB6B6'
export const C513 = '#BBBDBF'
export const C514 = '#E20613'
export const C515 = '#7448BC'
export const C516 = '#FCFCFCCC'
export const C517 = '#0B6623'
export const C518 = '#818181'
export const C519 = '#FCFCFCCC'
export const C520 = '#FCFCFCB3'
export const C521 = '#94B28A'
export const C522 = '#F5FFCB'
export const C523 = '#0B6623'
export const C524 = '#F5FFCB'
export const C525 = '#94B28A'
export const C526 = '#F5FFCB'
export const C527 = '#F5FFCBB3'
export const C528 = '#E20613'
export const C529 = '#728C69'
export const C530 = '#728C69'
export const C531 = '#0B6623'
export const C532 = '#FCFCFCCC'
export const C533 = '#F5FFCBCC'
export const C534 = '#728C69'
export const C535 = '#94B28A'
export const C536 = '#D8EAD2'
export const C537 = '#728C69'
export const C538 = '#E20613'
export const C539 = '#E20613'
export const C540 = '#FCFCFC'
export const C541 = '#FCFCFC'
export const C542 = '#FCFCFC26'
export const C543 = '#D8EAD2'
export const C544 = '#D8EAD2'
export const C545 = '#0B6623'
export const C546 = '#94B28A4D'
export const C547 = '#728C69'
export const C548 = '#FCFCFC'
export const C549 = '#F5FFCB'
export const C550 = '#5E8352'
export const C551 = '#2F4A26'
export const C552 = '#D8BD1C'
export const C553 = '#FCFCFC'
export const C554 = '#231F20'
export const C555 = '#0B662345'
export const C556 = '#FCFCFC'
export const C557 = '#FFDD154D'
export const C558 = '#00ADEE4D'
export const C559 = '#37B34A4D'
export const C560 = '#A1A930'
export const C561 = '#0B6623'
export const C562 = 'linear-gradient(to bottom,#063312,#0B6623)'
export const C563 = '#FCFCFC1A'
export const C564 = '#0A4D1C'
export const C565 = '#FCFCFC'
export const C566 = '#0B6623'
export const C567 = 'linear-gradient(to right,#1AA7DB,#067EAA)'
export const C568 = '#0B662380'
export const C569 = '#F5FFCB'
export const C570 = '#0B662380'
export const C571 = '#E98020'
export const C572 = '#F5FFCB'
export const C573 = '#000000'
export const C574 = '#F5FFCB'
export const C575 = '#000000'
export const C576 = '#FCFCFC'
export const C577 = '#000000'
export const C578 = '#F5FFCB'
export const C579 = '#e1f682'
export const C580 = '#FCFCFC'
export const C581 = '#000000'
export const C582 = '#63d581'
export const C583 = '#C3C3C3'
export const C584 = '#DACA85'
export const C585 = '#C4C9CE'
export const C586 = '#E4E5EC'
export const C587 = '#dce0af'
export const C588 = '#587560'
export const C589 = '#4A4A4A'
export const C590 = '#A8B573'
export const C591 = '#C4C9CE'
export const C592 = '#DBEB98'
export const C593 = '#000000'
export const C594 = '#A1A930'
export const C595 = '#717807'
export const C597 = '#E2FE30'
export const C598 = '#F0F6D7'
export const C599 = '#000000'
export const C601 = '#f5ffcb'
export const C602 = '#37B34A'
export const C603 = '#F0F6D7'
export const C604 = '#B3261E'
export const C605 = '#00000080'
export const C606 = '#b7c21b'
export const C607 = '#00000099'
export const C608 = '#A1A930'
export const C609 = '#F5FFCB'
export const C610 = '#A1A930'
export const C611 = '#FFFAEC'
export const C612 = '#21252B'
export const C613 = '#00000080'
export const C614 = '#FCFCFc'


export const MC1 = '#656985B5'
export const MC2 = '#2A2D3E'
export const MC3 = '#231F2040'