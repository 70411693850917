import React, {useContext, useEffect, useRef} from 'react'
import componentStyle from './InstructionTemplate.module.scss'
import ToplineTitle from '../../../../components/ToplineTitle/ToplineTitle'
import classNames from 'classnames'
import structuresContext from "../../../../contexts/StructuresContext";
import {robotTeacherScrollIntoViewByElement} from "../../../../operations/robotTeacherScrollIntoViewByElement";
import {useSelector} from "react-redux";

const MainTitleTemplate = ({template}) => {
  const elRef = useRef(null)
  const {scrolling} = useSelector(state => state.roboTeacher)
  const {isRoboTeacher, roboTeacherData} = useContext(structuresContext)
  const isHidden = (isRoboTeacher ?? false) ? ((roboTeacherData.currentTime ?? 0) < (template?.hiddenTime ?? 0)) : false

  useEffect(() => {
    if (template?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isHidden])

  return (
    <div
      ref={elRef}
      data-rt-id={template?.id}
      className={classNames(componentStyle.container, 'textHolderColors-disabled', {['hide-element']: isHidden})}
    >
      <ToplineTitle titleData={template.data?.title}/>
    </div>
  )
}

export default MainTitleTemplate