import * as React from "react"

const ResetSvg = ({color}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill={color}
  >
    <path
      d="M10 19.335a9.337 9.337 0 0 1-9.334-9.333V9.47H1.73V10A8.225 8.225 0 0 0 4.15 15.86a8.243 8.243 0 0 0 5.857 2.432h.008c4.567 0 8.283-3.71 8.286-8.272.004-4.566-3.712-8.283-8.28-8.287h-.007a8.294 8.294 0 0 0-6.266 2.86.096.096 0 0 0-.015.106.103.103 0 0 0 .092.059h3.251v1.061H1.924V.668h1.062V3.59a.1.1 0 0 0 .173.066c.9-.969 1.968-1.72 3.174-2.233a9.368 9.368 0 0 1 9.67 1.426 9.214 9.214 0 0 1 2.399 3.08 9.34 9.34 0 0 1 .921 3.652 9.295 9.295 0 0 1-2.69 7.001A9.291 9.291 0 0 1 10 19.335Z"
    />
  </svg>
)

export default ResetSvg
