import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import colorsContext from "../../contexts/ColorsContext";
import {OverlayTrigger, Popover} from "react-bootstrap";
import componentStyle from './RoboTeacherIcon.module.scss'
import useAppUser from "../../hooks/useAppUser";
import {updateRoboTeacherCurrentDataStatus} from "../../redux/features/roboTeacherSlice";
import {useDispatch} from "react-redux";
import {ENGLISH, NATIVE} from "../../enums/roboTeacher/dataStatusEnums";

const RoboTeacherIcon = ({unit}) => {
  const navigate = useNavigate()
  const {colors} = useContext(colorsContext)
  const {appUserData} = useAppUser()
  const dispatch = useDispatch()

  const popover = (
    <Popover className='colorpalette-popover' style={{background: colors['C49'], borderColor: colors['C49'], zIndex:'2'}}>
      <Popover.Body>
        <ul>
          <li>
            <button
              onClick={() => {
                dispatch(updateRoboTeacherCurrentDataStatus(ENGLISH))
                navigate('/sections/roboTeacher/' + unit)
              }}
              className='word-title'
              style={{color: colors['C23']}}
            >
              <img
                className={componentStyle.flag}
                src={`${window.location.origin}/assets/flags/gb.svg`}
                alt=""
              />
              English
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                dispatch(updateRoboTeacherCurrentDataStatus(NATIVE))
                navigate('/sections/roboTeacher/' + unit)
              }}
              className='word-title'
              style={{color: colors['C23']}}
            >
              <img
                className={componentStyle.flag}
                src={`${window.location.origin}/assets/images/languages/${appUserData?.setting?.nativeLanguageFlagKey}.svg`}
                alt=""
              />
              {appUserData?.setting?.nativeLanguageTitle}
            </button>
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  )

  return (
    <OverlayTrigger
      rootClose
      trigger='click'
      placement='bottom'
      overlay={popover}
    >
      <button id='robo_teacher_button' className='icon-robot-teacher'>
        <img src={`${window.location.origin}/assets/images/headRoboTeacher.png`} alt=''/>
      </button>
    </OverlayTrigger>
  )
}

export default RoboTeacherIcon