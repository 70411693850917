import * as React from "react"

const VideoSvg = ({color}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
       width={35.6}
       height={35.2}
       viewBox='0 0 35.6 35.2'
       fill="none"
  >
      <path
        fill={color}
        d="M35.021 9.187h-1.723a.574.574 0 0 0-.574.573v.31l-3.657 3.135h-3.806v-3.158a2.012 2.012 0 0 0-2.01-2.01H11.264L9.54 4.594h4.239v.574a.574.574 0 0 0 .573.574h4.594a2.87 2.87 0 1 0 0-5.741h-4.594a.574.574 0 0 0-.573.574v.574h-7.47a.575.575 0 0 0-.405.168L3.777 3.444H2.005A2.012 2.012 0 0 0 0 5.456v18.946a2.012 2.012 0 0 0 2.01 2.009h6.028v1.536l-4.482 6.164a.574.574 0 0 0 .465.913h2.296a.574.574 0 0 0 .465-.237l4.129-5.677v5.339a.574.574 0 0 0 .574.573h2.296a.574.574 0 0 0 .574-.573v-5.34l4.129 5.678a.575.575 0 0 0 .464.237h2.297a.574.574 0 0 0 .464-.913l-4.481-6.164v-1.536h6.029a2.012 2.012 0 0 0 2.009-2.01v-3.157h3.806l3.658 3.134v.31a.574.574 0 0 0 .573.574h1.723a.574.574 0 0 0 .574-.574V9.762a.574.574 0 0 0-.574-.574l-.005-.001ZM14.928 1.149h4.02a1.723 1.723 0 0 1 0 3.444h-4.02V1.15ZM6.554 2.297h7.22v1.147H5.4l1.154-1.147ZM1.149 5.455a.861.861 0 0 1 .861-.862h6.248l1.723 3.445H1.149V5.455Zm4.875 28.419h-.872l3.758-5.168h.872l-3.758 5.168Zm7.18 0h-1.147v-5.168h1.148v5.168Zm-4.017-6.315V26.41h6.89v1.148h-6.89Zm10.929 6.314h-.871l-3.759-5.167h.872l3.758 5.168Zm3.998-9.472a.861.861 0 0 1-.861.861H2.009a.861.861 0 0 1-.861-.861V9.187h22.104a.861.861 0 0 1 .861.861l.001 14.353Zm4.593-4.306h-3.444v-5.741h3.444v5.74Zm4.019 2.77-2.87-2.46v-6.361l2.87-2.461v11.283Zm1.723 1.245h-.574V10.336h.574V24.11Z"
      />
      <path
        fill={color}
        d="M3.447 9.758a1.722 1.722 0 1 0 0 3.445 1.722 1.722 0 0 0 0-3.445Zm0 2.296a.574.574 0 1 1 0-1.148.574.574 0 0 1 0 1.148ZM9.185 11.48a1.723 1.723 0 1 0-3.446 0 1.723 1.723 0 0 0 3.446 0Zm-2.297 0a.574.574 0 1 1 1.148 0 .574.574 0 0 1-1.148 0ZM18.373 13.777H6.891a.574.574 0 0 0-.574.574v5.741a.574.574 0 0 0 .574.574h11.482a.574.574 0 0 0 .574-.574v-5.74a.574.574 0 0 0-.574-.575Zm-.574 5.741H7.468v-4.593h10.331v4.593Z"
      />
  </svg>
)

export default VideoSvg
