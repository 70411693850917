import { getRepeatNumberByStudyStatus, studyTypeEnum} from "../../../enums/userStudyEnums/userStudyEnums";
import studyTypeIsRepeat from "../../../operations/studyTypeIsRepeat";
import getHumanReadableDate from "../../../operations/getHumanReadableDate";
import {secondsToDHM} from "../../../util/utility";

const todayStudyPlanMapper = (data, baseData) => {
  const getStudyType = (studyStatus) => {
    if (studyStatus === studyTypeEnum.NEW) {
      return 'new'
    } else if (studyTypeIsRepeat(studyStatus)) {
      return 'repeat'
    } else if (studyStatus === studyTypeEnum.REVIEW) {
      return 'review'
    } else {
      return 'new'
    }
  }

  let studyPlanDetails = data?.studyPlanDetails?.map(it => {
    return {
      id: `${it.unitId}-${it.studyType}`,
      studyType: getStudyType(it.studyType),
      studyStatus: it.studyType,
      unitId: it.unitId,
      title: it.unitTitle,
      description: it.unitDescription,
      startDate: getHumanReadableDate(it.startDate, "MMM DD, YYYY - hh:mm"),
      finishDate: getHumanReadableDate(it.finishDate, "MMM DD, YYYY - hh:mm"),
      duration: secondsToDHM(it.duration),
      repeatNumber: getRepeatNumberByStudyStatus(it.studyType),
      timeState: it.timeState,
      isFinished: it.isFinished,
      isInProgress: it.isInProgress
    }
  }) ?? []

  if (baseData?.length > 0 && baseData[baseData.length - 1]?.isLoadMore) {
    baseData = [...baseData].slice(0, -1)
  }

  let plans = [...baseData, ...studyPlanDetails]

  if (data?.hasNext && plans?.length > 0) {
    plans.push({isLoadMore: true})
  }

  return {
    plans,
    hasNext: data?.hasNext
  }
}

export default todayStudyPlanMapper