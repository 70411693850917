export const C1 = '#231F20CC'
export const C2 = '#E4E5EC'
export const C3 = '#3D4158'
export const C4 = '#3D4158'
export const C5 = '#3D4158'
export const C6 = '#3D4158'
export const C7 = '#3D4158'
export const C8 = '#3D4158'
export const C9 = '#3D4158'
export const C10 = '#3D4158'
export const C11 = '#3D4158'
export const C12 = '#2A2D3E'
export const C13 = '#3D4158'
export const C14 = '#3D4158'
export const C15 = '#3D4158'
export const C16 = '#BFC932'
export const C17 = '#FFDD15'
export const C18 = '#FF8800'
export const C19 = '#E4E5EC'
export const C20 = '#2A2D3E'
export const C21 = '#FCFCFC4D'
export const C22 = '#FCFCFCCC'
export const C23 = '#E4E5EC'
export const C24 = '#21B3D0'
export const C25 = '#007991'
export const C26 = '#FCFCFC4D'
export const C27 = '#D0D2D3'
export const C28 = '#A2A1A1'
export const C29 = '#E20613'
export const C30 = '#1b982f '
export const C31 = '#11B2D2'
export const C32 = '#2A2D3E80'
export const C33 = '#E4E5ECCC'
export const C34 = '#706F6F'
export const C35 = '#3D415866'
export const C36 = '#007991'
export const C37 = '#2E4A66'
export const C38 = '#E4E5EC61'
export const C39 = '#FCFCFC4D'
export const C40 = '#3D4158'
export const C41 = '#11B2D2'
export const C42 = '#656985'
export const C43 = '#FCFCFC'
export const C44 = '#FCFCFC'
export const C45 = '#2A2D3EB3'
export const C46 = '#656985'
export const C47 = '#00FFFF'
export const C48 = '#B3B9E6'
export const C49 = '#3D4158'
export const C50 = '#EB008B'
export const C51 = '#2A2D3E'
export const C52 = '#656985'
export const C53 = '#3D4158'
export const C54 = '#3D4158'
export const C55 = '#007991'
export const C56 = '#656985'
export const C57 = 'linear-gradient(to right,#7C0EFF,#4F78FD,#09DEF8)'
export const C58 = '#09DEF8'
export const C59 = '#E1BD47'
export const C60 = '#007991'
export const C61 = 'linear-gradient(to bottom, #007991,#2E4A66,#007991)'
export const C62 = '#3D4158'
export const C63 = '#4E526B'
export const C64 = '#B1B1B1'
export const C65 = '#656985'
export const C66 = '#11B2D2'
export const C67 = 'linear-gradient(to bottom, #FCFCFC,#D0D2D3)'
export const C68 = '#656985'
export const C69 = '#878787'
export const C70 = '#b2b3b6'
export const C71 = 'linear-gradient(to right,#37B34A,#E20613)'
export const C72 = '#3D4158'
export const C73 = '#7A7D96'
export const C74 = '#2A2D3E'
export const C75 = '#656985'
export const C76 = '#464A61'
export const C77 = '#3D4158'
export const C78 = '#4E526B'
export const C79 = '#2A2D3E'
export const C80 = '#383A42'
export const C81 = '#9598AD'
export const C82 = '#272935'
export const C83 = '#313135'
export const C84 = '#656985'
export const C85 = '#15192E'
export const C86 = '#32354A'
export const C87 = '#A8A6B3'
export const C88 = '#666576'
export const C89 = '#555772'
export const C90 = '#B6B5C0'
export const C91 = '#191A25'
export const C92 = '#A2A4AC'
export const C93 = '#939393'
export const C94 = '#9194A8'
export const C95 = '#484A5A'
export const C96 = '#56596D'
export const C97 = '#383A46'
export const C98 = '#191B24'
export const C99 = '#8386A0'
export const C100 = '#48CDF7'
export const C101 = '#E4E5EC'
export const C102 = '#656985'
export const C103 = '#656985'
export const C104 = 'linear-gradient(to bottom, #D2CDE7,#696774)'
export const C105 = '#666983'
export const C106 = 'linear-gradient(to right,#B1B1B1,#FCFCFC)'
export const C107 = '#2A2D3E'
export const C108 = '#656985CF'
export const C109 = '#231F2080'
export const C110 = '#656985'
export const C111 = '#1C4E6B'
export const C112 = '#919191'
export const C113 = '#0D0C07'
export const C114 = '#B4C2C3'
export const C115 = '#F4EEE2'
export const C116 = '#FFFFCB'
export const C117 = '#EFECDE'
export const C118 = '#669ACC'
export const C119 = '#659EB9'
export const C120 = '#986665'
export const C121 = '#9A7E4E'
export const C122 = '#CC9866'
export const C123 = '#131A22'
export const C124 = '#3B3B3B'
export const C125 = '#030303'
export const C126 = '#666666'
export const C127 = '#616161'
export const C128 = '#D9C39A'
export const C129 = '#A69370'
export const C130 = '#979832'
export const C131 = '#A58C4C'
export const C132 = '#9D9686'
export const C133 = '#C3943B'
export const C134 = '#336667'
export const C135 = '#0C0E1A'
export const C136 = '#656700'
export const C137 = '#5D5115'
export const C138 = '#C0A93F'
export const C139 = '#D4D4D2'
export const C140 = '#A6A5A1'
export const C141 = '#8385B9'
export const C142 = '#656985'
export const C143 = '#FCFCFC80'
export const C144 = '#FCFCFCCC'
export const C145 = '#3D4158'
export const C146 = '#39A93580'
export const C147 = '#868686'
export const C148 = '#3D4158'
export const C149 = '#E4E5EC'
export const C150 = '#191a23'
export const C151 = '#FCFCFC'
export const C152 = '#FCFCFC'
export const C153 = '#FCFCFC'
export const C154 = '#656985'
export const C155 = '#3D4158'
export const C156 = 'linear-gradient(to right,#5BC5F1,#F8B133)'
export const C157 = '#2A2D3E'
export const C158 = '#9295a6'
export const C159 = '#007991'
export const C160 = '#E4E5EC'
export const C161 = '#2A2D3E'
export const C162 = '#2A2D3E'
export const C163 = '#2A2D3E'
export const C164 = '#2A2D3E'
export const C165 = '#2A2D3E'
export const C166 = '#2A2D3E'
export const C167 = '#E4E5EC'
export const C168 = '#FCFCFC4D'
export const C169 = 'radial-gradient(#2A2D3E,#303346,#606688)'
export const C170 = '#FCFCFC80'
export const C171 = 'linear-gradient(to right,#D0D2D3,#FCFCFC)'
export const C172 = '#44495EB3'
export const C173 = '#706F6F'
export const C174 = '#2A2D3E'
export const C175 = '#E4E5EC'
export const C176 = '#2A2D3E'
export const C177 = '#585A6BFF'
export const C178 = '#E4E5EC80'
export const C179 = '#E4E5EC'
export const C180 = '#3D4158'
export const C181 = '#2A2D3E'
export const C182 = '#FCFCFC33'
export const C183 = '#2A2D3E'
export const C184 = '#3D4158'
export const C185 = '#464A61'
export const C186 = '#2A2D3E'
export const C187 = '#E4E5EC'
export const C188 = '#656985'
export const C189 = '#11B2D24D'
export const C190 = '#3D4158'
export const C191 = '#E4E5EC'
export const C192 = '#3D4158CC'
export const C193 = '#464A61'
export const C194 = '#3D4158'
export const C195 = '#B3B9E6'
export const C196 = '#464A61'
export const C197 = '#FCFCFC4D'
export const C198 = '#B3B9E6'
export const C199 = '#3D4158'
export const C200 = '#3D4158'
export const C201 = '#7c8285'
export const C202 = '#E4E5EC'
export const C203 = '#3D415880'
export const C204 = '#E4E5EC80'
export const C205 = '#3D4158'
export const C206 = '#3D4158'
export const C207 = '#2A2D3E'
export const C208 = '#3D4158'
export const C209 = '#E4E5EC'
export const C210 = '#464A61'
export const C211 = '#464A61'
export const C212 = '#464A61'
export const C213 = '#E4E5EC'
export const C214 = '#E4E5EC99'
export const C215 = '#7C0EFF'
export const C216 = '#DDC136'
export const C217 = '#E4E5EC'
export const C218 = '#E4E5EC80'
export const C219 = '#231F20'
export const C220 = '#2A2D3E'
export const C221 = '#D0D2D3'
export const C222 = '#E4E5EC40'
export const C223 = '#E4E5EC'
export const C224 = '#11B2D2'
export const C225 = '#11B2D2'
export const C226 = '#E1E1E1'
export const C227 = '#3D4158'
export const C228 = '#bfc1c7'
export const C229 = '#007991'
export const C230 = '#FCFCFC'
export const C231 = '#3D4158'
export const C232 = '#656985'
export const C233 = '#231F20'
export const C234 = '#4b5069'
export const C235 = '#656985'
export const C236 = '#525671'
export const C237 = '#11B2D2'
export const C238 = '#3D4158'
export const C239 = '#3D4158'
export const C240 = '#3D4158'
export const C241 = '#FCFCFCB3'
export const C242 = '#E4E5EC'
export const C243 = '#6C66AF'
export const C244 = '#E4E5EC'
export const C245 = '#E7E8F4'
export const C246 = '#E7E8F4'
export const C247 = '#FCFCFCB3'
export const C248 = '#CCB058'
export const C249 = '#B0B0B0'
export const C250 = '#BB9090'
export const C251 = '#638088'
export const C252 = '#545F83'
export const C253 = '#3D4158'
export const C254 = '#656985'
export const C255 = '#2A2D3E'
export const C256 = '#11B2D2'
export const C257 = '#6569854D'
export const C258 = '#3D4158'
export const C259 = '#2A2D3E1A'
export const C260 = '#FCFCFC66'
export const C261 = '#656985'
export const C262 = '#8BC53F'
export const C263 = '#A6A9AB'
export const C264 = '#656985'
export const C265 = '#65698566'
export const C266 = '#11B2D2'
export const C267 = '#2A2D3E'
export const C268 = '#007991'
export const C269 = '#3D4158'
export const C270 = '#424767'
export const C271 = '#C4679E'
export const C272 = '#00ADEE'
export const C273 = '#C2C73B'
export const C274 = '#D3D3D3'
export const C275 = '#3D415873'
export const C276 = '#11B2D2'
export const C277 = '#2A2D3EA8'
export const C278 = '#4E526B'
export const C279 = '#C02EF2'
export const C280 = '#A78233'
export const C281 = '#7D4848'
export const C282 = '#638088'
export const C283 = '#262261'
export const C284 = '#3D4158'
export const C285 = '#FCFCFC33'
export const C286 = '#2A2D3E'
export const C287 = 'linear-gradient(to right,#808080,#FCFCFC)'
export const C288 = '#11B2D2'
export const C289 = '#FCFCFC66'
export const C290 = '#FCFCFC80'
export const C291 = '#FCFCFC'
export const C292 = '#11B2D2'
export const C293 = '#3D4158'
export const C294 = '#11B2D2'
export const C295 = '#2A2D3E'
export const C296 = '#EEC732'
export const C297 = '#E4E5EC80'
export const C298 = '#3D4158'
export const C299 = '#3D4158'
export const C300 = '#FCFCFC99'
export const C301 = '#3A3E5C'
export const C302 = '#3D4158'
export const C303 = '#4E526B'
export const C304 = '#FCFCFC66'
export const C305 = '#FCFCFC33'
export const C306 = '#87878799'
export const C307 = '#87878733'
export const C308 = '#DCA3CE'
export const C309 = '#656985'
export const C310 = '#F9AE08'
export const C311 = '#656985'
export const C312 = '#696f8f'
export const C313 = '#231F20'
export const C314 = '#ADB5E4'
export const C315 = '#9FA7D6'
export const C316 = '#979ECB'
export const C317 = '#8D94BE'
export const C318 = '#838AB2'
export const C319 = '#7980A6'
export const C320 = '#72789B'
export const C321 = '#696E8F'
export const C322 = '#5D6281'
export const C323 = '#555A76'
export const C324 = '#4A4E67'
export const C325 = '#41445A'
export const C326 = '#373A4D'
export const C327 = '#2D3040'
export const C328 = '#232532'
export const C329 = '#1A1C26'
export const C330 = '#13151C'
export const C331 = '#0F1117'
export const C332 = '#0C0D13'
export const C333 = '#0A0A0A'
export const C334 = '#0F1117'
export const C335 = '#0C0D13'
export const C336 = '#696E8F'
export const C337 = '#5D6281'
export const C338 = '#555A76'
export const C339 = '#4A4E67'
export const C340 = '#41445A'
export const C341 = '#373A4D'
export const C342 = '#2D3040'
export const C343 = '#232532'
export const C344 = '#1A1C26'
export const C345 = '#13151C'
export const C346 = '#0F1117'
export const C347 = '#0C0D13'
export const C348 = '#ADB5E4'
export const C349 = '#9FA7D6'
export const C350 = '#979ECB'
export const C351 = '#8D94BE'
export const C352 = '#7980A6'
export const C353 = '#696E8F'
export const C354 = '#5D6281'
export const C355 = '#555A76'
export const C356 = '#4A4E67'
export const C357 = '#41445A'
export const C358 = '#373A4D'
export const C359 = '#2D3040'
export const C360 = '#232532'
export const C361 = '#1A1C26'
export const C362 = '#13151C'
export const C363 = '#0F1117'
export const C364 = '#0C0D13'
export const C365 = '#ADB5E4'
export const C366 = '#9FA7D6'
export const C367 = '#979ECB'
export const C368 = '#8D94BE'
export const C369 = '#696E8F'
export const C370 = '#5D6281'
export const C371 = '#555A76'
export const C372 = '#4A4E67'
export const C373 = '#41445A'
export const C374 = '#373A4D'
export const C375 = '#2D3040'
export const C376 = '#232532'
export const C377 = '#1A1C26'
export const C378 = '#13151C'
export const C379 = '#0F1117'
export const C380 = '#0C0D13'
export const C381 = '#ADB5E4'
export const C382 = '#9FA7D6'
export const C383 = '#979ECB'
export const C384 = '#696E8F'
export const C385 = '#5D6281'
export const C386 = '#555A76'
export const C387 = '#4A4E67'
export const C388 = '#41445A'
export const C389 = '#373A4D'
export const C390 = '#2D3040'
export const C391 = '#232532'
export const C392 = '#1A1C26'
export const C393 = '#13151C'
export const C394 = '#0F1117'
export const C395 = '#0C0D13'
export const C396 = '#ADB5E4'
export const C397 = '#9FA7D6'
export const C398 = '#979ECB'
export const C399 = '#696E8F'
export const C400 = '#5D6281'
export const C401 = '#555A76'
export const C402 = '#4A4E67'
export const C403 = '#41445A'
export const C404 = '#2D3040'
export const C405 = '#232532'
export const C406 = '#1A1C26'
export const C407 = '#13151C'
export const C408 = '#0F1117'
export const C409 = '#0C0D13'
export const C410 = '#9FA7D6'
export const C411 = '#979ECB'
export const C412 = '#696E8F'
export const C413 = '#5D6281'
export const C414 = '#555A76'
export const C415 = '#4A4E67'
export const C416 = '#41445A'
export const C417 = '#2D3040'
export const C418 = '#232532'
export const C419 = '#1A1C26'
export const C420 = '#13151C'
export const C421 = '#0F1117'
export const C422 = '#0C0D13'
export const C423 = '#9FA7D6'
export const C424 = '#979ECB'
export const C425 = '#696E8F'
export const C426 = '#5D6281'
export const C427 = '#555A76'
export const C428 = '#4A4E67'
export const C429 = '#41445A'
export const C430 = '#2D3040'
export const C431 = '#232532'
export const C432 = '#1A1C26'
export const C433 = '#13151C'
export const C434 = '#0F1117'
export const C435 = '#9FA7D6'
export const C436 = '#979ECB'
export const C437 = '#696E8F'
export const C438 = '#5D6281'
export const C439 = '#4A4E67'
export const C440 = '#41445A'
export const C441 = '#2D3040'
export const C442 = '#232532'
export const C443 = '#1A1C26'
export const C444 = '#13151C'
export const C445 = '#0F1117'
export const C446 = '#979ECB'
export const C447 = '#696E8F'
export const C448 = '#5D6281'
export const C449 = '#4A4E67'
export const C450 = '#41445A'
export const C451 = '#2D3040'
export const C452 = '#232532'
export const C453 = '#1A1C26'
export const C454 = '#13151C'
export const C455 = '#0F1117'
export const C456 = '#696E8F'
export const C457 = '#5D6281'
export const C458 = '#4A4E67'
export const C459 = '#41445A'
export const C460 = '#2D3040'
export const C461 = '#232532'
export const C462 = '#1A1C26'
export const C463 = '#13151C'
export const C464 = '#0F1117'
export const C465 = '#696E8F'
export const C466 = '#5D6281'
export const C467 = '#4A4E67'
export const C468 = '#41445A'
export const C469 = '#2D3040'
export const C470 = '#232532'
export const C471 = '#1A1C26'
export const C472 = '#13151C'
export const C473 = '#797FA8'
export const C474 = '#6E749A'
export const C475 = '#63688A'
export const C476 = '#555977'
export const C477 = '#484C67'
export const C478 = '#3C3F56'
export const C479 = '#303347'
export const C480 = '#242635'
export const C481 = '#FCFCFC4D'
export const C482 = '#FCFCFC99'
export const C483 = '#231F20'
export const C484 = '#E4E5EC'
export const C485 = '#2A2D3E'
export const C486 = '#656985'
export const C487 = '#3D4158'
export const C488 = '#656985'
export const C489 = '#9D0606'
export const C490 = '#090a0c'
export const C491 = 'linear-gradient(to bottom, #656985,#2A2D3E)'
export const C492 = '#A8149D'
export const C493 = '#A19C06'
export const C494 = '#A56FE6'
export const C495 = '#AC618F'
export const C496 = '#2697AE'
export const C497 = '#16BFE0'
export const C498 = '#93C01F'
export const C499 = '#009EE2'
export const C500 = '#5D2684'
export const C501 = '#3D4158'
export const C502 = 'linear-gradient(to right,#656985,#11B2D2)'
export const C503 = 'linear-gradient(to right,#11B2D2,#656985)'
export const C504 = '#656985'
export const C505 = '#E7E8F4'
export const C506 = '#CACBCF'
export const C507 = '#707070'
export const C508 = '#3D4158'
export const C509 = '#656985'
export const C510 = '#656985'
export const C511 = '#E4E5EC4D'
export const C512 = '#BAB6B6'
export const C513 = '#BBBDBF'
export const C514 = '#231F207A'
export const C515 = '#7448BC'
export const C516 = '#FCFCFCCC'
export const C517 = '#2A2D3E'
export const C518 = '#818181'
export const C519 = '#2A2D3E'
export const C520 = '#3D4158'
export const C521 = '#3D4158'
export const C522 = '#4E526B'
export const C523 = '#E7E8F4'
export const C524 = '#3D4158'
export const C525 = '#656985'
export const C526 = '#464A61'
export const C527 = '#3D4158'
export const C528 = '#3D415880'
export const C529 = '#007991'
export const C530 = '#11B2D2'
export const C531 = '#11B2D2'
export const C532 = '#656985'
export const C533 = '#656985'
export const C534 = '#2A2D3E'
export const C535 = '#5b6080'
export const C536 = '#656985'
export const C537 = '#656985'
export const C538 = '#656985DE'
export const C539 = '#BBBDC0'
export const C540 = '#E4E5EC'
export const C541 = '#FCFCFC'
export const C542 = '#FCFCFC26'
export const C543 = '#2A2D3E'
export const C544 = '#656985'
export const C545 = '#656985CC'
export const C546 = '#2A2D3E'
export const C547 = '#11B2D2'
export const C548 = '#656985'
export const C549 = '#3D4158'
// export const C550 = '#231F20'
export const C550 = '#7A7A7A'
export const C551 = '#A6A8AB'
export const C552 = '#D8BD1C'
export const C553 = '#231F20'
export const C554 = '#FCFCFC'
export const C555 = '#00799145'
export const C556 = '#FCFCFC'
export const C557 = '#FFDD154D'
export const C558 = '#00ADEE4D'
export const C559 = '#37B34A4D'
export const C560 = '#3D4158'
export const C561 = '#656985'
export const C562 = 'linear-gradient(to bottom,#2E303E,#5C607B)'
export const C563 = '#FCFCFC1A'
export const C564 = '#11B2D2'
export const C565 = '#E4E5EC'
export const C566 = '#7E8194'

export const C567 = 'linear-gradient(to right,#1AA7DB,#067EAA)'
// export const C567 =	'#E98020'
export const C568 = '#4E526B'
export const C569 = '#3D4158'
export const C570 = '#FCFCFC'
export const C571 = '#E98020'
export const C572 = '#FCFCFC'
export const C573 = '#FCFCFC'
export const C574 = '#656985FF'
export const C575 = '#FCFCFC'
export const C576 = '#FCFCFC'
export const C577 = '#FCFCFC'
export const C578 = '#FFFFFFC4'
export const C579 = '#d2beb4'
export const C580 = '#FCFCFC'
export const C581 = '#FCFCFC'
export const C582 = '#2e2f3f'
export const C583 = '#C3C3C3'
export const C584 = '#DACA85'
export const C585 = '#C4C9CE'
export const C586 = '#E4E5EC'
export const C587 = '#7A7272CC'
export const C588 = '#2A2D3E'
export const C589 = '#e1deef'
export const C590 = '#A4A9C9'
export const C591 = '#C4C9CE'
export const C592 = '#C4C8E2'
export const C593 = '#FCFCFC'
export const C594 = '#3D4158'
export const C595 = '#007991'
export const C596 = '#E4E5EC'
export const C597 = '#EDEDED'
export const C598 = '#B6B9CC'
export const C599 = '#000000'
export const C600 = '#575756'
export const C601 = '#7A7D96'
export const C602 = '#37B34A'
export const C603 = '#7A7D96'
export const C604 = '#B3261E'
export const C605 = '#00000080'
export const C606 = '#757db4'
export const C607 = '#00000099'
export const C608 = '#3D4158'
export const C609 = '#7A7D96'
export const C610 = '#3D4158'
export const C611 = '#FFFAEC'
export const C612 = '#21252B'
export const C613 = '#9999A2FF'
export const C614 = '#FCFCFC'


export const MC1 = '#656985B5'
export const MC2 = '#2A2D3E'
export const MC3 = '#231F2040'