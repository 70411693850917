import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WifiCrossedOutSvg from "../../../icons/WifiCrossedOutSvg";
import style from './networkStatus.module.scss'
import WifiSvg from "../../../icons/WifiSvg";
import PageLayer from "../../../components/PageLayer/PageLayer";

const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(true)
  const OfflineIcon = () => {
    return (
      <div className={style.textAndIcon}>
        <div>
          <WifiCrossedOutSvg svgColor={'#FCFCFC'} backLineColor={'#e74c3c'}/>
        </div>
        <span>No internet connection!</span>
      </div>
    )
  }

  const OnlineIcon = () => {
    return (
      <div className={style.textAndIcon}>
        <div>
          <WifiSvg color={'#000000'}/>
        </div>
        <span> You are back online!</span>
      </div>
    )
  }

  useEffect(() => {
    // Notify when offline

    const handleOffline = () => {
      setIsOnline(false)
      toast.error(<OfflineIcon/>, {
        position: "bottom-center",
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        theme: "colored",
        draggable: false,
        icon: false
      })
    }


    // Notify when back online
    const handleOnline = () => {
      setIsOnline(true)
      toast.dismiss(); // Close the offline toast
      toast.success(<OnlineIcon/>, {
        position: "bottom-center",
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        icon: false
      })
    }


    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [])

  return (
    <>
      {!isOnline &&
        <PageLayer background="#231f2069"/>
      }
    </>
  )
}

export default NetworkStatus
