import { isEmpty } from "lodash"

const nativeDictionaryMapper = (data) => {
  return {
    word: data?.[0]?.word,
    phonetic: data?.[0]?.phonetic,
    meanings: data?.map(item => {
      return {
        partOfSpeech: item?.meanings?.partOfSpeech,
        definitions: [...item?.meanings?.definitions]?.map((definition) => {
          return { definition: definition }
        }),
        antonyms: item?.meanings?.antonyms?.filter(it => !isEmpty(it)),
        synonyms: item?.meanings?.synonyms?.filter(it => !isEmpty(it)),
        plural: item?.meanings?.plural,
        base: item?.meanings?.base,
        past: item?.meanings?.past,
        pastParticiple: item?.meanings?.pastParticiple,
        thirdPerson: item?.meanings?.thirdPerson,
        syllable: item?.meanings?.syllable,
        ingForm: item?.meanings?.ingForm,
        nounForm: item?.meanings?.nounForm,
        adverbForm: item?.meanings?.adverbForm,
        adjectiveForm: item?.meanings?.adjectiveForm,
      }
    }),
  }
}

export default nativeDictionaryMapper