import * as React from "react"

const SpellingSvg = ({color}) => (
    <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style type="text/css">
            {
                ".SpellingSvg-st0{fill:none;stroke-width:36.3455;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"
            }
        </style>
        <g>
            <polyline className="SpellingSvg-st0" stroke={color} points="19.3,319.2 88.3,132.3 155.4,320.1  " />
            <line className="SpellingSvg-st0" stroke={color} x1={52.6} y1={255} x2={120} y2={255} />
        </g>
        <line className="SpellingSvg-st0" stroke={color} x1={204.7} y1={313.2} x2={204.7} y2={135.7} />
        <circle className="SpellingSvg-st0" stroke={color} cx={268} cy={258.8} r={59.3} />
        <path
            className="SpellingSvg-st0" stroke={color}
            d="M494.2,214c-2.3-2.9-24-28.7-56.7-26.6c-32.6,2.1-60.3,31-61.7,65.2c-1.3,33,22.2,63.7,54,69.6 c34.9,6.4,60.6-20.5,62.3-22.4"
        />
        <line className="SpellingSvg-st0" stroke={color} x1={380} y1={372.1} x2={489.5} y2={372.1} />
    </svg>
)

export default SpellingSvg
