import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  currentOpenModal: null,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setCurrentOpenModal: (state, action) => {
      state.currentOpenModal = action.payload
    }
  }
})

export const { setCurrentOpenModal } = modalSlice.actions

export default modalSlice.reducer