import Modal from "../Modal"
import InputModalStyles from "./InputModal.module.scss"
import {useEffect, useState} from "react"
import {isArray} from "lodash"

const InputModal = ({inputList, closeModal, onSave}) => {
  const [inputData, setInputData] = useState([])

  useEffect(() => {
    if (inputList && isArray(inputList)) {
      setInputData(inputList)
    }
  }, [])

  const onChangeInput = (event, inputIndex) => {
    setInputData(oldState => [...oldState].map((item, i) => {
      if (i === inputIndex) {
        return {
          ...item,
          value: event.target.value
        }
      } else {
        return item
      }
    }))
  }

  const renderInputs = () => {
    if (inputData.length > 0) {
      return inputList.map((input, i) => {
        return (
          <div className={InputModalStyles.inputGroup} key={i}>
            <label
              htmlFor=""
              className={InputModalStyles.label}
              style={{color: "#707070"}}
            >
              {input.label}
            </label>
            <input
              className={InputModalStyles.input}
              id={`input_modal_id_${i}`}
              value={inputData[i].value}
              onChange={(e) => onChangeInput(e, i)}
              style={{borderColor: "#707070"}}
            />
          </div>
        )
      })
    }
  }

  return (
    <Modal closeModal={closeModal}>
      <div className="modal-type-2" style={{backgroundColor: "#DBDEF1"}}>
        <div className={InputModalStyles.modalInner}>
          {renderInputs()}
          <div className={InputModalStyles.buttonsContainer}>
            <button
              onClick={closeModal}
              style={{color: "#821BDB"}}
              className={InputModalStyles.button}>
              Cancel
            </button>
            <button
              onClick={() => onSave(inputData)}
              style={{color: "#821BDB"}}
              className={InputModalStyles.button}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default InputModal