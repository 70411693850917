import Modal from "../../../components/Modal"
import HelpVideoModalContent from "../../molecules/HelpVideoModalContent/HelpVideoModalContent"

const HelpVideoDesktop = ({close, videos}) => {
  return (
    <Modal isCenter closeModal={close}>
      <div className='pb-3'>
        <HelpVideoModalContent videos={videos}/>
      </div>
    </Modal>
  )
}

export default HelpVideoDesktop