import { unitStatusEnums } from '../../../enums/levelUnitEnums/levelUnitEnums'

const sectionsMapper = (data) => {
  data = [...data].map(it => {
    if (it.sectionType === 'WRITING_EXERCISE_11') {
      return {
        ...it,
        isExercise: true
      }
    }
    return it
  })

  return data.map((item) => {
    return {
      sectionType: item.sectionType,
      isFinished: item.isFinished,
      isEligibleToPass:
        item.sectionType === 'REVIEW_THE_MISTAKES'
          ? item.isFinished && data?.filter(it => it.isExercise)?.every(it => it.isFinished)
          : item.isFinished || (item.isExercise && item.correctPercentage > 0 && !item.isSkipped),
      isEligibleToPassFromExercisePage:
        item.sectionType === 'REVIEW_THE_MISTAKES'
          ? data?.filter(it => it.isExercise)?.every(it => it.correctPercentage >= 100)
          : (!item.isExercise && item.isFinished) || (item.isExercise && item.correctPercentage > 0 && !item.isSkipped),
      studyProgressStatus: item.isFinished ? unitStatusEnums.DONE : (item.isInProgress ? unitStatusEnums.ON_GOING : unitStatusEnums.NOT_START),
    }
  })
}

export default sectionsMapper