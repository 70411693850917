import './util/extenstions'
sessionStorage.removeItem('cacheList')

console._log =  (...message) => {
  if (process.env.NODE_ENV === 'development') {
    console.info('%c systemLog:', 'background: #202124; color: #bada55', ...message)
  }
}
console._info =  (...message) => {
  if (process.env.NODE_ENV === 'development') {
    console.info('%c systemInfo:', 'background: #202124; color: #17a2b8', ...message)
  }
}
console._error =  (...message) => {
  if (process.env.NODE_ENV === 'development') {
    console.info('%c systemError:', 'background: #202124; color: #dc3545', ...message)
  }
}
console._warning =  (...message) => {
  if (process.env.NODE_ENV === 'development') {
    console.info('%c systemWarning:', 'background: #202124; color: #ffc107', ...message)
  }
}