import componentStyle from './StructureTextAreaComponent.module.scss'
import HtmlTextWrapper from '../../../../../components/HtmlTextWrapper/HtmlTextWrapper'
import {moreOptionsList} from '../../../../../data'
import {useContext, useEffect, useRef} from 'react'
import colorsContext from '../../../../../contexts/ColorsContext'
import classNames from "classnames";
import structuresContext from "../../../../../contexts/StructuresContext";
import {robotTeacherScrollIntoViewByElement} from "../../../../../operations/robotTeacherScrollIntoViewByElement";
import {useSelector} from "react-redux";

const StructureTextAreaComponent = ({text}) => {
  const {colors} = useContext(colorsContext)
  const {scrolling} = useSelector(state => state.roboTeacher)
  const {isRoboTeacher, roboTeacherData} = useContext(structuresContext)
  const isHidden = (isRoboTeacher ?? false) ? ((roboTeacherData.currentTime ?? 0) < (text?.hiddenTime ?? 0)) : false
  const elRef = useRef(null)

  useEffect(() => {
    if (text?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isHidden])

  if (text) {
    return (
      <div
        ref={elRef}
        className={classNames(componentStyle.textAreaComponent, {['hide-element']: isHidden})}
        style={{background: colors['C555']}}
      >
        <HtmlTextWrapper
          data={text}
          moreOptionsList={moreOptionsList}
          showPhraseOptions
          textColor={colors['C23']}
          optionsColor={colors['C23']}
        />
      </div>
    )
  } else {
    return null
  }
}

export default StructureTextAreaComponent