import serviceCall from "../../services"
import allUnitGameStatisticMapper from "../../services/mapper/games/allUnitGameStatisticMapper"
const gameUseCase = async ({ gameType }) => {
  try {
    const result = await serviceCall(
      'fetchGameStatisticsAllUnits',
      { gameType },
      'cloud'
    )
    return allUnitGameStatisticMapper(result)
  } catch (error) {
    console.error('Error in gameUseCase:', error)
    return null
  }
}

export default gameUseCase
