import serviceCall from "../../services";

const updateNotificationReceiverUseCase = async ({registrationToken, deviceId}) => {
  return await serviceCall(
    'putNotificationReceiver',
    {registrationToken, deviceId},
    'cloud',
  )
}

export default updateNotificationReceiverUseCase