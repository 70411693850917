import React, { useContext, useEffect, useMemo, useRef } from 'react'
import CircleNavigationButton from '../../components/CircleNavigationButton/CircleNavigationButton'
import ScrollContainer from 'react-indiana-drag-scroll'
import GenderIcon from '../../components/GenderIcon'
import { useParams } from 'react-router-dom'
import Lock from '../../components/Lock/Lock'
import PhrasesOptions from '../../components/PhrasesOptions/PhrasesOptions'
import HintClueButton from '../../components/HintClueButton/HintClueButton'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkDrillAnswer, decreaseCurrentIndex, fetchDrill, increaseCurrentIndex,
  setCurrentIndex, setRecordModalIsOpen, setShowClue,
  setUserData, setUserAnswer, tryAgain, toggleUnlockAnswer, setDrillSpeechType,
  getDrillHint,
} from '../../redux/features/drillSlice'
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton'
import htmlToText from '../../operations/htmlToText'
import SpeechCircleButton from '../../components/SpeechCircleButton/SpeechCircleButton'
import SpeechModal from '../../components/SpeechModal/SpeechModal'
import { moreOptionsList } from '../../data'
import HtmlTextWrapper from '../../components/HtmlTextWrapper/HtmlTextWrapper'
import { gsap } from 'gsap'
import HelpButton from '../../components/HelpButton/HelpButton'
import ResetSvg from '../../icons/ResetSvg'
import LevelNumberBox from '../../components/LevelNumberBox/LevelNumberBox'
import colorsContext from '../../contexts/ColorsContext'
import { START_SECTION } from "../../enums/studyLogEnums/studyLogEnums";
import useLog from "../../hooks/logHooks/useLog";
import useSectionEvents from "../../hooks/sectionHooks/useSectionEvents";
import { pageGuidEnums } from '../../enums/pageGuideEnums/pageGuideEnums'
import NativeText from "../../components/NativeText/NativeText";
import useTranslatedText from "../../hooks/useTranslatedText";
import ExerciseMistakeInfoButton from "../../components/ExerciseMistakeInfoButton/ExerciseMistakeInfoButton";
import { isEmpty, toNumber } from "lodash";
import SkipButton from "../../components/SkipButton/SkipButton";
import { pushSpeechRecognitionHistory } from "../../redux/features/speechToTextSlice";
import speechRecognitionHistoryFactory from "../../factory/speechRecognitionHistoryFactory";
import cleanSentenceForCheck from "../../operations/cleanSentenceForCheck";
import useAppUser from '../../hooks/useAppUser'
import TranslatedText from '../../components/TranslatedText/TranslatedText'
import useActionRetryerWrapper from '../../hooks/useDispatchWrapper'
import { AnswerStatusEnums } from '../../enums/globalEnums/globalEnums'

const Drill = () => {
  require('./drill.scss')

  const { colors } = useContext(colorsContext)
  const { sendServerLog } = useLog()
  const { appUserData } = useAppUser()

  const dispatch = useDispatch()
  const { networkLossRetryerDispatch } = useActionRetryerWrapper()
  const states = useSelector(state => state.drill)
  const { speechRecognitionHistory, speechRecognitionType } = useSelector(state => state.speechToText)
  const { logPending } = useSelector(state => state.studyLog)

  const inputEl = useRef(null)
  const inputCoverEl = useRef(null)
  const inputWrapperEl = useRef(null)
  const continueButtonRef = useRef(null)
  const { unit } = useParams()
  const paginationRef = useRef(null)
  const paginationListRef = useRef(null)
  const { dir } = useTranslatedText() // rtl, ltr

  const { navigateToNextSection } = useSectionEvents()

  const currentData = useMemo(() => {
    return states.data?.[states.currentIndex]
  }, [JSON.stringify(states.data ?? []), states.currentIndex])

  const answerChecked = useMemo(() => {
    return states.userData[states.currentIndex]?.checked
  }, [JSON.stringify(states.userData ?? []), states.currentIndex])

  const isTheLastItem = useMemo(() => {
    return states.currentIndex + 1 === states.userData?.length
  }, [JSON.stringify(states.userData ?? []), states.currentIndex])

  const statusColor = useMemo(() => (
    {
      'UNKNOWN': colors['C225'],
      'CORRECT': colors['C30'],
      'INCORRECT': colors['C29'],
    }
  ), [])

  const paginationStatusColor = useMemo(() => (
    {
      [AnswerStatusEnums.NONE]: colors['C160'],
      [AnswerStatusEnums.CORRECT]: colors['C30'],
      [AnswerStatusEnums.INCORRECT]: colors['C29'],
      [AnswerStatusEnums.CLUE]: colors['C71'],
    }
  ), [])

  useEffect(() => {
    if (
      !states.data ||
      toNumber(states.fetchedBy.unit) !== toNumber(unit) ||
      states.fetchedBy.nativeLanguage !== appUserData.setting.nativeLanguage
    ) {
      dispatch(setCurrentIndex(0))
      networkLossRetryerDispatch(retryId => {
        dispatch(fetchDrill({ unit, retryId })).then(() => {
          sendServerLog(START_SECTION)
        })
      })
    }
  }, [])

  useEffect(() => {
    if (answerChecked) {
      const nextButton = document.getElementById('primary_next_button')
      nextButton?.focus()
    }
  }, [answerChecked])

  const currentUserData = states.userData?.[states.currentIndex]

  /*
  * Auto fit textarea height to current value
  */
  useEffect(() => {
    if (
      states.userData.length > 0 &&
      currentUserData.userAnswer &&
      inputCoverEl.current &&
      inputEl.current
    ) {
      inputWrapperEl.current.style.height = `${inputCoverEl.current.clientHeight}px`
      inputWrapperEl.current.style.height = `${inputEl.current.scrollHeight}px`
    } else if (currentUserData?.checked && currentUserData?.isCorrectWithMistake) {
      inputWrapperEl.current.style.height = `${inputWrapperEl.current.clientHeight + 24}px`
    }
  }, [currentUserData?.userAnswer, currentUserData?.checked])

  /*
  * Auto scrolling pagination navbar on change current index
  */
  useEffect(() => {
    if (paginationRef.current && paginationListRef.current) {
      const paginationContainer = paginationRef.current.container.current,
        currentItem = paginationListRef.current.querySelector(`:nth-child(${states.currentIndex + 1})`),
        itemOffsetLeft = currentItem.offsetLeft - paginationContainer.offsetLeft

      gsap.to(
        paginationContainer, {
        scrollLeft:
          (itemOffsetLeft) +
          (currentItem.clientWidth / 2) -
          (paginationContainer.clientWidth / 2),
      },
      )
    }

    inputEl.current?.focus()
  }, [states.currentIndex])

  const renderPaginations = () => {
    return states.data.map((item, i) => {
      const answerStatusValue = answerStatus(i)
      const answeredUsingClue = states.userData[i].checked && states.userData[i].clueIndexUsed + 1 > 0 && answerStatusValue === AnswerStatusEnums.CORRECT
      return (
        <li
          key={i}
          style={{
            background: answeredUsingClue ?
              paginationStatusColor[AnswerStatusEnums.CLUE] :
              paginationStatusColor[answerStatusValue],
          }}
          onClick={() => dispatch(setCurrentIndex(i))}
          data-index={i}
        >
          <span style={{ color: states.userData[i].checked ? colors['C160'] : colors['C233'] }}>
            {i + 1}
          </span>
        </li>
      )
    })
  }

  const checkAnswer = () => {
    let matchCases = {}
    const correctAnswerString = htmlToText(currentData.sentence)
    const userData = states.userData[states.currentIndex]
    const sentenceData = states.data[states.currentIndex]

    if (cleanSentenceForCheck(userData.userAnswer) !== '') {
      matchCases[correctAnswerString] = userData.userAnswer
      dispatch(checkDrillAnswer({
        unit,
        matchCases,
        save: true,
        trackerNumber: sentenceData?.trackerNumber,
        isVoiceRecognition: userData.bySpeech
      })).then((res) => {
        if (userData.bySpeech) {
          let isCorrect = res.payload?.data?.matchResultDtos?.[0]?.wordByWordResult?.every(it => it.match)

          const currentSpeechType = states.data[states.currentIndex]?.speechType ?? speechRecognitionType
          const speechData = {
            input: userData.userAnswer,
            given: userData.sentenceCover,
            practiceType: 'DRILL',
            speechType: currentSpeechType,
            isCorrect,
          }
          const { newHistory, newSpeechType } = speechRecognitionHistoryFactory(
            currentSpeechType,
            speechRecognitionHistory,
            speechData
          )
          dispatch(pushSpeechRecognitionHistory(newHistory))
          dispatch(setDrillSpeechType({ currentIndex: states.currentIndex, speechType: newSpeechType }))
        }
      })
    }
  }

  const renderButton = () => {
    if (answerChecked) {
      if (isTheLastItem) {
        return (
          <PrimaryButton
            id='primary_next_button'
            ref={continueButtonRef}
            loading={logPending}
            onClick={navigateToNextSection}
          >
            Next
          </PrimaryButton>
        )
      }
      return (
        <PrimaryButton
          id='primary_next_button'
          ref={continueButtonRef}
          onClick={() => dispatch(increaseCurrentIndex())}
        >
          Continue
        </PrimaryButton>
      )
    } else {
      return (
        <PrimaryButton
          onClick={() => {
            if (!notAnswered) {
              checkAnswer({ save: true })
            }
          }}
          disabled={notAnswered}
          loading={states.loading}
        >
          Check
        </PrimaryButton>
      )
    }
  }

  const onClickHint = () => {
    const correctAnswerString = htmlToText(currentData.sentence)
    const userData = states.userData[states.currentIndex]
    const sentenceData = states.data[states.currentIndex]

    if (correctAnswerString !== userData.userAnswer) {
      const matchCases = {
        [correctAnswerString]: userData.userAnswer
      }

      dispatch(getDrillHint({
        unit,
        matchCases,
        save: true,
        trackerNumber: sentenceData?.trackerNumber,
        isVoiceRecognition: userData.bySpeech
      }))
    }
    inputEl.current.focus()
  }

  let notAnswered = states.userData.length > 0 && states.userData[states.currentIndex].userAnswer === ''
  const answerStatus = (i) => {
    if (states.userData[i].checked && states.userData[i].answerIsCorrect)
      return AnswerStatusEnums.CORRECT
    else if (states.userData[i].checked && !states.userData[i].answerIsCorrect)
      return AnswerStatusEnums.INCORRECT
    else
      return 'UNKNOWN'
  }

  const renderGenderIcon = () => {
    switch (currentData.voiceOver) {
      case 'MA*':
        return <GenderIcon color={colors['C564']} type='MALE' />
      case 'FA*':
        return <GenderIcon color={colors['C564']} type='FEMALE' />
      default:
        return <div />
    }
  }

  const onChangeInput = (e) => {
    dispatch(setUserData(
      [...states.userData].map((obj, i) => {
        if (i === states.currentIndex) {
          return { ...obj, userAnswer: e.target.value }
        } else {
          return obj
        }
      }),
    ))

    if (e.target.value === '') {
      if (!states.userData[states.currentIndex].showClue) {
        dispatch(setShowClue(true))
      }
    } else if (states.userData[states.currentIndex].showClue) {
      dispatch(setShowClue(false))
    }
  }

  const onRecordFinished = (text) => {
    if (!isEmpty(text)) {
      dispatch(setUserAnswer({ text: text, bySpeech: true }))
    } else {
      dispatch(setRecordModalIsOpen(false))
    }
  }

  const renderDrillBox = () => {
    const renderInput = () => {
      const currentItemUserData = states.userData[states.currentIndex]

      // If checked
      if (currentItemUserData.checked) {
        if (currentItemUserData?.answerIsCorrect || currentItemUserData.unlocked) {
          // If is correct or wrong but user unlocked the answer
          return (
            <div className='drill-input' style={{ color: statusColor[AnswerStatusEnums.CORRECT] }}>
              <div className='title-value'>
                <HtmlTextWrapper
                  textColor={colors['C30']}
                  data={currentData}
                  style={{ display: 'inline-block' }}
                />
                {currentItemUserData?.isCorrectWithMistake &&
                  <span style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: 6 }}>
                    <ExerciseMistakeInfoButton
                      wordByWordResult={currentItemUserData.checkResult}
                      popoverPlacement='top'
                    />
                  </span>
                }
              </div>
            </div>
          )
        } else {
          return (
            <div className='drill-input'>
              <p style={{ color: colors['C29'] }}>
                {currentItemUserData?.userAnswer}
              </p>
              {/*{currentItemUserData.checkResult.map((item, i) => (*/}
              {/*  <React.Fragment key={i}>*/}
              {/*		<span key={i} style={{*/}
              {/*      color: item.userStr ? (item.match ? statusColor['CORRECT'] : statusColor['INCORRECT']) : colors['C23'],*/}
              {/*      opacity: item.userStr ? 1 : 0.5*/}
              {/*    }}>*/}
              {/*			{item.correctStr}*/}
              {/*      {currentItemUserData.checkResult.length > i + 1 && ' '}*/}
              {/*		</span>*/}
              {/*  </React.Fragment>*/}
              {/*))}*/}
            </div>
          )
        }
      } else {
        return (
          <>
            {/* Drill input cover (shadow) */}
            <div
              ref={inputCoverEl}
              className={`drill-input-cover ${!states.userData[states.currentIndex].showClue ? 'hide-cover' : ''}`}
              dangerouslySetInnerHTML={{
                __html: states.userData[states.currentIndex].sentenceCover,
              }}
            />
            {/* Drilling input */}
            <textarea
              onKeyPress={e => {
                if (e.key === 'Enter' || e.key === 'ctrlKey') {
                  e.preventDefault()
                  if (!notAnswered) {
                    checkAnswer({ save: true })
                  }
                }
              }}
              ref={inputEl}
              className='drill-input'
              value={states.userData[states.currentIndex].userAnswer}
              onChange={(e) => onChangeInput(e)}
              style={{
                backgroundImage: `url('${window.location.origin}/assets/images/input_dots.svg')`,
                color: colors['C153'],
              }}
            />
          </>
        )
      }
    }

    return (
      <div
        className='drill-box'
        style={{
          backgroundColor: states.userData[states.currentIndex].checked ? colors['C516'] : colors['C194'],
          borderColor: statusColor[answerStatus(states.currentIndex)],
        }}
      >
        <div className='over-input'>
          {renderGenderIcon()}
          <div>
            {states.userData[states.currentIndex].checked &&
              !states.userData[states.currentIndex].answerIsCorrect &&
              <Lock
                onClickLock={() => dispatch(toggleUnlockAnswer())}
                isLocked={!states.userData[states.currentIndex].unlocked}
              />
            }
          </div>
        </div>
        <div className='drill-input-wrapper' ref={inputWrapperEl}>
          {renderInput()}
        </div>
        <div className='under-input'>
          {!states.userData[states.currentIndex].checked &&
            <SpeechCircleButton
              iconColor={colors['C23']}
              borderColor={colors['C23']}
              onClick={() => dispatch(setRecordModalIsOpen(true))}
              isSmall
            />
          }
          {states.userData[states.currentIndex].checked &&
            <>
              <button
                className='reset-drill-item'
                onClick={() => dispatch(tryAgain())}
              >
                <ResetSvg color={colors['C233']} />
              </button>
              {(states.userData[states.currentIndex].unlocked ||
                states.userData[states.currentIndex].answerIsCorrect) &&
                <PhrasesOptions
                  color={colors['C233']}
                  moreOptionsList={moreOptionsList}
                  data={currentData}
                />
              }
            </>
          }
        </div>
      </div>
    )
  }

  return (
    <div data-jsx='Drill'>
      <div className='drill-page-container pt-3'>
        <div>
          <div className='container-fluid'>
            <div className='d-flex justify-content-end'>
              <div className='me-3 me-md-5 ms-2'>
                <HelpButton color={colors['C2']} pageType={pageGuidEnums.DRILLING_SECTION} />
              </div>
            </div>
          </div>
          <div className='container container-xlg'>
            {states.userData.length > 0 &&
              <div className='drill-container'>
                <div className='pagination-container'>
                  <div className='pagination-wrapper' style={{ borderColor: colors['C34'] }}>
                    <ScrollContainer className='pagination' ref={paginationRef}>
                      <ul style={{ background: colors['C171'] }} ref={paginationListRef}>
                        {renderPaginations()}
                      </ul>
                    </ScrollContainer>
                  </div>
                </div>
                <div className='sentence-container'>
                  <CircleNavigationButton
                    color={colors['C2']}
                    direction='left'
                    hideButton={states.currentIndex === 0}
                    onClick={() => dispatch(decreaseCurrentIndex())}
                  />
                  <div className='sentence-wrapper'>
                    <NativeText className='sentence' style={{ color: colors['C2'] }}>
                      {currentData.translate}
                    </NativeText>
                  </div>
                  <CircleNavigationButton
                    color={colors['C2']}
                    direction='right'
                    hideButton={states.currentIndex + 1 === states.data.length}
                    onClick={() => dispatch(increaseCurrentIndex())}
                  />
                </div>
                {renderDrillBox()}
                <div className='clue-and-number-container'>
                  <div>
                    <HintClueButton
                      onClickHint={onClickHint}
                      disabled={states.userData[states.currentIndex].checked}
                      loading={states.loading}
                    />
                  </div>
                  <LevelNumberBox className='mx-auto mb-4' color={colors['C187']}>
                    {states.currentIndex + 1}/{states.data.length}
                  </LevelNumberBox>
                  <div className='organizer' />
                </div>
                <div className='d-flex justify-content-center'>
                  {renderButton()}
                </div>
              </div>
            }
          </div>
        </div>
        <div className='container'>
          <div className='skip-container'>
            <SkipButton
              color={colors['C2']}
              disabled={logPending}
              onClick={navigateToNextSection}
              translate={false}
            />
          </div>
        </div>
      </div>
      {states.recordModalIsOpen &&
        <SpeechModal
          speechType={currentData?.speechType}
          nativeSentence={<TranslatedText translationKey={'say.this.in.english'} />}
          given={states.userData[states.currentIndex].sentenceCover}
          sentence={`<br><p dir="${dir}">${currentData.translate}</p>`}
          onRecordFinished={(text) => onRecordFinished(text)}
          loading={states.spellPending}
        />
      }
    </div>
  )
}

export default Drill