import React, {useContext, useEffect, useRef} from 'react'
import structureStyle from './../StructuredComponents.module.scss'
import componentStyle from './ImagesTemplate.module.scss'
import colorsContext from '../../../../contexts/ColorsContext'
import StructureTitleComponent from '../Components/StructureTitleComponent/StructureTitleComponent'
import classNames from 'classnames'
import StructureImageComponent from '../Components/StructureImageComponent/StructureImageComponent'
import {isEmpty, isNumber} from 'lodash'
import structuresContext from "../../../../contexts/StructuresContext";
import {robotTeacherScrollIntoViewByElement} from "../../../../operations/robotTeacherScrollIntoViewByElement";
import {useSelector} from "react-redux";

const imagesContainerClassName = {
  'ONE_COLUMN': componentStyle.oneColumnContainer,
  'TWO_COLUMN': componentStyle.twoColumnContainer,
  'THREE_COLUMN': componentStyle.threeColumnContainer,
}

export const ImagesTemplateCard = ({card}) => {

  const elRef = useRef(null)
  const {scrolling} = useSelector(state => state.roboTeacher)
  const {isRoboTeacher, roboTeacherData} = useContext(structuresContext)
  const cardIsHidden = (isRoboTeacher ?? false) ? ((roboTeacherData.currentTime ?? 0) < (card?.hiddenTime ?? 0)) : false

  useEffect(() => {
    if (card?.hiddenTime > 0 && !cardIsHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [cardIsHidden])

  return (
    <div
      ref={elRef}
      data-rt-id={card?.id}
      className={classNames(structureStyle.templateCard, {['hide-element']: cardIsHidden})}
    >
      {card.title?.sentence &&
        <StructureTitleComponent text={card.title}/>
      }
      <div
        className={classNames(
          componentStyle.imagesTemplateContainer,
          imagesContainerClassName[card.displayMode],
        )}
      >
        {card.images.map((image, imageIndex) => (
          <StructureImageComponent key={imageIndex} picture={{...image?.picture, text: image.text}}/>
        ))}
      </div>
    </div>
  )
}

const ImagesTemplate = ({template}) => {
  const {colors} = useContext(colorsContext)
  const elRef = useRef(null)
  const {scrolling} = useSelector(state => state.roboTeacher)
  const {isRoboTeacher, roboTeacherData} = useContext(structuresContext)
  const isHidden = (isRoboTeacher ?? false) ? ((roboTeacherData.currentTime ?? 0) < (template?.hiddenTime ?? 0)) : false

  useEffect(() => {
    if (template?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isHidden])

  const renderTemplateCard = (cards) => {
    return (
      <div
        ref={elRef}
        data-rt-id={template?.id}
        className={classNames(
          structureStyle.templateContainer,
          {[structureStyle.border]: !isEmpty(template?.config?.borderColor)},
          {['hide-element']: isHidden}
        )}
        style={{
          background: isEmpty(template?.config?.backgroundColor) ? colors['C194'] : template.config.backgroundColor,
          borderColor: template?.config?.borderColor,
        }}
      >
        {cards.map((card, i) => {
          return (
            <ImagesTemplateCard key={i} card={card}/>
          )
        })}
      </div>
    )
  }

  return (
    <div className='container container-1'>
      {template.config.isJoined
        ? renderTemplateCard(template.data.cards)
        : template.data.cards.map((card, i) => {
          return (
            <React.Fragment key={i}>
              {renderTemplateCard([card])}
            </React.Fragment>
          )
        })
      }
    </div>
  )
}

export default ImagesTemplate