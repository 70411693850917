import React from 'react'
import PageLayer from '../../../components/PageLayer/PageLayer'
import FramerMotion from '../../../layouts/FramerMotion'
import WordMarkLogoSvg from '../../../icons/logo/WordMarkLogoSvg'
import TranslatedText from '../../../components/TranslatedText/TranslatedText'
import styles from './AddToHomeScreenGuide.module.scss'
import classNames from 'classnames'
import SecondaryButton from '../../atoms/SecondaryButton/SecondaryButton'
import reactStringReplace from 'react-string-replace'
import IphoneShareButtonSvg from '../../../icons/IphoneShareButtonSvg'
import IphoneAddButtonSvg from '../../../icons/IphoneAddButtonSvg'
import useTranslatedText from '../../../hooks/useTranslatedText'
import { setCurrentOpenModal } from '../../../redux/features/modalSlice'
import { useDispatch } from 'react-redux'

const AddToHomeScreenGuide = () => {
	const { dir } = useTranslatedText()
	const dispatch = useDispatch()

	return (
		<PageLayer>
			<FramerMotion style={{ background: '#FFFAEC' }}>
				<div
					className={classNames(
						styles.wrapper,
						'd-flex flex-column justify-content-center align-items-center minh-100vh',
					)}>
					<div className={styles.logo}>
						<WordMarkLogoSvg primaryColor="#000000" secondaryColor="#E98020" oInnerColor="transparent" />
					</div>
					<div dir={dir}>
						<h3 className={styles.title}>
							<TranslatedText translationKey="add.to.home.screen.guide.title" />
						</h3>
						<div className={styles.card} style={{ background: '#F5F1E6', borderColor: '#E98020' }}>
							<div>
								<p className={styles.descItem}>
									<TranslatedText
										middleware={value => {
											return reactStringReplace(value, '[icon]', () => {
												return (
													<span className={styles.inlineSvgIcon} key="iphone-share-button-svg">
														<IphoneShareButtonSvg />
													</span>
												)
											})
										}}
										translationKey="add.to.home.screen.guide.item.1"
									/>
								</p>
								<p className={styles.descItem}>
									<TranslatedText
										middleware={value => {
											return reactStringReplace(value, '[icon]', () => {
												return (
													<span className={styles.inlineSvgIcon} key="iphone-add-button-svg">
														<IphoneAddButtonSvg />
													</span>
												)
											})
										}}
										translationKey="add.to.home.screen.guide.item.2"
									/>
								</p>
								<p className={styles.descItem}>
									<TranslatedText translationKey="add.to.home.screen.guide.item.3" />
								</p>
							</div>
							<SecondaryButton
								onClick={() => dispatch(setCurrentOpenModal(null))}
								backgroundColor="transparent"
								borderColor="#E98020"
								color="#E98020"
								className="mx-auto">
								<TranslatedText translationKey="got.it" />
							</SecondaryButton>
						</div>
					</div>
				</div>
			</FramerMotion>
		</PageLayer>
	)
}

export default AddToHomeScreenGuide
