import serviceCall from "../../services";
import { setPasswordActionEnums } from "../../enums/inquiryEnums/inquiryEnums";

const inquiryResetPasswordUseCase = async (data) => {
  const response = await serviceCall(
    'postInquiryResetPassword',
    {
      authData: {
        // platform: data.authData?.platform,
        username: data.authData?.username,
        password: data.authData?.password,
        rePassword: data.authData?.rePassword,
        code: data.authData?.code,
        state: data.authData?.state,
      },
      captchaToken: data.captchaToken
    },
    'cloud'
  )

  return { ...response, action: response.action ?? setPasswordActionEnums.DONE }
}

export default inquiryResetPasswordUseCase