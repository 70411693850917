import React, { useContext } from 'react'
import styles from './TipCard.module.scss'
import colorsContext from '../../contexts/ColorsContext'

const TipCard = ({ children, title = 'Tip' }) => {
	const { colors } = useContext(colorsContext)
	return (
		<div className={styles.tipCard} style={{ background: colors['C242'] }}>
			<div className={styles.tipCardInner} style={{ borderColor: colors['C537'] }}>
				<div className={styles.tipTitleWrapper}>
					<span className={styles.beforeLine} style={{ background: colors['C537'] }} />
					<p style={{ color: colors['C537'] }}>{title}</p>
				</div>
				<div className={styles.content} style={{ color: colors['C233'] }}>
					{children}
				</div>
			</div>
		</div>
	)
}

export default TipCard