import React, { useContext } from 'react'
import getUserStatusColor from '../../../../operations/getUserStatusColor'
import colorsContext from '../../../../contexts/ColorsContext'
import { AnswerStatusEnums } from '../../../../enums/globalEnums/globalEnums'

const renderUserAnswer = (userAnswerData, colorCode = 'C23') => {
	const { colors } = useContext(colorsContext)

	if (userAnswerData?.userAnswer) {
		// if (userAnswerData.wordByWordResult) {
		// 	return userAnswerData.wordByWordResult.map((word, i) => (
		// 		<span key={i} style={{
		// 			color: word.userStr ? (word.match ? colors['C30'] : colors['C29']) : colors[colorCode],
		// 			opacity: word.userStr ? 1 : 0.5,
		// 		}}>
		// 			{word.specialChar === '\n' && <br />}
		// 			{word.userStr ?? word.correctStr}
		// 			{userAnswerData.wordByWordResult.length > i + 1 && ' '}
		// 		</span>
		// 	))
		// } else {
			return (
				<span style={{ color: colors[getUserStatusColor(userAnswerData.userAnswerStatus)] }}>
					{userAnswerData.userAnswerStatus === AnswerStatusEnums.INCORRECT ? userAnswerData.userAnswer : userAnswerData.correctAnswer}
				</span>
			)
		// }
	} else {
		return ' '
	}
}

export default renderUserAnswer